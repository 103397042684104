import { takeEvery, select, all, put } from 'redux-saga/effects'
import { UPDATE_SELECTED_BY_GROUPS_AND_TAGS, toggleSelectedObject } from '../../store/Objects/actions'


export default function* () {
    yield takeEvery(UPDATE_SELECTED_BY_GROUPS_AND_TAGS, worker)
}

function* worker({ payload }) {
    const type = payload.type
    const selected = payload.selected || []
    const id = payload.id || -1

    const selectedTags = yield select(state => state.objectsByTags.selected)
    let selectedGroups = yield select(state => state.objectsGroup.selected)
    const tags = yield select(state => state.objectsByTags.tagsAssoc)
    const tags2 = yield select(state => state.objectsByTags.tags)
    const groups = yield select(state => state.objectsGroup.groupsAssoc)

    let objectsIds = []

    try {
        let actionToAdd = true
        //

        console.log("UPDATE_SELECTED_BY_GROUPS_AND_TAGS", { payload, selectedGroups, id });

        if (type === "groups" && selectedGroups && selectedGroups.indexOf(id) === -1) actionToAdd = false

        if (type === "tags") {
            //objectsIds = tags[id].objectsIds
            selectedTags.forEach((tagId) => {
                let tag = tags2.find(tagObj => tagObj.id === tagId);
                if (tag) {
                    objectsIds.push(...tag.objectsIds)
                }
            })
            objectsIds = [...new Set(objectsIds)]
            console.log(1, objectsIds);
            yield put(toggleSelectedObject({
                selected: objectsIds,
                simple: true
            }))
            console.log(2);
            return;
        } else if (type === "groups") {
            selected.forEach(grId => {
                objectsIds.push(...groups[grId].objectsIds)
            })
            objectsIds = [...new Set(objectsIds)]
            yield put(toggleSelectedObject({
                selected: objectsIds,
                simple: true
            }))
            return;
        }
        console.log(3);
        objectsIds = [...new Set(objectsIds)]

        yield all(
            objectsIds.map(objectId =>
                put(toggleSelectedObject({
                    id: objectId,
                    required: actionToAdd // Снимаем/устанавливаем выделение
                }))
            )
        )
        console.log(4);
    } catch (e) {
        console.log(`Ошибка при ${UPDATE_SELECTED_BY_GROUPS_AND_TAGS}`)
        console.error(e)
    }
}
import React from 'react'


const Empty = ({ className = "", children }) => {
    return(
        <div className={`Space__Empty ${className}`}>
            <p className='spp-font-text-lg spp-c-dark spp-o-xl'>
                {children}
            </p>
        </div>
    )
}

export default Empty
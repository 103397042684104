import React, {useState, useRef} from 'react'

import {OBJECT_SCHEDULE} from '../../../../../../store/Settings/sets'

import {setsex} from '../../../../utilities/setsclasses'
import {cl} from '../../../../../../utilities/cl'
import {mediaTabletAndUp} from '../../../../../../utilities/media'

import Group from '../../../../components/Group/Group'
import SettingsTabs from '../../../../components/Tabs'
import SettingsDays from '../../../../components/Days'
import SettingsWithoutAds from '../../../../components/WithoutAds'
import MediaList from './MediaList'


const GroupSmart = ({
    className = "",
    stateKey,
    store,
    settings = {},
    items = [],
    tracks = {},
    selectedObjects = [],
    objectsAssoc = {},
    selected = [],
    selectedBlockNumber,
    toggleSelectedItems,
    selectBlock,
    deleteTrackFromPosition,
    saveItemDaymask,
    saveTrackForItem,
    deleteItems,
    onChange,
    downloadTracks,
    loading,
    saving,
    deleting,
    openModal,
    closeModal,
}) => {

    const activeTabRef = useRef(null)

    const disabledChannel = getDisabledChannel(selectedObjects, objectsAssoc)
    let disabledTab = -1
    if(disabledChannel > 0) {
        disabledTab = (disabledChannel === 1) ? 1 : 0
    }
    const activeTabNumber = disabledTab === 1 ? 0 : 1

    const hToggleActiveTab = () => {
        if(activeTabRef?.current?.tagName === "DIV") {
            const elem = activeTabRef.current

            if(elem.classList.contains("active-tab-0")) {
                elem.classList.remove("active-tab-0")
                elem.classList.add("active-tab-1")
                elem.dataset.activeTab = 1
            } else {
                elem.classList.remove("active-tab-1")
                elem.classList.add("active-tab-0")
                elem.dataset.activeTab = 0
            }
        }
    }

    className = cl(
        className,
        "ObjectScheduleGroupSmart",
    )

    return(
        <Group className={className} columns={1} title="Редактирование рекламного блока">
            <div className={`active-tab-${activeTabNumber}`} data-active-tab={activeTabNumber} ref={activeTabRef}>

                <header className="ObjectScheduleGroupSmart__header setsex-flex-nowrap setsex-align-end">
                    <SettingsTabs
                        className={setsex(["col-2", "mr", "mb-0"])}
                        tabs={[
                            {id: 1, title: "Помещение"},
                            {id: 0, title: "Улица"},
                        ]}
                        selected={activeTabNumber}
                        onChange={hToggleActiveTab}
                        disabled={disabledTab}
                    />

                    {mediaTabletAndUp(
                        <>
                            <div className={setsex(["col-2"])}>
                                <SettingsDays
                                    value={settings["daymask"] || 127}
                                    disabled={!(selected.length)}
                                    onChange={onChange("daymask")}
                                />
                                <div className="pt-sm1">
                                    <SettingsWithoutAds
                                        value={settings["__without-ads"]}
                                        name="__without-ads"
                                        disabled={!(selected.length)}
                                        onChange={onChange("__without-ads")}
                                    />
                                </div>
                            </div>
                        </>,
                        null
                    )}
                </header>

                <MediaList
                    stateKey={stateKey}
                    store={store}
                    type={OBJECT_SCHEDULE}
                    activeTabRef={activeTabRef}
                    items={items}
                    tracks={tracks}
                    selected={selected}
                    selectedBlockNumber={selectedBlockNumber}
                    toggleSelectedItems={toggleSelectedItems}
                    selectBlock={selectBlock}
                    saveItemDaymask={saveItemDaymask}
                    deleteTrackFromPosition={deleteTrackFromPosition}
                    deleteItems={deleteItems}
                    downloadTracks={downloadTracks}
                    saveTrackForItem={saveTrackForItem}
                    loading={loading}
                    saving={saving}
                    deleting={deleting}
                    openModal={openModal}
                    closeModal={closeModal}
                />
            </div>
        </Group>
    )
}

function getDisabledChannel(selected, objects) {
    let channels = 0

    selected.map(objectId => {
        if(channels < 3) {
            channels += objects[objectId].chmask
        }
    })

    if(channels === 1) {
        return 1
    } else if(channels === 2) {
        return 2
    }

    return 0
}

export default GroupSmart
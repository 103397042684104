import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../../utilities/generateStateKey'
import {cl} from '../../../../../utilities/cl'

import {OBJECT_OBJECT} from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'
import ScrollContainer from '../../../../../components/ScrollContainer'
import {download} from "../../../../../utilities/download";
import DiskSize from "../../../../../components/DiskSize";


const SettingsObjectCollapsible = ({
                                       className = "",
                                       selectedObjects,
                                       selectedObjectsGroup,
                                       placeNumber,
                                       active
                                   }) => {

    const stateKey = generateStateKey([selectedObjects])

    const hSave = () => {
        const collapsible = document.querySelector(".SettingsObjectObjectCollapsible")
        const buttonSave = collapsible.querySelector(".SettingsObjectObjectSave button")
        if (buttonSave) {
            buttonSave.click()
        }
    }

    const hDownload = () => {
        download(`https://a.ms-box.ru/msbox/config/download?id=${selectedObjects}&token=${window.localStorage.getItem("sessionToken")}`)
    }

    className = cl(
        className,
        "SettingsObjectObjectCollapsible"
    )

    const ct = selectedObjectsGroup.length ? 'Настройки группы объектов' : (selectedObjects.length > 1 ? 'Настройки объектов' : 'Настройки объекта');
    return (
        <Collapsible
            active={active}
            id={211}
            className={className}
            title={ct}
            placeNumber={placeNumber}
            scroll={{
                on: true,
                data: {
                    size: "lg"
                }
            }}
            buttons={{
                download: {
                    on: true,
                    mobile: true,
                    onClick: () => hDownload()
                },
                save: {
                    on: true,
                    mobile: false,
                    onClick: hSave
                }
            }}
        >
            {selectedObjects.length === 1 && <DiskSize objectId={selectedObjects[0]}></DiskSize>}
            <Settings
                set={OBJECT_OBJECT}
                data={{
                    objectIds: selectedObjects,
                    stateKey: stateKey,
                }}
            />
        </Collapsible>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
    selectedObjectsGroup: [...state.objectsGroup.selected],
})

export default connect(stateToProps, null)(SettingsObjectCollapsible)
import moment from 'moment'
import { takeEvery, put, call } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { SAVE_PERIOD, loadPeriods, saving } from '../../../store/Settings/actions/components/Periods'
import { closeModal } from '../../../store/Modal/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_PERIOD, worker)
}

function* worker({ payload }) {
    const { type, id, period } = payload

    yield put(saving({ type, saving: true }))

    try {
        period.fromdate = moment(period.fromdate).format("YYYY[-]MM[-]DD")
        period.todate = moment(period.todate).format("YYYY[-]MM[-]DD")

        const saved = yield call(fetchPeriods, type, id, period)

        // Ошибка
        if (saved.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${SAVE_PERIOD}.`, saved],
                text: "Период не был сохранён. Попробуйте повторить."
            }))

            // Успех
        } else {
            yield put(loadPeriods({ type, required: true }))
            yield put(closeModal("element"))
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_PERIOD}.`, e],
            text: "Период не был сохранён. Попробуйте повторить."
        }))
    }

    finally {
        yield put(saving({ type, saving: false }))
    }
}

function fetchPeriods(type, id, period) {
    let formData = new FormData()
    formData.set("fromdate", period.fromdate)
    formData.set("todate", period.todate)

    return axios(
        {
            url: `/msbox/${type}/dates/update`,
            method: "POST",
            data: formData,
            params: {
                id
            }
        }
    )
        .then(response => response.data)
}
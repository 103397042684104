import {takeEvery, put, select} from 'redux-saga/effects'
import {updateModalStore} from '../../../store/Modal/types/AdsEdit/actions'
import {OPEN_MODAL} from '../../../store/Modal/actions'


export default function* () {
    yield takeEvery(OPEN_MODAL, worker)
}

function* worker({ payload }) {

    if(payload.type === "adsEdit") {
        const tracks = yield select(state => state.tracks.tracks)
        const selectedTrack = yield select(state => state.tracks.selected[0])

        let adsData = {}
 
        tracks.map(track => {
            if(track.id === selectedTrack) {
                adsData = track
            }
        })

        yield put(updateModalStore(adsData))
    }

    if(payload.type === "adsEditExtra") {
        const tracks = yield select(state => state.tracks.tracksExtra)
        const selectedTrack = yield select(state => state.tracks.selected[0])

        let adsData = {}
 
        tracks.map(track => {
            if(track.id === selectedTrack) {
                adsData = track
            }
        })

        yield put(updateModalStore(adsData))
    }
}
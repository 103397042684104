import React from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../../utilities/cl'

import ElementsGroup from '../../ElementsGroup'
import ButtonCircle from '../../ButtonCircle'
import SvgExpand from '../../SVG/Expand'
import SvgCollapse from '../../SVG/Collapse'


const Place = ({
    className = "",
    title,
    number,
    elements = {},
    expand = true,
    disabled,
    style,

    expandedPlace = true,
    activePlace,
    changeActivePlace,
    changeExpandedPlace,
}) => {
    let statePlace = (activePlace === number) ? "active " : ""

    if (expandedPlace !== 0) statePlace += (expandedPlace === number) ? "expanded" : "not-expanded"



    className = cl(
        className,
        "Panel__Place",
        statePlace,
        { "disabled": disabled }
    )

    return (
        <div className={ className } style={ style }>
            <div className="title" onClick={ () => changeActivePlace(number) }>
                { title }
            </div>

            <div className="body">
                <ElementsGroup position="left">
                    { elements.left && elements.left }
                </ElementsGroup >

                <ElementsGroup position="right">
                    { elements.right && elements.right }
                    { expand &&
                        <ButtonCircle
                            className="expand"
                            onClick={ () => changeExpandedPlace(number) }
                            disabled={ disabled }
                        >
                            <SvgCollapse className="SvgCollapse" />
                            <SvgExpand className="SvgExpand" />
                        </ButtonCircle>
                    }
                </ElementsGroup>
            </div>
        </div>
    )
}

Place.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    expandedPlace: PropTypes.number,
    activePlace: PropTypes.number,
    number: PropTypes.number,
    disabled: PropTypes.bool,
    changeActivePlace: PropTypes.func,
}

export default Place
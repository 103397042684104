import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { CREATE_PLAYLIST, loading, avMapBinding } from '../../../store/Modal/types/PlaylistCreate/actions'
import { closeModal } from '../../../store/Modal/actions'
import { loadPlaylists } from '../../../store/ContentPlaylists/actions'
import { showAlertError } from '../../../store/Alert/actions'
import { loadAvmapBindings } from '../../../store/ContentPlaylists/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(CREATE_PLAYLIST, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        let created = yield call(fetchCreate, payload)

        if (created.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CREATE_PLAYLIST}.`, created],
                text: "Плейлист не был создан. Попробуйте повторить."
            }))
            yield put(closeModal("playlistCreate"))

        } else {
            if (payload.videolist) {
                created.success =  created.success.map(list => {
                    let totalsize2 = 0;
                    if (list.totalsize) {
                        let size = Math.floor(list.totalsize / 1024 / 1024);
                        size = !size ? `${Math.floor(list.totalsize / 1024 )} Kb` : `${size} Mb`
                        totalsize2 = size
                    }
                    list.totalsize2 = totalsize2
                    return list;
                })
                console.log({created: created.success});
                yield call(fetchAvMapBindings, { owner_id: payload.owner_id, playlist_id: created.success, videolist_id: payload.videolist })
            }
            yield put(closeModal("playlistCreate"))
            yield put(loadAvmapBindings())
            yield put(loadPlaylists())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CREATE_PLAYLIST}.`, e],
            text: "Плейлист не был создан. Попробуйте повторить."
        }))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchCreate(playlistData) {
    let formData = new FormData()
    if (playlistData && playlistData.name) {
        Object.keys(playlistData).forEach(name => {
            formData.set(name, playlistData[name])
        })
    }

    return axios(
        {
            url: "/playlist/insert",
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}

function fetchAvMapBindings(bindingData) {
    let formData = new FormData()
    console.log(bindingData)
    if (bindingData && bindingData.owner_id && bindingData.playlist_id && bindingData.videolist_id) {
        Object.keys(bindingData).forEach(name => {
            formData.set(name, bindingData[name])
        })
    }
    return axios(
        {
            url: "/videolist/avmap/insert",
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}
export const LOAD_PV_DATA = "PvData/Load"
export const SAVE_PV_DATA = "PvData/SAVE"
export const UPDATE_PV_DATA = "PvData/UPDATE"

export const savePvData = payload => ({
    type: SAVE_PV_DATA,
    payload
})

export const loadPvData = () => ({
    type: LOAD_PV_DATA    
})

export const updatePvData = payload => ({
    type: UPDATE_PV_DATA,
    payload
})
import {LOADING, UPDATE_MODAL_STORE} from './actions'

const initialState = {
    loading: false,
    adsData: {},
}

const reducer = (state = initialState, {type, payload}) => {
    switch(type) {

        case LOADING:
            return {
                ...state,
                loading: payload
            }

        case UPDATE_MODAL_STORE:
            return {
                ...state,
                adsData: payload
            }
    }
    return state
}

export default reducer
import { takeEvery, put, call } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { UPDATE_PRESET_NAME, loadPresets } from '../../../store/Settings/actions/components/Presets'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(UPDATE_PRESET_NAME, worker)
}

function* worker({ payload }) {
    try {
        const updated = yield call(fetchUpdatePresetName, payload.presetId, payload.name)

        if (updated.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_PRESET_NAME}.`, updated],
                text: "Пресет не был обновлён. Попробуйте повторить."
            }))
        } else {
            yield put(loadPresets())
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_PRESET_NAME}.`, e],
            text: "Пресет не был обновлён. Попробуйте повторить."
        }))
    }
}

function fetchUpdatePresetName(id, name) {
    let formData = new FormData()
    formData.set("name", name)

    return axios(

        {
            url: "/preset/update",
            method: "POST",
            data: formData,
            params: {
                id: id
            }
        }
    )
        .then(response => response.data)
}
import React, { useState, useEffect } from 'react'
import Spinner from '../../../../../../../components/Spinner'
import { connect } from 'react-redux'
import { loadSystemReport } from '../../../../../../../store/Reports/System/actions'
import SystemReportItem from '../System/item'
import List from '../../../../../../../components/List'
import SvgArrowUpDown from '../../../../../../../components/SVG/ArrowUpDown'

const ReportSystem = ({
    loadSystemReport,
    objects,
    selected,
    system_report,
    loading,
    date
}) => {
    // useEffect(() => {
    //     loadSystemReport({ id: selected[0], date: date })
    // }, [])
    const getItems = () => {
        return system_report.map((system_report, index) => ({
            id: index,
            name: system_report.event,
            element: <SystemReportItem
                key={ index }
                id={ index }
                timestamp={ system_report.timestamp }
                color={ system_report.color }
                event={ system_report.event }
                variant={ (index % 2 == 0) ? "gray" : "" }
            />
        }))
    }
    return (
        <div className="SystemsReport">
            { !loading ?
                <div style={ { marginTop: "40px" } }>
                    <List
                        type="simple"
                        scroll={ {
                            on: true,
                            data: {
                                variant: "white",
                                size: "sm",
                            }
                        } }
                        // variant={ "none" }
                        content={ { items: getItems(), variant: "none" } }
                    />
                </div>
                : <Spinner />
            }

        </div >
    )
}

const stateToProps = state => ({
    objects: state.objects.allObjects,
    selected: state.objects.selected,
    system_report: state.system_report.system_report,
    loading: state.system_report.loading
})

const actionsToProps = {
    loadSystemReport
}

export default connect(stateToProps, actionsToProps)(ReportSystem)
import { takeEvery, put, call } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { isObj } from '../../../utilities/isObj'
import { LOAD_PRESETS, putPresets } from '../../../store/Settings/actions/components/Presets'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_PRESETS, worker)
}

function* worker() {
    try {
        const presets = yield call(fetchPresets)
        let sorted = {},
            type = ""

        if (presets.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_PRESETS}.`, presets],
                text: "Пресеты не были загружены. " + presets.error
            }))
            yield put(putPresets({}))
        } else {

            presets.success.map(preset => {
                type = JSON.parse(preset.data)
                type =
                    isObj(type)
                        ? type = type.__settingsType
                        : false

                if (type) {
                    if (!sorted[type]) sorted[type] = []
                    sorted[type].push(preset)
                }
            })

            yield put(putPresets(sorted))
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_PRESETS}.`, e],
            text: "Пресеты не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putPresets({}))
    }
}

function fetchPresets() {
    return axios.get("/preset/select")
        .then(response => response.data)
}
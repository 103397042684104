import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Provider } from 'react-redux'
import { store } from './connect'

import './index.sass'
import App from './components/App'
import NotFound from './routes/NotFound'
import Main from './routes/Main'
import Settings from './routes/Settings'
import Advert from './routes/Advert'
import Content from './routes/Content'
import Object from './routes/Object'
import Login from './routes/Login'
import Reports from './routes/Reports'
import Templates from './routes/Templates'
import Notifications from './routes/Notifications'
import Roles from './routes/Roles'
import EtherFeatures from './routes/EtherFeatures'
import Interface from './routes/Interface'
import Help from './routes/Help'

import './assets/fonts/Circe/Circe/Circe-Bold.otf'
import './assets/fonts/Circe/Circe/Circe-ExtraBold.otf'
import './assets/fonts/Circe/Circe/CRC25.otf'
import './assets/fonts/Circe/Circe/CRC35.otf'
import './assets/fonts/Circe/Circe/CRC55.otf'
import { PrivateRoute } from './components/App/PrivateRoute'

import {
    SETTINGS,
    SETTINGS_TEMPLATES,
    SETTINGS_NOTIFICATIONS,
    SETTINGS_ROLES,
    MAIN,
    MAIN_OBJECT,
    MAIN_ADVERT,
    MAIN_CONTENT,
    LOGIN,
    REPORT,
    HELP,
    SETTINGS_INTERFACE,
    SETTINGS_FEATURES_OF_THE_ETHER

} from './routes/routes'
import ErrorBoundary from './components/ErrorBoundary'

console.log('REACT_APP_VERSION', process.env.REACT_APP_VERSION)

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <Provider store={store}>
                <ErrorBoundary>
                    <App>
                        <Switch>
                            <PrivateRoute exact path={MAIN} component={Main} />
                            <PrivateRoute exact path={SETTINGS} component={Settings} />
                            <PrivateRoute exact path={MAIN_ADVERT} component={Advert} />
                            <PrivateRoute exact path={MAIN_CONTENT} component={Content} />
                            <PrivateRoute exact path={MAIN_OBJECT} component={Object} />
                            <PrivateRoute exact path={SETTINGS_TEMPLATES} component={Templates} />
                            <PrivateRoute exact path={SETTINGS_NOTIFICATIONS} component={Notifications} />
                            <PrivateRoute exact path={SETTINGS_ROLES} component={Roles} />
                            <PrivateRoute exact path={SETTINGS_FEATURES_OF_THE_ETHER} component={EtherFeatures} />
                            <PrivateRoute exact path={SETTINGS_INTERFACE} component={Interface} />
                            <PrivateRoute exact path={REPORT} component={Reports} />
                            <PrivateRoute exact path={HELP} component={Help} />
                            <Route exact path={LOGIN} component={Login} />
                            {/* <Route exact path={SETTINGS_OBJECT} component={Content} /> */}
                            <PrivateRoute path='*' component={NotFound} />
                        </Switch>
                    </App>
                </ErrorBoundary>
            </Provider>
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("app")
)
import React from 'react'


const Plus = ({ title = 'Добавить', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 4.233">
                <title>{ title }</title>
                <path d="M2.115-.001a.14.14 0 00-.141.141v1.835H.14a.14.14 0 100 .282h1.834v1.834a.14.14 0 10.282 0V2.257H4.09a.141.141 0 100-.282H2.256V.14a.14.14 0 00-.141-.141z" data-name="Group 4108" strokeWidth=".938" fill="#0a0a0a" /></svg>
        </i>
    )
}

export default Plus
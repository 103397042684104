import React from 'react'
import {connect} from 'react-redux'

import {OBJECT_OBJECT} from '../../../../store/Settings/sets'
import {updateOptions} from '../../../../store/Settings/actions'
import {loadSettings, saveSettings, loadGroups} from '../../../../store/Settings/actions/sets/Object'

import ObjectObject from './ObjectObject'


const ObjectObjectContainer = props => (
    <ObjectObject
        className={props.className}
        objects={props.objects}
        settings={props.settings}
        stateKey={props.stateKey}
        loadSettings={props.loadSettings}
        saveSettings={props.saveSettings}
        groupsList={props.groupsList}
        statusList={props.statusList}
        selectedObjects={props.selectedObjects}
        selectedGroups={props.selectedGroups}
        updateOptions={props.updateOptions}
    />
)

const stateToProps = (state, { stateKey }) => {
    let settings = state.settings.settings[OBJECT_OBJECT] || {}
    return {
        settings: settings[stateKey],
        groupsList: state.objectsGroup.groupsList,
        statusList: [{title: 'Не выставлять', value: null},...state.objects.statusList.map(st => { return {title: st.name, value: st.id}})],
        selectedGroups: state.objectsGroup.selected,
        selectedObjects: state.objects.selected,
        objects: state.objects.allObjectsAssociated,
    }
}

const actionsToProps = {
    loadSettings,
    saveSettings,
    updateOptions,
}

export default connect(stateToProps, actionsToProps)(ObjectObjectContainer)
export function getCommonItems(arrays = []) {
    let commonItems = [],
        totalIndex = 0,
        countObj = {}

    arrays.map((arr, index) => {
        const arrIndex = (index+1)*9999
        
        arr.map((item) => {
            if(!countObj[item]) countObj[item] = {}

            if(countObj[item][arrIndex] !== true) {
                countObj[item][arrIndex] = true
                if(!countObj[item].count) countObj[item].count = 0
                countObj[item].count += arrIndex
            }
        })

        totalIndex += arrIndex
    })

    for(let item in countObj) {
        if(countObj[item].count === totalIndex) {
            commonItems.push(item)
        }
    }

    return commonItems
}
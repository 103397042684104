import React from 'react'
import { connect } from 'react-redux'

import { generateStateKey } from '../../../../utilities/generateStateKey'

import { loadClips, deleteClips, putClips } from '../../../../store/Settings/actions/components/Clips'
import { openModal, closeModal } from '../../../../store/Modal/actions'

import SettingsClips from './Clips'


const SettingsClipsContainer = props => (
    <SettingsClips
        clips={props.clips}
        className={props.className}
        indentIds={props.indentIds}
        objectIds={props.objectIds}
        objects={props.objects}
        loadClips={props.loadClips}
        putClips={props.putClips}
        deleteClips={props.deleteClips}
        openModal={props.openModal}
        closeModal={props.closeModal}
        toggleOpened={props.toggleOpened}
        hideEnded={props.hideEnded}
    />
)

const stateToProps = (state, props) => ({
    clips: getClips(state.settings.clips, props.indentIds, props.objectIds, state.objects.trackObjects),
    objects: state.objects.allObjectsAssociated,
    hideEnded: state.tracks.hideEnded

})

const actionsToProps = {
    loadClips, deleteClips, openModal, closeModal, putClips
}

export default connect(stateToProps, actionsToProps)(SettingsClipsContainer)


//
// Функции
//

function getClips(clips, indentIds, objectIds, trackObjects) {

    if (!indentIds || !indentIds.length) return []
    if (!objectIds || !objectIds.length) return []



    let cls = {}
    let result = []
    objectIds.map(id => {
        let clipsNew = []
        indentIds.map((indentId, index, arr) => {
            let currentObjIndentArray = clips[generateStateKey([indentId, id])] || []
            currentObjIndentArray.map(clip => {
                let found = clipsNew.find(cl => cl.id2 === clip.id2)
                if (!found) {
                    if (typeof clip.indent_ids === 'undefined') {
                        clip.indent_ids = []
                    }
                    if (!clip.indent_ids.includes(indentId)) {
                        clip.indent_ids.push(indentId)
                    }
                    clipsNew.push(clip)
                } else {
                    if (!found.indent_ids.includes(indentId)) {
                        found.indent_ids.push(indentId)
                    }
                }
            })
        })
        // cls.push({
        //     id,
        //     clips: clipsNew
        // })

        cls[id] = {
            id,
            clips: clipsNew
        }
    }
    )
    //console.log(cls)

    trackObjects.forEach(element => {
        if (cls[element.id]) {
            result.push(cls[element.id])
        }
    });

    return result
}
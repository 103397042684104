import {takeEvery, put, select} from 'redux-saga/effects'
import {updateOptions} from '../../../store/Settings/actions'
import {RESET_PRESET} from '../../../store/Settings/actions/components/Presets'
import {showAlertError} from '../../../store/Alert/actions'


export default function* () {
    yield takeEvery(RESET_PRESET, worker)
}

function* worker({payload}) {
    let {type, stateKey} = payload
    let preset = {
        daymask: 127,
        block: '',
        //channel: 0,
        fromtime: '',
        totime: '',
        steptime: '',
        __inday: false,
        __inhour: 0
    }
    yield put(updateOptions({
        type,
        stateKey,
        settings: preset
    }))
}
import React from 'react'


const Calendar = ({ title = 'Календарь', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 4.233">
                <title>{ title }</title>
                <g data-name="Group 4160"><g transform="translate(.007 .465) scale(.23519)" data-name="Rectangle 144" fill="none" stroke="none" strokeWidth="1.125"><rect rx="2" height="16" width="18" /><path d="M4.5-2c-.277 0-.5.223-.5.5v1.439H2a2.07 2.07 0 00-2.063 2.06v12c0 1.134.93 2.064 2.063 2.064h14c1.132 0 2.063-.93 2.063-2.063V2A2.07 2.07 0 0016-.062h-2v-1.44a.499.499 0 00-.5-.498.497.497 0 00-.5.499v1.439H5v-1.44a.497.497 0 00-.499-.498zM2 1.064h2V2.5c0 .277.223.5.5.5A.497.497 0 005 2.5V1.063H13V2.5a.499.499 0 101 0V1.063H16c.529 0 .938.407.938.936v3.002H1.062V1.999c0-.529.409-.936.938-.936zM1.062 6h15.876v8c0 .529-.41.938-.938.938H2A.924.924 0 011.062 14V6z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" /></g></g></svg>
        </i>
    )
}

export default Calendar
export default function({
    settings = {},
    name,
    onChange,
    stateKey,
    disabled,
}) {

    const hClick = (e) => {
        const check = e.target

        if(check && check.tagName === "INPUT") {
            onChange({ value: check.checked })
        }
    }

    return [
        {
            type: "Check",
            data: {
                inline: true,
                type: "checkbox",
                label: "Через день",
                id: `group-5-check-${stateKey}-1`,
                onClick: hClick,
                checked: settings[name],
                disabled,
            }
        }
    ]
}
import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_TEMPLATES, loading, putTemplates } from '../../store/Templates/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_TEMPLATES, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        const templates = yield call(fetchTemplates)
        if (templates.error && templates.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_TEMPLATES}.`, templates],
                text: "Шаблоны не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putTemplates([]))
        } else {
            yield put(putTemplates({ templates: templates.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_TEMPLATES}.`, e],
            text: "Шаблоны не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putTemplates([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchTemplates() {
    return axios.get("/ether/template/select")
        .then(response => response.data)
}

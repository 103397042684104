import {takeEvery, put} from 'redux-saga/effects'
import {TOGGLE_SELECTED_TAGS} from '../../store/ObjectsByTags/actions'
import {updateSelectedByGroupsAndTags} from '../../store/Objects/actions'

export default function* () {
    yield takeEvery(TOGGLE_SELECTED_TAGS, worker)
}

function* worker({ payload }) {
    yield put(updateSelectedByGroupsAndTags({
        type: "tags",
        id: payload.tagName
    }))
}
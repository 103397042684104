import React from 'react'


const FileM = ({ title = '', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.05 4.234">
                <title>{ title }</title>
                <g strokeWidth="1.097"><path d="M.424 0A.43.43 0 000 .426v3.382a.43.43 0 00.424.426h2.201a.43.43 0 00.425-.426V1.165l-.097-.097L2.76.874 1.986.098A.132.132 0 001.98.092a.132.132 0 00-.004-.003L1.888 0H.425zm.001.265H1.76v.602c0 .234.192.426.425.427h.6v2.513a.16.16 0 01-.16.162h-2.2a.16.16 0 01-.161-.162V.427c0-.091.07-.161.161-.162zm1.599.246l.248.249.269.269h-.355a.16.16 0 01-.162-.162zM.811 1.678a.047.047 0 00-.035.014.051.051 0 00-.011.034v1.258c0 .013.004.024.013.033.01.01.02.014.033.014h.166a.04.04 0 00.032-.014.045.045 0 00.014-.033v-.831l.268.51c.017.028.039.043.066.043h.08c.029 0 .05-.015.064-.043l.268-.51v.831c0 .013.005.024.014.033.009.01.02.014.032.014h.166a.051.051 0 00.035-.012.047.047 0 00.013-.035V1.726a.047.047 0 00-.013-.034.047.047 0 00-.035-.014h-.154c-.024 0-.044.012-.058.037l-.372.691-.372-.691c-.014-.025-.033-.037-.058-.037z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" stroke="none" data-name="Group 2900" strokeMiterlimit="10" /></g></svg>
        </i>
    )
}

export default FileM
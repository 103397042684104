import React from 'react'
import {connect} from 'react-redux'
import {Button as ButtonBS} from 'react-bootstrap'
import {MEDIA_BREAKPOINT_LG} from '../../../../utilities/breakpoints'
import {normalizeNumberSpaces} from '../../../../utilities/normalize'

import {saveAdsExtra} from '../../../../store/Modal/types/AdsEdit/actions'

import Header from '../../components/header'

import Uploader from '../../../Uploader'
import Select from '../../../Form/Select'
import InputText from '../../../Form/InputText'
import {loadVideolists} from "../../../../store/ContentVideolists/actions";


class AdsEditExtra extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sumbitDisabled: false,
            ...this.props.adsData
        }

        this.hSubmit = this.hSubmit.bind(this)
        this.hLoadfile = this.hLoadfile.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.validateForm = this.validateFrom.bind(this)
        this.allowedTypes = [".mp3"]
    }

    hLoadfile(file) {
        this.setState(() => ({
            filename: file,
            //name: file.name,
            origname: file.name,
            size: file.size,
            cliptime: "—",
        }), this.validateFrom)
    }

    hInputText({name, value}) {
        if (name) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
    }

    hSubmit() {
        console.log("HEAR");
        this.props.saveAdsExtra({
            id: this.state.id,
            dataToSave: {
                filename: this.state.filename,
                videolist_id: this.state.videolist_id,
                name: this.state.name
            }
        })
    }

    validateFrom() {
        if (true
            && (typeof this.state.name === "string" && this.state.name.length > 1)
        ) {
            this.setState({sumbitDisabled: false})
        } else {
            this.setState({sumbitDisabled: true})
        }
    }

    componentDidMount() {
        this.props.loadVideolists()
    }

    render() {
        return (
            <div className="ModalAdsEdit">
                <Header
                    title="Редактирование экстренного ролика"
                    toggleOpened={this.props.toggleOpened}
                />

                <div className="ModalDoubleGrid">
                    <div className="ModalDoubleGrid__left">
                        <div className="ModalDoubleGrid__title">
                            Настройка
                        </div>

                        <div className="ModalAdsCreate__grid">
                            <InputText
                                label="Название"
                                comment="* Внимание! Если имя файла не задано, звук будет импортирован из выбранного по-умолчанию"
                                attrs={{
                                    placeholder: "Название ролика",
                                    value: this.state.name,
                                    name: "name"
                                }}
                                onChange={this.hInputText}
                            />

                            <Select
                                label="Привязать фоновый контент"
                                type="options"
                                onChange={this.hInputText}
                                selected={this.state.videolist_id}
                                value={this.state.videolist_id}
                                name="videolist_id"
                                placeholder="Не привязывать"
                                options={this.props.allVideolists}
                            />
                        </div>
                        {window.outerWidth > MEDIA_BREAKPOINT_LG &&
                        <Uploader
                            label="Замена файла"
                            attrs={{
                                name: "filename",
                                accept: this.allowedTypes.join(',')
                            }}
                            trackName={this.state.origname}
                            fileTypes={this.allowedTypes}
                            onChange={this.hLoadfile}
                        />
                        }

                    </div>

                    <div className="ModalDoubleGrid__right">
                        <div className="ModalDoubleGrid__title">
                            Информация
                        </div>

                        {window.outerWidth <= MEDIA_BREAKPOINT_LG &&
                        <Uploader
                            label="Замена файла"
                            attrs={{
                                name: "filename",
                                accept: ".mp3,.mp4"
                            }}
                            trackName={this.state.origname}
                            fileTypes={[".mp3", ".mp4"]}
                            onChange={this.hLoadfile}
                        />
                        }

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Оригинальное имя файла</div>
                            <div className="info">
                                {this.state.origname}
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Время звучания</div>
                            <div className="info">
                                {normalizeThisTime(this.state.cliptime)}
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Последняя модификация</div>
                            <div className="info">
                                {normalizeThisTime(this.state.mtime)}
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Размер в килобайтах</div>
                            <div className="info">
                                {Math.ceil(this.state.size / 1000).toLocaleString()} КБ
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Время создания</div>
                            <div className="info">
                                {normalizeThisTime(this.state.ctime)}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="Modal__footer">
                    <ButtonBS variant="primary" onClick={this.hSubmit}
                              disabled={this.state.sumbitDisabled || this.props.loading}>
                        {this.props.loading ? "Сохранение..." : "Сохранить"}
                    </ButtonBS>
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
    adsData: {...state.modalAdsEdit.adsData},
    loading: state.modalAdsEdit.loading,
    allVideolists: [{title: "Не привязывать", value: 'NULL'}, ...state.videolists.allVideolists.map((videolist) => ({title: videolist.name, value: videolist.id, subtitle: `${videolist.totalcount} шт`}))],

})

const actionsToProps = {
    saveAdsExtra, loadVideolists
}

export default connect(stateToProps, actionsToProps)(AdsEditExtra)

function normalizeThisTime(time) {
    if (typeof time !== "string") return ""
    return time.split(".")[0]
}

function normalizeSize(size) {
    size = normalizeNumberSpaces(size).split(" ")
    if (size.length >= 2) return `${size[0]} ${size[1]} КБ`
    if (size.length === 1 && size[0].length > 2) return `${size[0]} КБ`
    return "—"
}
import React, { useState } from 'react'

import SvgClose from '../../SVG/Close'


const Video = ({
    className = "",
    video = {},
    toggleOpened,
}) => {
    console.log(video)
    const [playing, setPlaying] = useState(true)

    return (
        <div className={ `ModalVideo ${className}` }>

            <div
                className={ `ModalVideo__wrap ${playing ? "playing" : ""}` }
                style={ { backgroundImage: `url(${video.poster})` } }
            >
                <div className={ `ModalVideo__header ${playing ? "" : "active"}` }>
                    <div className="title">
                        { video.title }
                    </div>
                    <div className="close" onClick={ toggleOpened }>
                        <SvgClose variant="light" size="sm3" />
                    </div>
                </div>
                { video.mp4 ?


                    <video
                        onPlaying={ () => setPlaying(true) }
                        controls="controls"
                        controlsList="nodownload"
                        preload="metadata"
                        poster="/assets/img/video-poster-transparent.png"
                        autoplay="autoplay"
                    >
                        { (video.ogv && video.ogv !== "") &&
                            <source src={ video.ogv } type='video/ogg; codecs="theora, vorbis"' />
                        }
                        { (video.mp4 && video.mp4 !== "") &&
                            <source src={ video.mp4 } type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                        }
                        { (video.webm && video.webm !== "") &&
                            <source src={ video.webm } type='video/webm; codecs="vp8, vorbis"' />
                        }
                    </video>
                    :
                    <img src={ video.image } width="100%"></img>
                }
            </div>

        </div>
    )
}

export default Video
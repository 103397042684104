import React, { useState, useEffect } from 'react'
import Spinner from '../../../../../components/Spinner'
import { connect } from 'react-redux'
import { loadObjectsState, sortObjectsState, setSortDirection, toggleSelectedObjectState } from '../../../../../store/Reports/actions'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'
import ObjectsStateItem from './item/index'
import List from '../../../../../components/List'
import { toggleSelectedObject } from '../../../../../store/Objects/actions'

const ObjectsState = ({
    loadObjectsState,
    objects_state,
    sortObjectsState,
    loading,
    toggleSelectedObject,
    toggleSelectedObjectState,
    selected
}) => {
    useEffect(() => {
        loadObjectsState()
    }, [])
    const [sort_direction, setSortDirection] = useState("")
    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortObjectsState({ direction: sort_direction, sort_type: type })
    }
    const hToggleSelected = (e) => {
        const object = e.target.closest(".List__item")
        if (object) {
            const objectId = Number(object.dataset.listItemId)
            if (!isNaN(objectId)) {
                toggleSelectedObject({ id: objectId })
                toggleSelectedObjectState({
                    id: objectId,

                })
                if (document.querySelector(`div[data-object-id="${objectId}"]`) && document.querySelector(`div[data-object-id="${objectId}"]`) !== null) {
                    document.querySelector(`div[data-object-id="${objectId}"]`).scrollIntoView({ block: "start", behavior: "smooth" });
                }

            }
        }
    }
    const getItems = () => {
        return objects_state.map((object_state, index) => ({
            id: object_state.id,
            name: object_state.name,
            element: <ObjectsStateItem                                
                key={object_state.id}
                id={object_state.id}
                name={object_state.name}
                lastsyncsystem={object_state.lastsyncsystem}
                sync_time={object_state.lastsync}
                sync_start_time={object_state.startsync}
                ip={object_state.lip}
                error={object_state.lasterror}
                address={object_state.address}
                variant={(index % 2 == 0) ? "gray" : ""}
                mute={object_state.mute}
            />
        }))
    }
    return (
        <div>
            {!loading ?
                <div className="ObjectsState">
                    <div className="ObjectsState">
                        <header className="ObjectsState__control">
                            <div className="ObjectsState__grid">
                                <div className="id" >
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={() => sort("id")}
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>ID</span>
                                    </button>
                                </div>
                                <div className="state">
                                    {/* <img src={ connectionok }></img> */}
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={() => sort("sync_time")}
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>Статус</span>
                                    </button>
                                </div>
                                <div className="name">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={() => sort("name")}
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>Объект</span>
                                    </button>
                                </div>
                                <div className="sync_time">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={() => sort("sync_time")}
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>Синхронизирован</span>
                                    </button>
                                </div>
                                <div className="sync_start_time">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={() => sort("sync_start_time")}
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>Время начала</span>
                                    </button>
                                </div>
                                <div className="ip">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={() => sort("ip")}
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>IP 1</span>
                                    </button>
                                </div>
                                <div className="error">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={() => sort("error")}
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>Ошибки</span>
                                    </button>
                                </div>
                            </div>
                        </header>
                    </div>
                    {Object.values(objects_state).length > 0 &&
                        <div onClick={(e) => hToggleSelected(e)} style={{ cursor: "pointer" }}>
                            <List className="Report_List"
                                type="simple"
                                scroll={{
                                    on: true,
                                    data: {
                                        variant: "white",
                                        size: "sm",
                                    }
                                }}

                                content={{ items: getItems(), variant: "none" }}
                            />
                        </div>
                    }
                </div>
                : <Spinner />
            }

        </div>
    )
}
const stateToProps = state => ({
    objects_state: state.report.objects_state,
    loading: state.report.loading,
    selected: state.report.selected
})
const actionsToProps = {
    loadObjectsState,
    sortObjectsState,
    toggleSelectedObjectState,
    toggleSelectedObject
}

export default connect(stateToProps, actionsToProps)(ObjectsState)
export default [
    {title: 0, value: 0},
    {title: 1, value: 1},
    {title: 2, value: 2},
    {title: 3, value: 3},
    {title: 4, value: 4},
    {title: 5, value: 5},
    {title: 6, value: 6},
    {title: 7, value: 7},
    {title: 8, value: 8},
    {title: 9, value: 9},
    
    {title: 10, value: 10},
    {title: 11, value: 11},
    {title: 12, value: 12},
    {title: 13, value: 13},
    {title: 14, value: 14},
    {title: 15, value: 15},
    {title: 16, value: 16},
    {title: 17, value: 17},
    {title: 18, value: 18},
    {title: 19, value: 19},

    {title: 20, value: 20},
    {title: 21, value: 21},
    {title: 22, value: 22},
    {title: 23, value: 23},
    {title: 24, value: 24},
    {title: 25, value: 25},
    {title: 26, value: 26},
    {title: 27, value: 27},
    {title: 28, value: 28},
    {title: 29, value: 29},

    {title: 30, value: 30},
    {title: 31, value: 31},
    {title: 32, value: 32},
    {title: 33, value: 33},
    {title: 34, value: 34},
    {title: 35, value: 35},
    {title: 36, value: 36},
    {title: 37, value: 37},
    {title: 38, value: 38},
    {title: 39, value: 39},

    {title: 40, value: 40},
    {title: 41, value: 41},
    {title: 42, value: 42},
    {title: 43, value: 43},
    {title: 44, value: 44},
    {title: 45, value: 45},
    {title: 46, value: 46},
    {title: 47, value: 47},
    {title: 48, value: 48},
    {title: 49, value: 49},

    {title: 50, value: 50},
    {title: 51, value: 51},
    {title: 52, value: 52},
    {title: 53, value: 53},
    {title: 54, value: 54},
    {title: 55, value: 55},
    {title: 56, value: 56},
    {title: 57, value: 57},
    {title: 58, value: 58},
    {title: 59, value: 59},

    {title: 60, value: 60},
    {title: 61, value: 61},
    {title: 62, value: 62},
    {title: 63, value: 63},
    {title: 64, value: 64},
    {title: 65, value: 65},
    {title: 66, value: 66},
    {title: 67, value: 67},
    {title: 68, value: 68},
    {title: 69, value: 69},

    {title: 70, value: 70},
    {title: 71, value: 71},
    {title: 72, value: 72},
    {title: 73, value: 73},
    {title: 74, value: 74},
    {title: 75, value: 75},
    {title: 76, value: 76},
    {title: 77, value: 77},
    {title: 78, value: 78},
    {title: 79, value: 79},

    {title: 80, value: 80},
    {title: 81, value: 81},
    {title: 82, value: 82},
    {title: 83, value: 83},
    {title: 84, value: 84},
    {title: 85, value: 85},
    {title: 86, value: 86},
    {title: 87, value: 87},
    {title: 88, value: 88},
    {title: 89, value: 89},

    {title: 90, value: 90},
    {title: 91, value: 91},
    {title: 92, value: 92},
    {title: 93, value: 93},
    {title: 94, value: 94},
    {title: 95, value: 95},
    {title: 96, value: 96},
    {title: 97, value: 97},
    {title: 98, value: 98},
    {title: 99, value: 99},

    {title: 100, value: 100},
]
import { takeEvery, select, put, all } from 'redux-saga/effects'
import { updateOptions } from '../../../store/Settings/actions'
import { LOAD_SETTINGS } from '../../../store/Settings/actions/sets/Object'
import { getCommonItems } from '../../../utilities/getCommonItems'
import { parseTags } from '../../../utilities/parseTags'


export default function* () {
    yield takeEvery(LOAD_SETTINGS, worker)
}

function* worker({ payload }) {
    const {
        type,
        fields = [],
        stateKey,
    } = payload

    try {
        const selectedObjects = yield select(state => state.objects.selected)

        if (selectedObjects.length) {
            //let settingsIsExist = false

            // if (selectedObjects.length === 1) {
            //     const settingsFromBuffer = yield select(state => state.settings.settings[type][stateKey] || {})
            //     let allFieldsIsExist = true
            //     fields.map(field => {
            //         if (typeof settingsFromBuffer[field] === 'undefined') {
            //             allFieldsIsExist = false
            //         }
            //     })
            //     settingsIsExist = allFieldsIsExist
            // }

            if (true) {
                const settings = selectedObjects.length > 1 ? {} : yield select(state => state.objects.allObjectsAssociated[selectedObjects[0]])
                let nextSettings = {}

                // Собираем обычные поля
                fields.map(name => {
                    if (name !== "tags") {
                        nextSettings[name] = settings[name] || getDefaultSettings(name)
                    }
                })

                // Обрабатываем tags
                if (fields.indexOf("tags") !== -1) {
                    let objectsTags = [],
                        objectsTagsState = yield all(
                            selectedObjects.map(id =>
                                select(state => state.objects.allObjectsAssociated[id]["tags"])
                            )
                        )

                    objectsTagsState.map(tags => {
                        if (Array.isArray(tags)) {
                            objectsTags.push(parseTags(tags))
                        }
                    })

                    objectsTags = getCommonItems(objectsTags)
                    objectsTags = objectsTags.join(",")

                    nextSettings["tags"] = objectsTags
                    nextSettings["__savedTags"] = objectsTags
                }

                nextSettings.__settingsLoaded = true

                yield put(updateOptions({
                    type,
                    stateKey,
                    noChanged: true,
                    settings: nextSettings
                }))
            }
        }
    }

    catch (e) {
        console.log(e)
    }

}

function getDefaultSettings(name) {
    const sets = {
        "msgroup_id": "",
        "msstatus_id": null,
        "name": "",
        "alias": "",
        "address": "",
        "description": "",
        "chmask": 0,
        "isbackon": false,
        "bpalg": false,
        "msync": false,
        "autoupdate": false,
        "ftpactive": false,
        "tz": "",
        "ntpserver": "",
        "warntraffic": "",
        "maxtraffic": "",

        "minitstr": "",
        "mphone": "",
        "mname": "",
        "mpass": "",
        "timeout": "",
        "synctime": "",
        "minsynctim": "",
        "maxsynctim": "",
        "maxtime": "",
        "syncmute": false,
        "waitbgvideo": false,

        "pppoename": "",
        "pppoepass": "",
        "lip": "",
        "gateway": "",
        "dns": "",
        "linterval": "",
        "pausealg": 0,
        "dummyad": "",

        "notifyblock": ''
    }
    return sets[name]
}
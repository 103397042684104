import {takeEvery, call, put, takeLeading} from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOGIN, doLogin, Login, DO_LOGIN, loading } from '../../store/Login/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import {didMount} from "../../store/App/actions";
import { loadPvData } from '../../store/Features/actions'

export default function* () {
    yield takeLeading(DO_LOGIN, worker)
}

function* worker({ payload }) {
    try {
        yield put(loading(true))
        const auth = yield call(fetchAuth, payload)
        if (auth.error) {
            yield put(Login({ isAuthorized: false, error: auth.error }))
            yield put(loading(false))
        } else {
            if (auth.success.a2 && !payload.code) {
                yield put(Login({ isAuthorized: false, error: '', a2: true }))
                yield put(loading(false))
                if(!auth.success.emailCodeSend) {
                    yield put(showAlertError({
                        errors: [`Ошибка ДФА`],
                        text: "Дополнительный код не был отправлен, обратитесь в техподдержку."
                    }))
                }
                return;
            }
            if ((auth.success.a2 && payload.code && auth.success.a2ok) || !auth.success.a2) {
                window.localStorage.setItem("sessionToken", auth.success.token)
                if (auth.success.cpanel) {
                    window.localStorage.setItem("sessionLink", auth.success.cpanel.link)
                } else {
                    window.localStorage.removeItem("sessionLink")
                }
                axios.defaults.headers.common["token"] = auth.success.token
                yield put(didMount());
                yield put(loadPvData());
            }
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DO_LOGIN}.`, e],
            text: "Авторизация не пройдена. Попробуйте заново."
        }))
    }
}

function fetchAuth(data) {
    const params = {
        name: data.login,
        password: data.password
    }
    if (data.code) {
        params.code = data.code
    }
    const postData = {
        name: data.login,
        password: data.password
    }
    return axios.post(`/account/login`,
        {
            ...data
        }, {
            params
        })
        .then(response => response.data)
}
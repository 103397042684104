export function buildGETUrl(resource = "", objectOfParams, gate = "https://a.ms-box.ru/", empty) {
// export function buildGETUrl(resource = "", objectOfParams, gate = "/", empty) {
    if (typeof resource !== "string") return false
    if (objectOfParams !== empty && typeof objectOfParams !== "object") return false

    let token = window.localStorage.getItem("sessionToken")
    token = token !== null ? "token=" + token : ""

    if (objectOfParams === empty) {
        return `${gate}${resource}?${token}`
        //return `${gate}${resource}`
    } else {
        let buffer = []
        Object.keys(objectOfParams).forEach(key => {
            buffer.push(encodeURI(key) + '=' + encodeURI(objectOfParams[key]))
        })

        buffer = buffer.join('&')
        return `${gate}${resource}?${buffer}&${token}`
        // return `${gate}${resource}?${buffer}`
    }
}

//~ buildGETUrl("clip/select", {id: 222, name: "Fedor"}) => http://a.ms-box.ru/clip/select?id=222&name=Fedor

//~ buildGETUrl("clip/select") => http://a.ms-box.ru/clip/select

//~ buildGETUrl() => false
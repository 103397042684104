import React from 'react'
import { Link } from 'react-router-dom'

import { SETTINGS_TEMPLATES, SETTINGS_NOTIFICATIONS, SETTINGS_ROLES, SETTINGS_INTERFACE, SETTINGS_FEATURES_OF_THE_ETHER } from '../../../../routes'


const Menu = ({ page }) => {
    let pathname = document.location.pathname

    return (
        <section className="Settings__Menu">
            <Link to={ SETTINGS_TEMPLATES } className='link' >Шаблоны</Link>
            <Link to={ SETTINGS_NOTIFICATIONS } className='link' style={ { color: "#e60017" } }>Уведомления</Link>
            <Link to={ SETTINGS_ROLES } className='link'>Роли пользователей</Link>
            <Link to={ SETTINGS_INTERFACE } className='link'>Интерфейс</Link>
            <Link to={ SETTINGS_FEATURES_OF_THE_ETHER } className='link'>Особенности эфира</Link>

        </section>
    )
}

export default Menu
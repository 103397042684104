import { takeEvery, call, put, select } from 'redux-saga/effects'

import { LOAD_PV_DATA, savePvData } from '../../store/Features/actions';
import { showAlertError } from '../../store/Alert/actions';
import axios from 'axios';

export default function* () {
    yield takeEvery(LOAD_PV_DATA, worker)
}

function* worker({ payload }) {

    try {

        const response = yield call(fetchPvdata)

        if (response.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_PV_DATA}.`, response],
                text: "Настройки не загружены. Попробуйте повторить."
            }))

        } else {
            const { note, mute } = response.success[0]

            yield put(savePvData({ note: note || '', mute }))

        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_PV_DATA}.`, e],
            text: "Настройки не загружен. Попробуйте повторить."
        }))
    } finally {
        // yield put(loading(false))
    }
}

function fetchPvdata() {
    return axios.get("/pvdata/select")
        .then(response => response.data)
}
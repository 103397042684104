import React from 'react'

import Space from '../../../components/SpaceContent'

import What from './What'
import Where from './Where'



const Workspace = ({ page }) => {
    return (
        <>
            <Space>
                <What page={ page } />
                <Where />
                {/* <When className="not-expanded" /> */ }
            </Space>
        </>
    )
}

export default Workspace
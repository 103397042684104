import numbers from '../numbers'


export default function({
    settings = {},
    name,
    onChange,
}) {

    return [
        {
            type: "Select",
            data: {
                type: "options",
                selected: settings[name],
                onChange,
                placeholder: "Выберите из списка",
                options: numbers
            }
        }
    ]
}
import React from 'react'
import { connect } from 'react-redux'

import { loadPeriods, copyPeriod, deletePeriod } from '../../../../store/Settings/actions/components/Periods'
import { openModal, closeModal } from '../../../../store/Modal/actions'

import Periods from './Periods'


const PeriodsContainer = props => (
    <Periods
        type={props.type}
        className={props.className}
        name={props.name}
        selected={props.selected}
        onChange={props.onChange}
        showCopied={props.showCopied}

        periods={props.periods}
        loading={props.loading}

        loadPeriods={props.loadPeriods}
        copyPeriod={props.copyPeriod}
        deletePeriod={props.deletePeriod}
        openModal={props.openModal}
        closeModal={props.closeModal}
    />
)

const stateToProps = (state, props) => ({
    periods: state.settings.periods[props.type]?.periods,
    loading: state.settings.periods[props.type]?.loading,
})

const actionsToProps = {
    loadPeriods,
    copyPeriod,
    deletePeriod,
    openModal,
    closeModal,
}

export default connect(stateToProps, actionsToProps)(PeriodsContainer)
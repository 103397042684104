import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS } from 'react-bootstrap'
import { Form } from "react-bootstrap"
import { normalizeNumberSpaces } from '../../../utilities/normalize'

import { update, editVideolist } from '../../../store/Modal/types/VideolistCreate/actions'

import Header from '../components/header'

import Uploader from '../../Uploader'
import InputText from '../../Form/InputText'
import InputTextarea from '../../Form/InputTextarea'
import Switch from '../../Form/Switch'
import Select from '../../Form/Select'
// import Checkbox from '../../Form?'


class VideolistEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: this.props.currentVideolist.name,
            description: this.props.currentVideolist.description,
            sumbitDisabled: true,
            checked: this.props.currentVideolist.isdefault
        }
        this.hSubmit = this.hSubmit.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.hCheck = this.hCheck.bind(this)
        this.validateForm = this.validateFrom.bind(this)
    }

    hInputText({ name, value }, event) {
        if (name && value) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
    }
    hCheck(event) {
        this.setState({ checked: !this.state.checked })
        this.validateFrom()
    }

    hSubmit() {
        this.props.editVideolist({
            name: this.state.name,
            description: this.state.description || "",
            id_videolist: this.props.currentVideolist.id,
            isdefault: this.state.checked

        })
    }

    validateFrom() {
        if (this.state.name
            && (typeof this.state.name === "string" && this.state.name.length > 1)
            // && (typeof this.state.description === "string" && this.state.description.length > 3)
        ) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
    }

    componentDidMount() {
        this.props.update()
    }

    render() {
        return (
            <div className="ModalOneInput">
                <Header
                    title="Редактирование видео списка"
                    toggleOpened={ this.props.toggleOpened }
                />
                <InputText
                    label="Название"

                    attrs={ {
                        name: "name",
                        value: this.props.currentVideolist.name
                    } }
                    onChange={ this.hInputText }
                />
                <div style={ { marginTop: 30 } }>
                    <InputText
                        label="Описание"
                        attrs={ {
                            name: "description",
                            value: this.props.currentVideolist.description
                        } }
                        onChange={ this.hInputText }
                    />
                </div>

                <div style={ { marginTop: 30 } }>
                    <Form.Check
                        inline
                        name="default_for_objects"
                        type="checkbox"
                        id={ "default_for_objects" }
                        defaultChecked={ this.state.checked }
                        onChange={ this.hCheck }
                        label={ <>
                            <span className="circle"></span>
                            Используется на всех объектах по умолчанию
                        </> }
                    />

                </div>

                <div className="Modal__footer">
                    <ButtonBS onClick={ this.hSubmit } variant="primary" disabled={ this.state.sumbitDisabled || this.props.loading }>
                        { this.props.loading ? "Сохранение..." : "Сохранить" }
                    </ButtonBS>
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
    loading: state.modalVideolistCreate.loading,
    currentVideolist: state.videolists.currentVideolist
})

const actionsToProps = {
    update, editVideolist
}



export default connect(stateToProps, actionsToProps)(VideolistEdit)
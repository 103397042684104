import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../../utilities/generateStateKey'
import {cl} from '../../../../../utilities/cl'

import {OBJECT_NET} from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'
import ScrollContainer from '../../../../../components/ScrollContainer'


const SettingsNetCollapsible = ({
    className = "",
    selectedObjects,
    placeNumber,
    active
}) => {

    const stateKey = generateStateKey([ selectedObjects ])

    const hSave = () => {
        const collapsible = document.querySelector(".SettingsObjectNetCollapsible")
        const buttonSave = collapsible.querySelector(".SettingsObjectNetSave button")
        if(buttonSave) {
            buttonSave.click()
        }
    }

    className = cl(
        className,
        "SettingsObjectNetCollapsible"
    )

    return(
        <Collapsible
            active={active}
            id={233}
            className={className}
            title="Проводная сеть"
            placeNumber={placeNumber}
            buttons={{
                save: {
                    on: true,
                    mobile: false,
                    onClick: hSave
                }
            }}
        >
            <Settings
                set={OBJECT_NET}
                data={{
                    objectIds: selectedObjects,
                    stateKey: stateKey,
                }}
            />
        </Collapsible>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
})

export default connect(stateToProps, null)(SettingsNetCollapsible)
import React from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../utilities/cl'


const ButtonWhite = ({
    className = "",
    mode = "",
    title = "",
    disabled,
    onClick,
    value,
    children
}) => {
    
    function hClick(value) {
        if(!disabled) {
            if(typeof onClick === "function") {
                onClick(value)
            }
        }
    }

    className = cl(
        "ButtonWhite",
        `ButtonWhite_${mode}`,
        className,
    )

    return (
        <button
            className={className}
            disabled={disabled && true}
            onClick={() => hClick(value)}
        >
            {children}
            <span>{title}</span>
        </button>
    )
}

ButtonWhite.propTypes = {
    className: PropTypes.string,
    mode: PropTypes.oneOf(["extend"]),
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.any
}

export default ButtonWhite
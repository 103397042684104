import React from 'react'

import Space from '../../../components/Space'

import What from './What'
import Where from './Where'
import When from './When'


const Workspace = ({ page }) => {
    return (
        <>
            <Space>
                <What />
                <Where />
                <When />
            </Space>
        </>
    )
}

export default Workspace
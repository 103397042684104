import React from 'react'
import { connect } from 'react-redux'

import Place from './Place'


const PlaceContainer = props => (
    <Place
        className={ props.className }
        number={ props.number }
        expandedPlace={ props.expandedPlace }
        activePlace={ props.activePlace }
        wereActive={ props.wereActive }
        disabled={ props.disabled }
        children={ props.children }
        style={ props.style }
    />
)

const stateToProps = state => ({
    expandedPlace: state.panel.expandedPlace,
    activePlace: state.panel.activePlace,
    wereActive: state.panel.wereActive
})

export default connect(stateToProps, null)(PlaceContainer)
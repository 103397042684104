export const LOAD_TZ = "Marks/LOAD_TZ"
export const PUT_TZ  = "Marks/PUT_TZ"


export const loadTz = () => ({
    type: LOAD_TZ,
})

export const putTz = ({ tz }) => ({
    type: PUT_TZ,
    payload: {
        tz
    }
})
import React from 'react'
import PropTypes from 'prop-types'
import {Form} from 'react-bootstrap'

import {arraysHasDifferences} from '../../../utilities/arraysHasDifferences'


class MinutesOfHour extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: []
        }

        this.labels = {
            1: 0,
            2: 5,
            3: 10,
            4: 15,
            5: 20,
            6: 25,
            7: 30,
            8: 35,
            9: 40,
            10: 45,
            11: 50,
            12: 55,
        }

        this.id = Math.floor(Math.random() * Math.floor(99999))
        this.form = React.createRef()

        this.updateOption = this.updateOption.bind(this)
        this.highlightCheckeds = this.highlightCheckeds.bind(this)
        this.hCancel = this.hCancel.bind(this)
        this.hSave = this.hSave.bind(this)
    }

    componentDidMount() {
        this.updateOption(this.props.checked)
    }

    componentDidUpdate(prevProps) {
        if( arraysHasDifferences(prevProps.checked || [], this.props.checked || []) ) {
            this.updateOption(this.props.checked)
        }
        if(prevProps.opened !== this.props.opened && this.props.opened) {
            this.highlightCheckeds()
        }
        if(prevProps.keyEsc !== this.props.keyEsc || prevProps.keyEnter !== this.props.keyEnter) {
            if(this.props.keyEsc) this.hCancel()
            if(this.props.keyEnter) this.hSave()
            if(this.props.keyReset) this.props.keyReset()
        }
    }

    hCancel() {
        this.props.toggleContentOpened()
    }

    hSave() {
        const form = this.form.current
        let checked = []
        
        Array.prototype.map.call(form.checkboxTimeBlock, check => {
            if(check.checked) checked.push(Number(check.value))
        })

        this.updateOption(checked)
        
        this.props.toggleContentOpened()
    }

    render() {
        return(
            <div className="FormSelectMinutesOfHour">

                <div className="FormSelectMinutesOfHour__blocks">
                    <form ref={this.form}>
                        <div>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[1]} value="1" id={`FormSelectMinutesOfHour-checkbox-${this.id}-1`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[2]} value="2" id={`FormSelectMinutesOfHour-checkbox-${this.id}-2`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[3]} value="3" id={`FormSelectMinutesOfHour-checkbox-${this.id}-3`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[4]} value="4" id={`FormSelectMinutesOfHour-checkbox-${this.id}-4`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[5]} value="5" id={`FormSelectMinutesOfHour-checkbox-${this.id}-5`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[6]} value="6" id={`FormSelectMinutesOfHour-checkbox-${this.id}-6`}/>
                        </div>
                        <div>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[7]} value="7" id={`FormSelectMinutesOfHour-checkbox-${this.id}-7`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[8]} value="8" id={`FormSelectMinutesOfHour-checkbox-${this.id}-8`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[9]} value="9" id={`FormSelectMinutesOfHour-checkbox-${this.id}-9`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[10]} value="10" id={`FormSelectMinutesOfHour-checkbox-${this.id}-10`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[11]} value="11" id={`FormSelectMinutesOfHour-checkbox-${this.id}-11`}/>
                            <Form.Check type="checkbox" name="checkboxTimeBlock" label={this.labels[12]} value="12" id={`FormSelectMinutesOfHour-checkbox-${this.id}-12`}/>
                        </div>
                    </form>
                </div>

                <div className="FormSelectMinutesOfHour__buttons mobile-modal__buttons">
                    <div className="cancel" onClick={this.hCancel}>Отменить</div>
                    <div className="save" onClick={this.hSave}>Сохранить</div>
                </div>
            </div>
        )
    }

    updateOption(checked) {
        if(checked && Array.isArray(checked)) {
            checked = checked.map(check => Number(check))
            this.setState({
                checked: checked,
            })
            if(typeof this.props.onChange === "function") this.props.onChange(checked)
            if(typeof this.props.setValue === "function") {
                let checkedValues = []
                checked.map(blockId => checkedValues.push(this.labels[blockId]))
                checkedValues.sort((a, b) => (a > b) ? 1 : -1)
                this.props.setValue(checkedValues.join(", "))
            }
        }
    }

    highlightCheckeds() {
        const form = this.form.current

        Array.prototype.map.call(form.checkboxTimeBlock, check => {
            check.checked =
                this.state.checked.indexOf( Number(check.value) ) !== -1
                    ? true
                    : false
        })
    }
}

MinutesOfHour.propTypes = {
    checked: PropTypes.array,
    onSelect: PropTypes.func
}

export default MinutesOfHour
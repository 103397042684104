import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_SCHEDULE } from '../../../../store/Settings/sets'
import { loading } from '../../../../store/Settings/actions'
import { LOAD_TRACKS, putTracks } from '../../../../store/Settings/actions/sets/Object/Schedule'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { parseBitmap, maps } from '../../../../utilities/bitmap'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_TRACKS, worker)
}

function* worker({ payload }) {
    const { required } = payload

    try {
        const loadedTracks = yield select(state => state.settings.settings[OBJECT_SCHEDULE].tracks || {})

        if (!loadedTracks["NULL"] || required) {
            const fetchedTracks = yield call(fetchTracks)

            // Ошибка загрузки роликов
            if (fetchedTracks.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${LOAD_TRACKS}.`, fetchedTracks],
                    text: "Объявления не были загружены. Попробуйте повторить."
                }))

                // Успех загрузки роликов
            } else {
                let tracks = {
                    "NULL": {
                        id: "NULL",
                        name: "Не задан",
                    },
                }

                fetchedTracks.success.map(track => {
                    track.filename = buildGETUrl("clip/play", { id: track.id })
                    tracks[track.id] = track
                })

                // tracks[275782] = {
                //     id: 275782,
                //     origext: "mp4",
                //     name: "Marry Cristmas!",
                //     filename: "https://r5---sn-4g5ednsk.googlevideo.com/videoplayback?expire=1609010073&ei=OTfnX5_7Eci2Wu_DvaAH&ip=103.54.36.59&id=o-AI43L2py1M_VHJo5tUApicEZYmDWp1tqpNBCrHuzBaNU&itag=18&source=youtube&requiressl=yes&vprv=1&mime=video%2Fmp4&ns=db7YXoUqbKXrOTCCs_coAeIF&gir=yes&clen=2804652&ratebypass=yes&dur=41.052&lmt=1514530368404706&fvip=5&c=WEB&n=0aFB7UaSEKe0taZv&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cvprv%2Cmime%2Cns%2Cgir%2Cclen%2Cratebypass%2Cdur%2Clmt&sig=AOq0QJ8wRQIhANXmMzfQwkBcpsxd207kBzkLPAg5HuQvm8prpm7YGJZFAiAXMcSlARdsSJG__DRsU6_7kBcu6AYPhhHza40sxZmlgg%3D%3D&rm=sn-x5guiuxaxjvh-q5jl7z&req_id=1f3db4d405f2a3ee&redirect_counter=2&cm2rm=sn-npozz76&cms_redirect=yes&mh=a6&mip=95.32.196.64&mm=34&mn=sn-4g5ednsk&ms=ltu&mt=1608987880&mv=u&mvi=5&pl=22&lsparams=mh,mip,mm,mn,ms,mv,mvi,pl&lsig=AG3C_xAwRQIgQKA66PdCinXT0fqOfThs67BPvmSX0znqz-gkANI6OdgCIQClmc4sV61O1P5v9xTQpYSsPIlks4vI_YoR1L1_dX2YDg%3D%3D"
                // }

                yield put(putTracks({
                    tracks
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_TRACKS}.`, e],
            text: "Объявления не были загружены. Попробуйте повторить."
        }))
    }
}

function fetchTracks() {
    return axios.get("/msbox/adt/select")
        .then(response => response.data)
}
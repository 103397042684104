import { takeEvery, put, select } from 'redux-saga/effects'
import { DOWNLOAD_VIDEOS, loadVideolists } from '../../store/ContentVideolists/actions'
import { showAlertError } from '../../store/Alert/actions'
import { download } from '../../utilities/download'


export default function* () {
    yield takeEvery(DOWNLOAD_VIDEOS, worker)
}

function* worker({ payload }) {
    const { videosIds } = payload

    try {
        if (Array.isArray(videosIds)) {
            let arr = yield select(state => state.videolists.currentVideolist.songs)
            const videosLinks = getTracksLinks(arr, videosIds)
            console.log(videosLinks)
            download(videosLinks)
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка при ${DOWNLOAD_VIDEOS}.`, e],
            text: "Ролики не были скачаны. Попробуйте повторить."
        }))
    }
}

function getTracksLinks(videos, videosIds) {
    let videosLinks = []
    videos.map(video => {
        if (videosIds.indexOf(video.id) !== -1) {
            videosLinks.push(`https://t.ms-box.ru/download.php?id=${video.id}&token=${window.localStorage.getItem("sessionToken")}`)
        }
    })
    return videosLinks
}
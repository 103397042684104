const validateMap = {
    // name: {
    //     required: true,
    //     type: "string",
    //     min: 2,
    // },
    // alias: {
    //     // required: true,
    //     type: "string",
    //     min: 2,
    // },
}

export default validateMap
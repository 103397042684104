import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_SCHEDULE } from '../../../../store/Settings/sets'
import { SAVE_ITEM_DAYMASK, loadItems } from '../../../../store/Settings/actions/sets/Object/Schedule'
import { saving } from '../../../../store/Settings/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_ITEM_DAYMASK, worker)
}

function* worker({ payload }) {
    let { stateKey, itemIds } = payload

    try {
        if (Array.isArray(itemIds)) {
            yield put(saving({
                type: OBJECT_SCHEDULE,
                stateKey,
                saving: true,
            }))

            const itemStateKey = generateStateKey([stateKey, itemIds[0]])
            const itemSettings = yield select(state => state.settings.settings[OBJECT_SCHEDULE][itemStateKey])
            const selectedObjects = yield select(state => state.objects.selected || [])
            const selectedGroups = yield select(state => state.objectsGroup.selected || [])
            // const blocks       = yield select(state => state.settings.settings[OBJECT_SCHEDULE][stateKey].blocks)

            const msIds = selectedGroups.length ? selectedGroups : selectedObjects
            const saved = yield call(fetchSave, itemIds, msIds, itemSettings)

            // Ошибка
            if (saved.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${SAVE_ITEM_DAYMASK}.`, saved],
                    text: "Ошибка при сохранении. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(showAlertSuccess({
                    responses: [`Успешное выполнение ${SAVE_ITEM_DAYMASK}.`, saved],
                    text: "Настройки успешно сохранены."
                }))
                yield put(loadItems({
                    stateKey,
                    required: true,
                    // itemIds: blocks[itemSettings.__blocknumber] || []
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_ITEM_DAYMASK}.`, e],
            text: "Ошибка при сохранении. Попробуйте повторить."
        }))
    }

    finally {
        yield put(saving({
            type: OBJECT_SCHEDULE,
            stateKey,
            saving: false,
        }))
    }
}

function fetchSave(id, ms_id, settings) {
    id = id.join(",")
    ms_id = ms_id.join(",")

    const daymask = (settings["__without-ads"] === true) ? settings.daymask + 128 : settings.daymask

    let formData = new FormData()
    formData.set("daymask", daymask)

    return axios(
        {
            url: "/msbox/clip/update",
            method: "POST",
            data: formData,
            params: {
                id, ms_id
            }
        }
    )
        .then(response => response.data)
}
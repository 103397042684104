import React from 'react'


const Expand = ({ title = 'Развернуть', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-'+variant : ''} ${size ? 'spp-svg-size-'+size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 4.233">
                <title>{title}</title>
                <path d="M.127 0a.132.132 0 00-.133.132v1.323a.132.132 0 10.265 0V.264h1.19a.132.132 0 100-.265H.127zm2.645 0a.132.132 0 100 .264h1.191v1.19a.132.132 0 10.265 0V.133a.132.132 0 00-.132-.133H2.772zM.127 2.644a.132.132 0 00-.133.132V4.1c0 .073.06.132.133.132h1.322a.132.132 0 100-.264H.26v-1.19a.132.132 0 00-.132-.133zm3.969 0a.132.132 0 00-.133.132v1.19h-1.19a.132.132 0 100 .265h1.323a.132.132 0 00.132-.132V2.777a.132.132 0 00-.132-.132z" data-name="Group 947" fill="#0a0a0a"/></svg>
        </i>
    )
}

export default Expand 
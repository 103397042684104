import moment from 'moment'
import {takeEvery, put, call} from 'redux-saga/effects'
import {buildGETUrl} from '../../../utilities/buildGETUrl'
import {DELETE_PERIOD, loadPeriods, saving} from '../../../store/Settings/actions/components/Periods'
import {closeModal} from '../../../store/Modal/actions'
import {showAlertError} from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_PERIOD, worker)
}

function* worker({payload}) {
    const {type, id, period} = payload

    yield put(saving({type, saving: true}))

    try {

        const deleted = yield call(fetchDelete, type, id, period)

        // Ошибка
        if (deleted.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${DELETE_PERIOD}.`, deleted],
                text: "Период не был удален. Попробуйте повторить."
            }))

            // Успех
        } else {
            yield put(loadPeriods({type, required: true}))
            yield put(closeModal("element"))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_PERIOD}.`, e],
            text: "Период не был удален. Попробуйте повторить."
        }))
    } finally {
        yield put(saving({type, saving: false}))
    }
}

function fetchDelete(type, id, period) {
    let formData = new FormData()
    formData.set("id", id)
    return axios(
        {
            url: `/msbox/${type}/dates/delete`,
            method: "POST",
            data: formData,
            params: {
                id
            }
        }
    ).then(response => response.data)
}
import React, { useState, useEffect } from 'react'
import Spinner from '../../../../../../components/Spinner'
import { connect } from 'react-redux'
import { loadObjectsState, sortObjectsState, setSortDirection, toggleSelectedObjectState } from '../../../../../../store/Reports/actions'
import SvgArrowUpDown from '../../../../../../components/SVG/ArrowUpDown'
import ObjectsStateItem from './item/index'
import List from '../../../../../../components/List'
import Select from '../../../../../../components/Form/Select'
import { filterByTagId } from '../../../../../../store/ObjectsByTags/actions'
import { loadObjects, toggleSelectedObject } from '../../../../../../store/Objects/actions'
import MoveUp from "../../../../../../components/SVG/MoveUp";
import { Button } from "react-bootstrap";


const ObjectsState = ({
    loadObjectsState,
    toggleSelectedObject,
    toggleSelectedObjectState,
    objects_state,
    sortObjectsState,
    loadObjects,
    loading,
    filterByTagId,
    filteredTagId = -1,
    tags = []
}) => {
    useEffect(() => {
        loadObjectsState()
        loadObjects()
    }, [])
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset + window.innerHeight >= document.body.clientHeight - 70 && window.pageYOffset > 700) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
    const [sort_direction, setSortDirection] = useState("")
    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortObjectsState({ direction: sort_direction, sort_type: type })
    }
    const hSelectTag = ({ value }) => filterByTagId({ tagId: value })
    const getItems = () => {
        return objects_state.map((object_state, index) => ({
            id: object_state.id,
            name: object_state.name,
            element: <ObjectsStateItem
                key={ object_state.id }
                id={ object_state.id }
                name={ object_state.name }
                lastsyncsystem={ object_state.lastsyncsystem }
                sync_time={ object_state.lastsync }
                sync_start_time={ object_state.startsync }
                ip={ object_state.lip }
                error={ object_state.lasterror }
                variant={ (index % 2 == 0) ? "gray" : "" }
                address={ object_state.address }
            />
        }))
    }
    return (
        <div>
            <h2>СОСТОЯНИЕ ОБЪЕКТОВ</h2>
            <div style={ { marginTop: "24px" } }><Select
                type="options"
                placeholder="Фильтрация по меткам"
                options={ [
                    { title: "Не фильтровать", value: -1 },
                    ...tags
                ] }
                onChange={ hSelectTag }
            /></div>
            { !loading ?

                <div>
                    <div className="ObjectsState" style={ { marginBottom: "-30px", marginTop: "24px" } }>
                        <header className="ObjectsState__control" >
                            <div className="ObjectsState__grid">
                                <div className="id" >
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("id") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>ID</span>
                                    </button>
                                </div>
                                <div className="name">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("name") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        <span>ОБЪЕКТ</span>
                                    </button>
                                </div>
                                <div className="error">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("error") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ОШИБКИ
                                    </button>
                                </div>
                            </div>
                        </header>
                    </div>

                    <List
                        type="simple"
                        scroll={ {
                            on: true,
                            data: {
                                variant: "white",
                                size: "sm",
                            }
                        } }
                        content={ { items: getItems(), variant: "none" } }
                    />

                    { isVisible && (
                        <div style={ { position: "fixed", bottom: 0, margin: "auto", height: "95px", display: "flex", width: "95%", flexDirection: "column", justifyContent: "center" } }>
                            <Button onClick={ scrollToTop } variant="light">
                                <h2><MoveUp></MoveUp>В начало</h2>

                            </Button>
                        </div>
                    ) }
                </div>
                : <Spinner />
            }

        </div>
    )
}
const stateToProps = state => ({
    // objects_state: state.report.objects_state,
    // objects_state: state.objects.allObjects,
    objects_state: state.report.objects_state,
    loading: state.report.loading,
    tags: [...state.objectsByTags.tags],
    filteredTagId: state.objectsByTags.filteredTagId,
})
const actionsToProps = {
    loadObjectsState,
    sortObjectsState,
    filterByTagId,
    loadObjects,
    toggleSelectedObject,
    toggleSelectedObjectState,
}

export default connect(stateToProps, actionsToProps)(ObjectsState)
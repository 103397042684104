import { SORT_PLAYLISTS } from './actions'

const inititalState = {
    sort_type: "",
    direction: "",
    id: "",
    playlists: ""
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {

        case SORT_PLAYLISTS:
            return {
                ...state,
                playlists: state.playlists.allPlaylists.sort((a, b) => a.name < b.name),
            }

    }
    return state
}

export default reducer
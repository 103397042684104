import React from 'react'
import {connect} from 'react-redux'

import {loadSettings, saveSettings} from '../../../../store/Settings/actions/sets/Object'
import {updateOptions} from '../../../../store/Settings/actions'
import {OBJECT_MODEM} from '../../../../store/Settings/sets'

import ObjectModem from './ObjectModem'


const ObjectModemContainer = props => (
    <ObjectModem
        className={props.className}
        settings={props.settings}
        stateKey={props.stateKey}
        updateOptions={props.updateOptions}
        loadSettings={props.loadSettings}
        saveSettings={props.saveSettings}
    />
)

const stateToProps = (state, { stateKey }) => {
    let settings = state.settings.settings[OBJECT_MODEM] || {}
    return {
        settings: {...settings[stateKey]},
    }
}

const actionsToProps = {
    loadSettings,
    updateOptions,
    saveSettings,
}

export default connect(stateToProps, actionsToProps)(ObjectModemContainer)
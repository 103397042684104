import moment from "moment";

export default function ({
                             settings = {},
                             name,
                             onChange
                         }) {
    return [
        {
            type: "Datepicker",
            data: {
                type: 'single',
                onChange,
                variant: "icon",
                durationInput: false,
                titles: {
                    main: "Выберите даты",
                    from: "Дата",
                    to: "Дата"
                },
                data: {
                    oneDay: true,
                    oneDate: true,
                    disabledType: "past",
                    default: settings && settings[name]  ? moment(settings[name]).format("YYYY-MM-DD") : null,
                    // default: {
                    //     from: settings[name],
                    //     to: settings[name],
                    // },
                }
            }
        }
    ]
}
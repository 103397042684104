import React from 'react'
import {connect} from 'react-redux'

import {closeModal, openModal} from '../../../../../store/Modal/actions'
import {copyAds} from '../../../../../store/Modal/types/AdsCopy/actions'

import ObjectsComponent from '../../../../../components/Objects'
import Collapsible from '../../../../../components/Space/Collapsible'
import Button from '../../../../../components/Button'

import SvgFileP from '../../../../../components/SVG/FileP'
import SvgCopy from '../../../../../components/SVG/Copy'
import SvgTrash from '../../../../../components/SVG/Trash'
import {clearAdvert} from "../../../../../store/AdvertClear/actions";


const Objects = ({
                     className = "",
                     active,
                     placeNumber,
                     selectedObjects,
                     openModal,
                     closeModal,
                     copyAds,
                     clearAdvert
                 }) => {

    const modal = () => {
        openModal({
            type: "oneInput",
            size: "sm",
            data: {
                title: "Копирование рекламы",
                required: true,
                input: {
                    label: "ID объекта/объектов",
                    attrs: {
                        placeholder: "Впишите ID объекта",
                        required: true
                    }
                },
                buttons: {
                    prim: {
                        title: "Скопировать",
                        onClick: (value) => {
                            copyAds({from: selectedObjects[0], to: value})
                        }
                    }
                }
            }
        })
    }

    const modalRemoveAds = () => {
        openModal({
            type: "confirm",
            size: "sm",
            data: {
                title: "Вы точно хотите удалить рекламу?",
                buttons: {
                    cancel: {
                        title: "Вернуться",
                        onClick: () => {
                            closeModal("confirm")
                        }
                    },
                    confirm: {
                        title: "Удалить",
                        onClick: () => {
                            clearAdvert(selectedObjects[0])
                            closeModal("confirm")
                        }
                    }
                }
            }
        })
    }
    return (
        <Collapsible
            className={`PartObjects ${className}`}
            active={active}
            id={211}
            placeNumber={placeNumber}
            title="Объекты"
            elements={
                <>
                    <Button
                        type="string"
                        disabled={(selectedObjects[0]) ? false : true}
                        onClick={() => openModal({type: "adsReport"})}
                    >
                        <SvgFileP title="Эфир по рекламе"/>
                        Эфир по рекламе
                    </Button>
                    <Button
                        type="string"
                        disabled={(selectedObjects[0]) ? false : true}
                        onClick={modal}
                    >
                        <SvgCopy title="Скопировать рекламу"/>
                        Скопировать рекламу
                    </Button>
                    <Button
                        type="string"
                        disabled={(selectedObjects[0]) ? false : true}
                        onClick={modalRemoveAds}
                    >
                        <SvgTrash title="Удалить рекламу"/>
                        Удалить рекламу
                    </Button>
                </>
            }
        >
            <ObjectsComponent/>
        </Collapsible>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
})

const actionsToProps = {
    openModal,
    closeModal,
    copyAds,
    clearAdvert
}

export default connect(stateToProps, actionsToProps)(Objects)
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { filterByTagId } from '../../../../../store/ObjectsByTags/actions'
import { isMobile } from '../../../../../utilities/media'
import { MEDIA_BREAKPOINT_MD, MEDIA_BREAKPOINT_LG } from '../../../../../utilities/breakpoints'
import PlaylistsComponent from '../../../../../components/ContentMusic'

import ContentMobile from '../../../../../components/ContentMobile/Music'

import Collapsible from '../../../../../components/SpaceContent/Collapsible'
import Select from '../../../../../components/Form/Select'
import List from '../../../../../components/List'
import Button from '../../../../../components/Button'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'
import ScrollContainer from '../../../../../components/ScrollContainer'
import { openModal, closeModal } from '../../../../../store/Modal/actions'
import { deletePlaylists, sortPlaylists } from '../../../../../store/ContentPlaylists/actions'
import AllPlaylists from '../../Where/AllPlaylists'


const Objects = ({
    className = "",
    // active,
    placeNumber,
    tags = [],
    filterByTagId,
    openModal,
    closeModal,
    selectedPlaylists,
    deletePlaylists,
    allPlaylists,
    sortPlaylists,
    expandedPlace
}) => {

    const [sort_direction, setSortDirection] = useState("")
    const [active, setActive] = useState(true)
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    useEffect(() => {

        if (expandedPlace === 1) {
            console.log(expandedPlace)
            setActive(true)
        } else {
            setActive(false)
        }
    }, [expandedPlace])
    // useEffect(() => {
    //     function handleResize() {
    //         setDimensions({
    //             height: window.innerHeight,
    //             width: window.innerWidth
    //         })

    //     }

    //     window.addEventListener('resize', handleResize)
    // })

    const hSelectTag = ({ value }) => filterByTagId({ tagId: value })

    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortPlaylists({ direction: sort_direction, sort_type: type })
    }

    return (
        <div data-id={ `music` } className={ `${className}` } style={ { width: "100%" } }>
            <Collapsible
                className={ `CollapsibleMusic ${className}` }
                active={ active }
                name={ `music` }
                id={ 111 }
                placeNumber={ placeNumber }
                title="Музыка"
                buttons={ {
                    add: {
                        on: true,
                        title: "Создать плейлист",
                        onClick: () => openModal({
                            type: "playlistCreate",
                            size: "sm",
                        })
                    },

                    remove: {
                        on: true,
                        disabled: (selectedPlaylists[0]) ? false : true,
                        onClick: () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: "Вы точно хотите удалить выделенные плейлисты?",
                                buttons: {
                                    cancel: {
                                        title: "Вернуться",
                                        onClick: () => {
                                            closeModal("confirm")
                                        }
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deletePlaylists(selectedPlaylists)
                                            closeModal("confirm")
                                        }
                                    }
                                }
                            }
                        })
                    },
                    edit: {
                        on: true,
                        disabled: (selectedPlaylists[0]) ? false : true,
                        title: "Редактировать плейлист",
                        onClick: () => openModal({
                            type: "playlistEdit",
                            size: "sm",
                        })
                    },
                } }
            >

                <header className="Playlists__control">
                    { window.outerWidth > MEDIA_BREAKPOINT_LG || !isMobile()
                        ? false
                        : <h4 style={ { marginBottom: "32px" } }> Мои плейлисты</h4>
                    }
                    <div className="Playlists__grid">
                        <div className="name">
                            <Button
                                type="string"
                                onClick={ () => sort("name") }
                            >
                                <SvgArrowUpDown title="Сортировка по название плейлиста" />
                                Название
                            </Button>
                        </div>
                        <div className="tracks_count ">
                            <Button
                                type="string"
                                onClick={ () => sort("songs") }
                            >
                                <SvgArrowUpDown title="Сортировка по кол-ву треков" />
                                <span>Треков</span>
                            </Button>
                        </div>
                        <div className="size">
                            <Button
                                type="string"
                                onClick={ () => sort("size") }
                            >
                                <SvgArrowUpDown title="Сортировка по размеру плейлиста" />
                                <span>Размер</span>
                            </Button>
                        </div>
                        <div className="duration">
                            <Button
                                type="string"
                                onClick={ () => sort("duration") }
                            >
                                <SvgArrowUpDown title="Сортировка по длительности" />
                                <span>Длительность</span>
                            </Button>
                        </div>
                    </div>
                </header>
                { window.outerWidth > MEDIA_BREAKPOINT_LG
                    ? <PlaylistsComponent types={ ["simple"] } allPlaylists={ allPlaylists } />
                    :
                    <div>
                        <ContentMobile />
                    </div>
                }
            </Collapsible>
        </div>
    )
}

const stateToProps = state => ({
    tags: [...state.objectsByTags.tags],
    allPlaylists: state.playlists.allPlaylists,
    selectedPlaylists: state.playlists.selected,
    expandedPlace: state.panel.expandedPlace
})

const actionsToProps = {
    filterByTagId,
    openModal,
    closeModal,
    deletePlaylists,
    sortPlaylists
}

export default connect(stateToProps, actionsToProps)(Objects)
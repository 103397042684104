import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_VOLUME } from '../../../../store/Settings/sets'
import { loading } from '../../../../store/Settings/actions'
import { LOAD_ITEMS, putItems } from '../../../../store/Settings/actions/sets/Object/Volume'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_ITEMS, worker)
}

function* worker({ payload }) {
    const { stateKey, required } = payload

    try {
        const __itemsLoaded = yield select(state => state.settings.settings[OBJECT_VOLUME][stateKey]?.__itemsLoaded)
        const selectedObjects = yield select(state => state.objects.selected || [])

        if (selectedObjects.length) {
            if (required || __itemsLoaded !== true) {
                yield put(loading({
                    type: OBJECT_VOLUME,
                    stateKey,
                    loading: true,
                }))

                const fetchedItems = yield call(fetchItems, selectedObjects)

                // Ошибка
                if (fetchedItems.error) {
                    yield put(showAlertError({
                        errors: [`Ошибка сервера при ${LOAD_ITEMS}.`, fetchedItems],
                        text: "Список Громкость не был загружен. Попробуйте повторить."
                    }))

                    // Успех
                } else {
                    const bufferStateKey = generateStateKey([stateKey, 0])

                    let items = fetchedItems.success,
                        assocItems = {
                            [bufferStateKey]: {
                                "inside": 95,
                                "outside": 95,
                                "back": 88,
                                "starttime": "",
                                "daymask": 127,
                            }
                        }

                    items.map(item => {
                        const itemStateKey = generateStateKey([stateKey, item.id])
                        assocItems[itemStateKey] = item
                    })

                    yield put(putItems({
                        stateKey,
                        items,
                        assocItems
                    }))
                }
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_ITEMS}.`, e],
            text: "Список Громкость не был загружен. Попробуйте повторить."
        }))
    }
    finally {
        yield put(loading({
            type: OBJECT_VOLUME,
            stateKey,
            loading: false,
        }))
    }
}

function fetchItems(ms_id) {
    ms_id = ms_id.join(",")
    return axios.get("/msbox/volume/select", { params: { ms_id } })
        .then(response => response.data)
}
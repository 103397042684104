import React from 'react'
import PropTypes from 'prop-types'

import ScrollContainer from '../../ScrollContainer'

import SvgTrash from '../../SVG/Trash'


const List = (list, deleteItem) => {
    return (
        <div className="Track__List">

            <ScrollContainer variant="none" size='md'>

                { (list && list[0])
                    ? list.map((item, index) => {
                        return (
                            <div className="item">
                                <div className="number">{ ++index }</div>
                                <div className="from">
                                    Размещение<span className='d-mg-inline'> с</span>:
                                </div>
                                <div className="date">{ item.dateFrom }—{ item.dateTo }</div>
                                {/* <div className='plays'>Идёт<span className='d-mg-inline'> сейчас</span></div> */ }
                                <div className="remove" onClick={ () => deleteItem(item.id) }>
                                    <SvgTrash variant='dark' size='sm1' />
                                </div>
                            </div>
                        )
                    })
                    : ""
                }
            </ScrollContainer>
        </div>
    )
}

List.propTypes = {
    list: PropTypes.exact({
        dateFrom: PropTypes.string,
        dateTo: PropTypes.string,
    }),
    deleteItem: PropTypes.func
}



export default List
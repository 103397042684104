import React from 'react'
import PropTypes from 'prop-types'
import { MEDIA_BREAKPOINT_LG } from '../../utilities/breakpoints'


class SlideMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            slideLeft: 0,
            slideRight: 0,
            slidePosition: "start",
            slideStyle: {},
            activeItem: this.props.activeItem || 1,
            wereActive: []
        }
        this.SlideMenu = React.createRef()
        this.itemFirst = {}
        this.itemLast = {}
        this.menu = React.createRef()
        this.scroll = React.createRef()
        this.line = React.createRef()
        this.slide = React.createRef()

        this.pointStart = 0
        this.pointEnd = 0
        this.lastScroll = 0
        this.isClick = false
        this.toggleMenu = this.toggleMenu.bind(this)
        this.slideControl = this.slideControl.bind(this)
    }

    componentDidMount() {
        this.itemFirst.current = this.SlideMenu.current.querySelector(`.item-1`)
        this.itemLast.current = this.SlideMenu.current.querySelector(`.item-${this.props.items.length}`)
        this.pointStart = this.getSlidePointStart()
        this.pointEnd = this.getSlidePointEnd()
        this.toggleMenu(this.state.activeItem)
    }

    toggleMenu(itemNumber) {
        let target = typeof itemNumber === "number"
            ? this.SlideMenu.current.querySelector(`.item-${itemNumber}`)
            : itemNumber.target.parentNode

        let scrollWidth = this.scroll.current.scrollWidth,
            spaceWidth = this.menu.current.offsetWidth,
            itemWidth = target.offsetWidth,
            itemPosition = target.offsetLeft,
            activeItem = typeof itemNumber === "number" ? itemNumber : Number(target.dataset.itemNumber),
            scrollTo = 0 // Первый

        // Последний
        if ((itemPosition + itemWidth) === scrollWidth) {
            scrollTo = itemPosition
            this.setState(state => {
                let wereActive = state.wereActive
                if (wereActive.indexOf(activeItem) === -1) wereActive.push(activeItem)
                return {
                    slideLeft: 0,
                    slideRight: this.pointEnd,
                    slidePosition: "end",
                    slideStyle: {},
                    activeItem: activeItem,
                    wereActive: wereActive
                }
            })
        }
        // Средний
        if (itemPosition !== 0 && itemPosition !== scrollTo) {
            scrollTo = itemPosition - ((spaceWidth - itemWidth) / 2)
            this.setState(state => {
                let wereActive = state.wereActive
                if (wereActive.indexOf(activeItem) === -1) wereActive.push(activeItem)
                return {
                    slideLeft: 0,
                    slideRight: 0,
                    slidePosition: "center",
                    slideStyle: {},
                    activeItem: activeItem,
                    wereActive: wereActive
                }
            })
        }
        // Первый
        if (scrollTo === 0) {
            this.setState(state => {
                let wereActive = state.wereActive
                if (wereActive.indexOf(activeItem) === -1) wereActive.push(activeItem)
                return {
                    slideLeft: this.pointStart,
                    slideRight: 0,
                    slidePosition: "start",
                    slideStyle: {},
                    activeItem: activeItem,
                    wereActive: wereActive
                }
            })
        }

        elementScrollLeft(this.scroll.current, scrollTo)
            .then(s => {
                this.lastScroll = s
            })

        this.itIsClick()
    }

    slideControl() {
        if (this.isClick) return true

        let currentLeft = this.slide.current.offsetLeft,
            currentScroll = this.scroll.current.scrollLeft

        this.setState({
            slideStyle: {
                left: `${currentLeft + (this.lastScroll - currentScroll)}px`,
                transition: "none"
            }
        })

        this.lastScroll = currentScroll
    }

    render() {
        const { items, className = "" } = this.props
        return (
            <>
                <section className={`SlideMenu ${className}`} ref={this.SlideMenu}>
                    <div className="SlideMenu__wrap">
                        <div className="SlideMenu__scroll" onScroll={this.slideControl} ref={this.scroll}>
                            <div className="SlideMenu__padding">
                                <div className="SlideMenu__menu" ref={this.menu}>

                                    {items.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`item item-${(index + 1)} ${this.state.activeItem === (index + 1) ? "active" : ""}`}
                                                onClick={this.toggleMenu}
                                                data-item-number={(index + 1)}>
                                                <span>
                                                    {item.title}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="SlideMenu__line" ref={this.line} style={{ left: `${this.state.slideLeft}%`, right: `${this.state.slideRight}%` }}>
                        <div className={`slide ${this.state.slidePosition}`} ref={this.slide} style={this.state.slideStyle}></div>
                    </div>
                </section>

                {window.outerWidth <= MEDIA_BREAKPOINT_LG
                    ? items.map((item, index) => {
                        if (this.state.wereActive.indexOf((index + 1)) !== -1) {
                            if ((this.state.activeItem - 1) === index) {
                                return React.cloneElement(item.content, {
                                    active: true
                                })
                            } else {
                                return React.cloneElement(item.content, {
                                    className: "d-none"
                                })
                            }
                        }
                    })
                    : items.map((item) => {
                        return item.content
                    })
                }
            </>
        )
    }



    getSlidePointStart() {
        let item = this.itemFirst.current.getBoundingClientRect(),
            SlideMenu = this.SlideMenu.current.getBoundingClientRect(),
            slide = this.slide.current.getBoundingClientRect()
        let itemCenter = ((item.width / 2) + item.x) - SlideMenu.x,
            lineOffsetPx = itemCenter - (slide.width / 2),
            lineOffsetPercent = lineOffsetPx / (SlideMenu.width / 100)
        return lineOffsetPercent
    }

    getSlidePointEnd() {
        let item = this.itemLast.current.getBoundingClientRect(),
            SlideMenu = this.SlideMenu.current.getBoundingClientRect(),
            slide = this.slide.current.getBoundingClientRect(),
            scrollWidth = this.scroll.current.scrollWidth,
            scrollOffsetWidth = this.scroll.current.offsetWidth,
            scrollLeft = this.scroll.current.scrollLeft,
            scroll = (scrollWidth - scrollOffsetWidth) - scrollLeft
        let itemCenter = ((item.width / 2) + (item.x - scroll)) - SlideMenu.x,
            lineOffsetPx = itemCenter + (slide.width / 2),
            lineOffsetPercent = (SlideMenu.width - lineOffsetPx) / (SlideMenu.width / 100)
        return lineOffsetPercent
    }

    itIsClick() {
        this.isClick = true
        setTimeout(() => {
            this.isClick = false
        }, 2000)
    }
}

SlideMenu.propTypes = {
    className: PropTypes.string,
    activeItem: PropTypes.number,
    items: PropTypes.array
}

async function elementScrollLeft(elem, to, smooth = 10) {
    let cycle = 1,
        from = elem.scrollLeft,
        step = (from - to) / smooth

    step = from < to ? Math.abs(step) : 0 - step

    let animate = new Promise(resolve => {

        requestAnimationFrame(function animate() {
            elem.scrollLeft = from + step * cycle
            cycle === smooth
                ? resolve(elem.scrollLeft)
                : requestAnimationFrame(animate)
            cycle++
        })
    })

    return await animate
}

export default React.memo(SlideMenu)


import {
    LOAD_CLIP_REPORT, LOADING,
    PUT_CLIP_REPORT,
    SORT_CLIP_REPORT
} from './actions'
import {durationFromCliptime} from "../../../utilities/duration";

const inititalState = {
    loading: false,
    advert_report: [],
    advert_duration: ''
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {

        case SORT_CLIP_REPORT:
            if (payload.sort_type === "timestamp") {
                return {
                    ...state,
                    advert_report:
                        Array.from(state.advert_report).sort((a, b) => {
                            if (a.timestamp < b.timestamp) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.timestamp > b.timestamp) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }

            } else if (payload.sort_type === "channel") {
                return {
                    ...state,
                    advert_report:
                        Array.from(state.advert_report).sort((a, b) => {
                            if (a.channel < b.channel) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.channel > b.channel) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "name") {
                return {
                    ...state,
                    advert_report:
                        Array.from(state.advert_report).sort((a, b) => {
                            if (a.name < b.name) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.name > b.name) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "sync_start_time") {
                return {
                    ...state,
                    advert_report:
                        Array.from(state.advert_report).sort((a, b) => {
                            if (a.startsync < b.startsync) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.startsync > b.startsync) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "ip") {
                return {
                    ...state,
                    advert_report:
                        Array.from(state.advert_report).sort((a, b) => {
                            if (a.lip < b.lip) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.lip > b.lip) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "error") {
                return {
                    ...state,
                    advert_report:
                        Array.from(state.advert_report).sort((a, b) => {
                            if (a.lasterror < b.lasterror) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.lasterror > b.lasterror) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            }
        case LOAD_CLIP_REPORT:
            return {
                ...state,
            }
        case PUT_CLIP_REPORT:

            return {
                ...state,
                advert_report: payload.advert_report,
                advert_duration: durationFromCliptime(payload.advert_report)
            }

        case LOADING:
            return {
                ...state,
                loading: payload
            }


    }
    return state
}

export default reducer
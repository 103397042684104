import React from 'react'
import { connect } from 'react-redux'

import { loadTracks, toggleSelectedTrack } from '../../store/Tracks/actions'
import { updateCurrentMark } from '../../store/Marks/actions'

import Tracks from './Tracks'


const TracksContainer = props => (
    <Tracks
        theme={props.theme}
        tracks={props.tracks}
        selected={props.selected}
        searchActive={props.searchActive}
        searchFounded={props.searchFounded}
        toggleSelectedTrack={props.toggleSelectedTrack}
        loading={props.loading}
        updateCurrentMark={props.updateCurrentMark}
        loadTracks={props.loadTracks}
        showHideEndedButton={props.showHideEndedButton}
        toggleHideEnded={props.toggleHideEnded}
    />
)

const stateToProps = (state, props) => ({
    tracks: props.theme ? props.theme === 'extra' ? state.tracks.tracksExtra : state.tracks.tracksTheme : state.tracks.tracks,
    loading: state.tracks.loading,
    selected: state.tracks.selected,
    searchActive: state.search["tracks"].modeActive,
    searchFounded: [...state.search["tracks"].founded],
})

const actionsToProps = {
    loadTracks,
    toggleSelectedTrack,
    updateCurrentMark
}

export default connect(stateToProps, actionsToProps)(TracksContainer)
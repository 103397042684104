import React from 'react'


const FileP = ({ title = '', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.05 4.234">
                <title>{ title }</title>
                <g strokeWidth="1.097"><path d="M.424 0A.43.43 0 000 .426v3.382a.43.43 0 00.424.426h2.201a.43.43 0 00.425-.426V1.165l-.097-.097L2.76.874 1.986.098A.132.132 0 001.98.092a.132.132 0 00-.004-.003L1.888 0H.425zm.001.265H1.76v.602c0 .234.192.426.425.427h.6v2.513a.16.16 0 01-.16.162h-2.2a.16.16 0 01-.161-.162V.427c0-.091.07-.161.161-.162zm1.599.246l.248.249.269.269h-.355a.16.16 0 01-.162-.162zm-.972 1.167a.047.047 0 00-.034.014.051.051 0 00-.012.034v1.258c0 .013.005.024.014.033.009.01.02.014.032.014h.185a.051.051 0 00.035-.012.047.047 0 00.013-.035v-.452h.284a.57.57 0 00.366-.106c.089-.072.133-.178.133-.317 0-.14-.044-.247-.133-.32a.557.557 0 00-.366-.11zm.23.216h.277c.077 0 .135.02.172.056.039.038.058.09.058.157 0 .068-.02.12-.06.157-.039.034-.095.051-.17.051h-.277z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" stroke="none" data-name="Group 2672" strokeMiterlimit="10" /></g></svg>
        </i>
    )
}

export default FileP
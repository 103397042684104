import React, {useEffect} from 'react'

import {OBJECT_MODEM} from '../../../../store/Settings/sets'

import {cl} from '../../../../utilities/cl'
import {prepareSettings} from '../../utilities/prepareSettings'
import {carryUpdate} from '../../utilities/carryUpdate'
import {updateHelper} from '../../utilities/updateHelper'
import {setsex, setsmob} from '../../utilities/setsclasses'
import fields from './fields'

import Builder from '../../Builder'

import fieldMinitstr from './items/minitstr'
import fieldMphone from './items/mphone'
import fieldMname from './items/mname'
import fieldMpass from './items/mpass'
import fieldTimeout from './items/timeout'
import fieldSynctime from './items/synctime'
import fieldMinsynctime from './items/minsynctime'
import fieldMaxsynctime from './items/maxsynctime'
import fieldMaxtime from './items/maxtime'
import fieldSyncmute from './items/syncmute'
import ItemSave from './items/save'


const SettingsObjectModem = ({
    className = "",
    settings = {},
    stateKey,
    loadSettings,
    updateOptions,
    saveSettings,
}) => {

    className = cl(
        className,
        "SettingsObjectModem"
    )

    useEffect(() => {
        loadSettings({
            type: OBJECT_MODEM,
            stateKey,
            fields,
        })
    }, [stateKey])

    let validMap
    [settings, validMap] = prepareSettings({
        type: OBJECT_MODEM,
        settings: settings,
    })

    updateOptions = carryUpdate({
        type: OBJECT_MODEM,
        stateKey,
        updateOptions
    })

    const hChange = (name) => updateHelper({ 
        name,
        updateOptions
    })

    return(
        <Builder
            className={className}
            groups={{
                all: [
                    // Group
                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                            className: setsex(["col-2", "divider-pb", "divider-bb", "mr"])+setsmob(["col-1"])
                        },
                        items: [
                            {
                                title: "Строка инициализации модема",
                                components: fieldMinitstr({
                                    settings,
                                    name: "minitstr",
                                    onChange: hChange("minitstr")
                                })
                            },
                            {
                                title: "Строка набора телефонного номера",
                                components: fieldMphone({
                                    settings,
                                    name: "mphone",
                                    onChange: hChange("mphone")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            className: setsex(["col-2", "no-divider", "divider-pb", "mt-0"])+setsmob(["col-1"])
                        },
                        items: [
                            {
                                title: "Имя пользователя для входа в сеть/Название точки доступа",
                                components: fieldMname({
                                    settings,
                                    name: "mname",
                                    onChange: hChange("mname")
                                })
                            },
                            {
                                title: "Пароль пользователя для входа/Пароль точки доступа",
                                components: fieldMpass({
                                    settings,
                                    name: "mpass",
                                    onChange: hChange("mpass")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                            className: setsex(["col-2", "mr", "divider-pb", "divider-bb"])+setsmob(["col-1"])
                        },
                        items: [
                            {
                                title: "Максимальное время дозвона в сек.",
                                components: fieldTimeout({
                                    settings,
                                    name: "timeout",
                                    onChange: hChange("timeout")
                                })
                            },
                            {
                                title: "Время ежедневной синхронизации",
                                components: fieldSynctime({
                                    settings,
                                    name: "synctime",
                                    onChange: hChange("synctime")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            className: setsex(["col-2", "no-divider", "divider-pb"])+setsmob(["col-1"])
                        },
                        items: [
                            {
                                title: "Минимальное время, с которого можно проводить синхронизацию при перезапуске",
                                components: fieldMinsynctime({
                                    settings,
                                    name: "minsynctime",
                                    onChange: hChange("minsynctime")
                                })
                            },
                            {
                                title: "Максимальное время, с которого можно проводить синхронизацию при перезапуске",
                                components: fieldMaxsynctime({
                                    settings,
                                    name: "maxsynctime",
                                    onChange: hChange("maxsynctime")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            className: setsex(["col-2"])+setsmob(["col-1"]),
                            noDivider: true,
                        },
                        items: [
                            {
                                title: "Максимальное количество часов (-1 — синхронизировать как по проводной цепи)",
                                components: fieldMaxtime({
                                    settings,
                                    name: "maxtime",
                                    onChange: hChange("maxtime")
                                })
                            },
                            {
                                title: "Выключать звук при синхронизации",
                                components: fieldSyncmute({
                                    settings,
                                    name: "syncmute",
                                    onChange: hChange("syncmute")
                                })
                            }
                        ]
                    },

                    // Save
                    {
                        data: {
                            className: setsex(["col-2-nowrap"])
                        },
                        items: [
                            <ItemSave
                                settings={settings}
                                stateKey={stateKey}
                                fields={fields}
                                saveSettings={saveSettings}
                            />
                        ]
                    }
                ],
            }}
        />
    )
}

export default SettingsObjectModem
import { takeEvery, put, call } from 'redux-saga/effects'
import { ADD_MARK, updateMark, deleteMark } from '../../store/Marks/actions'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import axios from 'axios'

export default function* () {
    yield takeEvery(ADD_MARK, worker)
}

function* worker({ payload }) {
    const markId = payload
    try {
        const clips = yield call(fetchMarks, markId)

        if (clips.error) {
            console.log("Возникла ошибка при загрузке `mark`.")
            console.log(clips)
            yield put(deleteMark(markId))
        } else {
            let items = []

            clips.success.map(clip => {
                items.push(Number(clip.ms_id))
                items.push(Number(clip.indent_id))
            })

            items = [...new Set(items)]

            yield put(updateMark({
                markId,
                items
            }))
        }
    }
    catch (e) {
        console.log("Возникла ошибка при загрузке `mark`.")
        console.log(e)
        yield put(deleteMark(markId))
    }
}

function fetchMarks(markId) {
    return axios.get("/timeline/clip/select", { params: { mark: markId } })
        .then(response => response.data)
}
import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_ACCOUNT_INFO, loading, putAccountInfo } from '../../store/Roles/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_ACCOUNT_INFO, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        const account = yield call(fetchAccountInfo)
        if (account.error && account.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_ACCOUNT_INFO}.`, account],
                text: "Информация о пользователе не была загружена. Попробуйте перезагрузить страницу."
            }))
            yield put(putAccountInfo([]))
        } else {
            yield put(putAccountInfo({ account: account.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_ACCOUNT_INFO}.`, e],
            text: "Информация о пользователе не была загружена. Попробуйте перезагрузить страницу."
        }))
        yield put(putAccountInfo([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchAccountInfo() {
    return axios.get("/account/logged")
        .then(response => response.data)
}

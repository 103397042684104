import {normalizeDate} from '../../../utilities/normalize'
import {getDatesBetween} from './getDatesBetween'


export function highlightCalendar(pickerData = {
    picker: "",
    selected: {
        from: "",
        to: "",
    },
    disabled: []
}) {
    applyHighlight(pickerData)
}

function applyHighlight(pickerData) {
    try {
        highlightDisabled(pickerData)
        highlightMiddleDates(pickerData)
        highlightSelected(pickerData)
    } catch (e) {
        console.log("Возникла ошибка при подсветке дат. Событие ошибки ниже.")
        console.log(e)
    }
}

function highlightDisabled({ picker, disabled }) {
    if(picker) {
        Array.prototype.map.call(picker.querySelectorAll(".date.disabled"), elem => {
            elem.classList.remove("disabled")
        })

        Array.prototype.map.call(picker.querySelectorAll(".date"), elem => {
            if(elem.innerHTML.length === 0) {
                elem.classList.add("disabled")
            }
            if(disabled.indexOf(elem.dataset.date) !== -1) {
                elem.classList.add("disabled")
            }
        })
    }
}

function highlightMiddleDates({ picker, selected }) {
    const middles = normalizeDate(getDatesBetween( [selected.from, selected.to] ))

    if(picker) {
        Array.prototype.map.call(picker.querySelectorAll(".date.selected-middle"), elem => {
            elem.classList.remove("selected-middle", "selected-middle-last")
        })

        if(!middles.length) return

        middles.map(date => {
            Array.prototype.map.call(picker.querySelectorAll(`.date[data-date='${date}']`), elem => {
                if(elem && elem.innerHTML.length) {
                    elem.classList.add("selected-middle")
                    if(elem.nextSibling) {
                        if(elem.nextSibling.innerHTML.length === 0) {
                            elem.classList.add("selected-middle-last")
                        }
                    }
                    if(elem.previousSibling) {
                        if(elem.previousSibling.innerHTML.length === 0) {
                            elem.classList.add("selected-middle-first")
                        }
                    }
                }
            })
        })
    }
}

function highlightSelected({ picker, selected }) {
    const selectedArray = [selected.from, selected.to]

    if(picker) {
        Array.prototype.map.call(picker.querySelectorAll(".date.selected"), elem => {
            elem.classList.remove("selected")
        })

        selectedArray.map(date => {
            if(date !== null) {
                Array.prototype.map.call(picker.querySelectorAll(`.date[data-date='${date}']`), elem => {
                    if(elem && elem.innerHTML.length) {
                        elem.classList.remove("disabled")
                        elem.classList.add("selected")
                    }
                })      
            }
        })
    }
}
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { generateStateKey } from '../../../../../utilities/generateStateKey'
import { cl } from '../../../../../utilities/cl'

import { OBJECT_OBJECT } from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'
import ButtonCircle from '../../../../../components/ButtonCircle'
import SVGPlus from '../../../../../components/SVG/Plus'
import InputText from '../../../../../components/Form/InputText'
import { Button as ButtonBS } from 'react-bootstrap'
import Button from "../../../../../components/Button"
import SVGTrash from '../../../../../components/SVG/Trash'
import Tags from '../../../../../components/Tags'
import { loadNotifications, updateNotifications } from '../../../../../store/Notifications/actions'
import Spinner from '../../../../../components/Spinner'
import { OLD_LK } from '../../../../routes'
import InputTextarea from '../../../../../components/Form/InputTextarea'
import axios from 'axios'
import Switch from '../../../../../components/Form/Switch'
import { loadPvData, updatePvData } from '../../../../../store/Features/actions'

const SettingsInterface = ({
    className = "",
    features,
    updatePvData
}) => {

    className = cl(
        className,
        "SettingsObjectObjectCollapsible"
    )



    const [state, setState] = useState(features)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setState(features)
    }, [features])

    const save = async () => {
        // setLoading(true)

        try {
            const { note, mute } = state

            updatePvData({ note, mute })
        } catch (error) {
            console.log({ error });
        } finally {
            loadPvData()
            // setLoading(false)
        }
    }

    useEffect(() => { setState(features) }, [features])

    return (
        <div className='SettingsFeatures'>
            <div><h2>Особенности программирования эфира</h2></div>
            <div className='SettingsFeatures__description_parent'>
                <InputTextarea
                    label="Описание"
                    onChange={(value) => setState(oldState => ({ ...oldState, note: value.value }))}
                    attrs={{
                        name: 'note',
                        value: state['note'],
                        placeholder: "Введите текст",
                        forcedCheck: true
                    }}
                />
            </div>
            <div className='SettingsFeatures__description_parent'>
                <label class="SettingsFeatures__label">Отключение громкости на всей учётной записи</label>
                <Switch
                    label="ds"
                    value={state['mute'] || false}
                    onChange={(value) => setState(oldState => ({ ...oldState, mute: value.value }))}
                    name="mute"
                />
            </div>
            <ButtonBS disabled={(state['mute'] === features['mute'] && state['note'] === features['note']) || loading} style={{ marginTop: 40, width: 80 }} onClick={save}>Сохранить</ButtonBS>


        </div>
    )
}


const stateToProps = state => ({
    features: state.features,
})

const actionsToProps = {
    updatePvData
}

export default connect(stateToProps, actionsToProps)(SettingsInterface)
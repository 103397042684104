import React from 'react'


const Ok = ({ title = '', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-'+variant : ''} ${size ? 'spp-svg-size-'+size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.901 8.072">
                <title>{title}</title>
                <path d="M10.4 0a.498.498 0 00-.353.146L3.33 6.863.856 4.39a.501.501 0 00-.71.707l2.831 2.828a.498.498 0 00.707 0l7.07-7.07A.499.499 0 0010.401 0z" fill="#0a0a0a"/></svg>
        </i>
    )
} 

export default Ok 
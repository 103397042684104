export function parseTags(tags = []) {
    // if(str === "{}" || typeof str !== "string") return []
    // let parsed

    // try {
    //     parsed = JSON.parse(str)
    // }
    // catch(e) {
    //     console.log("Ошибка парсинга тегов.")
    //     console.log(e)
    // }
    
    // return parsed
    return tags

    // str = str.slice(1)
    // str = str.slice(0, -1)

    // str = str.split(",")
    // const tags = str.map(tag => {
    //     if(tag[0] === '"') tag = tag.slice(1)
    //     if(tag[tag.length-1] === '"') tag = tag.slice(0, -1)
    //     return tag
    // })

    // return tags
}
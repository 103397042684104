import React from 'react'
import {Form} from 'react-bootstrap'

import Group from './Group'
import Item from './Item'

import Datepicker from '../../../Form/Datepicker'
import Select from '../../../Form/Select'
import CycleTime from '../../../Form/CycleTime'
import InputText from '../../../Form/InputText'
import InputPassword from '../../../Form/InputPassword'
import InputIPAddress from '../../../Form/InputIPAddress'
import InputNumber from '../../../Form/InputNumber'
import InputTextarea from '../../../Form/InputTextarea'
import Switch from '../../../Form/Switch'


const GroupController = ({
    data,
    items = [],
    element
}) => (

    (items.length || React.isValidElement(element))

        && <Group
            className={data.className}
            title={data.title}
            subtitle={data.subtitle}
            typeTitle={data.typeTitle}
            comment={data.comment}
            wrap={data.wrap}
            alignHeight={data.alignHeight}
            noDivider={data.noDivider}
            columns={data.columns}
            children={data.children}
            disabled={data.disabled}
        >
            
            {(React.isValidElement(element))
                ? element
                : items.map((item, index) => (

                    (React.isValidElement(item))
                        ? item
                        : <Item
                            className={item.className}
                            title={item.title}
                            subtitle={item.subtitle}
                            disabled={item.disabled}
                            key={index}
                        >

                            {item.element &&
                                item.element
                            }

                            {item.components &&
                                item.components.map((component, index) => (
                                    <React.Fragment>
                                        {(component.type === "Element")
                                            && React.cloneElement(component.data, {key: index})
                                        }

                                        {(component.type === "Datepicker")
                                            && <Datepicker
                                                key={index}
                                                {...component.data}
                                                // className={component.data.className}
                                                // title={component.data.title}
                                                // titles={component.data.titles}
                                                // type={component.data.type}
                                                // disabledType={component.data.disabledType}
                                                // default={component.data.default}
                                                // data={component.data.data}
                                                // durationInput={component.data.durationInput}
                                                // variant={component.data.variant}
                                                // onChange={component.data.onChange}
                                            />
                                        }
            
                                        {(component.type === "Check")
                                            && <Form.Check {...component.data} key={index}/>
                                        }
            
                                        {(component.type === "Select")
                                            && <Select
                                                key={index}
                                                {...component.data}
                                                // id={component.data.id}
                                                // label={component.data.label}
                                                // name={component.data.name}

                                                // options={component.data.options}
                                                // container={component.data.container}
                                                // h={component.data.h}
                                                // m={component.data.m}

                                                // className={component.data.className}
                                                // type={component.data.type}
                                                // icon={component.data.icon}
                                                // variant={component.data.variant}
                                                // fontStyle={component.data.fontStyle}
                                                // onClick={component.data.onClick}

                                                // placeholder={component.data.placeholder}
                                                // options={component.data.options}
                                                // selected={component.data.selected}
                                                // resetMultiple={component.data.resetMultiple}
                                                // multiple={component.data.multiple}
                                                // search={component.data.search}
                                                // onChange={component.data.onChange}
                                                // disabled={component.data.disabled}
                                            />
                                        }

                                        {(component.type === "CycleTime")
                                            && <CycleTime
                                                key={index}
                                                {...component.data}
                                                // id={component.data.id}
                                                // label={component.data.label}
                                                // name={component.data.name}

                                                // options={component.data.options}
                                                // container={component.data.container}
                                                // h={component.data.h}
                                                // m={component.data.m}

                                                // className={component.data.className}
                                                // type={component.data.type}
                                                // icon={component.data.icon}
                                                // variant={component.data.variant}
                                                // fontStyle={component.data.fontStyle}
                                                // onClick={component.data.onClick}

                                                // placeholder={component.data.placeholder}
                                                // options={component.data.options}
                                                // selected={component.data.selected}
                                                // resetMultiple={component.data.resetMultiple}
                                                // multiple={component.data.multiple}
                                                // search={component.data.search}
                                                // onChange={component.data.onChange}
                                                // disabled={component.data.disabled}
                                            />
                                        }

                                        {(component.type === "InputText")
                                            && <InputText {...component.data} key={index}/>
                                        }

                                        {(component.type === "InputPassword")
                                            && <InputPassword {...component.data} key={index}/>
                                        }

                                        {(component.type === "InputIPAddress")
                                            && <InputIPAddress {...component.data} key={index}/>
                                        }

                                        {(component.type === "InputNumber")
                                            && <InputNumber {...component.data} key={index}/>
                                        }

                                        {(component.type === "InputTextarea")
                                            && <InputTextarea {...component.data} key={index}/>
                                        }

                                        {(component.type === "Switch")
                                            && <Switch {...component.data} key={index}/>
                                        }
                                    </React.Fragment>
                                ))
                            }
        
                        </Item>
                ))
            }

        </Group>
)

export default GroupController
import React from 'react'
import {connect} from 'react-redux'

import {toggleSelectedTags} from '../../store/ObjectsByTags/actions'
import {toggleSelectedObject} from '../../store/Objects/actions'

import ObjectsByTags from './ObjectsByTags'


const ObjectsByTagsContainer = props => (
    <ObjectsByTags
        className={props.className}
        tags={props.tags}
        loading={props.loading}
        toggleSelectedTags={props.toggleSelectedTags}
        toggleSelectedObject={props.toggleSelectedObject}
        selectedTags={props.selectedTags}
        selectedObjects={props.selectedObjects}
    />
)

const stateToProps = state => ({
    tags: state.objectsByTags.tags,
    loading: state.objectsByTags.loading,
    selectedTags: state.objectsByTags.selected,
    selectedObjects: [...state.objects.selected],
})

const actionsToProps = {
    toggleSelectedTags,
    toggleSelectedObject
}

export default connect(stateToProps, actionsToProps)(ObjectsByTagsContainer)
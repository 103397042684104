import {takeEvery, call, put, all, select} from 'redux-saga/effects'
import {buildGETUrl} from '../../../utilities/buildGETUrl'
import {DELETE_CLIPS, loadClips} from '../../../store/Settings/actions/components/Clips'
import {loadTracks} from '../../../store/Tracks/actions'
import {loadIndentObjects} from '../../../store/Objects/actions'
import {deleteMark} from '../../../store/Marks/actions'
import {showAlertSuccess, showAlertError} from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_CLIPS, worker)
}

function* worker({payload}) {
    const selectedTracks = yield select(state => state.tracks.selected)
    const selectedObjects = yield select(state => state.objects.selected)

    try {
        let clipsDatas = payload.clipsDatas
        clipsDatas = clipsDatas.map(clipData => validateClipData(clipData))

        const clipsDeleted = yield all(
            clipsDatas.map(clipData => call(fetchDelete, clipData))
        )

        let isError = false
        clipsDeleted.map(clip => {
            if (clip.error) isError = true
        })

        if (isError) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${DELETE_CLIPS}.`, clipsDeleted],
                text: "Не все расписания были удалены. Попробуйте повторить."
            }))
        } else {
            yield put(showAlertSuccess({
                responses: [`Успешное выполнение ${DELETE_CLIPS}.`, clipsDeleted],
                text: "Все расписания были удалены."
            }))
            yield put(loadTracks())
            yield put(loadTracks({theme: true}))
            yield put(loadIndentObjects())

            yield all(
                selectedTracks.map(indentId =>
                    put(loadClips({
                        indentId,
                        objectIds: selectedObjects,
                        required: true
                    }))
                )
            )

            yield all(
                clipsDatas.map(clipData => {
                    if (clipData.mark) {
                        return put(deleteMark(clipData.mark))
                    }
                })
            )
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_CLIPS}.`, e],
            text: "Не все расписания были удалены. Попробуйте повторить."
        }))
    }
}

function fetchDelete(clipData) {
    let params = {
        fromdate: clipData.fromdate,
        todate: clipData.todate,
        channel: clipData.channel,
        indent_id: clipData.indent_id,
        ms_id: clipData.ms_id,
    };
    if (clipData.mark) {
        params = {
            mark: clipData.mark
        }
    }
    return axios.get("/timeline/clip/delete", {
        params: params
    })
        .then(response => response.data)
}

function validateClipData(clipData) {
    if (Array.isArray(clipData.indentIds)) clipData.indentId = clipData.indentIds.join(",")
    if (Array.isArray(clipData.indentId)) clipData.indentId = clipData.indentId.join(",")
    if (Array.isArray(clipData.objectIds)) clipData.objectId = clipData.objectIds.join(",")
    if (Array.isArray(clipData.objectId)) clipData.objectId = clipData.objectId.join(",")

    if (clipData.indentId) clipData.indent_id = clipData.indentId
    if (clipData.objectId) clipData.ms_id = clipData.objectId

    delete clipData.indentIds
    delete clipData.indentId
    delete clipData.objectIds
    delete clipData.objectId
    delete clipData.__inday

    return clipData
}
import {LOADING, PUT_META, PUT_MEDIAPLAN} from './actions'

const initialState = {
    loading: false,
    mediaplan: []
}

const reducer = (state = initialState, { type, payload }) => {
    switch(type) {

        case LOADING:
            return {
                ...state,
                loading: payload
            }

        case PUT_MEDIAPLAN:
            return {
                ...state,
                loading: false,
                mediaplan: payload,
            }
    }
    return state
}

export default reducer
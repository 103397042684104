import React from 'react'

import { cl } from '../../../utilities/cl'
import { BsFillVolumeMuteFill } from 'react-icons/bs'


const ObjectItem = ({
    id,
    obj,
    name,
    selected,
    statusObject,
    markItems,
    filteredTagId = -1,
    tags = {},
    address,
    fullName,
    mute,
    statusText
}) => {

    if (filteredTagId !== -1 && typeof tags[filteredTagId] !== "undefined") {
        if (tags[filteredTagId].objectsIds.indexOf(id) === -1) {
            return null
        }
    }

    const className = cl(
        "Objects__item",
        "selectable-item",
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
    )
    //const fullName = (name && address && alias) ? <i>{alias}</i> : `${name} ${address || '-'}`
    //const statusText = obj.statusText;
    return (
        <div className={className} data-object-id={id} title={id} style={{ justifyContent: 'center' }}>
            <span className="selectable-item__color">
                {mute && mute === true && <BsFillVolumeMuteFill size="15px" color='#ff3347' title='Звук отключен на объекте'  style={{marginBottom: 3}}/>}

                {statusText ? <span className="badge badge-info">{statusText}</span> : ''} <span>{fullName}</span>
            </span>
        </div>
    )
}

export default ObjectItem
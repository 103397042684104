import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../../utilities/generateStateKey'
import {cl} from '../../../../../utilities/cl'

import {OBJECT_MODEM} from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'
import ScrollContainer from '../../../../../components/ScrollContainer'


const SettingsModemCollapsible = ({
    className = "",
    selectedObjects,
    placeNumber,
    active
}) => {

    const stateKey = generateStateKey([ selectedObjects ])

    const hSave = () => {
        const collapsible = document.querySelector(".SettingsObjectModemCollapsible")
        const buttonSave = collapsible.querySelector(".SettingsObjectModemSave button")
        if(buttonSave) {
            buttonSave.click()
        }
    }

    className = cl(
        className,
        "SettingsObjectModemCollapsible"
    )

    return(
        <Collapsible
            active={active}
            id={222}
            className={className}
            title="МОДЕМ/WI-FI"
            placeNumber={placeNumber}
            buttons={{
                save: {
                    on: true,
                    mobile: false,
                    onClick: hSave
                }
            }}
        >
            {/* <ScrollContainer
                className="SettingsModemCollapsible__MainScrollContainer"
                size="mg"
                collapsed={false}
            > */}
                <Settings
                    set={OBJECT_MODEM}
                    data={{
                        objectIds: selectedObjects,
                        stateKey: stateKey,
                    }}
                />
            {/* </ScrollContainer> */}
        </Collapsible>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
})

export default connect(stateToProps, null)(SettingsModemCollapsible)
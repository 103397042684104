export default function({
    settings = {},
    name,
    onChange
}) {

    return [
        {
            type: "InputPassword",
            data: {
                onChange,
                attrs: {
                    value: settings[name],
                    placeholder: "Введите пароль"
                }
            }
        }
    ]
}
import {takeEvery, call, put, select} from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { CREATE_ADVERT, loading } from '../../../store/Modal/types/AdvertCreate/actions'
import { loadTracks } from '../../../store/Settings/actions/sets/Object/Schedule'
import { closeModal } from '../../../store/Modal/actions'
import {showAlertError, showAlertSuccess} from '../../../store/Alert/actions'
import axios from 'axios'
import {REQUEST_MEDIAPLAN} from "../../../store/Modal/types/Mediaplan/actions";

export default function* () {
    yield takeEvery(REQUEST_MEDIAPLAN, worker)
}

function* worker({ payload }) {
    yield put(loading(true))

    try {
        const indentSelectedId = yield select(state => state.tracks.selected[0])
        const created = yield call(fetchCreate, payload.email, indentSelectedId)

        if (created.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${REQUEST_MEDIAPLAN}.`, created],
                text: "Запрос не был отправлен. " + created.error
            }))

        } else {
            yield put(showAlertSuccess({
                text: "Запрос успешно отправлен"
            }))
            yield put(closeModal("mediaplan"))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${REQUEST_MEDIAPLAN}.`, e],
            text: "Запрос не был отправлен. " + e.message
        }))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchCreate(email, indent_id) {

    return axios(
        {
            url: "/mail/mediaplan",
            method: "POST",
            data: {email, indent_id},
            params: {indent_id, email}
        }
    )
        .then(response => response.data)
}
import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_MUSIC_REPORT, loading, putMusicReport } from '../../../store/Reports/Music/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_MUSIC_REPORT, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        const music_report = yield call(fetchMusicReport, payload)
        if (music_report.error && music_report.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_MUSIC_REPORT}.`, music_report],
                text: "Отчет не был загружен. Попробуйте перезагрузить страницу."
            }))
            yield put(putMusicReport([]))
        } else {
            yield put(putMusicReport({ music_report: music_report.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_MUSIC_REPORT}.`, e],
            text: "Отчет не был загружен. Попробуйте перезагрузить страницу."
        }))
        yield put(putMusicReport([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchMusicReport(search) {
    return axios.get("/msbox/report/music", { params: { id: search.id, date: search.date } })
        .then(response => response.data)
}

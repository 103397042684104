export const validateMap = {
    "inside": {
        required: true,
        type: "number",
        min: 0,
    },
    "outside": {
        required: true,
        type: "number",
        min: 0,
    },
    "back": {
        required: true,
        type: "number",
        min: 0,
    },
    "starttime": {
        required: true,
        type: "string",
        min: 1,
    },
    "daymask": {
        required: true,
        type: "number",
        min: 1,
    },
}
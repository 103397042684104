import React from 'react'
import Navbar from '../../components/Navbar'

import Space from '../../components/SpaceContent'

import Panel from '../Templates/Panel'
import What from './What'
import Where from '../Advert/Workspace/Where'
import When from '../Advert/Workspace/When'


const Main = props => (
    <section className='Settings'>
        <Navbar page={ props.match.path } />
        <Panel page={ props.match.path } />
        <Space>
            <What />
            {/* <Where disabled={ true } /> */ }
            {/* <When /> */ }
        </Space>
    </section>
)

export default Main
import { takeEvery, call, put } from 'redux-saga/effects'
import { DELETE_ROLE, loading, loadRoles } from '../../store/Roles/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_ROLE, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        const response = yield call(deleteRequest, payload)
        if (response.error && response.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${DELETE_ROLE}.`, response],
                text: "При удалении пользователя произошла ошибка." + response.error
            }))
            // yield put(putSubusers([]))
        } else {
            yield put(loadRoles())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_ROLE}.`, e],
            text: "При удалении пользователя произошла ошибка сети. " + e.message
        }))
        // yield put(putSubusers([]))
    }
    finally {
        yield put(loading(false))
    }
}

function deleteRequest(id) {
    // let formData = new FormData()
    // Object.keys(userdata).forEach(name => {
    //     formData.set(name, userdata[name])
    // })
    return axios({
        url: "/account/roles/delete",
        params: {
            id
        },
        method: "POST",
        //data: formData
    })
        .then(response => response.data)
}

export function encodeBitmap(numbers, map) {
    if(Array.isArray(numbers) && numbers.length) {
        if(typeof map === "object") {
            let numberMask = 0
            numbers.map(num => numberMask += map[num])
            return numberMask
        }
    }
}

export function parseBitmap(number, map) {
    if(typeof number === "number") {

        // Если передан обычный массив
        if(Array.isArray(map)) {
            let answer = []
            map.sort((a, b) => a < b ? 1 : -1)

            map.map(item => {
                if(number - item >= 0) {
                    number = number - item
                    answer.push(item)
                }
            })
            return answer

        // Если ассоциативный в формате ( {искомое значение: кратное значение маски} )
        } else if(typeof map === "object") {
            let answer = [],
                arrMap = []

            Object.keys(map).forEach((key) => {
                arrMap.push({
                    key: key,
                    value: Number(map[key])
                })
            })

            arrMap.sort((a, b) => a.value < b.value ? 1 : -1)

            arrMap.map(item => {
                if(number - item.value >= 0) {
                    number = number - item.value

                    if(!isNaN(Number(item.key))) {
                        answer.push(Number(item.key))
                    } else {
                        answer.push(item.key)
                    }
                    
                }
            })
            return answer
        }
    }
}

export const maps = {
    daymask: [64, 32, 16, 8, 4, 2, 1],
    daymaskWidthAds: [128, 64, 32, 16, 8, 4, 2, 1],
    blockmask: {
        1: 1,
        2: 2,
        3: 4,
        4: 8,
        5: 16,
        6: 32,
        7: 64,
        8: 128,
        9: 256,
        10: 512,
        11: 1024,
        12: 2048,
    }
}
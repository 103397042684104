import React from 'react'


const FileC = ({ title = '', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.051 4.234">
                <title>{ title }</title>
                <g data-name="Group 2654" strokeWidth="1.097"><path d="M.425 0A.43.43 0 000 .426v3.382c0 .234.192.425.425.426h2.201a.429.429 0 00.425-.426V1.165L2.47.583 1.985.098A.132.132 0 001.98.092a.132.132 0 00-.004-.003L1.888 0h-.055zm0 .265H1.76v.603c.001.233.192.425.425.426h.602v2.513a.16.16 0 01-.162.162h-2.2a.16.16 0 01-.16-.161V.426c0-.09.07-.161.16-.162zm1.6.247l.259.259.258.258h-.356a.16.16 0 01-.161-.161zm-.5 1.146c-.175 0-.312.046-.41.14a.53.53 0 00-.156.384 5.401 5.401 0 000 .344.54.54 0 00.154.388c.098.09.236.135.413.135.114 0 .214-.019.299-.058a.474.474 0 00.196-.162.416.416 0 00.075-.23.032.032 0 00-.012-.029.04.04 0 00-.028-.011h-.187c-.015 0-.027.003-.035.011-.008.007-.014.019-.02.037-.019.08-.053.136-.102.17a.326.326 0 01-.186.048c-.184 0-.28-.103-.287-.31a5.057 5.057 0 010-.324c.007-.206.103-.309.287-.309a.32.32 0 01.188.05c.048.033.081.089.1.168a.09.09 0 00.02.04c.008.006.02.01.035.01h.187c.01 0 .02-.004.027-.01a.034.034 0 00.013-.028v-.004a.416.416 0 00-.075-.23.46.46 0 00-.196-.16.695.695 0 00-.3-.06z" aria-label="C" fontWeight="500" fontSize="8" fontFamily="Rubik-Medium,Rubik" letter-spacing=".04em" fill="#fff" /></g></svg>
        </i>
    )
}

export default FileC
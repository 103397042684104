import React from 'react'
import {componentDisabled} from '../../../../utilities/componentDisabled'


const SettingsGroupItem = ({ className = "", title = null, subtitle, children, disabled }) => {
    return(
        <fieldset disabled={disabled} onClickCapture={(e) => componentDisabled(e, disabled)}>
            <section className={`SettingsGroupItem ${className} ${disabled ? "disabled" : ""}`}>
                
                {(title !== null)
                    && <div className="SettingsGroupItem__title">
                        {title}
                        {subtitle
                            && <div className="subtitle">
                                {subtitle}
                            </div>
                        }
                    </div>
                }

                <div className="SettingsGroupItem__content">
                    {children}
                </div>

            </section>
        </fieldset>
    )
}

export default SettingsGroupItem
import React, {useEffect} from 'react'
import { connect } from 'react-redux'

import { deleteClips } from '../../../store/Settings/actions/components/Clips'
import { openModal, closeModal } from '../../../store/Modal/actions'
import { MAIN_OBJECT, MAIN_ADVERT, MAIN_CONTENT } from '../../routes'

import { download } from '../../../utilities/download'
import { buildGETUrl } from '../../../utilities/buildGETUrl'

import Panel from '../../../components/Panel'
import Place from '../../../components/Panel/Place'

import Button from '../../../components/Button'
import Search from '../../../components/Search'

import SvgFile3 from '../../../components/SVG/File3'
import SvgFileC from '../../../components/SVG/FileC'
import SvgTrash from '../../../components/SVG/Trash'
import {modeActiveOn} from "../../../store/Search/actions";


const AdvertPanel = ({
    page,
    searchState,
    changeExpandedPlace,
    changeActivePlace,
    openModal,
    closeModal,
    selectedTracks,
    selectedObjects,
    currentMarks,
    currentMarkId,
    deleteClips,
                         modeActiveOn
}) => {
    const disabled = isDisabled(page)
    function keyboardHandler(event) {
        const key = parseInt(event.keyCode || event.which || 0, 10);
        if ((event.ctrlKey || event.metaKey) && key === 70) {
            console.log('CTRL+F Detected!');
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
            modeActiveOn('tracks');
            document.getElementById('search-input').focus();
            return false;
        }
    }
    useEffect(() => {
        window.document.addEventListener("keydown", keyboardHandler);
        //console.log('keyboardHandler started')
        return () => {
            window.document.removeEventListener("keydown", keyboardHandler);
            //console.log('keyboardHandler stopped')
        };
    }, []);
    return (
        <Panel page={ page } disabled={ disabled }>

            {/* 1 */ }

            <Place
                title="Что"
                number={ 1 }
                className={ `${(searchState["tracks"].modeActive) ? "right-extend" : ""}` }
                disabled={ disabled }
                elements={ {
                    left: <>
                        <Button
                            variant="light"
                            type="string"
                            disabled={ disabled || ((selectedTracks[0] && !selectedTracks[1]) ? false : true) }
                            // onClick={ () => download(buildGETUrl("//a.ms-box.ru/docs/select", { id: selectedTracks[0], t: "indent" })) }
                            onClick={ () => download(buildGETUrl("docs/select", { id: selectedTracks[0], t: "indent" }, 'https://a.ms-box.ru/')) }
                        >
                            <SvgFile3 /> Заявка
                        </Button>
                        <Button
                            variant="light"
                            type="string"
                            disabled={ disabled || (!selectedTracks.length) }
                            onClick={ () => openModal({ type: "castinfo" }) }
                        >
                            <SvgFileC /> Эфирная справка
                        </Button>
                    </>,

                    right: <>
                        <Search placeholder="Поиск по роликам" searchType="tracks" disabled={ disabled } />
                    </>
                } }
            />

            {/* 2 */ }

            <Place
                title="Где"
                number={ 2 }
                className="right-extend"
                changeActivePlace={ changeActivePlace }
                disabled={ disabled }
                elements={ {
                    right: <Search placeholder="Поиск по объектам" searchType="objects" disabled={ disabled } />
                } }
            />

            {/* 3 */ }

            <Place
                title="Когда"
                number={ 3 }
                disabled={ disabled }
                elements={ {
                    left: <>

                    </>
                } }
            />

        </Panel>
    )
}

const stateToProps = state => ({
    searchState: state.search,
    selectedObjects: [...state.objects.selected],
    selectedTracks: [...state.tracks.selected],
    currentMarkId: state.marks.current,
    currentMarks: [...state.marks[state.marks.current]?.items]
})

const actionsToProps = {
    openModal, closeModal, deleteClips, modeActiveOn
}

export default connect(stateToProps, actionsToProps)(AdvertPanel)

//
// Функции
//

function isDisabled(page) {
    return (page !== MAIN_OBJECT && page !== MAIN_ADVERT && page !== MAIN_CONTENT) ? true : false
}

// Удаление расписания

function openDeleteModal({ openModal, closeModal, deleteClips, selectedTracks, selectedObjects, marks, markId }) {
    if (Array.isArray(selectedTracks) && Array.isArray(selectedObjects) && Array.isArray(marks)) {

        const deleteType =
            (marks.indexOf(selectedTracks[0]) === -1)
                ? "simple"
                : (selectedTracks.length > 1)
                    ? "simple"
                    : "mark"

        if (deleteType === "simple") {
            openModal({
                type: "confirm",
                size: "sm",
                data: {
                    title: "Вы точно хотите удалить данное размещение?",
                    buttons: {
                        cancel: {
                            title: "Вернуться",
                            onClick: () => {
                                closeModal("confirm")
                            }
                        },
                        confirm: {
                            title: "Удалить",
                            onClick: () => {
                                deleteClips([{
                                    indentId: selectedTracks,
                                    objectId: selectedObjects,
                                }])
                                closeModal("confirm")
                            }
                        }
                    }
                }
            })
        } else {
            openModal({
                type: "confirm",
                size: "sm",
                data: {
                    title: "Вы хотите удалить групповое расписание, или всё размещение для данного ролика?",
                    buttons: {
                        cancel: {
                            title: "Групповое расписание",
                            onClick: () => {
                                closeModal("confirm")
                                openDeleteConfirm({
                                    deleteClips,
                                    openModal,
                                    closeModal,
                                    title: "Вы точно хотите удалить групповое расписание?",
                                    clip: { mark: markId }
                                })
                            }
                        },
                        confirm: {
                            title: "Всё размещение для ролика",
                            onClick: () => {
                                closeModal("confirm")
                                openDeleteConfirm({
                                    deleteClips,
                                    openModal,
                                    closeModal,
                                    title: "Вы точно хотите удалить всё размещение для ролика?",
                                    clip: {
                                        indentIds: selectedTracks,
                                        objectIds: selectedObjects,
                                    }
                                })
                            }
                        }
                    }
                }
            })
        }
    }
}

function openDeleteConfirm({ deleteClips, openModal, closeModal, title, clip }) {
    openModal({
        type: "confirm",
        size: "sm",
        data: {
            title,
            buttons: {
                cancel: {
                    title: "Вернуться",
                    onClick: () => {
                        closeModal("confirm")
                    }
                },
                confirm: {
                    title: "Удалить",
                    onClick: () => {
                        deleteClips([clip])
                        closeModal("confirm")
                    }
                }
            }
        }
    })
}

function isDeleteDisabled({ disabled, selectedTracks, selectedObjects }) {
    if (disabled === true) return true
    if (!selectedTracks[0] || !selectedObjects[0]) return true
    if (selectedTracks[1] || selectedObjects[1]) return true
    return false
}

// function getActiveTimelineClipId(selectedTracks, selectedObjects, clipsSettings) {
//     if(    (selectedTracks[0] && !selectedTracks[1])
//         && (selectedObjects[0] && !selectedObjects[1])
//     ){
//         const trackId = selectedTracks[0]
//         const objectId = selectedObjects[0]
//         if(clipsSettings[trackId]) {
//             if(clipsSettings[trackId][objectId]) {
//                 const id = clipsSettings[trackId][objectId].id
//                 if(id) {
//                     if( !isNaN(Number(id)) ) {
//                         return Number(id)
//                     }
//                 }
//             }
//         }
//     }
//     return false
// }
import outside from "../../utilities/outside";
import {hideAlertError} from "../../store/Alert/actions";
import {connect} from "react-redux";
import React from "react";
import Spinner from "../Spinner";
import {cl} from "../../utilities/cl";

const Loading = ({isLoading, loadingText}) => {
    if (isLoading) {
        return (
            <div className={cl({'App--is-loading': isLoading})}>
                <Spinner className="App-Spinner" text={loadingText}/>
            </div>
        )
    } else {
        return <></>
    }
}

const stateToProps = state => ({
    isLoading: state.app.loading,
    loadingText: state.app.loadingText,
})

export default connect(stateToProps, null)(Loading)
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Button from '../../../../../components/Button'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'
import { openModal, closeModal } from '../../../../../store/Modal/actions'
import VideolistsComponent from '../../../../../components/ContentVideo'
import Collapsible from '../../../../../components/SpaceContent/Collapsible'
import List from '../../../../../components/List'
import { deleteVideolists, sortVideolists, loadVideolists } from '../../../../../store/ContentVideolists/actions'
import ContentMobile from '../../../../../components/ContentMobile/Video'
import { isMobile } from '../../../../../utilities/media'
import { MEDIA_BREAKPOINT_MD, MEDIA_BREAKPOINT_LG } from '../../../../../utilities/breakpoints'

const Videolists = ({
    className = "",
    placeNumber,
    selectedVideolists,
    deleteVideolists,
    openModal,
    closeModal,
    currentVideolist,
    sortVideolists,
    expandedPlace,
    loadVideolists,
    allVideolists
}) => {

    const [sort_direction, setSortDirection] = useState("")
    const [active, setActive] = useState(false)
    useEffect(() => {

        if (expandedPlace === 1) {
            //console.log(expandedPlace)
            setActive(true)
        } else {
            setActive(false)
        }
    }, [expandedPlace])

    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortVideolists({ direction: sort_direction, sort_type: type })
    }



    return (
        <div data-id={ `video` } className={ `${className}` } style={ { width: "100%" } }>
            <Collapsible
                className={ `CollapsibleVideo ${className}` }
                active={ active }
                id={ 111 }
                name={ `video` }
                placeNumber={ placeNumber }
                title="Видео"
                buttons={ {
                    add: {
                        on: true,
                        title: "Создать список",
                        onClick: () => openModal({
                            type: "videolistCreate",
                            size: "sm",
                        })
                    },

                    remove: {
                        on: true,
                        disabled: (selectedVideolists[0]) ? false : true,
                        onClick: () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: `Вы действительно хотите удалить список "${currentVideolist.name}"?`,
                                buttons: {
                                    cancel: {
                                        title: "Вернуться",
                                        onClick: () => {
                                            closeModal("confirm")
                                        }
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deleteVideolists(selectedVideolists)
                                            closeModal("confirm")
                                        }
                                    }
                                }
                            }
                        })
                    },
                    edit: {
                        on: true,
                        disabled: (selectedVideolists[0]) ? false : true,
                        title: "Редактировать видео список",
                        onClick: () => openModal({
                            type: "videolistEdit",
                            size: "sm",
                        })
                    },
                } }
            >

                <header className="Videolists__control">
                    { window.outerWidth > MEDIA_BREAKPOINT_LG || !isMobile()
                        ? false
                        : <h4 style={ { marginBottom: "22px" } }> Мои списки</h4>
                    }
                    <div className="Videolists__grid">
                        <div className="name">
                            <Button
                                type="string"
                                onClick={ () => sort("name") }
                            >
                                <SvgArrowUpDown title="Сортировка по название плейлиста" />
                                Название
                            </Button>
                        </div>
                        <div className="tracks_count ">
                            <Button
                                type="string"
                                onClick={ () => sort("songs") }
                            >
                                <SvgArrowUpDown title="Сортировка по кол-ву треков" />
                                <span>Треков</span>
                            </Button>
                        </div>
                        <div className="size">
                            <Button
                                type="string"
                                onClick={ () => sort("size") }
                            >
                                <SvgArrowUpDown title="Сортировка по размеру плейлиста" />
                                <span>Размер</span>
                            </Button>
                        </div>
                        <div className="duration">
                            <Button
                                type="string"
                                onClick={ () => sort("duration") }
                            >
                                <SvgArrowUpDown title="Сортировка по длительности" />
                                <span>Длительность</span>
                            </Button>
                        </div>
                    </div>
                </header>
                { window.outerWidth > MEDIA_BREAKPOINT_LG
                    ? <VideolistsComponent types={ ["simple"] } allVideolists={ allVideolists } />
                    :
                    <div>
                        <ContentMobile />
                    </div>
                }
            </Collapsible>
        </div>
    )
}

const stateToProps = state => ({
    selectedVideolists: state.videolists.selected,
    allVideolists: state.videolists.allVideolists,
    // currentVideolist: state.videolists.allVideolists.filter(videolist => videolist.id === state.videolists.selected[0])[0],
    currentVideolist: state.videolists.currentVideolist,
    expandedPlace: state.panel.expandedPlace
})

const actionsToProps = {
    deleteVideolists,
    openModal,
    closeModal,
    sortVideolists,
    loadVideolists
}

export default connect(stateToProps, actionsToProps)(Videolists)
import {takeEvery, put, select} from 'redux-saga/effects'
import {PUT_OBJECTS} from '../../store/Objects/actions'
import {putObjectsTags, loading} from '../../store/ObjectsByTags/actions'
import {parseTags} from '../../utilities/parseTags'

export default function* () {
    yield takeEvery(PUT_OBJECTS, worker)
}

function* worker({ payload }) {
    const objects = payload
    let tags = [],
        tagsAssoc = {}

    if(objects.length) {
        let objectTags

        objects.map(object => {

            if(Array.isArray(object.tags)) {
                objectTags = parseTags(object.tags)

                if(Array.isArray(objectTags)) {

                    // Сохраняем теги
                    objectTags.map(tag => {
                        let tagIndex = -1
                        tags.map((t, index) => {
                            if(t.name === tag) tagIndex = index
                        })
                        
                        if(tagIndex !== -1) {
                            tags[tagIndex].objects.push({
                                id: object.id,
                                name: object.name,
                            })
                            tags[tagIndex].objectsIds.push(object.id)
                            
                        } else {
                            tags.push({
                                id: Number(object.id) + 233,
                                name: tag,
                                value: tag,
                                title: tag,
                                objects: [
                                    { id: object.id, name: object.name }
                                ],
                                objectsIds: [object.id]
                            })
                        }
                    })
                }
            }
        })

        tags.map(tag => tagsAssoc[tag.name] = tag)

        yield put(putObjectsTags({
            tags,
            tagsAssoc
        }))
        yield put(loading(false))
    }
}
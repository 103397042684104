import {takeEvery, call, put, select} from 'redux-saga/effects'
import {
    ADD_SONGS_TO_PLAYLIST,
    LOAD_PLAYLISTS,
    loading,
    loadPlaylists,
    updatePlaylist,
    putPlaylists
} from '../../store/ContentPlaylists/actions'
import { showAlertError, showAlertSuccess } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(ADD_SONGS_TO_PLAYLIST, worker)
}

function* worker({ payload }) {
    //yield put(loading(true))
    try {
        let id_playlist = payload.id_playlist
        let selected_songs = payload.selected_song
        const selectedPlaylist = yield select(state => state.playlists.allPlaylists.find(playlist => playlist.id === id_playlist));
        let add_data = {
            id_playlist: id_playlist,
            name: selectedPlaylist.name,
            songs: selected_songs.join(",")
        }
        const added = yield call(fetchAdd, add_data)
        if (added.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${ADD_SONGS_TO_PLAYLIST}.`, added],
                text: "Треки не были добавлены. Попробуйте повторить."
            }))
        } else {

            let fetchedPlaylist = yield call(fetchPlaylist, id_playlist);
            fetchedPlaylist = fetchedPlaylist.success[0];
            console.log(">>>>>>",fetchedPlaylist);
            let totalsize2 = 0;
            if (fetchedPlaylist.totalsize) {
                let size = Math.floor(fetchedPlaylist.totalsize / 1024 / 1024);
                size = !size ? `${Math.floor(fetchedPlaylist.totalsize / 1024 )} Kb` : `${size} Mb`
                totalsize2 = size
            }
            fetchedPlaylist.totalsize2 = totalsize2
            console.log(">>>>>>",fetchedPlaylist);
            yield put(updatePlaylist({playlist: {...selectedPlaylist, ...fetchedPlaylist}}));

            // let playlists = []
            // const fetchedPlaylists = yield call(fetchPlaylists)
            // if (fetchedPlaylists.error) {
            //     yield put(showAlertError({
            //         errors: [`Ошибка сервера при ${LOAD_PLAYLISTS}.`, fetchedPlaylists],
            //         text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
            //     }))
            //     yield put(putPlaylists({ playlists }))
            // } else {
            //     playlists = fetchedPlaylists.success
            //     yield put(putPlaylists({ playlists }))
            // }
            yield put(showAlertSuccess({
                text: "Плейлист успешно изменен."
            }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${ADD_SONGS_TO_PLAYLIST}.`, e],
            text: "Треки не были добавлены. Попробуйте повторить."
        }))
    }
    finally {

        yield put(loading(false))
    }
}

function fetchAdd(addData) {
    let formData = new FormData()
    if (addData && addData.songs) {
        Object.keys(addData).forEach(name => {
            formData.set(name, addData[name])
        })
    } else {
        formData.set('name', addData.name)
        formData.set('songs', '')
    }
    return axios(
        {
            url: `/playlist/set?id=${addData.id_playlist}`,
            method: "POST",
            data: formData,

        }
    )
        .then(response => response.data)
}

function fetchPlaylist(id) {
    return axios.get(`/playlist/select?id=${id}`)
        .then(response => response.data)
}

function fetchPlaylists() {
    return axios.get("/playlist/select")
        .then(response => response.data)
}
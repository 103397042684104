import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS, Spinner as SpinnerBS } from 'react-bootstrap'
import Spinner from "../../../Spinner";
import { cl } from '../../../../utilities/cl'
import { normalizeNumberSpaces } from '../../../../utilities/normalize'
import { store } from "../../../../connect";
import { update, createAds } from '../../../../store/Modal/types/AdsCreate/actions'
import { loadingText } from '../../../../store/App/actions'

import Header from '../../components/header'

import Uploader from '../../../Uploader'
import Select from '../../../Form/Select'
import InputText from '../../../Form/InputText'
import { loadVideolists } from "../../../../store/ContentVideolists/actions";
import WarningRed from "../../../SVG/WarningRed";

const defaultTrackObj = {
    filename: false,
    name: false,
    videolist_id: false,
    description: false,
    track: {
        name: "—",
        size: "—",
        duration: "—",
    }
}

class AdsCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            filename: false,
            name: false,
            videolist_id: false,
            description: false,
            sumbitDisabled: true,
            track: {
                name: "—",
                size: "—",
                duration: "—",
            },
            pri: null
        }

        this.hSubmit = this.hSubmit.bind(this)
        this.hLoadfile = this.hLoadfile.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.validateForm = this.validateFrom.bind(this)
        this.convertFile = this.convertFile.bind(this)
        this.setVal = this.setVal.bind(this)
        this.allowedTypes = [".mp4", ".3gp", ".mkv", ".avi", ".wmv", ".mpeg", ".mpga", ".mpgv",
            ".mp3", ".aac", ".wav", ".flac", ".ogg",
            ".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"]
    }

    setVal(index, attr, val) {
        // console.log(index, attr, val);
        this.setState((state) => {
            const list = state.list;

            list[index][attr] = val;
            return { list };
        });
    }

    convertFile(file, index) {
        const fileName = (file.name);
        const name = fileName.substring(0, fileName.lastIndexOf('.'));
        if (file.type !== "image/jpeg") {
            const url = URL.createObjectURL(file);
            const media = new Audio(url);
            media.onloadedmetadata = () => {
                this.updateList(file, name, index, media)
                URL.revokeObjectURL(url);
            };
            media.onerror = () => {
                this.updateList(file, name, index)
            }
        } else {
            this.updateList(file, name, index)
        }
    }

    updateList(file, name, index, media) {
        const fileObj = {
            filename: file,
            name: name,
            progress: 0,
            status: 0,
            error: '',
            track: {
                name: file.name,
                size: file.size,
                duration: media && typeof media.duration !== 'undefined' ? media.duration : '-'
            }
        }
        this.setState((state) => {
            const list = state.list;
            list[index] = fileObj;
            return { list };
        }, this.validateFrom);
    }

    hLoadfile(files) {
        console.log("    hLoadfile(files) {            ");
        console.log({files});

        if(files.length && files.length === 1){
            files = files[0]
        }

        if (!Array.isArray(files)) {
            this.setState((state) => {
                return { list: [] };
            });
            let file = files
            const fileName = (file.name);
            const name = fileName.substring(0, fileName.lastIndexOf('.'));
            if (file.type !== "image/jpeg") {
                const url = URL.createObjectURL(file);
                const media = new Audio(url);
                media.onloadedmetadata = () => {
                    this.setState(() => ({
                        filename: file,
                        name: name,
                        track: {
                            name: file.name,
                            size: file.size,
                            duration: media.duration
                        }
                    }), this.validateFrom)
                    URL.revokeObjectURL(url);
                };
                media.onerror = () => {
                    this.setState(() => ({
                        filename: file,
                        name: name,
                        track: {
                            name: file.name,
                            size: file.size
                        }
                    }), this.validateFrom)
                }
            } else {
                this.setState(() => ({
                    filename: file,
                    name: name,
                    track: {
                        name: file.name,
                        size: file.size,
                        duration: "—"
                    }
                }), this.validateFrom)
            }
        } else {
            this.setState(() => ({
                filename: '',
                name: '',
                track: {
                    name: '',
                    size: '',
                    duration: ''
                }
            }));
            [...files].forEach((currentFile, index) => {
                this.convertFile(currentFile, index);
            })
        }

    }

    hInputText({ name, value }, event) {
        if (name) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
    }

    hSubmit() {
        if (this.state.list.length <= 1) {
            this.props.createAds({
                filename: this.state.filename,
                name: this.state.name,
                videolist_id: this.state.videolist_id,
                description: this.state.description || "",
                ...(this.state.pri && !!this.state.pri.length ? { pri: this.state.pri } : {})
            })
        } else {
            this.props.createAds({
                list: this.state.list,
                videolist_id: this.state.videolist_id,
                setVal: this.setVal,
                ...(this.state.pri && !!this.state.pri.length ? { pri: this.state.pri } : {})

            })
        }
    }

    validateFrom() {
        if ((this.state.filename
            && (typeof this.state.name === "string" && this.state.name.length > 1 && this.validatePriFormat(this.state.pri)))
            || this.state.list.length >= 2
            // && (typeof this.state.description === "string" && this.state.description.length > 3)
        ) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
    }

    validatePriFormat = (val) => {
        if (!val)
            return true
        const regex = /^[-]\d+$/;
        const regex2 = /^\d+$/;
        return (regex.test(val) || regex2.test(val));
    };

    componentDidMount() {
        this.props.update()
        this.props.loadVideolists()
    }

    render() {
        return (
            <div className="ModalAdsCreate">
                <Header
                    title="Создание рекламного ролика"
                    toggleOpened={this.props.toggleOpened}
                />

                <div className="ModalDoubleGrid">
                    <div className="ModalDoubleGrid__left">
                        <div className="ModalDoubleGrid__title">
                            Настройка
                        </div>

                        <div className="ModalDoubleGrid__title Uploader-title">
                            Файл
                        </div>

                        <Uploader
                            attrs={{
                                name: "filename",
                                multiple: true,
                                accept: this.allowedTypes.join(',')
                            }}
                            trackName={this.state.list.length <= 1 ? this.state.track.name : `Выбрано объектов: ${this.state.list.length}`}
                            fileTypes={this.allowedTypes}                            
                            onChange={this.hLoadfile}
                            
                        />
                        {
                            this.state.list.length <= 1 ?
                                <div className="ModalAdsCreate__grid">
                                    <InputText
                                        label="Название"
                                        comment="* Внимание! Если имя файла не задано, звук будет импортирован из выбранного по-умолчанию"
                                        attrs={{
                                            name: "name",
                                            value: this.state.name,
                                            placeholder: "Название ролика"
                                        }}
                                        onChange={this.hInputText}
                                    />
                                    <InputText
                                        label="Комментарий"
                                        attrs={{
                                            name: "description",
                                            placeholder: "Текст комментария"
                                        }}
                                        onChange={this.hInputText}
                                    />
                                    <Select
                                        label="Привязать фоновый контент"
                                        type="options"
                                        onChange={this.hInputText}
                                        selected={this.state.videolist_id}
                                        name="videolist_id"
                                        placeholder="Не привязывать"
                                        options={this.props.allVideolists}
                                    />
                                    <InputText
                                        label="Приоритет"
                                        attrs={{
                                            placeholder: "Введите приоритет",
                                            value: this.state.pri,
                                            name: "pri"
                                        }}
                                        onChange={this.hInputText}
                                    />
                                </div> :
                                <>
                                    <Select
                                        className="mb-2"
                                        label="Привязать фоновый контент"
                                        type="options"
                                        onChange={this.hInputText}
                                        selected={this.state.videolist_id}
                                        name="videolist_id"
                                        placeholder="Не привязывать"
                                        options={this.props.allVideolists}
                                    />
                                    <table className="table table-striped table-sm mt-3">
                                        <tbody>
                                            {this.state.list.map((file) => {
                                                return (<tr key={file.name}
                                                    className={cl({ 'table-success': file.status >= 2 }, { 'table-danger': file.status < 0 })}>
                                                    <td>
                                                        {file.status === -1 ? <WarningRed title={file.error}></WarningRed> : ''}

                                                        {file.status === 1 ?
                                                            <SpinnerBS className="mr-2" animation="border" role="status"
                                                                variant="dark-md" size="sm"></SpinnerBS> : ''}
                                                        {file.progress}%
                                                    </td>
                                                    <td>{file.name}</td>
                                                    <td>{convertTime(file.track.duration)}</td>
                                                    <td>{normalizeSize(file.track.size)}</td>
                                                </tr>)
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </>

                        }

                    </div>

                    <div className="ModalDoubleGrid__right">
                        <div className="ModalDoubleGrid__title">
                            Информация
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Оригинальное имя файла</div>
                            <div className={cl("info", { "c-prim": this.state.track.name !== "—" })}>
                                {this.state.track.name}
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Время звучания</div>
                            <div className="info">{convertTime(this.state.track.duration)}</div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Последняя модификация</div>
                            <div className="info"> —</div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Размер в килобайтах</div>
                            <div className="info">
                                {normalizeSize(this.state.track.size)}
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Время создания</div>
                            <div className="info"> —</div>
                        </div>
                    </div>

                </div>

                <div className="Modal__footer">
                    <ButtonBS onClick={this.hSubmit} variant="primary"
                        disabled={this.state.sumbitDisabled || this.props.loading}>
                        {this.props.loading ? "Сохранение..." : "Сохранить"}
                    </ButtonBS>
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
    loading: state.modalAdsCreate.loading,
    allVideolists: [{
        title: "Не привязывать",
        value: 0
    }, ...state.videolists.allVideolists.map((videolist) => ({
        title: videolist.name,
        value: videolist.id,
        subtitle: `${videolist.totalcount} шт (${videolist.totalsize})`
    }))],
})

const actionsToProps = {
    update, createAds, loadVideolists, loadingText
}

function convertTime(time) {
    if (typeof time !== "number") {
        return "--:--";
    }
    var mins = Math.floor(time / 60);
    if (mins < 10) {
        mins = '0' + String(mins);
    }
    var secs = Math.floor(time % 60);
    if (secs < 10) {
        secs = '0' + String(secs);
    }

    return mins + ':' + secs;
}

function normalizeSize(size) {
    if (typeof size === "number") {
        size = Math.ceil(size / 1000)
        size = `${size.toLocaleString()} КБ`
    }
    return size
}

export default connect(stateToProps, actionsToProps)(AdsCreate)
import React, {useState, useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {cl} from '../../../../../utilities/cl'
import InputText from '../../../../../components/Form/InputText'
import Select from '../../../../../components/Form/Select'
import {Button as ButtonBS, Form} from 'react-bootstrap'
import Edit from '../../../../../components/SVG/Edit'
import Eye from '../../../../../components/SVG/Eye'
import Button from '../../../../../components/Button'
import Trash from '../../../../../components/SVG/Trash'
import {
    loadRoles,
    loadAccountInfo,
    createRole,
    deleteRole,
    updateRole,
    updateAccount
} from '../../../../../store/Roles/actions'
import Spinner from '../../../../../components/Spinner'
import {openModal, closeModal} from '../../../../../store/Modal/actions'
import SvgSave from "../../../../../components/SVG/Save";

const SettingsRoles = ({
                           className = "",
                           loadRoles,
                           updateAccount,
                           roles,
                           loading,
                           loadAccountInfo,
                           account,
                           openModal,
                           closeModal,
                           createRole,
                           deleteRole,
                           updateRole
                       }) => {
    let parsed_roles = []
    let roles_list = []
    let show_arr = []
    useEffect(() => {
        loadAccountInfo()
        loadRoles()
    }, [])
    useEffect(() => {
        setAdminEmail(account.email)
        setAdminA2(account.a2)
    }, [account])
    const [roles2, setRoles2] = useState([])
    const [edit_rights, setEditRights] = useState({})
    const [initial_rights, setFullRights] = useState("")

    useEffect(() => {
        let roles_rights = {}
        if (roles.length > 0) {
            let r = [...roles]
            r = r.map((user, index) => {
                if (user.permission) {
                    roles_rights[user.name] = JSON.parse(user.permission)
                } else {
                    roles_rights[user.name] = {}
                }
                user.password = ''
                return user
            })
            setEditRights(roles_rights)
            setFullRights(JSON.stringify(roles_rights))
            setRoles2([...r]);
        }

    }, [roles])
    const [exist, setUserExist] = useState(false)

    roles_list.push({title: "Новая роль", value: 0})
    if (roles.length > 0) {
        parsed_roles = Object.values(roles)
        parsed_roles.map((user, index) => {
            roles_list.push({title: user.description, value: index + 1})
            show_arr.push(false)
        })
    }
    className = cl(
        className,
    )

    const [showpass, setShowpass] = useState(false)
    const [show, setShow] = useState(show_arr)
    const [password, setPassword] = useState("")
    const [login, setLogin] = useState("")
    const [a2, setA2] = useState(false)
    const [email, setEmail] = useState('')

    const [save_rights, setSaveRights] = useState({})

    const [description, setDescription] = useState("")
    const [new_role, setNewRole] = useState("")

    const [adminPassword, setAdminPassword] = useState("")
    const [adminA2, setAdminA2] = useState(false)
    const [adminEmail, setAdminEmail] = useState('')

    const hLoginChange = (e) => {
        for (let su of parsed_roles) {
            if (su.name === e.value) {
                setUserExist(true)
            } else {
                setUserExist(false)
            }
        }
        setLogin(`${e.value.split('-')[1] === undefined ? " " : e.value.split('-')[1]}`)
    }
    const hNewRoleChange = (e) => {
        setNewRole(e.value)
    }
    const handleShow = () => {
        setShowpass(!showpass)
    }
    const hShow = index => {
        show[index] = !show[index]
        setShow([...show])
    }
    const hChangeRole = (index, attr, data) => {
        roles2[index][attr] = data
        setRoles2([...roles2])
    }
    const hDesc = (e) => {
        if (e.value !== 0) {
            let sub_role = [...parsed_roles].filter(sub => sub.description === e.title)
            let permission = typeof sub_role[0].permission !== 'undefined' && sub_role[0].permission !== "" ? JSON.parse(sub_role[0].permission) : {}
            setSaveRights(permission)
            setNewRole("")
        } else {
            setSaveRights({})
        }
        setDescription({title: e.title, value: e.value})
    }
    const hCreate = () => {
        let data = {
            name: login,
            a2,
            email,
            password,
            description: description.value !== 0 ? description.title : (new_role || ''),
            permission: JSON.stringify(save_rights)
        }
        createRole(data)
        setLogin("")
        setA2(false)
        setEmail('')
        setPassword("")
        setDescription({title: "", value: ""})
    }
    const hSaveAdmin = () => {
        let data = {id: account.id, a2: adminA2, email: adminEmail};
        updateAccount(data)
    }

    const hUpdate = (index) => {
        let role = roles2[index]
        let data = {
            id: role.id,
            a2: role.a2 || false,
            password: role.password,
            description: role.description,
            email: role.email || '',
            permission: JSON.stringify(edit_rights[role.name])
        }
        updateRole(data)
    }

    const hDelete = (name, id) => {
        openModal({
            type: "confirm",
            size: "sm",
            data: {
                title: `Вы действительно хотите удалить пользователя "${name}"?`,
                buttons: {
                    cancel: {
                        title: "Вернуться",
                        onClick: () => {
                            closeModal("confirm")
                        }
                    },
                    confirm: {
                        title: "Удалить",
                        onClick: () => {
                            deleteRole(id)
                            closeModal("confirm")
                        }
                    }
                }
            }
        })
    }

    return (
        <div className='SettingsRoles'>
            <div><h2>УЧЕТНАЯ ЗАПИСЬ АДМИНИСТРАТОРА</h2></div>
            <div className='SettingsRoles__newrole_parent'>

                <div className='SettingsRoles__newrole'>
                    <div className="SettingsRoles__newrole_login">
                        <InputText
                            label="ЛОГИН"
                            readonly={true}
                            attrs={{
                                name: "name",
                                readOnly: true,
                                value: `${account.name}`,

                            }}
                        />

                    </div>
                    <div className="SettingsRoles__newrole_password">
                        <div className="form-group">
                            <label className='form-label'>ПАРОЛЬ</label>
                            <input
                                type={showpass ? "text" : "password"}
                                required
                                placeholder="Пароль"
                                className="form-control"
                                onChange={(e) => setAdminPassword(e.target.value)}
                                // style={ { height: "60px", borderRadius: "10px" } }
                                value={adminPassword}
                            />
                            {adminPassword.length > 0 &&
                            <Eye onClick={() => handleShow()} className={`eye ${showpass ? "eye-shown" : ""}`}/>}
                        </div>
                    </div>
                    <div className="SettingsRoles__newrole_password">
                        <div className="form-group">
                            <label className='form-label'>ДВУХФАКТОРНАЯ АВТОРИЗАЦИЯ</label>
                            <div className="d-flex align-items-center">
                                <Form.Check
                                    className="mb-0"
                                    type="checkbox"
                                    checked={adminA2 === true}
                                    id="a2"
                                    name="a2"
                                    label=""
                                    onChange={(e) => setAdminA2(e.target.checked)}
                                    value="true"
                                />
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="form-control"
                                    onChange={(e) => setAdminEmail(e.target.value)}
                                    value={adminEmail}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <ButtonBS
                    onClick={hSaveAdmin}
                >Сохранить</ButtonBS>

            </div>

            <div><h2>СОЗДАНИЕ НОВОЙ РОЛИ</h2></div>
            {exist && <div style={{color: "#ff3347", fontWeight: "500"}}>Такой пользователь уже есть</div>}
            {Object.values(account).length > 0 ?
                <div className='SettingsRoles__newrole_parent'>

                    <div className='SettingsRoles__newrole'>
                        <div className="SettingsRoles__newrole_login">
                            <InputText
                                label="ЛОГИН"
                                // readonly={ false }
                                attrs={{
                                    required: true,
                                    name: "login",
                                    readOnly: false,
                                    value: `${account.name}-${login}`,

                                }}
                                onChange={hLoginChange}
                            />

                        </div>
                        <div className="SettingsRoles__newrole_password">
                            <div className="form-group">
                                <label className='form-label'>ПАРОЛЬ</label>
                                <input
                                    type={showpass ? "text" : "password"}
                                    required
                                    placeholder="Пароль"
                                    className="form-control"
                                    onChange={(e) => setPassword(e.target.value)}
                                    // style={ { height: "60px", borderRadius: "10px" } }
                                    value={password}
                                />
                                {password.length > 0 &&
                                <Eye onClick={() => handleShow()} className={`eye ${showpass ? "eye-shown" : ""}`}/>}
                            </div>
                        </div>
                        <div className="SettingsRoles__newrole_password">
                            <div className="form-group">
                                <label className='form-label'>ДВУХФАКТОРНАЯ АВТОРИЗАЦИЯ</label>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        className="mb-0"
                                        type="checkbox"
                                        id="newA2"
                                        name="newA2"
                                        checked={a2 === true}
                                        label=""
                                        onChange={(e) => setA2(e.target.checked)}
                                        value="true"
                                    />
                                    <input
                                        type="email"
                                        required
                                        placeholder="email"
                                        className="form-control"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="SettingsRoles__newrole_select">
                            <Select
                                label="РОЛЬ"
                                // readonly={ false }
                                placeholder="Выберете роль пользователя"
                                type="options"
                                name="roleslist"
                                selected={description.value}
                                options={
                                    [...roles_list]
                                }
                                onChange={hDesc}
                            />
                        </div>
                        {description.value === 0 &&
                        <div className="SettingsRoles__newrole_login">
                            <InputText
                                label="НАЗВАНИЕ НОВОЙ РОЛИ"
                                // readonly={ false }
                                attrs={{
                                    name: "newrole",
                                    readOnly: false,
                                    placeholder: "Введите название",
                                    value: new_role,

                                }}
                                onChange={hNewRoleChange}

                            />
                        </div>
                        }
                        <div className="SettingsRoles__newrole_edit_rights">
                            <ButtonBS variant="light" onClick={() => openModal({
                                type: "settingsRole",
                                size: "sm",
                                data: {
                                    save_rights: save_rights,
                                    saveRights: setSaveRights,
                                    description,
                                }
                            })}><Edit/>Настроить права доступа</ButtonBS>
                        </div>
                    </div>
                    <ButtonBS
                        disabled={exist || login.length === 0 || password.length === 0 || description.length === 0 || (description.value === 0 && new_role === "") ? true : false}
                        onClick={hCreate}
                    >Создать</ButtonBS>

                </div>
                :
                <Spinner/>
            }

            <div><h2>НАСТРОЙКА РОЛЕЙ ПОЛЬЗОВАТЕЛЕЙ</h2></div>
            <div className='SettingsRoles__scroll'>
                {loading ? <Spinner/> :
                    roles2.length > 0 ? roles2.map((role, index) => (
                            <div className='SettingsRoles__editrole' key={index}>
                                <div className="SettingsRoles__newrole_login">
                                    <InputText
                                        label="ЛОГИН"
                                        attrs={{
                                            name: "login",
                                            readOnly: true,
                                            value: role.name
                                        }}
                                    />
                                </div>
                                <div className="SettingsRoles__newrole_password">
                                    <div className="form-group">
                                        <label className='form-label'>ПАРОЛЬ</label>
                                        <input
                                            type={show[index] ? "text" : "password"}
                                            required
                                            placeholder="Пароль"
                                            className="form-control"
                                            onChange={(e) => hChangeRole(index, 'password', e.target.value)}
                                            // style={ { height: "60px", borderRadius: "10px" } }
                                            value={role.password}
                                        />
                                        <Eye onClick={() => hShow(index)}
                                             className={`eye ${show[index] ? "eye-shown" : ""}`}/>
                                    </div>
                                </div>
                                <div className="SettingsRoles__newrole_password">
                                    <div className="form-group">
                                        <label className='form-label'>ДВУФАКТОРНАЯ АВТОРИЗАЦИЯ</label>
                                        <div className="d-flex align-items-center">
                                            <Form.Check
                                                className="mb-0"
                                                type="checkbox"
                                                checked={role.a2 === true}
                                                id={'chgA2'+index}
                                                name={'chgA2'+index}
                                                label=""
                                                onChange={(e) => hChangeRole(index, 'a2', e.target.checked)}
                                                value="true"
                                            />
                                            <input
                                                type="email"
                                                placeholder="email"
                                                className="form-control"
                                                onChange={(e) => hChangeRole(index, 'email', e.target.value)}
                                                value={role.email}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="SettingsRoles__newrole_select">
                                    <Select
                                        label="РОЛЬ ПОЛЬЗОВАТЕЛЯ"
                                        selected={roles_list.filter(r => r.title === role.description)[0]?.value}
                                        type="options"
                                        name="description"
                                        readOnly={true}
                                        disabled
                                        options={
                                            [...roles_list]
                                        }
                                    />
                                </div>
                                <div className="SettingsRoles__newrole_edit_rights">
                                    <div className="mr-2">
                                        <Button
                                            type="string"
                                            onClick={() => hUpdate(index)}
                                        >
                                            <SvgSave className="m-0"/>
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => openModal({
                                                type: "settingsRoleEdit",
                                                size: "sm",
                                                data: {
                                                    all_rights: edit_rights,
                                                    edit_rights: edit_rights[role.name],
                                                    editRights: setEditRights,
                                                    description: role.description,
                                                    username: role.name
                                                }
                                            })}
                                            type="string">
                                            <Edit/>
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => hDelete(role.name, role.id)}
                                            type="string">
                                            <Trash/>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        ))

                        :
                        <h2>Пока нет пользователей</h2>
                }
            </div>
        </div>
    )
}

const stateToProps = state => ({
    subusers: state.settings_roles.subusers,
    roles: state.settings_roles.roles,
    loading: state.settings_roles.loading,
    account: state.settings_roles.account
})

const actionToProps = {
    loadRoles,
    loadAccountInfo,
    updateAccount,
    openModal,
    createRole,
    deleteRole,
    closeModal,
    updateRole
}

export default connect(stateToProps, actionToProps)(SettingsRoles)
import React from 'react'
import { connect } from 'react-redux'
import {Button as ButtonBS, Form} from 'react-bootstrap'
import Header from '../components/header'
import InputText from '../../Form/InputText'
import Select from '../../Form/Select'
import {createObjectsGroup} from "../../../store/ObjectsGroup/actions";


class ObjectGroupCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            copy_id: '',
            copy_music: 0,
            copy_clip: 0,
        }
        this.hSubmit = this.hSubmit.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.hCheck = this.hCheck.bind(this)
        this.validateForm = this.validateFrom.bind(this)
    }

    hInputText({ name, value }, event) {
        if (name && value) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
        //console.log(this.state)
    }

    hCheck(event) {
        this.setState({ [event.target.name]: event.target.checked ? 1 : 0 })
    }

    hSubmit() {
        this.props.createObjectsGroup({
            name: this.state.name,
            copy_id: this.state.copy_id || "",
            copy_music: this.state.copy_music,
            copy_clip: this.state.copy_clip
        })
    }

    validateFrom() {
        if (this.state.name
            && (typeof this.state.name === "string" && this.state.name.length > 1)
            //&& ( (this.state.copy_music || this.state.copy_clip) && this.state.copy_id )
        ) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
    }

    componentDidMount() {
        this.validateFrom()
        //this.props.update()
        // this.props.loadVideolists()
    }

    render() {
        let groups2 = [...this.props.groups.map((group, index) => {
            return { title: group.name, value: group.id, id: group.id }
        })]
        return (
            <div className="ModalOneInput">
                <Header
                    title="Создание группы объектов"
                    toggleOpened={ this.props.toggleOpened }
                />
                <InputText
                    label="Название группы объектов"
                    attrs={ {
                        name: "name",
                        placeholder: "Название группы объектов",
                    } }
                    onChange={ this.hInputText }
                />
                <div className={'form-group'} style={ { marginTop: 50 } }>
                    <label className="form-label mr-5">Скопировать расписание</label>

                    <Form.Check
                        name="copy_clip"
                        inline
                        type="checkbox"
                        label="Ролики"
                        id="modal-сlip"
                        onChange={ this.hCheck }
                    />
                    <Form.Check
                        name="copy_music"
                        inline
                        type="checkbox"
                        label="Музыка"
                        id="modal-music"
                        onChange={ this.hCheck }
                    />
                </div>
                <div style={ { marginTop: 10 } }>
                    <Select
                        disabled={!(this.state.copy_clip || this.state.copy_music)}
                        label="Из группы"
                        type="options"
                        //placeholder={ "Не привязывать" }
                        name="copy_id"
                        options={ groups2 }
                        onChange={ this.hInputText }
                    />
                </div>

                <div className="Modal__footer">
                    <ButtonBS onClick={ this.hSubmit } variant="primary" disabled={ this.state.sumbitDisabled || this.props.loading }>
                        { this.props.loading ? "Сохранение..." : "Добавить" }
                    </ButtonBS>
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
    loading: state.modalPlaylistCreate.loading,
    groups: state.objectsGroup.groups
})

const actionsToProps = {
    createObjectsGroup
}

export default connect(stateToProps, actionsToProps)(ObjectGroupCreate)
import React from 'react'

import Spinner from '../../Spinner'
import PlaylistItem from '../PlaylistItemMobile'
import Empty from '../Empty'
import List from '../../List'



const Left = ({
    searchActive,
    searchFounded = [],
    playlists = [],
    loading,
    toggleSelected,
}) => {

    if (searchActive) playlists = searchFounded
    const getItems = () => {
        return playlists.map((playlist, index) => ({
            //return (
            id: playlist.id,
            name: playlist.name,
            element: < PlaylistItem
                key={ playlist.id }
                id={ playlist.id }
                name={ playlist.name }
                songs={ playlist.songs }
                variant={ (index % 2 == 0) ? "gray" : "" }
            />
        }))
    }
    return (
        <section className="">
            <div onClick={ toggleSelected }>
                { loading
                    ? <Spinner className="pt-xs pb-xs" />

                    : (playlists.length || searchActive)
                        ? <List
                            type="simple"
                            scroll={ {
                                on: true,
                                data: {
                                    variant: "white",
                                    size: "lg",
                                }
                            } }
                            // variant={ "none" }
                            content={ { items: getItems(), variant: "none" } }
                        />

                        : <Empty title="Плейлистов пока нет." />
                }

            </div>
        </section>
    )
}



export default Left
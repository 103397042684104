import React from 'react'
import { connect } from 'react-redux'

import VideolistItem from './VideolistItem'


const VideolistItemContainer = props => (
    <VideolistItem
        id={ props.id }
        name={ props.name }
        songs={ props.songs }
        selected={ props.selected }
        markItems={ props.markItems }
        tags={ props.tags }
        variant={ props.variant }
    />
)

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.videolists.selected],
})

export default connect(stateToProps, null)(VideolistItemContainer)
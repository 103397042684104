import {isArrEmpty} from './isArrEmpty'
import {isObj} from './isObj'
import {isArr} from './isArr'


export function getNextSelected({
    multiple = false,
    selected = [],
    items = [],
    itemId,
    ctrlKey,
    shiftKey,
    def = -1
}) {

    // multiple

    if(multiple) {
        if(ctrlKey) {
            (selected.indexOf(itemId) === -1)
                ? selected.push(itemId)
                : selected.splice(selected.indexOf(itemId), 1)
        } else if(shiftKey) {
            if(isArrEmpty(items)) {
                selected = []
            } else {
                cleanSelection()

                let order = {},
                    clickPos = -1
                
                if(isObj(items)) {
                    Object.keys(items).map((name, index) => {
                        const item = items[name]
                        if(selected.indexOf(item.id) !== -1) {
                            order[index] = item.id
                        }
                        if(item.id === itemId) {
                            clickPos = index
                        }
                    })
                } else if(isArr(items)) {
                    items.map((item, index) => {
                        if(selected.indexOf(item.id) !== -1) {
                            order[index] = item.id
                        }
                        if(item.id === itemId) {
                            clickPos = index
                        }
                    })
                }

                let orderSorted = Object.keys(order)
                orderSorted.sort((a, b) => a - b)

                let topPos = Number(orderSorted[0]),
                    botPos = Number(orderSorted[orderSorted.length-1]),
                    lastSelectedPos = Number(selected[selected.length-1])

                // Клик выше
                if(clickPos < topPos) {
                    selected = getIdsBetween({
                        a: clickPos,
                        b: topPos,
                        items,
                    })
                }
                // Клик ниже
                else if(clickPos > botPos) {
                    selected = getIdsBetween({
                        a: clickPos,
                        b: botPos,
                        items,
                    })
                }
                // Любые другие
                else {
                    const toTop = Math.abs(clickPos-topPos)
                    const toBot = Math.abs(clickPos-botPos)

                    selected = getIdsBetween({
                        a: clickPos,
                        b: (toBot < toTop) ? botPos : topPos,
                        items,
                    })
                }
            }
        } else {
            if(selected.indexOf(itemId) === -1) {
                selected = [itemId]
            } else {
                if(selected.length > 1) {
                    selected = [itemId]
                } else {
                    selected = []
                }
            }
        }

    // single

    } else {
        if(selected[0] === itemId) {
            selected = []
        } else {
            selected = [itemId]
        }
    }

    if(def !== -1) {
        if(selected.length === 0) {
            selected = [def]
        } else {
            if(selected.indexOf(def) !== -1) {
                selected.splice(selected.indexOf(def), 1)
            }
        }
    }

    return [...selected]
}

function getIdsBetween({ a, b, items }) {
    let start,
        stop,
        selected = []

    if(a > b) {
        start = b
        stop = a
    } else {
        start = a
        stop = b
    }

    if(isObj(items)) {
        Object.keys(items).map((name, index) => {
            const id = items[name].id
            if(index === start || index === stop || (index > start && index < stop)) {
                selected.push(id)
            }
        })
    } else if(isArr(items)) {
        items.map((item, index) => {
            const id = item.id
            if(index === start || index === stop || (index > start && index < stop)) {
                selected.push(id)
            }
        })
    }

    return selected 
}

function cleanSelection() {
    if(window.getSelection) {
        window.getSelection().removeAllRanges()
    } else if (document.selection) {
        document.selection.empty()
    }
}
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Form} from 'react-bootstrap'


class Colorpicker extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            contentOpened: false,
            selectedColor: this.props.default || "#0a0a0a"
        }

        this.inputColorRef = React.createRef()
        this.selectColor = this.selectColor.bind(this)
        this.openColorPicker = this.openColorPicker.bind(this)
    }

    selectColor(e) {
        this.setState({
            selectedColor: e.target.value
        })
        if(typeof this.props.onSelect === "function") this.props.onSelect(e.target.value)
    }

    openColorPicker() {
        this.inputColorRef.current.click()
    }

    render() {
        const {placeholder, id = "", name = "", disabled} = this.props
        return(
            <>
                <input
                    className={`form-control ${this.state.selectedColor === "" || this.state.selectedColor === undefined ? "" : "active"}`}
                    onClick={this.openColorPicker}
                    value={this.state.selectedColor}
                    placeholder={placeholder}
                    readonly="true"
                    type="text"
                    disabled={disabled}
                />

                <div className="color-preview" style={{backgroundColor: this.state.selectedColor}}></div>

                <input
                    type="color"
                    ref={this.inputColorRef}
                    value={this.state.selectedColor}
                    onChange={this.selectColor}
                />

            </>
        )
    }
}

Colorpicker.propTypes = {
    default: PropTypes.string,
    id: PropTypes.string,
    onSelect: PropTypes.func,
}

export default Colorpicker
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_SILENT } from '../../../../store/Settings/sets'
import { saving } from '../../../../store/Settings/actions'
import { closeModal } from '../../../../store/Modal/actions'
import { SAVE_ITEM, loadItems, cleanBuffer } from '../../../../store/Settings/actions/sets/Object/Silent'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_ITEM, worker)
}

function* worker({ payload }) {
    const { stateKey, itemId, settings } = payload

    try {
        if (itemId) {
            yield put(saving({
                type: OBJECT_SILENT,
                stateKey,
                saving: true,
            }))

            const selectedObjects = yield select(state => state.objects.selected || [])

            const fetchedSave = yield call(fetchSave, itemId, selectedObjects, settings)

            // Ошибка
            if (fetchedSave.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${SAVE_ITEM}.`, fetchedSave],
                    text: "Не удалось выполнить сохранение. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(showAlertSuccess({
                    responses: [`Успешное выполнение ${SAVE_ITEM}.`, fetchedSave],
                    text: "Расписание успешно сохранено."
                }))
                yield put(loadItems({
                    stateKey,
                    required: true,
                }))
                yield put(closeModal("element"))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_ITEM}.`, e],
            text: "Не удалось выполнить сохранение. Попробуйте повторить."
        }))
    }

    finally {
        yield put(saving({
            type: OBJECT_SILENT,
            stateKey,
            saving: false,
        }))
    }
}

function fetchSave(id, ms_id, settings) {
    ms_id = ms_id.join(",")

    if (!settings.todate || settings.todate === "") settings.todate = settings.fromdate

    let formData = new FormData()
    for (let name in settings) {
        formData.set(name, settings[name])
    }

    return axios(
        {
            url: "/msbox/silent/update",
            method: "POST",
            data: formData,
            params: {
                id, ms_id
            }
        }
    )
        .then(response => response.data)
}
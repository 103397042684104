import React from 'react'

import Select from '../../../Form/Select'

import SvgEdit from '../../../SVG/Edit'
import SvgClose from '../../../SVG/Close'


class SettingsPresets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedPreset: null,
            selected: false
        }

        this.selectPreset = this.selectPreset.bind(this)
        this.editPreset = this.editPreset.bind(this)
        this.resetPreset = this.resetPreset.bind(this)
    }

    componentDidMount() {
        if (this.props.presets === null)
            this.props.loadPresets()
    }

    componentDidUpdate(prevProps) {
        if (this.props.stateKey !== prevProps.stateKey) {
            this.resetPreset();
        }
    }

    render() {
        const {
            className = "",
            presets,
        } = this.props
        const {
            selected,
        } = this.state

        if (presets === null) {
            return null;
        }
        return (
            <div className={`SettingsPresets ${className}`}>
                <Select
                    type="options"
                    placeholder="Пресеты"
                    onChange={this.selectPreset}
                    selected={this.state.selectedPreset?.value || ''}
                    options={
                        presets.map(preset => ({
                            title: preset.name,
                            value: preset.id
                        }))
                    }
                />
                {selected && <><span onClick={this.resetPreset}>
                        <SvgClose className="icon-close" title="Сбросить пресет" size="xs"/>
                    </span>
                    <span onClick={this.editPreset}>
                    <SvgEdit className="icon-edit" title="Редактировать пресет" size="xs"/>
                    </span></>
                }
            </div>
        )
    }

    editPreset() {
        const presetId = this.state.selectedPreset.value
        const name = this.state.selectedPreset.title

        this.props.openModal({
            type: "oneInput",
            size: "sm",
            data: {
                title: "Редактирование пресета",
                input: {
                    label: "Название пресета",
                    attrs: {
                        value: name,
                    }
                },
                buttons: {
                    prim: {
                        title: "Сохранить",
                        onClick: (value) => this.props.updatePresetName({
                            presetId: presetId,
                            name: value
                        })
                    },
                    forth: {
                        title: "Удалить пресет",
                        onClick: () => this.props.deletePreset(presetId)
                    }
                }
            }
        })
    }

    selectPreset(selected) {
        const {
            type,
            stateKey,
            applyPreset,
        } = this.props

        if (selected.title && selected.title !== "") {
            this.setState(() => {
                applyPreset({
                    type: type,
                    stateKey: stateKey,
                    presetId: selected.value,
                })
                return {
                    selectedPreset: selected,
                    selected: true
                }
            })
        } else {
            this.setState({
                selectedPreset: null,
                selected: false
            })
        }
    }

    resetPreset() {
        const {
            type,
            stateKey,
            resetPreset,
        } = this.props;

        this.setState(() => {
            resetPreset({
                type: type,
                stateKey: stateKey
            })
            return {
                selectedPreset: null,
                selected: false
            }
        })
    }
}

export default SettingsPresets
export function durationFromCliptime(songs_arr)
{
    let songs_hour = 0
    let songs_minutes = 0
    let songs_seconds = 0
    let hours = 0
    let minutes = 0
    songs_arr.map(song => {
        if (song.cliptime) {
            let duration_arr = song.cliptime.split(".")[0].split(":")
            songs_hour += Number(duration_arr[0])
            songs_minutes += Number(duration_arr[1])
            songs_seconds += Number(duration_arr[2])
        }
    })
    if (songs_seconds % 60 >= 0) {
        minutes = Math.floor(songs_seconds / 60)
        songs_minutes += minutes
        songs_seconds = songs_seconds % 60
    }
    if (songs_minutes % 60 >= 0) {
        hours = Math.floor(songs_minutes / 60)
        songs_hour += hours
        songs_minutes = songs_minutes % 60
    }
    return `${songs_hour.toString().padStart(2, "0")}:${songs_minutes.toString().padStart(2, "0")}:${songs_seconds.toString().padStart(2, "0")}`
}
import { OPEN_MODAL, CLOSE_MODAL } from './actions'

const initialState = {
    types: {
        "adsReport": { opened: false },
        "adsCreate": { opened: false },
        "adsCreateExtra": { opened: false },
        "playlistCreate": { opened: false },
        "objectGroupCreate": { opened: false },
        "videolistCreate": { opened: false },
        "adsEdit": { opened: false },
        "adsEditExtra": { opened: false },
        "settingsClipEdit": { opened: false },
        "mediaplan": { opened: false },
        "videoHistory": { opened: false },
        "advertCreate": { opened: false },
        "castinfo": { opened: false },
        "confirm": { opened: false },
        "createLine": { opened: false },
        "element": { opened: false },
        "oneInput": { opened: false },
        "video": { opened: false },
        "videoCreate": { opened: false },
        "publicsongsFilter": { opened: false }
    }
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case OPEN_MODAL:
            return {
                types: {
                    ...state.types,
                    [payload.type]: {
                        ...payload.data,
                        size: payload.size,
                        zIndex: payload.zIndex,
                        opened: true,
                    }
                }
            }

        case CLOSE_MODAL:
            return {
                types: {
                    ...state.types,
                    [payload.type]: {
                        opened: false
                    }
                }
            }
    }
    return state
}

export default reducer
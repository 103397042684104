import moment from 'moment'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { loading, putMeta, putVideoHistory } from '../../../store/Modal/types/VideoHistory/actions'
import { OPEN_MODAL } from '../../../store/Modal/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(OPEN_MODAL, worker)
}

function* worker({ payload }) {
    const { type } = payload

    if (type === "videoHistory") {
        yield put(loading(true))

        try {
            const indentSelectedId = yield select(state => state.tracks.selected[0])
            const indents = yield select(state => state.tracks.tracks)

            const indentName = indents.filter(i => i.id === indentSelectedId)[0]?.name || ""

            yield put(putMeta({ indentName }))

            const history = yield call(fetch, indentSelectedId)

            if (history.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${OPEN_MODAL}.`, history],
                    text: "История не была загружена. Попробуйте повторить."
                }))
            } else {
                yield put(putVideoHistory(history.success))
            }
        }
        catch (e) {
            yield put(showAlertError({
                errors: [`Ошибка сети при ${OPEN_MODAL}.`, e],
                text: "История не была загружена. Попробуйте повторить."
            }))
        }
        finally {
            yield put(loading(false))
        }
    }
}

function fetch(indentId) {
    return axios.get("/timeline/history/select", { params: { indent_id: indentId } })
        .then(response => response.data)
}

// Преобразует полученные данные в массив нужного вида
function normalize(history) {
    let arr = [],
        indexes = {}

    history.map(object => {
        let objectIndex = indexes[object.msid]

        if (typeof objectIndex === "number") {
            arr[objectIndex].rows.push({
                ...object,
                keyId: Math.floor(Math.random() * Math.floor(99999)),
                fromdateMilliseconds: Number(moment(object.fromdate).format("x")),
                todateMilliseconds: Number(moment(object.todate).format("x"))
            })

        } else {
            indexes[object.msid] = arr.length
            arr.push({
                id: object.msid,
                name: object.name,
                rows: [
                    {
                        ...object,
                        keyId: Math.floor(Math.random() * Math.floor(99999)),
                        fromdateMilliseconds: Number(moment(object.fromdate).format("x")),
                        todateMilliseconds: Number(moment(object.todate).format("x"))
                    }
                ]
            })
        }
    })

    return arr
}
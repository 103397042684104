import React, {useEffect} from 'react'

import {OBJECT_NET} from '../../../../store/Settings/sets'

import {cl} from '../../../../utilities/cl'
import {prepareSettings} from '../../utilities/prepareSettings'
import {carryUpdate} from '../../utilities/carryUpdate'
import {updateHelper} from '../../utilities/updateHelper'
import {setsex, setsmob} from '../../utilities/setsclasses'
import fields from './fields'

import Builder from '../../Builder'

import fieldPppoename from './items/pppoename'
import fieldPppoepass from './items/pppoepass'
import fieldLip from './items/lip'
import fieldGateway from './items/gateway'
import fieldDns from './items/dns'
import fieldLinterval from './items/linterval'
import ItemSave from './items/save'

 
const SettingsObjectNet = ({
    className = "",
    settings = {},
    stateKey,
    loadSettings,
    updateOptions,
    saveSettings,
}) => {

    className = cl(
        className,
        "SettingsObjectNet"
    )

    useEffect(() => {
        loadSettings({
            type: OBJECT_NET,
            stateKey,
            fields,
        })
    }, [stateKey])

    let validMap
    [settings, validMap] = prepareSettings({
        type: OBJECT_NET,
        settings: settings,
    })

    updateOptions = carryUpdate({
        type: OBJECT_NET,
        stateKey,
        updateOptions
    })

    const hChange = (name) => updateHelper({
        name,
        updateOptions
    })

    return(
        <Builder
            className={className}
            groups={{
                all: [
                    // Group
                    {
                        data: {
                            className: setsex(["col-2-nowrap"])+setsmob(["col-1"])
                        },
                        items: [
                            {
                                title: "Имя пользователя для PPPoE",
                                components: fieldPppoename({
                                    settings,
                                    name: "pppoename",
                                    onChange: hChange("pppoename")
                                })
                            },
                            {
                                title: "Пароль пользователя для PPPoE",
                                components: fieldPppoepass({
                                    settings,
                                    name: "pppoepass",
                                    onChange: hChange("pppoepass")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 1,
                            noDivider: true,
                            className: setsex(["col-2", "mr", "divider-pb", "divider-bb"])
                        },
                        items: [
                            {
                                title: "IP адрес/маска",
                                components: fieldLip({
                                    settings,
                                    name: "lip",
                                    onChange: hChange("lip")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 1,
                            noDivider: true,
                            className: setsex(["col-4", "mr", "divider-pb"])
                        },
                        items: [
                            {
                                title: "IP адрес шлюза",
                                components: fieldGateway({
                                    settings,
                                    name: "gateway",
                                    onChange: hChange("gateway")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 1,
                            className: setsex(["col-4", "no-divider", "divider-pb"])
                        },
                        items: [
                            {
                                title: "Адрес сервера DNS",
                                components: fieldDns({
                                    settings,
                                    name: "dns",
                                    onChange: hChange("dns")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            className: setsex(["col-1"]),
                            noDivider: true,
                        },
                        items: [
                            {
                                title: "Интервал в минутах, через который будет проводиться удалённая синхронизация по проводной сети",
                                className: "SettingsObjectNet__syncInterval",
                                components: fieldLinterval({
                                    settings,
                                    name: "linterval",
                                    onChange: hChange("linterval")
                                })
                            },
                        ]
                    },

                    // Save
                    {
                        data: {
                            className: setsex(["col-2-nowrap"])
                        },
                        items: [
                            <ItemSave
                                settings={settings}
                                stateKey={stateKey}
                                fields={fields}
                                saveSettings={saveSettings}
                            />
                        ]
                    }
                ],
            }}
        />
    )
}

export default SettingsObjectNet
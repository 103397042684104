import moment from 'moment'
import {takeEvery, put, call, select} from 'redux-saga/effects'
import {buildGETUrl} from '../../../utilities/buildGETUrl'
import {loading, putMeta, putMediaplan} from '../../../store/Modal/types/Mediaplan/actions'
import {OPEN_MODAL} from '../../../store/Modal/actions'
import {showAlertError} from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(OPEN_MODAL, worker)
}

function* worker({payload}) {
    const {type} = payload

    if (type === "mediaplan") {
        yield put(loading(true))

        try {
            const indentSelectedId = yield select(state => state.tracks.selected[0])
            const indents = yield select(state => state.tracks.tracks)

            const indentName = indents.filter(i => i.id === indentSelectedId)[0]?.name || ""

            yield put(putMeta({indentName}))

            const mediaplan = yield call(fetchMediaplan, indentSelectedId)

            if (mediaplan.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${OPEN_MODAL}.`, mediaplan],
                    text: "Медиплан не был загружен. Попробуйте повторить."
                }))
            } else {
                yield put(putMediaplan(normalizeMediaplan(mediaplan.success)))
            }
        } catch (e) {
            yield put(showAlertError({
                errors: [`Ошибка сети при ${OPEN_MODAL}.`, e],
                text: "Медиплан не был загружен. Попробуйте повторить."
            }))
        } finally {
            yield put(loading(false))
        }
    }
}

function fetchMediaplan(indentId) {
    return axios.get("/timeline/mediaplan/select", {params: {indent_id: indentId}})
        .then(response => response.data)
}

// Преобразует полученные данные в массив нужного вида
function normalizeMediaplan(mediaplan) {
    let arr = [],
        indexes = {}

    mediaplan.map(object => {
        let objectIndex = indexes[object.msid]

        object.clip.url = buildGETUrl("clip/play", {id: object.clip.id});
        object.clip.mtime2 = moment(object.clip.mtime).format("DD[.]MM[.]YYYY H[:]mm[:]ss");
        if (typeof objectIndex === "number") {
            arr[objectIndex].rows.push({
                ...object,
                keyId: Math.floor(Math.random() * Math.floor(99999)),
                fromdateMilliseconds: Number(moment(object.fromdate).format("x")),
                todateMilliseconds: Number(moment(object.todate).format("x"))
            })

        } else {
            indexes[object.msid] = arr.length
            arr.push({
                id: object.msid,
                name: object.alias || object.name,
                rows: [
                    {
                        ...object,
                        keyId: Math.floor(Math.random() * Math.floor(99999)),
                        fromdateMilliseconds: Number(moment(object.fromdate).format("x")),
                        todateMilliseconds: Number(moment(object.todate).format("x"))
                    }
                ]
            })
        }
    })

    return arr
}
import React from 'react'
import { connect } from 'react-redux'

import { HELP } from '../../routes'

import Panel from '../../../components/Panel'
import Place from '../../../components/Panel/Place'

import Search from '../../../components/Search'
import { cl } from '../../../utilities/cl'
import { MEDIA_BREAKPOINT_LG } from '../../../utilities/breakpoints'


const AdvertPanel = ({
    page,
    searchState,
}) => {
    const disabled = isDisabled(page)
    // let className = cl({ "d-none": true })
    return (

        window.outerWidth > MEDIA_BREAKPOINT_LG ?
            <Panel page={ page } disabled={ disabled }>

                {/* 1 */ }

                <Place
                    title="Что"
                    number={ 1 }
                    className={ `${(searchState.modeActive) ? "right-extend" : ""}` }
                    disabled={ disabled }
                    elements={ {

                    } }
                />

                {/* 2 */ }

                <Place
                    title="Где"
                    number={ 2 }
                    disabled={ disabled }
                />

                {/* 3 */ }

                <Place
                    title="Когда"
                    number={ 3 }
                    disabled={ disabled }
                />

            </Panel> : <div></div>

    )
}

const stateToProps = state => ({
    searchState: state.search["objects"],
})

export default connect(stateToProps, null)(AdvertPanel)

//
// Функции
//

function isDisabled(page) {
    return (page !== HELP) ? true : false
}
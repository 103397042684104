import React from 'react'
import {connect} from 'react-redux'

import {toggleSelectedGroup} from '../../store/ObjectsGroup/actions'
import {toggleSelectedObject} from '../../store/Objects/actions'

import ObjectsGroup from './ObjectsGroup'


const ObjectsGroupContainer = props => (
    <ObjectsGroup
        className={props.className}
        groups={props.groups}
        loading={props.loading}
        toggleSelectedGroup={props.toggleSelectedGroup}
        toggleSelectedObject={props.toggleSelectedObject}
        selectedGroups={props.selectedGroups}
        selectedObjects={props.selectedObjects}
        statusObject={props.statusObject}
    />
)

const stateToProps = state => ({
    groups: [...state.objectsGroup.groups],
    loading: state.objectsGroup.loading,
    selectedGroups: [...state.objectsGroup.selected],
    selectedObjects: [...state.objects.selected],
    statusObject: state.objects.statusObject,
})

const actionsToProps = {
    toggleSelectedGroup,
    toggleSelectedObject,
}

export default connect(stateToProps, actionsToProps)(ObjectsGroupContainer)
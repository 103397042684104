import {PUT_OBJECTS_GROUP, PUT_GROUPS_LIST, LOADING, TOGGLE_SELECTED_GROUP} from './actions'

const initialState = {
    loading: true,
    groups: [],
    groupsAssoc: {},
    groupsList: [],
    selected: [],
}

const reducer = (state = initialState, { type, payload }) => {
    switch(type) {

        case LOADING:
            return {
                ...state,
                loading: payload.mode
            }

        case PUT_OBJECTS_GROUP:
            return {
                ...state,
                groups: payload.groups,
                groupsAssoc: payload.groupsAssoc,
            }

        case PUT_GROUPS_LIST:
            return {
                ...state,
                groupsList: payload.groupsList
            }

        case TOGGLE_SELECTED_GROUP:
            return {
                ...state,
                selected: payload.selected,
            }
    }
    return state
}

export default reducer
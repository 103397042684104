import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS } from 'react-bootstrap'

import { normalizeNumberSpaces } from '../../../utilities/normalize'

import { update, createAdvert } from '../../../store/Modal/types/AdvertCreate/actions'

import Header from '../components/header'

import Uploader from '../../Uploader'
import InputText from '../../Form/InputText'
import InputTextarea from '../../Form/InputTextarea'
import {cl} from "../../../utilities/cl";


class AdvertCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filename: false,
            name: false,
            description: false,
            sumbitDisabled: true,
            track: {
                name: "—",
                size: "—",
            }
        }

        this.hSubmit = this.hSubmit.bind(this)
        this.hLoadfile = this.hLoadfile.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.validateForm = this.validateFrom.bind(this)
        this.allowedTypes = [".mp4", ".3gp", ".mkv", ".avi", ".wmv", ".mpeg", ".mpga", ".mpgv",
            ".mp3", ".aac", ".wav", ".flac", ".ogg",
            ".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"]
    }

    hLoadfile(file) {
        const fileName = (file.name);
        const name = fileName.substring(0, fileName.lastIndexOf('.'));
        this.setState(() => ({
            filename: file,
            name: name,
            track: {
                name: file.name,
                size: file.size
            }
        }), this.validateFrom)
    }

    hInputText({ name, value }, event) {
        if (name && value) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
    }

    hSubmit() {
        this.props.createAdvert({
            filename: this.state.filename,
            name: this.state.name,
            description: this.state.description || ""
        })
    }

    validateFrom() {
        if (this.state.filename
            && (typeof this.state.name === "string" && this.state.name.length > 1)
            // && (typeof this.state.description === "string" && this.state.description.length > 3)
        ) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
    }

    componentDidMount() {
        this.props.update()
    }

    render() {
        return (
            <div className="ModalAdvertCreate">
                <Header
                    title="Создание рекламного ролика"
                    toggleOpened={ this.props.toggleOpened }
                />

                <div className="ModalDoubleGrid">
                    <div className="ModalDoubleGrid__left">

                        <div className="ModalDoubleGrid__title Uploader-title">
                            Файл
                        </div>

                        <Uploader
                            attrs={ {
                                name: "filename",
                                accept: this.allowedTypes.join(',')
                            } }
                            trackName={this.state.track.name}
                            fileTypes={this.allowedTypes}
                            onChange={ this.hLoadfile }
                        />

                        <div className="ModalAdvertCreate__grid">
                            <InputText
                                label="Название объявления"
                                attrs={ {
                                    name: "name",
                                    value: this.state.name,
                                    placeholder: "Название объявления"
                                } }
                                onChange={ this.hInputText }
                            />
                            <InputTextarea
                                label="Текст сообщения"
                                attrs={ {
                                    name: "description",
                                    placeholder: "Сообщение"
                                } }
                                onChange={ this.hInputText }
                            />
                        </div>
                    </div>

                    <div className="ModalDoubleGrid__right">
                        <div className="ModalDoubleGrid__title">
                            Информация
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Оригинальное имя файла</div>
                            <div className={cl("info", {"c-prim": this.state.track.name !== "—"})}>
                                { this.state.track.name }
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Время звучания</div>
                            <div className="info"> — </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Последняя модификация</div>
                            <div className="info"> — </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Размер в килобайтах</div>
                            <div className="info">
                                { normalizeSize(this.state.track.size) }
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Время создания</div>
                            <div className="info"> — </div>
                        </div>
                    </div>

                </div>

                <div className="Modal__footer">
                    <ButtonBS onClick={ this.hSubmit } variant="primary" disabled={ this.state.sumbitDisabled || this.props.loading }>
                        { this.props.loading ? "Сохранение..." : "Сохранить" }
                    </ButtonBS>
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
    loading: state.modalAdvertCreate.loading,
})

const actionsToProps = {
    update, createAdvert
}

function normalizeSize(size) {
    size = normalizeNumberSpaces(size).split(" ")
    if (size.length >= 2) return `${size[0]} ${size[1]} КБ`
    if (size.length === 1 && size[0].length > 2) return `${size[0]} КБ`
    return "—"
}

export default connect(stateToProps, actionsToProps)(AdvertCreate)
import React, {useState} from 'react'


const Line = ({ className = "" }) => {
    const [selected, useSelected] = useState(false)

    return(
        <div className={`Line selectable-item${className} ${selected ? "selected" : ""}`} onClick={useSelected.bind(null, !selected)}>
            <div className="Lines__grid">
                <div className="Line__number selectable-item__color">1</div>
                <div className="Line__title selectable-item__color">Дата/Время</div>
            </div>
        </div>
    )
}

export default Line
import React from 'react'


const Close = ({ title = 'Закрыть', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 4.234">
                <title>{ title }</title>
                <path d="M.172 0A.172.172 0 00.05.294l1.824 1.823L.05 3.94a.172.172 0 10.244.243L2.116 2.36 3.94 4.183a.172.172 0 10.243-.243L2.36 2.117 4.183.294a.171.171 0 000-.243.171.171 0 00-.243 0L2.116 1.873.294.051A.171.171 0 00.172 0z" data-name="Group 118" strokeWidth=".77" fill="#080b29" /></svg>
        </i>
    )
}

export default Close
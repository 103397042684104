import React from 'react'
import {connect} from 'react-redux'

import {filterByTagId} from '../../../../../store/ObjectsByTags/actions'

import ObjectsComponent from '../../../../../components/Objects'
import Collapsible from '../../../../../components/Space/Collapsible'
import Select from '../../../../../components/Form/Select'


const Objects = ({
    className = "",
    active,
    placeNumber,
    tags = [],
    filterByTagId,
    onChangeCollapsible,
                     selectedTagId
}) => {

    const hSelectTag = ({ value }) => filterByTagId({ tagId: value })

    return(
        <>
            <Collapsible
                className={`PartObjects ${className}`}
                active={active}
                id={111}
                placeNumber={placeNumber}
                onChangeCollapsible={onChangeCollapsible}
                title="Объекты"
                elements={
                    <Select
                        selected={selectedTagId}
                        type="options"
                        placeholder="Фильтрация по меткам"
                        options={[
                            {title: "Не фильтровать", value: -1},
                            ...tags
                        ]}
                        onChange={hSelectTag}
                    />
                }
            >
                <ObjectsComponent types={["simple"]}/>
            </Collapsible>
        </>
    )
}

const stateToProps = state => ({
    tags: [...state.objectsByTags.tags],
    selectedTagId: state.objectsByTags.filteredTagId,
})

const actionsToProps = {
    filterByTagId,
}

export default connect(stateToProps, actionsToProps)(Objects)
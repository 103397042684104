import React from 'react'
import { connect } from 'react-redux'

import { openModal, closeModal } from '../../../../../store/Modal/actions'
import { deleteTracksExtra, downloadTracks } from '../../../../../store/Tracks/actions'

import Collapsible from '../../../../../components/Space/Collapsible'
import Button from '../../../../../components/Button'
import Tracks from '../../../../../components/Tracks'

import SvgFileM from '../../../../../components/SVG/FileM'
import SvgLog from '../../../../../components/SVG/Log'


const VideoExtra = ({
    className = "",
    active,
    placeNumber,
    openModal,
    closeModal,
    selectedTracks = [],
    deleteTracksExtra,
                   title,
    downloadTracks,    
    addVideoToExtra,
    onChangeCollapsible
}) => {
    //let title = 'Ролики'
    //if (selectedTracks.length > 1) title += ` {<span>${selectedTracks.length}</span>}`

    return (
        <>
            <Collapsible
                active={ active }
                id={ 111 }
                className={ `CollapsibleVideo ${className}` }
                title={<>{title} {selectedTracks.length > 1 ? <small className='badge badge-light'>{selectedTracks.length}</small> : ''}</>}
                placeNumber={ placeNumber }
                onChangeCollapsible={onChangeCollapsible}
                elements={
                    <>

                    </>
                }
                //TODO TASK_24
                buttons={ {
                    add: {
                        on: true,
                        title: "Добавить экстренный ролик",
                        onClick: () => openModal({
                            type: "adsCreateExtra"                            
                        })
                    },
                    download: {
                        on: true,
                        disabled: (selectedTracks[0]) ? false : true,
                        onClick: () => downloadTracks({ tracksIds: selectedTracks, type: 'extra' })
                    },
                    edit: {
                        on: true,
                        disabled: (selectedTracks[0] && !selectedTracks[1]) ? false : true,
                        onClick: () => openModal({
                            type: "adsEditExtra",
                        })
                    },
                    remove: {
                        on: true,
                        disabled: (selectedTracks[0]) ? false : true,
                        onClick: () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: "Вы точно хотите удалить выделенные ролики?",
                                buttons: {
                                    cancel: {
                                        title: "Вернуться",
                                        onClick: () => {
                                            closeModal("confirm")
                                        }
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deleteTracksExtra(selectedTracks)
                                            closeModal("confirm")
                                        }
                                    }
                                }
                            }
                        })
                    }
                } }
            >
                <Tracks theme={'extra'}  showHideEndedButton={false}/>
            </Collapsible>
        </>
    )
}

const stateToProps = state => {
    const themesIds = state.tracks.tracksExtra.map(tr => tr.id)
    return {
        selectedTracks: state.tracks.selected.filter(id => themesIds.includes(id))
    }
}
const actionsToProps = {
    openModal, closeModal, deleteTracksExtra, downloadTracks
}

export default connect(stateToProps, actionsToProps)(VideoExtra)
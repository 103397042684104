import React, { useState, useEffect } from 'react'

import { cl } from '../../utilities/cl'
import { isFunc } from '../../utilities/isFunc'

import Select from '../Form/Select'


const SelectTZ = ({
    className,
    name,
    value,
    tz = [],
    onChange,
    loadTz,
}) => {

    const [currentValue, setCurrentValue] = useState(value)

    useEffect(() => {
        loadTz()
    }, [])

    useEffect(() => {
        setCurrentValue(value)
    }, [value])

    const hChange = ({ value }, e) => {
        setCurrentValue(value)
        if(isFunc(onChange)) {
            onChange({
                name,
                value,
            }, e)
        }
    }

    className = cl(
        "SelectTZ",
        className,
    )

    return (
        <Select
            className={className}
            type="options"
            onChange={hChange}
            selected={currentValue}
            placeholder="Не выбрано"
            options={tz}
        />
    )
}

export default SelectTZ
export const LOAD_RUBRICS = "Modal/PublicSongsFilter/LOAD_RUBRICS"
export const LOADING = "Modal/PublicSongsFilter/LOADING"
export const PUT_RUBRICS = "Modal/PublicSongsFilter/PUT_RUBRICS"
export const UPDATE_FILTER_PARAMS = "Modal/PublicSongsFilter/UPDATE_FILTER_PARAMS"

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const loadRubrics = () => ({
    type: LOAD_RUBRICS
})
export const putRubrics = rubrics => ({
    type: PUT_RUBRICS,
    payload: rubrics
})
export const updateFilterParams = params => ({
    type: UPDATE_FILTER_PARAMS,
    payload: params
})
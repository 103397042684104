import {isObj} from './isObj'


export function cleanInsideParams(params) {
    if(!isObj(params)) return {}

    for(let name in params) {
        if(name[0] === "_" && name[1] === "_") {
            delete params[name]
        }
    }
    return params
}
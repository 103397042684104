import { takeEvery, call, put, all } from 'redux-saga/effects'
import { LOAD_PLAYLISTS, loading, putPlaylists } from '../../store/ContentPlaylists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_PLAYLISTS, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        let playlists = []
        const fetchedPlaylists = yield call(fetchPlaylists)

        if (fetchedPlaylists.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_PLAYLISTS}.`, fetchedPlaylists],
                text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putPlaylists({ playlists }))
        } else {
            playlists = fetchedPlaylists.success.map(list => {
                let totalsize2 = 0;
                if (list.totalsize) {
                    let size = Math.floor(list.totalsize / 1024 / 1024);
                    size = !size ? `${Math.floor(list.totalsize / 1024 )} Kb` : `${size} Mb`
                    totalsize2 = size
                }
                list.totalsize2 = totalsize2
                return list;
            })
            yield put(putPlaylists({ playlists }))
        }
    }
    catch (e) {
        console.error(e)
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_PLAYLISTS}.`, e],
            text: "Плейлисты не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putPlaylists({ playlists: [] }))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchPlaylists() {
    return axios.get("/playlist/select")
        .then(response => response.data)
}

import React from 'react'
import Navbar from '../../components/Navbar'
import Panel from './Panel'
import Workspace from './Workspace'
import { SETTINGS_CONTENT } from '../routes'

const Content = props => {
    return (
        <section className='Reports'>
            <Navbar page={ props.match.path } />
            <Panel page={ props.match.path } />
            <Workspace page={ props.match.path } />

        </section>
    )
}

export default Content
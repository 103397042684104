export function arraysHasDifferences(a, b) {
    if(    typeof a === "object" || Array.isArray(a)
        && typeof b === "object" || Array.isArray(b)
    ){
        return (JSON.stringify(a) !== JSON.stringify(b))
    } else {
        return (a !== b)
        console.log("isDidChanged(): параметры не являются массивами. Ниже переданные параметры.")
        console.log("isDidChanged(a, b)", a, b)
    }
}
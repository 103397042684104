import React from 'react'
import { connect } from 'react-redux'

import Place from '../../../../components/Space/Place'

import ReportDownload from './ReportDownload'


const When = ({
    selectedObjects = [],
    selectedGroups = [],
}) => {

    return (
        <>
            {/* 3 */ }

            <Place
                number={ 3 }
                disabled={ !(selectedObjects.length > 0 || selectedGroups.length > 0) }
                style={ { width: "15%" } }
            // className="not-expanded"
            // style={ { width: "15%" } }
            >
                { (selectedObjects.length > 0 || selectedGroups.length > 0)
                    && <ReportDownload selectedObjects={selectedObjects}></ReportDownload>
                }
            </Place>
        </>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
    selectedGroups: [...state.objectsGroup.selected],
})

export default connect(stateToProps, null)(When)
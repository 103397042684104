import React from 'react'

import { isFunc } from '../../utilities/isFunc'
import { cl } from '../../utilities/cl'

import SvgClose from '../SVG/Close'
import SvgArrowDown from '../SVG/ArrowDown'
import SvgArrowUp from '../SVG/ArrowUp'


class Tags extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showed: false
        }

        this.itemClick = this.itemClick.bind(this)
        this.chooseTag = this.chooseTag.bind(this)
        this.deleteTag = this.deleteTag.bind(this)
        this.toggleShow = this.toggleShow.bind(this)
    }

    itemClick(e) {
        const target = e.target
        const item = target.closest(".item")
        const itemDelete = target.closest(".item-delete")

        if (item) {
            let name = item.dataset.tagName,
                id = item.dataset.tagId

            id = isNaN(id) ? id : Number(id)

            if (itemDelete) {
                console.log("delete", { id, name })
                this.deleteTag({ id, name })
            } else {
                this.chooseTag({ id, name })
            }
        }
    }

    chooseTag(tagObj) {
        if (isFunc(this.props.onChoose)) {
            this.props.onChoose(tagObj)
        }
    }

    deleteTag(tagObj) {
        if (isFunc(this.props.onDelete)) {
            this.props.onDelete(tagObj)
        }
    }

    toggleShow() {
        this.setState(state => ({
            showed: !state.showed
        }))
    }

    render() {
        let {
            className = "",
            tags = []
        } = this.props
        const {
            showed
        } = this.state

        className = cl(
            className,
            {
                "Tags": [
                    { "chosen": isFunc(this.props.onChoose) }
                ]
            },
            { "showed": showed }
        )

        return (
            <section className={ className }>
                { tags.length
                    ? <div className="Tags__items" onClick={ this.itemClick }>
                        { tags.map(tag =>
                            <div className="item" key={ tag.id } data-tag-id={ tag.id } data-tag-name={ tag.name }>
                                { tag.name }
                                <SvgClose
                                    className="item-delete"
                                    title="Удалить метку"
                                    size="xs"
                                    variant="dark"
                                />
                            </div>
                        ) }
                    </div>
                    : <div className="Tags__empty">
                        Меток пока что нет.
                    </div>
                }

                { (tags.length > 9)
                    && <div className="Tags__show">
                        <div className="show-wrap" onClick={ this.toggleShow }>
                            <div className="show">
                                <SvgArrowDown
                                    title="Показать все"
                                    size="mr"
                                    variant="dark"
                                />
                                Показать все
                            </div>
                            <div className="hide">
                                <SvgArrowUp
                                    title="Скрыть"
                                    size="mr"
                                    variant="dark"
                                />
                                Скрыть
                            </div>
                        </div>
                    </div>
                }
            </section>
        )
    }
}

export default Tags
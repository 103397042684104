import React from 'react'
import {Button as ButtonBS} from 'react-bootstrap'

import {cl} from '../../../../utilities/cl'

import SvgPlus from '../../../SVG/Plus'
import Item from '../Group/Item'


const AddButton = ({
    className = "",
    title = "Добавить",
    onClick,
    disabled = false
}) => {

    className = cl(
        className,
        "SettingsAddButton",
        "pb-xs1"
    )

    return(
        <Item>
            <div className={className}>
                <div className="spp-btns">
                    <ButtonBS
                        variant="light"
                        onClick={onClick}
                        disabled={disabled}
                        >
                        <SvgPlus/> {title}
                    </ButtonBS>
                </div>
            </div>
        </Item>
    )
}

export default AddButton
import {
    LOAD_OBJECTS_STATE, LOADING,
    PUT_OBJECTS_STATE,
    SORT_OBJECTS_STATE,
    TOGGLE_SELECTED_OBJECT_STATE,
    REPORT_MENU_SELECTED,
    NOW_PLAYING
    // SORT_PLAYLISTS, SORT_SONGS,
} from './actions'

const inititalState = {
    loading: false,
    objects_state: [],
    selected: [],
    menu: 1,
    file: ''
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case SORT_OBJECTS_STATE:
            if (payload.sort_type === "id") {
                return {
                    ...state,
                    objects_state:
                        Array.from(state.objects_state).sort((a, b) => {
                            if (a.id < b.id) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.id > b.id) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }

            } else if (payload.sort_type === "name") {
                return {
                    ...state,
                    objects_state:
                        Array.from(state.objects_state).sort((a, b) => {
                            if (a.name < b.name) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.name > b.name) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "sync_time") {
                return {
                    ...state,
                    objects_state:
                        Array.from(state.objects_state).sort((a, b) => {
                            if (a.lastsync < b.lastsync) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.lastsync > b.lastsync) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "sync_start_time") {
                return {
                    ...state,
                    objects_state:
                        Array.from(state.objects_state).sort((a, b) => {
                            if (a.startsync < b.startsync) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.startsync > b.startsync) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "ip") {
                return {
                    ...state,
                    objects_state:
                        Array.from(state.objects_state).sort((a, b) => {
                            if (a.lip < b.lip) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.lip > b.lip) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "error") {
                return {
                    ...state,
                    objects_state:
                        Array.from(state.objects_state).sort((a, b) => {
                            if (a.lasterror < b.lasterror) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.lasterror > b.lasterror) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            }
        case LOAD_OBJECTS_STATE:
            return {
                ...state,
            }
        case NOW_PLAYING:
            return {
                ...state,
                file: payload
            }
        case REPORT_MENU_SELECTED:
            return {
                ...state,
                menu: payload
            }
        case PUT_OBJECTS_STATE:
            return {
                ...state,
                objects_state: payload.objects_state,
            }

        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case TOGGLE_SELECTED_OBJECT_STATE:
            const { id, selected, simple, required } = payload
            if (simple === true) {
                return {
                    ...state,
                    selected,
                }
            } else if (required === true) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected
                }
            } else if (required === false) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) !== -1)
                            ? state.selected.filter(objectId => objectId !== id)
                            : state.selected
                }
            } else {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected.filter(objectId => objectId !== id)
                }
            }

    }
    return state
}

export default reducer
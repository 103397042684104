import { takeEvery, select, all, put, call } from 'redux-saga/effects'
import { OBJECT_BACKGROUND } from '../../../../store/Settings/sets'
import { SAVE_ITEMS, loadItems } from '../../../../store/Settings/actions/sets/Object/Background'
import { saving } from '../../../../store/Settings/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError } from '../../../../store/Alert/actions'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_ITEMS, worker)
}

function* worker({ payload }) {
    let { stateKey, itemIds, settings } = payload

    try {
        yield put(saving({
            type: OBJECT_BACKGROUND,
            stateKey,
            saving: true,
        }))

        const selectedItems = yield select(state => state.settings.settings[OBJECT_BACKGROUND][stateKey]?.selected || [])
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedGroups = yield select(state => state.objectsGroup.selected || [])

        itemIds = itemIds || selectedItems

        if (itemIds && (selectedObjects.length || selectedGroups.length)) {

            //
            // Настройки для отображения берутся из первого выделенного
            //
            const itemStateKey = generateStateKey([stateKey, itemIds[0]])
            let itemsSettings = yield select(state => state.settings.settings[OBJECT_BACKGROUND][itemStateKey] || {})

            // if(!settings) {
            //     itemsSettings = yield all(
            //         itemIds.map(itemId => {
            //             const itemStateKey = generateStateKey([ stateKey, itemId ])
            //             return select(state => state.settings.settings[OBJECT_BACKGROUND][itemStateKey] || {})
            //         })
            //     )
            // }

            const msIds = selectedGroups.length ? selectedGroups : selectedObjects

            const saved = yield all(
                itemIds.map((itemId) =>
                    call(fetchSave, itemIds.length, itemId, msIds, settings || itemsSettings)
                )
            )

            let error = false
            saved.map(item => {
                if (item.error) error = true
            })

            // Ошибка
            if (error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${SAVE_ITEMS}.`, saved],
                    text: "Ошибка при сохранении. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(loadItems({
                    stateKey,
                    required: true,
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_ITEMS}.`, e],
            text: "Ошибка при сохранении. Попробуйте повторить."
        }))
    }

    finally {
        yield put(saving({
            type: OBJECT_BACKGROUND,
            stateKey,
            saving: false,
        }))
    }
}

function fetchSave(itemsQuantity, id, ms_id, settings) {
    delete settings.id
    delete settings.ms_id
    delete settings.videolist_id

    if (itemsQuantity > 1) {
        delete settings.starttime
    }

    let formData = new FormData()
    for (let name in settings) {
        if (settings[name] && settings[name] !== "") {
            formData.set(name, settings[name])
        }
    }

    ms_id = ms_id.join(",")

    return axios(
        {
            url: "/msbox/play/update",
            method: "POST",
            data: formData,
            params: {
                id, ms_id
            }
        }
    )
        .then(response => response.data)
}
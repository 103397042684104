import moment from 'moment'
import {takeEvery, takeLeading, call, select, put} from 'redux-saga/effects'
import {buildGETUrl} from '../../../utilities/buildGETUrl'
import {LOAD_REPORT, loading, sortData} from '../../../store/Modal/types/AdsReport/actions'
import {OPEN_MODAL} from '../../../store/Modal/actions'
import {showAlertError} from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeLeading(LOAD_REPORT, worker)
    // yield takeEvery(OPEN_MODAL, worker)
}

function* worker({type, payload}) {
    let continueToUpdate = true
    const selectedObjects = yield select(state => state.objects.selected)

    if (type === OPEN_MODAL) {
        if (payload.type !== "adsReport") {
            continueToUpdate = false
        }
    }
    if (typeof payload.objectId !== "number" || isNaN(payload.objectId)) {
        payload.objectId = selectedObjects[selectedObjects.length - 1]
    }
    if (typeof payload.from !== "string" || !payload.from.length) {
        payload.from = moment().format("YYYY[-]MM[-]DD")
    }
    if (typeof payload.to !== "string" || !payload.to.length) {
        payload.to = moment().format("YYYY[-]MM[-]DD")
    }
    if (continueToUpdate && payload.objectId && payload.from && payload.from !== "Invalid date" && payload.to && payload.to !== "Invalid date") {
        yield put(loading(true)) // Выключается при следующем событии (sortData)
        const {from, to, objectId} = payload
        let nextState = {},
            continueToSort = true

        try {
            // nextState.date = date
            nextState.from = from
            nextState.to = to
            nextState.objectId = objectId

            const reports = yield select(state => state.modalAdsReport.reports)
            const fetched = yield call(fetchReport, from, to, objectId)

            if (fetched.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сети при ${LOAD_REPORT}.`, fetched],
                    text: "Отчёт не был загружен. " + fetched.error
                }))
                continueToSort = false
            } else {
                nextState.data = fetched.success
            }

            if (continueToSort) {
                yield put(sortData(nextState))
            }
        } catch (e) {
            yield put(showAlertError({
                errors: [`Ошибка сети при ${LOAD_REPORT}.`, e],
                text: "Отчёт не был загружен. " + e.message
            }))
        }
    }
}

function fetchReport(from, to, objectId) {
    return axios.get("/timeline/ad", {
        params: {
            date: from,
            //from_date: from,
            todate: to,
            ms_id: objectId,
        }
    })
        .then(response => response.data)
}
export default function({
    settings = {},
    name,
    onChange
}) {

    return [
        {
            type: "InputIPAddress",
            data: {
                gate: true,
                onChange,
                attrs: {
                    value: settings[name],
                }
            }
        }
    ]
}
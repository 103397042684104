import React, { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import { Button as ButtonBS } from 'react-bootstrap'

import { cl } from '../../../../utilities/cl'
import { isObj } from '../../../../utilities/isObj'
import { isArr } from '../../../../utilities/isArr'
import { getNextSelected as getNextSelectedIds } from '../../../../utilities/getNextSelected'

import Button from '../../../Button'
import SvgArrowDown from '../../../SVG/ArrowDown'
import SvgTrash from '../../../SVG/Trash'
import SvgEdit from '../../../SVG/Edit'
import { useDebouncedEffect } from "../../../../utilities/useDebouncedEffect";
import { deleteTimeOutClipsFromPrograms } from '../../../../utilities/deleteTimeOutPrograms'


const SettingsClips = ({
    className,
    objects = {},
    clips: clipsInner = [],
    indentIds = [],
    objectIds = [],

    toggleOpened,
    loadClips,
    putClips,
    deleteClips,
    openModal,
    closeModal,
    hideEnded
}) => {
    {/* TODO TASK_21 */ }
    console.log({ clipsInner });
    let clips = [...clipsInner]
        // .sort((a, b) => {
        //     const nameA = objects[a.id]?.fullName.toUpperCase().replace(a.id, '');
        //     const nameB = objects[b.id]?.fullName.toUpperCase().replace(b.id, '');

        //     if (nameA < nameB) {
        //         return -1;
        //     }
        //     if (nameA > nameB) {
        //         return 1;
        //     }
        //     return 0;
        // })

    if (hideEnded)
        clips = clips.map(object => ({
            ...object,
            clips: deleteTimeOutClipsFromPrograms(object.clips)
        }))


    const [lastIndentId, setLastIndentId] = useState(indentIds.join(','))
    const [collapsed, setCollapsed] = useState(false)
    const [clipsCount, setClipsCount] = useState(0)

    const [selectedIds, setSelectedIds] = useState([])

    const allClips = useMemo(() => { // Содержит все Расписания в одном Array (без деления на Объекты) в порядке рендера. Нужно для правильного выделения по shift
        const items = []
        clips.map(object => {
            if (isArr(object.clips)) {
                object.clips.map((item, index) => {
                    items.push({
                        ...item,
                        //blockText: item.block.split(',').sort((a, b) => Number(b) > Number(a) ? -1 : 1).map(d => (d-1)*5),
                        id: createClipId(object.id, index)
                    })
                })
            }
        })
        return items
    }, [clips]);

    const selectedDatas = useMemo(() => { // Содержит все Расписания в одном Array (без деления на Объекты) в порядке рендера. Нужно для правильного выделения по shift
        const items = allClips.filter((clip) => selectedIds.includes(clip.id));
        return items.reduce((acc, clip) => {
            const [objectId] = parseClipId(clip.id);
            acc[clip.id] = clip;
            return acc;
        }, {});
    }, [allClips, selectedIds]);

    // const selectedClip = useMemo(() => {
    //     return selectedDatas[selectedIds[0]] ?? null;
    // }, [selectedDatas, selectedIds]);

    const groupedClipsByObject = useMemo(() => {
        const items = {};
        clips.forEach(object => {
            if (isArr(object.clips)) {
                object.clips.forEach((item, index) => {
                    if (typeof items[object.id] === 'undefined') {
                        items[object.id] = {};
                    }
                    const id = createClipId(object.id, index);
                    const clipData = {
                        indentId: lastIndentId,
                        objectId: object.id,
                        fromdate: item.fromdate,
                        todate: item.todate,
                        channel: item.channel,
                    };
                    items[object.id][id] = clipData;
                })
            }
        })
        return items
    }, [clips, lastIndentId])

    //console.log(lastIndentId, objectIds)
    // useEffect(() => {
    //     if (indentIds.length === 1) {
    //         loadClips({
    //             indentId: lastIndentId,
    //             objectIds,
    //         })
    //     }
    //     updateSelected()
    // }, [lastIndentId, objectIds])

    useDebouncedEffect(() => {
        if (indentIds.length === 1) {
            loadClips({
                indentId: lastIndentId,
                objectIds,
                //required: true
            })
        }
        return () => {
            updateSelected();
        };

    }, [lastIndentId, objectIds], 500)

    useEffect(() => {
        setLastIndentId(indentIds.join(','))
        updateClipsCount()
    }, [indentIds])

    useEffect(() => {
        (clipsCount) ? toggleOpened(true) : toggleOpened(false)
    }, [clipsCount])

    const hDelete = () => {
        if (selectedIds.length) {
            modalDeleteClips()
        }
    }

    const modalEditClip = () => {
        if (!selectedIds.length) {
            return;
        }
        const selectedClip = selectedDatas[selectedIds[0]];
        putClips({ stateKey: selectedIds, clips: selectedClip });
        openModal({
            type: "settingsClipEdit",
            size: "sm",
            data: {
                selectedDatas,
                onSuccess: () => {
                    setSelectedIds([])
                }
            }
        })
    }

    const hToggleSelect = (e) => {
        const item = e.target?.closest(".item")
        const objectId = item?.dataset.objectId
        if (objectId) {
            setSelectedIds(Object.keys(groupedClipsByObject[objectId]));
            return;
        }
        const clipId = item?.dataset.clipId
        //const clipData = JSON.parse(item?.dataset.clipJsonData)

        if (clipId) {
            const nextSelectedIds = getNextSelectedIds({
                multiple: true,
                itemId: clipId,
                selected: selectedIds,
                ctrlKey: e.ctrlKey || e.metaKey,
                shiftKey: e.shiftKey,
                items: allClips,
            })
            console.log(nextSelectedIds)
            setSelectedIds(nextSelectedIds)
            //setSelected({nextSelectedIds})
        }
    }

    className = cl(
        "SettingsClips",
        { "collapsed": collapsed },
        className,
    )

    // if (!clipsCount) return null

    return (
        <section className={className}>

            <div className="SettingsClips__collapse">
                <div className="spp-btns">
                    <ButtonBS
                        className={cl({ "active": collapsed })}
                        variant="light"
                        onClick={() => setCollapsed(!collapsed)}
                        title={
                            collapsed
                                ? `Скрыть существующее расписание (${clipsCount})`
                                : `Показать существующее расписание (${clipsCount})`
                        }
                    >
                        {collapsed
                            ? `Скрыть существующее расписание (${clipsCount})`
                            : `Показать существующее расписание (${clipsCount})`
                        }
                        <SvgArrowDown
                            className="ml-mr"
                            size="mr"
                            rotate={collapsed}
                        />
                    </ButtonBS>
                </div>
            </div>

            <div className="SettingsClips__schedule">
                <div className="SettingsClips__header">
                    <div className="SettingsClips__grid">
                        <div className="date">Дни</div>
                        <div className="time">Время</div>
                        <div className="channel">Канал</div>
                        <div className="delete">
                            <Button
                                type="string"
                                onClick={modalEditClip}
                                disabled={!selectedIds.length}
                            >
                                <SvgEdit
                                    className="me-1"
                                    title="Редактировать расписание"
                                />
                            </Button>
                            <Button
                                type="string"
                                onClick={hDelete}
                                disabled={!(selectedIds.length)}
                            >
                                <SvgTrash
                                    className="m-0"
                                    title="Удалить расписание"
                                />
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="SettingsClips__table" onClick={hToggleSelect}>
                    {clips
                        .map(object => (
                            (object.clips?.length)
                                ? <React.Fragment key={object.id}>
                                    <div className="SettingsClips__grid item" key={object.id} data-object-id={object.id}>
                                        <div className="title">
                                            {objects[object.id]?.fullName}
                                        </div>
                                    </div>

                                    {object.clips.map((clip, index) => {
                                        const clipId = createClipId(object.id, index)

                                        return (
                                            <div
                                                key={clipId}
                                                className={cl(
                                                    "SettingsClips__grid",
                                                    "item",
                                                    { "selected": selectedIds.indexOf(clipId) !== -1 }
                                                )}
                                                data-clip-id={clipId}
                                                data-clip-json-data={JSON.stringify({
                                                    indentId: lastIndentId,
                                                    objectId: object.id,
                                                    indent_ids: clip.indent_ids,
                                                    fromdate: clip.fromdate,
                                                    fromtime: clip.fromtime,
                                                    totime: clip.totime,
                                                    block: clip.block,
                                                    mark: clip.mark,
                                                    todate: clip.todate,
                                                    channel: clip.channel,
                                                })}
                                                // data-object-id={object.id}
                                                // data-fromdate={clip.fromdate}
                                                // data-todate={clip.todate}
                                                // data-channel={clip.channel}
                                                data-fromtime={clip.fromtime}
                                                data-totime={clip.totime}
                                                data-mark={clip.mark}
                                            >
                                                {indentIds.length > 1 ? <div className="subtitle">{clip.indent_ids.join(', ')}</div> : ''}
                                                <div className="date item__color">
                                                    {moment(clip.fromdate).format("DD[.]MM[.]YYYY")}
                                                    {(clip.todate !== clip.fromdate)
                                                        ? ` — ${moment(clip.todate).format("DD[.]MM[.]YYYY")}`
                                                        : ""
                                                    }
                                                    {(clip.__inday)
                                                        ? <>
                                                            <br />
                                                            <small>Чередовать через день</small>
                                                        </>
                                                        : ""
                                                    }
                                                </div>
                                                <div className="time">
                                                    {`${normalizeTime(clip.fromtime)}`}
                                                    {(clip.totime !== clip.fromtime)
                                                        ? ` — ${normalizeTime(clip.totime)}`
                                                        : ""
                                                    }
                                                    {(clip.totime !== clip.fromtime && !clip.steptime) ?
                                                        <>
                                                            <br />
                                                            <small>
                                                                (
                                                                {clip.block.split(',').sort((a, b) => Number(b) > Number(a) ? -1 : 1).map(d => (d - 1) * 5).join(', ')} мин
                                                                )
                                                            </small>
                                                        </>
                                                        : ''}
                                                    {(clip.steptime) ? <>
                                                        <br />
                                                        <small>Чередовать через {clip.stepTimeText} ч</small>
                                                    </> : ''}
                                                </div>
                                                <div className="channel">
                                                    {(clip.channel === 1)
                                                        ? "Улица"
                                                        : (clip.channel === 2)
                                                            ? "Помещение"
                                                            : "Улица/Помещение"

                                                    }
                                                </div>
                                                {/*<div className="delete"></div>*/}
                                            </div>
                                        )
                                    })}
                                </React.Fragment>
                                : null
                        ))}

                </div>
            </div>
        </section>
    )

    function updateClipsCount() {
        let count = 0

        if (indentIds.length) {
            clips.map(obj => {
                count += (obj.clips.length || 0)
            })
        }

        setClipsCount(count)
    }

    function updateSelected() {
        if (selectedIds.length) {
            const [objectId, indentId] = parseClipId(selectedIds[0])

            if (lastIndentId !== indentId || objectIds.indexOf(objectId) === -1) {
                cleanSelected()
            }
        }
    }

    function modalDeleteClips() {
        openModal({
            type: "confirm",
            size: "sm",
            data: {
                title: "Вы точно хотите удалить данное размещение?",
                buttons: {
                    cancel: {
                        title: "Отмена",
                        onClick: () => {
                            closeModal("confirm")
                        }
                    },
                    confirm: {
                        title: "Удалить",
                        onClick: () => {
                            deleteClips({
                                clipsDatas: convertObjToArr(selectedDatas)
                            })
                            cleanSelected()
                            closeModal("confirm")
                        }
                    }
                }
            }
        })
    }

    function createClipId(objectId, clipIndex) {
        return `${objectId}-${lastIndentId}-${clipIndex}`
    }

    function parseClipId(clipId) {
        let [objectId, indentId, clipIndex] = clipId.split("-")
        objectId = Number(objectId)
        indentId = Number(indentId)
        clipIndex = Number(clipIndex)
        return [objectId, indentId, clipIndex]
    }

    function setSelected({ nextSelectedIds }) {
        setSelectedIds(nextSelectedIds)

    }

    function cleanSelected() {
        setSelectedIds([])
    }
}

export default SettingsClips

//
// Функции
//

function normalizeTime(time) {
    time = time.split(":")
    return `${time[0]}:${time[1]}`
}

function convertObjToArr(obj) {
    let answer = []
    if (isObj(obj)) {
        for (let n in obj) {
            answer.push(obj[n])
        }
    }
    return answer
}
import React from 'react'
import { connect } from 'react-redux'

import { loadPlaylists, toggleSelectedPlaylist, loadPlaylist } from '../../store/ContentPlaylists/actions'
import { loadPublicPlaylists, toggleSelectedPublicPlaylist } from '../../store/PublicPlaylists/actions'


import Playlists from './Playlists'


const PlaylistsContainer = props => (

    <Playlists
        className={ props.className }
        types={ props.types }

        allPlaylists={ props.allPlaylists }
        trackObjects={ props.trackObjects }
        searchFounded={ props.searchFounded }
        searchVal={ props.searchVal }

        selectedPlaylists={ props.selectedPlaylists }
        selectedPublicPlaylists={ props.selectedPublicPlaylists }
        selectedTracks={ props.selectedTracks }

        loading={ props.loading }
        loading_public={ props.loading_public }
        searchActive={ props.searchActive }

        loadPlaylists={ props.loadPlaylists }
        loadPlaylist={ props.loadPlaylist }
        toggleSelectedPlaylist={ props.toggleSelectedPlaylist }

        loadPublicPlaylists={ props.loadPublicPlaylists }
        toggleSelectedPublicPlaylist={ props.toggleSelectedPublicPlaylist }
    />
)
/*
TODO
- фильтр плейлистов по аккаунту юзера
*/
const stateToProps = state => ({
    allPlaylists: state.playlists.allPlaylists,
    allPublicPlaylists: state.public_playlists.allPublicPlaylists,
    trackObjects: state.objects.trackObjects,
    searchFounded: [...state.search["playlists"].founded],
    searchVal: [...state.search["playlists"].value],
    selectedPlaylists: [...state.playlists.selected],
    selectedPublicPlaylists: [...state.public_playlists.selected],
    selectedTracks: state.tracks.selected,
    loading: state.playlists.loading,
    loading_public: state.public_playlists.loading,
    searchActive: state.search["playlists"].modeActive,
})

const actionsToProps = {
    loadPlaylists,
    loadPlaylist,
    toggleSelectedPlaylist,
    loadPublicPlaylists,
    toggleSelectedPublicPlaylist,
}

export default connect(stateToProps, actionsToProps)(PlaylistsContainer)
import React from 'react'
import Navbar from '../../components/Navbar'
import Panel from './Panel'
import Workspace from './Workspace'


const Object = props => (
    <section className="Object">
        <Navbar page={props.match.path}/>
        <Panel page={props.match.path} />
        <Workspace/>
    </section>
)

export default Object
export const LOAD_NOTIFICATIONS = "Settings/Notifications/LOAD_NOTIFICATIONS"
export const LOADING = "Settings/Notifications/LOADING"
export const PUT_NOTIFICATIONS = "Settings/Notifications/PUT_TEMPLATES"
export const UPDATE_NOTIFICATIONS = "Settings/Notifications/UPDATE_TEMPLATES"

export const loadNotifications = () => ({
    type: LOAD_NOTIFICATIONS,
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const putNotifications = notifications => ({
    type: PUT_NOTIFICATIONS,
    payload: notifications
})

export const updateNotifications = notifications => ({
    type: UPDATE_NOTIFICATIONS,
    payload: notifications
})



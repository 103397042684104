import moment from 'moment'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { LOAD_PERIODS, putPeriods, loading } from '../../../store/Settings/actions/components/Periods'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_PERIODS, worker)
}

function* worker({ payload }) {
    const { type, required } = payload

    yield put(loading({ type, loading: true }))

    try {
        const loaded = yield select(state => state.settings.periods[type]?.periods || [])

        if (required === true || !loaded.length) {
            const fetchedPeriods = yield call(fetchPeriods, type)

            // Ошибка
            if (fetchedPeriods.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${LOAD_PERIODS}.`, fetchedPeriods],
                    text: "Периоды трансляций не были загружены. Попробуйте перезагрузить страницу."
                }))

                // Успех
            } else {
                let periods = [{ id: -1, value: -1, title: "Основной эфир", fromdate: '', todate: '' }],
                    periodsAssoc = {}

                fetchedPeriods.success.map(period => {
                    let fromdate = moment(period.fromdate).format("DD[.]MM[.]YYYY"),
                        todate = moment(period.todate).format("DD[.]MM[.]YYYY")

                    period = {
                        id: Number(period.id),
                        value: Number(period.id),
                        title: `${fromdate}-${todate}`,
                        fromdate,
                        todate,
                    }

                    periods.push(period)
                    periodsAssoc[period.id] = period
                })

                yield put(putPeriods({
                    type,
                    periods,
                    periodsAssoc
                }))
            }
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_PERIODS}.`, e],
            text: "Периоды трансляций не были загружены. Попробуйте перезагрузить страницу."
        }))
    }

    finally {
        yield put(loading({ type, loading: false }))
    }
}

function fetchPeriods(type) {
    return axios.get(`/msbox/${type}/dates/select`)
        .then(response => response.data)
}
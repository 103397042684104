export const LOAD_OBJECTS_STATE = "Reports/LOAD_OBJECTS_STATE"
export const LOADING = "Reports/LOADING"
export const PUT_OBJECTS_STATE = "Reports/PUT_OBJECTS_STATE"
export const SORT_OBJECTS_STATE = "Reports/SORT_OBJECTS_STATE"
export const TOGGLE_SELECTED_OBJECT_STATE = "Reports/TOGGLE_SELECTED_OBJECT_STATE"
export const REPORT_MENU_SELECTED = "Reports/REPORT_MENU_SELECTED"
export const NOW_PLAYING = "Reports/NOW_PLAYING"

export const toggleSelectedObjectState = ({ id, selected, simple, required }) => ({
    type: TOGGLE_SELECTED_OBJECT_STATE,
    payload: {
        id,
        selected,
        simple,
        required,
    }
})
export const loadObjectsState = () => ({
    type: LOAD_OBJECTS_STATE,
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})
export const menuSelected = mode => ({
    type: REPORT_MENU_SELECTED,
    payload: mode
})

export const nowPlaying = file => ({
    type: NOW_PLAYING,
    payload: file
})

export const putObjectsState = objects_state => ({
    type: PUT_OBJECTS_STATE,
    payload: objects_state
})
export const sortObjectsState = sortSource => ({
    type: SORT_OBJECTS_STATE,
    payload: sortSource
})



import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS } from 'react-bootstrap'

import { normalizeNumberSpaces } from '../../../utilities/normalize'

import { update, editPlaylist } from '../../../store/Modal/types/PlaylistCreate/actions'

import Header from '../components/header'

import Uploader from '../../Uploader'
import InputText from '../../Form/InputText'
import InputTextarea from '../../Form/InputTextarea'
import Select from '../../Form/Select'
import Spinner from '../../Spinner'


class PlaylistEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: this.props.currentPlaylist.name,
            description: this.props.currentPlaylist.description,
            videolist: false,
            sumbitDisabled: true,
        }
        this.hSubmit = this.hSubmit.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.validateForm = this.validateFrom.bind(this)
    }

    hInputText({ name, value }, event) {
        if (name && value) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
        console.log(this.state)
    }

    hSubmit() {
        this.props.editPlaylist({
            name: this.state.name,
            description: this.state.description || "",
            id_playlist: this.props.currentPlaylist.id,
            videolist: this.state.videolist,
            owner_id: this.props.currentPlaylist.owner_id
        })
    }

    validateFrom() {
        if (this.state.name
            && (typeof this.state.name === "string" && this.state.name.length > 1)
            // && (typeof this.state.description === "string" && this.state.description.length > 3)
        ) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
    }

    componentDidMount() {
        this.props.update()
    }

    render() {
        let video_binded = []
        let avmap_search = this.props.avmap.filter(av => av.playlist_id === this.props.currentPlaylist.id)[0]
        if (avmap_search) {
            let videolist_search = this.props.videolists.filter(list => list.id === avmap_search.videolist_id)[0]
            video_binded.push(videolist_search.id)
        }
        let videolists2 = [{ title: 'Не привязывать', value: -1, id: -1 }, ...this.props.videolists.map((list, index) => {
            return { title: list.name, value: list.id, id: list.id }
        })]
        return (

            < div className="ModalOneInput" >
                { !this.props.loading_videolists ?
                    <> <Header
                        title="Редактирование музыкального плейлиста"
                        toggleOpened={ this.props.toggleOpened }
                    />
                        <InputText
                            label="Название"
                            attrs={ {
                                name: "name",
                                value: this.props.currentPlaylist.name
                                // placeholder: "Введите название плейлиста",
                            } }
                            onChange={ this.hInputText }
                        />
                        <div style={ { marginTop: 30 } }>
                            <InputText
                                label="Описание"
                                attrs={ {
                                    name: "description",
                                    value: this.props.currentPlaylist.description
                                    // placeholder: "Введите описание"
                                } }
                                onChange={ this.hInputText }
                            />
                        </div>
                        <div style={ { marginTop: 30 } }>
                            <Select
                                label="Привязать фоновое видео"
                                type="options"
                                placeholder={ "Не привязано" }
                                name="videolist"
                                selected={ video_binded }
                                options={ videolists2 }
                                onChange={ this.hInputText }
                            />
                        </div>
                        <div className="Modal__footer">
                            <ButtonBS onClick={ this.hSubmit } variant="primary" disabled={ this.state.sumbitDisabled || this.props.loading }>
                                { this.props.loading ? "Сохранение..." : "Сохранить" }
                            </ButtonBS>
                        </div></> :
                    <Spinner></Spinner>
                }
            </div >


        )
    }
}

const stateToProps = state => ({
    loading: state.modalPlaylistCreate.loading,
    currentPlaylist: state.playlists.allPlaylists.filter(playlist => playlist.id === state.playlists.selected[0])[0],
    avmap: state.playlists.avmap_bindings,
    videolists: state.videolists.allVideolists,
    loading_videolists: state.videolists.loading,

})

const actionsToProps = {
    update, editPlaylist
}



export default connect(stateToProps, actionsToProps)(PlaylistEdit)
import { takeEvery, all, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { DELETE_TRACKS_EXTRA, loadTracks } from '../../store/Tracks/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_TRACKS_EXTRA, worker)
}

function* worker(action) {
    console.log({action});
    if (action.payload && action.payload[0]) {
        const tracksIds = action.payload

        try {
            let deletedSuccess = true
            const deleted = yield all(
                tracksIds.map(trackId => call(fetchDeleteTrack, trackId))
            )

            deleted.map(d => { if (d.error) deletedSuccess = false })

            if (deletedSuccess) {
                yield put(loadTracks({theme: 'extra'}))
            } else {
                yield put(showAlertError({
                    errors: [`Ошибка сети при ${DELETE_TRACKS_EXTRA}.`, deleted],
                    text: "Не все ролики были удалены. Попробуйте заново."
                }))
            }
        }
        catch (e) {
            yield put(showAlertError({
                errors: [`Ошибка сети при ${DELETE_TRACKS_EXTRA}.`, e],
                text: "Не все ролики были удалены. Попробуйте заново."
            }))
        }
    }
}

function fetchDeleteTrack(trackId) {
    return axios.get("/clip/extra/delete", { params: { id: trackId } })
        .then(response => response.data)
}
import React from 'react'


const Uodate = ({ title = 'Обновить', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.26 17.065">
                <title>{ title }</title>
                <path d="M9.716.019c-3.443 0-6.92 2.123-7.886 6.254a.5.5 0 10.972.228C3.667 2.808 6.675 1.02 9.716 1.02c3.041 0 6.048 1.789 6.913 5.48l-2.387-.756a.5.5 0 00-.147-.025.5.5 0 00-.154.979l3.285 1.04a.5.5 0 00.002.003.5.5 0 00.635-.026.5.5 0 00.01-.008.5.5 0 00.004-.004.5.5 0 00.181-.365l1.051-3.234a.5.5 0 00-.473-.66.5.5 0 00-.478.351l-.67 2.067C16.39 2.004 13.042.019 9.716.019zM1.73 9.245a.5.5 0 00-.283.086.5.5 0 00-.219.34L.16 12.961a.5.5 0 10.949.308l.668-2.057c1.1 3.851 4.447 5.834 7.77 5.834 3.443 0 6.92-2.123 7.886-6.254a.5.5 0 10-.972-.228c-.865 3.693-3.873 5.482-6.915 5.482-3.04 0-6.047-1.79-6.912-5.482l2.391.758a.5.5 0 10.3-.954L1.95 9.298a.5.5 0 00-.219-.053z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" /></svg>
        </i>
    )
}

export default Uodate
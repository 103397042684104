export default function({
    settings = {},
    name,
    onChange,
}) {

    return [
        {
            type: "InputText",
            data: {
                onChange,
                attrs: {
                    value: settings[name],
                    placeholder: "Введите псевдоним"
                },
            }
        }
    ]
}
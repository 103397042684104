import React from 'react'
import {connect} from 'react-redux'
import {Button as ButtonBS} from 'react-bootstrap'

import {cl} from '../../../../../utilities/cl'

import {ADVERT_TRACKS} from '../../../../../store/Settings/sets'
import {createPreset} from '../../../../../store/Settings/actions/components/Presets'
import {saveSettings} from '../../../../../store/Settings/actions/sets/Advert/Tracks'
import {openModal} from '../../../../../store/Modal/actions'

import Group from '../../../../Settings/components/Group/Group'
import Item from '../../../../Settings/components/Group/Item'

import SvgPlus from '../../../../SVG/Plus'


const GroupSave = ({
    className = "",
    settings = {},
    validMap = {},
    stateKey,
    indentIds,
    objectIds,
    saveSettings,
    openModal,
    createPreset
}) => {

    className = cl(
        className,
        "SettingsAdvertTracksGroupSave"
    )

    const modal = () => {
        openModal({
            type: "oneInput",
            size: "sm",
            data: {
                title: "Создание пресета",
                input: {
                    label: "Название пресета",
                    attrs: {
                        placeholder: "Название"
                    }
                },
                buttons: {
                    prim: {
                        title: "Сохранить",
                        onClick: (value) => createPreset({
                            type: ADVERT_TRACKS,
                            name: value,
                            data: settings
                        })
                    }
                }
            }
        })
    }

    return(
        <Group className={className} wrap>
            <Item disabled={settings.saving}>
                <div className="spp-btns">
                    
                    <ButtonBS
                        variant="primary"
                        disabled={!validMap.validResult}
                        onClick={() => saveSettings({
                            type: ADVERT_TRACKS,
                            indentIds,
                            objectIds,
                            stateKey,
                            settings,
                        })}
                    >
                        {settings.saving === true ? "Сохранение..." : "Сохранить"}
                    </ButtonBS>

                    <ButtonBS
                        variant="light"
                        onClick={modal}
                        disabled={(!validMap.channel || !validMap.validGroups.time || !validMap.validGroups.days)}
                    >
                        <SvgPlus/> Добавить пресет
                    </ButtonBS>

                </div>
            </Item>
        </Group>
    )
}

const actionsToProps = {
    saveSettings,
    createPreset,
    openModal,
}

export default connect(null, actionsToProps)(GroupSave)
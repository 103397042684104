import React from 'react'
import { MAIN_ADVERT } from '../../../routes/routes'

import Space from '../../../components/Space'

import What from './What'
import Where from './Where'
import When from './When'


const Workspace = ({ page }) => {
    return (
        <>
            <Space>
                <What />
                <Where disabled={ whereIsDisabled(page) } />
                <When />
            </Space>
        </>
    )
}

function whereIsDisabled(page) {
    if (page === MAIN_ADVERT) {
        return false
    }
    return true
}

export default Workspace
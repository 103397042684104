import {OBJECT_SILENT} from '../../../sets'

export const LOAD_ITEMS            = `Settings/${OBJECT_SILENT}/LOAD_ITEMS`
export const PUT_ITEMS             = `Settings/${OBJECT_SILENT}/PUT_ITEMS`
export const ADD_ITEM              = `Settings/${OBJECT_SILENT}/ADD_ITEM`
export const SAVE_ITEM             = `Settings/${OBJECT_SILENT}/SAVE_ITEM`
export const CLEAN_BUFFER          = `Settings/${OBJECT_SILENT}/CLEAN_BUFFER`
export const DELETE_ITEMS          = `Settings/${OBJECT_SILENT}/DELETE_ITEMS`

export const loadItems = ({ stateKey, required = false }) => ({
    type: LOAD_ITEMS,
    payload: {
        stateKey,
        required,
    }
})

export const putItems = ({ stateKey, items, assocItems }) => ({
    type: PUT_ITEMS,
    payload: {
        type: OBJECT_SILENT,
        stateKey,
        items,
        assocItems,
    }
})

export const addItem = ({ stateKey }) => ({
    type: ADD_ITEM,
    payload: {
        stateKey
    }
})

export const saveItem = ({ stateKey, itemId, settings }) => ({
    type: SAVE_ITEM,
    payload: {
        stateKey,
        itemId,
        settings
    }
})

export const cleanBuffer = ({ itemStateKey }) => ({
    type: CLEAN_BUFFER,
    payload: {
        type: OBJECT_SILENT,
        itemStateKey
    }
})

export const deleteItems = ({ stateKey, itemIds }) => ({
    type: DELETE_ITEMS,
    payload: {
        stateKey,
        itemIds
    }
})
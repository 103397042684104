import React from 'react'


const Eye = ({ title = 'Показать', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.013 13.803">
                <title>{ title }</title>
                <path d="M11.686.012C7.268.012 2.85 2.155.258 6.442a.5.5 0 00-.035.468.5.5 0 00.033.463 13.25 13.25 0 0011.431 6.441 13.457 13.457 0 0011.424-6.437.5.5 0 00.041-.475.5.5 0 000-.002.5.5 0 00-.039-.459c-2.592-4.286-7.01-6.43-11.427-6.43zm0 .97c4.063 0 8.125 1.977 10.539 5.926a12.457 12.457 0 01-10.541 5.906A12.247 12.247 0 011.146 6.906C3.56 2.958 7.622.982 11.686.982zm0 1.086A4.642 4.642 0 007.05 6.703a4.642 4.642 0 004.635 4.635 4.642 4.642 0 004.634-4.635 4.642 4.642 0 00-4.634-4.635zm0 1a3.627 3.627 0 013.634 3.635 3.627 3.627 0 01-3.634 3.635A3.627 3.627 0 018.05 6.703a3.627 3.627 0 013.635-3.635z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0d0e0f" data-name="Group 6353" /></svg>
        </i>
    )
}

export default Eye
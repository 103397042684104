export function getNumbersBetween(a, b) {
    let start = a,
    end = b
    if(a > b) {
        start = b
        end = a
    }

    if(a === b) return [a]

    let numbers = []
    let i = (start + 1)

    while(i < end) {
        numbers.push(i++)
    }
    return numbers
}
import React from 'react'


const ArrowUp = ({ title = 'Вверх', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.175 1.703">
                <title>{ title }</title>
                <path d="M1.551 0a.142.142 0 00-.1.042L.04 1.46a.142.142 0 00.001.202.142.142 0 00.202 0L1.584.312l1.348 1.34a.143.143 0 10.201-.203L1.716.043a.14.14 0 00-.132-.036C1.574.004 1.562 0 1.55 0z" data-name="Group 956" fill="#0a0a0a" strokeWidth=".927" /></svg>
        </i>
    )
}

export default ArrowUp
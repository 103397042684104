import React from 'react'
import {connect} from 'react-redux'

import {loadAvailList, loadUsedList, addToUsed, deleteUsedItems, toggleSelectedItems, moveUsedItemTo, saveUsedItemsMoving} from '../../../../store/Settings/actions/components/BackgroundLists'

import BackgroundLists from './BackgroundLists'


const BackgroundListsContainer = props => (
    <BackgroundLists
        className={props.className}
        timelineId={props.timelineId}

        used={props.used}
        usedOrder={props.usedOrder}
        usedOrdered={props.usedOrdered}
        usedSelected={props.usedSelected}
        usedVideo={props.usedVideo}
        usedLoading={props.usedLoading}
        usedDeleting={props.usedDeleting}

        availPlay={props.availPlay}
        availVideo={props.availVideo}
        availPlayAssoc={props.availPlayAssoc}
        availSelected={props.availSelected}
        availLoading={props.availLoading}
        availAddingToUsed={props.availAddingToUsed}

        savingUsedItemsMoving={props.savingUsedItemsMoving}

        selectUsedVideo={props.selectUsedVideo}
        loadUsedList={props.loadUsedList}
        loadAvailList={props.loadAvailList}
        addToUsed={props.addToUsed}
        deleteUsedItems={props.deleteUsedItems}
        toggleSelectedItems={props.toggleSelectedItems}
        moveUsedItemTo={props.moveUsedItemTo}
        saveUsedItemsMoving={props.saveUsedItemsMoving}
    />
)

const stateToProps = (state, props) => ({
    used: [...(state.settings.backgroundLists[props.timelineId]?.list || [])],
    usedOrder: state.settings.backgroundLists[props.timelineId]?.usedOrder || {},
    usedOrdered: state.settings.backgroundLists[props.timelineId]?.ordered || [],
    usedSelected: state.settings.backgroundLists[props.timelineId]?.selected,
    usedLoading: state.settings.backgroundLists[props.timelineId]?.__loading,
    usedDeleting: state.settings.backgroundLists[props.timelineId]?.__deleting,

    savingUsedItemsMoving: state.settings.backgroundLists[props.timelineId]?.__savingUsedItemsMoving,

    availPlay: state.settings.backgroundLists.avail?.playList || [],
    availVideo: state.settings.backgroundLists.avail?.videoList?.map(vl => {return {title: vl.title, id: vl.id, value: vl.id}}) || [],
    availPlayAssoc: state.settings.backgroundLists.avail?.assocPlayList || {},
    availSelected: state.settings.backgroundLists.avail?.selected,
    availLoading: state.settings.backgroundLists.avail?.__loading,
    availAddingToUsed: state.settings.backgroundLists.avail?.__addingToUsed,
})

const actionsToProps = {
    loadUsedList,
    loadAvailList,
    addToUsed,
    deleteUsedItems,
    toggleSelectedItems,
    moveUsedItemTo,
    saveUsedItemsMoving,
}

export default connect(stateToProps, actionsToProps)(BackgroundListsContainer)
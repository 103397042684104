import React, {useEffect} from 'react'

import {OBJECT_VOLUME} from '../../../../store/Settings/sets'

import {cl} from '../../../../utilities/cl'
import {generateStateKey} from '../../../../utilities/generateStateKey'
import {prepareSettings} from '../../utilities/prepareSettings'
import {carryUpdate} from '../../utilities/carryUpdate'
import {updateHelper} from '../../utilities/updateHelper'
import {validateMap} from './validateMap'
import {setsex, setsmob} from '../../utilities/setsclasses'

import Builder from '../../Builder'

import fieldInside from './items/inside'
import fieldOutside from './items/outside'
import fieldStarttime from './items/starttime'
import fieldBack from './items/back'
import SettingsDays from '../../components/Days'
import SettingsAddButton from '../../components/AddButton'
import GroupVolumeList from './groups/VolumeList'


const SettingsObjectVolume = ({
                                  className = "",
                                  stateKey,
                                  store = {},
                                  data = {},
                                  items = [],
                                  selected = [0],

                                  updateOptions,
                                  loadItems,
                                  addItem,
                                  saveItemSettings,
                                  deleteItems,
                                  toggleSelectedItems,
                                  openModal,
                                  closeModal,
                              }) => {

    className = cl(
        className,
        "SettingsObjectVolume"
    )

    useEffect(() => {
        loadItems({stateKey})
    }, [stateKey])

    const itemId = selected[0]
    const multiple = selected.length === 1 ? false : true

    const itemStateKey = multiple ? generateStateKey([stateKey, 0]) : generateStateKey([stateKey, itemId])

    let [settings, validMap] = prepareSettings({
        type: OBJECT_VOLUME,
        settings: store[itemStateKey],
        validateMap,
    });

    if(typeof settings['daymask'] === 'undefined') {
        //settings['daymask'] = 127;
    }

    updateOptions = carryUpdate({
        type: OBJECT_VOLUME,
        stateKey: itemStateKey,
        updateOptions
    })

    const hChange = (name) => updateHelper({
        name,
        updateOptions,
        handlerType: OBJECT_VOLUME
    })

    return (
        <Builder
            className={className}
            groups={{
                all: [
                    // Group
                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                            title: "Добавление",
                            className: setsex(["col-2", "mr", "pt-0"]) + setsmob(["col-1"])
                        },
                        items: [
                            {
                                title: "Помещение",
                                // disabled: multiple,
                                components: fieldInside({
                                    settings,
                                    name: "inside",
                                    onChange: hChange("inside")
                                })
                            },
                            {
                                title: "Улица",
                                // disabled: multiple,
                                components: fieldOutside({
                                    settings,
                                    name: "outside",
                                    onChange: hChange("outside")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                            className: setsex(["col-2", "mt-0"]) + setsmob(["col-1", "fd-column-reverse"])
                        },
                        items: [
                            {
                                title: "Время начала",
                                // disabled: multiple,
                                components: fieldStarttime({
                                    settings,
                                    name: "starttime",
                                    onChange: hChange("starttime")
                                })
                            },
                            {
                                title: "Музыка",
                                // disabled: multiple,
                                components: fieldBack({
                                    settings,
                                    name: "back",
                                    onChange: hChange("back")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            noDivider: true,
                            className: setsex(["col-2", "mr"])
                        },
                        items: [
                            <SettingsDays
                                value={settings["daymask"]}
                                name="daymask"
                                onChange={hChange("daymask")}
                                // disabled={multiple}
                            />
                        ]
                    },

                    // Group
                    {
                        data: {
                            noDivider: true,
                            className: setsex(["col-2", "visible"])
                        },
                        items: [
                            <SettingsAddButton/>
                        ]
                    },

                    // Список
                    <GroupVolumeList
                        className="setsex-col-1"
                        title="Редактирование расписания"
                        type={OBJECT_VOLUME}
                        stateKey={stateKey}
                        settings={store[itemStateKey]}
                        items={[...items]}
                        selected={selected}
                        loading={data.__loading}
                        saving={data.__saving}
                        adding={data.__adding}
                        deleting={data.__deleting}
                        validMap={validMap}
                        addItem={addItem}
                        saveItemSettings={saveItemSettings}
                        deleteItems={deleteItems}
                        toggleSelectedItems={toggleSelectedItems}
                        openModal={openModal}
                        closeModal={closeModal}
                    />
                ],
            }}
        />
    )
}

export default SettingsObjectVolume
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_SCHEDULE } from '../../../../store/Settings/sets'
import { ADD_ITEM, cleanSettings, loadItems } from '../../../../store/Settings/actions/sets/Object/Schedule'
import { adding } from '../../../../store/Settings/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(ADD_ITEM, worker)
}

function* worker({ payload }) {
    let { stateKey } = payload

    try {
        yield put(adding({
            type: OBJECT_SCHEDULE,
            stateKey,
            adding: true,
        }))

        const settings = yield select(state => state.settings.settings[OBJECT_SCHEDULE][stateKey] || {})
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedGroups = yield select(state => state.objectsGroup.selected || [])
        const selectedPeriod = settings.selectedPeriod || -1

        const msIds = selectedGroups.length ? selectedGroups : selectedObjects
        const added = yield call(fetchAdd, settings, selectedPeriod, msIds)

        // Ошибка
        if (added.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${ADD_ITEM}.`, added],
                text: "Ошибка при добавлении. Попробуйте повторить."
            }))

            // Успех
        } else {
            yield put(cleanSettings({ stateKey }))
            if (selectedGroups.length) {
                //если в группе, то нужно обновить еще и обычные мски в дополнение к группе
                yield put(loadItems({
                    stateKey,
                    itemIds: selectedObjects,
                    required: true,
                }))
            }
            yield put(loadItems({
                stateKey,
                required: true,
            }))
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${ADD_ITEM}.`, e],
            text: "Ошибка при добавлении. Попробуйте повторить."
        }))
    }

    finally {
        yield put(adding({
            type: OBJECT_SCHEDULE,
            stateKey,
            adding: false,
        }))
    }
}

function fetchAdd(settings, period, ms_id) {
    settings = { ...settings }

    settings.ms_id = ms_id.join(",")

    if (period !== -1) {
        settings.dates_id = period
    }

    if (settings["__without-ads"] === true) settings.daymask = settings.daymask + 128

    delete settings["__without-ads"]
    delete settings.__itemsLoaded
    delete settings.__loading
    delete settings.__deleting
    delete settings.__adding
    delete settings.selectedPeriod
    delete settings.selected
    delete settings.tracks
    delete settings.blocks
    if (settings.block < 0) {
        delete settings.block
    }

    return axios.get("/msbox/clip/insert", { params: { ...settings } })
        .then(response => response.data)
}
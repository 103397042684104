import {takeEvery, call, put, select} from 'redux-saga/effects'
import { closeModal } from '../../../store/Modal/actions'
import { loadTracks } from '../../../store/Tracks/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'
import {COPY_ADS} from "../../../store/Modal/types/AdsCopy/actions";
import {loadClips} from "../../../store/Settings/actions/components/Clips";
import { loadIndentObjects } from '../../../store/Objects/actions'


export default function* () {
    yield takeEvery(COPY_ADS, worker)
}

function* worker({ payload }) {
    // yield put(loading(true))
    try {
        const copied = yield call(fetchCopy, payload)
        const selectedTracks = yield select(state => state.tracks.selected)
        const selectedObjects = yield select(state => state.objects.selected)

        if (copied.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${COPY_ADS}.`, copied],
                text: "Реклама не скопирована. Попробуйте повторить."
            }))
            yield put(closeModal("oneInput"))

        } else {
            yield put(closeModal("oneInput"))
            yield put(loadTracks())
            yield put(loadIndentObjects())
            yield put(loadClips({
                indentId: selectedTracks[0],
                objectIds: [payload.to],
                required: true
            }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${COPY_ADS}.`, e],
            text: "Реклама не скопирована. Попробуйте повторить."
        }))
    }
    finally {
        // yield put(loading(false))
    }
}

function fetchCopy({from, to}) {
    let formData = new FormData()
    formData.set('from', from);
    formData.set('to', to);
    return axios(
        {
            url: "/timeline/clip/copy",
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { MAIN_OBJECT, MAIN_ADVERT, MAIN_CONTENT, REPORT } from '../../routes'
import { MEDIA_BREAKPOINT_LG, MEDIA_BREAKPOINT_MD } from '../../../utilities/breakpoints';
import ArrowDownLightWhite from '../../../components/SVG/ArrowDownLightWhite';
import { Button } from 'react-bootstrap';
import Panel from '../../../components/PanelContent'
import Place from '../../../components/PanelContent/Place'

import Search from '../../../components/Search'


const AdvertPanel = ({
    page,
    searchState,
    selected,
    selected_state
}) => {
    const disabled = isDisabled(page)
    let history = useHistory()
    const hBack = () => {
        document.location.reload()
        history.push('/report')
    }

    return (
        <Panel page={ page } disabled={ disabled }>
            <Place
                title="Что"
                number={ 1 }
                className={ `${(searchState.modeActive) ? "right-extend" : ""}` }
                disabled={ disabled }
                elements={ {
                    right: <Search placeholder="Поиск по объектам" searchType="objects" disabled={ disabled } />,
                    left:
                        ((selected.length > 0 || selected_state.length > 0) && window.innerWidth < 1200) && <div style={ {
                            width: "182px",
                            height: "100%",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                        } }
                            onClick={ () => hBack() }
                        >
                            <div style={ { transform: "rotate(90deg)", width: "30px" } } className="Back"><ArrowDownLightWhite /></div><span style={ { color: "white" } }>К СТАТУСАМ ОБЪЕКТОВ</span>
                        </div>
                } }
            />
            <Place
                title="Где"
                number={ 2 }
                disabled={ disabled }
            />
        </Panel>
    )
}

const stateToProps = state => ({
    searchState: state.search["objects"],
    selected: state.objects.selected,
    selected_state: state.report.selected
    //searchState: state.search
})

export default connect(stateToProps, null)(AdvertPanel)

//
// Функции
//

function isDisabled(page) {
    return (page !== MAIN_OBJECT && page !== MAIN_ADVERT && page !== MAIN_CONTENT && page !== REPORT) ? true : false
}
import React from 'react'


const PlusCircle = ({ title = 'Добавить', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 4.233">
                <title>{ title }</title>
                <g strokeWidth="1.188"><path d="M2.117-.02A2.139 2.139 0 00-.021 2.115a2.14 2.14 0 002.138 2.138 2.14 2.14 0 002.137-2.138A2.14 2.14 0 002.117-.02zm0 .264A1.87 1.87 0 013.99 2.116 1.871 1.871 0 012.117 3.99 1.87 1.87 0 01.244 2.116 1.87 1.87 0 012.117.244zm-.012.864a.111.111 0 00-.111.112V2h-.78a.11.11 0 00-.111.11c0 .063.05.112.11.112h.781v.78a.111.111 0 10.223 0v-.78h.78c.061 0 .11-.05.11-.111A.11.11 0 002.998 2h-.78v-.78a.112.112 0 00-.112-.112z" data-name="Group 4143" fill="#0a0a0a" /><circle cx="9.5" cy="9.5" r="9.5" stroke="none" transform="scale(.2228)" data-name="Ellipse 719" fill="none" /></g></svg>
        </i>
    )
}

export default PlusCircle
import React from 'react'
import { connect } from 'react-redux'


import { cl } from '../../../../../utilities/cl'
import { Button as ButtonBS } from 'react-bootstrap'

import { OLD_LK } from '../../../../routes'

const SettingsInterface = ({
    className = "",
}) => {

    className = cl(
        className,
        "SettingsObjectObjectCollapsible"
    )

    const toOldLk = () => {
        window.location.replace(OLD_LK)
    }

    return (
        <div>
            <h2>Прошлая версия</h2>

            <ButtonBS style={{ marginTop: 20 }} onClick={toOldLk}>Перейти</ButtonBS>
        </div>
    )
}

export default connect(null, null)(SettingsInterface)
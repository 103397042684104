import React, {useEffect, useRef, useCallback} from 'react'

import { cl } from '../../../utilities/cl'

import InputNumber from '../InputNumber'
import SvgArrowUpLight from '../../SVG/ArrowUpLight'
import SvgArrowDownLight from '../../SVG/ArrowDownLight'


let changes = 0

const CycleTimeTime = ({
    title = "",
    index,
    value,
    isTime,
    from,
    to,
    step,
    onChange,
}) => {

    const elemRef = useRef(null)

    value = (value === undefined) ? from : value
    if(value > to) value = to
    if(value < from) value = from

    const hChange = (inc) => {
        let numb = step*inc,
            val  = value+numb
        if(val > to) val = to
        if(val < from) val = from

        onChange({
            val, index
        })
    }

    const hChangeInput = ({ value }) => onChange({ val: Number(value), index })

    const hTakeVal = (e) => {
        if(e.target.dataset.valInc) {
            const inc = Number(e.target.dataset.valInc)
            hChange(inc)
        }
    }

    const hArrowToggle = (e) => {
        if(e.keyCode === 38) hChange(-1)
        if(e.keyCode === 40) hChange(+1)
    }

    const inputValidate = (val) => val >= from && val <= to

    // Колёсико

    const hWheel = useCallback((e) => {
        e = e || window.event
        let delta = e.deltaY || e.detail || e.wheelDelta

        changes++

        delta > 0 ? hChange(+1) : hChange(-1)

        e.preventDefault ? e.preventDefault() : (e.returnValue = false)
        return false
    }, [changes])

    useEffect(() => {
        const elem = elemRef.current
        if (elem && elem.addEventListener) {
            if ('onwheel' in document) {
                elem.addEventListener("wheel", hWheel, { passive: false });
            } else if ('onmousewheel' in document) {
                elem.addEventListener("mousewheel", hWheel, { passive: false });
            } else {
                elem.addEventListener("MozMousePixelScroll", hWheel, { passive: false });
            }
        }
        return () => {
            const elem = elemRef.current
            if (elem && elem.addEventListener) {
                if ('onwheel' in document) {
                    elem.removeEventListener("wheel", hWheel, { passive: false });
                } else if ('onmousewheel' in document) {
                    elem.removeEventListener("mousewheel", hWheel, { passive: false });
                } else {
                    elem.removeEventListener("MozMousePixelScroll", hWheel, { passive: false });
                }
            }
        }
    }, [changes])

    return(
        <div className="FormCycleTimeTime" ref={elemRef}>
            <div className="FormCycleTimeTime__title">{title}</div>

            <div className="FormCycleTimeTime__content">
                <div className="FormCycleTimeTime__times" onClick={hTakeVal} onKeyDown={hArrowToggle}>
                    <div className="time time_1" data-val-inc="-2">{filterTime(-2)}</div>
                    <div className="time time_2" data-val-inc="-1">{filterTime(-1)}</div>
                    <InputNumber
                        className="time time_3"
                        attrs={{
                            value: filterTime(0)
                        }}
                        validate={inputValidate}
                        onChange={hChangeInput}
                    />
                    <div className="time time_4" data-val-inc="+1">{filterTime(+1)}</div>
                    <div className="time time_5" data-val-inc="+2">{filterTime(+2)}</div>
                </div>

                <div className="FormCycleTimeTime__buttons">
                    <div
                        onClick={() => hChange(-1)}
                        className={cl(
                            "up",
                            {"disabled": value <= from},
                        )}
                    >
                        <SvgArrowUpLight variant="dark" size="xs" title="Меньше"/>
                    </div>
                    <div
                        onClick={() => hChange(+1)}
                        className={cl(
                            "down",
                            {"disabled": value >= to},
                        )}
                    >
                        <SvgArrowDownLight variant="dark" size="xs" title="Больше"/>
                    </div>
                </div>

            </div>
        </div>
    )

    function filterTime(inc) {
        let numb = step*inc,
            val  = value+numb

        if(val > to || val < from) val = ""

        val = val.toString()
        if(val.length === 1 && isTime === true) val = `0${val}`

        return val
    }
}

export default CycleTimeTime
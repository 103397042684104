import {takeEvery, call, put, all, select} from 'redux-saga/effects'
import { cleanSettings, saving } from '../../../../store/Settings/actions'
import { SAVE_SETTINGS } from '../../../../store/Settings/actions/sets/Advert/Tracks'
import { loadClips } from '../../../../store/Settings/actions/components/Clips'
import { loadTracks } from '../../../../store/Tracks/actions'
import { loadIndentObjects } from '../../../../store/Objects/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_SETTINGS, worker)
}

function* worker({ payload }) {
    yield put(saving({ ...payload, __saving: true }))

    console.log("SAVE_SETTINGS",{payload});

    try {
        const {
            type,
            indentIds,
            objectIds,
            stateKey,
            settings
        } = payload
        const selectedGroups = yield select(state => state.objectsGroup.selected)
        const tracksAssocTheme = yield select(state => state.tracks.tracksAssocTheme)
        const isTheme = typeof tracksAssocTheme[indentIds[0]] !== "undefined"

        const saved = yield call(fetchSave, indentIds, selectedGroups.length > 0 ? selectedGroups : objectIds, settings)

        if (saved.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${SAVE_SETTINGS}.`, saved],
                text: saved.error
            }))
        } else {
            if(saved.warning) {
                yield put(showAlertSuccess({
                    responses: [`Успешное выполнение ${SAVE_SETTINGS}.`, saved],
                    text: saved.warning
                }))
            } else {
                yield put(showAlertSuccess({
                    responses: [`Успешное выполнение ${SAVE_SETTINGS}.`, saved],
                    text: `Настройки успешно сохранены.`
                }))
            }

            yield put(cleanSettings({
                type,
                stateKey
            }))
            if (isTheme) {
                yield put(loadTracks({theme: true}))
            } else {
                yield put(loadTracks())
            }

            yield put(loadIndentObjects())

            yield all(
                indentIds.map(indentId =>
                    put(loadClips({
                        indentId,
                        objectIds,
                        required: true
                    }))
                )
            )
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_SETTINGS}.`, e],
            text: e.message
        }))
    }

    finally {
        yield put(saving({ ...payload, __saving: false }))
    }
}

function fetchSave(indentIds, objectIds, settings) {
    if ((!settings.todate || settings.todate === "") && settings.fromdate?.length) settings.todate = settings.fromdate

    //if (settings.__inhour && settings.__inhour !== 0) settings.block = convertInhourToBlock(settings.__inhour)
    if (settings.__inhour && settings.__inhour !== 0) delete settings.block
    if (settings.__inday && settings.__inday === true) settings.daymask = 85
    if (settings.block && settings.block !== "0" && settings.block?.length) delete settings.steptime

    if(!settings.fromtime || settings.fromtime === "") delete settings.fromtime
    if(!settings.totime || settings.totime === "") delete settings.totime
    if(!settings.steptime || settings.steptime === "") delete settings.steptime

    delete settings.__settingsType
    delete settings.__saving
    //delete settings.__inhour
    delete settings.__inday

    return axios.get("/timeline/clip/insert", {
        params: {
            indent_id: indentIds.join(","),
            ms_id: objectIds.join(","),
            ...settings
        }
    })
        .then(response => response.data)
}

function convertInhourToBlock(value) {
    const step = 12 / value
    let blocks = []

    let block = 0
    for (let i = 0; i < value; i++) {
        blocks.push(Math.round(block + step))
        block += step
    }
    return blocks.join(",")
}
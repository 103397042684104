import { PUT_TZ } from './actions'

const initialState = {
    tz: []
}

const reducer = (state = initialState, { type, payload }) => {
    switch(type) {

        case PUT_TZ:
            return {
                tz: payload.tz
            }
    }
    return state
}

export default reducer
import {takeEvery, put, select} from 'redux-saga/effects'
import {updateOptions} from '../../../store/Settings/actions'
import {APPLY_PRESET} from '../../../store/Settings/actions/components/Presets'
import {showAlertError} from '../../../store/Alert/actions'


export default function*() {
    yield takeEvery(APPLY_PRESET, worker)
}

function* worker({ payload }) {
    let { type, presetId, stateKey } = payload
    presetId = Number(presetId)
    
    const presets = yield select(state => state.settings.presets[type] || [])

    let preset = null
    presets.map(p => {
        if(p.id === presetId) {
            preset = JSON.parse(p.data)
        }
    })

    if(preset !== null) {
        delete preset.__settingsType
        yield put(updateOptions({
            type,
            stateKey,
            settings: preset
        }))
    } else {
        yield put(showAlertError({
            errors: [payload, presets, preset, stateKey],
            text: "Пресет не был применён. Попробуйте повторить."
        }))
    }
}
export const DOWNLOAD = "Modal/Castinfo/DOWNLOAD"
export const DOWNLOADING = "Modal/Castinfo/DOWNLOADING"

export const download = downloadData => ({
    type: DOWNLOAD,
    payload: {
        dates: downloadData.dates,
        allPeriod: downloadData.allPeriod
    }
})

export const downloading = mode => ({
    type: DOWNLOADING,
    payload: mode
})
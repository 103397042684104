export function carryMemoize(func, limit = -1) {
    let store = {},
        objects = {},
        storeKeys = [],
        lastRewritten = 0
    
    return function(args = [], deps = [], required) {
        let storeKey = deps.join("-"),
            linksAreOk = true

        const containsLinks = getLinksFromDeps(deps)

        if(containsLinks.length) {
            if(objects[storeKey] === undefined) objects[storeKey] = []
            deps.map((dep, index) => {
                if(dep !== objects[storeKey][index]) {
                    linksAreOk = false
                }
                objects[storeKey][index] = dep
            })
        }

        if(store[storeKey] === undefined || linksAreOk === false || required === true) {
            const shot = func(...args)

            if(limit > 0 && typeof limit === "number") {
                if(storeKeys.length < limit) {
                    store[storeKey] = shot
                    storeKeys.push(storeKey)
                } else {
                    if((lastRewritten+1) > limit) lastRewritten = 0

                    delete store[storeKeys[lastRewritten]]
                    storeKeys[lastRewritten] = storeKey

                    store[storeKey] = shot

                    lastRewritten++
                }
            } else {
                store[storeKey] = shot
            }
        }

        return store[storeKey]
    }
}

function getLinksFromDeps(deps = []) {
    let links = []
    deps.map(dep => {
        if(isLink(dep)) links.push(dep)
    })
    return links
}

function isLink(any) {
    return (typeof any === "object" || typeof any === "function") ? true : false
}
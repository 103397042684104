import {isFunc} from './isFunc'
import {isObj} from './isObj'


// const sampleMap = {
//     prop1: {
//         required: true,
//         type: "string",
//         min: 0,
//         max: 10,
//         func: (val) => val.indexOf("forbidden-phrase") === -1,
//         regexp: /[абвгд]/
//     },
//     prop2: {
//         required: true,
//         type: "number",
//         min: 0,
//         max: 10,
//         func: (val) => val !== 5
//     },
//     prop3: {
//         required: true,
//         type: "array",
//         min: 0,
//         max: 10,
//         func: (arr) => arr.indexOf("forbidden-elem") === -1
//     },
//     prop4: {
//         required: true,
//         type: "object",
//         min: 0,
//         max: 10,
//         func: (obj) => obj.name !== undefined
//     },
//     prop5: {
//         required: true,
//         type: "NaN", // "undefined" || "null" || "bool"
//     },
//     validGroups: {
//         props: ["prop3", "prop4", "prop5"],
//     }
// }

const kkk = {
    
}


export function isValidObj(map, props) {
    if(!isObj(map) || !isObj(props)) return false
    
    const empty = undefined

    // Ускоренная проврка
    if(map.validGroups === empty) {
        for(let key in map) {
            const smpl = map[key]
            const prop = props[key]

            if(validateProp(smpl, prop) === false)
                return false
        }
        return true
        
    // Проверка с группами
    } else {
        const validMap = mapValidObj(map, props)
        return validMap.validResult
    }
}

export function mapValidObj(map, props) {
    if(!isObj(map) || !isObj(props)) return {validResult: false, validGroups: {}}
    let validResult = true,
        validMap    = {}

    const empty     = undefined

    for(let key in map) {
        if(key !== "validGroups") {
            const smpl = map[key]
            const prop = props[key]
            const rslt = validateProp(smpl, prop)

            validMap[key] = rslt            

            if(map.validGroups === empty) {
                if(rslt === false)
                    validResult = false
            }
        }
    }

    if(map.validGroups !== empty) {
        let groupsProps = []

        // Объединям группы в один массив
        for(let key in map.validGroups) {
            const group = map.validGroups[key]

            if(Array.isArray(group)) {
                groupsProps = groupsProps.concat(group)
            }
        }

        // Проверяем валидность без учёта групповых
        for(let key in validMap) {
            const prop = validMap[key]

            if(groupsProps.indexOf(key) === -1) {
                if(prop === false)
                    validResult = false
            }
        }

        // Проверяем валидность групп
        validMap.validGroups = {}

        for(let key in map.validGroups) {
            let isGroupValid = false,
                group        = map.validGroups[key]

            group.map(prop => {
                if(validMap[prop] === true)
                    isGroupValid = true
            })

            validMap.validGroups[key] = isGroupValid

            if(isGroupValid === false)
                validResult = false
        }
    }

    validMap.validResult = validResult

    return validMap
}

export function validateProp(smpl, prop) {
    if(!isObj(smpl)) return false
    
    const empty = undefined

    // required

    if(smpl.required === true) {
        if(prop === empty)
            return false
    }

    // no required

    if(prop !== empty) {

        // array
        if(smpl.type === "array") {
            if(!Array.isArray(prop))
                return false

            if(smpl.min) {
                if(prop.length < smpl.min)
                    return false
            }
            if(smpl.max) {
                if(prop.length > smpl.max)
                    return false
            }
        }

        // object
        if(smpl.type === "object") {
            if(!isObj(prop))
                return false

            let proplen

            if(smpl.min !== empty || smpl.max !== empty)
                proplen = objLength(prop)

            if(smpl.min) {
                if(proplen < smpl.min)
                    return false
            }
            if(smpl.max) {
                if(proplen > smpl.max)
                    return false
            }
        }

        // string
        if(smpl.type === "string") {
            if(typeof prop !== "string")
                return false

            if(smpl.min) {
                if(prop.length < smpl.min)
                    return false
            }
            if(smpl.max) {
                if(prop.length > smpl.max)
                    return false
            }
            if(smpl.regexp) {
                if(!smpl.regexp.test(prop))
                    return false
            }
        }

        // number
        if(smpl.type === "number") {
            if(typeof prop !== "number")
                return false

            if(smpl.min) {
                if(prop < smpl.min)
                    return false
            }
            if(smpl.max) {
                if(prop > smpl.max)
                    return false
            }
        }

        // undefined
        if(smpl.type === "undefined") {
            if(prop !== empty)
                return false
        }

        // null
        if(smpl.type === "null") {
            if(prop !== null)
                return false
        }

        // NaN
        if(smpl.type === "NaN") {
            if(!isNaN(prop))
                return false
        }

        // bool
        if(smpl.type === "bool") {
            if(typeof prop !== "boolean")
                return false
        }

        if(isFunc(smpl.func)) {
            if(smpl.func(prop) === false)
                return false
        }
    }

    return true
}

//
// Функции
//

function objLength(obj) {
    let count = 0
    for(let k in obj) {
        count++
    }
    return count
}
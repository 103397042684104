import { takeEvery, call, put, select } from 'redux-saga/effects'
import { loadTracks } from '../../store/Tracks/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { loadIndentObjects } from "../../store/Objects/actions";
import { loadClips } from "../../store/Settings/actions/components/Clips";


import { COPY_VIDEO } from '../../store/ContentAllVideolists/actions';

export default function* () {
    yield takeEvery(COPY_VIDEO, worker)
}

function* worker({ payload }) {
    // yield put(loading(true))
    console.log({ payload });
    if (!payload) {
        return;
    }
    const selectedTracks = payload;

    try {
        //TODO TASK_24
        const response = yield call(fetchCopyVideo, selectedTracks)

        if (response.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${COPY_VIDEO}.`, response],
                text: "Ролики не добавлены. Попробуйте повторить."
            }))

        } else {
            yield put(loadTracks())
            yield put(loadTracks({ theme: true }))
            yield put(loadIndentObjects())
            yield put(loadClips({
                indentId: selectedTracks[0],
                objectIds: [payload],
                required: true
            }))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${COPY_VIDEO}.`, e],
            text: "Ролики не добавлены. Попробуйте повторить."
        }))
    } finally {
        // yield put(loading(false))
    }
}

function fetchCopyVideo(id) {
    return axios.get("/clip/copy", {
        params: {
            id
        }
    }).then(response => response.data)

    return axios(
        {
            url: `/clip/copy?id=${id}`,
            method: "GET"

        }
    )
        .then(response => response.data)
}
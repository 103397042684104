import React from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../../utilities/cl'

import Video from './Video'
import VideoExtra from './VideoExtra'
import VideoTheme from './VideoTheme'
import Line from './Line'

import Place from '../../../../components/Space/Place'
import Empty from '../../../../components/Space/Empty'

import SlideMenu from '../../../../components/SlideMenu'
import ObjectsMenu from '../../../../components/ObjectsMenu'

import { disableBlocks, enableBlocks } from '../../../../store/AdvertPage/actions'
import { addVideoToAdvert, addVideoToExtra } from '../../../../store/ContentAllVideolists/actions'
import { toggleHideEnded } from '../../../../store/Tracks/actions'

class What extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            empty: false
        }

        this.setEmptyMode = this.setEmptyMode.bind(this)
    }

    componentDidUpdate() {
        const {
            searchActive,
            searchFounded,
            searchValue
        } = this.props

        if (searchActive) {
            (searchValue === "")
                ? this.setEmptyMode(false)
                : this.setEmptyMode(searchFounded.length === 0)
        } else {
            this.setEmptyMode(false)
        }
    }

    setEmptyMode(mode) {
        if (!mode) {
            this.setState(state => {
                if (state.empty) {
                    return { empty: false }
                }
            })
        } else {
            this.setState(state => {
                if (!state.empty) {
                    return { empty: true }
                }
            })
        }
    }

    render() {
        const {
            searchActive,
            disableBlocks,
            enableBlocks,
            addVideoToAdvert,
            toggleHideEnded
        } = this.props
        const {
            empty
        } = this.state

        const hideContent = cl({ "d-none": empty })
        const placeHolder = cl({ "d-none": !empty })



        const onVideoThemeContentCollapsableChanged = (opened) => {

            if (opened)
                disableBlocks()
            else
                enableBlocks()
        }


        return (
            <>
                {/* 1 */}

                <Place
                    number={1}
                    className={cl(
                        "AdvertWhat",
                        { "search-active": searchActive }
                    )}
                >
                    <ObjectsMenu />

                    <SlideMenu
                        activeItem={1}
                        className={hideContent}

                        items={[
                            {
                                title: "Ролики",
                                content: <Video title="Ролики" className={hideContent} active placeNumber={1} toggleHideEnded={toggleHideEnded} />
                            },                            
                            {
                                title: "Тематические ролики",
                                content: <VideoTheme title="Тематические ролики" className={hideContent} placeNumber={2} onChangeCollapsible={onVideoThemeContentCollapsableChanged} addVideoToAdvert={addVideoToAdvert} />
                            },
                            {
                                title: "Экстренные ролики",
                                content: <VideoExtra title="Экстренные ролики" className={hideContent} placeNumber={3} onChangeCollapsible={onVideoThemeContentCollapsableChanged}/>
                            },
                            // {
                            //     title: "Бегущая строка",
                            //     content: <Line className={ hideContent } placeNumber={ 1 } />
                            // }
                        ]}
                    />

                    <Empty className={placeHolder}>
                        Роликов с таким названием не найдено
                    </Empty>
                </Place>
            </>
        )
    }
}

const stateToProps = state => ({
    searchActive: state.search["tracks"].modeActive,
    searchValue: state.search["tracks"].value,
    searchFounded: [...state.search["tracks"].founded],
})

const actionsToProps = { disableBlocks, enableBlocks, addVideoToAdvert, toggleHideEnded }

export default connect(stateToProps, actionsToProps)(What)
import {DOWNLOADING} from './actions'

const initialState = {
    downloading: false
}

const reducer = (state = initialState, {type, payload}) => {
    switch(type) {

        case DOWNLOADING:
            return {
                ...state,
                downloading: payload
            }
    }
    return state
}

export default reducer
import React from 'react'
import {connect} from 'react-redux'

import {openModal, closeModal} from '../../../../store/Modal/actions'
import {updateOptions, toggleSelectedItems} from '../../../../store/Settings/actions'
import {loadItems, addItem, saveItemSettings, deleteItems} from '../../../../store/Settings/actions/sets/Object/Volume'
import {OBJECT_VOLUME} from '../../../../store/Settings/sets'

import ObjectVolume from './ObjectVolume'


const ObjectVolumeContainer = props => (
    <ObjectVolume
        className={props.className}
        stateKey={props.stateKey}
        store={props.store}
        data={props.data}
        items={props.items}
        selected={props.selected}
        loadItems={props.loadItems}
        addItem={props.addItem}
        saveItemSettings={props.saveItemSettings}
        deleteItems={props.deleteItems}
        toggleSelectedItems={props.toggleSelectedItems}
        updateOptions={props.updateOptions}
        openModal={props.openModal}
        closeModal={props.closeModal}
    />
)

const stateToProps = (state, props) => {
    const store    = state.settings.settings[OBJECT_VOLUME]
    const data     = store[props.stateKey] || {}
    const selected = data.selected || [0]
    return {
        store,
        data,
        items: [...(data.list || [])],
        selected: [...selected]
    }
}

const actionsToProps = {
    loadItems,
    toggleSelectedItems,
    addItem,
    saveItemSettings,
    deleteItems,
    updateOptions,
    openModal,
    closeModal,
}

export default connect(stateToProps, actionsToProps)(ObjectVolumeContainer)
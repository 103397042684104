import React from 'react'
import {connect} from 'react-redux'

import Header from '../../components/header'

import ScrollContainer from '../../../ScrollContainer'
import Spinner from '../../../Spinner'
import moment from "moment";
import StringDays from "../../../StringDays";


const VideoHistory = ({
                          loading,
                          indentName,
                          videoHistory,
                          toggleOpened
                      }) => {

    return (
        <div className="ModalMediaplan">
            <Header
                title="История публикаций"
                toggleOpened={toggleOpened}
                addedTitleElement={(
                    <div className="ModalMediaplan__track-name">
                        {indentName}
                    </div>
                )}
            />

            <ScrollContainer
                collapsed={true}
                size="lg"
                scrollVariant="gray"
            >
                {loading
                    ? <Spinner className="pt-xs pb-xs"/>

                    : (videoHistory && videoHistory[0])
                        ?
                        <>
                            <div className="ModalMediaplanObject__header2">
                                <div className="ModalMediaplanObject__grid grids-titles">
                                    <div className="start">
                                        Время начала
                                    </div>
                                    <div className="end">
                                        <span className="des">Время окончания</span>
                                    </div>
                                    <div className="end">
                                        <span className="des">Канал</span>
                                    </div>
                                    <div className="daymask">
                                        Дни
                                    </div>
                                    <div className="end">
                                        <span className="des">Минуты часа</span>
                                    </div>
                                </div>
                            </div>
                            {videoHistory.map(object => {
                                return (
                                    <div key={object.id} className="ModalMediaplanObject__item">
                                        <div className="ModalMediaplanObject__grid">
                                            <div className="start">
                                                {moment(object.fromdate).format("DD[.]MM[.]YYYY")}
                                                <br/>
                                                <small>{object.fromtime}</small>
                                            </div>
                                            <div className="end">
                                                {moment(object.todate).format("DD[.]MM[.]YYYY")}
                                                <br/>
                                                <small>{object.totime}</small>
                                            </div>
                                            <div className="end">
                                                {(object.channel === 1) ? "Улица" : (object.channel === 2)
                                                    ? "Помещение" : "Улица/Помещение"}
                                            </div>
                                            <div className="daymask">
                                                <StringDays daymask={object.daymask}/>
                                            </div>
                                            <div className="">
                                                {object.block.split(', ').map(b => (b-1)*5).join(', ')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>

                        : <div className="ModalMediaplan__empty">
                            Записей пока нет.
                        </div>
                }
            </ScrollContainer>
        </div>
    )
}

const stateToProps = state => ({
    loading: state.modalVideoHistory.loading,
    indentName: state.modalVideoHistory.indentName,
    videoHistory: state.modalVideoHistory.videoHistory,
})

export default connect(stateToProps, null)(VideoHistory)
import { isElem } from './isElem'


export function position({
    parent,
    child,
    offsets = {}, // y, left, right
    isWidthAuto = false,
    isSqueeze = true,
    isOverlay = false,
}) {

    let pos = {}

    if(isElem(child)) {
        parent = parent || child.parentElement

        if(isElem(parent)) {
            const parentParams = parent.getBoundingClientRect()

            pos = {
                top: parentParams.top + window.scrollY - (offsets.y || 0),
                left: parentParams.left - (offsets.left || 0),
            }

            if(!isOverlay) {
                pos.top += parentParams.height + ((offsets.y || 0)*2)
            }

            if(!isWidthAuto) {
                pos.width = parentParams.width + (offsets.right || 0) + (offsets.left || 0)
            }

            pos.squeezed = false

            // Если нужно постараться уместить child в видимую часть экрана

            if(isSqueeze) {
                const childParams = child.getBoundingClientRect()

                const viewBottomMax = window.scrollY + window.innerHeight
                const childBottom = pos.top + childParams.height

                // bottom
                if(childBottom > viewBottomMax) {
                    pos.top = (window.scrollY + parentParams.top) - childParams.height - (offsets.y || 0)
                    
                    if(isOverlay) {
                        pos.top += parentParams.height + (offsets.y || 0)*2
                    }
                    pos.squeezed = true
                }

                // left
                if(pos.left < 0) {
                    pos.left = 0 + (offsets.left || 0)
                    pos.squeezed = true
                }

                // right
                if(!isWidthAuto) {
                    const childRight = pos.left + pos.width
                    if(childRight > window.innerWidth) {
                        pos.left = childRight - window.innerWidth - (offsets.right || 0) - (offsets.left || 0)
                        pos.squeezed = true
                    }
                }
            }
        }
    }

    return pos
}
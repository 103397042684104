import React from 'react'
import {connect} from 'react-redux'

import List from './List'


class ListContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.didMount(this.props.trackId)
    }

    render() {
        return(
            <List
                list={this.props.list}
                deleteItem={this.props.deleteItem}
            />
        )
    }
}

const stateToProps = state => ({
    list: [],
    deleteItem: {}
})

const actionsToProps = {
    // didMount
}


export default connect(stateToProps, actionsToProps)(ListContainer)
import React from 'react';
import { Route, Redirect } from 'react-router-dom';


//Вспомогательный компонент для определения того, какие компоненты можно показывать не авторизованному пользователю

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={ props => (

        localStorage.getItem('sessionToken')
            ? <Component { ...props } />
            : <Redirect to={ { pathname: '/login', state: { from: props.location } } } />
    ) } />
)
import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { DELETE_ADVERT, deleting } from '../../../store/Modal/types/AdvertCreate/actions'
import { loadTracks } from '../../../store/Settings/actions/sets/Object/Schedule'
import { showAlertError, showAlertSuccess } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_ADVERT, worker)
}

function* worker({ payload }) {
    const { advertId } = payload

    try {
        if (!isNaN(advertId)) {
            yield put(deleting(true))

            const deleted = yield call(fetchDelete, advertId)

            if (deleted.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${DELETE_ADVERT}.`, deleted],
                    text: "Объявление не было удалено. Попробуйте повторить."
                }))
            } else {
                yield put(loadTracks({ required: true }))
                yield put(showAlertSuccess({
                    response: [`Успешное выполнение ${DELETE_ADVERT}.`, deleted],
                    text: "Объявление успешно удалено."
                }))
            }
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_ADVERT}.`, e],
            text: "Объявление не было удалено. Попробуйте повторить."
        }))
    }
    finally {
        yield put(deleting(false))
    }
}

function fetchDelete(id) {
    return axios.get(`/msbox/adt/delete?id=${id}`)
        .then(response => response.data)
}
import React from 'react'


const MoveDown = ({ title = 'Переместить вниз', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.645 16.207">
                <title>{ title }</title>
                <path d="M5.316-.006A.5.5 0 004.822.5v13.793l-3.761-3.762a.5.5 0 10-.707.707l4.564 4.565a.5.5 0 00.318.195.5.5 0 00.041.006.5.5 0 00.008 0 .5.5 0 00.043.002.5.5 0 00.412-.217l4.551-4.55a.5.5 0 10-.707-.708l-3.762 3.762V.5a.5.5 0 00-.506-.506z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" data-name="Group 5814" /></svg>
        </i>
    )
}

export default MoveDown
import React, {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'

import {random} from '../../../../utilities/random'
import {cl} from '../../../../utilities/cl'
import {isFunc} from '../../../../utilities/isFunc'

import Item from '../Group/Item'


const WithoutAds = ({
    className,
    value = false,
    name = "settings-without-ads",
    onChange,
    disabled,
}) => {

    const [checked, setChecked] = useState(value)

    useEffect(() => {
        setChecked(value)
    }, [value])

    className = cl(
        className,
        "SettingsWithoutAds"
    )

    const checkId = `${name}-${random()}`

    const hChange = (e) => {
        const check = e.target

        if(check.tagName === "INPUT") {
            const nextValue = check.checked

            setChecked(nextValue)
            if(isFunc(onChange)) {
                onChange({
                    name,
                    value: nextValue
                })
            }
        }
    }

    return(
        <Item title=" ">
            <div className={className}>
                <Form.Check
                    inline
                    name={name}
                    id={checkId}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onClick={hChange}
                    label={<>
                        <span className="circle"></span>
                        — Без рекламы
                    </>}
                />
            </div>
        </Item>
    )
}

export default WithoutAds
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_VOLUME } from '../../../../store/Settings/sets'
import { adding } from '../../../../store/Settings/actions'
import { ADD_ITEM, loadItems, cleanBuffer } from '../../../../store/Settings/actions/sets/Object/Volume'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(ADD_ITEM, worker)
}

function* worker({ payload }) {
    const { stateKey } = payload

    try {
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedItems = yield select(state => state.settings.settings[OBJECT_VOLUME][stateKey].selected || [])
        const itemId = selectedItems[0] || 0

        if (itemId === 0) {
            yield put(adding({
                type: OBJECT_VOLUME,
                stateKey,
                adding: true,
            }))

            const itemStateKey = generateStateKey([stateKey, itemId])

            const itemSetting = yield select(state => state.settings.settings[OBJECT_VOLUME][itemStateKey] || {})
            const fetchedAdd = yield call(fetchAdd, itemSetting, selectedObjects)

            // Ошибка
            if (fetchedAdd.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${ADD_ITEM}.`, fetchedAdd],
                    text: "Не удалось создать элемент. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(showAlertSuccess({
                    responses: [`Успешное выполнение ${ADD_ITEM}.`, fetchedAdd],
                    text: "Элемент успешно создан."
                }))
                yield put(cleanBuffer({
                    stateKey,
                }))
                yield put(loadItems({
                    stateKey,
                    required: true,
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${ADD_ITEM}.`, e],
            text: "Не удалось создать элемент. Попробуйте повторить."
        }))
    }

    finally {
        yield put(adding({
            type: OBJECT_VOLUME,
            stateKey,
            adding: false,
        }))
    }
}

function fetchAdd(settings, ms_id) {
    settings.ms_id = ms_id.join(",")

    return axios.get("/msbox/volume/insert", { params: { ...settings } })
        .then(response => response.data)
}
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../utilities/cl'
import { isMobile } from '../../utilities/media'
import { getNextSelected } from '../../utilities/getNextSelected'

import Left from './Left/Left'



const Playlists = ({
    className = "",
    types = [],
    allPlaylists,
    selectedPlaylists,
    loading,
    searchActive,
    searchFounded,
    loadPlaylists,
    toggleSelectedPlaylist,
    searchVal
}) => {

    const [doubleView, setDoubleView] = useState(false)

    useEffect(() => {
        loadPlaylists()
    }, [])

    className = cl(
        className,
        "Playlists",
        { "double": doubleView }
    )

    const hToggleSelected = (e) => {
        const playlist = e.target.closest(".Playlist")

        if (playlist) {
            const playlistId = Number(playlist.dataset.playlistId)

            if (!isNaN(playlistId)) {
                const selected = getNextSelected({
                    multiple: true,
                    itemId: playlistId,
                    selected: selectedPlaylists,
                    ctrlKey: !isMobile() || e.ctrlKey || e.metaKey,
                })

                toggleSelectedPlaylist({
                    selected,
                    simple: true,
                })
            }
        }
    }
    const isSimple = (Array.isArray(types) && types?.indexOf("simple") !== -1)

    return (
        <section className={ className } onClick={ () => setDoubleView(!doubleView) }>

            <Left
                playlists={ allPlaylists }
                loading={ loading }
                searchActive={ searchActive }
                searchFounded={ searchFounded }
                searchVal={ searchVal }
                toggleSelected={ hToggleSelected }
            />
        </section>
    )
}

Playlists.propTypes = {
    types: PropTypes.arrayOf((value, key, componentName, propName) => {
        const values = ["simple"]
        if (values.indexOf(value[key]) === -1)
            return new Error(`Проп ${propName} компонента ${componentName} имеет неправильное значение`)
    })
}

export default Playlists
import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS } from 'react-bootstrap'

import { update, createPlaylist } from '../../../store/Modal/types/PlaylistCreate/actions'
import { loadVideolists } from '../../../store/ContentVideolists/actions'

import Header from '../components/header'

import InputText from '../../Form/InputText'
import Select from '../../Form/Select'


class PlaylistCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: false,
            description: false,
            videolist: false,
            sumbitDisabled: true,
        }
        this.hSubmit = this.hSubmit.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.validateForm = this.validateFrom.bind(this)
    }

    hInputText({ name, value }, event) {
        if (name && value) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
        console.log(this.state)
    }

    hSubmit() {
        this.props.createPlaylist({
            name: this.state.name,
            description: this.state.description || "",
            videolist: this.state.videolist,
            //owner_id: 3581
        })
    }

    validateFrom() {
        if (this.state.name
            && (typeof this.state.name === "string" && this.state.name.length > 1)
            // && (typeof this.state.description === "string" && this.state.description.length > 3)
        ) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
    }

    componentDidMount() {
        this.props.update()
        this.props.loadVideolists()
    }

    render() {
        let videolists2 = [{ title: 'Не привязывать', value: -1, id: -1 }, ...this.props.videolists.map((list, index) => {
            return { title: list.name, value: list.id, id: list.id }
        })]
        return (
            <div className="ModalOneInput">
                <Header
                    title="Создание музыкального плейлиста"
                    toggleOpened={ this.props.toggleOpened }
                />
                <InputText
                    label="Название"

                    attrs={ {
                        name: "name",
                        placeholder: "Введите название плейлиста",
                    } }
                    onChange={ this.hInputText }
                />
                <div style={ { marginTop: 30 } }>
                    <InputText
                        label="Описание"
                        attrs={ {
                            name: "description",
                            placeholder: "Введите описание"
                        } }
                        onChange={ this.hInputText }
                    />
                </div>
                <div style={ { marginTop: 30 } }>
                    <Select
                        label="Привязать фоновое видео"
                        type="options"
                        placeholder={ "Не привязывать" }
                        name="videolist"
                        options={ videolists2 }
                        onChange={ this.hInputText }
                    />
                </div>

                <div className="Modal__footer">
                    <ButtonBS onClick={ this.hSubmit } variant="primary" disabled={ this.state.sumbitDisabled || this.props.loading }>
                        { this.props.loading ? "Сохранение..." : "Добавить" }
                    </ButtonBS>
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
    loading: state.modalPlaylistCreate.loading,
    videolists: state.videolists.allVideolists
})

const actionsToProps = {
    update, createPlaylist, loadVideolists
}



export default connect(stateToProps, actionsToProps)(PlaylistCreate)
import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../../utilities/generateStateKey'
import {cl} from '../../../../../utilities/cl'

import {OBJECT_SCHEDULE} from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'
import ScrollContainer from '../../../../../components/ScrollContainer'


const SettingsScheduleCollapsible = ({
    className = "",
    selectedObjects = [],
    selectedGroups = [],
    placeNumber,
    active
}) => {

    const stateKey = selectedGroups.length ? generateStateKey([ selectedGroups ]) : generateStateKey([ selectedObjects ])

    return(
        <Collapsible
            active={active}
            id={3111}
            className={className}
            title="Расписание"
            placeNumber={placeNumber}
        >
            <Settings
                set={OBJECT_SCHEDULE}
                data={{
                    stateKey,
                }}
            />
        </Collapsible>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
    selectedGroups: [...state.objectsGroup.selected],
})

export default connect(stateToProps, null)(SettingsScheduleCollapsible)
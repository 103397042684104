import { takeEvery, select, put, call, all } from 'redux-saga/effects'
import { OBJECT_VOLUME } from '../../../../store/Settings/sets'
import { saving } from '../../../../store/Settings/actions'
import { SAVE_ITEM_SETTINGS, loadItems } from '../../../../store/Settings/actions/sets/Object/Volume'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_ITEM_SETTINGS, worker)
}

function* worker({ payload }) {
    let { stateKey, settings } = payload

    try {
        const selectedObjects = yield select(state => state.objects.selected || [])
        const store = yield select(state => state.settings.settings[OBJECT_VOLUME])
        const selectedItems = store[stateKey].selected || []

        if (selectedItems.length) {
            yield put(saving({
                type: OBJECT_VOLUME,
                stateKey,
                saving: true,
            }))

            const fetchedSave = yield all(
                selectedItems.map(itemId => {
                    if (itemId) {
                        const itemStateKey = generateStateKey([stateKey, itemId])

                        const itemSettings =
                            (settings)
                                ? settings
                                : store[itemStateKey] || {}

                        return call(fetchSave, itemId, selectedObjects, itemSettings)
                    }
                })
            )

            let error = false
            fetchedSave.map(item => {
                if (item.error) error = true
            })

            // Ошибка
            if (error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${SAVE_ITEM_SETTINGS}.`, fetchedSave],
                    text: "Не удалось сохранить изменения. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(showAlertSuccess({
                    responses: [`Успешное выполнение ${SAVE_ITEM_SETTINGS}.`, fetchedSave],
                    text: "Изменения успешно сохранены."
                }))
                yield put(loadItems({
                    stateKey,
                    required: true,
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_ITEM_SETTINGS}.`, e],
            text: "Не удалось сохранить изменения. Попробуйте повторить."
        }))
    }

    finally {
        yield put(saving({
            type: OBJECT_VOLUME,
            stateKey,
            saving: false,
        }))
    }
}

function fetchSave(id, ms_id, settings) {
    ms_id = ms_id.join(",")
    let formData = new FormData()
    for (let name in settings) {
        formData.set(name, settings[name])
    }

    return axios(
        {
            url: "/msbox/volume/update",
            method: "POST",
            data: formData,
            params: {
                id, ms_id
            }
        }
    )
        .then(response => response.data)
}
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_BACKGROUND } from '../../../../store/Settings/sets'
import { updateOptions } from '../../../../store/Settings/actions'
import { SELECT_USED_VIDEO } from '../../../../store/Settings/actions/sets/Object/Background'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SELECT_USED_VIDEO, worker)
}

function* worker({ payload }) {
    let { id, itemStateKey, videolistId } = payload

    try {
        const currentVideolistId = yield select(state => state.settings.settings[OBJECT_BACKGROUND][itemStateKey].videolist_id)

        if (typeof id === "number" && videolistId !== currentVideolistId && videolistId !== 0) {
            let fetched = { success: true },
                withoutFetch = (videolistId === "") ? true : false

            if (!withoutFetch) {
                fetched = yield call(fetchSelectUsedVideo, id, videolistId)
            }

            // Ошибка
            if (fetched.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${SELECT_USED_VIDEO}.`, fetched],
                    text: "Ошибка при сохранении видеолиста. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(updateOptions({
                    type: OBJECT_BACKGROUND,
                    stateKey: itemStateKey,
                    settings: {
                        videolist_id: videolistId,
                    }
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SELECT_USED_VIDEO}.`, e],
            text: "Ошибка при сохранении видеолиста. Попробуйте повторить."
        }))
    }
}

function fetchSelectUsedVideo(id, videolist_id) {
    videolist_id = (+videolist_id === -1) ? "NULL" : videolist_id

    let formData = new FormData()
    formData.set("videolist_id", videolist_id)
    //formData.set("id", id)
    return axios(
        {
            url: "/msbox/play/update",
            method: "POST",
            data: formData,
            params: {
                id
            }
        }
    )
        .then(response => response.data)
}
import React from 'react'
import { connect } from 'react-redux'

import { SETTINGS_TEMPLATES, SETTINGS_ROLES, SETTINGS_NOTIFICATIONS } from '../../routes'

import Panel from '../../../components/PanelContent'
import Place from '../../../components/PanelContent/Place'


const SettingsPanel = ({
    page,
    searchState,
}) => {
    const disabled = isDisabled(page)

    return (
        <Panel page={ page } disabled={ disabled }>

            {/* 1 */ }

            <Place
                title="Что"
                number={ 1 }
                className={ `${(searchState.modeActive) ? "right-extend" : ""}` }
                disabled={ disabled }

            />

            {/* 2 */ }

            <Place
                title="Где"
                number={ 2 }
                disabled={ disabled }
            />

            {/* 3 */ }

            {/* <Place
                title="Когда"
                number={ 3 }
                // className="not-expanded"
                disabled={ true }
            /> */}

        </Panel>
    )
}

const stateToProps = state => ({
    searchState: state.search["objects"],
})

export default connect(stateToProps, null)(SettingsPanel)

//
// Функции
//

function isDisabled(page) {
    return (page !== SETTINGS_TEMPLATES && page !== SETTINGS_NOTIFICATIONS && page !== SETTINGS_ROLES) ? true : false
}
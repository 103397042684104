import React from 'react'

import Group from './components/Group'
import Warning from './components/Warning'
import Header from './components/Header'


// const sampleGroups = {
//     all: [
//         <Element/>,
//         {
//             data: {},
//             element: <Element/>,
//         },
//         {
//             data: {},
//             items: [
//                 <Element/>,
//                 {
//                     title: "",
//                     disabled: false,
//                     components: [
//                         {
//                             type: "Select",
//                             data: {}
//                         }
//                     ]
//                 }
//             ],
//         },
//     ]
// }


const Builder = ({
    className = "",
    warning,
    header,
    groups = {
        all: [],
        left: [],
        right: [],
    },
}) => {

    return (
        <section className={`Settings ${className}`}>

            {warning && <Warning message={warning} />}

            {header && <Header {...header} key={header.lineTitle} />}

            <div className="Settings__groups" key={1}>
                {groups.all
                    ? <div className="expanded-all">
                        {groups.all.map((group, index) => !!!group ? null :
                            (React.isValidElement(group))
                                ? group
                                : <Group data={group.data} items={group.items} element={group.element} key={index} />
                        )}
                    </div>

                    : (groups.left || groups.right)
                    && <React.Fragment>
                        {(groups.left)
                            && <div className="expanded-left">
                                {groups.left.map((group, index) =>
                                    (React.isValidElement(group))
                                        ? group
                                        : <Group data={group.data} items={group.items} element={group.element} key={'l' + index} />
                                )}
                            </div>
                        }
                        {(groups.right)
                            && <div className="expanded-right">
                                {groups.right.map((group, index) =>
                                    (React.isValidElement(group))
                                        ? group
                                        : <Group data={group.data} items={group.items} element={group.element} key={'r' + index} />
                                )}
                            </div>
                        }
                    </React.Fragment>
                }
            </div>

        </section>
    )
}

export default Builder
import { takeEvery, call, put } from 'redux-saga/effects'
import { loadSubusers, UPDATE_SUBUSER, loading } from '../../store/Roles/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'


export default function* () {
    yield takeEvery(UPDATE_SUBUSER, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        const subusers = yield call(fetchUpdateSubusers, { ...payload })
        if (subusers.error && subusers.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_SUBUSER}.`, subusers],
                text: "Пользователи не были обновлены. Попробуйте перезагрузить страницу."
            }))
            yield put(loadSubusers())
        } else {
            // yield put(loadSubusers())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_SUBUSER}.`, e],
            text: "Пользователи не были обновлены. Попробуйте перезагрузить страницу."
        }))
        yield put(loadSubusers())
    }
    finally {
        yield put(loadSubusers())
        // yield put(closeModal("templateCreate"))
    }
}

function fetchUpdateSubusers(data) {
    let formData = new FormData()
    for (let name in data) {
        formData.set(name, data[name])
    }
    return axios({
        url: "/account/subowner/update",
        method: "POST",
        data: formData
    })
        .then(response => response.data)
}

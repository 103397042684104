import { OBJECT_BACKGROUND } from '../../../sets'
export const LOAD_ITEMS = `Settings/${OBJECT_BACKGROUND}/LOAD_ITEMS`
export const PUT_ITEMS = `Settings/${OBJECT_BACKGROUND}/PUT_ITEMS`
export const ADD_ITEM = `Settings/${OBJECT_BACKGROUND}/ADD_ITEM`
export const PUT_OBJECT_TO_USES_OWN = `Settings/${OBJECT_BACKGROUND}/PUT_OBJECT_TO_USES_OWN`
export const REMOVE_OBJECT_FROM_USES_OWN = `Settings/${OBJECT_BACKGROUND}/REMOVE_OBJECT_FROM_USES_OWN`
export const SAVE_ITEMS = `Settings/${OBJECT_BACKGROUND}/SAVE_ITEMS`
export const DELETE_ITEMS = `Settings/${OBJECT_BACKGROUND}/DELETE_ITEMS`
export const SELECT_USED_VIDEO = `Settings/${OBJECT_BACKGROUND}/SELECT_USED_VIDEO`
export const SELECT_PERIOD = `Settings/${OBJECT_BACKGROUND}/SELECT_PERIOD`
export const CLEAN_SETTINGS = `Settings/${OBJECT_BACKGROUND}/CLEAN_SETTINGS`

export const loadItems = ({ stateKey, required, afterAdded }) => ({
    type: LOAD_ITEMS,
    payload: {
        stateKey,
        required,
        afterAdded
    }
})

export const putItems = ({ stateKey, assocItems, periods }) => ({
    type: PUT_ITEMS,
    payload: {
        stateKey,
        assocItems,
        periods,
    }
})

export const putObjectToUsesOwn = ({ objectId }) => ({
    type: PUT_OBJECT_TO_USES_OWN,
    payload: {
        objectId
    }
})

export const removeObjectFromUsesOwn = ({ objectId }) => ({
    type: REMOVE_OBJECT_FROM_USES_OWN,
    payload: {
        objectId
    }
})

export const addItem = ({ stateKey }) => ({
    type: ADD_ITEM,
    payload: {
        stateKey,
    }
})

export const saveItems = ({ stateKey, itemIds, settings }) => ({
    type: SAVE_ITEMS,
    payload: {
        stateKey,
        itemIds,
        settings,
    }
})

export const deleteItems = ({ stateKey, itemIds }) => ({
    type: DELETE_ITEMS,
    payload: {
        stateKey,
        itemIds,
    }
})

export const selectUsedVideo = ({ id, itemStateKey, videolistId }) => ({
    type: SELECT_USED_VIDEO,
    payload: {
        id,
        itemStateKey,
        videolistId,
    }
})

export const selectPeriod = ({ stateKey, periodId }) => ({
    type: SELECT_PERIOD,
    payload: {
        stateKey,
        periodId,
    }
})

export const cleanSettings = ({ stateKey }) => ({
    type: CLEAN_SETTINGS,
    payload: {
        stateKey
    }
})
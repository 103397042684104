import React from 'react'

export function createCalendar({ startDay, daysInMonth, year, month }) {
    let i = 1
    return(
        <>
            <div className="FormDatepickerSingle__row">
                <span className="date" data-date={`${year}-${month}-${i}`}>{startDay <= 1 && startDay !== 0 ? i++ : ""}</span>
                <span className="date" data-date={`${year}-${month}-${i}`}>{startDay <= 2 && startDay !== 0 ? i++ : ""}</span>
                <span className="date" data-date={`${year}-${month}-${i}`}>{startDay <= 3 && startDay !== 0 ? i++ : ""}</span>
                <span className="date" data-date={`${year}-${month}-${i}`}>{startDay <= 4 && startDay !== 0 ? i++ : ""}</span>
                <span className="date" data-date={`${year}-${month}-${i}`}>{startDay <= 5 && startDay !== 0 ? i++ : ""}</span>
                <span className="date" data-date={`${year}-${month}-${i}`}>{startDay <= 6 && startDay !== 0 ? i++ : ""}</span>
                <span className="date" data-date={`${year}-${month}-${i}`}>{i++}</span>
            </div>
            {i <= daysInMonth &&
                <div className="FormDatepickerSingle__row">
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                </div>
            }
            {i <= daysInMonth &&
                <div className="FormDatepickerSingle__row">
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                </div>
            }
            {i <= daysInMonth &&
                <div className="FormDatepickerSingle__row">
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                </div>
            }
            {i <= daysInMonth &&
                <div className="FormDatepickerSingle__row">
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                </div>
            }
            {i <= daysInMonth &&
                <div className="FormDatepickerSingle__row">
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                    <span className="date" data-date={`${year}-${month}-${i}`}>{i <= daysInMonth ? i++ : ""}</span>
                </div>
            }
        </>
    )
}
import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducer from './store/reducer'
import sagas from './sagas/root'

const saga = createSagaMiddleware()
let storeF = undefined;
if(process.env.NODE_ENV !== 'production' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') {
    storeF = createStore(
        reducer,
        compose(
            applyMiddleware(saga),
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    );
} else {
    storeF = createStore(
        reducer,
        compose(
            applyMiddleware(saga)
        )
    );
}

export const store = storeF

saga.run(sagas)
import React, {useState} from 'react'
import {Button as ButtonBS} from 'react-bootstrap'
// import {connect} from 'react-redux'

// import {openModal} from '../../../../../../store/Modal/actions'
import Builder from '../../../../Builder'

import fieldInside from '../../items/inside'
import fieldOuside from '../../items/outside'
import fieldStarttime from '../../items/starttime'
import fieldBack from '../../items/back'
import SettingsDays from '../../../../components/Days'


const EditContent = ({
    stateKey,
    itemId,
    settings = {},
    saving,

    saveItemSettings,
    closeModal,
}) => {

    const [inside, setInside] = useState(settings["inside"])
    const [outside, setOutside] = useState(settings["outside"])
    const [starttime, setStarttime] = useState()
    const [back, setBack] = useState(settings["back"])
    const [daymask, setDaymask] = useState(settings["daymask"])

    const hChangeInside    = ({ value }) => setInside(value)
    const hChangeOutside   = ({ value }) => setOutside(value)
    const hChangeStarttime = ({ value }) => setStarttime(value)
    const hChangeBack      = ({ value }) => setBack(value)
    const hChangeDaymask   = ({ value }) => setDaymask(value)

    const nextSettings = {
        inside,
        outside,
        back,
        daymask,
    }

    if(starttime !== "") nextSettings.starttime = starttime

    const hSaveSettings = () => {
        closeModal("element")
        saveItemSettings({
            stateKey,
            itemIds: [itemId],
            settings: nextSettings,
        })
    }

    return(
        <>
            <Builder
                groups={{
                    all: [
                        // Group
                        {
                            data: {
                                columns: 1,
                                noDivider: true,
                            },
                            items: [
                                {
                                    title: "Улица",
                                    components: fieldOuside({
                                        settings,
                                        name: "outside",
                                        onChange: hChangeOutside
                                    })
                                },
                                {
                                    title: "Помещение",
                                    components: fieldInside({
                                        settings,
                                        name: "inside",
                                        onChange: hChangeInside
                                    })
                                },
                            ]
                        },

                        // Group
                        {
                            data: {
                                columns: 1,
                                noDivider: true,
                            },
                            items: [
                                {
                                    title: "Музыка",
                                    components: fieldBack({
                                        settings,
                                        name: "back",
                                        onChange: hChangeBack
                                    })
                                },
                                {
                                    title: "Время начала",
                                    components: fieldStarttime({
                                        name: "starttime",
                                        onChange: hChangeStarttime
                                    })
                                },
                            ]
                        },

                        // Group
                        {
                            data: {
                                noDivider: true,
                            },
                            items: [
                                <SettingsDays
                                    value={settings["daymask"] || 127}
                                    name="daymask"
                                    onChange={hChangeDaymask}
                                />
                            ]
                        },
                    ],
                }}
            />

            <div className="Modal__footer">
                <ButtonBS onClick={hSaveSettings} variant="primary" disabled={saving}>
                    {saving ? "Сохранение..." : "Сохранить"}
                </ButtonBS>
            </div>
        </>
    )
}

const openModalEdit = ({
    stateKey,
    itemId,
    items = [],
    saving,
    saveItemSettings,

    openModal,
    closeModal,
}) => {

    let settings = {}
    items.map(item => { if(item.id === itemId) settings = item })

    openModal({
        type: "element",
        size: "sm",
        data: {
            title: "Редактирование расписания",
            element:
                <EditContent
                    stateKey={stateKey}
                    itemId={itemId}
                    settings={settings}
                    saving={saving}
                    saveItemSettings={saveItemSettings}
                    closeModal={closeModal}
                />
        }
    })
}

export default openModalEdit
// export default connect(null, actionsToProps)(openModalEdit)
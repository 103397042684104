export const LOAD_HELP = "Help/LOAD_HELP"
export const LOADING = "Help/LOADING"
export const SENDING_CALL = "Help/SENDING_CALL"
export const SENDING_QUESTION = "Help/SENDING_QUESTION"
export const LOADING_CONTENT = "Help/LOADING_CONTENT"
export const PUT_HELP = "Help/PUT_HELP"
export const LOAD_HELP_CONTENT = "Help/LOAD_HELP_CONTENT"
export const PUT_HELP_CONTENT = "Help/PUT_HELP_CONTENT"
export const TOGGLE_SELECTED_HELP_MENU = "Help/TOGGLE_SELECTED_HELP_MENU"
export const NEED_HELP = "Help/NEED_HELP"
export const PUT_SENDED = "Help/PUT_SENDED"


export const loadHelp = () => ({
    type: LOAD_HELP,
})

export const needHelp = user => ({
    type: NEED_HELP,
    payload: user
})

export const loadHelpContent = page => ({
    type: LOAD_HELP_CONTENT,
    payload: page
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const sending_call = mode => ({
    type: SENDING_CALL,
    payload: mode
})

export const sending_question = mode => ({
    type: SENDING_QUESTION,
    payload: mode
})

export const loadingContent = mode => ({
    type: LOADING_CONTENT,
    payload: mode
})

export const putHelp = help => ({
    type: PUT_HELP,
    payload: help
})

export const putSended = type => ({
    type: PUT_SENDED,
    payload: type
})

export const putHelpContent = help_content => ({
    type: PUT_HELP_CONTENT,
    payload: help_content
})
export const toggleSelectedHelpMenu = ({ id }) => ({
    type: TOGGLE_SELECTED_HELP_MENU,
    payload: {
        id
    }
})



import React from 'react'
import {Form} from 'react-bootstrap'

import {isFunc} from '../../../../utilities/isFunc'
import {random} from '../../../../utilities/random'
import {cl} from '../../../../utilities/cl'
import {parseBitmap, maps} from '../../../../utilities/bitmap'

import Item from '../Group/Item'


class Days extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            days: parseBitmap(props.value, maps.daymask) || [],
            rand: random()
        }

        this.form = React.createRef()
        this.chooseDay = this.chooseDay.bind(this)
        this.highlightDays = this.highlightDays.bind(this)
    }

    componentDidMount() {
        this.highlightDays(this.props.value || 0)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.highlightDays(this.props.value || 0)
        }
    }

    chooseDay() {
        let name = this.props.name || "settings-days";
        //console.log(Array.from(this.form.current[name]));
        const daymask = Array.from(this.form.current[name]).reduce((acc, check) => {
            if (check.checked) acc += Number(check.value)
            return acc
        }, 0);

        // Array.prototype.map.call(this.form.current[name], check => {
        //     if(check.checked) daymask += Number(check.value)
        // })
        // this.setState({days: parseBitmap(daymask, maps.daymask)})
        if (isFunc(this.props.onChange)) {
            this.props.onChange({
                name,
                value: daymask
            })
        }
    }

    render() {
        let {
            className = "",
            name = "settings-days",
            disabled
        } = this.props

        // this.highlightDays(this.props.value || 0)

        className = cl(
            className,
            "SettingsDays"
        )

        const checkId = `${name}-${this.state.rand}`

        return (
            <Item title="Дни трансляции">
                <div className={className}>
                    <form ref={this.form} onClick={this.chooseDay}>
                        <Form.Check inline type="checkbox" label="Пн" name={name} value={64} id={`${checkId}-1`}
                                    disabled={disabled}/>
                        <Form.Check inline type="checkbox" label="Вт" name={name} value={32} id={`${checkId}-2`}
                                    disabled={disabled}/>
                        <Form.Check inline type="checkbox" label="Ср" name={name} value={16} id={`${checkId}-3`}
                                    disabled={disabled}/>
                        <Form.Check inline type="checkbox" label="Чт" name={name} value={8} id={`${checkId}-4`}
                                    disabled={disabled}/>
                        <Form.Check inline type="checkbox" label="Пт" name={name} value={4} id={`${checkId}-5`}
                                    disabled={disabled}/>
                        <Form.Check inline type="checkbox" label="Сб" name={name} value={2} id={`${checkId}-6`}
                                    disabled={disabled}/>
                        <Form.Check inline type="checkbox" label="Вс" name={name} value={1} id={`${checkId}-7`}
                                    disabled={disabled}/>
                        <div className="form-check-margin-normalize"></div>
                    </form>
                </div>
            </Item>
        )
    }

    highlightDays(daymask) {
        const days = parseBitmap(daymask, maps.daymask)
        const form = this.form.current
        const name = this.props.name || "settings-days"
// if (!form || typeof form[name] === 'undefined') {
//     return;
// }
        this.setState({days})
        Array.prototype.map.call(form[name], check => {
            check.checked = (days.indexOf(Number(check.value)) !== -1)
        })
    }
}

export default Days
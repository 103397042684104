import { takeEvery, put, select } from 'redux-saga/effects'
import { DOWNLOAD_TRACKS, loadTracks } from '../../store/Tracks/actions'
import { showAlertError } from '../../store/Alert/actions'
import { download } from '../../utilities/download'
import axios from 'axios'

export default function* () {
    yield takeEvery(DOWNLOAD_TRACKS, worker)
}

function* worker({ payload }) {
    const { tracksIds, type } = payload
    console.log({payload});
    let tracksLinks
    let tracks
    try {
        if (Array.isArray(tracksIds)) {
            if (type && type === "report") {
                tracks = yield select(state => state.advert_report.advert_report)
                tracksLinks = getTracksLinks(tracks, tracksIds, type)

            } else if (type && type === 'extra') {
                tracks = yield select(state => state.tracks.tracksExtra)
                console.log({tracks, tracksIds});
                tracksLinks = getTracksLinks(tracks, tracksIds, type)
            } else {
                tracks = yield select(state => state.tracks.tracks)
                tracksLinks = getTracksLinks(tracks, tracksIds, type)
            }

            download(tracksLinks)
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка при ${DOWNLOAD_TRACKS}.`, e],
            text: "Ролики не были скачаны. Попробуйте повторить."
        }))
    }
}

function getTracksLinks(tracks, tracksIds, type) {
    let tracksLinks = []
    if (type && type === "report") {
        tracks.map(track => {
            if (tracksIds.indexOf(track.clip_id) !== -1) {
                tracksLinks = []
                tracksLinks.push(`https://t.ms-box.ru/download.php?id=${track.clip_id}`)
            }
        })
    } else if (type && type === "extra") {
        tracks.map(track => {
            if (tracksIds.indexOf(track.id) !== -1) {
                tracksLinks.push(`https://a.ms-box.ru/clip/extra/download.php?id=${track.id}&token=${window.localStorage.getItem("sessionToken")}`)
            }
        })
    } else {
        tracks.map(track => {
            if (tracksIds.indexOf(track.id) !== -1) {
                tracksLinks.push(`https://a.ms-box.ru/clip/download.php?id=${track.id}&token=${window.localStorage.getItem("sessionToken")}`)
            }
        })
    }

    return tracksLinks
}
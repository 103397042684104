import React from 'react'

import {cl} from '../../../../../../utilities/cl'
import {isFunc} from '../../../../../../utilities/isFunc'
import {getNextSelected} from '../../../../../../utilities/getNextSelected'
import {mediaTabletAndUp} from '../../../../../../utilities/media'

import modalEdit from './modalEdit'

import StringDays from '../../../../../StringDays'
import Group from '../../../../components/Group/Group'

import List from '../../../../../List'
import Button from '../../../../../Button'
import SvgPlusCircle from '../../../../../SVG/PlusCircle'
import SvgUpdate from '../../../../../SVG/Update'
import SvgTrash from '../../../../../SVG/Trash'


const GroupVolumeList = ({
    className = "",
    title,
    type,
    stateKey,
    settings,
    items = [],
    selected = [],
    addItem,
    saveItemSettings,
    deleteItems,
    toggleSelectedItems,
    validMap = {},
    loading,
    saving,
    adding,
    deleting,
    openModal,
    closeModal,
}) => {

    className = cl(
        className,
        "ObjectVolumeGroupVolumeList"
    )

    const hClick = ({ id, items }, event) => {
        if(isFunc(toggleSelectedItems)) {
            selected = getNextSelected({
                multiple: true,
                def: 0,
                itemId: id,
                selected,
                ctrlKey: event.ctrlKey || event.metaKey,
                shiftKey: event.shiftKey,
                items,
            })
        
            toggleSelectedItems({
                type,
                stateKey,
                itemIds: selected,
            })
        }
    }

    const hModalEdit = ({ id }) => modalEdit({
        stateKey,
        itemId: id,
        items,
        saving,
        openModal,
        closeModal,
        saveItemSettings,
    })

    const hDeleteItem = ({ id }) => {
        openModal({
            type: "confirm",
            size: "sm",
            data: {
                title: `Вы точно хотите удалить данное расписание?`,
                buttons: {
                    cancel: {
                        title: "Вернуться",
                        onClick: () => {
                            closeModal("confirm")
                        }
                    },
                    confirm: {
                        title: "Удалить",
                        onClick: () => {
                            deleteItems({ stateKey, itemIds: [ id ] })
                            closeModal("confirm")
                        }
                    }
                }
            }
        })
    }
    
    return(
        <Group title={title} className={className}>
            <div>
                <List
                    type="simple"
                    header={mediaTabletAndUp({
                        size: "sm",
                        right: <>
                            <Button
                                className="setssimp-visible"
                                type="string"
                                onClick={() => addItem({ stateKey })}
                                disabled={!(validMap.validResult && selected[0] === 0) || adding}
                            >
                                <SvgPlusCircle size="sm1" variant="dark"/>
                            </Button>
                            <Button
                                type="string"
                                onClick={() => saveItemSettings({ stateKey, itemIds: selected, settings })}
                                disabled={!(validMap.validResult && selected.length && selected[0] !== 0) || saving}
                            >
                                <SvgUpdate size="sm1" variant="dark"/>
                            </Button>
                            <Button
                                type="string"
                                onClick={() => deleteItems({ stateKey })}
                                disabled={!(selected.length && selected[0] !== 0) || deleting}
                            >
                                <SvgTrash size="sm1" variant="dark"/>
                            </Button>
                        </>,
                    })}
                    content={{
                        items: getList()
                    }}
                    sort={{
                        by: "starttime",
                        //type: "number",
                        order: "DESC",
                    }}
                    selected={selected}
                    loading={loading}
                    onClick={hClick}
                    buttons={{
                        edit: {
                            on: true,
                            mobile: true,
                            onClick: hModalEdit,
                        },
                        remove: {
                            on: true,
                            mobile: true,
                            onClick: hDeleteItem,
                        },
                    }}
                />
            </div>
        </Group>
    )

    function getList() {
        let list = []
        items.map(item =>
            list.push({
                id: item.id,
                volumesum: item.outside+item.inside+item.back,
                element:
                    <div className="ObjectVolumeGroupVolumeList__item">
                        <div className="time">
                            {formatTimeToRender(item.starttime)}
                        </div>
                        <div className="info">
                            <div className="wrap">
                                <div className="info-item">Улица: <span>{item.outside}</span></div>
                                <div className="info-item">Помещение: <span>{item.inside}</span></div>
                                <div className="info-item">Музыка: <span>{item.back}</span></div>
                            </div>
                        </div>
                        <StringDays
                            daymask={item.daymask}
                        />
                    </div>
            })
        )
        return list
    }
}

export default GroupVolumeList

//
// funcs
//

function formatTimeToRender(time = "") {
    return time.slice(0, 5)
}
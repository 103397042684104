import React, { useEffect } from 'react'
import List from '../../../../../components/List'
import { connect } from 'react-redux'
import { loadHelp, toggleSelectedHelpMenu } from '../../../../../store/Help/actions'
import { updateCurrentMark } from '../../../../../store/Marks/actions'
import HelpMenuItem from './item'
import Spinner from '../../../../../components/Spinner'

const HelpMenu = ({
    loadHelp,
    menu,
    loading,
    selected,
    toggleSelectedHelpMenu,
    updateCurrentMark,
    markItems,
    className
}) => {
    useEffect(() => {
        loadHelp()

    }, [])
    let getItems = () => {
        return menu.map((item, index) => ({
            id: index,
            name: index,
            element: <HelpMenuItem
                title={ item.title }
                selected={ selected }
                markItems={ markItems }
                index={ index } />
        })
        )
    }
    useEffect(() => {
        if (menu.length > 0 && selected.length === 0) {
            menu.map((i, index) => {
                if (i.url === "index") {
                    toggleSelectedHelpMenu({
                        id: index
                    })
                }
            })
        }

    }, [menu])
    const hToggleSelected = ({ }, e) => {
        const helpItem = e.target.closest(".HelpMenuItem")
        if (helpItem) {
            const helpItemId = Number(helpItem.dataset.helpId)
            toggleSelectedHelpMenu({
                id: helpItemId
            })
            updateCurrentMark()
        }
    }

    return (
        <div className={ `HelpMenu ${className}` } >
            <h2 style={ { marginTop: "-62px" } }>ЧАСТЫЕ ВОПРОСЫ</h2>
            { !loading ?
                <List
                    onClick={ hToggleSelected }
                    className='HelpMenuList'
                    type="destructed"
                    scroll={ {
                        on: false,
                        data: {
                            variant: "none",
                            size: "lg",
                        }
                    } }
                    // variant={ "none" }
                    content={ { items: getItems(), variant: "none" } }
                />
                : <Spinner />
            }
        </div >
    )
}

const stateToProps = state => ({
    menu: state.help.help,
    loading: state.help.loading,
    markItems: [...state.marks[state.marks.current].items],
    selected: state.help.selected,
})

const actionsToProps = {
    loadHelp,
    toggleSelectedHelpMenu,
    updateCurrentMark
}

export default connect(stateToProps, actionsToProps)(HelpMenu)
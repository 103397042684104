import moment from 'moment'

export function getDatesBetween(selected) {
    if(!selected[1] || selected[1] === null) return []

    let start = moment(selected[0]).valueOf(),
        end = moment(selected[1]).valueOf(),
        isRun = true,
        current,
        selectedMiddle = []

    if(start > end){
        start = moment(selected[1]).valueOf()
        end = moment(selected[0]).valueOf()
    }

    current = moment(start).add(1, "day").valueOf()

    while(isRun){
        if(current < end){
            selectedMiddle.push( moment(current).format("Y[-]M[-]D") )
        }
        current = moment(current).add(1, "day").valueOf()
        if(current >= end) isRun = false
    }

    return selectedMiddle
}
import React from 'react'
import { connect } from 'react-redux'
import { hideAlertSuccess } from '../../store/Alert/actions'

import outside from '../../utilities/outside'


const AlertSuccess = ({ text, warning, responses, show, ms, hideAlertSuccess }) => {
    if (show) {
        setTimeout(() => hideAlertSuccess(), ms)

        if (responses && responses[0]) responses.map(m => console.log(m))

        return (
            <>
                {outside(
                    <>
                        <div className="Alert AlertSuccess">
                            {text}
                            {warning && `\n\n${warning}`}
                        </div>
                    </>
                )}
            </>
        )
    } else {
        return <></>
    }
}

const stateToProps = state => ({
    show: state.alert.success.show,
    text: state.alert.success.text,
    responses: state.alert.success.responses,
    ms: state.alert.success.ms,
    warning: state.alert.success.warning,
})

const actionsToProps = {
    hideAlertSuccess
}

export default connect(stateToProps, actionsToProps)(AlertSuccess)
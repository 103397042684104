import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {cl} from '../../utilities/cl'
import {isFunc} from '../../utilities/isFunc'

import ScrollContainer from '../ScrollContainer'

import SvgArrowDownLight from '../SVG/ArrowDownLight'


const CollapsibleCard = ({
    className,
    selected,
    id,
    name,
    title,
    payload,
    onClick,
    onOpen,
    children
}) => {

    const [opened, setOpened] = useState(false)
    const [loaded, setLoaded] = useState(false)

    className = cl(
        className,
        "CollapsibleCard",
        "selectable-item",
        {"opened": opened},
        {"selected": selected},
    )

    const hClick = (event) => {
        if(isFunc(onClick)) {
            if(!event.target.closest(".__CollapsibleCard__buttons"))
                onClick({ id, name, title, selected, payload }, event)
        }
    }

    const hOpen = (event) => {
        setOpened(!opened)
        setLoaded(true)
        if(isFunc(onOpen)) {
            onOpen({ id, name, title, selected, payload }, event)
        }
    }

    return(
        <div className={className}>

            <header className="CollapsibleCard__header" onClick={hClick}>
                <div className="CollapsibleCard__title selectable-item__color">
                    {cutTitle(title)}
                </div>
                <div className="__CollapsibleCard__buttons">
                    <div className="CollapsibleCard__open" onClick={hOpen}>
                        <SvgArrowDownLight
                            variant="dark"
                            size="mr"
                            title={opened ? "Свернуть" : "Развернуть"}
                        />
                    </div>
                </div>
            </header>

            <div className="CollapsibleCard__content">
                {(loaded || opened)
                    && <ScrollContainer size="md">
                        {children}
                    </ScrollContainer>
                }
            </div>
        </div>
    )
}

CollapsibleCard.propTypes = {
    className: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    onOpen: PropTypes.func
}

export default CollapsibleCard

function cutTitle(title = "") {
    return title.length > 28 ? `${title.slice(0, 38)}…` : title
}
import React, {useEffect, useState, useRef} from 'react'

import {cl} from '../../../utilities/cl'
import {isFunc} from '../../../utilities/isFunc'
import {isMobile} from '../../../utilities/media'

import SvgArrowDownLight from '../../SVG/ArrowDownLight'

import InputText from '../InputText'
import Time from './Time'
import MobileModal from '../../MobileModal'



const CycleTime = ({
    className = "",
    label,
    comment,
    name,
    value = "",
    times = [
        {
            title: "Часы",
            from: 0, to: 23,
            step: 1,
        },
        {
            title: "Минуты",
            from: 0, to: 55,
            step: 5,
        },
    ],
    placeholder = "ЧЧ, ММ",
    divider = ":",
    format = "00:00:00",
    isTime = true,
    onChange,
    disabled,
}) => {

    if(typeof value === "number") value = `${value}`

    const refCycleTime              = useRef(null)
    const refCycleTimeContent       = useRef(null)
    const [val, setVal]             = useState(value)
    const [arrayVals, setArrayVals] = useState(splitStringToNumbers(value, divider))
    const [opened, setOpened]       = useState(false)
    
    useEffect(() => {
        setVal(value)
        setArrayVals(splitStringToNumbers(value, divider))
    }, [value])

    useEffect(() => {
        document.addEventListener("click", closeCycleTime)
        return () => document.removeEventListener("click", closeCycleTime)
    }, [])

    const hOpen  = () => (disabled === true) ? setOpened(false) : setOpened(true)
    const hClose = () => setOpened(false)

    const hApply = () => {
        let formatArray = format.split(divider),
            formated = []

        if(arrayVals.length !== formatArray) {
            formatArray.map((fv, index) => {
                formated[index] = arrayVals[index] || fv
            })
        } else {
            formated = arrayVals
        }

        const nextVal = buildStrValue(formated, divider, isTime)
        setVal(nextVal)
        hClose()
        if(isFunc(onChange)) {
            onChange({
                name,
                value: nextVal
            })
        }
    }

    const hKeyClose = (e) => {
        if(e.key === "Escape") hClose()
        if(e.key === "Enter") hApply()
    }

    const hChangeArrayVal = ({ val, index }) => {
        let nextArrayVals = [...arrayVals]
        nextArrayVals[index] = val
        setArrayVals(nextArrayVals)
    }

    className = cl(
        className,
        "FormCycleTime",
        {"opened": opened},
        {"disabled": disabled},
    )

    const contentClassName = cl(
        "FormCycleTime__content",
        {"opened": opened},
    )

    return(
        <div
            ref={refCycleTime}
            className={className}
            onKeyDown={hKeyClose}
        > 
            <InputText
                className="FormCycleTime__input"
                label={label}
                comment={comment}
                icon={<SvgArrowDownLight title="Развернуть список" size="mr2"/>}
                attrs={{
                    value: getVisibleValue(),
                    placeholder,
                    readonly: "true",
                }}
                onClick={hOpen}
                disabled={disabled}
            />

            <MobileModal opened={opened}>
                <div ref={refCycleTimeContent} className={contentClassName}>

                    <div className="FormCycleTime__times">
                        {times.map((time, index) => 
                            <Time
                                key={index}
                                index={index}
                                value={arrayVals[index]}
                                isTime={isTime}
                                onChange={hChangeArrayVal}

                                title={time.title}
                                from={time.from}
                                to={time.to}
                                step={time.step}
                            />
                        )}
                    </div>

                    <div className="FormCycleTime__buttons MobileModal__buttons">
                        <div className="cancel" onClick={hClose}>Отменить</div>
                        <div className="save" onClick={hApply}>Сохранить</div>
                    </div>

                </div>
            </MobileModal>

        </div>
    )

    function getVisibleValue() {
        let vals = val.split(divider)
        vals = vals.filter((v, i) => times[i] !== undefined)
        return vals.join(divider)
    }

    function closeCycleTime(e) {
        if(!isMobile()) {
            const input = refCycleTime?.current
            const content = refCycleTimeContent?.current

            if(opened && input && content) {
                if(!input.contains(e.target) && !content.contains(e.target)) {
                    setOpened(false)
                }
            }
        }
    }
}

export default CycleTime

//
// funcs
//

function splitStringToNumbers(str = "", divider) {
    const arr = str.split(divider)
    const numbs = arr.map(s => Number(s))
    return numbs
}

function buildStrValue(vals = [], divider, isTime) {
    vals = vals.map(val => {
        val = val.toString()
        if(val.length === 1 && isTime === true) val = `0${val}`
        return val
    })
    return vals.join(divider)
}
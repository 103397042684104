import React from 'react'
import {Button as ButtonBS, Form} from 'react-bootstrap'
import {Editor, EditorState, RichUtils} from 'draft-js'

import Select from '../../Form/Select'

import Header from '../components/header'

import SvgPlus from '../../SVG/Plus'

const styleMap = {
    'FONT_SIZE_16': { fontSize: '16px' },
    'FONT_SIZE_18': { fontSize: '18px' },
    'FONT_SIZE_20': { fontSize: '20px' },
    'FONT_SIZE_22': { fontSize: '22px' },
    'FONT_SIZE_24': { fontSize: '24px' },
    'FONT_SIZE_26': { fontSize: '26px' },
    'FONT_SIZE_28': { fontSize: '28px' },
    'FONT_SIZE_30': { fontSize: '30px' },
    'FONT_SIZE_32': { fontSize: '32px' },
    'FONT_SIZE_34': { fontSize: '34px' },
    'FONT_SIZE_36': { fontSize: '36px' },
    'FONT_SIZE_38': { fontSize: '38px' },
    'FONT_SIZE_40': { fontSize: '40px' },
    'FONT_SIZE_42': { fontSize: '42px' },
    'FONT_SIZE_44': { fontSize: '44px' },
    
    'SET_COLOR': {
        fontWeight: '400',
    },
    'FONT_FAMILY_Roboto': {
        fontFamily: "'Roboto', sans-serif",
    },
    'FONT_FAMILY_Roboto+Condensed': {
        fontFamily: "'Roboto Condensed', sans-serif",
    },
    'FONT_FAMILY_Roboto+Slab': {
        fontFamily: "'Roboto Slab', serif",
    },
    'FONT_FAMILY_PT+Sans': {
        fontFamily: "'PT Sans', sans-serif",
    },
    'FONT_FAMILY_Open+Sans+Condensed': {
        fontFamily: "'Open Sans Condensed', sans-serif",
    },
    'FONT_FAMILY_Playfair+Display': {
        fontFamily: "'Playfair Display', serif",
    },
    'SET_COLOR_#808080': {
        color: "#808080"
    },
    'SET_COLOR_#000000': {
        color: "#000000"
    },
    'SET_COLOR_#ff0000': {
        color: "#ff0000"
    },
    'SET_COLOR_#800000': {
        color: "#800000"
    },
    'SET_COLOR_#ffff00': {
        color: "#ffff00"
    },
    'SET_COLOR_#00ff00': {
        color: "#00ff00"
    },
    'SET_COLOR_#008000': {
        color: "#008000"
    },
    'SET_COLOR_#00ffff': {
        color: "#00ffff"
    },
    'SET_COLOR_#0000ff': {
        color: "#0000ff"
    },
    'SET_COLOR_#000080': {
        color: "#000080"
    },
    'SET_COLOR_#ff00ff': {
        color: "#ff00ff"
    },
}


class CreateLine extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selection: null,
            editorState: EditorState.createEmpty()
        }

        this.linkContainerRef = React.createRef()

        this.setFont = this.setFont.bind(this)
        this.setFontSize = this.setFontSize.bind(this)
        this.setFontColor = this.setFontColor.bind(this)
        this.onChange = this.onChange.bind(this)
        this.saveSelection = this.saveSelection.bind(this)
        this.getStateWidthSelection = this.getStateWidthSelection.bind(this)
    }

    setFont(state) {
        const font = state.selected.value
        const href = `https://fonts.googleapis.com/css2?family=${font}:wght@400`

        if( !document.querySelector(`link[href="${href}"]`) ){

            if(this.linkContainerRef.current){
                let link = document.createElement("link")
                link.rel = "stylesheet"
                link.href = href
                this.linkContainerRef.current.append(link)
            }
        }

        this.onChange(RichUtils.toggleInlineStyle(
            this.getStateWidthSelection(),
            "FONT_FAMILY_"+font
        ))
    }

    setFontSize(state) {
        this.onChange(RichUtils.toggleInlineStyle(
            this.getStateWidthSelection(),
            "FONT_SIZE_"+state.selected.value
        ))
    }

    setFontColor(state) {
        this.onChange(RichUtils.toggleInlineStyle(
            this.getStateWidthSelection(),
            "SET_COLOR_"+state.selected.value
        ))
    }

    onChange(editorState) {
        this.setState({editorState})
    }

    render() {
        const {toggleOpened} = this.props
        return(
            <div className="ModalCreateLine">
                <Header title="Создание бегущей строки" toggleOpened={toggleOpened}/>
    
                <Form.Group className="line-offset" controlId="formBasicEmail">
                    <Form.Label>Название</Form.Label>
                    <Form.Control type="text" placeholder="Название бегущей строки" />
                </Form.Group>
    
                <div className="ModalCreateLine__grid line-offset">
                    <Select
                        label="Шрифт"
                        onClick={this.setFont}
                        type="options"
                        className="font"
                        placeholder="Выберите шрифт"
                        options={[
                            {
                                title: "Roboto",
                                value: "Roboto",
                                selected: true
                            },
                            {
                                title: "Roboto Condensed",
                                value: "Roboto+Condensed"
                            },
                            {
                                title: "Roboto Slab",
                                value: "Roboto+Slab"
                            },
                            {
                                title: "PT Sans",
                                value: "PT+Sans"
                            },
                            {
                                title: "Open Sans Condensed",
                                value: "Open+Sans+Condensed"
                            },
                            {
                                title: "Playfair Display",
                                value: "Playfair+Display"
                            }
                        ]}
                    />
                    <Select
                        label="Размер"
                        type="options"
                        className="font-size"
                        placeholder="Размер шрифта"
                        onClick={this.setFontSize}
                        options={[
                            { title: "16px", value: "16", selected: true },
                            { title: "18px", value: "18" },
                            { title: "20px", value: "20" },
                            { title: "22px", value: "22" },
                            { title: "24px", value: "24" },
                            { title: "26px", value: "26" },
                            { title: "28px", value: "28" },
                            { title: "30px", value: "30" },
                            { title: "32px", value: "32" },
                            { title: "34px", value: "34" },
                            { title: "36px", value: "36" },
                            { title: "38px", value: "38" },
                            { title: "40px", value: "40" },
                            { title: "42px", value: "42" },
                            { title: "44px", value: "44" },
                        ]}
                    />
                    <Select
                        label="Цвет"
                        type="color"
                        onClick={this.setFontColor}
                        className="font-color"
                        placeholder="Выберите цвет"
                        colors={[
                            { title: "Серый", value: "#808080" },
                            { title: "Чёрный", value: "#000000" },
                            { title: "Красный", value: "#ff0000", selected: true },
                            { title: "Тёмно-бордовый", value: "#800000" },
                            { title: "Жёлтый", value: "#ffff00" },
                            { title: "Лайм", value: "#00ff00" },
                            { title: "Зелёный", value: "#008000" },
                            { title: "Морская волна", value: "#00ffff" },
                            { title: "Синий", value: "#0000ff" },
                            { title: "Тёмно-синий", value: "#000080" },
                            { title: "Фуксия", value: "#ff00ff" },
                        ]}
                    /> 
                </div>
                
                <div ref={this.linkContainerRef}></div>

                <div className="ModalCreateLine__Editor line-offset" onBlur={this.saveSelection}>
                    <label>Текст бегущей строки</label>
                    <Editor
                        customStyleMap={styleMap}
                        onChange={editorState => {this.onChange(editorState)}}
                        editorState={this.state.editorState}
                        placeHolder="Введите текст бегущей строки"
                    />
                </div>

                <Select
                    label="Готовые шаблоны"
                    type="options"
                    className="templates"
                    placeholder="Не выбрано"
                    options={[
                        {
                            title: "Один шаблон",
                        },
                        {
                            title: "Другой шаблон",
                        },
                        {
                            title: "Ещё какой-нибудь",
                        },
                    ]}
                />
    
                <div className="Modal__footer">
                    <div className="spp-btns">
                        <ButtonBS variant="primary">Добавить</ButtonBS>
                        <ButtonBS variant="light"><SvgPlus/>Добавить шаблон</ButtonBS>
                    </div>
                </div>
            </div>
        )
    }

    saveSelection() {
        this.setState({
            selection: {
                anchorOffset: this.state.editorState.getSelection().getAnchorOffset(),
                focusOffset: this.state.editorState.getSelection().getFocusOffset(),
            }
        })
    }

    getStateWidthSelection() {
        const selectionState = this.state.editorState.getSelection()
        const newSelection = selectionState.merge(this.state.selection)
        return EditorState.forceSelection(this.state.editorState, newSelection)
    }
}

export default CreateLine
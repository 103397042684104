import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'


class InputTextarea extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.attrs?.value || ""
        }

        this.refTextarea = React.createRef()
        this.hChange = this.hChange.bind(this)
        this.updateHeight = this.updateHeight.bind(this)
        this.setHeightCSS = this.setHeightCSS.bind(this)
        this.getHeight = this.getHeight.bind(this)
    }

    componentDidMount() {
        this.props.attrs.forcedCheck ?
            setTimeout(() => this.updateHeight(), 500)
            : this.updateHeight()
    }


    componentDidUpdate(prevProps) {
        if (typeof this.props.attrs === "object"
            && prevProps.attrs.value !== this.props.attrs.value) {
            this.setState({
                value: this.props.attrs.value
            }, () => {
                if (this.props.fillHeight)
                    this.updateHeight();
            });
        }
    }

    hChange(event) {
        const value = event.target.value
        this.updateHeight()

        this.setState(() => {
            const continueToSave =
                (typeof this.props.validate === "function")
                    ? this.props.validate(value)
                    : true

            if (continueToSave) {
                const eventData = {
                    name: this.props.attrs?.name,
                    value: value,
                }
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(eventData, event)
                }
                return { value }
            }
        })
    }



    render() {
        let {
            className = "",
            label,
            comment,
            attrs = {},
        } = this.props
        const {
            value,
        } = this.state

        return (
            <div className={`FormInputTextarea ${className}`}>
                <Form.Group controlId={attrs.id}>
                    {label
                        && <Form.Label>
                            {label}
                        </Form.Label>
                    }
                    <Form.Control
                        {...attrs}
                        ref={this.refTextarea}
                        as="textarea"
                        value={value}
                        onChange={this.hChange}
                    />
                    {comment
                        && <div className="FormInputTextarea__comment">
                            {comment}
                        </div>
                    }
                </Form.Group>
            </div>
        )
    }

    updateHeight() {
        // const currentValue = this.refTextarea.current.value

        // if (currentValue.length < this.state.value.length)
        this.setHeightCSS("0")

        this.setHeightCSS(`${this.getHeight()}px`)
    }

    setHeightCSS(heightCSS) {
        this.refTextarea.current.style = `height:${heightCSS} !important`
    }

    getHeight() {
        const textarea = this.refTextarea.current
        const scrollHeight = Math.max(
            textarea.scrollHeight,
            textarea.offsetHeight,
            textarea.clientHeight
        )
        console.log("height:", textarea.scrollHeight,
            textarea.offsetHeight,
            textarea.clientHeight)
        return scrollHeight
    }
}

InputTextarea.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    comment: PropTypes.string,
    attrs: PropTypes.object,
    onChange: PropTypes.func,
    validate: PropTypes.func
}

export default InputTextarea
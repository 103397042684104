import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../utilities/cl'
import SvgPlay from '../../../components/SVG/Play'
import SvgPause from '../../../components/SVG/Pause'
import { formatSecondsToTime } from '../../../utilities/format'
import { toggleSelectedSong, toggleSelectedPlaylist } from '../../../store/ContentPlaylists/actions'
import { updateCurrentMark } from '../../../store/Marks/actions'
import { MEDIA_BREAKPOINT_LG } from '../../../utilities/breakpoints'

const Song = ({
    className = "",
    selectedPlaylists,
    currentPlaylist,
    placeNumber,
    id,
    id_playlist,
    active,
    name,
    turn,
    cliptime,
    tempo,
    songauthor_name,
    player_name,
    album_name,
    genre_name,
    year,
    variant,
    selected,
    markItems,
    audio,
    trackPlaying,
    isPlaying,
    play,
    pause,
    stop,
    rewind,
    filename,
    isWatching,
    toggleSelectedSong,
    updateCurrentMark,
    toggleSelectedPlaylist,
    selected_playlist
}) => {

    let [watcherInterval, setWatcherInterval] = useState(null)
    const [currentTime, setCurrentTime] = useState("00:00")
    const [slider, setSlider] = useState("100%")

    const prevPlaying = usePrevious(trackPlaying)
    const prevWatching = usePrevious(isWatching)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {
        if (trackPlaying !== prevPlaying || isWatching !== prevWatching) {
            if (isWatching) {
                setWatcherInterval(clearInterval(watcherInterval))
                startWatch()
            } else {
                killWatcher()
            }

        }
    }, [trackPlaying])



    const toggleSelectedS = (e) => {
        const songTitle = e.target
        if (songTitle) {
            const songId = Number(songTitle.dataset.songId || songTitle.parentNode.dataset.songId || songTitle.parentNode.parentNode.dataset.songId)
            if (!isNaN(songId)) {
                if (selected_playlist.length === 0 || id_playlist !== selected_playlist[0]) {
                    toggleSelectedPlaylist({
                        selected: [id_playlist],
                        simple: true
                    })
                }

                toggleSelectedSong({
                    id_song: songId,
                    onlyOne: !((e.ctrlKey || e.metaKey || e.shiftKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                })
                updateCurrentMark()
            }
        }
    }

    function startWatch() {

        if (trackPlaying === id) {
            setWatcherInterval(setInterval(() => {
                if (audio.currentTime === audio.duration) {
                    stop()
                } else {
                    updateTime()
                }
            }, 50))
        } else {
            killWatcher(true)
        }
    }

    function killWatcher(resetTime) {
        clearInterval(watcherInterval)
        updateTime(resetTime)
    }


    const updateTime = (resetTime) => {
        let currentTime = "00:00"
        let slider = "100%"

        if (resetTime !== true && (audio.currentTime !== audio.duration && trackPlaying === id)) {
            // Время
            currentTime = formatSecondsToTime(audio.currentTime)

            // Слайдер

            const sliderPercent = (audio.currentTime / (audio.duration / 100))
            slider = `${100 - sliderPercent}%`
        }
        setSlider(slider)
        setCurrentTime(currentTime)

    }

    function plays() {
        play({
            trackId: id,
            filename: filename
        })
    }
    function pauses() {
        pause()
    }
    function rewinds(e) {
        const rect = e.target.getBoundingClientRect(),
            clickPercent = 100 * (e.nativeEvent.offsetX / rect.width),
            totime = audio.duration * (clickPercent * 0.01)
        rewind(totime)
    }

    className = cl(
        className,
        // { "Playlist": [variant] },
        "selectable-item",
        { "Song": [variant] },
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
        { "playing": (isPlaying && trackPlaying === id) },
        { "name-fade": slider === "100%" },
    )

    return (
        <div className={ className } data-song-id={ id } onClick={ (e) => toggleSelectedS(e) }>
            <header className="Song__header">
                <span className="rewind" onClick={ rewinds }></span>
                <span className={ cl("slider-1", { "d-none": slider === 0 }) } style={ { right: slider } }></span>
                <span className={ cl("slider-2", { "d-none": slider === 0 }) } style={ { right: slider } }></span>
                <div className="Songs__grid">
                    <div className="play">
                        <span onClick={ plays }>
                            <SvgPlay className="icon-play" variant="prim" size="sm" title="Воспроизвести" />
                        </span>
                        <span onClick={ pauses }>
                            <SvgPause className="icon-pause" variant="prim" size="sm" title="Приостановить" />
                        </span>
                    </div>
                    <div className="composition" data-song-id={ id }>
                        { name }
                    </div>
                    <div className="duration" data-song-id={ id }>
                        { cliptime.split(":").splice(1).join(":") }
                    </div>
                    <div className="temp" data-song-id={ id }>
                        { tempo }
                    </div>
                    <div className="added" data-song-id={ id }>
                        { year }
                    </div>
                </div>
            </header>
        </div>
    )
}

const stateToProps = state => ({
    selected: [...state.playlists.selected_song],
    currentPlaylist: state.playlists.allPlaylists.filter(cur => cur.id === state.playlists.selected[0])[0],
    selected_playlist: state.playlists.selected
})

const actionsToProps = ({
    updateCurrentMark,
    toggleSelectedSong,
    toggleSelectedPlaylist
})
export default connect(stateToProps, actionsToProps)(Song)
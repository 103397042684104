import React from 'react'


const Grid = ({ title = 'Редактировать', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} only_trigger spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg className="only_trigger" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" font-weight="400" x="0px" y="0px" width="24px" height="24px" enableBackground="new 0 0 512 512" xml="preserve" viewBox="0 0 512 380">
                <g>
                </g>
                <g>
                    <rect className="only_trigger" x="32" y="32" width="127.97" height="60" />
                    <rect className="only_trigger" x="191.963" y="32" width="127.969" height="60" />
                    <rect className="only_trigger" x="351.926" y="32" width="127.969" height="60" />
                    <rect className="only_trigger" x="32" width="127.97" y="160" height="60" />
                    <rect className="only_trigger" x="191.963" width="127.969" y="160" height="60" />
                    <rect className="only_trigger" x="351.926" width="127.969" y="160" height="60" />
                    <rect className="only_trigger" x="32" width="127.97" y="288" height="60" />
                    <rect className="only_trigger" x="191.963" width="127.969" y="288" height="60" />
                    <rect className="only_trigger" x="351.926" width="127.969" y="288" height="60" />
                </g>
            </svg>
        </i>
    )
}

export default Grid
import React from 'react'


const Panel = ({ disabled, children }) => (
    <div className={ `Panel ${disabled ? "disabled" : ""}` }>
        <div className="spp-container">
            <div className="wrap-content">

                { children }

            </div>
        </div>
    </div>
)

export default Panel
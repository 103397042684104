import React from 'react'
import { connect } from 'react-redux'

import Place from '../../../../components/Space/Place'

import SlideMenu from '../../../../components/SlideMenu'
import Callback from './Callback'

const When = ({

}) => {


    return (
        <>
            {/* 3 */ }

            <Place
                number={ 3 }
                className="ObjectsWhen"
            >
                <SlideMenu
                    activeItem={ 1 }
                    items={ [{ content: <Callback placeNumber={ 2 } active /> }] }
                />
            </Place>
        </>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
    selectedGroups: [...state.objectsGroup.selected],
})

export default connect(stateToProps, null)(When)
import React from 'react'
import PropTypes from 'prop-types'

import ScrollContainer from '../../ScrollContainer'


class Color extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            contentOpened: false,
            selected: {
                value: "",
                title: "",
            }
        }

        this.contentRef = React.createRef()
        this.inputRef = React.createRef()

        this.toggleContentOpened = this.toggleContentOpened.bind(this)
        this.selectOption = this.selectOption.bind(this)
        this.setOption = this.setOption.bind(this)
    }

    toggleContentOpened() {
        this.setState(state => {
            return {
                contentOpened: !state.contentOpened
            }
        })

        if(!this.state.contentOpened){
            function closeElement(e) {
                if(e.target === this.inputRef.current){
                    return true
                }
                if(e.target !== this.contentRef.current && !this.contentRef.current.contains(e.target)) {
                    this.setState({ contentOpened: false })
                    document.removeEventListener("click", closeElement)
                }
            }
            document.addEventListener("click", closeElement.bind(this))
        }
    }

    selectOption(e) {
        const option = e.target
        this.setOption(
            option.dataset.value || "",
            option.dataset.title || "",
        )
        this.toggleContentOpened()
    }

    setOption(value, title) {
        let newState = {
            selected: {
                value: value,
                title: title,
            }
        }
        this.setState(state => {
            if(value === state.selected.value){
                newState.selected.value = ""
                newState.selected.title = ""
            }
            return {...newState}
        })

        if(typeof this.props.onClick === "function")
            this.props.onClick(
                {...this.state, ...newState},
                this.state
            )
    }

    componentDidMount() {
        let v, t
        this.props.colors.map(option => {
            if(option.selected === true){
                v = option.value || option.title
                t = option.title
            }
        })
        this.setOption(v, t)
    }

    render() {
        const {colors, placeholder, id = "", name = "", onClick, disabled} = this.props
        return(
            <>
                <input
                    className={`form-control ${this.state.selected.title === "" || this.state.selected.title === undefined ? "" : "active"}`}
                    onClick={this.toggleContentOpened}
                    value={this.state.selected.title}
                    ref={this.inputRef}
                    placeholder={placeholder}
                    readonly="true"
                    type="text"
                    disabled={disabled}
                />

                <div className="color" style={{backgroundColor: this.state.selected.value}}></div>

                <div className={`FormSelect__content ${this.state.contentOpened ? "opened" : ""}`} ref={this.contentRef}>
                    <ScrollContainer size="md" collapsed={false}>
                        <div onClick={this.selectOption}>
                            {colors.map(option => {
                                return <div
                                            className={`FormSelectColor__item ${this.state.selected.value == option.value || this.state.selected.value == option.title ? "selected" : ""}`}
                                            data-value={option.value}
                                            data-title={option.title}>
                                                <div className="color" style={{backgroundColor: option.value}}></div>
                                                {option.title}
                                        </div>
                            })}
                        </div>
                    </ScrollContainer>
                </div>

                <select id={id} name={name} value={this.state.selected.value}>
                    {colors.map(option => {
                        return <option value={option.value || option.title} selected={option.selected}>{option.title}</option>
                    })}
                </select>
            </>
        )
    }
}

Color.propTypes = {
    colors: PropTypes.exact({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        title: PropTypes.string,
        selected: PropTypes.bool
    }),
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
}

export default Color
export default function ({
    settings = {},
    name,
    onChange,
    placeholder
}) {

    return [
        {
            type: "InputTextarea",
            data: {
                onChange,
                attrs: {
                    value: settings[name],
                    placeholder: placeholder || "Введите описание"
                },
                fillHeight: true
            }
        }
    ]
}
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { modeActiveOn, modeActiveOff, changeValue, clearValue } from '../../store/Search/actions'

import SearchComponent from './Search'


const Search = props => {


    return <SearchComponent
        className={props.className}
        placeholder={props.placeholder}
        onChange={props.onChange}
        disabled={props.disabled}
        searchState={props.searchState}
        searchType={props.searchType}
        actions={props.actions}
        clearMarker={props.clearMarker}
    />
}

const stateToProps = (state, props) => ({
    searchState: state.search[props.searchType],
    // playlists: state.playlists.selected
})

const actionsToProps = dispatch => ({
    actions: {
        modeActiveOn: bindActionCreators(modeActiveOn, dispatch),
        modeActiveOff: bindActionCreators(modeActiveOff, dispatch),
        changeValue: bindActionCreators(changeValue, dispatch),
        clearValue: bindActionCreators(clearValue, dispatch),
    }
})

export default connect(stateToProps, actionsToProps)(Search)
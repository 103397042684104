import { takeEvery, call, put } from 'redux-saga/effects'
import { NEED_HELP, sending_call, sending_question, putSended } from '../../store/Help/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(NEED_HELP, worker)
}

function* worker({ payload }) {
    if (payload.subject === 1) {
        yield put(sending_call(true))
    } else {
        yield put(sending_question(true))
    }

    try {
        const help = yield call(fetchHelp, { ...payload })
        if (help.error && help.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${NEED_HELP}.`, help],
                text: "Заявка не отправлена. Попробуйте перезагрузить страницу."
            }))
            // yield put(putHelp([]))
        } else {
            yield put(putSended({ type: payload.subject }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${NEED_HELP}.`, e],
            text: "Заявка не отправлена. Попробуйте перезагрузить страницу."
        }))
        // yield put(putHelp([]))
    }
    finally {
        if (payload.subject === 1) {
            yield put(sending_call(false))
        } else {
            yield put(sending_question(false))
        }
    }
}

function fetchHelp(userdata) {
    let formData = new FormData()
    Object.keys(userdata).forEach(name => {
        formData.set(name, userdata[name])
    })
    return axios({
        url: "/mail/support",
        method: "POST",
        data: formData
    })
        .then(response => response.data)
}

import { takeEvery, put, select } from 'redux-saga/effects'
import { MODE_ACTIVE_ON, putFounded } from '../../store/Search/actions'

export default function* () {
    yield takeEvery(MODE_ACTIVE_ON, worker)
}

function* worker({ payload }) {
    const { type } = payload


    const founded = yield select(state => state.search[type].founded)
    const value = yield select(state => state.search[type].value)

    if (!value.length && !founded.length) {
        let items
        if (type === "tracks") {
            items = yield select(state => state.tracks.tracks)
        } else if (type === "objects") {
            items = yield select(state => state.objects.allObjects)
        } else if (type === "playlists") {
            items = yield select(state => state.playlists.allPlaylists)
        }
         else if (type === "songs") {
            items = yield select(state => state.playlists.allPlaylists && !!state.playlists.selected.length ? state.playlists.allPlaylists.filter(cur => cur.id === state.playlists.selected[0])[0].songs : [])
        }


        yield put(putFounded({
            type: type,
            founded: items
        }))
    }
}
import React from 'react'


const Collapse = ({ title = 'Свернуть', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-'+variant : ''} ${size ? 'spp-svg-size-'+size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 4.234">
                <title>{title}</title>
                <path d="M1.45 0a.132.132 0 00-.132.132v1.191H.128a.132.132 0 100 .265H1.45a.132.132 0 00.132-.132V.131A.132.132 0 001.45 0zm1.323 0a.132.132 0 00-.132.132v1.323c0 .074.059.133.132.133h1.323a.132.132 0 100-.265h-1.19V.133A.132.132 0 002.772 0zM.127 2.646a.132.132 0 100 .264h1.191V4.1a.132.132 0 10.264 0V2.779a.132.132 0 00-.132-.132H.127zm2.646 0a.133.133 0 00-.132.132V4.1a.132.132 0 10.264 0V2.91h1.19a.132.132 0 100-.265H2.774z" data-name="Group 1811" fill="#0a0a0a"/></svg>
        </i>
    )
}

export default Collapse 
import { takeEvery, select, put, call, all } from 'redux-saga/effects'
import { OBJECT_SCHEDULE } from '../../../../store/Settings/sets'
import {loadItems, SAVE_TRACK_FOR_ITEM} from '../../../../store/Settings/actions/sets/Object/Schedule'
import { updateOptions, mode } from '../../../../store/Settings/actions'
import { closeModal } from '../../../../store/Modal/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_TRACK_FOR_ITEM, worker)
}

function* worker({ payload }) {
    let { stateKey, trackId, side, position } = payload

    try {
        if (typeof trackId === "number" || trackId === "NULL") {
            yield put(mode({
                type: OBJECT_SCHEDULE,
                stateKey,
                modeType: "__savingTrackForItem",
                mode: true,
            }))

            const selectedItems = yield select(state => state.settings.settings[OBJECT_SCHEDULE][stateKey]?.selected || [])

            if (selectedItems.length) {
                const itemsSettings = yield select(state => state.settings.settings[OBJECT_SCHEDULE])
                const selectedObjects = yield select(state => state.objects.selected || [])
                const selectedGroups = yield select(state => state.objectsGroup.selected || [])
                const msIds = selectedGroups.length ? selectedGroups : selectedObjects
                const itemsTracksSettings = {}

                const saved = yield all(
                    selectedItems.map(selectedItem => {
                        const itemStateKey = generateStateKey([stateKey, selectedItem])
                        const itemSettings = itemsSettings[itemStateKey]

                        const settingName = `${position}_id`
                        let itemTracksSettings = itemSettings[settingName]
                        if (typeof itemTracksSettings === "string") itemTracksSettings = itemTracksSettings.split(",")
                        itemTracksSettings[side] = trackId

                        itemsTracksSettings[itemStateKey] = itemTracksSettings

                        return call(fetchSave, selectedItem, msIds, settingName, itemTracksSettings)
                    })
                )

                let error = false
                saved.map(req => {
                    if (req.error) error = true
                })

                // Ошибка
                if (error) {
                    yield put(showAlertError({
                        errors: [`Ошибка сервера при ${SAVE_TRACK_FOR_ITEM}.`, saved],
                        text: "Ошибка при сохранении. Попробуйте повторить."
                    }))

                    // Успех
                } else {
                    yield put(showAlertSuccess({
                        responses: [`Успешное выполнение ${SAVE_TRACK_FOR_ITEM}.`, saved],
                        text: "Изменения успешно сохранены."
                    }))
                    yield put(loadItems({
                        stateKey,
                        required: true,
                    }))
                    const sideStr = side === 0 ? "inside" : "outside"
                    position = position === "start" ? "Start" : "Stop"

                    yield all(
                        selectedItems.map(selectedItem => {
                            const itemStateKey = generateStateKey([stateKey, selectedItem])
                            const itemTracksSettings = itemsTracksSettings[itemStateKey]

                            return put(updateOptions({
                                type: OBJECT_SCHEDULE,
                                stateKey: itemStateKey,
                                settings: {
                                    ...itemTracksSettings,
                                    [sideStr + position]: trackId
                                }
                            }))
                        })
                    )
                    yield put(closeModal("element"))
                }
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_TRACK_FOR_ITEM}.`, e],
            text: "Ошибка при сохранении. Попробуйте повторить."
        }))
    }

    finally {
        yield put(mode({
            type: OBJECT_SCHEDULE,
            stateKey,
            modeType: "__savingTrackForItem",
            mode: false,
        }))
    }
}

function fetchSave(id, ms_id, settingName, settings) {
    ms_id = ms_id.join(",")

    let formData = new FormData()
    formData.set(settingName, settings.join(","))

    return axios(
        {
            url: "/msbox/clip/update",
            method: "POST",
            data: formData,
            params: {
                id, ms_id
            }
        }
    )
        .then(response => response.data)
}
import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { EDIT_VIDEOLIST, loading } from '../../../store/Modal/types/VideolistCreate/actions'
import { closeModal } from '../../../store/Modal/actions'
import { loadVideolists } from '../../../store/ContentVideolists/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(EDIT_VIDEOLIST, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        const created = yield call(fetchEdit, payload)

        if (created.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${EDIT_VIDEOLIST}.`, created],
                text: "Видеолист не был создан. Попробуйте повторить."
            }))
            yield put(closeModal("videolistEdit"))

        } else {

            yield put(closeModal("videolistEdit"))
            yield put(loadVideolists())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${EDIT_VIDEOLIST}.`, e],
            text: "Видеолист не был создан. Попробуйте повторить."
        }))
    }
    finally {
        yield put(loading(false))
        yield put(closeModal("videolistEdit"))
    }
}

function fetchEdit(videolistData) {
    let formData = new FormData()
    let clear = { ...videolistData }
    delete clear.id_videolist
    if (clear && clear.name) {
        Object.keys(clear).forEach(name => {
            formData.set(name, clear[name])
        })
    }

    return axios(
        {
            url: `/videolist/update?id=${videolistData.id_videolist}`,
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}

import React, {useState} from 'react'

import Empty from '../Space/Collapsible/Empty'
import ScrollContainer from '../ScrollContainer'
import CollapsibleCard from '../CollapsibleCard'
import Spinner from '../Spinner'

import {getNextSelected} from '../../utilities/getNextSelected'


const ObjectsByTags = ({
    className = "",
    tags = [],
    selectedTags = [],
    selectedObjects = [],
    toggleSelectedTags,
    toggleSelectedObject,
    loading,
}) => {

    //const tagsNames = tags.map(tag => tag.name)
    const hClick = ({ payload }, event) => {
        const nextSelectedTags = getNextSelected({
            multiple: true,
            items: tags,
            itemId: payload.id,
            // itemId: payload.tagName,
            selected: selectedTags,
            shiftKey: event.shiftKey,
            ctrlKey: event.ctrlKey || event.metaKey,
        })
        console.log(nextSelectedTags)

        toggleSelectedTags({
            tagName: payload.tagName,
            selected: nextSelectedTags,
        })
    }
    
    return(
        <section className={`ObjectsByTags ${className}`}>
            
            <div className="ObjectsByTags__list">
                <ScrollContainer size="lg">

                    {loading
                        ? <Spinner className="pt-xs pb-xs"/>
                        : (tags.length)
                            ? tags.map((tag, index) =>
                                <CollapsibleCard
                                    id={index}
                                    key={index}
                                    title={<>{tag.name} <span>({tag.objects.length})</span></>}
                                    selected={selectedTags.indexOf(tag.id) !== -1}
                                    onClick={hClick}
                                    payload={{
                                        tagName: tag.name,
                                        id: tag.id
                                    }}
                                >
                                    {tag.objects.map(object => (
                                        <div
                                            key={object.id}
                                            className={`ObjectsByTags__item selectable-sub-item ${selectedObjects.indexOf(object.id) !== -1 ? "selected" : ""}`}
                                            onClick={() => toggleSelectedObject({ id: object.id })}
                                        >
                                            <span className="selectable-sub-item__color">
                                                {object.name}
                                            </span>
                                        </div>
                                    ))}
                                </CollapsibleCard>
                            )
                            : <Empty title="Меток для групп пока нет."/>
                    }

                </ScrollContainer>
            </div>
        </section>
    )
}

export default ObjectsByTags
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import 'react-bootstrap'
import ButtonWhite from '../../ButtonWhite'
import { Button } from 'react-bootstrap'
import SvgEdit from '../../SVG/Edit'
import SvgTrash from '../../SVG/Trash'
import SvgPlus from '../../SVG/PlusCircle'
import { editVideolist } from '../../../store/Modal/types/VideolistCreate/actions'
import { deleteVideolists, addVideoToList } from '../../../store/ContentVideolists/actions'
import { openModal, closeModal } from '../../../store/Modal/actions'


const Footer = ({
    selected_song_videolist,
    selected_videolist,
    openModal,
    closeModal,
    deleteVideolists,
    videolists,
    addVideoToList,
    selected_all_song,
    selected_all_videolist,
    all_videolists,
    vl_state
}) => {
    const [playlist_items, setPlaylistItems] = useState([])
    const [public_playlist_items, setPublicPlaylistItems] = useState([])
    useEffect(() => {
        if (selected_videolist.length > 0 && videolists.length > 0) {
            setPlaylistItems([...Object.values(videolists.filter(pl => pl.id === selected_videolist[0])[0].songs)])
        }
    }, [selected_videolist, vl_state])
    useEffect(() => {
        if (selected_all_videolist.length > 0) {
            setPublicPlaylistItems([...Object.values(all_videolists.filter(pl => pl.id === selected_all_videolist[0])[0].songs)])
        }

    }, [selected_all_videolist])
    const deleteSongs = () => {
        let clear = playlist_items.map(song => { return song.id }).filter(id => selected_song_videolist.indexOf(id) === -1)
        let playlist_items_update = Array.from(playlist_items).filter(song => selected_song_videolist.indexOf(song.id) === -1)
        addVideoToList({ id_videolist: selected_videolist[0], selected_song: clear, songs: playlist_items_update })
    }
    const addSongs = () => {
        let current = playlist_items.map(song => { return song.id })
        let full = [...current, ...selected_all_song]
        console.log(current, selected_all_song)
        let playlist_items_update = [...Array.from(playlist_items), ...public_playlist_items.filter(song => selected_all_song.indexOf(song.id) !== -1)]
        addVideoToList({ id_videolist: selected_videolist[0], selected_song: full, songs: playlist_items_update })
    }
    return (
        <div>
            { (selected_videolist.length > 0 && selected_song_videolist.length === 0 && selected_all_videolist.length === 0) &&
                <div className="fixed-bottom p-2 bg-light border footer">
                    <div className="d-flex justify-content-between flex-row justify-content-center align-items-center">
                        <button onClick={ () => openModal({
                            type: "videolistEdit",
                            size: "sm",
                        }) } className="btn btn-light btn-sm mt-2" style={ { minWidth: "48%", lineHeight: "1px" } }><SvgEdit size="sm" />Изменить</button>
                        <button onClick={ () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: "Вы точно хотите удалить выделенные списки?",
                                buttons: {
                                    cancel: {
                                        title: "Вернуться",
                                        onClick: () => {
                                            closeModal("confirm")
                                        }
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deleteVideolists(selected_videolist)
                                            closeModal("confirm")
                                        }
                                    }
                                }
                            }
                        }) } className="btn btn-light btn-sm mt-2" style={ { minWidth: "48%", lineHeight: "1px" } }><SvgTrash size="sm" />Удалить</button>
                    </div>
                </div >
            }
            { selected_song_videolist.length > 0 &&
                <div className="fixed-bottom p-2 bg-light border footer">
                    <div className="d-flex justify-content-between flex-row justify-content-center align-items-center">
                        <button onClick={ () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: "Вы точно хотите удалить выделенные видео из этого списка?",
                                buttons: {
                                    cancel: {
                                        title: "Вернуться",
                                        onClick: () => {
                                            closeModal("confirm")
                                        }
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deleteSongs()
                                            closeModal("confirm")
                                        }
                                    }
                                }
                            }
                        }) } className="btn btn-light btn-sm mt-2" style={ { minWidth: "100%", lineHeight: "1px" } }><SvgTrash size="sm" />Удалить</button>
                    </div>
                </div >
            }
            { selected_videolist.length > 0 && selected_all_song.length > 0 &&
                <div className="fixed-bottom p-2 bg-light border footer">
                    <div className="d-flex justify-content-between flex-row justify-content-center align-items-center">
                        <button onClick={ () => addSongs() } className="btn btn-light btn-sm mt-2" style={ { minWidth: "100%", lineHeight: "1px" } }><SvgPlus size="sm" />Добавить</button>
                    </div>
                </div >
            }
        </div>
    )
}

const stateToProps = state => ({
    selected_videolist: state.videolists.selected,
    videolists: state.videolists.allVideolists,
    selected_song_videolist: state.videolists.selected_video,
    vl_state: state.videolists,

    all_videolists: state.videolists_all.allVideolists,
    selected_all_song: state.videolists_all.selected_video,
    selected_all_videolist: state.videolists_all.selected,
})
const actionsToProps = ({
    editVideolist,
    deleteVideolists,
    openModal,
    closeModal,
    addVideoToList
})
export default connect(stateToProps, actionsToProps)(Footer)
export const SAVE_ADS = "Modal/AdsEdit/SAVE_ADS"
export const SAVE_ADS_EXTRA = "Modal/AdsEdit/SAVE_ADS_EXTRA"
export const UPDATE_MODAL_STORE = "Modal/AdsEdit/UPDATE_MODAL_STORE"
export const LOADING = "Modal/AdsEdit/LOADING"

export const saveAds = adsData => ({
    type: SAVE_ADS,
    payload: {
        id: adsData.id,
        dataToSave: adsData.dataToSave
    }
})

export const saveAdsExtra = adsData => ({
    type: SAVE_ADS_EXTRA,
    payload: {
        id: adsData.id,
        dataToSave: adsData.dataToSave
    }
})

export const updateModalStore = adsData => ({
    type: UPDATE_MODAL_STORE,
    payload: adsData
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})
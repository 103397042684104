import React, {useEffect} from 'react'

import Channel from '../../../../Settings/components/Channel'


const ItemChannel = ({
    stateKey,
    settings = {},
    objectIds = [],
    objectsAssociated,
    name,
    onChange,
    empty,
}) => {

    const calcAvailables = () => {
        const outs  = (1 * objectIds.length)
        const insd  = (2 * objectIds.length)
        const bouth = 3
        let countCh = 0

        objectIds.map(id =>
            countCh += objectsAssociated[id].chmask
        )

        if(countCh === outs) return 1
        if(countCh === insd) return 2
        return bouth
    }

    const availablesChannels = calcAvailables()
    // // Эффект создан для задания канала по умолчанию (при рендере компонента или смене или переключении объектов)
    // // Устанавливает все доступные каналы
    // useEffect(() => {
    //     //if(settings[name] === empty) {
    //         console.log(availablesChannels)
    //         onChange({
    //             name: "channel",
    //             value: availablesChannels,
    //         })
    //         settings.channel = availablesChannels
    //     //}
    // }, [stateKey])

    return(
        <Channel
            channel={settings[name]}
            onChange={onChange}
            availables={availablesChannels}
            showAlways
        />
    )
}

export default ItemChannel
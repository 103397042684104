import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../../utilities/generateStateKey'
import {cl} from '../../../../../utilities/cl'

import {OBJECT_BACKGROUND} from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'
import ScrollContainer from '../../../../../components/ScrollContainer'


const SettingsContentCollapsible = ({
    className = "",
    selectedObjects = [],
    selectedGroups = [],
    placeNumber,
    active
}) => {

    const stateKey = selectedGroups.length ? generateStateKey([ selectedGroups ]) : generateStateKey([ selectedObjects ])
    
    return(
        <Collapsible
            active={active}
            id={3221}
            className={className}
            title="Фоновый контент"
            subtitle="Музыка/Видео"
            placeNumber={placeNumber}
        >
            <Settings
                set={OBJECT_BACKGROUND}
                data={{
                    stateKey: stateKey,
                }}
            />
        </Collapsible>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
    selectedGroups: [...state.objectsGroup.selected],
})

export default connect(stateToProps, null)(SettingsContentCollapsible)
import React from 'react'
import {Button as ButtonBS} from 'react-bootstrap'

import {OBJECT_NET} from '../../../../../store/Settings/sets'


export default function({
    settings = {},
    stateKey,
    fields,
    saveSettings,
    disabled,
}) {

    return(
        <div className="SettingsObjectNetSave spp-btns">    
            <ButtonBS
                variant="primary"
                disabled={disabled}
                onClick={() => saveSettings({
                    type: OBJECT_NET,
                    stateKey,
                    fields,
                })}
            >
                {settings.saving === true ? "Сохранение..." : "Сохранить"}
            </ButtonBS>
        </div>
    )
}
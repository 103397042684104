import { DISABLE, ENABLE } from './actions'

const initialState = {
    disableWhereAndWhenBlock: false,
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case DISABLE:
            return {
                ...state,
                disableWhereAndWhenBlock: true
            }
        case ENABLE:
            return {
                ...state,
                disableWhereAndWhenBlock: false
            }

    }
    return state
}

export default reducer
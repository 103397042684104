export function setsmob(classes = []) {
    let answer = []
    classes.map(mod => answer.push( `setsmob-${mod}` ))
    return answer.join(" ")+" "
}

export function setsex(classes = []) {
    let answer = []
    classes.map(mod => answer.push( `setsex-${mod}` ))
    return answer.join(" ")+" "
}

export function setssimp(classes = []) {
    let answer = []
    classes.map(mod => answer.push( `setssimp-${mod}` ))
    return answer.join(" ")+" "
}
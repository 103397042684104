import { takeEvery, all, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { DELETE_VIDEOLISTS, loading, loadVideolists } from '../../store/ContentVideolists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_VIDEOLISTS, worker)
}

function* worker(action) {
    if (action.payload && action.payload[0]) {
        const videolistsIds = action.payload
        try {
            let deletedSuccess = true
            const deleted = yield all(
                videolistsIds.map(videolistId => call(fetchDeleteVideolists, videolistId))
            )

            deleted.map(d => { if (d.error) deletedSuccess = false })

            if (deletedSuccess) {
                yield put(loadVideolists())
            } else {
                yield put(showAlertError({
                    errors: [`Ошибка сети при ${DELETE_VIDEOLISTS}.`, deleted],
                    text: "Не все плейлисты были удалены. Попробуйте заново."
                }))
            }
        }
        catch (e) {
            yield put(showAlertError({
                errors: [`Ошибка сети при ${DELETE_VIDEOLISTS}.`, e],
                text: "Не все плейлисты были удалены. Попробуйте заново."
            }))
        }
    }
}

function fetchDeleteVideolists(videolistId) {
    return axios.get(`/videolist/delete?id=${videolistId}`)
        .then(response => response.data)
}
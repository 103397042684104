export default [
    "minitstr",
    "mphone",
    "mname",
    "mpass",
    "timeout",
    "synctime",
    "minsynctim",
    "maxsynctim",
    "maxtime",
    "syncmute",
]
export const LOAD_SUBUSERS = "Settings/Roles/LOAD_SUBUSERS"
export const LOAD_ROLES = "Settings/Roles/LOAD_ROLES"
export const LOADING = "Settings/Roles/LOADING"
export const PUT_SUBUSERS = "Settings/Roles/PUT_SUBUSERS"
export const PUT_ROLES = "Settings/Roles/PUT_ROLES"
export const UPDATE_SUBUSER = "Settings/Roles/UPDATE_SUBUSER"
export const UPDATE_ROLE = "Settings/Roles/UPDATE_ROLE"
export const UPDATE_ACCOUNT = "Settings/Roles/UPDATE_ACCOUNT"
export const CREATE_SUBUSER = "Settings/Roles/CREATE_SUBUSER"
export const CREATE_ROLE = "Settings/Roles/CREATE_ROLE"
export const LOAD_ACCOUNT_INFO = "Settings/Roles/LOAD_ACCOUNT_INFO"
export const PUT_ACCOUNT_INFO = "Settings/Roles/PUT_ACCOUNT_INFO"
export const DELETE_SUBUSER = "Settings/Roles/DELETE_SUBUSER"
export const DELETE_ROLE = "Settings/Roles/DELETE_ROLE"

export const loadSubusers = () => ({
    type: LOAD_SUBUSERS,
})

export const loadRoles = () => ({
    type: LOAD_ROLES,
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const putSubusers = subusers => ({
    type: PUT_SUBUSERS,
    payload: subusers
})

export const putRoles = roles => ({
    type: PUT_ROLES,
    payload: roles
})

export const updateSubuser = subuser => ({
    type: UPDATE_SUBUSER,
    payload: subuser
})

export const updateRole = role => ({
    type: UPDATE_ROLE,
    payload: role
})

export const updateAccount = account => ({
    type: UPDATE_ACCOUNT,
    payload: account
})

export const createSubuser = subuser => ({
    type: CREATE_SUBUSER,
    payload: subuser
})

export const createRole = role => ({
    type: CREATE_ROLE,
    payload: role
})

export const deleteSubuser = subuser => ({
    type: DELETE_SUBUSER,
    payload: subuser
})

export const deleteRole = id => ({
    type: DELETE_ROLE,
    payload: id
})

export const loadAccountInfo = () => ({
    type: LOAD_ACCOUNT_INFO,
})

export const putAccountInfo = info => ({
    type: PUT_ACCOUNT_INFO,
    payload: info
})

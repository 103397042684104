export default function({
    settings = {},
    updateOptions
}) {

    const hChange = (selected) => {
        updateOptions({
            fromdate: selected.from,
            todate: selected.to
        }) 
    }

    return [
        {
            type: "Datepicker",
            data: {
                type: "between",
                durationInput: true,
                variant: "icon",
                onChange: hChange,
                titles: {
                    main: "Период трансляции",
                    from: "Дата начала трансляции",
                    to: "Дата окончания трансляции"
                },
                data: {
                    disabledType: "past",
                    default: {
                        from: settings.fromdate,
                        to: settings.todate,
                    }
                }
            }
        }
    ]
}
import React from 'react'


const ArrowUpDown = ({ title = 'Больше/Меньше', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1.827 4.231">
                <title>{ title }</title>
                <path d="M.936 0C.928 0 .92.003.912.005A.131.131 0 00.796.04L.039.796a.133.133 0 10.19.189L.912.3l.685.685a.133.133 0 10.19-.19L1.03.04A.133.133 0 00.936 0zM.134 3.21a.133.133 0 00-.095.228l.757.756a.13.13 0 00.117.035.13.13 0 00.118-.035l.756-.756a.133.133 0 10-.19-.189l-.684.684L.23 3.25a.133.133 0 00-.095-.04z" data-name="Group 3320" fill="#0a0a0a" strokeWidth=".989" /></svg>
        </i>
    )
}

export default ArrowUpDown
import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import ScrollContainer from '../../ScrollContainer'
import Spinner from '../../Spinner'
import AllVideolistItem from '../VideolistAllItem'
import Empty from '../Empty'
import List from '../../List'
import { loadPublicPlaylists, toggleSelectedPublicPlaylist, sortPublicPlaylists } from '../../../store/PublicPlaylists/actions'
import { loadVideolistsAll, toggleSelectedVideolistAll, sortAllVideolists } from '../../../store/ContentAllVideolists/actions'
import { isMobile } from '../../../utilities/media'
import { getNextSelected } from '../../../utilities/getNextSelected'
import Button from '../../Button'
import SvgArrowUpDown from '../../SVG/ArrowUpDown'


const AllVideolists = ({
    searchActive,
    searchFounded = [],
    videolists_all = [],
    loading_public,
    toggleSelected,
    selected,
    dnd_result,
    loadVideolistsAll,
    toggleSelectedVideolistAll,
    sortAllVideolists,
    allVideolists,
    loading_all,
    selectedAllVideolists

}) => {
    const [sort_direction, setSortDirection] = useState("")

    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortAllVideolists({ direction: sort_direction, sort_type: type })
    }
    useEffect(() => {
        loadVideolistsAll()
    }, [])
    //if (searchActive) allPlaylists = searchFounded
    videolists_all = allVideolists
    const hToggleSelected = (e) => {
        const videolist = e.target.closest(".Videolist")
        if (videolist) {
            const videolistId = Number(videolist.dataset.videolistId)

            if (!isNaN(videolistId)) {
                const selected = getNextSelected({
                    multiple: true,
                    itemId: videolistId,
                    selected: selectedAllVideolists,
                    ctrlKey: !isMobile() || e.ctrlKey || e.metaKey,
                })

                toggleSelectedVideolistAll({
                    selected,
                    simple: true,
                })
            }
        }
    }



    const getItems = () => {
        return videolists_all.map((videolist, index) => ({
            //return (
            id: videolist.id,
            name: videolist.name,
            element: < AllVideolistItem
                key={ videolist.id }
                id={ videolist.id }
                name={ videolist.name }
                songs={ videolist.songs }
                totaltime={ videolist.totaltime }
                totalsize={ videolist.totalsize }
                variant={ (index % 2 == 0) ? "gray" : "" }
            />
        }))
    }

    return (
        <section className="Videolists">
            <div onClick={ hToggleSelected } style={ { marginTop: 50 } }>
                <header className="Videolists__control">
                    <h4 style={ { marginBottom: "10px" } }> Все списки</h4>
                    <div className="Videolists__grid">
                        <div className="name">
                            <Button
                                type="string"
                                onClick={ () => sort("name") }
                            >
                                <SvgArrowUpDown title="Сортировка по название плейлиста" />
                                Название
                            </Button>
                        </div>
                        <div className="tracks_count">
                            <Button
                                type="string"
                                onClick={ () => sort("songs") }
                            >
                                <SvgArrowUpDown title="Сортировка по кол-ву треков" />
                                <span>Треков</span>
                            </Button>
                        </div>
                        <div className="size">
                            <Button
                                type="string"
                                onClick={ () => sort("size") }
                            >
                                <SvgArrowUpDown title="Сортировка по размеру плейлиста" />
                                <span>Размер</span>
                            </Button>
                        </div>
                        <div className="duration">
                            <Button
                                type="string"
                                onClick={ () => sort("duration") }
                            >
                                <SvgArrowUpDown title="Сортировка по длительности" />
                                <span>Длительность</span>
                            </Button>
                        </div>
                    </div>
                </header>
                { loading_all
                    ? <Spinner className="pt-xs pb-xs" />
                    : videolists_all.length > 0
                        ? <List
                            type="simple"
                            scroll={ {
                                on: true,
                                data: {
                                    variant: "white",
                                    size: "lg",
                                }
                            } }
                            // variant={ "none" }
                            content={ { items: getItems(), variant: "none" } }
                        />

                        : <Empty title="Плейлистов пока нет." />
                }

            </div>
        </section>
    )
}

const stateToProps = state => ({
    allVideolists: state.videolists_all.allVideolists,
    loading_all: state.videolists_all.loading,
    selectedAllVideolists: [...state.videolists_all.selected],
    selected: [...state.videolists_all.selected],
})

const actionsToProps = {
    loadVideolistsAll,
    toggleSelectedVideolistAll,
    sortAllVideolists
}
export default connect(stateToProps, actionsToProps)(AllVideolists)
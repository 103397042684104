import {formatSecondsToTime, formatTimeToSeconds} from '../../../utilities/format'
import {takeEvery, select, put} from 'redux-saga/effects'
import {SORT_DATA, loading, putReport} from '../../../store/Modal/types/AdsReport/actions'
import {showAlertError} from '../../../store/Alert/actions'


export default function* () {
    yield takeEvery(SORT_DATA, worker)
}

function* worker({ payload }) {
    const {from, to, data, objectId} = payload

    try {
        let nextState = {
            from, to, objectId
        }

        let reportObjects = yield select(state => state.modalAdsReport.objects)
        let allObjects = yield select(state => state.objects.allObjects)
        let objects = []
        if(!reportObjects[0]) {
            allObjects.map(o => objects.push({
                title: o.fullName,
                value: o.id
            }))
            nextState.objects = objects
        }

        if(data) {
            let inside = [],
                outside = []

            data.map(item => {
                if(Number(item.channel) === 2) inside.push(item)
                if(Number(item.channel) === 1) outside.push(item)
                if (typeof item.channel === 'undefined') {
                    inside.push(item)
                    outside.push(item)
                }
            })
            nextState.report = {
                inside: addBlockTimeLeft(inside),
                outside: addBlockTimeLeft(outside)
            }
        }

        yield put(putReport(nextState))
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SORT_DATA}.`, e],
            text: "Отчёт не был загружен. Попробуйте повторить."
        }))
    }
    finally {
        yield put(loading(false))
    }
}

//
// Функции
//

function addBlockTimeLeft(items) {
    let answer = [],
        blockWorkload = 0,
        currentBlock  = 0,
        addTimeLeft = false,
        secondsLeft = 0,
        secondsSum = 0,
        itemsCount = items.length

    items.map((item, index) => {
        item.indenttime = normalizeThisTime(item.indenttime)
        item.timeLeft = normalizeThisTime(item.timeLeft)
        answer.push(item)

        currentBlock  = getBlockNumber(item.starttime)
        blockWorkload += formatTimeToSeconds(item.indenttime)
        // addTimeLeft   = isNextItemTheNextBlock({
        //     itemTimeStart: items[++index]?.starttime,
        //     currentBlock:  currentBlock
        // })
        let diff = formatTimeToSeconds(items[++index]?.starttime) - formatTimeToSeconds(item.starttime);
        if(diff > 0 || itemsCount === index) {
            secondsSum = blockWorkload
            secondsLeft = 300 - blockWorkload // 5 минут - текущая загрузка блока
            blockWorkload = 0
            answer.push({ sum: formatSecondsToTime(secondsSum), final: formatSecondsToTime(secondsLeft >= 0 ? secondsLeft : 0) })
        }
    })

    return answer
}

function isNextItemTheNextBlock({ itemTimeStart, currentBlock, empty }) {
    if(itemTimeStart === empty) return true
    return (currentBlock !== getBlockNumber(itemTimeStart))
}

function getBlockNumber(itemTimeStart) {
    let blockNumber = 0
    if(typeof itemTimeStart !== "string" || itemTimeStart === "") return blockNumber

    const minutes = Number(itemTimeStart.split(":")[1])

    if(!isNaN(minutes)) {
        blockNumber =
            minutes === 0
                ? 1
                : minutes/5
        
        if(minutes !== 0) {
            blockNumber =
                (Math.ceil(blockNumber) === blockNumber)
                    ? blockNumber+1
                    : blockNumber
        }
    }
    return blockNumber
}

function normalizeThisTime(time) {
    if(typeof time !== "string") return ""
    return time.split(".")[0]
}
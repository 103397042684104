import React, { useState, useEffect } from 'react'
import Spinner from '../../../../../../components/Spinner'
import { connect } from 'react-redux'
import { loadClipReport, sortClipReport } from '../../../../../../store/Reports/Advert/actions'
import AdvertReportItem from '../Advert/item'
import List from '../../../../../../components/List'
import SvgArrowUpDown from '../../../../../../components/SVG/ArrowUpDown'

const ReportAdvert = ({
    loadClipReport,
    objects,
    selecteo,
    selected_state,
    advert_report,
    sortClipReport,
    loading,
    date
}) => {
    let selected
    if (selecteo.length > 0) {
        selected = selecteo
    } else {
        selected = selected_state
    }
    useEffect(() => {
        loadClipReport({ id: selected[0], date: date })
    }, [])
    const [sort_direction, setSortDirection] = useState("")

    const [trackPlaying, setTrackPlaying] = useState(0)
    const [trackNotId, setTrackNotId] = useState('')
    const [isPlaying, setIsPlaying] = useState(false)
    const [isWatching, setIsWatching] = useState(false)
    const [audio, setAudio] = useState(new Audio())
    const [lastClickedPlayId, setLastClickedPlayId] = useState(-1)
    const [lastClickedPlayNotId, setLastClickedPlayNotId] = useState(-1)
    const hPause = () => {
        setIsPlaying(false)
        setIsWatching(false)
        audio.pause()
    }
    const listener = function (e) {
        if (e.target.classList.contains('Object-report_menu-element') || e.target.classList.contains('only_trigger') || e.target.classList.contains('date')) {
            audio.pause()
        }
    }
    useEffect(() => {
        if (lastClickedPlayId === trackPlaying && lastClickedPlayNotId === trackNotId) {
            audio.addEventListener("canplaythrough", audio.play)
        }
    }, [audio])
    useEffect(() => {
        hPause()
    }, [selecteo, date])
    document.addEventListener('click', listener, false)
    const hPlay = ({ trackId, filename, trackNot }) => {
        if (trackId === trackPlaying && trackNotId === trackNot) {
            setLastClickedPlayId(trackId)
            setLastClickedPlayNotId(trackNot)
            setTrackNotId(trackNot)
            audio.play()
            setIsPlaying(true)
            setIsWatching(true)
        } else {
            setLastClickedPlayId(trackId)
            setLastClickedPlayNotId(trackNot)
            setTrackNotId(trackNot)
            audio.pause()
            setTrackPlaying(trackId)
            setIsPlaying(true)
            setIsWatching(true)
            setAudio(new Audio(filename))
        }
    }
    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortClipReport({ direction: sort_direction, sort_type: type })
        hPause()
        setLastClickedPlayId(-1)
        setLastClickedPlayNotId(-1)
        setTrackNotId('')
        setTrackPlaying(0)
    }
    const hStop = () => {
        setIsPlaying(false)
        setIsWatching(false)
    }

    const hRewind = (totime) => {
        if (audio.currentTime > 0) {
            audio.currentTime = totime
        }
    }
    // let data = [{
    //     "timestamp": "2021-11-03 11:20:42",
    //     "channel": 1,
    //     "name": "соц сети 1920x1080",
    //     "mimetype": "video/mp4",
    //     "clip_id": 297163
    // }, {
    //     "timestamp": "2021-11-03 11:21:20",
    //     "channel": 1,
    //     "name": "гренки-",
    //     "mimetype": "video/mp4",
    //     "clip_id": 330873
    // },
    // {
    //     "timestamp": "2021-11-03 09:35:04",
    //     "channel": 1,
    //     "name": "АМ Рекомендации по профилактике коронавируса 042020",
    //     "mimetype": "audio/mpeg",
    //     "clip_id": 264241
    // },
    // ]

    const getItems = () => {
        return advert_report.map((advert_report, index) => ({
            id: index,
            name: advert_report.channel,
            element: <AdvertReportItem
                key={ index }
                id={ index }
                name={ advert_report.name }
                timestamp={ advert_report.timestamp }
                channel={ advert_report.channel }
                mimetype={ advert_report.mimetype }
                clip_id={ advert_report.clip_id }
                variant={ (index % 2 == 0) ? "gray" : "" }

                audio={ audio }
                trackPlaying={ trackPlaying }
                trackNotId={ trackNotId }
                isPlaying={ isPlaying }
                isWatching={ isWatching }
                play={ hPlay }
                pause={ hPause }
                stop={ hStop }
                rewind={ hRewind }
            />
        }))
    }
    return (
        <div>
            { !loading ?
                <div style={ { marginTop: "40px" } }>
                    <div className="AdvertsReport">
                        <header className="AdvertsReport__control">
                            <div className="AdvertsReport__grid">
                                <div className="timestamp" >
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("timestamp") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ВРЕМЯ НАЧАЛА
                                    </button>
                                </div>
                                <div className="channel">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("channel") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        КАНАЛ
                                    </button>
                                </div>
                                <div className="clip">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("name") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ОБЪЯВЛЕНИЕ
                                    </button>
                                </div>
                            </div>
                        </header>
                    </div>
                    <List
                        type="simple"
                        scroll={ {
                            on: true,
                            data: {
                                variant: "white",
                                size: "sm",
                            }
                        } }
                        // variant={ "none" }
                        content={ { items: getItems(), variant: "none" } }
                    />
                </div>
                : <Spinner />
            }

        </div>
    )
}

const stateToProps = state => ({
    objects: state.objects.allObjects,
    selecteo: state.objects.selected,
    selected_state: state.report.selected,
    advert_report: state.advert_report.advert_report,
    loading: state.advert_report.loading
})

const actionsToProps = {
    loadClipReport,
    sortClipReport
}

export default connect(stateToProps, actionsToProps)(ReportAdvert)
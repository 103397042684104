import React from 'react'
import { connect } from 'react-redux'
import { didMount } from '../../store/App/actions'
import OutsideElements from '../OutsideElements'


class App extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (window.location.pathname !== '/login') {
            this.props.didMount()
        }
    }

    render() {
        return (
            <>
                <div className='App'>
                    { this.props.children }
                </div>

                <OutsideElements />
            </>
        )
    }
}
export default connect(null, { didMount })(App)
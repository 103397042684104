import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_NOTIFICATIONS, loading, putNotifications, loadNotifications, UPDATE_NOTIFICATIONS } from '../../store/Notifications/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { closeModal } from '../../store/Modal/actions'

export default function* () {
    yield takeEvery(UPDATE_NOTIFICATIONS, worker)
}

function* worker({ payload }) {
    //yield put(loading(true))
    try {
        const { notifyto1, notifyto2 } = payload
        const notifications = yield call(fetchNotifications, { notifyto1: notifyto1, notifyto2: notifyto2 })
        if (notifications.error && notifications.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_NOTIFICATIONS}.`, notifications],
                text: "Уведомления не были обновлены. Попробуйте перезагрузить страницу."
            }))
            yield put(loadNotifications())
        } else {
            // yield put(loadNotifications())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_NOTIFICATIONS}.`, e],
            text: "Уведомления не были обновлены. Попробуйте перезагрузить страницу."
        }))
        yield put(loadNotifications())
    }
    finally {
        yield put(loadNotifications())
        // yield put(closeModal("templateCreate"))
    }
}

function fetchNotifications(data) {
    let formData = new FormData()
    data.notifyto1 !== undefined && formData.set('notifyto1', data.notifyto1)
    data.notifyto2 !== undefined && formData.set('notifyto2', data.notifyto2)
    // formData.set(data.notifyto1, data.notifyto2)
    return axios({
        url: "/pvdata/update",
        method: "POST",
        data: formData
    })
        .then(response => response.data)
}

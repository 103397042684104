import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

import { componentDisabled } from '../../../utilities/componentDisabled'
import { cl } from '../../../utilities/cl'

import SvgArrowDownLight from '../../SVG/ArrowDownLight'
import Options from './Options'
import Colorpicker from './Colorpicker'
import Color from './Color'
import Content from './Content'
import MinutesOfHour from './MinutesOfHour'


class Select extends React.PureComponent {
    constructor(props) {
        super(props)
        this.optionRef = React.createRef();

    }



    open = () => {
        this.optionRef.current.open();
    }

    render() {


        let {
            className = "",
            type = "options",
            options,
            placeholder = "",
            id = "",
            label,
            variant = "dark",
            fontStyle = "normal",
            icon = <SvgArrowDownLight title="Развернуть список" size="mr2" />,
            name,
            onClick,
            disabled,
            children
        } = this.props

        className = cl(
            className,
            "FormSelect",
            { "FormSelectOptions": type === "options" },
            { "FormSelectContent": type === "content" },
            { "FormSelectColor": type === "color" },
            { "FormSelectColorpicker": type === "colorpicker" },
            { "FormSelectDecorate": type === "decorate" },
        )

        let groupClassName = cl(
            variant,
            fontStyle,
            { "icon": icon }
        )

        return (
            <div className={className} onClickCapture={(e) => componentDisabled(e, disabled)}>
                <Form.Group className={groupClassName}>

                    {label && <Form.Label>{label}</Form.Label>}

                    {type === "options" &&
                        <Options
                            id={this.props.id}
                            ref={this.optionRef}
                            name={this.props.name}
                            placeholder={this.props.placeholder}
                            options={this.props.options}
                            selected={this.props.selected}
                            resetMultiple={this.props.resetMultiple}
                            multiple={this.props.multiple}
                            search={this.props.search}
                            searchWord={this.props.searchWord}
                            onChange={this.props.onChange}
                            onChangeInput={this.props.onChangeInput}
                            disabled={this.props.disabled}
                        />
                    }

                    {type === "content" &&
                        <Content {...this.props} />
                    }

                    {type === "minutesOfHour" &&
                        <Content mobileModal {...this.props}>
                            <MinutesOfHour {...this.props} />
                        </Content>
                    }

                    {type === "colorpicker" &&
                        <Colorpicker {...this.props} />
                    }

                    {type === "color" &&
                        <Color {...this.props} />
                    }

                    {type === "decorate" &&
                        <Form.Control type="text" placeholder="type: decorate" />
                    }

                    {icon && icon}
                </Form.Group>
            </div>
        )
    }
}

Select.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(["options", "content", "decorate"]).isRequired,
    //options: PropTypes.objectOf(),
    controlId: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.oneOf(["light", "dark"]),
    icon: PropTypes.element,
    onClick: PropTypes.func
}

export default React.forwardRef((props, ref) => <Select {...props} ref={ref} />);

// export default Select
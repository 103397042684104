import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Collapsible from './Collapsible'


const CollapsibleContainer = props => (

    <Collapsible
        { ...props }
        activeDefault={ props.expandedPlace === props.placeNumber }
    />
)

const stateToProps = state => ({
    expandedPlace: state.panel.expandedPlace,
})

export default connect(stateToProps, null)(CollapsibleContainer)
import React from 'react'
import {connect} from 'react-redux'

import Place from '../../../../components/Space/Place'

import SlideMenu from '../../../../components/SlideMenu'

import SettingsSchedule from './SettingsSchedule'
import SettingsContent from './SettingsContent'
import SettingsVolume from './SettingsVolume'
// import SettingsDates from './SettingsDates'
import SettingsSilent from './SettingsSilent'


const When = ({
    selectedObjects = [],
    selectedGroups = [],
}) => {

    const items = [
        { 
            title: "Расписание",
            content: <SettingsSchedule placeNumber={3}/>
        },
        { 
            title: "Фоновый контент",
            content: <SettingsContent placeNumber={3}/>
        },
        {
            title: "Молчание по датам",
            content: <SettingsSilent placeNumber={3}/>
        }
    ]

    if(selectedObjects.length === 1) {
        items.push({ 
            title: "Громкость",
            content: <SettingsVolume placeNumber={3}/>
        })
        // items.push({
        //     title: "Молчание по датам",
        //     content: <SettingsSilent placeNumber={3}/>
        // })
    }

    return (
        <>
            {/* 3 */}
    
            <Place 
                number={3}
                className="ObjectsWhen"
            >
                {(selectedObjects.length > 0 || selectedGroups.length > 0)
                    && <SlideMenu
                        activeItem={1}
                        items={items}
                    />
                }
            </Place>
        </>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
    selectedGroups: [...state.objectsGroup.selected],
})

export default connect(stateToProps, null)(When)
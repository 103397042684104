import React from 'react'
import Navbar from '../../components/Navbar'
import Panel from '../Advert/Panel'


const NotFound = props => {
    return(
        <section className='NotFound'>
            <Navbar page={props.match.path}/>
            <Panel page={props.match.path} />
            <div className="spp-container pt-lg">
                <h2>-</h2>
            </div>
        </section>
    )
}

export default NotFound
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS, Form } from 'react-bootstrap'

import { addPeriod } from '../../../../store/Settings/actions/components/Periods'
import { openModal } from '../../../../store/Modal/actions'

import Datepicker from '../../../Form/Datepicker'
import Select from "../../../Form/Select";


const Add = ({
    type,
    addPeriod,
    groups,
    adding,
    selected,
    selectedGroups
}) => {


    const selectRef = useRef();

    const [period, setPeriod] = useState({ fromdate: "", todate: "" })
    const [copydate, setCopydate] = useState(0);
    const [copyGroupe, setCopyGroupe] = useState(0);
    const [copy_ms_id, set_copy_ms_id] = useState()

    const otherGroupPeriods = React.useMemo(() => groups.reduce((data, item) => {
        return [...data, { title: item.name, value: item.id }]
    }, []), [groups]);



    const onCheckboxPressed = (id, checked) => {
        if (id === 1) {
            setCopydate(checked ? 1 : 0)
            setCopyGroupe(0)
        }

        if (id === 2 && selectRef.current) {


            if (!!copy_ms_id) {
                setCopyGroupe(checked ? 1 : 0)
                setCopydate(0)
            }
            else {
                selectRef.current.open();
            }
        }
    }


    const hChange = ({ from, to }) => {
        setPeriod({
            fromdate: from,
            todate: to,
        })
    }

    const submit = () => addPeriod(
        !!copyGroupe ?
            { type, period, copydate, selected, copy_ms_id }
            : { type, period, copydate, selected }
    )

    const onSelectedOtherGroupe = (item) => {
        setCopyGroupe(1);
        setCopydate(0)
        set_copy_ms_id(item.value)
    }



    const completed = (period?.fromdate?.length > 2)

    const label = `Скопировать основное расписание ${selectedGroups.length > 0 ? 'группы объектов' : 'объекта'}`
    return (
        <>
            <Datepicker
                className="mb-3"
                type={"between"}
                durationInput={true}
                variant="icon"
                titles={{
                    main: "Период",
                    from: "Дата начала трансляции",
                    to: "Дата окончания трансляции",
                }}
                data={{
                    disabledType: "past",
                }}
                onChange={hChange}
            />

            <Form.Check type="checkbox" id={"copydate"} name="copydate" label={"Скопировать основное расписание объекта/группы объектов"}
                onChange={e => onCheckboxPressed(1, e.target.checked)} value="1"
                checked={!!copydate} />

            {/* <Form.Check type="checkbox" id={"copyGroupe"} name="copyGroupe" label='Скопировать основное расписание из другого оббъекта'
                onChange={e => onCheckboxPressed(2, e.target.checked)} value="2"
                checked={!!copyGroupe}
            />

            <div>
                <Select
                    ref={selectRef}
                    type="options"
                    placeholder="Выберите группу"
                    onClick={console.log}
                    onChange={onSelectedOtherGroupe}
                    options={otherGroupPeriods}
                />
            </div> */}

            <div className="Modal__footer">
                <ButtonBS variant="prim" onClick={submit} disabled={adding || !completed}>
                    {adding ? "Добавление..." : "Сохранить"}
                </ButtonBS>
            </div>
        </>
    )
}

const stateToProps = (state, props) => ({
    adding: state.settings.periods[props.type]?.adding,
    selected: state.objects.selected,
    selectedGroups: state.objectsGroup.selected,
    groups: state.objectsGroup.groups
})

const actionsToProps = {
    addPeriod,
    openModal,
}

export default connect(stateToProps, actionsToProps)(Add)
import React, {useEffect, useState} from 'react'

import {cl} from '../../../../utilities/cl'
import {isFunc} from '../../../../utilities/isFunc'

import Button from '../../../Button'
import Group from '../Group/Group'
import Select from '../../../Form/Select'
import InputText from '../../../Form/InputText'
import Tags from '../../../Tags'

import SvgPlusCyrcle from '../../../SVG/PlusCircle'


const SettingsTags = ({
    className = "",
    name,
    value = "",
    allTags = [],
    onChange,
}) => {

    const [inputTagName, setInputTagName] = useState("")
    const [searchWord, setSearchWord]     = useState("")
    const [tags, setTags]                 = useState([])

    className = cl(
        className,
        "SettingsTags"
    )

    useEffect(() => {
        putTagsFromProps()
    }, [value])

    const hChange = ({ value }) => setInputTagName(value.join(","))
    const hChangeInput = (value) => setSearchWord(value)

    const hKeyDown = (e) => {
        if(e.key === "Enter") addTags()
    }

    const tagsForSelect = [...allTags.filter(t => tags.indexOf(t.title) === -1)]
    const tagsSelected  = inputTagName.length ? inputTagName.split(",") : []

    return(
        <Group className={className} columns={1}>
            <div>
            
                <div className="SettingsTags__header" onKeyDown={hKeyDown}>
                    <Select
                        label="Список меток"
                        type="options"
                        placeholder="Название новой метки"
                        multiple={true}
                        selected={tagsSelected}
                        resetMultiple={{
                            title: "Убрать",
                            value: "",
                        }}
                        search={true}
                        searchWord={searchWord}
                        options={tagsForSelect}
                        onChange={hChange}
                        onChangeInput={hChangeInput}
                    />

                    <div>
                        <Button
                            type="string"
                            title="Добавить тег"
                            onClick={addTags}
                            disabled={!(inputTagName.length || searchWord.length)}
                        >
                            <SvgPlusCyrcle
                                size="sm3"
                                variant="dark-lg"
                            />
                        </Button>
                    </div>
                </div>
                
                <Tags
                    tags={buildTagsList(tags)}
                    onDelete={deleteTag}
                />

            </div>
        </Group>
    )

    function addTags() {
        let inputed  = inputTagName === "0" ? "" : inputTagName,
            nextTags = [...tags]

        if(inputed.length || searchWord.length) {
            if(searchWord) {
                inputed = (inputed.length) ? `${inputTagName},${searchWord}` : searchWord
            }

            inputed = inputed.split(",")

            inputed.map(it => {
                if(nextTags.indexOf(it) === -1) {
                    if(it[0] === " ") {
                        it = it.slice(1)
                    }
                    nextTags.push(it)
                }
            })

            if(tags.indexOf(inputTagName) === -1) {
                setTags([...nextTags])
                setInputTagName("")
                setSearchWord("")
                applyOnChange(nextTags)
            }
        }
    }   

    function deleteTag({ name }) {
        let index = tags.indexOf(name)
        tags.splice(index, 1)
        
        if(index !== -1) {
            setTags([...tags])
            applyOnChange(tags)
        }
    }

    function putTagsFromProps() {
        let nextTags = tags
        nextTags = value.length ? value.split(",") : []
        setTags(nextTags)
    }

    function applyOnChange(tags) {
        if(isFunc(onChange)) {
            onChange({
                name: name,
                value: tags.join(",")
            })
        }
    }
}

export default SettingsTags

//
// Функции
//

function buildTagsList(tags) {
    let tagsList = []

    if(Array.isArray(tags)) {
        tags.map(tagName => tagsList.push({
            id: tagName,
            name: tagName
        }))
    }

    return tagsList
}
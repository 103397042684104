import {takeEvery, call, put, all, select} from 'redux-saga/effects'
import {buildGETUrl} from '../../utilities/buildGETUrl'
import {
    LOAD_VIDEOLISTS_ALL_BY_ID,
    loading, putVideolistsAll,
    putVideolistsAllSongs
} from '../../store/ContentAllVideolists/actions'
import {showAlertError} from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_VIDEOLISTS_ALL_BY_ID, worker)
}

function* worker(action) {
    const videoListId = action.payload
    yield put(loading(true))
    try {
        let videolists = yield select(state => state.videolists_all.allVideolists);
        const fetchedVideolist = yield call(fetchVideo, videoListId)

        if (fetchedVideolist.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_VIDEOLISTS_ALL_BY_ID}.`, fetchedVideolist],
                text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
            }))
            //yield put(putVideolistsAll({ videolists }))

        } else {
            const songs = fetchedVideolist.success.songs.map(track => {
                track.filename = buildGETUrl("clip/play", {id: track.id})
                return track
            })
            videolists = videolists.map(list => {
                if (list.id === videoListId) {
                    list.songs = songs
                }
                return list;
            })
            yield put(putVideolistsAllSongs(songs))
            yield put(putVideolistsAll({ videolists }))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_VIDEOLISTS_ALL_BY_ID}.`, e],
            text: "Плейлисты не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putVideolistsAllSongs([]))
        yield put(putVideolistsAll({ videolists: [] }))
    } finally {
        yield put(loading(false))
    }
}

function fetchVideo(videolistId) {
    return axios.get(`/videolist/get?id=${videolistId}`)
        .then(response => response.data)
}
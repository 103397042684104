import React from 'react'


const Edit = ({ title = 'Редактировать', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 4.234">
                <title>{ title }</title>
                <path d="M3.226 0a.201.201 0 00-.12.043.132.132 0 00-.001.001.132.132 0 00-.024.018l-.006.004a.132.132 0 00-.008.01L.275 2.868a.132.132 0 00-.048.048l-.172.172a.132.132 0 00-.036.041.132.132 0 00-.003.004.132.132 0 000 .003.132.132 0 00-.014.09l.005.832a.132.132 0 00.036.132.132.132 0 000 .002.132.132 0 00.006.005.132.132 0 00.125.03l.834.005a.132.132 0 00.104-.022.132.132 0 00.035-.032l.172-.172a.132.132 0 00.046-.046l2.803-2.802a.132.132 0 00.03-.044.2.2 0 00-.03-.258l-.792-.79A.2.2 0 003.226 0zm0 .29l.718.717-2.696 2.695-.717-.716L3.227.29zM.345 3.173l.717.717-.077.077-.714-.004-.003-.713.077-.077z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" stroke="none" data-name="Group 2766" strokeWidth=".973" strokeMiterlimit="10" strokeLinecap="round" /></svg>
        </i>
    )
}

export default Edit
import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { OBJECT_OBJECT } from '../../../../store/Settings/sets'

import { cl } from '../../../../utilities/cl'
import { carryUpdate } from '../../utilities/carryUpdate'
import { updateHelper } from '../../utilities/updateHelper'
import { prepareSettings } from '../../utilities/prepareSettings'
import { setsex, setsmob } from '../../utilities/setsclasses'
import validateMap from './validateMap'
import fields from './fields'

import Builder from '../../Builder'
import GroupItem from '../../components/Group/Item'

import fieldMsgroupId from './items/msgroup_id'
import SettingsTags from '../../components/Tags'
import fieldName from './items/name'
import fieldAlias from './items/alias'
import fieldAddress from './items/address'
import fieldDescription from './items/description'
import SettingsChannel from '../../components/Channel'
import fieldExtravolume from './items/extravolume'
import fieldMicvol from './items/micvol'
import fieldLinevol from './items/linevol'
import fieldIsbackon from './items/isbackon'
import fieldBpalg from './items/bpalg'
import fieldMsync from './items/msync'
import fieldAutoupdate from './items/autoupdate'
import fieldMute from './items/mute'
import fieldFtpactive from './items/ftpactive'
import fieldDymmyad from './items/dummyad'
import SelectTZ from '../../../SelectTZ'
import fieldNtpserver from './items/ntpserver'
import fieldWarntraffic from './items/warntraffic'
import fieldMaxtraffic from './items/maxtraffic'
import ItemSave from './items/save'
import Select from "../../../Form/Select";
import fieldVsip from "../ObjectObject/items/vsip";
import fieldEmail from "../ObjectObject/items/email";
import fieldNotifydate from "../ObjectObject/items/notifydate";
import { connect } from 'react-redux'


const SettingsObjectObject = ({
    className = "",
    objects,
    settings = {},
    stateKey,
    loadSettings,
    saveSettings,
    groupsList = [],
    statusList = [],
    selectedObjects = [],
    selectedGroups = [],
    updateOptions,
    user
}) => {

    className = cl(
        className,
        "SettingsObjectObject"
    )

    useEffect(() => {
        loadSettings({
            type: OBJECT_OBJECT,
            stateKey,
            fields,
        })
    }, [stateKey, objects])

    let validMap
    [settings, validMap] = prepareSettings({
        type: OBJECT_OBJECT,
        settings,
        validateMap,
    })

    updateOptions = carryUpdate({
        type: OBJECT_OBJECT,
        stateKey,
        updateOptions
    })

    const hChange = (name, handlerAction) => updateHelper({
        name,
        updateOptions,
        handlerAction,
        handlerType: OBJECT_OBJECT,
    })

    return (
        <Builder
            className={className}
            groups={{
                all: [
                    // Group
                    settings.streamlink && {
                        data: {
                            columns: 1,
                            disabled: selectedGroups.length > 0,
                            className: setsex(["col-1", "mw-items-300"]) + setsmob(["col-1"])
                        },
                        items: [
                            {
                                title: 'Адрес потока',
                                components: [{
                                    type: "InputText",
                                    data: {
                                        attrs: {
                                            value: settings['streamlink'],
                                            placeholder: "Адрес потока",
                                            readOnly: true
                                        },
                                    }
                                }]
                            }
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            disabled: selectedGroups.length > 0,
                            className: setsex(["col-1", "mw-items-300"]) + setsmob(["col-1"])
                        },
                        items: [
                            {
                                title: "Принадлежит к группе",
                                components: fieldMsgroupId({
                                    settings,
                                    name: "msgroup_id",
                                    groups: [{ title: 'Не привязывать', value: null }, ...groupsList],
                                    onChange: hChange("msgroup_id"),
                                })
                            },
                            <GroupItem title="Статус">
                                <Select
                                    options={statusList}
                                    name={"msstatus_id"}
                                    selected={settings["msstatus_id"]}
                                    onChange={hChange("msstatus_id")}
                                />
                            </GroupItem>
                            // {
                            //     title: "Статус",
                            //     components: fieldMsgroupId({
                            //         settings,
                            //         name: "msstatus_id",
                            //         groups: [...statusList],
                            //         onChange: hChange("msstatus_id"),
                            //     })
                            // },
                        ]
                    },

                    // Group
                    <SettingsTags
                        className="setsex-col-1"
                        value={settings["tags"]}
                        onChange={hChange("tags")}
                    />,

                    // Group
                    {
                        data: {
                            className: setsex(["col-2", "no-divider", "mr"]) + setsmob(["col-1"]),
                            disabled: selectedObjects.length > 1,
                        },
                        items: [
                            {
                                title: "Название объекта",
                                components: fieldName({
                                    settings,
                                    name: "name",
                                    onChange: hChange("name")
                                })
                            },
                            {
                                title: "Псевдоним",
                                components: fieldAlias({
                                    settings,
                                    name: "alias",
                                    onChange: hChange("alias"),
                                })
                            }
                        ]
                    },

                    // Group
                    {
                        data: {
                            noDivider: true,
                            className: setsex(["col-2"]),
                            disabled: selectedObjects.length > 1,
                        },
                        items: [
                            {
                                title: "Адрес объекта",
                                components: fieldAddress({
                                    settings,
                                    name: "address",
                                    onChange: hChange("address")
                                })
                            }
                        ]
                    },

                    // Group
                    {
                        data: {
                            className: setsex(["col-2", "mr", "no-divider", "divider-pb", "divider-bb"]),
                            disabled: selectedObjects.length > 1,
                        },
                        items: [
                            {
                                title: "Описание",
                                components: fieldDescription({
                                    settings,
                                    name: "description",
                                    onChange: hChange("description")
                                })
                            }
                        ]
                    },

                    // Group
                    {
                        data: {
                            className: setsex(["col-2", "no-divider", "divider-pb"]),
                            disabled: selectedObjects.length > 1,
                        },
                        items: [
                            <SettingsChannel
                                channel={settings["chmask"]}
                                onChange={hChange("chmask")}
                                userRole={user?.role}
                                titles={{
                                    label: "Рабочий канал",
                                    2: <>Помещение <span>(реклама и музыка)</span></>,
                                    1: <>Улица <span>(только реклама)</span></>,
                                }}
                            />
                        ]
                    },
                    // {
                    //     data: {
                    //         columns: 1,
                    //         className: setsex(["col-1", "mr"]),
                    //     },
                    //     items: [
                    //         {
                    //             title: "Выключить громкость на объекте",
                    //             components: fieldMute({
                    //                 settings,
                    //                 name: "mute",
                    //                 onChange: hChange("mute")
                    //             })
                    //         },
                    //     ]
                    // },
                    {
                        data: {
                            columns: 1,
                            className: setsex(["col-1", "mr"]),
                        },
                        items: [
                            {
                                title: "Громкость экстренных роликов",
                                // disabled: multiple,
                                components: fieldExtravolume({
                                    settings,
                                    name: "extravolume",
                                    onChange: hChange("extravolume"),
                                    placeholder: "Введите громкость"
                                })
                            },
                        ]
                    },
                    // Group
                    {
                        data: {
                            columns: 2,
                            className: setsex(["col-1", "mr"]),
                        },
                        items: [
                            {
                                title: "Чувствительность микрофонного входа",
                                components: fieldMicvol({
                                    settings,
                                    name: "micvol",
                                    onChange: hChange("micvol")
                                })
                            },
                            {
                                title: "Чувствительность линейного входа",
                                components: fieldLinevol({
                                    settings,
                                    name: "linevol",
                                    onChange: hChange("linevol")
                                })
                            }
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            alignHeight: true,
                            noDivider: true,
                            className: setsex(["col-2", "mr"]) + setsmob(["col-1"]),
                        },
                        items: [
                            {
                                title: <>{"Музыка "}<br />{"выключена"}</>,
                                components: fieldIsbackon({
                                    settings,
                                    name: "isbackon",
                                    onChange: hChange("isbackon")
                                })
                            },
                            {
                                title: "Проигрывать музыку по очереди из каждого списка",
                                components: fieldBpalg({
                                    settings,
                                    name: "bpalg",
                                    onChange: hChange("bpalg")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            alignHeight: true,
                            noDivider: true,
                            className: setsex(["col-2"]) + setsmob(["col-1"]),
                        },
                        items: [
                            {
                                title: "Синхронизировать музыку",
                                subtitle: "* Внимание, возможен большой трафик!",
                                components: fieldMsync({
                                    settings,
                                    name: "msync",
                                    onChange: hChange("msync")
                                })
                            },
                            {
                                title: "Разрешить автоматическое обновление ПО",
                                components: fieldAutoupdate({
                                    settings,
                                    name: "autoupdate",
                                    onChange: hChange("autoupdate")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            className: setsex(["col-1"]) + setsmob(["col-1"]),
                        },
                        items: [
                            {
                                title: "Активный режим работы с FTP",
                                subtitle: "* Используйте с осторожностью",
                                components: fieldFtpactive({
                                    settings,
                                    name: "ftpactive",
                                    onChange: hChange("ftpactive")
                                })
                            },
                            {
                                title: "Случайный порядок воспроизведения контента в видеосписке",
                                components: fieldFtpactive({
                                    settings,
                                    name: "videorandom",
                                    onChange: hChange("videorandom")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            className: setsex(["col-1"]) + setsmob(["col-1"]),
                        },
                        items: [
                            {
                                title: "ФОНОВОЕ ВИДЕО ОТКЛЮЧАТЬ ПРИ ВЫХОДЕ РЕКЛАМНОГО БЛОКА",
                                //subtitle: "* Используйте с осторожностью",
                                components: fieldFtpactive({
                                    settings,
                                    name: "waitbgvideo",
                                    onChange: hChange("waitbgvideo")
                                })
                            },
                            {
                                title: "Выключить громкость на объекте",
                                components: fieldMute({
                                    settings,
                                    name: "mute",
                                    onChange: hChange("mute")
                                })
                            }

                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 1,
                            className: setsex(["col-1"]) + setsmob(["col-1"]),
                        },
                        items: [
                            <Form.Group>
                                <Form.Label>Алгоритм паузы (только аудиосервер)</Form.Label>
                                <Form.Check
                                    checked={settings["pausealg"] === 0}
                                    name='pausealg'
                                    value={0}
                                    type='radio'
                                    id='pausealg-0'
                                    label='После рекламы начинать новый трек'
                                    onClick={(e) => hChange("pausealg")({ value: Number(e.target.value) })}
                                />
                                <Form.Check
                                    checked={settings["pausealg"] === 1}
                                    name='pausealg'
                                    value={1}
                                    type='radio'
                                    id='pausealg-1'
                                    label='Во время рекламы ставить трек на паузу'
                                    onClick={(e) => hChange("pausealg")({ value: Number(e.target.value) })}
                                />
                            </Form.Group>
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 1,
                            className: setsex(["col-1"]) + setsmob(["col-1"]) + " mb-5",
                        },
                        items: [
                            {
                                title: "Количество треков перед рекламой без расписания (только для аудио)",
                                components: fieldDymmyad({
                                    settings,
                                    name: "dummyad",
                                    onChange: hChange("dummyad")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            title: 'Синхронная трансляция видео на нескольких экранах в пределах коммутатора',
                            columns: 1,
                            className: "mt-",
                        },
                        items: [
                            {
                                title: "Максимум рассинхронизации, мс (рекомендуется 80)",
                                components: fieldDymmyad({
                                    settings,
                                    name: "vsdiff",
                                    onChange: hChange("vsdiff")
                                })
                            },
                        ]
                    },

                    {
                        data: {
                            columns: 1,
                            className: setsex(["col-1"]) + setsmob(["col-1"]),
                        },
                        items: [
                            {
                                title: "Слушать команды на адресе (при пустом адресе используется 224.1.0.1)",
                                subtitle: "Диапазон: 224.1.0.0 - 239.255.255.255 (Диапазон групповой рассылки)",
                                components: fieldVsip({
                                    settings,
                                    name: "vsip",
                                    onChange: hChange("vsip")
                                })
                            },
                        ]
                    },

                    {
                        data: {
                            columns: 1,
                            className: setsex(["col-1"]) + setsmob(["col-1"]),
                        },
                        items: [
                            {
                                title: "Базовый порт (при пустом используется 6666)",
                                subtitle: "Диапазон: 1025 - 65535 (непривилегированные порты)",
                                components: fieldDymmyad({
                                    settings,
                                    name: "vsbaseport",
                                    onChange: hChange("vsbaseport")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            title: 'Предупреждение о сбое связи',
                            subtitle: 'Если на объекте не указан статус',
                            columns: 2,
                            className: "",
                        },
                        items: [
                            {
                                title: "Email адрес для уведомлений 1",
                                components: fieldEmail({
                                    settings,
                                    name: "notifyto1",
                                    onChange: hChange("notifyto1")
                                })
                            },
                            {
                                title: "Email адрес для уведомлений 2",
                                components: fieldEmail({
                                    settings,
                                    name: "notifyto2",
                                    onChange: hChange("notifyto2")
                                })
                            },
                            {
                                title: "Не уведомлять до",
                                components: fieldNotifydate({
                                    settings,
                                    name: "notifyblock",
                                    onChange: (value) => value && hChange("notifyblock")({ name: 'notifyblock', value })
                                })
                            },
                        ]
                    },

                    {
                        data: {
                            columns: 1,
                            className: "",
                        },
                        items: [

                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            alignHeight: true,
                            noDivider: true,
                            className: setsex(["col-2", "mr"]) + setsmob(["col-1"])
                        },
                        items: [
                            <GroupItem title="Часовой пояс">
                                <SelectTZ
                                    name={"tz"}
                                    value={settings["tz"]}
                                    onChange={hChange("tz")}
                                />
                            </GroupItem>,
                            {
                                title: "Сервер синхронизации времени (NTP)",
                                components: fieldNtpserver({
                                    settings,
                                    name: "ntpserver",
                                    onChange: hChange("ntpserver")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            alignHeight: true,
                            noDivider: true,
                            className: setsex(["col-2"]) + setsmob(["col-1"]),
                        },
                        items: [
                            {
                                title: "Трафик в мегабайтах при превышении которого предупреждать",
                                components: fieldWarntraffic({
                                    settings,
                                    name: "warntraffic",
                                    onChange: hChange("warntraffic")
                                })
                            },
                            {
                                title: "Трафик в мегабайтах при превышении которого не синхронизировать музыку",
                                components: fieldMaxtraffic({
                                    settings,
                                    name: "maxtraffic",
                                    onChange: hChange("maxtraffic")
                                })
                            }
                        ]
                    },

                    // Save
                    {
                        data: {
                            className: setsex(["col-2-nowrap"])
                        },
                        items: [
                            <ItemSave
                                settings={settings}
                                stateKey={stateKey}
                                fields={fields}
                                saveSettings={saveSettings}
                                disabled={!validMap.validResult}
                            />
                        ]
                    }
                ],
            }}
        />
    )
}

const stateToProps = state => ({
    user: state.login.user
})


export default connect(stateToProps, {})(SettingsObjectObject)


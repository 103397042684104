import { LOADING, LOAD_RUBRICS, PUT_RUBRICS, UPDATE_FILTER_PARAMS } from './actions'

const initialState = {
    loading: false,
    rubrics: "",
    playlist: "",
    filter_params: {
        // genre_name: [],
        // songauthor_name: [],
        // tempo: []
    }
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case LOAD_RUBRICS:
            return {
                ...state
            }
        case PUT_RUBRICS:
            return {
                ...state,
                rubrics: payload.rubrics,
                playlist: payload.playlist
            }
        case UPDATE_FILTER_PARAMS:
            return {
                ...state,
                filter_params: payload
            }
    }
    return state
}

export default reducer
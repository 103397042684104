import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button as ButtonBS } from 'react-bootstrap'

import Header from '../components/header'

import InputText from '../../Form/InputText'
import Select from '../../Form/Select'
import Empty from '../../Space/Empty'
import SelectTZ from '../../SelectTZ'


let inputValue = ""


const ManyInputs = ({ className = "", title, inputs, select, buttons, toggleOpened }) => {

    return (
        <div
            className={ `ModalOneInput ${className}` }
            onKeyDown={ keyClose }
        >
            <Header
                title={ title }
                toggleOpened={ toggleOpened }
            />
            { inputs.map(input => {
                return (
                    <div style={ { marginTop: 30 } }>
                        <InputText
                            label={ input?.label }
                            onChange={ hChangeSaveValue }
                            attrs={ {
                                ...input?.attrs
                            } }
                        />
                    </div>
                )
            })
            }
            { select &&
                <div style={ { marginTop: 30 } }>
                    <Select
                        label={ select?.label }
                        type="options"
                        placeholder={ select?.attrs.placeholder }
                        options={ select?.attrs.options }
                    />
                </div>
            }

            <div className="Modal__footer">

                { (buttons && buttons.prim && buttons.forth)
                    ? <div className="spp-btns">
                        <ButtonBS variant="prim" onClick={ hClickButtonPrim } { ...buttons.prim.attrs }>
                            { buttons.prim.title }
                        </ButtonBS>
                        <ButtonBS variant="forth" onClick={ hClickButtonForth } { ...buttons.forth.attrs }>
                            { buttons.forth.title }
                        </ButtonBS>
                    </div>

                    : (buttons && buttons.prim)
                        ? <ButtonBS variant="prim" onClick={ hClickButtonPrim } { ...buttons.prim.attrs }>
                            { buttons.prim.title }
                        </ButtonBS>

                        : (buttons && buttons.forth)
                            ? <ButtonBS variant="forth" onClick={ hClickButtonForth } { ...buttons.forth.attrs }>
                                { buttons.forth.title }
                            </ButtonBS>

                            : ""
                }
            </div>
        </div>
    )

    function hChangeSaveValue({ value }) {
        inputValue = value
    }

    function hClickButtonPrim() {
        toggleOpened()
        if (buttons && buttons.prim && typeof buttons.prim.onClick === "function") {
            buttons.prim.onClick(inputValue)
        }
    }

    function hClickButtonForth() {
        toggleOpened()
        if (buttons && buttons.forth && typeof buttons.forth.onClick === "function") {
            buttons.forth.onClick(inputValue)
        }
    }

    function keyClose(e) {
        if (e.key === "Enter") hClickButtonPrim()
    }
}

ManyInputs.propTypes = {
    title: PropTypes.string,
    inputs: PropTypes.exact([{
        label: PropTypes.string,
        attrs: PropTypes.object
    }]),
    select: PropTypes.exact({
        label: PropTypes.string,
        attrs: PropTypes.object
    }),
    buttons: PropTypes.exact({
        prim: PropTypes.exact({
            title: PropTypes.string,
            onClick: PropTypes.func,
            attrs: PropTypes.object,
        }),
        forth: PropTypes.exact({
            title: PropTypes.string,
            onClick: PropTypes.func,
            attrs: PropTypes.object,
        }),
    }),
}

export default ManyInputs
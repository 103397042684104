import { takeEvery, call, put } from 'redux-saga/effects'
import {UPDATE_ACCOUNT, loading} from '../../store/Roles/actions'
import {showAlertError, showAlertSuccess} from '../../store/Alert/actions'
import axios from 'axios'
import {didMount} from "../../store/App/actions";


export default function* () {
    yield takeEvery(UPDATE_ACCOUNT, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        const response = yield call(fetchUpdateAccount, { ...payload })
        if (response.error && response.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_ACCOUNT}.`, response],
                text: "Пользователь не был обновлен. " + response.error
            }))
        } else {
            yield put(didMount())
            yield put(showAlertSuccess({
                text: "Сохранено", ms: 2000
            }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_ACCOUNT}.`, e],
            text: "Пользователь не был обновлен. " + e.message
        }))
    }
    finally {

    }
}

function fetchUpdateAccount(data) {
    let formData = new FormData()
    for (let name in data) {
        formData.set(name, data[name])
    }
    return axios({
        url: "/account/update",
        method: "POST",
        data: formData
    })
        .then(response => response.data)
}

import React from 'react'
import { cl } from '../../../../../../utilities/cl'

const HelpMenuItem = ({
    title,
    index,
    selected,
    markItems
}) => {
    const className = cl(
        "selectable-item",
        { "selected": selected.indexOf(index) !== -1 },
        { "highlighted": markItems.indexOf(index) !== -1 },
    )
    return (
        <div className={ `HelpMenuItem ${className}` } data-help-id={ index }>
            <span>{ title }</span>
        </div>
    )
}


export default HelpMenuItem
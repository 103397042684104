import {takeEvery, select} from 'redux-saga/effects'
import {OPEN_MODAL, CLOSE_MODAL} from '../../store/Modal/actions'

export default function* () {
    yield takeEvery(OPEN_MODAL, worker)
    yield takeEvery(CLOSE_MODAL, worker)
}

function* worker() {
    const html   = document.querySelector("html")
    const styles = html.getAttribute("style") || ""
    const modals = yield select(state => state.modal.types)

    let opened = false
    for(let type in modals){
        if(modals[type].opened === true) opened = true
    }

    let nextStyles = styles.replace(/;overflow-y:hidden;/g, "")
    if(opened) {
        nextStyles = `${nextStyles};overflow-y:hidden;`
    }

    html.setAttribute("style", nextStyles)
}
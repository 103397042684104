export default function({
    settings = {},
    name,
    onChange
}) {

    return [
        {
            type: "InputText",
            data: {
                onChange,
                attrs: {
                    placeholder: "Введите логин",
                    value: settings[name],
                }
            }
        }
    ]
}
import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Navbar as NavbarBS, Nav } from 'react-bootstrap'

import { cl } from '../../utilities/cl'

import User from './User'
import ObjectsMenu from '../ObjectsMenu'
import NavbarCollapse from '../NavbarCollapse'

import iconLogo from '../../assets/icons/logo.png'
import SvgLogOut from '../SVG/LogOut'
import SvgServerSearch from '../SVG/ServerSearch'
import { useHistory } from 'react-router-dom';


const Navbar = ({ className = "", links }) => {
    let path = window.location.href.split("/").pop()
    className = cl(
        className,
        "Navbar",
        "spp-container-p",

    )
    let history = useHistory()
    const handleLogout = () => {
        localStorage.sessionToken = "";
        localStorage.removeItem('master');
        //localStorage.token = "";
        history.push('/login')
    }
    return (
        <NavbarBS className={ className } bg="light" variant="light">

            <NavbarBS.Brand href="/">
                <img className="spp-h-md" src={ iconLogo } title="Сервис Поддержки Продвижения" />
            </NavbarBS.Brand>

            <ObjectsMenu />

            <div className="d-flex justify-content-end w-100">
                { path !== "login" &&
                    <div className="Navbar__Menu">
                        { links.map(link =>
                            <NavLink to={ link.href } className="nav-link only_trigger" key={ link.id }>{ link.title }</NavLink>
                        ) }
                    </div>
                }
                <User />
                <NavbarCollapse
                    linksToTop={ links }
                    linksToBottom={ [
                        {
                            id: 14,
                            title: < ><SvgLogOut className="Navbar__SvgLogOut" size="sm" onClick={ () => handleLogout() } variant="dark" title="Выйти" />Выйти</>,
                            href: "#",
                        }
                    ] }
                />
            </div>

        </NavbarBS>
    )
}

Navbar.propTypes = {
    className: PropTypes.string,
    links: PropTypes.array
}

export default Navbar
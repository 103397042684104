import React from 'react'


const SettingsWarning = ({
    message = ""
}) => {

    if(!message.length) return null

    return (
        <div className="Settings__warning">
            {message}
        </div>
    )
}

export default SettingsWarning
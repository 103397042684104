import axios from "axios"
export function download(links) {
    if (typeof links === "string" || Array.isArray(links)) {
        if (typeof links === "string") {
            links = [links]
        }

        const div = document.createElement("div")
        let aList = [], a

        links.map(link => {
            a = document.createElement("a")
            a.href = link
            // a.setAttribute("download", "filename.mp3")
            a.download = true
            document.body.append(a)
            a.click()
            // aList.push(a)
            setTimeout(() => a.remove(), 500)

        })
        console.log(links)
        // aList.map(a => div.append(a))
        // aList.map(a => window.open(a.href))
        // document.body.append(div)

        // aList.map(a => a.click())
        // setTimeout(() => div.remove(), 5000)

    }


}
const validateMap = {
    fromdate: {
        type: "string",
        min: 1,
    },
    channel: {
        type: "number",
        min: 1,
    },

    block: {
        required: true,
        type: "string",
        func: (val) => (val !== "" && val !== "0")
    },
    __inhour: {
        //required: true,
        type: "number",
        //min: 1,
    },
    steptime: {
        required: true,
        type: "number",
        min: 1
    },
    
    daymask: {
        type: "number",
        min: 1,
    },
    __inday: {
        required: true,
        type: "bool",
        func: (val) => val === true
    },

    validGroups: {
        time: ["block", "__inhour", "steptime"],
        days: ["__inday", "daymask"],
    },

    // pri:{
    //     type: "string",
    //     func: (val) => {
    //         const regex = /^[+-]\d+$/;
    //         return regex.test(val);
    //     }
    // }

    
}


export default validateMap
export const LOAD_USED_LIST                   = "Settings/BackgroundLists/LOAD_USED_LIST"
export const PUT_USED_LIST                    = "Settings/BackgroundLists/PUT_USED_LIST"
export const LOAD_AVAIL_LIST                  = "Settings/BackgroundLists/LOAD_AVAIL_LIST"
export const PUT_AVAIL_LIST                   = "Settings/BackgroundLists/PUT_AVAIL_LIST"
export const ADD_TO_USED                      = "Settings/BackgroundLists/ADD_TO_USED"
export const DELETE_USED_ITEMS                = "Settings/BackgroundLists/DELETE_USED_ITEMS"
export const MODE                             = "Settings/BackgroundLists/MODE"
export const TOGGLE_SELECTED_ITEMS            = "Settings/BackgroundLists/TOGGLE_SELECTED_ITEMS"

export const MOVE_USED_ITEM_TO                = "Settings/BackgroundLists/MOVE_USED_ITEM_TO"
export const PUT_MOVE_USED_ITEM_TO            = "Settings/BackgroundLists/PUT_MOVE_USED_ITEM_TO"
export const SAVE_USED_ITEM_MOVE_CHANGING     = "Settings/BackgroundLists/SAVE_USED_ITEM_MOVE_CHANGING"
export const SAVE_USED_ITEMS_MOVING           = "Settings/BackgroundLists/SAVE_USED_ITEMS_MOVING"
// export const PUT_SAVE_USED_ITEM_MOVE_CHANGING = "Settings/BackgroundLists/PUT_SAVE_USED_ITEM_MOVE_CHANGING"

export const loadUsedList = ({ timelineId, required }) => ({
    type: LOAD_USED_LIST,
    payload: {
        stateKey: timelineId,
        timelineId,
        required,
    }
})

export const putUsedList = ({ stateKey, list, usedOrder }) => ({
    type: PUT_USED_LIST,
    payload: {
        stateKey,
        list,
        usedOrder
    }
})

export const loadAvailList = (obj = {}) => ({
    type: LOAD_AVAIL_LIST,
    payload: {
        required: obj.required,
    }
})

export const putAvailList = ({ playList, videoList, assocPlayList }) => ({
    type: PUT_AVAIL_LIST,
    payload: {
        playList,
        videoList,
        assocPlayList,
    }
})

export const addToUsed = ({ timelineId }) => ({
    type: ADD_TO_USED,
    payload: {
        timelineId,
        stateKey: timelineId,
    }
})

export const deleteUsedItems = ({ timelineId }) => ({
    type: DELETE_USED_ITEMS,
    payload: {
        timelineId,
        stateKey: timelineId,
    }
})

export const mode = ({ stateKey, modeType, mode }) => ({
    type: MODE,
    payload: {
        stateKey,
        modeType,
        mode,
    }
})

export const toggleSelectedItems = ({ stateKey, selected }) => ({
    type: TOGGLE_SELECTED_ITEMS,
    payload: {
        stateKey,
        selected,
    }
})

export const moveUsedItemTo = ({ timelineId, usedId, to }) => ({
    type: MOVE_USED_ITEM_TO,
    payload: {
        timelineId,
        stateKey: timelineId,
        usedId,
        to,
    }
})

export const putMoveUsedItemTo = ({ stateKey, list, usedOrder }) => ({
    type: PUT_MOVE_USED_ITEM_TO,
    payload: {
        stateKey,
        list,
        usedOrder,
    }
})

export const saveUsedItemMoveChanging = ({ stateKey, usedId, order }) => ({
    type: SAVE_USED_ITEM_MOVE_CHANGING,
    payload: {
        stateKey,
        usedId,
        order,
    }
})

export const saveUsedItemsMoving = ({ timelineId }) => ({
    type: SAVE_USED_ITEMS_MOVING,
    payload: {
        timelineId,
        stateKey: timelineId,
    }
})

// export const putSaveUsedItemMoveChanging = ({  }) => ({
//     type: PUT_SAVE_USED_ITEM_MOVE_CHANGING,
//     payload: {
        
//     }
// })
import React from 'react'


const MoveUp = ({ title = 'Переместить вверх', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.645 16.207">
                <title>{ title }</title>
                <path d="M5.316.201a.5.5 0 00-.412.217l-4.55 4.55a.5.5 0 10.707.708l3.761-3.762v13.793a.5.5 0 101 0V1.914l3.762 3.762a.5.5 0 10.707-.707L5.727.404A.5.5 0 005.512.24a.5.5 0 00-.096-.03A.5.5 0 005.41.21a.5.5 0 00-.043-.006.5.5 0 00-.008 0 .5.5 0 00-.043-.002z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" data-name="Group 5813" /></svg>
        </i>
    )
}

export default MoveUp
import { SAVE_PV_DATA } from "./actions";

const initialState = {
    mute: false,
    note: ''
}


const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SAVE_PV_DATA:
            return { ...state, ...payload }

        default:
            return state;
    }
}

export default reducer
import moment from 'moment'
import { takeEvery, call, put, all } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_PUBLIC_PLAYLISTS, loading, putPublicPlaylists } from '../../store/PublicPlaylists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_PUBLIC_PLAYLISTS, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        let public_playlists = [],
            songsAssoc = {}
        const fetchedPublicPlaylists = yield call(fetchPublicPlaylists)

        if (fetchedPublicPlaylists.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_PUBLIC_PLAYLISTS}.`, fetchedPublicPlaylists],
                text: "Ролики не были загружены. " + fetchedPublicPlaylists.error
            }))
            yield put(putPublicPlaylists({ public_playlists }))
        } else {
            public_playlists = fetchedPublicPlaylists.success.map(el => {
                el["songs"] = []
                let totalsize2 = 0;
                if (el.totalsize) {
                    let size = Math.floor(el.totalsize / 1024 / 1024);
                    size = !size ? `${Math.floor(el.totalsize / 1024 )} Kb` : `${size} Mb`
                    totalsize2 = size
                }
                el.totalsize2 = totalsize2
                return el
            })
            yield put(putPublicPlaylists({ public_playlists }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_PUBLIC_PLAYLISTS}.`, e],
            text: "Плейлисты не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putPublicPlaylists({ public_playlists: [] }))
    }

    finally {
        yield put(loading(false))
    }
}

function fetchPublicPlaylists() {

    return axios.get("/playlist/select", {
        params: {
            all: true
        }
    })
        // return fetch("http://a.ms-box.ru/playlist/select?public=true", { method: 'GET', credentials: "same-origin", mode: "no-cors", referrerPolicy: "unsafe-url", headers: { 'token': `${token}` } })
        .then(response => response.data)
}

function fetchSongs(playlistId) {
    return axios.get("/playlist/tracks/select", {
        params: {
            id: playlistId,
            limit: 50,
            offset: 1
        }
    })
        .then(response => response.data)
}
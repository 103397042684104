import React from 'react'
import {connect} from 'react-redux'

import {loadSettings, saveSettings} from '../../../../store/Settings/actions/sets/Object'
import {updateOptions} from '../../../../store/Settings/actions'
import {OBJECT_NET} from '../../../../store/Settings/sets'

import ObjectNet from './ObjectNet'


const ObjectNetContainer = props => (
    <ObjectNet
        className={props.className}
        settings={props.settings}
        stateKey={props.stateKey}
        loadSettings={props.loadSettings}
        updateOptions={props.updateOptions}
        saveSettings={props.saveSettings}
    />
)

const stateToProps = (state, { stateKey }) => {
    let settings = state.settings.settings[OBJECT_NET] || {}
    return {
        settings: {...settings[stateKey]},
    }
}

const actionsToProps = {
    loadSettings,
    updateOptions,
    saveSettings,
}

export default connect(stateToProps, actionsToProps)(ObjectNetContainer)
import moment from 'moment'

export function compareDate(comparable = {a: null, b: null}) {
    if(comparable.a !== null && comparable.a.length && comparable.b !== null && comparable.b.length) {
        const a = Number(moment(comparable.a, 'YYYY-MM-DD').format("x"))
        const b = Number(moment(comparable.b, 'YYYY-MM-DD').format("x"))
        if(a > b) return "more"
        if(a < b) return "less"
        if(a === b) return "equal"
    }
}
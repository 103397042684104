import React from 'react'

import {cl} from '../../../../utilities/cl'
import {componentDisabled} from '../../../../utilities/componentDisabled'


const SettingsGroup = ({
    className = "",
    title,
    typeTitle,
    comment, subtitle,
    wrap,
    alignHeight,
    noDivider,
    columns,
    children,
    disabled,
}) => {
    
    className = cl(
        className,
        {"SettingsGroup": [
            {"title": () => (title || typeTitle) ? true : false},
            {"wrap": wrap},
            {"align-height": alignHeight},
            {"not-divider": noDivider},
            {[`columns-${columns}`]: columns},
        ]},
        {"disabled": disabled}
    )

    return(
        <section className={className} onClickCapture={(e) => componentDisabled(e, disabled)}>

            {(title || typeTitle) &&
                <div className="SettingsGroup__title">
                    {title
                        ? <span className="title">
                            {title}
                        </span>
                        : <span className="type-title">
                            {typeTitle}
                        </span>
                    }
                    {subtitle &&
                    <div className="SettingsGroup__subtitle">
                        {subtitle}
                    </div>
                    }
                </div>
            }

            <div className="SettingsGroup__items">
                {children}
            </div>
            
            {comment &&
                <div className="SettingsGroup__comment">
                    {comment}
                </div>
            }

        </section>
    )
}

export default SettingsGroup
export default function({
    settings = {},
    name,
    onChange,
}) {

    return [
        {
            type: "CycleTime",
            data: {
                value: settings[name],
                placeholder: "Укажите значение",
                times: [
                    {
                        title: "Значение громкости",
                        from: 0, to: 100,
                        step: 1,
                    },
                ],
                isTime: false,
                format: "0",
                onChange,
            }
        }
    ]
}
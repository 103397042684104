import React, { useState, useEffect } from 'react'
import { Accordion, Collapse } from 'react-bootstrap'
import { connect } from 'react-redux'
import { cl } from '../../../utilities/cl'
import CollapsiblePlaylist from '../../CollapsiblePlaylist'
import ScrollContainer from '../../ScrollContainer'
import Collapsible from '../../Space/Collapsible/Collapsible'
import SvgArrowDownLight from '../../SVG/ArrowDownLight'
import { loadAssocPublicTracks } from '../../../store/PublicPlaylists/actions'
import { isFunc } from '../../../utilities/isFunc'
import Song from '../PublicSong'
import SvgAudio from '../../SVG/Audio'
import Spinner from '../../Spinner'


const PlaylistItem = ({
    id,
    name,
    songs,
    selected,
    selected_public,
    markItems,
    variant,
    onClick,
    onOpen,
    totalsize,
    totaltime,
    loadAssocPublicTracks,
    load_assoc
}) => {
    const [opened, setOpened] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [trackPlaying, setTrackPlaying] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isWatching, setIsWatching] = useState(false)
    const [audio, setAudio] = useState(new Audio())
    const [lastClickedPlayId, setLastClickedPlayId] = useState(-1)
    // const [songs, setSongs] = useState([])
    useEffect(() => {
        if (lastClickedPlayId === trackPlaying) {
            audio.addEventListener("canplaythrough", audio.play)
        }
    }, [audio])
    const className = cl(
        // "Playlists__item",
        "selectable-item",
        { "Playlist": [variant] },
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
        { "opened": opened },
    )
    useEffect(() => {
        if (opened) {
            loadAssocPublicTracks({ offset: 0, current: Number(id) })
        }
    }, [opened])
    let songs_arr = []
    if (songs.length > 0 || Object.values(songs).length > 0) {
        songs_arr = Object.values(songs)

    }

    let songs_size = 0
    let songs_duration = ""
    let songs_hour = 0
    let songs_minutes = 0
    let songs_seconds = 0
    let hours = 0
    let minutes = 0
    let songs_count = songs_arr.length
    songs_arr.map(song => songs_size += song.size)
    songs_size = `${Math.round(songs_size / 1024 / 1024)} Mb`
    songs_arr.map(song => {
        let duration_arr = song.cliptime.split(".")[0].split(":")
        songs_hour += Number(duration_arr[0])
        songs_minutes += Number(duration_arr[1])
        songs_seconds += Number(duration_arr[2])
    })
    if (songs_seconds % 60 >= 0) {
        minutes = Math.floor(songs_seconds / 60)
        songs_minutes += minutes
        songs_seconds = songs_seconds % 60
    }
    if (songs_minutes % 60 >= 0) {
        hours = Math.floor(songs_minutes / 60)
        songs_hour += hours
        songs_minutes = songs_minutes % 60
    }
    songs_duration = `${songs_hour}:${songs_minutes}:${songs_seconds}`

    const hClick = (event) => {
        if (isFunc(onClick)) {
            if (!event.target.closest(".__CollapsibleCard__buttons"))
                onClick({ id, name, name, selected }, event)
        }
    }
    const hOpen = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setOpened(!opened)
        setLoaded(true)
        if (isFunc(onOpen)) {
            onOpen({ id, name, name, selected }, event)
        }
    }
    const hPause = () => {
        setIsPlaying(false)
        setIsWatching(false)
        audio.pause()
    }

    const hStop = () => {
        setIsPlaying(false)
        setIsWatching(false)
    }

    const hRewind = (totime) => {
        if (audio.currentTime > 0) {
            audio.currentTime = totime
        }
    }
    const hPlay = ({ trackId, filename }) => {
        if (trackId === trackPlaying) {
            setLastClickedPlayId(trackId)
            audio.play()
            setIsPlaying(true)
            setIsWatching(true)
        } else {
            setLastClickedPlayId(trackId)
            audio.pause()
            setTrackPlaying(trackId)
            setIsPlaying(true)
            setIsWatching(true)
            setAudio(new Audio(filename))
        }
    }
    return (
        <div>
            <div className={ `${className} CollapsibleCardPlaylist` } data-playlist-id={ id } onClick={ hClick }>
                <div className="Playlist__header CollapsibleCardPlaylist__header" >
                    <div className="Playlists__grid CollapsibleCardPlaylist__title">
                        <div className="play">
                            <span>
                                <SvgAudio variant="dark-lg" size="sm" title="Воспроизвести" />
                            </span>
                        </div>
                        <div className="name">
                            { name.length > 25 ? `${name.slice(0, 25)}…` : name }
                        </div>
                        <div className="tracks_count">
                            {/* { songs_count } */ }
                        </div>
                        <div className="size">
                            { Math.floor(totalsize / 1024 / 1024) + ` Mb` }
                        </div>
                        <div className="duration">
                            { totaltime }
                        </div>
                    </div>
                    <div className="__CollapsibleCardPlaylist__buttons" onClick={ hOpen }>
                        <div className="CollapsibleCardPlaylist__open" >
                            <SvgArrowDownLight
                                variant="dark"
                                size="mr"
                                title={ opened ? "Свернуть" : "Развернуть" }
                            />
                        </div>
                    </div>
                </div>
            </div >
            <div className="CollapsibleCardPlaylist__content">
                { (opened)
                    && <ScrollContainer size="md">
                        { songs_arr.length > 0 && !load_assoc ?
                            songs_arr.map((song, index) => {
                                return < Song
                                    key={ song.id }
                                    id={ song.id }
                                    name={ song.name }
                                    turn={ song.turn }
                                    cliptime={ song.cliptime }
                                    // selected={ selected_song }
                                    tempo={ song.tempo }
                                    songauthor_name={ song.songauthor_name }
                                    player_name={ song.player_name }
                                    album_name={ song.album_name }
                                    genre_name={ song.genre_name }
                                    year={ song.year }
                                    variant={ (index % 2 == 0) ? "gray" : "" }
                                    filename={ song.filename }
                                    id_playlist={ id }
                                    audio={ audio }
                                    trackPlaying={ trackPlaying }
                                    isPlaying={ isPlaying }
                                    isWatching={ isWatching }
                                    play={ hPlay }
                                    pause={ hPause }
                                    stop={ hStop }
                                    rewind={ hRewind }

                                />
                            })
                            : <Spinner />
                        }
                    </ScrollContainer>
                }
            </div>
        </div >
    )
}

const stateToProps = state => ({
    selected: state.public_playlists.selected,
    // songs: state.public_playlists.allPublicPlaylists.songs,
    load_assoc: state.public_playlists.load_assoc
})
const actionsToProps = ({
    loadAssocPublicTracks
})
export default connect(stateToProps, actionsToProps)(PlaylistItem)
import {OBJECT_VOLUME} from '../../../sets'

export const LOAD_ITEMS            = `Settings/${OBJECT_VOLUME}/LOAD_ITEMS`
export const PUT_ITEMS             = `Settings/${OBJECT_VOLUME}/PUT_ITEMS`
export const ADD_ITEM              = `Settings/${OBJECT_VOLUME}/ADD_ITEM`
export const CLEAN_BUFFER          = `Settings/${OBJECT_VOLUME}/CLEAN_BUFFER`
export const DELETE_ITEMS          = `Settings/${OBJECT_VOLUME}/DELETE_ITEMS`
export const SAVE_ITEM_SETTINGS    = `Settings/${OBJECT_VOLUME}/SAVE_ITEM_SETTINGS`

export const loadItems = ({ stateKey, required = false }) => ({
    type: LOAD_ITEMS,
    payload: {
        stateKey,
        required,
    }
})

export const putItems = ({ stateKey, items, assocItems }) => ({
    type: PUT_ITEMS,
    payload: {
        type: OBJECT_VOLUME,
        stateKey,
        items,
        assocItems,
    }
})

export const addItem = ({ stateKey }) => ({
    type: ADD_ITEM,
    payload: {
        stateKey
    }
})

export const cleanBuffer = ({ itemStateKey }) => ({
    type: CLEAN_BUFFER,
    payload: {
        type: OBJECT_VOLUME,
        itemStateKey
    }
})

export const deleteItems = ({ stateKey, itemIds }) => ({
    type: DELETE_ITEMS,
    payload: {
        stateKey,
        itemIds
    }
}) 

export const saveItemSettings = ({ stateKey, itemIds = [-1], settings }) => ({
    type: SAVE_ITEM_SETTINGS,
    payload: {
        type: OBJECT_VOLUME,
        stateKey,
        itemIds,
        settings,
    }
})
import React from 'react'

import {mediaMobile} from '../../utilities/media'
import outside from '../../utilities/outside'


const MobileControls = ({
    className = "",
    active,
    children,
}) => {
 
    return(
        mediaMobile(
            outside(
                <div className={`MobileControls ${active ? "active" : ""} ${className}`}>
                    {active && children}
                </div>
            ),
            null
        )
    )
}

export default MobileControls
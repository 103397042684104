export default function({
    settings = {},
    name,
    onChange,
    disabled
}) {

    return [
        {
            type: "Select",
            data: {
                type: "options",
                placeholder: "Выбрать",
                selected: settings[name],
                onChange,
                disabled,
                options: [
                    {title: "Выбрать", value: 0},
                    {title: 12, value: 12},
                    {title: 11, value: 11},
                    {title: 10, value: 10},
                    {title: 9, value: 9},
                    {title: 8, value: 8},
                    {title: 7, value: 7},
                    {title: 6, value: 6},
                    {title: 5, value: 5},
                    {title: 4, value: 4},
                    {title: 3, value: 3},
                    {title: 2, value: 2},
                    {title: 1, value: 1},
                ]
            }
        }
    ]
}
import React from 'react'
import { connect } from 'react-redux'
import Modal from './Modal'
import { closeModal } from '../../store/Modal/actions'


const ModalContainer = props => {
    return (
        <Modal
            className={ props.className }
            type={ props.type }
            data={ props.data }
            size={ props.data.size }
            zIndex={ props.data.zIndex }
            opened={ props.data.opened }
            closeModal={ props.closeModal }
        />
    )
}

const stateToProps = (state, props) => ({
    data: state.modal.types[props.type] || {},
})

const actionsToProps = {
    closeModal
}

export default connect(stateToProps, actionsToProps)(ModalContainer)
import {mapValidObj} from '../../../utilities/valid'
import {handlers, actions} from '../../../utilities/handlers'


export function prepareSettings({
    type = "",
    settings = {},
    validateMap = {},
}) {
    const validMap = mapValidObj(validateMap, settings)
    settings = awaySettings({ type, settings })

    return [settings, validMap]
}

export function awaySettings({ type, settings = {} }) {
    let handed = {}

    for(let name in settings) {
        handed[name] = handlers({
            type,
            name,
            action: actions.away,
            value: settings[name]
        })
    }
    return handed
}
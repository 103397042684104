import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Header from '../components/header'



let inputValue = ""


const SettingsRole = ({
    className = "",
    title,
    buttons,
    toggleOpened,
    saveRights,
    save_rights,
    description
}) => {
    let initial_data = {
        advert: {
            what: 0,
            where: 0,
            when: 0
        },
        object: {
            what: 0,
            where: 0,
            when: 0
        },
        content: {
            what: 0,
            where: 0,
            when: 0
        },
        service: {
            what: 0,
            where: 0,
            when: 0
        }
    }
    const [rights, setRights] = useState(Object.values(save_rights).length > 0 ? save_rights : initial_data)
    //console.log()
    const hCheck = (e, category) => {

        let new_rights = { ...rights }
        if (e.target.value === "change") {
            new_rights[category][e.target.name] !== 2 ? new_rights[category][e.target.name] = 2 : new_rights[category][e.target.name] = 0
        } else if (e.target.value === "read") {
            new_rights[category][e.target.name] !== 1 ? new_rights[category][e.target.name] = 1 : new_rights[category][e.target.name] = 0
        }
        setRights(new_rights)
        saveRights(new_rights)

    }
    return (
        <div
            className={ `ModalOneInput ${className}` }
            onKeyDown={ keyClose }
        >
            <Header
                title={ "Настройка прав доступа" }
                toggleOpened={ toggleOpened }
            />
            <div className='Modal_SettingsRole_description'>
                <span>Выбранная роль: <b>{ description.title }</b></span>
            </div>
            <div className='Modal_SettingsRole_body'>
                <div className='Modal_SettingsRole_body_element'>
                    <div>
                        <h2>Реклама</h2>
                    </div>

                    <div className='Modal_SettingsRole_body_element_check'>
                        <div>ЧТО</div>
                        <Form.Check type="checkbox" label="Чтение" name={ "what" } onChange={ (e) => hCheck(e, "advert") } value={ "read" } id={ 1 } disabled={ false } checked={ rights.advert.what === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "what" } onChange={ (e) => hCheck(e, "advert") } value={ "change" } id={ 2 } disabled={ false } checked={ rights.advert.what === 2 ? true : false } />
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <div>ГДЕ</div>
                        <Form.Check type="checkbox" label="Чтение" name={ "where" } onChange={ (e) => hCheck(e, "advert") } value={ "read" } id={ 3 } disabled={ false } checked={ rights.advert.where === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "where" } onChange={ (e) => hCheck(e, "advert") } value={ "change" } id={ 4 } disabled={ false } checked={ rights.advert.where === 2 ? true : false } />
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <div>КОГДА</div>
                        <Form.Check type="checkbox" label="Чтение" name={ "when" } onChange={ (e) => hCheck(e, "advert") } value={ "read" } id={ 5 } disabled={ false } checked={ rights.advert.when === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "when" } onChange={ (e) => hCheck(e, "advert") } value={ "change" } id={ 6 } disabled={ false } checked={ rights.advert.when === 2 ? true : false } />
                    </div>
                </div>
                <div className='Modal_SettingsRole_body_element'>
                    <div>
                        <h2 >ОБЪЕКТ</h2>
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <div>ЧТО</div>
                        <Form.Check type="checkbox" label="Чтение" name={ "what" } onChange={ (e) => hCheck(e, "object") } value={ "read" } id={ 7 } disabled={ false } checked={ rights.object.what === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "what" } onChange={ (e) => hCheck(e, "object") } value={ "change" } id={ 8 } disabled={ false } checked={ rights.object.what === 2 ? true : false } />
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <div>ГДЕ</div>
                        <Form.Check type="checkbox" label="Чтение" name={ "where" } onChange={ (e) => hCheck(e, "object") } value={ "read" } id={ 9 } disabled={ false } checked={ rights.object.where === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "where" } onChange={ (e) => hCheck(e, "object") } value={ "change" } id={ 10 } disabled={ false } checked={ rights.object.where === 2 ? true : false } />
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <div>КОГДА</div>
                        <Form.Check type="checkbox" label="Чтение" name={ "when" } onChange={ (e) => hCheck(e, "object") } value={ "read" } id={ 11 } disabled={ false } checked={ rights.object.when === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "when" } onChange={ (e) => hCheck(e, "object") } value={ "change" } id={ 12 } disabled={ false } checked={ rights.object.when === 2 ? true : false } />
                    </div>
                </div>
                <div className='Modal_SettingsRole_body_element'>
                    <div>
                        <h2 >ФОНОВЫЙ КОНТЕНТ</h2>
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <span>ЧТО</span>
                        <Form.Check type="checkbox" label="Чтение" name={ "what" } onChange={ (e) => hCheck(e, "content") } value={ "read" } id={ 13 } disabled={ false } checked={ rights.content.what === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "what" } onChange={ (e) => hCheck(e, "content") } value={ "change" } id={ 14 } disabled={ false } checked={ rights.content.what === 2 ? true : false } />
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <span>ГДЕ</span>
                        <Form.Check type="checkbox" label="Чтение" name={ "where" } onChange={ (e) => hCheck(e, "content") } value={ "read" } id={ 15 } disabled={ false } checked={ rights.content.where === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "where" } onChange={ (e) => hCheck(e, "content") } value={ "change" } id={ 16 } disabled={ false } checked={ rights.content.where === 2 ? true : false } />
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <span>КОГДА</span>
                        <Form.Check type="checkbox" label="Чтение" name={ "when" } onChange={ (e) => hCheck(e, "content") } value={ "read" } id={ 17 } disabled={ false } checked={ rights.content.when === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "when" } onChange={ (e) => hCheck(e, "content") } value={ "change" } id={ 18 } disabled={ false } checked={ rights.content.when === 2 ? true : false } />
                    </div>
                </div>
                <div className='Modal_SettingsRole_body_element'>
                    <div>
                        <h2>СЕРВИСНЫЕ СТРАНИЦЫ</h2>
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <span>ЧТО</span>
                        <Form.Check type="checkbox" label="Чтение" name={ "what" } onChange={ (e) => hCheck(e, "service") } value={ "read" } id={ 19 } disabled={ false } checked={ rights.service.what === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "what" } onChange={ (e) => hCheck(e, "service") } value={ "change" } id={ 20 } disabled={ false } checked={ rights.service.what === 2 ? true : false } />
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <span>ГДЕ</span>
                        <Form.Check type="checkbox" label="Чтение" name={ "where" } onChange={ (e) => hCheck(e, "service") } value={ "read" } id={ 21 } disabled={ false } checked={ rights.service.where === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "where" } onChange={ (e) => hCheck(e, "service") } value={ "change" } id={ 22 } disabled={ false } checked={ rights.service.where === 2 ? true : false } />
                    </div>
                    <div className='Modal_SettingsRole_body_element_check'>
                        <span>КОГДА</span>
                        <Form.Check type="checkbox" label="Чтение" name={ "when" } onChange={ (e) => hCheck(e, "service") } value={ "read" } id={ 23 } disabled={ false } checked={ rights.service.when === 1 ? true : false } />
                        <Form.Check type="checkbox" label="Изменение" name={ "when" } onChange={ (e) => hCheck(e, "service") } value={ "change" } id={ 24 } disabled={ false } checked={ rights.service.when === 2 ? true : false } />
                    </div>
                </div>
            </div>

        </div >
    )
    function hClickButtonPrim() {
        toggleOpened()
        // if (buttons && buttons.prim && typeof buttons.prim.onClick === "function") {
        //     buttons.prim.onClick(inputValue)
        // }
    }

    function keyClose(e) {
        if (e.key === "Enter") hClickButtonPrim()
    }
}


export default SettingsRole
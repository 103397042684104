import React from 'react'
import { connect } from 'react-redux'

import { openModal, closeModal } from '../../../../../store/Modal/actions'
import { deleteTracks, downloadTracks } from '../../../../../store/Tracks/actions'

import Collapsible from '../../../../../components/Space/Collapsible'
import Button from '../../../../../components/Button'
import Tracks from '../../../../../components/Tracks'

import SvgFileM from '../../../../../components/SVG/FileM'
import SvgLog from '../../../../../components/SVG/Log'


const VideoTheme = ({
    className = "",
    active,
    placeNumber,
    openModal,
    closeModal,
    selectedTracks = [],
    deleteTracks,
                   title,
    downloadTracks,    
    addVideoToAdvert,
    onChangeCollapsible
}) => {
    //let title = 'Ролики'
    //if (selectedTracks.length > 1) title += ` {<span>${selectedTracks.length}</span>}`

    return (
        <>
            <Collapsible
                active={ active }
                id={ 111 }
                className={ `CollapsibleVideo ${className}` }
                title={<>{title} {selectedTracks.length > 1 ? <small className='badge badge-light'>{selectedTracks.length}</small> : ''}</>}
                placeNumber={ placeNumber }
                onChangeCollapsible={onChangeCollapsible}
                elements={
                    <>

                    </>
                }
                //TODO TASK_24
                buttons={ {
                    add: {
                        on: true,
                        title: "Добавить ролик",
                        onClick: () => addVideoToAdvert(selectedTracks)
                    },
                    // download: {
                    //     on: true,
                    //     disabled: (selectedTracks[0]) ? false : true,
                    //     onClick: () => downloadTracks({ tracksIds: selectedTracks })
                    // },
                } }
            >
                <Tracks theme={true}  showHideEndedButton={false}/>
            </Collapsible>
        </>
    )
}

const stateToProps = state => {
    const themesIds = state.tracks.tracksTheme.map(tr => tr.id)
    return {
        selectedTracks: state.tracks.selected.filter(id => themesIds.includes(id))
    }
}
const actionsToProps = {
    openModal, closeModal, deleteTracks, downloadTracks
}

export default connect(stateToProps, actionsToProps)(VideoTheme)
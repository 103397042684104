import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { SAVE_OBJECTS_GROUP, loadObjectsGroup } from '../../store/ObjectsGroup/actions'
import { showAlertError, showAlertSuccess } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_OBJECTS_GROUP, worker)
}

function* worker({ payload }) {
    const { id, title } = payload

    try {
        const saved = yield call(fetchSaved, id, title)

        // Ошибка
        if (saved.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${SAVE_OBJECTS_GROUP}.`, saved],
                text: "Группа объектов не была сохранена. Попробуйте повторить."
            }))

            // Успех
        } else {
            yield put(showAlertSuccess({
                response: [`Ошибка сервера при ${SAVE_OBJECTS_GROUP}.`, saved],
                text: "Группа объектов успешно сохранена."
            }))
            yield put(loadObjectsGroup())
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сервера при ${SAVE_OBJECTS_GROUP}.`, e],
            text: "Группа объектов не была сохранена. Попробуйте повторить."
        }))
    }
}

function fetchSaved(id, name) {
    let formData = new FormData()
    formData.set("name", name)

    return axios(
        {
            url: `/msgroup/update?id=${id}`,
            method: "POST",
            data: formData,
        }
    )
        .then(response => response.data)
}
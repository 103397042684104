import React from 'react'
import { connect } from 'react-redux'

import { loadTz } from '../../store/SelectTZ/actions'

import SelectTZ from './SelectTZ'


const SelectTZContainer = props => (
    <SelectTZ
        className={props.className}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        tz={props.tz}

        loadTz={props.loadTz}
    />
)

const stateToProps = state => ({
    tz: [...state.selectTz.tz].sort((a, b) => a.title.replace(/\D/g, '') - b.title.replace(/\D/g, ''))
    // tz: state.selectTz.tz
})

const actionsToProps = {
    loadTz
}

export default connect(stateToProps, actionsToProps)(SelectTZContainer)
export const LOAD_CLIP_REPORT = "Reports/Advert/LOAD_CLIP_REPORT"
export const LOADING = "Reports/Advert/LOADING"
export const PUT_CLIP_REPORT = "Reports/Advert/PUT_CLIP_REPORT"
export const SORT_CLIP_REPORT = "Reports/Advert/SORT_CLIP_REPORT"

export const loadClipReport = date => ({
    type: LOAD_CLIP_REPORT,
    payload: date
})
export const loading = mode => ({
    type: LOADING,
    payload: mode
})
export const putClipReport = objects_state => ({
    type: PUT_CLIP_REPORT,
    payload: objects_state
})
export const sortClipReport = sortSource => ({
    type: SORT_CLIP_REPORT,
    payload: sortSource
})
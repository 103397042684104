import React from 'react'

import {cl} from '../../utilities/cl'
import {parseBitmap, maps} from '../../utilities/bitmap'


const StringDays = ({
    className = "",
    daymask = 0
}) => {

    className = cl(
        className,
        "StringDays"
    )

    const days = parseBitmap(daymask, maps.daymask)

    return(
        <div className={className}>
            <div className="StringDays__wrap">
                <div className={days.indexOf(64) !== -1 ? "active" : ""}>Пн</div>
                <div className={days.indexOf(32) !== -1 ? "active" : ""}>Вт</div>
                <div className={days.indexOf(16) !== -1 ? "active" : ""}>Ср</div>
                <div className={days.indexOf(8) !== -1 ? "active" : ""}>Чт</div>
                <div className={days.indexOf(4) !== -1 ? "active" : ""}>Пт</div>
                <div className={days.indexOf(2) !== -1 ? "active" : ""}>Сб</div>
                <div className={days.indexOf(1) !== -1 ? "active" : ""}>Вс</div>
            </div>
        </div>
    )
}

export default StringDays
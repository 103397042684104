import React from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../../utilities/cl'

import Place from '../../../../components/Space/Place'
import SlideMenu from '../../../../components/SlideMenu'
import Empty from '../../../../components/Space/Empty'

import Objects from './Objects'
import ObjectsGroup from './ObjectsGroup'
import Tags from './Tags'


class Where extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            empty: false
        }

        this.setEmptyMode = this.setEmptyMode.bind(this)
    }



    componentDidUpdate() {
        const {
            searchActive,
            searchFounded,
            searchValue
        } = this.props
        if (searchActive) {
            (searchValue === "")
                ? this.setEmptyMode(false)
                : this.setEmptyMode(searchFounded.length === 0)
        } else {
            this.setEmptyMode(false)
        }
    }

    setEmptyMode(mode) {
        if (!mode) {
            this.setState(state => {
                if (state.empty) {
                    return { empty: false }
                }
            })
        } else {
            this.setState(state => {
                if (!state.empty) {
                    return { empty: true }
                }
            })
        }
    }

    render() {
        const {
            searchActive,
            disabled,
            disableWhereAndWhenBlock,
            selectedThemeTracks
        } = this.props
        const {
            empty
        } = this.state

        const hideContent = cl({ "d-none": empty })
        const placeHolder = cl({ "d-none": !empty })


        return (
            <>
                {/* 2 */}

                <Place
                    number={2}
                    className={cl(
                        "AdvertWhere",
                        // { 'search-active': searchActive, 'd-none': !!selectedThemeTracks.length && disableWhereAndWhenBlock }
                        { 'search-active': searchActive, 'd-none': disableWhereAndWhenBlock }

                    )}
                    disabled={disabled}
                >
                    {window.location.pathname !== '/main'  ?
                        <>
                            <SlideMenu
                                activeItem={1}
                                className={hideContent}
                                items={[
                                    {
                                        title: "Объекты",
                                        content: <Objects className={hideContent} placeNumber={2} active />
                                    },
                                    {
                                        title: "Группы объектов",
                                        content: <ObjectsGroup placeNumber={2} />
                                    },
                                    {
                                        title: "Объекты по меткам",
                                        content: <Tags placeNumber={2} />
                                    }
                                ]}
                            />

                            <Empty className={placeHolder}>
                                Объектов с таким названием не найдено
                            </Empty>
                        </>
                        : ''}
                </Place>
            </>
        )
    }
}


const stateToProps = state => {
    const themesIds = state.tracks.tracksTheme.map(tr => tr.id)
    return {
        selectedThemeTracks: state.tracks.selected.filter(id => themesIds.includes(id)),
        searchActive: state.search["objects"].modeActive,
        searchValue: state.search["objects"].value,
        searchFounded: [...state.search["objects"].founded],
        disableWhereAndWhenBlock: state.advert_page.disableWhereAndWhenBlock
    }
}


export default connect(stateToProps, null)(Where)
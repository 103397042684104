import React from 'react'
import {connect} from 'react-redux'

import Place from '../../../../components/Space/Place'
import Empty from '../../../../components/Space/Empty'
import Settings from './Settings'

import SlideMenu from '../../../../components/SlideMenu'


const When = ({ selectedTracks, selectedObjects, selectedThemeTracks, disableWhereAndWhenBlock }) => {
    return(
        <>
            {/* 3 */}

            <Place 
                number={3}
                className="AdvertWhen"
            >
                {(selectedTracks?.length && selectedObjects?.length && !(!!selectedThemeTracks && !!selectedThemeTracks.length && disableWhereAndWhenBlock))
                    ? <SlideMenu
                        activeItem={1}
                        items={[
                            { 
                                title: "Настройка ролика",
                                content: <Settings placeNumber={3} active/>
                            }
                        ]}
                    />
                    : <Empty>

                    </Empty>
                }
            </Place>
        </>
    )
}


const stateToProps = state => {
    const themesIds = state.tracks.tracksTheme.map(tr => tr.id)
    return {
        selectedThemeTracks: state.tracks.selected.filter(id => themesIds.includes(id)),
        selectedTracks: [...state.tracks.selected],
        selectedObjects:  state.objects.selected  ? [...state.objects.selected] : [],
        disableWhereAndWhenBlock: state.advert_page.disableWhereAndWhenBlock,
    }
}


export default connect(stateToProps, null)(When)
import {
    LOAD_NOTIFICATIONS, LOADING,
    PUT_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS
} from './actions'

const inititalState = {
    loading: false,
    notifications: []
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case LOAD_NOTIFICATIONS:
            return {
                ...state,
            }
        case PUT_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload.notifications[0],
            }
        case UPDATE_NOTIFICATIONS:
            return {
                ...state,
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }
    }
    return state
}

export default reducer
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import 'react-bootstrap'
import ButtonWhite from '../../ButtonWhite'
import { Button } from 'react-bootstrap'
import SvgEdit from '../../SVG/Edit'
import SvgTrash from '../../SVG/Trash'
import SvgPlus from '../../SVG/PlusCircle'
import { deletePlaylists, addSongToPlaylist } from '../../../store/ContentPlaylists/actions'
import { editPlaylist } from '../../../store/Modal/types/PlaylistCreate/actions'
import { openModal, closeModal } from '../../../store/Modal/actions'


const Footer = ({
    selected_song_playlist,
    selected_playlist,
    openModal,
    closeModal,
    deletePlaylists,
    playlists,
    addSongToPlaylist,
    selected_public_song,
    selected_public_playlist,
    public_playlists,
    pl_state
}) => {
    const [playlist_items, setPlaylistItems] = useState([])
    const [public_playlist_items, setPublicPlaylistItems] = useState([])
    useEffect(() => {
        if (selected_playlist.length > 0 && playlists.length > 0) {
            setPlaylistItems([...Object.values(playlists.filter(pl => pl.id === selected_playlist[0])[0].songs)])
        }
        // if (selected_public_playlist.length > 0) {
        //     setPublicPlaylistItems([...Object.values(public_playlists.filter(pl => pl.id === selected_public_playlist[0])[0].songs)])
        // }

    }, [selected_playlist, pl_state])
    useEffect(() => {
        if (selected_public_playlist.length > 0 && Object.values(public_playlists).length > 0) {
            setPublicPlaylistItems([...Object.values(public_playlists.filter(pl => pl.id === selected_public_playlist[0])[0].songs)])
        }

    }, [selected_public_playlist])
    const deleteSongs = () => {
        let clear = playlist_items.map(song => { return song.id }).filter(id => selected_song_playlist.indexOf(id) === -1)
        let playlist_items_update = Array.from(playlist_items).filter(song => selected_song_playlist.indexOf(song.id) === -1)
        addSongToPlaylist({ id_playlist: selected_playlist[0], selected_song: clear, songs: playlist_items_update })
    }
    const addSongs = () => {
        let current = playlist_items.map(song => { return song.id })
        let full = [...current, ...selected_public_song]
        console.log(current, selected_public_song)
        let playlist_items_update = [...Array.from(playlist_items), ...public_playlist_items.filter(song => selected_public_song.indexOf(song.id) !== -1)]
        addSongToPlaylist({ id_playlist: selected_playlist[0], selected_song: full, songs: playlist_items_update })
    }
    return (
        <div>
            { (selected_playlist.length > 0 && selected_song_playlist.length === 0 && selected_public_playlist.length === 0) &&
                <div className="fixed-bottom p-2 bg-light border footer">
                    <div className="d-flex justify-content-between flex-row justify-content-center align-items-center">
                        <button onClick={ () => openModal({
                            type: "playlistEdit",
                            size: "sm",
                        }) } className="btn btn-light btn-sm mt-2" style={ { minWidth: "48%", lineHeight: "1px" } }><SvgEdit size="sm" />Изменить</button>
                        <button onClick={ () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: "Вы точно хотите удалить выделенные плейлисты?",
                                buttons: {
                                    cancel: {
                                        title: "Вернуться",
                                        onClick: () => {
                                            closeModal("confirm")
                                        }
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deletePlaylists(selected_playlist)
                                            closeModal("confirm")
                                        }
                                    }
                                }
                            }
                        }) } className="btn btn-light btn-sm mt-2" style={ { minWidth: "48%", lineHeight: "1px" } }><SvgTrash size="sm" />Удалить</button>
                    </div>
                </div >
            }
            { selected_song_playlist.length > 0 &&
                <div className="fixed-bottom p-2 bg-light border footer">
                    <div className="d-flex justify-content-between flex-row justify-content-center align-items-center">
                        <button onClick={ () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: "Вы точно хотите удалить выделенные треки из этого плейлиста?",
                                buttons: {
                                    cancel: {
                                        title: "Вернуться",
                                        onClick: () => {
                                            closeModal("confirm")
                                        }
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deleteSongs()
                                            closeModal("confirm")
                                        }
                                    }
                                }
                            }
                        }) } className="btn btn-light btn-sm mt-2" style={ { minWidth: "100%", lineHeight: "1px" } }><SvgTrash size="sm" />Удалить</button>
                    </div>
                </div >
            }
            { selected_playlist.length > 0 && selected_public_song.length > 0 &&
                <div className="fixed-bottom p-2 bg-light border footer">
                    <div className="d-flex justify-content-between flex-row justify-content-center align-items-center">
                        <button onClick={ () => addSongs() } className="btn btn-light btn-sm mt-2" style={ { minWidth: "100%", lineHeight: "1px" } }><SvgPlus size="sm" />Добавить</button>
                    </div>
                </div >
            }
        </div>
    )
}

const stateToProps = state => ({
    selected_playlist: state.playlists.selected,
    playlists: state.playlists.allPlaylists,
    selected_song_playlist: state.playlists.selected_song,
    pl_state: state.playlists,

    public_playlists: state.public_playlists.allPublicPlaylists,
    selected_public_song: state.public_playlists.selected_song,
    selected_public_playlist: state.public_playlists.selected,
})
const actionsToProps = ({
    editPlaylist,
    deletePlaylists,
    openModal,
    closeModal,
    addSongToPlaylist
})
export default connect(stateToProps, actionsToProps)(Footer)
import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../utilities/generateStateKey'

import {updateOptions, toggleSelectedItems} from '../../../../store/Settings/actions'
import {openModal, closeModal} from '../../../../store/Modal/actions'
import {
    loadItems,
    addItem,
    saveItems,
    deleteItems,
    selectUsedVideo,
    selectPeriod
} from '../../../../store/Settings/actions/sets/Object/Background'
import {OBJECT_BACKGROUND} from '../../../../store/Settings/sets'

import ObjectBackground from './ObjectBackground'


const ObjectBackgroundContainer = props => (
    <ObjectBackground
        className={props.className}
        stateKey={props.stateKey}

        store={props.store}
        data={props.data}
        settings={props.settings}
        selectedPeriod={props.selectedPeriod}
        selected={props.selected}
        periodStateKey={props.periodStateKey}
        items={props.items}

        selectedObjects={props.selectedObjects}
        selectedGroups={props.selectedGroups}
        objectsAssoc={props.objectsAssoc}
        groupsAssoc={props.groupsAssoc}
        usesOwn={props.usesOwn}

        loading={props.data.__loading}
        saving={props.data.__saving}
        deleting={props.data.__deleting}
        adding={props.data.__adding}

        loadItems={props.loadItems}
        addItem={props.addItem}
        saveItems={props.saveItems}
        deleteItems={props.deleteItems}
        selectUsedVideo={props.selectUsedVideo}
        selectPeriod={props.selectPeriod}
        updateOptions={props.updateOptions}
        toggleSelectedItems={props.toggleSelectedItems}
        openModal={props.openModal}
        closeModal={props.closeModal}
    />
)

const stateToProps = (state, {stateKey}) => {
    const store = state.settings.settings[OBJECT_BACKGROUND]
    const data = store[stateKey] || {}
    const settings = data
    if (typeof settings.daymask === 'undefined') {
        settings.daymask = 127;
    }
    const selectedPeriod = data.selectedPeriod || -1
    const selected = data.selected
    const periodStateKey = generateStateKey([stateKey, selectedPeriod])
    const items = store[periodStateKey]?.list || []

    return {
        store,
        data,
        settings,
        selectedPeriod,
        selected,
        periodStateKey,
        items,

        selectedObjects: state.objects.selected,
        selectedGroups: state.objectsGroup.selected,
        objectsAssoc: state.objects.allObjectsAssociated,
        groupsAssoc: state.objectsGroup.groupsAssoc,
        usesOwn: store.usesOwn,
    }
}

const actionsToProps = {
    loadItems,
    addItem,
    saveItems,
    deleteItems,
    selectUsedVideo,
    selectPeriod,
    updateOptions,
    toggleSelectedItems,
    openModal,
    closeModal,
}

export default connect(stateToProps, actionsToProps)(ObjectBackgroundContainer)
export const LOAD_PRESETS       = "Settings/Presets/LOAD_PRESETS"
export const PUT_PRESETS        = "Settings/Presets/PUT_PRESETS"
export const APPLY_PRESET       = "Settings/Presets/APPLY_PRESET"
export const RESET_PRESET       = "Settings/Presets/RESET_PRESET"
export const UPDATE_PRESET_NAME = "Settings/Presets/UPDATE_PRESET_NAME"
export const CREATE_PRESET      = "Settings/Presets/CREATE_PRESET"
export const DELETE_PRESET      = "Settings/Presets/DELETE_PRESET"

export const loadPresets = () => ({
    type: LOAD_PRESETS
})

export const putPresets = presets => ({
    type: PUT_PRESETS,
    payload: presets
})

export const applyPreset = presetId => ({
    type: APPLY_PRESET,
    payload: presetId
})

export const resetPreset = (p) => ({
    type: RESET_PRESET,
    payload: p
})

export const updatePresetName = ({ presetId, name }) => ({
    type: UPDATE_PRESET_NAME,
    payload: {
        presetId,
        name,
    }
})

export const createPreset = ({ type, name, description = "", data }) => ({
    type: CREATE_PRESET,
    payload: {
        type,
        name,
        description,
        data,
    }
})

export const deletePreset = presetId => ({
    type: DELETE_PRESET,
    payload: presetId
})
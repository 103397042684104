import React from 'react'
import {connect} from "react-redux";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const DiskSize = ({
    hdd_size,
    hdd_free
}) => {

    const percent = Math.round((hdd_size - hdd_free) / hdd_size * 100).toFixed(0);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Заполненное место</Popover.Title>
            <Popover.Content>
                Свободно: {hdd_free}/{hdd_size} Mb
            </Popover.Content>
        </Popover>
    );

    return(
        <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
            <ProgressBar now={percent} label={percent + '%'} className="DiskSize mb-2" style={{height: '10px'}}/>
        </OverlayTrigger>

        // <div className="progress mb-2" style={ {height: '10px'} } data-toggle="tooltip" data-placement="top" title="Tooltip on top">
        //     <div className="progress-bar bg-success" role="progressbar" style={ {width: percent + '%'} } aria-valuenow={percent} aria-valuemin="0"
        //          aria-valuemax="100">{percent}%
        //     </div>
        // </div>
    )
}

const stateToProps = (state, props) => ({
    hdd_size: state.objects.allObjectsAssociated[props.objectId].hdd_size,
    hdd_free: state.objects.allObjectsAssociated[props.objectId].hdd_free
})

export default connect(stateToProps, null)(DiskSize)
import React from 'react'

import ScrollContainer from '../../ScrollContainer'
import ObjectItem from '../ObjectItem'


const Right = ({
    objects = [],
    selectedTracks = [],
    toggleSelected,
}) => {

    return(
        <section className="ObjectsRight Objects__place">
            <div className="Objects__title">Ролик транслируется на объектах</div>
            <div className="Objects__list" onClick={toggleSelected}>
                
                <ScrollContainer className="Objects__container-list" size="auto">
                        {(objects.length && selectedTracks.length)
                            ? objects.map(object =>
                                <ObjectItem
                                    key={object.id}
                                    id={object.id}
                                    name={object.name}
                                    fullName={object.fullName}
                                    statusText={ object.statusText }
                                />
                            )
                            : <div className="Objects__message">
                                Объекты будут доступны
                                после того, как вы выберите
                                ролик или группу роликов
                            </div>
                        }
                </ScrollContainer>
            </div>
        </section>
    )
}

export default Right
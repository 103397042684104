import React from 'react'


const Edit = ({ title = 'Видео', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 13.463">
                <title>{ title }</title>
                <path d="M.5 0a.5.5 0 00-.5.5v12.463a.5.5 0 00.5.5h16a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zM1 1h3.846v3.154H1zm4.846 0h5.308v11.463H5.846zm6.308 0H16v3.154h-3.846zM1 5.154h3.846V8.31H1zm11.154 0H16V8.31h-3.846zM1 9.31h3.846v3.154H1zm11.154 0H16v3.154h-3.846z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" /></svg>
        </i>
    )
}

export default Edit
import React from 'react'

import InputNumber from '../InputNumber'
import { Form } from 'react-bootstrap'


class DurationInput extends React.Component {
    constructor(props) {
        super(props)
        this.hChange = this.hChange.bind(this)
    }

    hChange({ value }) {
        if (typeof this.props.onChange === "function") {
            this.props.onChange(value)
        }
    }

    inputValidate(value) {
        if (value >= 0 && value <= 9999) return true
        return false
    }




    render() {
        const {
            toggleNoEnd
        } = this.props
        return (
            <div className="FormDatepickerDurationInput" style={{width: '130px'}}>
                <div className="FormDatepickerDurationInput__title">
                    Кол-во дней
                </div>
                <div className="FormDatepickerDurationInput__input">
                    <InputNumber
                        onChange={this.hChange}
                        validate={this.inputValidate}
                        attrs={{
                            value: this.props.value || ""
                        }}
                    />
                </div>
                <Form.Check style={{ marginTop: 24 }} type="checkbox" id={"noEnd"} name="noEnd" label="Постоянно"
                    onChange={e => toggleNoEnd()} value="1"
                    checked={this.props.value === 999 | this.props.value === '999'} />
            </div>
        )
    }
}

export default DurationInput
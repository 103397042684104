import {takeEvery, put} from 'redux-saga/effects'
import {TOGGLE_SELECTED_GROUP} from '../../store/ObjectsGroup/actions'
import {updateSelectedByGroupsAndTags} from '../../store/Objects/actions'


export default function* () {
    yield takeEvery(TOGGLE_SELECTED_GROUP, worker)
}

function* worker({ payload }) {
    yield put(updateSelectedByGroupsAndTags({
        type: "groups",
        id: payload.id,
        selected: payload.selected
    }))
}
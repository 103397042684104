export const TOGGLE_SELECTED_OBJECT = "Objects/TOGGLE_SELECTED_OBJECT"
export const UPDATE_SELECTED_BY_GROUPS_AND_TAGS = "Objects/UPDATE_SELECTED_BY_GROUPS_AND_TAGS"
export const PUT_OBJECTS = "Objects/PUT_OBJECTS"
export const PUT_OBJECT_STATUS_LIST = "Objects/PUT_OBJECT/STATUS_LIST"
export const PUT_OBJECTS_ASSOCIATED = "Objects/PUT_OBJECTS_ASSOCIATED"
export const LOAD_INDENT_OBJECTS = "Objects/LOAD_INDENT_OBJECTS"
export const PUT_INDENT_OBJECTS = "Objects/PUT_INDENT_OBJECTS"
export const DELETE_OBJECTS = "Objects/DELETE_OBJECTS"
export const LOAD_OBJECTS = "Objects/LOAD_OBJECTS"
export const SEARCH_OBJECTS = "Objects/SEARCH_OBJECTS"
export const LOADING = "Objects/LOADING"
export const SORT_OBJECTS = "SORT_OBJECTS"

export const toggleSelectedObject = ({ id, selected, simple, required }) => ({
    type: TOGGLE_SELECTED_OBJECT,
    payload: {
        id,
        selected,
        simple,
        required,
    }
})

export const updateSelectedByGroupsAndTags = ({ type, id, selected }) => ({
    type: UPDATE_SELECTED_BY_GROUPS_AND_TAGS,
    payload: {
        type,
        selected,
        id,
    }
})

export const loadObjects = () => ({
    type: LOAD_OBJECTS,
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})
//ищет объекты в суб аккаунтах чтобы потом перейти в нужный аккаунт в нужный объект
export const searchObjects = id => ({
    type: SEARCH_OBJECTS,
    payload: id
})

export const putObjects = objects => ({
    type: PUT_OBJECTS,
    payload: objects
})

export const putObjectsStatusList = statuses => ({
    type: PUT_OBJECT_STATUS_LIST,
    payload: statuses
})

export const putObjectsAssociated = associatedObjects => ({
    type: PUT_OBJECTS_ASSOCIATED,
    payload: associatedObjects
})

export const loadIndentObjects = () => ({
    type: LOAD_INDENT_OBJECTS
})

export const putIndentObjects = objects => ({
    type: PUT_INDENT_OBJECTS,
    payload: objects
})

export const deleteObjects = selectedObjects => ({
    type: DELETE_OBJECTS,
    payload: selectedObjects
})
export const sortObjects = sortSource => ({
    type: SORT_OBJECTS,
    payload: sortSource
})
import React, {useEffect} from 'react'

import {OBJECT_SCHEDULE} from '../../../../store/Settings/sets'

import {cl} from '../../../../utilities/cl'
import {generateStateKey} from '../../../../utilities/generateStateKey'
import {carryUpdate} from '../../utilities/carryUpdate'
import {updateHelper} from '../../utilities/updateHelper'
import {setsex} from '../../utilities/setsclasses'
import {prepareSettings} from '../../utilities/prepareSettings'
import {validateMap} from './validateMap'

import Builder from '../../Builder'

import SettingsPeriods from '../../components/Periods'
import fieldTimestart from './items/timestart'
import fieldTimeend from './items/timeend'
import SettingsDays from '../../components/Days'
import fieldBlock from './items/block'
import SettingsWithoutAds from '../../components/WithoutAds'
import SettingsAddButton from '../../components/AddButton'
import GroupSmart from './groups/Smart'

 
const SettingsObjectSchedule = ({
    className = "",
    stateKey,
    store = {},
    settings = {},
    items = [],
    tracks = {},

    selectedObjects = [],
    selectedGroups = [],
    objectsAssoc = {},
    groupsAssoc = {},
    usesOwn = [],

    selectedPeriod,
    selected = [],
    adding,
    loading,
    saving,
    deleting,

    loadItems,
    loadTracks,
    addItem,
    deleteTrackFromPosition,
    saveItemDaymask,
    deleteItems,
    downloadTracks,
    selectPeriod,
    toggleSelectedItems,
    selectBlock,
    updateOptions,
    openModal,
    closeModal,
}) => {

    className = cl(
        className,
        "SettingsObjectSchedule"
    )

    useEffect(() => {
        loadTracks()
        loadItems({ stateKey })
        selectPeriod({ stateKey, periodId: -1 })
    }, [stateKey])

    let validMap
    [settings, validMap] = prepareSettings({
        type: OBJECT_SCHEDULE,
        settings,
        validateMap,
    })

    const updateOptionsBlock = carryUpdate({
        type: OBJECT_SCHEDULE,
        stateKey,
        updateOptions
    })

    const hChangeBlock = (name) => updateHelper({ 
        name,
        updateOptions: updateOptionsBlock,
        handlerType: OBJECT_SCHEDULE
    })

    const hSelectPeriod = ({ value }) => selectPeriod({ stateKey, periodId: value })
    const hAddItem = () => addItem({ stateKey })
    const hSaveItemDaymask = (itemIds) => saveItemDaymask({ stateKey, itemIds })
    const hDeleteItems = (itemIds) => deleteItems({ stateKey, itemIds })

    // onChange и готовые настройки для item (элементов списка)

    const itemStateKey = generateStateKey([ stateKey, selected[0] ])
    const itemSettings = store[itemStateKey] || {}
//console.log(itemStateKey, itemSettings)
    const selectedBlockNumber = itemSettings.__blocknumber

    const updateOptionsItem = carryUpdate({
        type: OBJECT_SCHEDULE,
        stateKey: itemStateKey,
        updateOptions
    })

    const hChangeItem = (name) => updateHelper({ 
        name,
        updateOptions: updateOptionsItem
    })

    return(
        <Builder
            warning={getWarning()}
            className={className}
            groups={{
                all: [
                    // Group
                    {
                        data: {
                            columns: 1,
                            title: "Добавление рекламного блока",
                            className: setsex(["col-1", "o-1"])
                        },
                        items: [
                            <SettingsPeriods
                                type="clip"
                                selected={selectedPeriod}
                                selectedLength={selected.length}
                                onChange={hSelectPeriod}
                            />
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                            className: setsex(["o-2", "col-2", "mr", "mb-items-0"])
                        },
                        items: [
                            {
                                title: "Время начала трансляции",
                                components: fieldTimestart({
                                    settings,
                                    name: "starttime",
                                    onChange: hChangeBlock("starttime"),
                                })
                            },
                            {
                                title: "Время окончания трансляции",
                                components: fieldTimeend({
                                    settings,
                                    name: "stop",
                                    onChange: hChangeBlock("stop"),
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            noDivider: true,
                            className: setsex(["o-4", "col-2", "mr"])
                        },
                        items: [
                            <SettingsDays
                                value={settings["daymask"]}
                                onChange={hChangeBlock("daymask")}
                            />
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                            alignHeight: true,
                            className: setsex(["o-3", "col-2", "mt-items-0"])
                        },
                        items: [
                            {
                                title: "Блок №",
                                components: fieldBlock({
                                    settings,
                                    name: "block",
                                    onChange: hChangeBlock("block"),
                                })
                            },
                            <SettingsWithoutAds
                                value={settings["__without-ads"]}
                                name="__without-ads"
                                onChange={hChangeBlock("__without-ads")}
                            />
                        ]
                    },

                    // Group
                    {
                        data: {
                            className: setsex(["o-5", "col-2"])
                        },
                        items: [
                            <SettingsAddButton
                                onClick={hAddItem}
                                title={adding ? "Добавление..." : "Добавить"}
                                disabled={adding || !validMap.validResult}
                            />
                        ]
                    },

                    // Слайдер и ролики
                    <GroupSmart
                        className={setsex(["o-6", "col-1"])}
                        store={store}
                        stateKey={stateKey}
                        settings={itemSettings}
                        onChange={hChangeItem}
                        items={items}
                        tracks={tracks}
                        selectedObjects={selectedObjects}
                        objectsAssoc={objectsAssoc}
                        selected={selected}
                        selectedBlockNumber={selectedBlockNumber}
                        deleteTrackFromPosition={deleteTrackFromPosition}
                        downloadTracks={downloadTracks}
                        saveItemDaymask={hSaveItemDaymask}
                        deleteItems={hDeleteItems}
                        toggleSelectedItems={toggleSelectedItems}
                        selectBlock={selectBlock}
                        openModal={openModal}
                        closeModal={closeModal}
                        loading={loading}
                        saving={saving}
                        deleting={deleting}
                    />
                ],
            }}
        />
    )

    function getWarning(empty) {
        if(selectedGroups.length === 1) {
            return `Расписание группы объектов «${groupsAssoc[selectedGroups[0]].name}»`;
        }
        if(selectedObjects.length === 1 && selectedGroups.length === 0) {
            const objectId = selectedObjects[0]
            const objectGroupId = objectsAssoc[objectId].msgroup_id || 0
            if (!objectGroupId) {
                return `Расписание объекта «${objectsAssoc[objectId].name}».`;
            } else {
                if(usesOwn.indexOf(objectId) !== -1) {
                    return `Объект «${objectsAssoc[objectId].name}» принадлежит группе объектов «${groupsAssoc[objectGroupId].name}», но использует собственное расписание`;
                } else {
                    return `Объект «${objectsAssoc[objectId].name}» использует расписание для группы объектов «${groupsAssoc[objectGroupId].name}»`
                }
            }
        }
        return empty
    }
}

export default SettingsObjectSchedule
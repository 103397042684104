import React, { useState, useEffect } from 'react'
import Spinner from '../../../../../components/Spinner'
import { connect } from 'react-redux'
import SVGGrid from "../../../../../components/SVG/Grid"
import List from '../../../../../components/List'
import Datepicker from '../../../../../components/Form/Datepicker'
import ButtonCircle from '../../../../../components/ButtonCircle'
import ArrowDownLight from '../../../../../components/SVG/ArrowDownLight'
import ArrowUpLight from '../../../../../components/SVG/ArrowUpLight'
import { useHistory } from 'react-router-dom'
import ReportAdvert from './Advert/index'
import ReportMusic from './Music/index'
import ReportSystem from './System/index'
import { loadClipReport } from '../../../../../store/Reports/Advert/actions'
import { loadMusicReport } from '../../../../../store/Reports/Music/actions'
import { loadSystemReport } from '../../../../../store/Reports/System/actions'
import { toggleSelectedObject } from '../../../../../store/Objects/actions'
import { toggleSelectedObjectState, menuSelected } from '../../../../../store/Reports/actions'

const ObjectReport = ({
    objects,
    selecteo,
    selected_state,
    loadClipReport,
    loadMusicReport,
    loadSystemReport,
    toggleSelectedObject,
    toggleSelectedObjectState,
    menuSelected,
    menu, duration,
    file
}) => {
    let history = useHistory()
    var dateFormat = function () {
        var token = /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g,
            timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g,
            timezoneClip = /[^-+\dA-Z]/g,
            pad = function (val, len) {
                val = String(val);
                len = len || 2;
                while (val.length < len) val = "0" + val;
                return val;
            };

        // Regexes and supporting functions are cached through closure
        return function (date, mask, utc) {
            var dF = dateFormat;

            // You can't provide utc if you skip other args (use the "UTC:" mask prefix)
            if (arguments.length == 1 && Object.prototype.toString.call(date) == "[object String]" && !/\d/.test(date)) {
                mask = date;
                date = undefined;
            }

            // Passing date through Date applies Date.parse, if necessary
            date = date ? new Date(date) : new Date;
            if (isNaN(date)) throw SyntaxError("invalid date");

            mask = String(dF.masks[mask] || mask || dF.masks["default"]);

            // Allow setting the utc argument via the mask
            if (mask.slice(0, 4) == "UTC:") {
                mask = mask.slice(4);
                utc = true;
            }

            var _ = utc ? "getUTC" : "get",
                d = date[_ + "Date"](),
                D = date[_ + "Day"](),
                m = date[_ + "Month"](),
                y = date[_ + "FullYear"](),
                H = date[_ + "Hours"](),
                M = date[_ + "Minutes"](),
                s = date[_ + "Seconds"](),
                L = date[_ + "Milliseconds"](),
                o = utc ? 0 : date.getTimezoneOffset(),
                flags = {
                    d: d,
                    dd: pad(d),
                    ddd: dF.i18n.dayNames[D],
                    dddd: dF.i18n.dayNames[D + 7],
                    m: m + 1,
                    mm: pad(m + 1),
                    mmm: dF.i18n.monthNames[m],
                    mmmm: dF.i18n.monthNames[m + 12],
                    yy: String(y).slice(2),
                    yyyy: y,
                    h: H % 12 || 12,
                    hh: pad(H % 12 || 12),
                    H: H,
                    HH: pad(H),
                    M: M,
                    MM: pad(M),
                    s: s,
                    ss: pad(s),
                    l: pad(L, 3),
                    L: pad(L > 99 ? Math.round(L / 10) : L),
                    t: H < 12 ? "a" : "p",
                    tt: H < 12 ? "am" : "pm",
                    T: H < 12 ? "A" : "P",
                    TT: H < 12 ? "AM" : "PM",
                    Z: utc ? "UTC" : (String(date).match(timezone) || [""]).pop().replace(timezoneClip, ""),
                    o: (o > 0 ? "-" : "+") + pad(Math.floor(Math.abs(o) / 60) * 100 + Math.abs(o) % 60, 4),
                    S: ["th", "st", "nd", "rd"][d % 10 > 3 ? 0 : (d % 100 - d % 10 != 10) * d % 10]
                };

            return mask.replace(token, function ($0) {
                return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
            });
        };
    }();

    // Some common format strings
    dateFormat.masks = {
        "default": "ddd mmm dd yyyy HH:MM:ss",
        shortDate: "m/d/yy",
        mediumDate: "mmm d, yyyy",
        longDate: "mmmm d, yyyy",
        fullDate: "dddd, mmmm d, yyyy",
        shortTime: "h:MM TT",
        mediumTime: "h:MM:ss TT",
        longTime: "h:MM:ss TT Z",
        isoDate: "yyyy-mm-dd",
        isoTime: "HH:MM:ss",
        isoDateTime: "yyyy-mm-dd'T'HH:MM:ss",
        isoUtcDateTime: "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'"
    };

    // Internationalization strings
    dateFormat.i18n = {
        dayNames: [
            "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
            "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
        ],
        monthNames: [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
            "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
        ]
    };

    // For convenience...
    Date.prototype.format = function (mask, utc) {
        return dateFormat(this, mask, utc);
    };
    let date_first = new Date()

    let selected
    if (selecteo.length > 0) {
        selected = selecteo
    } else {
        selected = selected_state
    }
    const [clicked, setClicked] = useState({ clicked: 1 })
    const [date, setDate] = useState(date_first.format("yyyy-mm-dd"))
    useEffect(() => {
        if (clicked.clicked !== '' && !date.includes("/")) {
            if (clicked.clicked === 1) {
                loadClipReport({ id: selected[0], date: date })
            } else if (clicked.clicked === 2) {
                loadMusicReport({ id: selected[0], date: date })
            } else if (clicked.clicked === 3) {
                loadSystemReport({ id: selected[0], date: date })
            }
        }
    }, [selecteo, selected_state, date, clicked])
    const [content, setContent] = useState(<ReportAdvert date={ date } />)
    const [disabled_next, setDisNext] = useState(true)


    //let content;
    function WithoutTime(dateTime) {
        let date = new Date(dateTime.getTime());
        date.setHours(0, 0, 0, 0);
        return date;
    }

    const hsetDate = (selected) => {
        setDate(selected)
    }
    const hClickMenuElem = (e, or) => {
        setClicked({ clicked: or })
        menuSelected(or)

        if (or === 1) {
            setContent(<ReportAdvert date={ date } />)
        } else if (or === 2) {
            setContent(<ReportMusic date={ date } />)
        } else if (or === 3) {
            setContent(<ReportSystem date={ date } />)
        }

    }

    const hBack = () => {
        const objectId = Number(selected[0])
        if (!isNaN(objectId)) {
            toggleSelectedObject({ id: objectId })
            toggleSelectedObjectState({
                id: objectId,
            })
        }
    }
    const setCustomDate = (type) => {
        let d = WithoutTime(new Date(date))
        let c = WithoutTime(new Date(date))
        let curr = WithoutTime(new Date())

        if (type === "next") {
            c.setDate(c.getDate() + 1)
            if (curr <= c) {
                d.setDate(d.getDate() + 1)
                setDate(d.format("yyyy-mm-dd"))
                setDisNext(true)
            } else if (curr > c) {
                d.setDate(d.getDate() + 1)
                setDate(d.format("yyyy-mm-dd"))
                setDisNext(false)
            }
        } else if (type === "prev") {
            d.setDate(d.getDate() - 1)
            if (curr > d) {
                setDate(d.format("yyyy-mm-dd"))
                setDisNext(false)
            }
        }
    }

    return (
        <div className="Object-report">
            <div className="Object-report_menu">
                <button className={ `btn Back Object-report_menu-element` } onClick={ () => hBack() }>
                    <span className="only_trigger">К СТАТУСАМ ОБЪЕКТОВ</span>
                    <div style={ { transform: "rotate(90deg)" } }><ArrowDownLight size="xs" className="only_trigger" /></div>
                </button>
                <button className={ `btn Object-report_menu-element ${clicked.clicked === 1 ? `active-rep` : ``}` } onClick={ (e) => hClickMenuElem(e, 1) }>
                    <span className="only_trigger">РЕКЛАМА</span>
                    <SVGGrid className="spp-svg only_trigger" />
                </button>
                <button className={ `btn Object-report_menu-element ${clicked.clicked === 2 ? `active-rep` : ``}` } onClick={ (e) => hClickMenuElem(e, 2) }>
                    <span className="only_trigger">МУЗЫКА</span>
                    <SVGGrid className="spp-svg only_trigger" />
                </button>
                <button className={ `btn Object-report_menu-element ${clicked.clicked === 3 ? `active-rep` : ``}` } onClick={ (e) => hClickMenuElem(e, 3) }>
                    <span className="only_trigger">СИСТЕМА</span>
                    <SVGGrid className="spp-svg only_trigger" />
                </button>
            </div>
            <div className="Object-report_controls">
                <div>
                    <span>ОБЪЕКТ</span>
                    <h4><span style={ { color: "rgba(10, 10, 10, 0.48)", marginRight: "10px" } }>{ `${selected[0]}` }</span>{ objects.filter(obj => obj.id === selected[0])[0]?.name } { objects.filter(obj => obj.id === selected[0])[0]?.address }</h4>
                </div>
                <div style={{margin: '0 20px'}}>
                    <span>ДЛИТ-ТЬ</span>
                    <h4>{duration}</h4>
                </div>
                <div className="Object-report_controls-datepicker0">
                    <span>ДАТА ОТЧЕТА</span>
                    <div className="Object-report_controls-datepicker2">
                        <div className="Object-report_controls-datepicker-date-arrows only_trigger">
                            <ButtonCircle
                                onClick={ () => setCustomDate("prev") }
                                className="only_trigger"
                            >
                                <div style={ { transform: "rotate(90deg)" } } className="only_trigger"><ArrowDownLight className="only_trigger" /></div>
                            </ButtonCircle>

                            <ButtonCircle
                                disabled={ disabled_next }
                                onClick={ () => setCustomDate("next") }
                                className="only_trigger"
                            >
                                <div style={ { transform: "rotate(90deg)" } } className="only_trigger"> <ArrowUpLight className="only_trigger" /></div>
                            </ButtonCircle>
                        </div>
                        <Datepicker
                            type="single"
                            // variant="icon"
                            onChange={ (e) => hsetDate(e) }
                            titles={ {
                                from: "ДАТА ОТЧЕТА",
                            } }
                            className="only_trigger"
                            data={ {
                                oneDate: true,
                                disabledType: "future",
                                default: date
                            } }
                        />
                    </div>
                </div>
            </div>
            <div className="Object-report_content">
                { content }
            </div>
        </div>
    )
}
const stateToProps = state => ({
    objects: state.objects.allObjects,
    selecteo: state.objects.selected,
    selected_state: state.report.selected,
    menu: state.report.menu,
    duration: state.report.menu === 1 ? state.advert_report.advert_duration : state.music_report.music_duration,
    file: state.report.file
})

const actionsToProps = {
    loadClipReport,
    loadMusicReport,
    loadSystemReport,
    toggleSelectedObject,
    toggleSelectedObjectState,
    menuSelected
}

export default connect(stateToProps, actionsToProps)(ObjectReport)
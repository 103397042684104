import React from 'react'
import {connect} from 'react-redux'

import {updateOptions} from '../../../../store/Settings/actions'
import {ADVERT_TRACKS} from '../../../../store/Settings/sets'

import SettingsAdvertTracks from './AdvertTracks'


const SettingsAdvertTracksContainer = props => (
    <SettingsAdvertTracks
        className={props.className}
        settings={props.settings}
        stateKey={props.stateKey}
        indentIds={props.indentIds}
        objectIds={props.objectIds}
        allObjectsAssociated={props.allObjectsAssociated}
        updateOptions={props.updateOptions}
        hideEnded={props.hideEnded}
    />
)

const stateToProps = (state) => {
    const settings = state.settings.settings[ADVERT_TRACKS];

    // if (typeof settings['daymask'] === 'undefined') {
    //     settings['daymask'] = 127;
    // }
    return {
        allObjectsAssociated: state.objects.allObjectsAssociated,
        settings: settings
    }
}

const actionsToProps = {
    updateOptions
}

export default connect(stateToProps, actionsToProps)(SettingsAdvertTracksContainer)
import { takeEvery, select, put, all, call } from 'redux-saga/effects'
import { OBJECT_SCHEDULE } from '../../../../store/Settings/sets'
import { saving } from '../../../../store/Settings/actions'
import { DELETE_TRACK_FROM_POSITION, loadItems } from '../../../../store/Settings/actions/sets/Object/Schedule'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_TRACK_FROM_POSITION, worker)
}

function* worker({ payload }) {
    let { stateKey, side, position } = payload

    try {
        yield put(saving({
            type: OBJECT_SCHEDULE,
            stateKey,
            saving: true,
        }))

        const positionName = `${position}_id`

        const itemIds = yield select(state => state.settings.settings[OBJECT_SCHEDULE][stateKey].selected || [])
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedGroups = yield select(state => state.objectsGroup.selected || [])

        if (itemIds.length) {
            const itemsTracks = yield all(
                itemIds.map(itemId => {
                    const itemStateKey = generateStateKey([stateKey, itemId])
                    return select(state => state.settings.settings[OBJECT_SCHEDULE][itemStateKey][positionName])
                })
            )

            const msIds = selectedGroups.length ? selectedGroups : selectedObjects
            const deleted = yield all(
                itemIds.map((itemId, index) => {
                    let tracks = itemsTracks[index]
                    tracks[side] = "NULL"
                    return call(fetchDeleteTrack, itemId, msIds, positionName, tracks)
                })
            )

            let error = false
            deleted.map(err => {
                if (err.error) error = true
            })

            // Ошибка
            if (error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${DELETE_TRACK_FROM_POSITION}.`, deleted],
                    text: "Ошибка при очистке. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(loadItems({
                    stateKey,
                    required: true,
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_TRACK_FROM_POSITION}.`, e],
            text: "Ошибка при очистке. Попробуйте повторить."
        }))
    }

    finally {
        yield put(saving({
            type: OBJECT_SCHEDULE,
            stateKey,
            saving: false,
        }))
    }
}

function fetchDeleteTrack(id, ms_id, positionName, tracks) {
    ms_id = ms_id.join(",")
    tracks = tracks.join(",")

    let formData = new FormData()
    formData.set(positionName, tracks)

    return axios(
        {
            url: "/msbox/clip/update",
            method: "POST",
            data: formData,
            params: {
                id, ms_id
            }
        }
    )
        .then(response => response.data)
}
import {takeEvery, call, put} from 'redux-saga/effects'
import {buildGETUrl} from '../../utilities/buildGETUrl'
import {LOGIN, doLogin, Login, DO_LOGIN, loading, DO_LOGOUT} from '../../store/Login/actions'
import {showAlertError} from '../../store/Alert/actions'
import axios from 'axios'
import {didMount} from "../../store/App/actions";

export default function* () {
    yield takeEvery(DO_LOGOUT, worker)
}

function* worker({payload}) {
    try {
        localStorage.sessionToken = "";
        localStorage.removeItem('master')
        yield put(loading(true))
        const auth = yield call(fetchAuth, payload)
        yield put(loading(false))
        window.location.assign('/login')
        window.location.reload()
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DO_LOGOUT}.`, e],
            text: "Выход не пройден. Попробуйте заново."
        }))
    }
}

function fetchAuth(data) {
    return axios.post(`/account/logout`)
        .then(response => response.data)
}
import React from 'react'
import {Form} from 'react-bootstrap'


const TextReadOnly = ({
    className = "",
    label,
    name,
    value,
    comment,
    onClick,
    disabled,
}) => (

    <div className={`FormTextReadOnly ${className}`}>
        <Form.Group>
            {label
                && <Form.Label>
                    {label}
                </Form.Label>
            }
            <div
                className={`FormTextReadOnly__text form-control ${disabled ? "disabled" : ""}`}
                onClick={(event) => onClick({
                    name,
                    value
                }, event)}
            >
                {value}
            </div>
            {comment
                && <div className="FormTextReadOnly__comment">
                    {comment}
                </div>
            }
        </Form.Group>
    </div>
)

export default TextReadOnly
import React from 'react'

import ScrollContainer from '../../ScrollContainer'
import VideolistItem from '../VideolistItem'


const Right = ({
    videolists = [],
    selectedTracks = [],
    toggleSelected,
}) => {

    return (
        <section className="">
            <div className="Videolists__list" onClick={ toggleSelected }>

                <ScrollContainer className="" size="auto">
                    { (videolists.length && selectedTracks.length)
                        ? videolists.map(videolist =>
                            <VideolistItem
                                key={ videolist.id }
                                id={ videolist.id }
                                name={ videolist.name }
                            />
                        )
                        : <div className="Objects__message">
                            Объекты будут доступны
                            после того, как вы выберите
                            ролик или группу роликов
                        </div>
                    }
                </ScrollContainer>
            </div>
        </section>
    )
}

export default Right
import React from 'react'

import Lines from './Lines'


const LinesContainer = props => (
    <Lines
        className={props.className}
    />
)

export default LinesContainer
import React from 'react'
import PropTypes from 'prop-types'
import {Form} from 'react-bootstrap'

import {cl} from '../../../utilities/cl'

import SvgEye from '../../SVG/Eye'


class InputPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.attrs?.value || "",
            showPassword: false,
        }

        this.hChange = this.hChange.bind(this)
        this.toggleShowPassword = this.toggleShowPassword.bind(this)
    }

    toggleShowPassword() {
        this.setState(state => {
            return {
                showPassword: !state.showPassword
            }
        })
    }

    componentDidUpdate(prevProps) {
        if(typeof this.props.attrs === "object") {
            if(prevProps.attrs.value !== this.props.attrs.value) {
                this.setState({
                    value: this.props.attrs.value
                })
            }
        }
    }

    hChange(event) {
        const value = event.target.value

        this.setState(() => {
            const continueToSave =
                (typeof this.props.validate === "function")
                    ? this.props.validate(value)
                    : true

            if(continueToSave) {
                const eventData = {
                    name: this.props.attrs?.name,
                    value: value,
                }
                if(typeof this.props.onChange === "function") {
                    this.props.onChange(eventData, event)
                }
                return { value }
            }
        })
    }

    render() {
        let {
            className = "",
            label,
            comment,
            attrs = {},
        } = this.props
        const {
            value,
            showPassword
        } = this.state

        className = cl(
            className,
            {"FormInputPassword": [
                {"showing": showPassword}
            ]}
        )

        return(
            <div className={className}>
                <Form.Group controlId={attrs.id}>
                    {label
                        && <Form.Label>
                            {label}
                        </Form.Label>
                    }

                    <Form.Control
                        {...attrs}
                        type={showPassword ? "text" : "password"}
                        value={value}
                        onChange={this.hChange}
                    />

                    <SvgEye
                        className="FormInputPassword__icon-password"
                        title={showPassword ? "Скрыть пароль" : "Показать пароль"}
                        size="xs1"
                        onClick={this.toggleShowPassword}
                    />

                    {comment
                        && <div className="FormInputPassword__comment">
                            {comment}
                        </div>
                    }
                </Form.Group>
            </div>
        )
    }
}

InputPassword.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    comment: PropTypes.string,
    attrs: PropTypes.object,
    onChange: PropTypes.func,
    validate: PropTypes.func
}

export default InputPassword
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { cl } from '../../utilities/cl'
import { Form } from 'react-bootstrap'
import CheckCircle from '../SVG/CheckCircle'
import { passRecovery } from '../../store/Login/actions'
import { useHistory } from 'react-router-dom';
import WarningRed from '../SVG/WarningRed'

const Recovery = props => {

    let history = useHistory()
    const [validated, setValidated] = useState(false);
    const [login, setLogin] = useState("")
    const [recovery_err, setRecoveryResult] = useState('')
    useEffect(() => {
        setRecoveryResult(props.recovery_err)
    }, [props.recovery_err])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            props.passRecovery({ email: login })
        }

        setValidated(true);
    };

    const CLabelL = () => { return (<label className="Login-form__label">Введите e-mail для получения регистрационной информации</label>) }


    return (
        <div className="Login-form">
            <h2 className="Login-form__header">Восстановление пароля </h2>
            <Form noValidate className="w-100" validated={ validated } md="6" onSubmit={ handleSubmit } >
                { (recovery_err === '' || recovery_err !== 'good') &&
                    <>
                        { recovery_err !== 'good' && recovery_err !== '' && !props.login.loading && <div className="recovery-notify">
                            <div style={ { marginTop: '3px' } }><WarningRed /></div> <div className="recovery-notify-text">Электронный адрес в системе не зарегистрирован. Обратитесь в службу поддержки по телефону <span>+7 (499) 703-35-40</span> доб. <span>3</span></div>
                        </div> }
                        <div className="form-groupp">
                            <CLabelL />

                            <input
                                type="text"
                                required
                                placeholder="E-mail"
                                className="form-control"
                                style={ { height: "60px", borderRadius: "10px" } }
                                id="exampleFormControlSelect1"
                                onChange={ (e) => setLogin(e.target.value) }
                                value={ login }

                            />
                            <div class="invalid-feedback">
                                Поле обязательно для заполнения
                            </div>

                        </div>
                        <span className="license">Нажимая кнопку «Войти», Вы подтверждаете, что даете свое согласие на обработку персональных данных.</span>
                    </>
                }
                { recovery_err === 'good' &&
                    <div className="recovery-notify">
                        <CheckCircle /> <div className="recovery-notify-text">На ваш электронный адрес отправлена инструкция по восстановлению пароля</div>
                    </div>
                }
                { recovery_err !== 'good' &&
                    <button className="btn btn-md btn-primary  w-100 Login-btn" type="submit">Отправить</button>
                }
                { recovery_err === 'good' &&
                    <span className="back-recovery" onClick={ () => history.push('/main') } >На главную</span>
                }
            </Form>
        </div>
    )
}
const stateToProps = state => ({
    recovery_err: state.login.recovery_err,
    login: state.login
})

const actionsToProps = {
    passRecovery
}

export default connect(stateToProps, actionsToProps)(Recovery)
import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_HELP, loading, putHelp } from '../../store/Help/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_HELP, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        const help = yield call(fetchHelp)
        if (help.error && help.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_HELP}.`, help],
                text: "Категории не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putHelp([]))
        } else {
            yield put(putHelp({ help: help.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_HELP}.`, e],
            text: "Категории не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putHelp([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchHelp() {
    return axios.get("/page/select")
        .then(response => response.data)
}

import {takeEvery, put, call, all, select, takeLeading} from 'redux-saga/effects'
import {generateStateKey} from '../../../utilities/generateStateKey'
import {buildGETUrl} from '../../../utilities/buildGETUrl'
import {formatTimeToSeconds} from '../../../utilities/format'
import {LOAD_CLIPS, putClips} from '../../../store/Settings/actions/components/Clips'
import {showAlertError} from '../../../store/Alert/actions'
import {addMark} from '../../../store/Marks/actions'
import axios from 'axios'
import moment from 'moment'
import {formatMinutesToTime} from "../../../components/Settings/utilities/formatTime";

export default function* () {
    yield takeLeading(LOAD_CLIPS, worker)
}

function* worker({payload}) {
    try {
        const {indentId, objectIds, required} = payload
        const cache = yield select(state => state.settings.clips || {})
        let allClips = []

        const objectsClips = yield all(
            objectIds.map(objectId =>
                getObjectClips({
                    indentId,
                    objectId,
                    required,
                    cache
                })
            )
        )

        // clips

        yield all(
            objectsClips.map((clips, index) => {
                if (clips.error) {
                    return put(showAlertError({
                        errors: [`Ошибка сервера при ${LOAD_CLIPS}.`, clips],
                        text: "Расписание не было загружено. " + clips.error
                    }))

                } else {
                    if (required || !clips.isCache) {

                        const stateKey = generateStateKey([indentId, objectIds[index]])

                        let clips2 = clips.success

                        let sorted = {}

                        clips2.map(clip => {
                            if (clip.deleted?.length) return true
                            let id = `${clip.fromdate}-${clip.todate}-${clip.channel}`;
                            clip.id2 = id;
                            if (clip.mark) {
                                id = clip.mark;
                            }
                            if (!sorted[id]) sorted[id] = []
                            sorted[id].push(clip)
                        })

                        let schedule = []

                        for (let key in sorted) {
                            const fromtimes = sorted[key].map(clip => clip.fromtime).filter((v, i, a) => a.indexOf(v) === i).sort();
                            const totimes = sorted[key].map(clip => clip.totime).filter((v, i, a) => a.indexOf(v) === i).sort();
                            const fromdates = sorted[key].map(clip => clip.fromdate).filter((v, i, a) => a.indexOf(v) === i).sort();
                            const todates = sorted[key].map(clip => clip.todate).filter((v, i, a) => a.indexOf(v) === i).sort();
                            const blockText = sorted[key].map(clip => clip.block).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => Number(b) > Number(a) ? -1 : 1).map(d => (d-1)*5);
                            schedule.push({
                                id: key,
                                id2: sorted[key][0].id2,
                                // fromdate: sorted[key][0].fromdate,
                                fromdate: fromdates[0],
                                fromdates,
                                daymask: sorted[key][0].daymask,
                                block: String(sorted[key].map(clip => clip.block).filter((v, i, a) => a.indexOf(v) === i).sort().join(',')),
                                block2: sorted[key].map(clip => clip.block).filter((v, i, a) => a.indexOf(v) === i).sort(),
                                blockText,
                                //ids: sorted[key].map(clip => clip.id),
                                fromtimes: fromtimes,
                                totimes: totimes,
                                //steptime: sorted[key][0].steptime,
                                __inday: sorted[key][0].daymask === 85 && !!sorted[key][0].mark,
                                ms_id: sorted[key][0].ms_id,
                                indent_id: sorted[key][0].indent_id,
                                // todate: sorted[key][0].todate,
                                todate: todates[todates.length - 1],
                                todates,
                                channel: sorted[key][0].channel,
                                mark: sorted[key][0].mark,
                                ...getTimes(sorted[key], fromtimes, totimes),
                            })
                        }

                        clips2 = schedule
                        allClips.push(clips2)

                        //console.log(clips, allClips, sorted)
                        return put(putClips({
                            stateKey,
                            clips: clips2
                        }))
                    }
                }
            })
        )

        // marks

        // yield all(
        //     allClips.map(clips => all(
        //         clips.map(clip => {
        //             if (clip.mark) return put(addMark(clip.mark))
        //         })
        //     ))
        // )
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_CLIPS}.`, e],
            text: "Расписание не было загружено. Попробуйте перезагрузить страницу."
        }))
    }
}

//
// Функции
//

function getObjectClips({indentId, objectId, required, cache}) {
    const cacheId = generateStateKey([indentId, objectId])
    cache = cache[cacheId]

    const isCache = cache ? true : false

    return (
        (!isCache || required === true)
            ? call(fetchClip, indentId, objectId)
            : {
                success: cache || [],
                isCache
            }
    )
}

function fetchClip(indentId, objectId) {
    return axios.get("/timeline/clip/select", {
        params: {
            indent_id: indentId,
            ms_id: objectId,
        }
    })
        .then(response => response.data)
}

//@fixme поправил баг
function getTimes(items, fromtimes, totimes) {
    let from = fromtimes[0],
        to = totimes[totimes.length - 1],
        steptime = '', stepTimeText = '';
    if (typeof fromtimes[1] !== 'undefined' && fromtimes[0] === totimes[0]) {
        let fr1 = moment.duration(fromtimes[1]).asMinutes()
        let fr0 = moment.duration(fromtimes[0]).asMinutes()
        let diff = fr1 - fr0;
        //console.log(fr1, fr0, diff)
        if (diff > 60) {
            steptime = diff;
            stepTimeText = formatMinutesToTime(steptime);
        }
    }

    // if (formatTimeToSeconds(from) > formatTimeToSeconds(to)) {
    //     from = items[items.length - 1].totime
    //     to = items[0].fromtime
    // }

    return {
        fromtime: from,
        totime: to,
        steptime,
        stepTimeText
    }
    // const fromObj = items.reduce((acc, item) => {
    //     acc[`${formatTimeToSeconds(item.fromtime)}`] = item.fromtime
    //     return acc
    // }, {})
    // const minFroms = Math.min(...Object.keys(fromObj))
    // const toObj = items.reduce((acc, item) => {
    //     acc[`${formatTimeToSeconds(item.totime)}`] = item.totime
    //     return acc
    // }, {})
    // const maxTos = Math.max(...Object.keys(toObj))
    //
    // return {
    //     fromtime: fromObj[minFroms],
    //     totime: toObj[maxTos],
    // }
}
import React from 'react'
import { connect } from 'react-redux'

import VideolistAllItem from './VideolistAllItem'


const VideolistAllItemContainer = props => (
    <VideolistAllItem
        id={ props.id }
        name={ props.name }
        totalcount={ props.totalcount }
        totalsize={ props.totalsize }
        totaltime={ props.totaltime }
        songs={ props.songs }
        selected={ props.selected }
        markItems={ props.markItems }
        tags={ props.tags }
        variant={ props.variant }
    />
)

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.videolists_all.selected],
})

export default connect(stateToProps, null)(VideolistAllItemContainer)
import {isFunc} from '../../../utilities/isFunc'
import {handlers, actions} from '../../../utilities/handlers'


export function updateHelper({
    name,
    updateOptions,
    handler,
    validate,
    callback,
    handlerType,
    handlerAction = actions.take,
}) {

    return ({ value }) => {
        if(isFunc(handler)) {
            value = handler(value)
        }

        value = handlers({
            type: handlerType,
            name,
            action: handlerAction,
            value,
        })
    
        if(isFunc(validate)) {
            if(validate(value)) {
                updateOptions({ [name]: value })
            }
        } else {
            updateOptions({ [name]: value })
        }
        
        if(isFunc(callback)) {
            callback({ name, value })
        }
    }
}
export const ADD_MARK = "Marks/ADD_MARK"
export const DELETE_MARK = "Marks/DELETE_MARK"
export const UPDATE_MARK = "Marks/UPDATE_MARK"
export const UPDATE_CURRENT_MARK = "Marks/UPDATE_CURRENT_MARK"
export const PUT_CURRENT_MARK = "Marks/PUT_CURRENT_MARK"


export const addMark = markId => ({
    type: ADD_MARK,
    payload: markId
})

export const deleteMark = markId => ({
    type: DELETE_MARK
})

export const updateCurrentMark = () => ({
    type: UPDATE_CURRENT_MARK
})

export const putCurrentMark = markId => ({
    type: PUT_CURRENT_MARK,
    payload: markId
})

export const updateMark = ({ markId, items }) => ({
    type: UPDATE_MARK,
    payload: {
        markId,
        items
    }
})
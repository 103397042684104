import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { cl } from '../../../../../../../utilities/cl'

const SystemReportItem = ({
    id,
    timestamp,
    color,
    name,
    event,
    markItems,
    variant
}) => {
    let className = cl(

        "selectable-item",
        "SystemReport",
        // { "SystemReport": [variant] },
        // { "selected": selected.indexOf(id) !== -1 },
        // { "highlighted": markItems.indexOf(id) !== -1 },
    )
    return (
        <div className={ className } data-object-state-id={ id } >
            <header className="SystemReport__header">
                <div className="SystemsReport__grid" style={ { color: color } }>
                    <div className="timestamp">
                        { timestamp }
                    </div>
                    <div className="event" data-object-state-id={ id }>
                        <span title={ event }>{ event }</span>
                    </div>
                </div>
            </header>
        </div >
    )
}

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    // selected: [...state.public_playlists.selected_song],
})

export default connect(stateToProps, null)(SystemReportItem)
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { cl } from '../../../../../../../../utilities/cl'
import Button from '../../../../../../../../components/Button'
import SvgDownload from '../../../../../../../../components/SVG/Download'
import SvgPlayVideo from '../../../../../../../../components/SVG/PlayVideo'
import SvgShowImage from '../../../../../../../../components/SVG/ShowImage'
import SvgPlay from '../../../../../../../../components/SVG/Play'
import SvgPause from '../../../../../../../../components/SVG/Pause'
import { downloadVideos } from '../../../../../../../../store/ContentVideolists/actions'
import { openModal } from '../../../../../../../../store/Modal/actions'
import { formatSecondsToTime } from '../../../../../../../../utilities/format'
const AdvertReportItem = ({
    id,
    timestamp,
    channel,
    mimetype,
    name,
    clip_id,
    markItems,
    variant,
    downloadVideos,
    openModal,
    pauses,
    play,
    pause,
    stop,
    rewind,
    audio,
    trackPlaying,
    isWatching,
    isPlaying
}) => {
    let channel_name
    switch (channel) {
        case 2:
            channel_name = "Улица"
            break
        case 1:
            channel_name = "Помещение"
            break
        case 3:
            channel_name = "Оба"
            break
    }
    let [watcherInterval, setWatcherInterval] = useState(null)
    const [currentTime, setCurrentTime] = useState("00:00")
    const [slider, setSlider] = useState("100%")
    const prevPlaying = usePrevious(trackPlaying)
    const prevWatching = usePrevious(isWatching)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    useEffect(() => {
        if (trackPlaying !== prevPlaying || isWatching !== prevWatching) {
            if (isWatching) {
                setWatcherInterval(clearInterval(watcherInterval))
                startWatch()
            } else {
                killWatcher()
            }

        }
    }, [trackPlaying])


    function startWatch() {

        if (trackPlaying === clip_id) {
            setWatcherInterval(setInterval(() => {
                if (audio.currentTime === audio.duration) {
                    stop()
                } else {
                    updateTime()
                }
            }, 50))
        } else {
            killWatcher(true)
        }
    }

    function killWatcher(resetTime) {
        clearInterval(watcherInterval)
        updateTime(resetTime)
    }
    const updateTime = (resetTime) => {
        let currentTime = "00:00"
        let slider = "100%"

        if (resetTime !== true && (audio.currentTime !== audio.duration && trackPlaying === clip_id)) {
            // Время
            // alert("ЖОПА")
            currentTime = formatSecondsToTime(audio.currentTime)

            // Слайдер

            const sliderPercent = (audio.currentTime / (audio.duration / 100))
            slider = `${100 - sliderPercent}%`
        }
        setSlider(slider)
        setCurrentTime(currentTime)

    }
    function plays() {
        // pause()
        if (mimetype !== "image/jpeg" && mimetype !== "audio/mpeg") {
            openModal({
                type: "video",
                data: {
                    video: {
                        title: name,
                        mp4: `/clip/play?id=${clip_id}`,
                    }
                }
            })
        } else if (mimetype === "audio/mpeg") {
            play({
                trackId: clip_id,
                filename: `/clip/play?id=${clip_id}`
            })
        } else {
            openModal({
                type: "video",
                data: {
                    video: {
                        title: name,
                        image: name,
                    }
                }
            })
        }
    }
    function pauses() {
        pause()
    }

    function rewinds(e) {
        const rect = e.target.getBoundingClientRect(),
            clickPercent = 100 * (e.nativeEvent.offsetX / rect.width),
            totime = audio.duration * (clickPercent * 0.01)
        rewind(totime)
    }
    let className = cl(

        "selectable-item",
        { "AdvertReport": [variant] },
        { "highlighted": markItems.indexOf(id) !== -1 },
        { "playing": (isPlaying && trackPlaying === clip_id) },
        { "name-fade": slider === "100%" },
    )
    return (
        <div className={ className } data-object-state-id={ id } >
            <header className="AdvertReport__header">
                <span className="rewind" onClick={ rewinds }></span>
                <span className={ cl("slider-1", { "d-none": slider === 0 }) } style={ { right: slider } }></span>
                <span className={ cl("slider-2", { "d-none": slider === 0 }) } style={ { right: slider } }></span>
                <div className="AdvertsReport__grid">
                    <div className="timestamp">
                        { timestamp }
                    </div>
                    <div className="channel" data-object-state-id={ id }>
                        <span title={ channel_name }>{ channel_name }</span>
                    </div>
                    { mimetype !== "audio/mpeg" ?
                        <div className="clip" data-object-state-id={ id }>
                            <span onClick={ plays }>

                                < SvgPlayVideo className="icon-play" variant="prim" size="xs1" title="Воспроизвести" />

                            </span>
                            <span>{ name }</span>
                            <Button
                                type="string"
                                onClick={ () => downloadVideos({ videosIds: clip_id }) }
                            >
                                <SvgDownload />
                            </Button>


                        </div>
                        :
                        <div className="song" data-object-state-id={ id }>
                            <span onClick={ plays }>
                                <SvgPlay className="icon-play" variant="prim" size="xs1" title="Воспроизвести" />
                            </span>
                            <span onClick={ pauses }>
                                <SvgPause className="icon-pause" variant="prim" size="xs1" title="Приостановить" />
                            </span>
                            <span style={ { marginLeft: "10px" } }>{ name }</span>
                        </div>
                    }
                </div>
            </header>
        </div >
    )
}

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    // selected: [...state.public_playlists.selected_song],
})
const actionsToProps = {
    downloadVideos,
    openModal
}
export default connect(stateToProps, actionsToProps)(AdvertReportItem)
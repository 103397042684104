export function formatMinutesToTime(minutes = "") {
    minutes = Number(minutes)

    if(isNaN(minutes)) return ""

    let h = String(Math.floor(minutes/60)).padStart(2, '0'),
        m = String(minutes - (h*60)).padStart(2, '0')
    return `${h}:${m}`
}

export function formatToMinutes(time = "") {
    const hours = Number(time.split(":")[0])
    const minutes = Number(time.split(":")[1])

    if(!isNaN(hours) && !isNaN(minutes)) {
        return (hours * 60) + minutes
    } else {
        return 0
    }
}
export const DID_MOUNT = "App/DID_MOUNT"
export const LOADING = "App/LOADING"
export const LOADING_TEXT = "App/LOADING_TEXT"

export const didMount = () => ({
    type: DID_MOUNT
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const loadingText = txt => ({
    type: LOADING_TEXT,
    payload: txt
})
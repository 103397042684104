import { takeEvery, select, put, call, all } from 'redux-saga/effects'
import { SAVE_USED_ITEMS_MOVING, loadUsedList, mode } from '../../../store/Settings/actions/components/BackgroundLists'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_USED_ITEMS_MOVING, worker)
}

function* worker({ payload }) {
    const { timelineId, stateKey } = payload

    try {

        if (typeof timelineId === "number") {

            const orders = yield select(state => state.settings.backgroundLists[stateKey]?.usedOrder || {})
            let ordered = yield select(state => state.settings.backgroundLists[stateKey]?.ordered || [])

            if (ordered.length) {
                yield put(mode({
                    stateKey,
                    modeType: "__savingUsedItemsMoving",
                    mode: true,
                }))

                ordered = [...new Set(ordered)]

                const fetchedOrdered = yield all(
                    ordered.map(usedId => {
                        if (typeof orders[usedId] === "number") {
                            return call(fetchOrder, usedId, orders[usedId])
                        }
                    })
                )

                let error = false
                fetchedOrdered.map(req => {
                    if (req.error) error = true
                })

                // Ошибка
                if (error) {
                    yield put(showAlertError({
                        errors: [`Ошибка сервера при ${SAVE_USED_ITEMS_MOVING}.`, fetchedOrdered],
                        text: "Сортировка была сохранена не для всех списков. Попробуйте повторить."
                    }))

                    // Успех
                } else {
                    yield put(loadUsedList({
                        timelineId,
                        required: true,
                    }))
                }
            }
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_USED_ITEMS_MOVING}.`, e],
            text: "Сортировка была сохранена не для всех списков. Попробуйте повторить."
        }))
    }

    finally {
        yield put(mode({
            stateKey,
            modeType: "__savingUsedItemsMoving",
            mode: false,
        }))
    }
}

function fetchOrder(id, turn) {
    let formData = new FormData()
    formData.set("turn", turn)

    return axios(
        {
            url: "/msbox/play/list/update",
            method: "POST",
            data: formData,
            params: {
                id
            }
        }
    )
        .then(response => response.data)
}
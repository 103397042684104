import React from 'react'

import {cl} from '../../../../../utilities/cl'
import {mediaTabletAndUp} from '../../../../../utilities/media'
import {isFunc} from '../../../../../utilities/isFunc'
import {getNextSelected} from '../../../../../utilities/getNextSelected'

import modalEditItem from './modals/editItem'

import Group from '../../../components/Group/Group'

import List from '../../../../List'
import Button from '../../../../Button'
import SvgTrash from '../../../../SVG/Trash'
import {formatTimeToRender} from "../../../../../utilities/format";


const GroupSilentList = ({
                             className = "",
                             title,
                             stateKey,
                             type,
                             items = [],
                             deleteItems,
                             selected = [],
                             loading,
                             toggleSelectedItems,
                             openModal,
                             closeModal,
                         }) => {

    className = cl(
        className,
        "ObjectSilentGroupSilentList"
    )

    const hClick = ({id, items}, event) => {
        if (isFunc(toggleSelectedItems)) {
            selected = getNextSelected({
                multiple: true,
                def: 0,
                itemId: id,
                selected,
                ctrlKey: event.ctrlKey || event.metaKey,
                shiftKey: event.shiftKey,
                items,
            })

            toggleSelectedItems({
                type,
                stateKey,
                itemIds: selected,
            })
        }
    }

    const hModalEditItem = ({id}) => modalEditItem({
        stateKey,
        itemId: [id],
        openModal,
    })

    const hModalDeleteItem = ({id}) => {
        openModal({
            type: "confirm",
            size: "sm",
            data: {
                title: "Вы точно хотите удалить данное расписание?",
                buttons: {
                    cancel: {
                        title: "Вернуться",
                        onClick: () => {
                            closeModal("confirm")
                        }
                    },
                    confirm: {
                        title: "Удалить",
                        onClick: () => {
                            deleteItems({stateKey, itemIds: [id]})
                            closeModal("confirm")
                        }
                    }
                }
            }
        })
    }

    return (
        <Group title={title} className={className}>
            <div>
                <List
                    type="simple"
                    header={mediaTabletAndUp({
                        size: "sm",
                        right: <>
                            <Button
                                type="string"
                                onClick={() => deleteItems({stateKey})}
                                disabled={!(selected.length && selected[0] !== 0)}
                            >
                                <SvgTrash size="sm1" variant="dark"/>
                            </Button>
                        </>,
                    })}
                    content={{
                        items: getList()
                    }}
                    selected={selected}
                    loading={loading}
                    onClick={hClick}
                    buttons={{
                        edit: {
                            on: true,
                            mobile: true,
                            onClick: hModalEditItem,
                        },
                        remove: {
                            on: true,
                            mobile: true,
                            onClick: hModalDeleteItem,
                        },
                    }}
                />
            </div>
        </Group>
    )

    function getList() {
        let list = []
        items.map(item => {
            item.fromdate = formatDateToRender(item.fromdate || '')
            item.todate = formatDateToRender(item.todate || '')

            list.push({
                id: item.id,
                element:
                    <div className="ObjectSilentGroupSilentList__item">
                        <div className="date">
                            {(item.fromdate === item.todate)
                                ? (item.fromdate || formatOneDateToRender(item.day, item.month, item.year))
                                : `${item.fromdate} — ${item.todate}`
                            }
                        </div>
                        {
                            (item.fromtime && item.totime) ?
                                <div className="time">{formatTimeToRender(item.fromtime)} - {formatTimeToRender(item.totime)}</div> : ''
                        }
                    </div>
            })
        })
        return list
    }
}

export default GroupSilentList

//
// funcs
//

function formatDateToRender(date = "") {
    if (date.indexOf("-") !== -1) {
        date = date.split("-")
        date = [date[2], date[1], date[0]]
        date = date.join(".")
    }
    return date
}

function formatOneDateToRender(day, month, year) {
    return day.toString().padStart(2, '0') + '.' + month.toString().padStart(2, '0') + '.' + year;
}
import React from 'react'

import {cl} from '../../../../../utilities/cl'

import Group from '../../../../Settings/components/Group/Group'
import Clips from '../../../components/Clips'


class GroupClips extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            opened: true
        }

        this.toggleOpened = this.toggleOpened.bind(this)
    }

    toggleOpened(mode) {
        this.setState(state => {
            if(state.opened !== mode) {
                return { opened: mode }
            }
        })
    }

    render() {
        const {
            indentIds,
            objectIds
        } = this.props

        return(
            <Group className={cl( {"d-none": !this.state.opened} )} key={indentIds}>
                <Clips
                    indentIds={indentIds}
                    objectIds={objectIds}
                    toggleOpened={this.toggleOpened}
                    hideEnded={this.props.hideEnded}
                />
            </Group>
        )
    }
}

export default GroupClips
import React from 'react'


const Space = ({ children }) => (
    <section className="Space">
        <div className="spp-container">
            <div className="Space__wrap-content">

                { children && children }

            </div>
        </div>
    </section>
)

export default Space
import React from 'react'
import { Button as ButtonBS } from 'react-bootstrap'

import SvgPlus from '../SVG/Plus'
import SvgComputer from '../SVG/Computer'
import Button from '../Button'


class Uploader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dragover: false,
        }

        this.input = React.createRef()
        this.area = React.createRef()
        this.inputOpen = this.inputOpen.bind(this)
        this.blockFileTypes = this.blockFileTypes.bind(this)
        this.hChange = this.hChange.bind(this)
        this.hDrop = this.hDrop.bind(this)
        this.hDragEnter = this.hDragEnter.bind(this)
        this.hDragOver = this.hDragOver.bind(this)
        this.hDragLeave = this.hDragLeave.bind(this)
    }

    hChange() {
        let files = [...this.input.current.files]
        const file = files[0]
        console.log({ attrs: this.props.attrs });
        if (file && !this.props.attrs.multiple) {
            if (this.blockFileTypes(file)) {
                if (typeof this.props.onChange === "function") {
                    this.props.onChange(file)
                }
            }
        } else {
            this.props.onChange(files)
        }
    }

    hDrop(e) {
        this.preventDefault(e)
        this.setState({ dragover: false })
        let files = e.dataTransfer.files,
            file
        files = [...files]
        file = files[0]

        console.log({file});

        let checkedFiles = files.filter(file => this.blockFileTypes(file))
        
        if (checkedFiles.length > 1) {
            if (typeof this.props.onChange === "function") {
                this.props.onChange(checkedFiles)
            }
            return
        }
        console.log({file});

        if (this.blockFileTypes(file)) {
            if (typeof this.props.onChange === "function") {
                this.props.onChange(file)
            }
        }
    }

    hDragEnter(e) {
        this.preventDefault(e)
        this.setState({ dragover: true })
    }

    hDragOver(e) {
        this.preventDefault(e)
        this.setState({ dragover: true })
    }

    hDragLeave(e) {
        this.preventDefault(e)
        this.setState({ dragover: false })
    }

    render() {
        const {
            multiple = false,
            className = "",
            label,
            attrs,
            fileTypes,
            trackName
        } = this.props

        return (
            <section className={`Uploader ${className}`}>
                {label &&
                    <label className="Uploader__label">
                        {label}
                    </label>
                }

                <div className="Uploader__wrap">
                    <div
                        className={`Uploader__drop ${this.state.dragover ? "dragover" : ""}`}
                        ref={this.area}
                        onClick={this.inputOpen}
                        onDragEnter={this.hDropEnter}
                        onDragOver={this.hDragOver}
                        onDragLeave={this.hDragLeave}
                        onDrop={this.hDrop}
                    >
                        <div className="title">
                            <SvgPlus variant="dark" size="sm1" />
                            <span>Загрузите новый файл, перетащив его в это поле</span>
                        </div>
                        <div className="comment">
                            * Для загрузки допустимы только файлы в {`${fileTypes.length > 1 ? "форматах" : "формате"}`} {fileTypes.join(", ")}
                        </div>
                    </div>
                    {
                        trackName && trackName !== "—" && <div className="Uploader__trackName"><span>{trackName}</span></div>
                    }
                    <div className="Uploader__or">
                        или
                    </div>
                    <div className="Uploader__button" onClick={this.inputOpen}>
                        <ButtonBS variant="light"><SvgComputer />Выберите файл на диске</ButtonBS>
                    </div>
                </div>

                <input
                    multiple={multiple}
                    type="file"
                    className="Uploader__input"
                    onChange={this.hChange}
                    ref={this.input}
                    value={this.state.track}
                    {...attrs}
                />
            </section>
        )
    }

    inputOpen() {
        this.input.current.click()
    }

    preventDefault(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    blockFileTypes(file) {
        let blocked = true
        if (file.name) {
            if (this.props.fileTypes && this.props.fileTypes.length) {
                this.props.fileTypes.forEach(type => {
                    if (file.name.slice(-(type.length)).toLowerCase() === type) {
                        blocked = false
                    }
                })
            } else {
                return true
            }
        }
        return !blocked
    }
}

export default Uploader
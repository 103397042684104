import React from 'react'

import ScrollContainer from '../../ScrollContainer'
import PlaylistItem from '../PlaylistItem'


const Right = ({
    playlists = [],
    selectedTracks = [],
    toggleSelected,
}) => {

    return (
        // <section className="ObjectsRight Objects__place">
        <section className="">
            <div className="Objects__title">Ролик транслируется на объектах</div>
            <div className="Objects__list" onClick={ toggleSelected }>

                <ScrollContainer className="" size="auto">
                    { (playlists.length && selectedTracks.length)
                        ? playlists.map(playlist =>
                            <PlaylistItem
                                key={ playlist.id }
                                id={ playlist.id }
                                name={ playlist.name }
                            />
                        )
                        : <div className="Objects__message">
                            Объекты будут доступны
                            после того, как вы выберите
                            ролик или группу роликов
                        </div>
                    }
                </ScrollContainer>
            </div>
        </section>
    )
}

export default Right
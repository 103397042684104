import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import { random } from '../../../../utilities/random'
import { cl } from '../../../../utilities/cl'

import Item from '../Group/Item'


const Channel = ({
    className = "",
    channel,
    name = "channel",
    availables = 3,
    updateOptions,
    userRole,
    onChange,
    titles = {
        label: "Рабочий канал",
        1: "Улица",
        2: "Помещение"
    },
    empty,
    showAlways
}) => {
    const hClick = (event) => {
        let itemChannel = Number(event.target.dataset.channel),
            checked = event.target.checked

        channel = channel || 0

        if (typeof channel === "number") {
            checked
                ? applyOnChange(channel + itemChannel, event)
                : applyOnChange(channel - itemChannel, event)
        }
    }

    const highlightChannel = (itemChannel) => {
        if (channel !== empty) {
            return (channel === itemChannel || channel === 3)
        } else {
            const check = (availables === itemChannel || availables === 3)
            return check
        }
    }

    const applyOnChange = (value, event) => {
        if (typeof onChange === "function") {
            onChange({
                name,
                value
            }, event)
        }
        if (typeof updateOptions === "function") {
            updateOptions({ [name]: value })
        }
    }

    const [id, setId] = useState(random());
    const checkId = `settings-channel-${id}`

    className = cl(
        className,
        "SettingsChannel"
    )

    return (
        <Item title={titles.label}>
            <div className={className}>
                <Form.Check
                    inline
                    type="checkbox"
                    label={titles[2]}
                    checked={highlightChannel(2)}
                    data-channel="2"
                    id={`${checkId}-2`}
                    name={name}
                    onClick={ hClick}
                    // disabled={ !(availables === 3 || availables === 2 ) || userRole !== "admin" }
                    disabled={ !showAlways && (!(availables === 3 || availables === 2 ) || userRole !== "admin") }
                />
                <Form.Check
                    inline
                    type="checkbox"
                    label={titles[1]}
                    checked={highlightChannel(1)}
                    data-channel="1"
                    id={`${checkId}-1`}
                    name={name}
                    onClick={hClick}
                    disabled={!(availables === 3 || availables === 1)}
                />
            </div>
        </Item>
    )
}

export default Channel
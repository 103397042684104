import moment from 'moment'
import {getNumbersBetween} from './getNumbersBetween'

export function createMonthsList({ disabledType = "", year, month, selected = "", exclude }, empty) {
    const currentMonth = Number(moment().format('M'))
    const currentYear = Number(moment().format('YYYY'))

    if(exclude === empty && disabledType.length && typeof year === "number") {
        if(currentYear === year) {

            if(disabledType === "past") {
                exclude = getNumbersBetween(0, (month < currentMonth ? month : currentMonth))
            }
            if(disabledType === "future") {
                exclude = getNumbersBetween((month > currentMonth ? month : currentMonth), 13)
            }
        }
    }

    let list = [], item
    
    const months = [
        { title: "Январь", number: 1 },
        { title: "Февраль", number: 2 },
        { title: "Март", number: 3 },
        { title: "Апрель", number: 4 },
        { title: "Май", number: 5 },
        { title: "Июнь", number: 6 },
        { title: "Июль", number: 7 },
        { title: "Август", number: 8 },
        { title: "Сентябрь", number: 9 },
        { title: "Октябрь", number: 10 },
        { title: "Ноябрь", number: 11 },
        { title: "Декабрь", number: 12 }
    ]

    months.map(month => {
        item = {
            title: month.title,
            value: month.number,
            selected:
                typeof selected === "number"
                    ? (selected === month.number)
                    : (month.number === currentMonth)
        }

        if(typeof exclude === "object" && exclude !== null){
            if(exclude.indexOf(month.number) === -1){
                list.push(item)
            }
        } else if(typeof exclude === "function"){
            if(exclude(month.number))
                list.push(item)
        } else {
            list.push(item)
        }
    })

    return list
}
import {takeEvery, select, put} from 'redux-saga/effects'
import {UPDATE_CURRENT_MARK, UPDATE_MARK, putCurrentMark} from '../../store/Marks/actions'


export default function*() {
    yield takeEvery(UPDATE_CURRENT_MARK, worker)
    yield takeEvery(UPDATE_MARK, worker)
}

function* worker() {
    try {
        const selectedTracks = yield select(state => state.tracks.selected)
        const selectedObjects = yield select(state => state.objects.selected)
        const marks = yield select(state => state.marks)

        let currentMarkId =
            (selectedTracks.length > 1 && selectedObjects.length > 1)
                ? 0
                : null

        if(currentMarkId === null) {
            const indentId = selectedTracks[0]
            const objectId = selectedObjects[0]

            Object.keys(marks).forEach(markId => {
                if(Array.isArray(marks[markId].items)) {
                    const m = marks[markId].items

                    if(m.indexOf(indentId) !== -1 && m.indexOf(objectId) !== -1) {
                        currentMarkId = Number(markId)
                    }
                }
            })
        }

        if(currentMarkId === null) currentMarkId = 0

        yield put(putCurrentMark(currentMarkId))
        
    }
    catch(e) {
        yield put(putCurrentMark(0))
    }
}
export const UPDATE               = "ObjectsByTags/UPDATE"
export const PUT_OBJECTS_TAGS     = "ObjectsByTags/PUT_OBJECTS_TAGS"
export const TOGGLE_SELECTED_TAGS = "ObjectsByTags/TOGGLE_SELECTED_TAGS"
export const FILTER_BY_TAG_ID     = "ObjectsByTags/FILTER_BY_TAG_ID"
export const LOADING              = "ObjectsByTags/LOADING"

export const update = () => ({
    type: UPDATE,
})

export const putObjectsTags = ({ tags, tagsAssoc }) => ({
    type: PUT_OBJECTS_TAGS,
    payload: {
        tags,
        tagsAssoc,
    }
})

export const toggleSelectedTags = ({ tagName, selected }) => ({
    type: TOGGLE_SELECTED_TAGS,
    payload: {
        tagName,
        selected
    }
})

export const filterByTagId = ({ tagId }) => ({
    type: FILTER_BY_TAG_ID,
    payload: {
        tagId
    }
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})
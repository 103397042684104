import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_NOTIFICATIONS, loading, putNotifications } from '../../store/Notifications/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_NOTIFICATIONS, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        const notifications = yield call(fetchNotifications)
        if (notifications.error && notifications.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_NOTIFICATIONS}.`, notifications],
                text: "Шаблоны не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putNotifications([]))
        } else {
            yield put(putNotifications({ notifications: notifications.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_NOTIFICATIONS}.`, e],
            text: "Шаблоны не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putNotifications([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchNotifications() {
    return axios.get("/pvdata/select")
        .then(response => response.data)
}

export const UPDATE = "Modal/PlaylistCreate/UPDATE"
export const LOADING = "Modal/PlaylistCreate/LOADING"
export const CREATE_PLAYLIST = "Modal/PlaylistCreate/CREATE_PLAYLIST"
export const EDIT_PLAYLIST = "Modal/PlaylistCreate/EDIT_PLAYLIST"
export const BIND_AVMAP = "Modal/PlaylistCreate/BIND_AVMAP"

export const update = () => ({
    type: UPDATE
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const createPlaylist = playlistData => ({
    type: CREATE_PLAYLIST,
    payload: playlistData
})
export const editPlaylist = playlistData => ({
    type: EDIT_PLAYLIST,
    payload: playlistData
})
export const avMapBinding = bindingData => ({
    type: BIND_AVMAP,
    payload: bindingData
})
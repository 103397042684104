export const LOADING = "Modal/AdsReport/LOADING"
export const LOAD_REPORT = "Modal/AdsReport/LOAD_REPORT"
export const PUT_REPORT = "Modal/AdsReport/PUT_REPORT"
export const SORT_DATA = "Modal/AdsReport/SORT_DATA"

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const loadReport = ({ from, to, objectId }) => ({
    type: LOAD_REPORT,
    payload: {
        from, to,
        objectId,
    }
})

export const sortData = data => ({
    type: SORT_DATA,
    payload: {
        data: data.data,
        from: data.from,
        to: data.to,
        objectId: data.objectId
    }
})

export const putReport = data => ({
    type: PUT_REPORT,
    payload: {
        objects: data.objects,
        from: data.from,
        to: data.to,
        objectId: data.objectId,
        report: data.report
    }
})
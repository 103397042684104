import {takeEvery, call, put, all, select} from 'redux-saga/effects'
import {SELECT_SONGS_ALL, putPublicPlaylists, setSelectSongsAll} from '../../store/PublicPlaylists/actions'
import {showAlertError} from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SELECT_SONGS_ALL, worker)
}

function* worker({payload}) {
    // yield put(loading(true))

    let selectedSongs = yield select(state => state.public_playlists.selected_song)
    if (selectedSongs.length > 0) {
        yield put(setSelectSongsAll([]))
        return;
    }
    let playlistIds = yield select(state => state.public_playlists.selected)
    //const selectedPlaylist = yield select(state => state.playlists.allPlaylists.find(playlist => playlist.id === payload));
    let playlistIds2 = yield select(state => state.playlists.selected)
    const public_tracks_table = yield select(state => state.public_playlists.assocPublicTracksTable)
    const params = {
        id: playlistIds[0]
    }
    if (public_tracks_table.filter && public_tracks_table.filter.blacklist) {
        params.blacklist = playlistIds2[0];
    }
    try {
        const fetchedSongsIds = yield call(fetchSongs, params)

        if (fetchedSongsIds.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${SELECT_SONGS_ALL}.`, fetchedSongsIds],
                text: "Треки не были загружены. Попробуйте перезагрузить страницу."
            }))
        } else {
            const ids = fetchedSongsIds.success
            yield put(setSelectSongsAll(ids))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SELECT_SONGS_ALL}.`, e],
            text: "Треки не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(setSelectSongsAll([]))
    } finally {
        // yield put(loading(false))
    }
}

function fetchSongs(params) {
    return axios.get("/playlist/tracks/ids", {
        params,
    }).then(response => response.data)
}
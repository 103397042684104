export default function({
    settings = {},
    name,
    groups,
    onChange,
}) {

    return [
        {
            type: "Select",
            data: {
                type: "options",
                selected: settings[name],
                onChange,
                placeholder: "Выбор",
                options: groups,
                // multiple: true,
                // resetMultiple: {
                //     title: "Не привязывать",
                //     value: 0
                // },
            }
        }
    ]
}
import React from 'react'
import { Link } from 'react-router-dom'

import { MAIN_OBJECT, MAIN_ADVERT, MAIN_CONTENT } from '../../routes'


const Menu = () => (
    <section className="Settings__Menu">
        <p className='title spp-font-text-lg spp-c-dark spp-o-xl'>Выберите один из разделов:</p>
        <Link to={ MAIN_OBJECT } className='link'>Объект</Link>
        <Link to={ MAIN_ADVERT } className='link'>Реклама</Link>
        <Link to={ MAIN_CONTENT } className='link'>Фоновый контент</Link>
    </section>
)

export default Menu
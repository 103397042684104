import {
    OBJECT_OBJECT,
} from '../store/Settings/sets'

export const actions = {
    take: "take",
    away: "away",
}

const hands = {
    // [OBJECT_OBJECT]: {
    //     "micvol": {
    //         [actions.take]: (val) => (val === "Выключен") ? "0" : val,
    //         // [actions.away]: (val) => (val === "0") ? "Выключен" : val.toString().split(","),
    //     }
    // },
}

export function handObj({
    type = "",
    action = "",
    obj = {},
}) {

    for(let name in obj) {
        obj[name] = handlers({
            type,
            action,
            name,
            value: obj[name],
        })
    }
    return obj
}

export function handlers({
    name,
    value,
    type = "",
    action = "",
}) {
    
    if(hands[type] !== undefined) {
        if(hands[type][name] !== undefined) {
            if(typeof hands[type][name][action] === "function") {
                value = hands[type][name][action](value)
            }
        }
    }
    return value
}
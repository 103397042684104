import React, { useRef } from 'react'

import { MEDIA_BREAKPOINT_LG } from '../../../utilities/breakpoints'
import { componentDisabled } from '../../../utilities/componentDisabled'
import { cl } from '../../../utilities/cl'


const Place = ({
    className = "",
    number,
    expandedPlace,
    activePlace,
    wereActive,
    disabled,
    children,
    style
}) => {

    const refPlace = useRef()

    let statePlace = (activePlace === number) ? "active " : ""
    if (expandedPlace !== 0) statePlace += (expandedPlace === number) ? "expanded " : "not-expanded "

    className = cl(
        "Space__Place",
        { "disabled": disabled },
        statePlace,
        className,
    )

    return (
        <section
            ref={ refPlace }
            className={ className }
            data-place-number={ number }
            onClickCapture={ (e) => componentDisabled(e, disabled) }
            style={ style }
        >
            { window.innerWidth <= MEDIA_BREAKPOINT_LG && (
                (activePlace === number || wereActive.indexOf(number) !== -1) && (
                    children
                )
            ) }
            { window.innerWidth > MEDIA_BREAKPOINT_LG && (
                children
            ) }
        </section>
    )
}

export default Place
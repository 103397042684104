import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button as ButtonBS } from 'react-bootstrap'
import { BsCheckCircleFill, BsFillDashCircleFill, BsExclamationCircleFill, BsFillQuestionCircleFill } from 'react-icons/bs'

import Header from '../components/header'

import InputText from '../../Form/InputText'


let inputValue = ""


const MobileInfoObject = ({
    className = "",
    title,
    input,
    sync_time,
    buttons,
    toggleOpened,
    sync_start_time,
    lastsyncsystem,
    ip
}) => {
    let date_now = new Date()
    let date_now_tzo = date_now.getTimezoneOffset()
    let date_now_tzo_ms = date_now_tzo * 60 * 1000

    let date_sync = new Date(lastsyncsystem)
    let date_now_ms = date_now.getTime()
    let date_sync_ms = date_sync.getTime()

    let diff_date_sync = date_now_ms - date_sync_ms + date_now_tzo_ms
    let color = ''
    let notify = "Плеер MSBOX работает, синхронизируется по графику"
    let icon = <BsCheckCircleFill size="24px" />
    if (lastsyncsystem === "") {
        color = '#7F7F7F'
        icon = <BsFillDashCircleFill size="24px" />
        notify = "Плеер MSBOX ни разу не подключался к сети интернет"
    } else {
        if (diff_date_sync > 60 * 60 * 1000 && diff_date_sync < 300 * 60 * 1000) {
            color = '#E45D05'
            icon = <BsFillQuestionCircleFill size="24px" />
            notify = "Последняя синхронизация плеера MSBOX не завершена, дождитесь завершения следующей"

        } else if (diff_date_sync > 300 * 60 * 1000) {
            color = '#AB0303'
            icon = <BsExclamationCircleFill size="24px" />
            notify = "Требуется вмешательство специалиста, плеер MSBOX не выходит на связь"
        }
    }
    return (
        <div
            className={ `ModalOneInput ${className}` }
            onKeyDown={ keyClose }
        >
            <Header
                // title={ title }
                toggleOpened={ toggleOpened }
            />
            <h2 style={ { marginTop: "-18px" } }>{ title }</h2>
            <h4 style={ { marginTop: "20px", color: color, fontWeight: 400 } }>{ notify }</h4>
            <div style={ {
                display: "flex",
                flexDirection: "row",
                gap: "34px",
                marginTop: "40px",

            } }>
                <div>
                    <span style={ {
                        color: "rgba(10, 10, 10, 0.48)",
                        fontWeight: 400,
                        fontSize: "14px",
                    } }>СИНХРОНИЗИРОВАН</span>
                    <h4 style={ { marginTop: "14px" } }>{ sync_time }</h4>
                </div>
                <div>
                    <span style={ {
                        color: "rgba(10, 10, 10, 0.48)",
                        fontWeight: 400,
                        fontSize: "14px"
                    } }>ВРЕМЯ НАЧАЛА</span>
                    <h4 style={ { marginTop: "14px" } }>{ sync_start_time }</h4>
                </div>
            </div>
            <div style={ {
                display: "flex",
                flexDirection: "row",
                gap: "44px",
                marginTop: "40px",

            } }>
                <div>
                    <span style={ {
                        color: "rgba(10, 10, 10, 0.48)",
                        fontWeight: 400,
                        fontSize: "14px",
                    } }>IP 1</span>
                    <h4 style={ { marginTop: "14px" } }>{ ip }</h4>
                </div>
                <div>
                    <span style={ {
                        color: "rgba(10, 10, 10, 0.48)",
                        fontWeight: 400,
                        fontSize: "14px"
                    } }>СТАТУС</span>
                    <div className="state" style={ { color: color === "" ? '#32CD32' : color, marginTop: "10px" } }>
                        { icon }
                    </div>
                </div>
            </div>

        </div >
    )

    function hChangeSaveValue({ value }) {
        inputValue = value
    }

    function hClickButtonPrim() {
        toggleOpened()
        if (buttons && buttons.prim && typeof buttons.prim.onClick === "function") {
            buttons.prim.onClick(inputValue)
        }
    }

    function hClickButtonForth() {
        toggleOpened()
        if (buttons && buttons.forth && typeof buttons.forth.onClick === "function") {
            buttons.forth.onClick(inputValue)
        }
    }

    function keyClose(e) {
        if (e.key === "Enter") hClickButtonPrim()
    }
}


export default MobileInfoObject
import { LOGIN, DO_LOGIN, LOADING, PASS_RECOVERY, PUT_RECOVERY, GET_USER } from './actions'

const initialState = {
    isAuthorized: false,
    user: {
        name: '',
        logo: '',
    },
    a2: false,
    loading: false,
    error: '',
    recovery_err: ''
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LOGIN:
            if (payload.user && payload.user.logo) {
                payload.user.logo = payload.user.logo + '?t=' + Date.now()
            }
            return {
                ...state,
                isAuthorized: payload.isAuthorized,
                user: payload.user,
                a2: payload.a2 || false,
                error: payload.error
            }
        case DO_LOGIN:
            return {
                ...state,
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case PASS_RECOVERY:
            return {
                ...state,
                recovery_err: payload
            }
        case PUT_RECOVERY:
            return {
                ...state,
                recovery_err: payload
            }
        case GET_USER:
            return {
                ...state,
                user: payload
            }
    }
    return state
}

export default reducer
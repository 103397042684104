export const PUT_CHANGED = "Settings/PUT_CHANGED"
export const CLEAN_CHANGED = "Settings/CLEAN_CHANGED"

export const putChanged = ({ type, fields = [], stateKey }) => ({
    type: PUT_CHANGED,
    payload: {
        type,
        fields,
        stateKey,
    }
})

export const cleanChanged = ({ type, stateKey }) => ({
    type: CLEAN_CHANGED,
    payload: {
        type,
        stateKey,
    }
})
import {
    ADVERT_TRACKS,
    OBJECT_OBJECT,
    OBJECT_MODEM,
    OBJECT_NET,
    OBJECT_BACKGROUND,
    OBJECT_SCHEDULE,
    OBJECT_SILENT,
    OBJECT_VOLUME,
} from './sets'
import {
    UPDATE_OPTIONS,
    CLEAN_SETTINGS,
    TOGGLE_SELECTED_ITEMS,
    SAVING,
    LOADING,
    ADDING,
    DELETING,
    MODE,
} from './actions'
import {
    PUT_PERIODS as PERIODS_PUT_PERIODS,
    LOADING as PERIODS_LOADING,
    SAVING as PERIODS_SAVING,
    ADDING as PERIODS_ADDING,
} from './actions/components/Periods'
import {
    PUT_CHANGED,
    CLEAN_CHANGED,
} from './actions/Changed'

import {
    PUT_ITEMS as PUT_ITEMS_SCHEDULE,
    PUT_TRACKS as PUT_TRACKS_SCHEDULE,
    SELECT_PERIOD as SELECT_PERIOD_SCHEDULE,
    CLEAN_SETTINGS as CLEAN_SETTINGS_SCHEDULE,
    SELECT_BLOCK as SELECT_BLOCK_SCHEDULE,
    PUT_OBJECT_TO_USES_OWN as PUT_OBJECT_TO_USES_OWN_SCHEDULE,
    REMOVE_OBJECT_FROM_USES_OWN as REMOVE_OBJECT_FROM_USES_OWN_SCHEDULE,
} from './actions/sets/Object/Schedule'
import {
    PUT_ITEMS as PUT_ITEMS_BACKGROUND,
    SELECT_PERIOD as SELECT_PERIOD_BACKGROUND,
    CLEAN_SETTINGS as CLEAN_SETTINGS_BACKGROUND,
    PUT_OBJECT_TO_USES_OWN as PUT_OBJECT_TO_USES_OWN_BACKGROUND,
    REMOVE_OBJECT_FROM_USES_OWN as REMOVE_OBJECT_FROM_USES_OWN_BACKGROUND,
} from './actions/sets/Object/Background'
import {
    PUT_ITEMS as PUT_ITEMS_VOLUME,
    CLEAN_BUFFER as CLEAN_BUFFER_VOLUME,
} from './actions/sets/Object/Volume'
import {
    PUT_ITEMS as PUT_ITEMS_SILENT,
    CLEAN_BUFFER as CLEAN_BUFFER_SILENT,
} from './actions/sets/Object/Silent'

import {PUT_CLIPS, PUT_CLIPS_ALL} from './actions/components/Clips'
import {PUT_PRESETS} from './actions/components/Presets'
import {
    PUT_USED_LIST,
    PUT_AVAIL_LIST,
    MODE as MODE_BACKGROUND_LISTS,
    TOGGLE_SELECTED_ITEMS as TOGGLE_SELECTED_ITEMS_BACKGROUND_LISTS,
    PUT_MOVE_USED_ITEM_TO,
    SAVE_USED_ITEM_MOVE_CHANGING,
} from './actions/components/BackgroundLists'


const initialState = {
    settings: {
        [ADVERT_TRACKS]: {
            //daymask: 127,
        },
        [OBJECT_OBJECT]: {},
        [OBJECT_MODEM]: {},
        [OBJECT_NET]: {},
        [OBJECT_BACKGROUND]: {
            daymask: 127,
            // "stateKey": {
            //     selectedPeriod: -1,
            //     selected: [],
            //     __loading: false,
            //     __itemsLoaded: false,
            //     ...settingsBuffer,
            // },
            // "stateKey--1": {
            //     list: [],
            // },
            // "stateKey-periodId": {
            //     list: [],
            // },
            // "stateKey-itemId": {}
        },
        [OBJECT_SCHEDULE]: {
            // "stateKey": {
            //     selectedPeriod: -1,
            //     selected: [],
            //     __loading: false,
            //     __itemsLoaded: false,
            //     ...settingsBuffer,
            // },
            // "stateKey--1": {
            //     list: [],
            // },
            // "stateKey-periodId": {
            //     list: [],
            // },
            // "stateKey-itemId": {}
        },
        [OBJECT_SILENT]: {},
        [OBJECT_VOLUME]: {
            // "stateKey": {
            //     __loading: false,
            //     __itemsLoaded: false,
            //     list: [],
            //     selected: [0]
            // },
            // "stateKey-itemId": {}
        },
    },
    defaultSettings: {
        [ADVERT_TRACKS]: {
            // "__inhour": 1,
            "daymask": 127,
            "channel": '',
        },
        [OBJECT_OBJECT]: {
            __settingsLoaded: false,
            "msstatus_id": null
        },
        [OBJECT_MODEM]: {
            __settingsLoaded: false
        },
        [OBJECT_NET]: {
            __settingsLoaded: false
        },
        [OBJECT_BACKGROUND]: {
            selectedPeriod: -1,
            selected: [],
            daymask: 127,
            __loading: true,
            __itemsLoaded: false,
        },
        [OBJECT_SCHEDULE]: {
            selectedPeriod: -1,
            selected: [],
            tracks: [],
            tracksTheme: [],
            daymask: 127,
            __loading: true,
            __itemsLoaded: false,
        },
        [OBJECT_SILENT]: {},
        [OBJECT_VOLUME]: {
            daymask: 127,
            list: [],
            selected: [0],
            __itemsLoaded: false,
        },
        periods: {
            periods: [],
            periodsAssoc: {},
            loading: false,
            saving: false,
        },
    },
    presets: null,
    clips: {},
    changed: {},
    periods: {},
    backgroundLists: {},
}

const reducer = (state = initialState, action) => {
    if(!action.payload) return state

    let nextState = state
    let {
        type,
        stateKey,
        itemStateKey,
        settings,
        saving,
        loading,
        adding,
        periods,
        periodId,
        tracks,
        periodsAssoc,
        deleting,
        clips,
        items,
        assocItems,
        blocks,
        blockNumber,
        selected,
        fields,
        list,
        playList,
        videoList,
        assocPlayList,
        usedOrder,
        usedId,
        order,
        mode,
        modeType,
        objectId,
    } = action.payload

    switch(action.type){

        case CLEAN_SETTINGS:
            if (type === 'ADVERT_TRACKS') {
                nextState.settings[type][stateKey] = {daymask: 127, __inhour: 0, channel: nextState.settings[type][stateKey].channel}
            } else {
                nextState.settings[type][stateKey] = {}
            }

            return {
                ...nextState
            }

        case UPDATE_OPTIONS:
            nextState.settings[type][stateKey] = {
                ...(state.settings[type][stateKey] || state.defaultSettings[type]),
                ...settings,
            }
            return {
                ...nextState
            }

        case TOGGLE_SELECTED_ITEMS:
            nextState.settings[type][stateKey].selected = selected
            return {
                ...nextState,
            }

        case SAVING:
            nextState.settings[type][stateKey] = {
                ...state.settings[type][stateKey],
                __saving: saving,
            }
            return {
                ...nextState
            }

        case LOADING:
            nextState.settings[type][stateKey] = {
                ...state.settings[type][stateKey],
                __loading: loading,
            }
            return {
                ...nextState
            }

        case ADDING:
            nextState.settings[type][stateKey] = {
                ...state.settings[type][stateKey],
                __adding: adding,
            }
            return {
                ...nextState
            }

        case DELETING:
            nextState.settings[type][stateKey] = {
                ...state.settings[type][stateKey],
                __deleting: deleting,
            }
            return {
                ...nextState
            }

        case MODE:
            nextState.settings[type][stateKey] = {
                ...state.settings[type][stateKey],
                [modeType]: mode,
            }
            return {
                ...nextState
            }

        // Periods

        case PERIODS_PUT_PERIODS:
            nextState.periods[type] = nextState.periods[type] || {...nextState.defaultSettings.periods}
            nextState.periods[type].periods = periods
            nextState.periods[type].periodsAssoc = periodsAssoc
            return {
                ...nextState,
            }

        case PERIODS_LOADING:
            nextState.periods[type] = nextState.periods[type] || {...nextState.defaultSettings.periods}
            nextState.periods[type].loading = loading
            return {
                ...nextState,
            }

        case PERIODS_ADDING:
            nextState.periods[type] = nextState.periods[type] || {...nextState.defaultSettings.periods}
            nextState.periods[type].adding = adding
            return {
                ...nextState,
            }

        case PERIODS_SAVING:
            nextState.periods[type] = nextState.periods[type] || {...nextState.defaultSettings.periods}
            nextState.periods[type].saving = saving
            return {
                ...nextState,
            }

        // Changed

        case PUT_CHANGED:
            nextState.changed[type] = nextState.changed[type] || {}
            nextState.changed[type][stateKey] = nextState.changed[type][stateKey] || []
            nextState.changed[type][stateKey] = Array.from(new Set(nextState.changed[type][stateKey].concat(fields)))
            // nextState.changed[type][stateKey] = nextState.changed[type][stateKey].concat(fields)
            return {
                ...nextState
            }

        case CLEAN_CHANGED:
            nextState.changed[type][stateKey] = []
            return {
                ...nextState
            }

        // Clips

        case PUT_CLIPS:
            nextState.clips[stateKey] = clips
            return {
                ...nextState
            }

        case PUT_CLIPS_ALL:
            nextState.clips = clips
            return {
                ...nextState
            }

        // Presets

        case PUT_PRESETS:
            return {
                ...state,
                presets: action.payload
            }

        // BackgroundLists

        case PUT_USED_LIST:
            nextState.backgroundLists[stateKey] = nextState.backgroundLists[stateKey] || {}
            nextState.backgroundLists[stateKey].list = list
            nextState.backgroundLists[stateKey].usedOrder = usedOrder
            nextState.backgroundLists[stateKey].ordered = []
            nextState.backgroundLists[stateKey].__listLoaded = true
            return {
                ...nextState,
            }

        case PUT_AVAIL_LIST:
            nextState.backgroundLists.avail = nextState.backgroundLists.avail || {}
            nextState.backgroundLists.avail.playList = playList
            nextState.backgroundLists.avail.videoList = videoList
            nextState.backgroundLists.avail.assocPlayList = assocPlayList
            nextState.backgroundLists.avail.__listLoaded = true
            return {
                ...nextState,
            }

        case MODE_BACKGROUND_LISTS:
            nextState.backgroundLists[stateKey] = nextState.backgroundLists[stateKey] || {}
            nextState.backgroundLists[stateKey][modeType] = mode
            return {
                ...nextState
            }

        case TOGGLE_SELECTED_ITEMS_BACKGROUND_LISTS:
            nextState.backgroundLists[stateKey] = nextState.backgroundLists[stateKey] || {}
            nextState.backgroundLists[stateKey].selected = selected
            return {
                ...nextState
            }

        case PUT_MOVE_USED_ITEM_TO:
            nextState.backgroundLists[stateKey] = nextState.backgroundLists[stateKey] || {}
            nextState.backgroundLists[stateKey].list = list
            nextState.backgroundLists[stateKey].usedOrder = usedOrder
            return {
                ...nextState,
            }

        case SAVE_USED_ITEM_MOVE_CHANGING:
            nextState.backgroundLists[stateKey] = nextState.backgroundLists[stateKey] || {}
            nextState.backgroundLists[stateKey].usedOrder = nextState.backgroundLists[stateKey].usedOrder || {}
            nextState.backgroundLists[stateKey].usedOrder[usedId] = order
            nextState.backgroundLists[stateKey].ordered = nextState.backgroundLists[stateKey].ordered || []
            nextState.backgroundLists[stateKey].ordered.push(usedId)
            return {
                ...nextState,
            }

        // ObjectSchedule

        case PUT_ITEMS_SCHEDULE:
            type = OBJECT_SCHEDULE
            // console.log(nextState.settings[type][stateKey])
            nextState.settings[type] = {
                [stateKey]: {
                    ...(nextState.settings[type][stateKey] || nextState.defaultSettings[type]),
                    // daymask: 127,
                    __itemsLoaded: true,
                    blocks: {
                        ...(nextState.settings[type][stateKey]?.blocks || {}),
                        ...blocks,
                    }
                },
                tracks: nextState.settings[type].tracks || {},
                usesOwn: nextState.settings[type].usesOwn || [],
                ...assocItems,
                ...periods,
            }
            return {
                ...nextState,
            }

        case PUT_TRACKS_SCHEDULE:
            type = OBJECT_SCHEDULE
            nextState.settings[type] = nextState.settings[type] || {}
            nextState.settings[type].tracks = tracks
            return {
                ...nextState,
            }

        case SELECT_PERIOD_SCHEDULE:
            type = OBJECT_SCHEDULE
            nextState.settings[type][stateKey] = {
                ...(nextState.settings[type][stateKey] || nextState.defaultSettings[type]),
                selectedPeriod: periodId,
            }
            //console.log(nextState.settings[type][stateKey])
            return {
                ...nextState,
            }

        case PUT_OBJECT_TO_USES_OWN_SCHEDULE:
            nextState.settings[OBJECT_SCHEDULE].usesOwn = nextState.settings[OBJECT_SCHEDULE].usesOwn || []
            const unique = new Set([...nextState.settings[OBJECT_SCHEDULE].usesOwn, objectId]);
            nextState.settings[OBJECT_SCHEDULE].usesOwn = Array.from(unique);//[].concat(nextState.settings[OBJECT_SCHEDULE].usesOwn, [objectId])
            return {
                ...nextState
            }

        case REMOVE_OBJECT_FROM_USES_OWN_SCHEDULE:
            nextState.settings[OBJECT_SCHEDULE].usesOwn = nextState.settings[OBJECT_SCHEDULE].usesOwn || []
            nextState.settings[OBJECT_SCHEDULE].usesOwn = nextState.settings[OBJECT_SCHEDULE].usesOwn.filter(item => item!== objectId)
            return {
                ...nextState
            }

        case CLEAN_SETTINGS_SCHEDULE:
            type = OBJECT_SCHEDULE
            nextState.settings[type][stateKey] = {
                __itemsLoaded: nextState.settings[type][stateKey]?.__itemsLoaded,
                __loading: nextState.settings[type][stateKey]?.__loading,
                selectedPeriod: nextState.settings[type][stateKey]?.selectedPeriod,
                selected: nextState.settings[type][stateKey]?.selected,
                // daymask: nextState.settings[type][stateKey].daymask,
                tracks: nextState.settings[type][stateKey]?.tracks,
                block: -1,
            }
            return {
                ...nextState,
            }

        case SELECT_BLOCK_SCHEDULE:
            type = OBJECT_SCHEDULE
            nextState.settings[type][stateKey].selected = nextState.settings[type][stateKey]?.blocks[blockNumber]
            return {
                ...nextState,
            }

        // ObjectBackground

        case PUT_ITEMS_BACKGROUND:
            type = OBJECT_BACKGROUND
            nextState.settings[type] = {
                [stateKey]: {
                    ...(nextState.settings[type][stateKey] || nextState.defaultSettings[type]),
                    __itemsLoaded: true,
                },
                ...assocItems,
                ...periods,
                usesOwn: nextState.settings[type].usesOwn || [],
            }
            return {
                ...nextState,
            }

        case SELECT_PERIOD_BACKGROUND:
            type = OBJECT_BACKGROUND
            nextState.settings[type][stateKey] = {
                ...(nextState.settings[type][stateKey] || nextState.defaultSettings[type]),
                selectedPeriod: periodId,
            }
            return {
                ...nextState,
            }

        case PUT_OBJECT_TO_USES_OWN_BACKGROUND:
            nextState.settings[OBJECT_BACKGROUND].usesOwn = nextState.settings[OBJECT_BACKGROUND].usesOwn || []
            nextState.settings[OBJECT_BACKGROUND].usesOwn = [].concat(nextState.settings[OBJECT_BACKGROUND].usesOwn, [objectId])
            return {
                ...nextState
            }

        case REMOVE_OBJECT_FROM_USES_OWN_BACKGROUND:
            nextState.settings[OBJECT_BACKGROUND].usesOwn = nextState.settings[OBJECT_BACKGROUND].usesOwn || []
            nextState.settings[OBJECT_BACKGROUND].usesOwn = nextState.settings[OBJECT_BACKGROUND].usesOwn.filter(item => item!== objectId)
            return {
                ...nextState
            }

        case CLEAN_SETTINGS_BACKGROUND:
            type = OBJECT_BACKGROUND
            nextState.settings[type][stateKey] = {
                __itemsLoaded: nextState.settings[type][stateKey]?.__itemsLoaded,
                __loading: nextState.settings[type][stateKey]?.__loading,
                selectedPeriod: nextState.settings[type][stateKey]?.selectedPeriod,
                selected: nextState.settings[type][stateKey]?.selected,
            }
            return {
                ...nextState,
            }

        // ObjectVolume

        case PUT_ITEMS_VOLUME:
            nextState.settings[type][stateKey] = {
                ...state.settings[type][stateKey],
                list: items,
                __itemsLoaded: true,
            }
            nextState.settings[type] = {
                ...state.settings[type],
                ...assocItems,
            }
            return {
                ...nextState,
            }

        case CLEAN_BUFFER_VOLUME:
            nextState.settings[type][itemStateKey] = {}
            return {
                ...nextState,
            }

        // ObjectSilent

        case PUT_ITEMS_SILENT:
            nextState.settings[type][stateKey] = {
                ...state.settings[type][stateKey],
                list: items,
                __itemsLoaded: true,
            }
            nextState.settings[type] = {
                ...state.settings[type],
                ...assocItems,
            }
            return {
                ...nextState,
            }

        case CLEAN_BUFFER_SILENT:
            nextState.settings[type][itemStateKey] = {}
            return {
                ...nextState,
            }
    }
    return state
}

export default reducer
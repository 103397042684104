import React from 'react'


const ArrowUpLight = ({ title = '', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg className={ className } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.175 1.661">
                <title>{ title }</title>
                <path className={ className } d="M1.567 0A.095.095 0 001.5.028L.028 1.5a.094.094 0 10.134.133L1.588.208l1.426 1.425a.094.094 0 10.134-.133L1.676.028a.093.093 0 00-.088-.024C1.581.002 1.574 0 1.567 0z" data-name="Group 1178" fill="#0a0a0a" strokeWidth="1.398" /></svg>
        </i>
    )
}

export default ArrowUpLight
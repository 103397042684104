import React from 'react'


const PlayVideo = ({ title = 'Включить', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.262 3.322">
                <title>{ title }</title>
                <g transform="translate(.014 .015) scale(.23519)" data-name="Rectangle 5401" fill="none" stroke="none" strokeWidth="1.125"><rect width="18" height="14" rx="2" /><path d="M2-.062C.867-.062-.063.868-.063 2v9.998c0 1.133.93 2.064 2.063 2.064h14c1.133 0 2.064-.93 2.064-2.064V2.001c0-1.133-.931-2.063-2.064-2.063zm0 1.125h14c.53 0 .938.409.938.938v9.998c0 .53-.409.939-.938.939H2a.925.925 0 01-.938-.939V2.001c0-.53.41-.938.938-.938zm3.934 2.34a.187.187 0 00-.165.189v7.63a.179.179 0 00.272.16l6.634-3.826a.17.17 0 000-.308h-.004l-6.63-3.825a.187.187 0 00-.107-.02z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#ff3347" /></g></svg>
        </i>
    )
}

export default PlayVideo
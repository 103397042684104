import React, {useState} from 'react'
import InputText from '../../../../../components/Form/InputText'
import {connect} from 'react-redux'
import {Button as ButtonBS} from 'react-bootstrap'
import Select from '../../../../../components/Form/Select'
import InputTextarea from '../../../../../components/Form/InputTextarea'
import {useIMask} from 'use-imask'
import {Form} from 'react-bootstrap'
import {needHelp,} from '../../../../../store/Help/actions'
import Spinner from '../../../../../components/Spinner'

const Callback = ({
                      needHelp,
                      sending_call,
                      sending_question,
                      sended
                  }) => {
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [department, setDepartment] = useState("")
    const [question, setQuestion] = useState("")

    const maskOptions = {
        mask: '+{7}(000)000-00-00',
        placeholderChar: "_",
        lazy: false,
    };
    const [ref] = useIMask(maskOptions, {
        onAccept: e => {
            setPhone(e.target.value);
        },
    });

    const sendQuestion = () => {
        let data = {
            email,
            subject: 2,
            message: question
        }
        needHelp(data)
    }
    const sendCall = () => {
        let data = {
            name,
            subject: 1,
            phone
        }
        needHelp(data)
    }

    let data = [
        {title: "Организация аудио и видео эфира", value: 2},
        {title: "Фоновая музыка", value: 3},
        {title: "Продажа рекламы", value: 4},
        {title: "Студия звукозаписи", value: 5},
        {title: "Техническая поддержка", value: 6},
    ]

    return (
        <div className='Callback'>
            <div className='Callback__phone'>
                <h2>Заказать обратный звонок</h2>
                {sending_call ? <Spinner/> :
                    <div className='Callback__phone_inputs'>
                        <div>
                            <InputText
                                label="ВАШЕ ИМЯ"
                                attrs={{
                                    name: "name",
                                    placeholder: "Введите ваше имя",
                                    value: name
                                }}

                                onChange={(e) => setName(e.value)}
                            />
                        </div>
                        <div>
                            <div className="form-group">
                                <Form.Label>НОМЕР ТЕЛЕФОНА</Form.Label>
                                <input
                                    type="text"
                                    required
                                    label="НОМЕР ТЕЛЕФОНА"
                                    placeholder="+7(___)___-__-__"
                                    className="form-control"
                                    id="exampleFormControlSelect1"
                                    value={phone}
                                    ref={ref}

                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            {sended === 1 &&
            <span style={{fontWeight: 500, color: "green", textAlign: "center"}}>Заявка успешно отправлена. Наш специалист позвонит вам в ближайшее время</span>}
            <div>
                <ButtonBS
                    disabled={name === "" || phone.replace(/[^0-9]/gi, '').length < 11 ? true : false}
                    onClick={sendCall}
                >Отправить</ButtonBS>
            </div>
            {sending_question ? <Spinner/> : <div className='Callback__question'>
                <h2>Задать вопрос специалисту</h2>
                <div className='Callback__question_inputs'>
                    <div>
                        <InputText
                            label="E-MAIL"
                            attrs={{
                                name: "email",
                                placeholder: "Введите ваш e-mail",
                                value: email
                            }}
                            onChange={(e) => setEmail(e.value)}
                        />
                    </div>
                </div>
                <div className='Callback__question_inputs'>
                    <div>
                        <InputTextarea
                            label="ВОПРОС"
                            attrs={{
                                name: "question",
                                value: question
                            }}
                            onChange={(e) => setQuestion(e.value)}
                        />
                    </div>
                </div>
            </div>}
            {sended === 2 &&
            <span style={{fontWeight: 500, color: "green", textAlign: "center"}}>Заявка успешно отправлена. Специалисты ответят на ваш вопрос в ближайшее время</span>}
            <div>
                <ButtonBS
                    disabled={email === "" || question === "" ? true : false}
                    onClick={sendQuestion}
                >Отправить</ButtonBS>
            </div>
        </div>
    )
}

const stateToProps = state => ({
    sending_call: state.help.sending_call,
    sending_question: state.help.sending_question,
    sended: state.help.sended,
})

const actionsToProps = {
    needHelp
}


export default connect(stateToProps, actionsToProps)(Callback)
import moment from 'moment'
import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import {
    loading,
    loading_assoc,
    putPublicPlaylists,
    putAssocMobilePublicTracks,
    putAssocPublicTracks,
    LOAD_ASSOC_PUBLIC_TRACKS,
    setSelectSongsAll
} from '../../store/PublicPlaylists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { CLEAR_VALUE, changeValue } from '../../store/Search/actions'

export default function* () {
    yield takeEvery(LOAD_ASSOC_PUBLIC_TRACKS, worker)
    yield takeEvery(CLEAR_VALUE, worker)
}

function* worker({ payload }) {

    if (payload.offset === 0) {
        yield put(loading_assoc(true))
    }

    try {
        let playlistIds = yield select(state => state.public_playlists.selected)
        let playlistIds2 = yield select(state => state.playlists.selected)
        playlistIds = playlistIds[0];
        if (payload.current) {
            playlistIds = payload.current
        }
        const public_tracks = yield select(state => state.public_playlists.assocPublicTracks)
        const public_tracks_table = yield select(state => state.public_playlists.assocPublicTracksTable)
        const searchName = payload.name || '';




        const assoc_public_tracks = {}
        const params = !!searchName.length ?
            {
                playlist_id: playlistIds, offset: payload.offset, name: searchName
            } : {
                id: playlistIds, offset: payload.offset
            }



        if (public_tracks_table.sort_field) {
            params[`sort[${[public_tracks_table.sort_field]}]`] = public_tracks_table.sort_dir;
        }
        if (public_tracks_table.filter && public_tracks_table.filter.blacklist) {
            params.blacklist = playlistIds2[0];
        }
        const fetchedAssocPublicTracks = yield call(fetchAssocPublicTracks, { ...params })



        if (fetchedAssocPublicTracks.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_ASSOC_PUBLIC_TRACKS}.`, fetchedAssocPublicTracks],
                text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putAssocPublicTracks({ assoc_public_tracks }))
            yield put(setSelectSongsAll([]))
        } else {
            const apt = fetchedAssocPublicTracks.success
            Object.values(apt).map(track => {
                assoc_public_tracks[track.id] = { ...track, filename: buildGETUrl("clip/play", { id: track.id }) }

            })


            if (payload.current) {
                yield put(putAssocMobilePublicTracks({ assoc_public_tracks, current: payload.current }))
            } else {
                if (payload.offset === 0) {
                    yield put(putAssocPublicTracks({ assoc_public_tracks }))
                } else {
                    yield put(putAssocPublicTracks({ assoc_public_tracks: { ...public_tracks, ...assoc_public_tracks } }))
                }
            }

        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_ASSOC_PUBLIC_TRACKS}.`, e],
            text: "Плейлисты не были загружены. Попробуйте перезагрузить страницу." + e
        }))
        yield put(putAssocPublicTracks({ assoc_public_tracks: {} }))
    }

    finally {
        yield put(loading_assoc(false))
    }
}

function fetchAssocPublicTracks(params) {
    // if (params.sort) {
    //     params
    // }
    return axios.get(`/playlist/tracks/${!!params.name ? "search" : "select"}`, {
        params: {
            limit: 50,
            ...params
            // // id: playlistId.id,
            // offset: params.offset,
            // sort[]
        }
    })
        .then(response => response.data)
}
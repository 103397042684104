import {takeEvery, call, put} from 'redux-saga/effects'
import {loadRoles, UPDATE_ROLE, loading} from '../../store/Roles/actions'
import {showAlertError} from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(UPDATE_ROLE, worker)
}

function* worker({payload}) {
    yield put(loading(true))
    try {
        const response = yield call(fetchUpdate, {...payload})
        if (response.error && response.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_ROLE}.`, response],
                text: "Пользователи не были обновлены. Попробуйте перезагрузить страницу."
            }))
            yield put(loadRoles())
        } else {
            // yield put(loadSubusers())
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_ROLE}.`, e],
            text: "Пользователи не были обновлены. Попробуйте перезагрузить страницу."
        }))
        yield put(loadRoles())
    } finally {
        yield put(loadRoles())
        // yield put(closeModal("templateCreate"))
    }
}

function fetchUpdate(data) {
    let formData = new FormData()
    for (let name in data) {
        formData.set(name, data[name])
    }
    return axios({
        url: "/account/roles/update",
        method: "POST",
        data: formData
    })
        .then(response => response.data)
}

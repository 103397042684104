import React from 'react'
import { connect } from 'react-redux'

import Place from '../../../../components/Space/Place'
import Empty from '../../../../components/Space/Empty'

import SlideMenu from '../../../../components/SlideMenu'
import HelpContent from './HelpContent'

// import SettingsObject from './SettingsObject'
// import SettingsModem from './SettingsModem'
// import SettingsNet from './SettingsNet'


const Where = ({ selectedObjects }) => (
    <>
        {/* 2 */ }

        <Place
            number={ 2 }
            className="ObjectsWhere"
        >

            <SlideMenu
                activeItem={ 1 }
                items={ [
                    {
                        // title: "Настройки объекта",
                        content: <HelpContent placeNumber={ 2 } active />
                    }
                ] }
            />

        </Place>
    </>
)

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
})

export default connect(stateToProps, null)(Where)
import React from 'react'
import PropTypes from 'prop-types'
import outside from '../../../utilities/outside'
import { cl } from '../../../utilities/cl'
import { random } from '../../../utilities/random'
import { MEDIA_BREAKPOINT_LG } from '../../../utilities/breakpoints'
import { isTablet, isDesktop } from '../../../utilities/media'
import { changeCollapsible } from '../../../store/Collapsible/actions'
import ButtonCircle from '../../ButtonCircle'
import ButtonWhite from '../../ButtonWhite'
import ElementsGroup from '../../ElementsGroup'
import Button from '../../Button'
import ScrollContainer from '../../ScrollContainer'

import SvgPlus from '../../SVG/Plus'
import SvgArrowDown from '../../SVG/ArrowDown'
import SvgEdit from '../../SVG/Edit'
import SvgTrash from '../../SVG/Trash'
import SvgSave from '../../SVG/Save'
import SvgDownload from '../../SVG/Download'
import { connect } from 'react-redux'



class Collapsible extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            collapsibleId: this.props.id || random(),
            contentLoaded: true,
            mobileControl: false,
            active: this.props.active || this.props.activeDefault || false,
        }

        this.refToggle1 = React.createRef()
        this.refToggle2 = React.createRef()

        this.toggleActive = this.toggleActive.bind(this)
        this.mobileControlToggle = this.mobileControlToggle.bind(this)
        this.collapseOtherCollapsiblesInThisSpace = this.collapseOtherCollapsiblesInThisSpace.bind(this)
    }



    toggleActive(val, event) {

        this.setState(state => ({
            contentLoaded: true,
            active: !state.active,
        }), () => {
            this.collapseOtherCollapsiblesInThisSpace(event, this.state.active)
        })
        if (this.props.name === "music" || this.props.name === "video") {
            if (!this.state.active) {
                this.props.changeCollapsible(this.props.name)
            }
        }

    }

    collapseOtherCollapsiblesInThisSpace(event, currentMode) {
        if (isTablet() || isDesktop()) {
            if (currentMode === true) {
                if (event.clientX !== 0) {
                    const thisSpace = this.refToggle1.current?.closest(".Space__Place")

                    if (thisSpace) {
                        const togglers = thisSpace.querySelectorAll(".Collapsible.active .toggle-active-1")

                        for (let toggler of togglers) {
                            if (toggler !== this.refToggle1.current) {
                                toggler.click()
                            }
                        }
                    }
                }
            }
        }
    }

    render() {
        // alert(JSON.stringify(this.props.collapsible))
        const {
            className = "",
            title = "",
            subtitle,
            notice,
            mobileControl,
            scroll = {},
            elements,
            children,
            types = []
        } = this.props
        const {
            active,
            activeMobileControl,
            contentLoaded
        } = this.state

        const scrollData = scroll.data || {}

        const collapsibleClassName = cl(
            className,
            { "Collapsible": types },
            { "active": active || this.props.active }
        )

        const mobileControlClassName = cl(
            "CollapsibleMobileControl",
            { "active": activeMobileControl }
        )

        return (
            <section className={ collapsibleClassName }>

                <header className="Collapsible__header">
                    { window.outerWidth >= MEDIA_BREAKPOINT_LG
                        ? <div className="Collapsible__desktop">
                            <div className="left">
                                <h2 className="Collapsible__title" onClick={ (e) => this.toggleActive(null, e) }>
                                    { title }
                                    { subtitle
                                        && <span className="subtitle">
                                            { subtitle }
                                        </span>
                                    }
                                </h2>
                                <div className="control">
                                    { this.getButton("add") }
                                    { this.getButton("toggle-active") }
                                </div>
                            </div>

                            <div className="right">
                                <ElementsGroup position="right">
                                    { this.getButton("download") }
                                    { elements }
                                    <div className="d-mg-none flex-grow-1"></div>
                                    { this.getButton("edit") }
                                    { this.getButton("remove") }
                                    { this.getButton("save") }
                                    { this.getButton("toggle-active-title") }
                                </ElementsGroup>
                            </div>
                        </div>

                        : <div className="Collapsible__mobile">
                            { this.getButton("add", true) }
                            { elements &&
                                <div className="elements">
                                    { elements }
                                </div>
                            }
                        </div>
                    }
                    { notice &&
                        <div className="Collapsible__notice">
                            { notice }
                        </div>
                    }
                </header>

                { (contentLoaded || active) &&
                    <>
                        { scroll.on
                            ? <ScrollContainer size="lg" collapsed={ false } { ...scrollData }>
                                { children }
                            </ScrollContainer>

                            : children
                        }
                    </>
                }

                {/* maxHeight={`${scrollHeight}px`} */ }

                { mobileControl !== false &&
                    outside(
                        <div className={ mobileControlClassName }>
                            { this.getButton("edit", true) }
                            { this.getButton("download", true) }
                            { this.getButton("remove", true) }
                            { this.getButton("save", true) }
                        </div>
                    )
                }
            </section>
        )
    }

    getButton(type, mobile) {
        const isSimple = (Array.isArray(this.props.types) && this.props.types?.indexOf("simple") !== -1)

        if (isSimple) return null

        if (this.props.buttons) {
            const {
                add,
                download,
                edit,
                remove,
                save,
            } = this.props.buttons

            if (type === "add" && !mobile && add?.on)
                return (
                    <ButtonCircle
                        className="plus"
                        disabled={ add.disabled }
                        onClick={ add.onClick }
                    >
                        <SvgPlus />
                    </ButtonCircle>
                )

            if (type === "add" && mobile && add?.mobile !== false && add?.on)
                return (
                    <ButtonWhite
                        title={ add?.title }
                        mode="extend"
                        className="plus-mobile"
                        disabled={ add.disabled }
                        onClick={ add.onClick }
                    >
                        <SvgPlus />
                    </ButtonWhite>
                )

            if (type === "download" && !mobile && download?.on)
                return (
                    <Button
                        type="string"
                        onClick={ download.onClick?.bind(this, download.value) }
                        disabled={ download.disabled }
                    >
                        <SvgDownload />
                        Скачать
                    </Button>
                )

            if (type === "download" && mobile && download?.mobile !== false && download?.on)
                return (
                    <ButtonCircle
                        className="download"
                        onClick={ download.onClick?.bind(this, download.value) }
                        disabled={ download.disabled }
                    >
                        <SvgDownload />
                    </ButtonCircle>
                )

            if (type === "edit" && !mobile && edit?.on)
                return (
                    <Button
                        type="string"
                        onClick={ edit.onClick?.bind(this, edit.value) }
                        disabled={ edit.disabled }
                        onClick={ edit.onClick }
                    >
                        <SvgEdit className="m-0" />
                    </Button>
                )

            if (type === "edit" && mobile && edit?.mobile !== false && edit?.on)
                return (
                    <ButtonWhite
                        title="Изменить"
                        mode="extend"
                        onClick={ edit.onClick?.bind(this, edit.value) }
                        disabled={ edit.disabled }
                        onClick={ edit.onClick }
                    >
                        <SvgEdit />
                    </ButtonWhite>
                )

            if (type === "remove" && !mobile && remove?.on)
                return (
                    <Button
                        type="string"
                        disabled={ remove.disabled }
                        onClick={ remove.onClick }
                    >
                        <SvgTrash className="m-0" />
                    </Button>
                )

            if (type === "remove" && mobile && remove?.mobile !== false && remove?.on)
                return (
                    <ButtonWhite
                        title="Удалить"
                        mode="extend"
                        disabled={ remove.disabled }
                        onClick={ remove.onClick }
                    >
                        <SvgTrash />
                    </ButtonWhite>
                )

            if (type === "save" && !mobile && save?.on)
                return (
                    <Button
                        type="string"
                        disabled={ save.disabled }
                        onClick={ save.onClick }
                    >
                        <SvgSave className="m-0" />
                    </Button>
                )

            if (type === "save" && mobile && save?.mobile !== false && save?.on)
                return (
                    <ButtonWhite
                        title="Сохранить"
                        mode="extend"
                        disabled={ save.disabled }
                        onClick={ save.onClick }
                    >
                        <SvgSave />
                    </ButtonWhite>
                )

        }

        if (type === "toggle-active")
            return (
                <Button className="toggle-active toggle-active-1" buttonRef={ this.refToggle1 } type="string" onClick={ this.toggleActive }>
                    <SvgArrowDown className="spp-svg-size-mr m-0" title={ this.state.active ? "Свернуть" : "Развернуть" } />
                </Button>
            )

        if (type === "toggle-active-title")
            return (
                <Button className="toggle-active toggle-active-2" buttonRef={ this.refToggle2 } type="string" onClick={ this.toggleActive }>
                    <SvgArrowDown className="spp-svg-size-mr" title={ this.state.active ? "Свернуть" : "Развернуть" } />
                    { this.state.active ? "Свернуть" : "Развернуть" }
                </Button>
            )

        return null
    }

    mobileControlToggle() {
        this.setState(state => ({
            activeMobileControl: !state.activeMobileControl
        }))
    }
}

Collapsible.propTypes = {
    className: PropTypes.string,
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    placeNumber: PropTypes.number,
    mobileControl: PropTypes.bool,
    elements: PropTypes.element,
    buttons: PropTypes.object,
    activeDefault: PropTypes.bool,
    types: PropTypes.arrayOf((value, key, componentName, propName) => {
        const values = ["simple", "embedded"]
        if (values.indexOf(value[key]) === -1)
            return new Error(`Проп ${propName} компонента ${componentName} имеет неправильное значение`)
    })
}

const mapDispatchToProps = {
    changeCollapsible
};
let buttonsSample = {
    add: {
        on: false,
        onClick: "",
        value: "",
        disabled: false,
    },
}

export default connect(null, mapDispatchToProps)(Collapsible)
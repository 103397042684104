export const LOAD_SYSTEM_REPORT = "Reports/System/LOAD_SYSTEM_REPORT"
export const LOADING = "Reports/System/LOADING"
export const PUT_SYSTEM_REPORT = "Reports/System/PUT_SYSTEM_REPORT"
export const SORT_SYSTEM_REPORT = "Reports/System/SORT_SYSTEM_REPORT"

export const loadSystemReport = date => ({
    type: LOAD_SYSTEM_REPORT,
    payload: date
})
export const loading = mode => ({
    type: LOADING,
    payload: mode
})
export const putSystemReport = system_report => ({
    type: PUT_SYSTEM_REPORT,
    payload: system_report
})
export const sortSystemReport = sortSource => ({
    type: SORT_SYSTEM_REPORT,
    payload: sortSource
})
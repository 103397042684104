export const LOAD_PERIODS = "Settings/Periods/LOAD_PERIODS"
export const PUT_PERIODS = "Settings/Periods/PUT_PERIODS"
export const ADD_PERIOD = "Settings/Periods/ADD_PERIOD"
export const SAVE_PERIOD = "Settings/Periods/SAVE_PERIOD"
export const COPY_PERIOD = "Settings/Periods/COPY_PERIOD"
export const DELETE_PERIOD = "Settings/Periods/DELETE_PERIOD"

export const LOADING = "Settings/Periods/LOADING"
export const ADDING = "Settings/Periods/ADDING"
export const SAVING = "Settings/Periods/SAVING"

export const loadPeriods = ({ type, required }) => ({
    type: LOAD_PERIODS,
    payload: {
        type,
        required: required || false,
    }
})

export const putPeriods = ({ type, periods, periodsAssoc }) => ({
    type: PUT_PERIODS,
    payload: {
        type,
        periods,
        periodsAssoc,
    }
})

export const addPeriod = ({ type, period, copydate, selected, copy_ms_id }) => ({
    type: ADD_PERIOD,
    payload: copy_ms_id ?
        {
            type,
            period,
            copydate,
            selected,
            copy_ms_id
        } :
        {
            type,
            period,
            copydate,
            selected
        }
})

export const savePeriod = ({ type, id, period }) => ({
    type: SAVE_PERIOD,
    payload: {
        type,
        id,
        period,
    }
})

export const copyPeriod = ({ type, id }) => ({
    type: COPY_PERIOD,
    payload: {
        type,
        id
    }
})

export const deletePeriod = ({ type, id, period }) => ({
    type: DELETE_PERIOD,
    payload: {
        type,
        id,
        period,
    }
})

export const loading = ({ type, loading }) => ({
    type: LOADING,
    payload: {
        type,
        loading
    }
})

export const adding = ({ type, adding }) => ({
    type: ADDING,
    payload: {
        type,
        adding
    }
})

export const saving = ({ type, saving }) => ({
    type: SAVING,
    payload: {
        type,
        saving
    }
})
export const LOAD_TEMPLATES = "Settings/Templates/LOAD_TEMPLATES"
export const LOADING = "Settings/Templates/LOADING"
export const PUT_TEMPLATES = "Settings/Templates/PUT_TEMPLATES"
export const UPDATE_TEMPLATES = "Settings/Templates/UPDATE_TEMPLATES"

export const loadTemplates = () => ({
    type: LOAD_TEMPLATES,
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const putTemplates = templates => ({
    type: PUT_TEMPLATES,
    payload: templates
})

export const updateTemplates = templates => ({
    type: UPDATE_TEMPLATES,
    payload: templates
})



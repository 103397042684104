import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {cl} from '../../utilities/cl'
import {isMobile} from '../../utilities/media'
import {getNextSelected} from '../../utilities/getNextSelected'

import ScrollContainer from '../ScrollContainer'
import Left from './Left/Left'
import Right from './Right/Right'

const Videolists = ({
                        className = "",
                        types = [],

                        allVideolists,
                        trackObjects,

                        selectedVideolists,

                        loading,
                        searchActive,
                        searchFounded,

                        loadVideolists,
                        loadVideolist,
                        toggleSelectedVideolist,
                    }) => {
    const [doubleView, setDoubleView] = useState(false)
    useEffect(() => {
        loadVideolists()
        // loadPublicPlaylists()
    }, [])

    className = cl(
        className,
        "Videolists",
        {"double": doubleView}
    )

    const hToggleSelected = (e) => {
        const videolist = e.target.closest(".Videolist")
        //return;
        if (videolist) {
            const videolistId = Number(videolist.dataset.videolistId)

            if (!isNaN(videolistId)) {
                const selected = getNextSelected({
                    multiple: true,
                    itemId: videolistId,
                    items: allVideolists,
                    selected: selectedVideolists,
                    shiftKey: e.shiftKey,
                    ctrlKey: isMobile() || e.ctrlKey || e.metaKey,
                })

                toggleSelectedVideolist({
                    selected,
                    simple: true,
                })
                loadVideolist(selected[0]);
            }
        }
    }
    const isSimple = (Array.isArray(types) && types?.indexOf("simple") !== -1)

    return (
        <section className={className} onClick={() => setDoubleView(!doubleView)}>

            <Left
                videolists={allVideolists}
                loading={loading}
                searchActive={searchActive}
                searchFounded={searchFounded}
                toggleSelected={hToggleSelected}
            />
            {!isSimple && (
                <Right
                    objects={trackObjects}
                    //selectedTracks={ selectedTracks }
                    toggleSelected={hToggleSelected}
                />
            )}

        </section>
    )
}

Videolists.propTypes = {
    types: PropTypes.arrayOf((value, key, componentName, propName) => {
        const values = ["simple"]
        if (values.indexOf(value[key]) === -1)
            return new Error(`Проп ${propName} компонента ${componentName} имеет неправильное значение`)
    })
}

export default Videolists
import React from 'react'
import Navbar from '../../components/Navbar'
// import Panel from './Panel'
// import Workspace from './Workspace'
import LoginForm from '../../components/Login'
import { SETTINGS_CONTENT } from '../routes'
import { openModal, closeModal } from '../../store/Modal/actions'

const LoginPage = props => {
    return (
        <section >
            <LoginForm />
        </section>
    )
}

export default LoginPage
import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../../utilities/generateStateKey'
import {cl} from '../../../../../utilities/cl'

import {OBJECT_VOLUME} from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'
import ScrollContainer from '../../../../../components/ScrollContainer'


const SettingsVolumeCollapsible = ({
    className = "",
    selectedObjects,
    placeNumber,
    active
}) => {

    const stateKey = generateStateKey([ selectedObjects ])

    return(
        <Collapsible
            active={active}
            id={3331}
            className={className}
            title="Громкость"
            placeNumber={placeNumber}
        >
            <Settings
                set={OBJECT_VOLUME}
                data={{
                    stateKey: stateKey,
                }}
            />
        </Collapsible>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
})

export default connect(stateToProps, null)(SettingsVolumeCollapsible)
import React from 'react'


const SettingsHeader = ({ title, lineTitle, lineTitleType }) => (
    <header className="Settings__header">
    
        {lineTitle
            && <div className="Settings__element-type-title">
                <div>{lineTitle}</div>
            </div>
        }

        {title
            && <div className="Settings__title">
                <h3>{title}</h3>
            </div>
        }

    </header>
)

export default SettingsHeader
import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS, Form } from 'react-bootstrap'

import Header from '../components/header'
import { download } from '../../../store/Modal/types/Castinfo/actions'

import Datepicker from '../../Form/Datepicker'


class Castinfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dates: {},
            allPeriod: false,
        }
        this.setDate = this.setDate.bind(this)
        this.toggleAllPeriod = this.toggleAllPeriod.bind(this)
        this.hSubmit = this.hSubmit.bind(this)
    }

    toggleAllPeriod(event) {
        this.setState({ allPeriod: event.target.checked })
    }

    setDate(selected) {
        this.setState({
            dates: selected
        })
    }

    hSubmit() {
        this.props.download({
            dates: this.state.dates,
            allPeriod: this.state.allPeriod,
        })
    }

    render() {
        const { downloading, toggleOpened } = this.props
        return (
            <div className="ModalCastinfo">
                <Header title="Эфирная справка" toggleOpened={ toggleOpened } />

                <div className="ModalCastinfo__content">
                    <div>
                        <Datepicker
                            type="between"
                            variant="icon"
                            onChange={ this.setDate }
                            disabled={ this.state.allPeriod }
                            titles={ {
                                main: "Период трансляции",
                                from: "Дата начала трансляции",
                                to: "Дата окончания трансляции",
                            } }
                            data={ {
                                oneDate: false,
                                //disabledType: "future",
                            } }
                        />
                    </div>
                    <Form.Check
                        inline
                        type="checkbox"
                        label="Скачать Эфирную справку за весь период"
                        id="modal-castinfo-check-1"
                        onChange={ this.toggleAllPeriod }
                    />
                </div>

                <div className="Modal__footer">
                    <ButtonBS variant="primary" onClick={ this.hSubmit } disabled={ downloading }>
                        { downloading ? "Скачивание..." : "Скачать" }
                    </ButtonBS>
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
    downloading: state.modalCastinfo.downloading
})

const actionsToProps = {
    download
}

export default connect(stateToProps, actionsToProps)(Castinfo)
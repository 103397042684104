import { takeEvery, select, put, call, all } from 'redux-saga/effects'
import { DELETE_USED_ITEMS, loadUsedList, mode, toggleSelectedItems } from '../../../store/Settings/actions/components/BackgroundLists'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'
import {loadObjects} from "../../../store/Objects/actions";

export default function* () {
    yield takeEvery(DELETE_USED_ITEMS, worker)
}

function* worker({ payload }) {
    const { stateKey, timelineId } = payload

    try {
        const usedSelected = yield select(state => state.settings.backgroundLists[stateKey]?.selected || [])

        if (usedSelected.length) {
            yield put(mode({
                stateKey,
                modeType: "__deleting",
                mode: true,
            }))

            const deleted = yield all(
                usedSelected.map(listId =>
                    call(fetchDelete, listId)
                )
            )

            let error = false
            deleted.map(req => {
                if (req.error) error = true
            })

            // Ошибка
            if (error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${DELETE_USED_ITEMS}.`, deleted],
                    text: "Не все списки были удалены. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(toggleSelectedItems({
                    stateKey,
                    selected: [],
                }))
                yield put(loadUsedList({
                    timelineId,
                    required: true,
                }))
                yield put(loadObjects())
            }
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_USED_ITEMS}.`, e],
            text: "Не все списки были удалены. Попробуйте повторить."
        }))
    }

    finally {
        yield put(mode({
            stateKey,
            modeType: "__deleting",
            mode: false,
        }))
    }
}

function fetchDelete(id) {
    return axios.get("/msbox/play/list/delete", { params: { id } })
        .then(response => response.data)
}
import React from 'react'


const Pause = ({ title = 'Приостановить', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.821 4.233">
                <title>{ title }</title>
                <path d="M0 0v4.233h.705V0zm2.116 0v4.233h.705V0z" fill="#ff3347" strokeWidth=".75" /></svg>
        </i>
    )
}

export default Pause
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ButtonCircle from '../../../../../../../components/ButtonCircle'
import { cl } from '../../../../../../../utilities/cl'
import { openModal } from '../../../../../../../store/Modal/actions'
import { getNextSelected } from '../../../../../../../utilities/getNextSelected'
import { isMobile } from '../../../../../../../utilities/media'
import { findByLabelText } from '@testing-library/dom'
import { toggleSelectedObject } from '../../../../../../../store/Objects/actions'
import { toggleSelectedObjectState } from '../../../../../../../store/Reports/actions'
const ObjectsStateItem = ({
    id,
    name,
    sync_time,
    sync_start_time,
    ip,
    error,
    markItems,
    lastsyncsystem,
    filteredTagId = -1,
    variant,
    tags = {},
    openModal,
    selectedObjects,
    selected,
    toggleSelectedObject,
    toggleSelectedObjectState,
    address
}) => {

    let className = cl(

        "selectable-item",
        { "ObjectState": [variant] },
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
    )
    if (filteredTagId !== -1) {
        if (tags[filteredTagId].objectsIds.indexOf(id) === -1) {
            return null
        }
    }
    let date_now = new Date()
    let date_now_tzo = date_now.getTimezoneOffset()
    let date_now_tzo_ms = date_now_tzo * 60 * 1000

    let date_sync = new Date(lastsyncsystem)
    let date_now_ms = date_now.getTime()
    let date_sync_ms = date_sync.getTime()

    let diff_date_sync = date_now_ms - date_sync_ms + date_now_tzo_ms
    let color
    let notify
    if (lastsyncsystem === "") {
        color = '#7F7F7F'
        // notify = "Не был синхронизирован"
    } else {
        if (diff_date_sync > 60 * 60 * 1000 && diff_date_sync < 300 * 60 * 1000) {
            color = '#E45D05'
            // notify = "Не отвечает более часа"
        } else if (diff_date_sync > 300 * 60 * 1000) {
            color = '#AB0303'
            // notify = "Не отвечает более 5 часов"
        }
    }
    const hToggleSelected = (e) => {
        const object = e.target.closest(".List__item")
        if (object) {
            const objectId = Number(object.dataset.listItemId)
            if (!isNaN(objectId)) {
                toggleSelectedObject({ id: objectId })
                toggleSelectedObjectState({
                    id: objectId,

                })
            }
        }
    }
    const hModalInfo = () => {
        openModal({
            type: "mobileInfoObject",
            size: "sm",
            data: {
                title: name,
                id: id,
                error: error,
                notify: notify,
                sync_time: sync_time,
                ip: ip,
                color: color,
                lastsyncsystem: lastsyncsystem,
                sync_start_time: sync_start_time

            }
        })
    }
    return (
        <div className={ className } data-object-state-id={ id } style={ { cursor: "pointer" } } >
            <header className="ObjectState__header" style={ { display: "flex", flexDirection: "row", alignItems: "center" } }>
                <div className="ObjectsState__grid" style={ { width: "100%" } } data-object-state-id={ id } onClick={ (e) => hToggleSelected(e) }>

                    <div className="id" >
                        { id }
                    </div>

                    <div className="name" data-object-state-id={ id } style={ { color: color } }>
                        <span title={ name }>{ name.length > 36 ? `${name.substr(0, 37)}...` : name } { address }</span>
                    </div>
                    <div className="error" data-object-state-id={ id } style={ { color: color } }>
                        { error }
                    </div>


                </div>
                <div style={ { marginRight: "8px" } }>
                    <ButtonCircle onClick={ () => hModalInfo() }>i</ButtonCircle>
                </div>
            </header>
        </div >
    )
}

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    tags: state.objectsByTags.tagsAssoc,
    filteredTagId: state.objectsByTags.filteredTagId,
    selectedObjects: [...state.objects.selected],
    selected: state.objects.selected,

    // selected: [...state.public_playlists.selected_song],
})

const actionsToProps = {
    openModal,
    getNextSelected,
    toggleSelectedObject,
    toggleSelectedObjectState

}

export default connect(stateToProps, actionsToProps)(ObjectsStateItem)
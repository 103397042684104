export default function({
    settings = {},
    updateOptions,
}) {

    const hChange = ({ from = "", to = "" }) => {
        updateOptions({
            fromdate: from,
            todate: to,
        })
    }

    const def = {
        from: settings.fromdate,
        to: settings.todate,
    }

    return [
        {
            type: "Datepicker",
            data: {
                type: "between",
                durationInput: true,
                variant: "icon",
                onChange: hChange,
                titles: {
                    main: "Выберите даты",
                    from: "Дата начала",
                    to: "Дата окончания"
                },
                data: {
                    disabledType: "past",
                    default: def,
                }
            }
        }
    ]
}
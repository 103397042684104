import React, {useEffect} from 'react'

import {OBJECT_SILENT} from '../../../../store/Settings/sets'

import {cl} from '../../../../utilities/cl'
import {generateStateKey} from '../../../../utilities/generateStateKey'
import {carryUpdate} from '../../utilities/carryUpdate'
import {prepareSettings} from '../../utilities/prepareSettings'
import {setsex} from '../../utilities/setsclasses'
import {validateMap} from './validateMap'

import Builder from '../../Builder'

import fieldPeriod from './items/period'
import SettingsAddButton from '../../components/AddButton'
import GroupSilentList from './groups/SilentList'
import fieldTimestart from "../ObjectSchedule/items/timestart";
import fieldTimeend from "../ObjectSchedule/items/timeend";

 
const SettingsObjectSilent = ({
    className = "",
    store = {},
    selected = [],
    stateKey,

    loadItems,
    addItem,
    saveItem,
    deleteItems,
    toggleSelectedItems,
    updateOptions,
    openModal,
    closeModal,
}) => {

    className = cl(
        className,
        "SettingsObjectSilent"
    )

    useEffect(() => {
        loadItems({ stateKey })
    }, [stateKey])

    const data     = store[stateKey] || {}

    const itemStateKey = generateStateKey([stateKey, -1])
        
    let [settings, validMap] = prepareSettings({
        type: OBJECT_SILENT,
        settings: store[itemStateKey],
        validateMap,
    })

    updateOptions = carryUpdate({
        type: OBJECT_SILENT,
        stateKey: itemStateKey,
        updateOptions
    })

    return(
        <Builder
            className={className}
            groups={{
                all: [
                    // Group
                    {
                        data: {
                            columns: 1,
                            noDivider: true,
                            title: "Добавление",
                            className: setsex(["col-1", "mw-items-200"]),
                        },
                        items: [
                            {
                                title: "Период",
                                components: fieldPeriod({
                                    settings,
                                    updateOptions
                                })
                            },
                        ]
                    },

                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                        },
                        items: [
                            {
                                title: "Время начала",
                                components: fieldTimestart({
                                    settings,
                                    name: "fromtime",
                                    onChange: ({value}) => updateOptions({fromtime: value}),
                                })
                            },
                            {
                                title: "Время окончания",
                                components: fieldTimeend({
                                    settings,
                                    name: "totime",
                                    onChange: ({value}) => updateOptions({totime: value}),
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            noDivider: true,
                            className: setsex(["col-2"])
                        },
                        items: [
                            <SettingsAddButton
                                onClick={() => addItem({ stateKey })}
                                disabled={!(validMap.validResult)}
                            />
                        ]
                    },

                    // Список
                    <GroupSilentList
                        className={setsex(["col-1"])}
                        title="Редактирование расписания"
                        stateKey={stateKey}
                        type={OBJECT_SILENT}
                        items={data.list}
                        selected={selected}
                        loading={data.__loading}
                        saveItem={saveItem}
                        deleteItems={deleteItems}
                        toggleSelectedItems={toggleSelectedItems}
                        openModal={openModal}
                        closeModal={closeModal}
                    />
                ],
            }}
        />
    )
}

export default SettingsObjectSilent
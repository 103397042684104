import React from 'react'


const Download = ({ title = 'Скачать', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.007 4.233">
                <title>{ title }</title>
                <path d="M1.994 0c-.056 0-.113.056-.113.113v2.973l-.92-.92c-.058-.058-.133-.058-.17 0a.088.088 0 000 .149l1.155 1.154a.115.115 0 00.009.012l.004.003c.02.027.05.04.08.037a.092.092 0 00.086-.04l1.168-1.167a.088.088 0 000-.15c-.038-.057-.113-.057-.17 0l-.997.997V.113C2.126.056 2.07 0 1.994 0zM.113 3.293A.12.12 0 000 3.405v.715a.121.121 0 00.113.113h3.763c.075 0 .13-.056.13-.113v-.715c0-.056-.055-.112-.13-.112-.057 0-.113.056-.113.112v.603H.243v-.603c0-.056-.055-.112-.13-.112z" fill="#0a0a0a" strokeWidth="1.125" /></svg>
        </i>
    )
}

export default Download
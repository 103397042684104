import React from 'react'
import PropTypes from 'prop-types'

import InputNumber from '../InputNumber'


class InputIPAddress extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: []
        }

        this.lastValue = ""

        this.refInput0 = React.createRef()
        this.refInput1 = React.createRef()
        this.refInput2 = React.createRef()
        this.refInput3 = React.createRef()
        this.refInput4 = React.createRef()

        this.hChange = this.hChange.bind(this)
        this.changeField = this.changeField.bind(this)
        this.isChanged = this.isChanged.bind(this)
        this.getValueStr = this.getValueStr.bind(this)
        this.getValueArr = this.getValueArr.bind(this)
        this.setValueFromProps = this.setValueFromProps.bind(this)
        this.validate = this.validate.bind(this)
    }

    componentDidMount() {
        this.setValueFromProps()
    }

    componentDidUpdate(prevProps) {
        if(this.props.attrs?.value !== prevProps.attrs?.value) {
            this.setValueFromProps()
        }
    }

    hChange({ name, value }) {
        const field = Number(name)
        let changed = false

        this.setState(state => {
            let values = state.values
            values[field] = value

            if(this.isChanged(values)) {
                changed = true
                return {
                    values
                }
            }
        }, () => {
            if(changed) {
                this.changeField({
                    field,
                    value
                })
                if(typeof this.props.onChange === "function") {
                    this.props.onChange({
                        name: this.props.attrs?.name,
                        value: this.lastValue
                    })
                }
            }
        })
    }

    changeField({ field, value }) {
        value = value.toString()

        if(value.length === 3) {
            if(field === 0) this.refInput1.current.focus()
            if(field === 1) this.refInput2.current.focus()
            if(field === 2) this.refInput3.current.focus()
            if(field === 3 && this.props.gate === true)
                this.refInput4.current.focus()
        }
    }

    render() {
        const {
            className = "",
            gate = false
        } = this.props

        return(
            <div className="FormInputIPAddress">
                <div className="FormInputIPAddress__inputs">
                    <InputNumber attrs={{ name: "0", value: this.state.values[0] }} onChange={this.hChange} createRef={this.refInput0} className={className} validate={this.validate}/>
                    <InputNumber attrs={{ name: "1", value: this.state.values[1] }} onChange={this.hChange} createRef={this.refInput1} className={className} validate={this.validate}/>
                    <InputNumber attrs={{ name: "2", value: this.state.values[2] }} onChange={this.hChange} createRef={this.refInput2} className={className} validate={this.validate}/>
                    <InputNumber attrs={{ name: "3", value: this.state.values[3] }} onChange={this.hChange} createRef={this.refInput3} className={className} validate={this.validate}/>
                    {(gate === true) &&
                        <>
                            <div className="FormInputIPAddress__gate-divider">/</div>
                            <InputNumber attrs={{ name: "4", value: this.state.values[4] }} onChange={this.hChange} createRef={this.refInput4} className={className} validate={(val) => this.validate(val, true)}/>
                        </>
                    }
                </div>
            </div>
        )
    }

    isChanged(values) {
        const nextValue = this.getValueStr(values)
        
        if(this.lastValue === nextValue) {
            this.lastValue = nextValue
            return false
        } else {
            this.lastValue = nextValue
            return true
        }
    }

    getValueStr(values) {
        let inputs = [],
            value = ""

        if(values[0] && values[0] >= 0) inputs.push(values[0])
        if(values[1] && values[1] >= 0) inputs.push(values[1])
        if(values[2] && values[2] >= 0) inputs.push(values[2])
        if(values[3] && values[3] >= 0) inputs.push(values[3])

        value = inputs.join(".")

        if(this.props.gate === true) {
            if(values[4] && values[4] >= 0) value += `/${values[4]}`
        }
        
        return value
    }

    getValueArr(value) {
        const splitedGate = value.split("/")
        const splitedAdd = (splitedGate[0] || "").split(".")

        const values = []

        splitedAdd.map(n => {
            if(this.validate(n)) {
                values.push(n)
            }
        })

        if(splitedGate[1]) {
            if(this.validate(splitedGate[1])) {
                values.push(splitedGate[1])
            }
        }

        values[0] = values[0] || ""
        values[1] = values[1] || ""
        values[2] = values[2] || ""
        values[3] = values[3] || ""
        values[4] = values[4] || ""

        return values
    }

    setValueFromProps() {
        const values = this.getValueArr(this.props.attrs?.value || "")
        this.setState({
            values
        })
    }

    validate(value, isMask) {
        if(isMask === true) {
            if(value > 32) return false
        } else {
            if(value > 255) return false
        }

        value = value.toString()
        return value.length <= 3
    }
}

InputIPAddress.propTypes = {
    className: PropTypes.string,
    gate: PropTypes.bool,
}

export default InputIPAddress
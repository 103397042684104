import { takeEvery, call, put, select } from 'redux-saga/effects'

import { UPDATE_PV_DATA, loadPvData } from '../../store/Features/actions';
import axios from 'axios';
import { showAlertError } from '../../store/Alert/actions';
import { loadSubusers } from '../../store/Roles/actions';

export default function* () {
    yield takeEvery(UPDATE_PV_DATA, worker)
}

function* worker({ payload }) {
    console.log({ payload });

    try {
        const { id, note, mute } = payload

        const response = yield call(updatePvdata, { note, mute }, id)
        console.log({ response });

        if (response.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_PV_DATA}.`, response],
                text: "Настройки не сохранены. Попробуйте повторить."
            }))

        } else {
            yield put(loadPvData())

        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_PV_DATA}.`, e],
            text: "Настройки не сохранены. Попробуйте повторить."
        }))
    } finally {
        // yield put(loading(false))
    }
}

function updatePvdata(data, id) {

    let formData = new FormData()
    if (data) {
        Object.keys(data).forEach(name => {
            formData.set(name, data[name])
        })
    }
    return axios(
        {
            url: `/pvdata/update`,
            method: "POST",
            data: formData,

        }
    )
        .then(response => response.data)

}
import React from 'react'
import {connect} from 'react-redux'
import {hideAlertError} from '../../store/Alert/actions'

import outside from '../../utilities/outside'


const AlertError = ({ text, errors, show, ms, hideAlertError }) => {
    if(show) {
        setTimeout(() => hideAlertError(), ms)

        if(errors && errors[0]) {
            const cyrRegexp = /[а-яё]/i

            errors.map(error => {
                console.log(error)

                // Вывод сообщения об ошибке, полученной с сервера, если она содержит кириллические символы
                if(typeof error.error === "string") {
                    if(cyrRegexp.test(error.error))
                        text = error.error
                }
            })
        }        

        return(
            <>
                {outside(
                    <div className="Alert AlertError">
                        {text}
                    </div>
                )}
            </>
        )
    } else {
        return <></>
    }
}

const stateToProps = state => ({
    show: state.alert.error.show,
    text: state.alert.error.text,
    errors: state.alert.error.errors,
    ms: state.alert.error.ms
})

const actionsToProps = {
    hideAlertError
}

export default connect(stateToProps, actionsToProps)(AlertError)
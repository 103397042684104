import React, {useEffect} from 'react'

import {ADVERT_TRACKS} from '../../../../store/Settings/sets'

import {cl} from '../../../../utilities/cl'
import {formatToMinutes} from '../../utilities/formatTime'
import {carryUpdate} from '../../utilities/carryUpdate'
import {updateHelper} from '../../utilities/updateHelper'
import {mapValidObj} from '../../../../utilities/valid'
import validateMap from './validateMap'

import Builder from '../../Builder'

import GroupClips from './groups/Clips'
import fieldDates from './items/dates'
import Channel from './items/Channel'
import fieldFromtime from './items/fromtime'
import fieldPri from './items/pri'
import fieldTotime from './items/totime'
import fieldBlock from './items/block'
import fieldInhour from './items/__inhour'
import fieldSteptime from './items/steptime'
import SettingsDays from '../../components/Days'
import fieldInday from './items/__inday'
import GroupSave from './groups/Save'


const SettingsAdvertTracks = ({
                                  className = "",
                                  indentIds = [],
                                  objectIds = [],
                                  stateKey,
                                  updateOptions,
                                  settings = {},
                                  allObjectsAssociated,
                                  hideEnded
                              }) => {

    className = cl(
        className,
        "SettingsAdvertTracks"
    )

    settings = settings[stateKey] || {}

    const validMap = mapValidObj(validateMap, settings)

    updateOptions = carryUpdate({
        type: ADVERT_TRACKS,
        stateKey,
        updateOptions
    })

    const hChange = (name, handler, callback) => updateHelper({
        name,
        updateOptions,
        handler,
        callback
    })

    const blockHandler = (val) => val.join(",")
    const steptimeHandler = (val) => formatToMinutes(val)
    const indayCallback = ({name, value}) => {
        if (value === true) {
            updateOptions({['daymask']: 127})
        }
    }
    const daymaskCallback = ({name, value}) => {
        if (value > 0) {
            updateOptions({['__inday']: false})
        }
    }
    if (settings['__inday']) {
        settings['daymask'] = 0;
    }
    if (settings['daymask'] > 0) {
        settings['__inday'] = false;
    }
    const calcAvailables = () => {
        const outs  = (1 * objectIds.length)
        const insd  = (2 * objectIds.length)
        const bouth = 3
        let countCh = 0

        objectIds.map(id =>
            countCh += allObjectsAssociated[id].chmask
        )

        if(countCh === outs) return 1
        if(countCh === insd) return 2
        return bouth
    }

    const availablesChannels = calcAvailables()
    // Эффект создан для задания канала по умолчанию (при рендере компонента или смене или переключении объектов)
    // Устанавливает все доступные каналы
    useEffect(() => {
        updateOptions({['channel']: availablesChannels})
        //}
    }, [stateKey])

    return (
        <Builder
            className={className}
            header={{
                lineTitle: objectIds.length > 1 ? "Объекты" : "Объект",
            }}
            groups={{
                left: [
                    // Clips
                    <GroupClips
                        key={indentIds[0]+'_'+objectIds[0]}
                        indentIds={indentIds}
                        objectIds={objectIds}
                        hideEnded={hideEnded}
                    />,

                    // Group
                    {
                        data: {},
                        items: [
                            {
                                title: "Период трансляции",
                                components: fieldDates({
                                    settings,
                                    updateOptions
                                })
                            },
                            <Channel
                                stateKey={stateKey}
                                settings={settings}
                                objectIds={objectIds}
                                objectsAssociated={allObjectsAssociated}
                                name="channel"
                                onChange={hChange("channel")}
                            />
                        ]
                    },

                    // Group
                    {
                        data: {
                            comment: "* Если не указывать время, то ролик будет запрограммирован на целый день"
                        },
                        items: [
                            {
                                title: "Время первой трансляции",
                                components: fieldFromtime({
                                    settings,
                                    name: "fromtime",
                                    onChange: hChange("fromtime"),
                                })
                            },
                            {
                                title: "Время последней трансляции",
                                components: fieldTotime({
                                    settings,
                                    name: "totime",
                                    onChange: hChange("totime"),
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            // comment: "* Выберите конкретные минуты выхода ролика, либо укажите, через какой период он должен чередоваться в эфире"
                        },
                        items: [
                            {
                                title: "Минуты часа",
                                //disabled: (validMap.__inhour || validMap.steptime),
                                components: fieldBlock({
                                    settings,
                                    name: "block",
                                    onChange: hChange("block", blockHandler),
                                })
                            },
                            {
                                title: "Трансляций в час",
                                components: fieldInhour({
                                    settings,
                                    name: "__inhour",
                                    onChange: hChange("__inhour"),
                                    disabled: (validMap.block || validMap.steptime),
                                })
                            }
                        ]
                    },
                ],

                right: [
                    // Group
                    {
                        data: {
                            wrap: true
                        },
                        items: [
                            {
                                title: "Чередовать ролик через",
                                components: fieldSteptime({
                                    settings,
                                    name: "steptime",
                                    onChange: hChange("steptime", steptimeHandler),
                                    //disabled: (validMap.block || validMap.__inhour),
                                })
                            },
                            <SettingsDays
                                value={settings.daymask}
                                onChange={hChange("daymask", undefined, daymaskCallback)}
                                //disabled={validMap.__inday}
                            />
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            // wrap: true,
                            noDivider: true,
                        },
                        items: [
                            {
                                title: "Частота трансляции",
                                components: fieldInday({
                                    settings,
                                    stateKey,
                                    name: "__inday",
                                    onChange: hChange("__inday", undefined, indayCallback),
                                    //disabled: validMap.daymask
                                })
                            },
                            // {
                            //     title: "Приоритет трансляции",
                            //     components: fieldPri({
                            //         settings,
                            //         stateKey,
                            //         name: "pri",
                            //         onChange: hChange("pri"),
                            //     })
                            // },
                        ]
                    },

                    // Save
                    <GroupSave
                        settings={settings}
                        validMap={validMap}
                        stateKey={stateKey}
                        indentIds={indentIds}
                        objectIds={objectIds}
                        key={'save'+stateKey}
                    />
                ],
            }}
        />
    )
}

export default SettingsAdvertTracks
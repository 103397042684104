// const classNames = classes(
//   "className",
//   {"one":   true},
//   {"two":   1},
//   {"three": () => false},
//   {"four":  () => "44"},
//   {"five":  () => 44},
//   {"six":   () => 0},
//   {
//     "Block": [
//       "active",
//       {"closed": true},
//       {"loaded": 0},
//       {"yellow": () => true},
//     ]
//   }  
// )
// 
// =>> className one two five Block Block_active Block_closed Block_yellow

export function cl(...clss) {
    let answer = []

    if (Array.isArray(clss)) {
        clss.map(item => {
            if (typeof item === "string") {
                answer.push(item)
            }

            else if (typeof item === "object" && !Array.isArray(item)) {
                for (let name in item) {
                    let value = item[name]
                    if (typeof value === "boolean" || typeof value === "number") {
                        if (value) answer.push(name)
                    }
                    else if (typeof value === "function") {
                        let funAnsw = value()
                        if ((typeof funAnsw === "boolean" || typeof funAnsw === "number") && funAnsw) answer.push(name)
                    }

                    else if (Array.isArray(value)) {
                        answer.push(name)
                        value.map(childItem => {
                            if (typeof childItem === "string") {
                                answer.push(`${name}_${childItem}`)
                            }

                            else if (typeof childItem === "object" && !Array.isArray(childItem)) {
                                for (let childName in childItem) {
                                    let childValue = childItem[childName]
                                    if (typeof childValue === "boolean" || typeof childValue === "number") {
                                        if (childValue) answer.push(`${name}_${childName}`)
                                    }
                                    if (typeof childValue === "function") {
                                        let childFunAnsw = childValue()
                                        if ((typeof childFunAnsw === "boolean" || typeof childFunAnsw === "number") && childFunAnsw) answer.push(`${name}_${childName}`)
                                    }
                                }
                            }
                        })
                    }
                }
            }
        })
    }

    answer = answer.filter(classname => (classname !== "" && classname !== " "))

    return answer.join(" ")
}
import React from 'react'


const Search = ({ title = 'Найти', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.232 4.097">
                <title>{ title }</title>
                <g data-name="Group 1907" strokeWidth=".986"><path d="M1.743 0C.782 0 0 .782 0 1.743c0 .961.782 1.743 1.743 1.743.467 0 .89-.185 1.203-.485l1.057 1.056a.134.134 0 00.19 0 .134.134 0 000-.19L3.124 2.8c.225-.294.36-.66.36-1.057C3.486.782 2.706 0 1.744 0zm0 .264c.818 0 1.479.66 1.479 1.479 0 .818-.661 1.478-1.48 1.478-.817 0-1.477-.66-1.477-1.478S.925.264 1.743.264z" fill="#fff" /><circle r="6.5" cy="6.5" cx="6.5" stroke="none" transform="translate(-.002 -.002) scale(.26847)" data-name="Ellipse 82" fill="none" /></g></svg>
        </i>
    )
}

export default Search
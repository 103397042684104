import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_SILENT } from '../../../../store/Settings/sets'
import { adding } from '../../../../store/Settings/actions'
import { ADD_ITEM, loadItems, cleanBuffer } from '../../../../store/Settings/actions/sets/Object/Silent'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(ADD_ITEM, worker)
}

function* worker({ payload }) {
    const { stateKey } = payload

    try {
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedObjectsGroup = yield select(state => state.objectsGroup.selected || [])
        const selected = selectedObjectsGroup.length > 0 ? selectedObjectsGroup : selectedObjects

        yield put(adding({
            type: OBJECT_SILENT,
            stateKey,
            adding: true,
        }))

        const itemStateKey = generateStateKey([stateKey, -1])

        const itemSetting = yield select(state => state.settings.settings[OBJECT_SILENT][itemStateKey] || {})
        const fetchedAdd = yield call(fetchAdd, itemSetting, selected)

        // Ошибка
        if (fetchedAdd.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${ADD_ITEM}.`, fetchedAdd],
                text: "Не удалось создать элемент. " + fetchedAdd.error
            }))

            // Успех
        } else {
            yield put(showAlertSuccess({
                responses: [`Успешное выполнение ${ADD_ITEM}.`, fetchedAdd],
                text: "Элемент успешно создан."
            }))
            yield put(cleanBuffer({
                itemStateKey,
            }))
            yield put(loadItems({
                stateKey,
                required: true,
            }))
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${ADD_ITEM}.`, e],
            text: "Не удалось создать элемент. Попробуйте повторить."
        }))
    }

    finally {
        yield put(adding({
            type: OBJECT_SILENT,
            stateKey,
            adding: false,
        }))
    }
}

function fetchAdd(settings, ms_id) {
    settings.ms_id = ms_id.join(",")

    if (!settings.todate || settings.todate === "") settings.todate = settings.fromdate

    return axios.get("/msbox/silent/insert", { params: { ...settings } })
        .then(response => response.data)
}
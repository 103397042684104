import React from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../../utilities/cl'

import Place from '../../../../components/Space/Place'
import Empty from '../../../../components/Space/Empty'

import SlideMenu from '../../../../components/SlideMenu'
import HelpMenu from './HelpMenu'
import { MEDIA_BREAKPOINT_LG } from '../../../../utilities/breakpoints'

import HelpMenuMobile from '../Mobile/HelpMenu'
import CallbackMobile from '../Mobile/Callback'

class What extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            empty: false
        }
    }



    render() {
        const {
            empty
        } = this.state
        const hideContent = cl({ "d-none": empty })
        return (
            <>
                <Place
                    number={ 1 }
                >
                    <SlideMenu
                        activeItem={ 1 }
                        className={ hideContent }

                        items={
                            window.outerWidth > MEDIA_BREAKPOINT_LG ?
                                [
                                    {
                                        title: "ЧАСТЫЕ ВОПРОСЫ",
                                        content: <HelpMenu className={ hideContent } placeNumber={ 1 } active />
                                    },
                                ]
                                :
                                [
                                    {
                                        title: "ЧАСТЫЕ ВОПРОСЫ",
                                        content: <HelpMenuMobile className={ hideContent } placeNumber={ 1 } active />
                                    },
                                    {
                                        title: "Обратная связь",
                                        content: <CallbackMobile className={ hideContent } placeNumber={ 1 } active />
                                    },
                                ]
                        }

                    />
                </Place>
            </>
        )
    }
}

const stateToProps = state => ({

})

export default connect(stateToProps, null)(What)
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Place from '../../../../components/Space/Place'
import Empty from '../../../../components/Space/Empty'

import SlideMenu from '../../../../components/SlideMenu'

import SettingsTemplates from './SettingsTemplates'
import { loadTemplates } from '../../../../store/Templates/actions'
import Spinner from '../../../../components/Spinner'


const Where = ({ templates, loadTemplates, loading }) => {
    useEffect(() => {
        loadTemplates()
    }, [])
    return (

        <>
            {/* 2 */ }

            <Place
                number={ 2 }
                className="ObjectsWhere"
            >

                <SlideMenu
                    activeItem={ 1 }
                    items={ [
                        {
                            title: "Настройка шаблонов",
                            content: Object.values(templates).length > 0 && !loading ? <SettingsTemplates placeNumber={ 2 } active templates={ templates } /> : <Spinner className="Templates_spinner" />
                        }
                    ] }
                />

            </Place>
        </>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
    templates: state.settings_templates.templates,
    loading: state.settings_templates.loading
})
const actionsToProps = {
    loadTemplates
}

export default connect(stateToProps, actionsToProps)(Where)
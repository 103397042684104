import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import ScrollContainer from '../../ScrollContainer'
import Spinner from '../../Spinner'
import PublicPlaylistItem from '../PublicPlaylistItem'
import Empty from '../Empty'
import List from '../../List'
import { loadPublicPlaylists, toggleSelectedPublicPlaylist, sortPublicPlaylists } from '../../../store/PublicPlaylists/actions'
import { isMobile } from '../../../utilities/media'
import { getNextSelected } from '../../../utilities/getNextSelected'
import Button from '../../Button'
import SvgArrowUpDown from '../../SVG/ArrowUpDown'


const AllPlaylists = ({
    searchActive,
    searchFounded = [],
    public_playlists = [],
    loading_public,
    toggleSelected,
    allPublicPlaylists,
    loadPublicPlaylists,
    toggleSelectedPublicPlaylist,
    selectedPublicPlaylists,
    selected,
    selected_public,
    dnd_result,
    sortPublicPlaylists

}) => {
    const [sort_direction, setSortDirection] = useState("")

    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortPublicPlaylists({ direction: sort_direction, sort_type: type })
    }
    useEffect(() => {
        loadPublicPlaylists()
    }, [])
    //if (searchActive) allPlaylists = searchFounded
    public_playlists = allPublicPlaylists
    const hToggleSelected = (e) => {
        const playlist = e.target.closest(".Playlist")
        if (playlist) {
            const playlistId = Number(playlist.dataset.playlistId)

            if (!isNaN(playlistId)) {
                const selected = getNextSelected({
                    multiple: true,
                    itemId: playlistId,
                    selected: selectedPublicPlaylists,
                    ctrlKey: !isMobile() || e.ctrlKey || e.metaKey,
                })

                toggleSelectedPublicPlaylist({
                    selected,
                    simple: true,
                })
            }
        }
    }



    const getItems = () => {
        return public_playlists.map((playlist, index) => ({
            //return (
            id: playlist.id,
            name: playlist.name,
            element: < PublicPlaylistItem
                key={ playlist.id }
                id={ playlist.id }
                name={ playlist.name }
                songs={ playlist.songs }
                totaltime={ playlist.totaltime }
                totalsize={ playlist.totalsize }
                variant={ (index % 2 == 0) ? "gray" : "" }
            />
        }))
    }

    return (
        <section className="Playlists">
            <div onClick={ hToggleSelected } style={ { marginTop: 34 } }>
                <header className="Playlists__control">
                    <h4 style={ { marginBottom: "32px" } }> Все плейлисты</h4>
                    <div className="Playlists__grid">
                        <div className="name">
                            <Button
                                type="string"
                                onClick={ () => sort("name") }
                            >
                                <SvgArrowUpDown title="Сортировка по название плейлиста" />
                                Название
                            </Button>
                        </div>
                        <div className="tracks_count">
                            <Button
                                type="string"
                            // onClick={ () => sort("songs") }
                            >
                                <SvgArrowUpDown title="Сортировка по кол-ву треков" />
                                <span>Треков</span>
                            </Button>
                        </div>
                        <div className="size">
                            <Button
                                type="string"
                                onClick={ () => sort("size") }
                            >
                                <SvgArrowUpDown title="Сортировка по размеру плейлиста" />
                                <span>Размер</span>
                            </Button>
                        </div>
                        <div className="duration">
                            <Button
                                type="string"
                                onClick={ () => sort("duration") }
                            >
                                <SvgArrowUpDown title="Сортировка по длительности" />
                                <span>Длительность</span>
                            </Button>
                        </div>
                    </div>
                </header>
                { loading_public
                    ? <Spinner className="pt-xs pb-xs" />
                    : public_playlists.length > 0
                        ? <List
                            type="simple"
                            scroll={ {
                                on: true,
                                data: {
                                    variant: "white",
                                    size: "lg",
                                }
                            } }
                            // variant={ "none" }
                            content={ { items: getItems(), variant: "none" } }
                        />

                        : <Empty title="Плейлистов пока нет." />
                }

            </div>
        </section>
    )
}

const stateToProps = state => ({
    allPublicPlaylists: state.public_playlists.allPublicPlaylists,
    loading_public: state.public_playlists.loading,
    selectedPublicPlaylists: [...state.public_playlists.selected],
    selected: [...state.public_playlists.selected],
})

const actionsToProps = {
    loadPublicPlaylists,
    toggleSelectedPublicPlaylist,
    sortPublicPlaylists
}
export default connect(stateToProps, actionsToProps)(AllPlaylists)
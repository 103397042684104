import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { cl } from '../../../../../../utilities/cl'
import connectionok from '../../../../../../assets/icons/connection-ok.png'
import connectionno from '../../../../../../assets/icons/connection-no.png'
import connectionbad from '../../../../../../assets/icons/connection-bad.png'
import connectionwait from '../../../../../../assets/icons/connection-wait.png'
import { BsCheckCircleFill, BsFillDashCircleFill, BsExclamationCircleFill, BsFillQuestionCircleFill, BsFillVolumeMuteFill } from "react-icons/bs";
import { OverlayTrigger, Popover } from 'react-bootstrap'

const ObjectsStateItem = ({
    id,
    name,
    sync_time,
    sync_start_time,
    ip,
    error,
    markItems,
    lastsyncsystem,
    variant,
    address,
    mute
}) => {

    let className = cl(

        "selectable-item",
        { "ObjectState": [variant] },
        // { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
    )
    let date_now = new Date()
    let date_now_tzo = date_now.getTimezoneOffset()
    let date_now_tzo_ms = date_now_tzo * 60 * 1000

    let date_sync = new Date(lastsyncsystem)
    let date_now_ms = date_now.getTime()
    let date_sync_ms = date_sync.getTime()

    let diff_date_sync = date_now_ms - date_sync_ms + date_now_tzo_ms
    let color = ''
    let notify = "Плеер MSBOX работает, синхронизируется по графику"
    let icon = <BsCheckCircleFill size="16px" />
    if (lastsyncsystem === "") {
        color = '#7F7F7F'
        icon = <BsFillDashCircleFill size="16px" />
        notify = "Плеер MSBOX ни разу не подключался к сети интернет"
    } else {
        if (diff_date_sync > 60 * 60 * 1000 && diff_date_sync < 300 * 60 * 1000) {
            color = '#E45D05'
            icon = <div style={{ marginRight: 10 }}><BsFillQuestionCircleFill size="16px" /></div>
            notify = "Последняя синхронизация плеера MSBOX не завершена, дождитесь завершения следующей"

        } else if (diff_date_sync > 300 * 60 * 1000) {
            color = '#AB0303'
            icon = <div style={{ marginRight: 10 }}> <BsExclamationCircleFill size="16px" /></div>
            notify = "Требуется вмешательство специалиста, плеер MSBOX не выходит на связь"
        }
    }
    if (mute) {
        color = '#AB0303'
        icon = <BsFillVolumeMuteFill size="20px" />
        notify = "Принудительно отключено"
    }


    const popoverHoverFocus = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom">
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                justifyContent: "space-between",
                padding: "4px"
            }}>
                <div style={{ color: color === "" ? '#32CD32' : color }}>{icon}</div>
                <span style={{ fontWeight: 500, fontSize: "12px" }}>{notify}</span>
            </div>
        </Popover>
    );
    return (
        <div className={className} data-object-state-id={id} >
            <header className="ObjectState__header">
                <div className="ObjectsState__grid">
                    <div className="id">
                        {id}
                    </div>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="left"
                        overlay={popoverHoverFocus}>
                        <div className="state"
                            style={{ color: color === "" ? '#32CD32' : color }}>
                            {icon}
                        </div>
                    </OverlayTrigger>
                    <div className="name" data-object-state-id={id} style={{ color: color }}>
                        <span title={name}>{name.length > 36 ? `${name.substr(0, 37)}...` : name} {address}</span>
                    </div>
                    <div className="sync_time" data-object-state-id={id}>
                        {sync_time === "1970-01-01 00:00:00" ? "" : sync_time}
                    </div>
                    <div className="sync_start_time" data-object-state-id={id}>
                        {sync_start_time}
                    </div>
                    <div className="ip" data-object-state-id={id}>
                        {ip}
                    </div>
                    <div className="error" data-object-state-id={id} style={{ color: color }}>
                        {error}
                    </div>
                </div>
            </header>
        </div >
    )
}

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    // selected: [...state.public_playlists.selected_song],
})

export default connect(stateToProps, null)(ObjectsStateItem)
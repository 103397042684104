import React from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../utilities/cl'


const Button = ({
    className,
    type,
    variant,
    buttonRef,
    disabled,
    onClick,
    value,
    children
}) => {

    const hClick = (e) => {
        e.persist()
        if(!disabled) {
            if(typeof onClick === "function") {
                onClick(value, e)
            }
        }
    }

    className = cl(
        "spp-btn",
        `spp-btn_${type}`,
        variant,
        className,
    )

    return(
        <button
            className={className}
            disabled={disabled}
            onClick={hClick}
            ref={buttonRef}
        >
            {children}
        </button>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(["string", "icon"]),
    variant: PropTypes.oneOf(["light", "normal"]),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.any
}

export default Button
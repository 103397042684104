import React from 'react'


const ArrowDown = ({ title = 'Вниз', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.175 1.697">
                <title>{ title }</title>
                <path d="M3.032 0a.142.142 0 00-.1.042L1.586 1.386.243.042a.143.143 0 10-.202.202l1.414 1.413a.14.14 0 00.134.036.14.14 0 00.13-.036L3.134.244a.142.142 0 000-.202A.142.142 0 003.032 0z" data-name="Group 956" fill="#0a0a0a" strokeWidth=".927" /></svg>
        </i>
    )
}

export default ArrowDown
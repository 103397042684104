import React from 'react'


const Play = ({ title = 'Воспроизвести', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-'+variant : ''} ${size ? 'spp-svg-size-'+size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.688 4.234">
                <title>{title}</title>
                <path d="M3.638 2.034L.144.011A.098.098 0 000 .097v4.037a.095.095 0 00.144.086l3.492-2.023a.09.09 0 000-.163z" data-name="Path 565" fill="#ff3347"/></svg>
        </i>
    )
}

export default Play 
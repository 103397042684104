import { takeEvery, select, put, call } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_TZ, putTz } from '../../store/SelectTZ/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_TZ, worker)
}

function* worker() {
    try {
        const cache = yield select(state => state.selectTz.tz)

        if (!cache.length) {
            let tz = yield call(fetchTz)

            if (tz.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${LOAD_TZ}.`, tz],
                    text: "Тайм-зоны не были загружены. Попробуйте перезагрузить страницу."
                }))
            } else {
                const tzFormated = [
                    // {title: "Из настроек сервера", value: ""},
                ]

                tz.success.map(t => {
                    tzFormated.push({
                        title: `${t.description} (GMT${t.GMT})`,
                        value: t.name
                    })
                })

                // tz = [
                //     {title: "Из настроек сервера", value: 1},
                //     {title: "Калининградское время (KALT): MSK-1 (UTC+2)", value: "Europe/Kaliningrad"},
                //     {title: "Московское время (MSK): MSK (UTC+3)", value: "Europe/Moscow"},
                //     {title: "Самарское время (SAMT): MSK+1 (UTC+4)", value: "Europe/Samara"},
                //     {title: "Екатеринбургское время (YEKT): MSK+2 (UTC+5)", value: "Asia/Yekaterinburg"},
                //     {title: "Омское время (OMST): MSK+3 (UTC+6)", value: "Asia/Omsk"},
                //     {title: "Красноярское время (KRAT): MSK+4 (UTC+7)", value: "Asia/Krasnoyarsk"},
                //     {title: "Иркутское время (IRKT): MSK+5 (UTC+8)", value: "Asia/Irkutsk"},
                //     {title: "Якутское время (YAKT): MSK+6 (UTC+9)", value: "Asia/Yakutsk"},
                //     {title: "Владивостокское время (VLAT): MSK+7 (UTC+10)", value: "Asia/Vladivostok"},
                //     {title: "Cреднеколымское время (MAGT): MSK+8 (UTC+11)", value: "Asia/Srednekolymsk"},
                //     {title: "Камчатское время (PETT): MSK+9 (UTC+12)", value: "Asia/Kamchatka"},
                // ]

                yield put(putTz({ tz: tzFormated }))
            }
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_TZ}.`, e],
            text: "Тайм-зоны не были загружены. Попробуйте перезагрузить страницу."
        }))
    }
}

function fetchTz() {
    return axios.get("/timezone/select")
        .then(response => response.data)
}
import React from 'react'

import {mediaTabletAndUp} from '../../../../../utilities/media'

import Group from '../../../components/Group/Group'
import SettingsDays from '../../../components/Days'


const GroupDays = ({
    className = "",
    value,
    onChange,
    disabled,
}) => {

    return(
        mediaTabletAndUp(
            <Group
                className={className}
                noDivider={true}
            >
                <SettingsDays
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            </Group>,
            null
        )
    )
}

export default GroupDays
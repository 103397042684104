import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../utilities/cl'

import SvgPlay from '../../../components/SVG/Play'
import SvgPause from '../../../components/SVG/Pause'
import SvgPlayVideo from '../../../components/SVG/PlayVideo'
import { formatSecondsToTime } from '../../../utilities/format'
import { openModal } from '../../../store/Modal/actions'
import { toggleSelectedVideolist, toggleSelectedVideo } from '../../../store/ContentVideolists/actions'
import { updateCurrentMark } from '../../../store/Marks/actions'
import { MEDIA_BREAKPOINT_LG } from '../../../utilities/breakpoints'

const Song = ({
    className = "",
    selectedVideolists,
    currentVideolist,
    placeNumber,
    id,
    active,
    name,
    turn,
    cliptime,
    tempo,
    songauthor_name,
    player_name,
    album_name,
    genre_name,
    year,
    variant,
    selected,
    markItems,
    audio,
    trackPlaying,
    isPlaying,
    play,
    pause,
    stop,
    rewind,
    filename,
    openModal,
    watcherInterval = null,
    ctime,
    selected_videolist,
    toggleSelectedVideolist,
    toggleSelectedVideo,
    updateCurrentMark,
    id_videolist

}) => {
    const [played, setPlayed] = useState(false)
    const [currentTime, setCurrentTime] = useState("00:00")
    const [slider, setSlider] = useState("100%")

    const [listLoaded, setListLoaded] = useState(false)
    const [opened, setOpened] = useState(false)

    const prevPlaying = usePrevious(trackPlaying)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {
        if (trackPlaying !== prevPlaying) {
            if (isPlaying) {
                startWatch()
            } else {
                killWatcher()
            }
        }
    }, [trackPlaying])

    const toggleSelectedV = (e) => {
        const songTitle = e.target
        if (songTitle) {
            const songId = Number(songTitle.dataset.songId || songTitle.parentNode.dataset.songId || songTitle.parentNode.parentNode.dataset.songId)
            if (!isNaN(songId)) {
                if (selected_videolist.length === 0 || id_videolist !== selected_videolist[0]) {
                    toggleSelectedVideolist({
                        selected: [id_videolist],
                        simple: true
                    })
                }

                toggleSelectedVideo({
                    id_video: songId,
                    onlyOne: !((e.ctrlKey || e.metaKey || e.shiftKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                })
                updateCurrentMark()
            }
        }
    }
    function startWatch() {
        if (trackPlaying === id) {
            watcherInterval = setInterval(() => {
                if (audio.currentTime === audio.duration) {
                    stop()
                } else {
                    updateTime()
                }
            }, 50)
        } else {
            killWatcher(true)
        }
    }

    function killWatcher(resetTime) {
        clearInterval(watcherInterval)
        updateTime(resetTime)
    }


    const updateTime = (resetTime) => {
        let currentTime = "00:00"
        let slider = "100%"

        if (resetTime !== true && (audio.currentTime !== audio.duration && trackPlaying === id)) {
            // Время
            currentTime = formatSecondsToTime(audio.currentTime)

            // Слайдер
            const sliderPercent = (audio.currentTime / (audio.duration / 100))
            slider = `${100 - sliderPercent}%`
        }
        setSlider(slider)
        setCurrentTime(currentTime)

    }


    function plays() {
        pause()
        openModal({
            type: "video",
            data: {
                video: {
                    title: name,
                    mp4: filename,
                }
            }
        })
    }
    function pauses() {
        pause()
    }
    function rewinds(e) {
        const rect = e.target.getBoundingClientRect(),
            clickPercent = 100 * (e.nativeEvent.offsetX / rect.width),
            totime = audio.duration * (clickPercent * 0.01)
        rewind(totime)
    }

    className = cl(
        className,
        // { "Playlist": [variant] },
        "selectable-item",
        { "Song": [variant] },
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
        { "playing": (isPlaying && trackPlaying === id) },
        { "name-fade": slider === "100%" },
    )

    return (


        <div className={ className } data-song-id={ id } onClick={ (e) => toggleSelectedV(e) }>
            <header className="Song__header">
                <span className="rewind" onClick={ rewinds }></span>
                <span className={ cl("slider-1", { "d-none": slider === 0 }) } style={ { right: slider } }></span>
                <span className={ cl("slider-2", { "d-none": slider === 0 }) } style={ { right: slider } }></span>

                <div className="Songs__grid">
                    <div className="play">
                        <span onClick={ plays }>
                            <SvgPlayVideo className="icon-play" variant="prim" size="xs1" title="Воспроизвести" />
                        </span>
                        <span onClick={ pauses }>
                            <SvgPause className="icon-pause" variant="prim" size="xs1" title="Приостановить" />
                        </span>
                    </div>
                    <div className="composition_video" data-song-id={ id }>
                        {/* <span style={ { marginRight: "10px" } } onClick={ plays }>
                            <SvgPlay variant="prim" size="xs1" />
                        </span> */}
                        { name }
                    </div>
                    <div className="duration_video" data-song-id={ id }>
                        { cliptime.split(":").splice(1).join(":") }
                    </div>
                    <div className="type_video" data-song-id={ id }>
                        { player_name }
                    </div>
                    <div className="added_video" data-song-id={ id }>
                        { ctime.split(" ").splice(0, 1) }
                    </div>
                </div>
            </header>
        </div>


    )
}

const stateToProps = state => ({
    selected: [...state.videolists.selected_video],
    currentVideolist: state.videolists.currentVideolist,
    selected_videolist: state.videolists.selected
})
const actionsToProps = {
    openModal,
    toggleSelectedVideolist,
    toggleSelectedVideo,
    updateCurrentMark
}

export default connect(stateToProps, actionsToProps)(Song)
import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_SYSTEM_REPORT, loading, putSystemReport } from '../../../store/Reports/System/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_SYSTEM_REPORT, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        const system_report = yield call(fetchSystemReport, payload)
        if (system_report.error && system_report.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_SYSTEM_REPORT}.`, system_report],
                text: "Отчет не был загружен. Попробуйте перезагрузить страницу."
            }))
            yield put(putSystemReport([]))
        } else {
            yield put(putSystemReport({ system_report: system_report.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_SYSTEM_REPORT}.`, e],
            text: "Отчет не был загружен. Попробуйте перезагрузить страницу."
        }))
        yield put(putSystemReport([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchSystemReport(search) {
    return axios.get("/msbox/report/system", { params: { id: search.id, date: search.date } })
        .then(response => response.data)
}

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { saveAs } from "file-saver";
import { generateStateKey } from '../../../../../utilities/generateStateKey'
import { cl } from '../../../../../utilities/cl'

import { OBJECT_OBJECT } from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'
import ScrollContainer from '../../../../../components/ScrollContainer'
import Select from '../../../../../components/Form/Select'
import AddButton from '../../../../../components/Settings/components/AddButton'
import { Button as ButtonBS } from 'react-bootstrap'
import Button from "../../../../../components/Button"
import ButtonCircle from '../../../../../components/ButtonCircle'
import SVGTrash from '../../../../../components/SVG/Trash'
import SVGDownload from '../../../../../components/SVG/Download'
import SVGPlusCircle from '../../../../../components/SVG/PlusCircle'
import SVGPlus from '../../../../../components/SVG/Plus'
import Spinner from '../../../../../components/Spinner'
import { updateTemplates, loadTemplates } from '../../../../../store/Templates/actions'
import InputText from '../../../../../components/Form/InputText'
import { openModal, closeModal } from '../../../../../store/Modal/actions'


const SettingsTemplates = ({
    className = "",
    selectedObjects,
    placeNumber,
    active,
    loadTemplates,
    templates,
    openModal,
    updateTemplates,
    closeModal,
    loading
}) => {
    let { indenttemplate, ethertemplate, raotemplate, voistemplate } = templates
    let { indenttemplate_default, ethertemplate_default, raotemplate_default, voistemplate_default } = templates

    const [indenttemplate_value, setIndentVal] = useState("По умолчанию")
    const [ethertemplate_value, setEtherVal] = useState("По умолчанию")
    const [voistemplate_value, setVoisVal] = useState("По умолчанию")
    const [raotemplate_value, setRaoVal] = useState("По умолчанию")

    useEffect(() => {
        !indenttemplate_default && setIndentVal("Пользовательский")
        !ethertemplate_default && setEtherVal("Пользовательский")
        !raotemplate_default && setVoisVal("Пользовательский")
        !voistemplate_default && setRaoVal("Пользовательский")
    }, [])
    className = cl(
        className,
        "SettingsObjectObjectCollapsible"
    )

    const hDownloadF = (name, type) => {
        let filename
        console.log(templates[name])
        switch (name) {
            case "indenttemplate":
                filename = indenttemplate_value
                break
            case "ethertemplate":
                filename = ethertemplate_value
                break
            case "raotemplate":
                filename = raotemplate_value
                break
            case "voistemplate":
                filename = voistemplate_value
                break
        }
        let file = new File([templates[name]], `${filename}_${type}.rtf`, {
            type: "application/msword"
        })
        saveAs(
            file,
            `${filename}_${type}.rtf`
        );
        console.log(file)
    }
    const hDelete = (name) => {
        openModal({
            type: "confirm",
            size: "sm",
            data: {
                title: "После удаления пользовательского шаблона будет установлен шаблон по умолчанию. Точно удалить?",
                buttons: {
                    cancel: {
                        title: "Вернуться",
                        onClick: () => {
                            closeModal("confirm")
                        }
                    },
                    confirm: {
                        title: "Удалить",
                        onClick: () => {
                            updateTemplates({
                                name: name,
                                body: ""
                            })
                            closeModal("confirm")
                        }
                    }
                }
            }
        })


    }
    const hModalInfo = (name) => {
        openModal({
            type: "templateCreate",
            size: "md",
            data: {
                template_type: name
            }
        })
    }

    return (
        <div>
            <h2>НАСТРОЙКА ШАБЛОНОВ</h2>
            <div className="Templates">
                <div className="Templates_one">
                    <div className="Templates_elements_row">
                        <InputText
                            label="Шаблон заявки"
                            readonly={ true }
                            attrs={ {
                                name: "indenttemplate",
                                readOnly: true,
                                value: indenttemplate_value
                            } }
                        ></InputText>
                        <ButtonCircle onClick={ () => hModalInfo("indenttemplate") }>
                            <SVGPlus />
                        </ButtonCircle>
                        <Button type="string" onClick={ () => hDownloadF("indenttemplate", "_Шаблон заявки") }>
                            <SVGDownload />
                        </Button>
                        <Button
                            type="string"
                            onClick={ () => hDelete("indenttemplate") }
                            disabled={ indenttemplate_default }
                        >
                            <SVGTrash />
                        </Button>
                    </div>
                    <div className="Templates_elements_row">
                        <InputText
                            label="Шаблон заявки эфирной справки"
                            readonly={ true }
                            attrs={ {
                                name: "ethertemplate",
                                readOnly: true,
                                value: ethertemplate_value
                            } }
                        ></InputText>
                        <ButtonCircle onClick={ () => hModalInfo("ethertemplate") }>
                            <SVGPlus />
                        </ButtonCircle>
                        <Button type="string" onClick={ () => hDownloadF("ethertemplate", "_Шаблон заявки эфирной справки") }>
                            <SVGDownload />
                        </Button>
                        <Button
                            type="string"
                            onClick={ () => hDelete("ethertemplate") }
                            disabled={ ethertemplate_default }
                        >
                            <SVGTrash />
                        </Button>
                    </div>
                </div>
                <div className="Templates_two">
                    <div className="Templates_elements_row">
                        <InputText
                            label="Шаблон отчета в рао"
                            readonly={ true }
                            attrs={ {
                                name: "raotemplate",
                                readOnly: true,
                                value: raotemplate_value
                            } }
                        ></InputText>
                        <ButtonCircle onClick={ () => hModalInfo("raotemplate") }>
                            <SVGPlus />
                        </ButtonCircle>
                        <Button type="string" onClick={ () => hDownloadF("raotemplate", "_Шаблон отчета в РАО") }>
                            <SVGDownload />
                        </Button>
                        <Button
                            type="string"
                            onClick={ () => hDelete("raotemplate") }
                            disabled={ raotemplate_default }
                        >
                            <SVGTrash />
                        </Button>
                    </div>
                    <div className="Templates_elements_row">
                        <InputText
                            label="Шаблон отчета в воис"
                            readonly={ true }

                            attrs={ {
                                name: "voistemplate",
                                readOnly: true,
                                value: voistemplate_value
                            } }
                        ></InputText>
                        <ButtonCircle onClick={ () => hModalInfo("voistemplate") }>
                            <SVGPlus />
                        </ButtonCircle>
                        <Button type="string" onClick={ () => hDownloadF("voistemplate", "_Шаблон отчета в ВОИС") }>
                            <SVGDownload />
                        </Button>
                        <Button
                            type="string"
                            onClick={ () => hDelete("voistemplate") }
                            disabled={ voistemplate_default }
                        >
                            <SVGTrash />
                        </Button>
                    </div>
                </div>
            </div>
            {/* <div style={ { marginTop: "80px" } }><ButtonBS>Сохранить</ButtonBS></div> */ }
        </div>
    )
}

const stateToProps = state => ({
    loading: state.settings_templates.loading
})

const actionsToProps = {
    loadTemplates,
    openModal,
    updateTemplates,
    closeModal
}
export default connect(stateToProps, actionsToProps)(SettingsTemplates)
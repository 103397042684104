export const UPDATE        = "Modal/VideoHistory/UPDATE"
export const LOADING       = "Modal/VideoHistory/LOADING"
export const PUT_VIDEO_HISTORY = "Modal/VideoHistory/PUT_VIDEO_HISTORY"
export const PUT_META      = "Modal/VideoHistory/PUT_META"

export const update = () => ({
    type: UPDATE
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const putMeta = ({ indentName }) => ({
    type: PUT_META,
    payload: {
        indentName,
    }
})

export const putVideoHistory = videoHistory => ({
    type: PUT_VIDEO_HISTORY,
    payload: videoHistory
})
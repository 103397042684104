import React, { useState, useEffect } from 'react'
import slide1 from "../../assets/images/slider/1.jpg"
import slide2 from "../../assets/images/slider/2.jpg"
import slide3 from "../../assets/images/slider/3.jpg"
import slide4 from "../../assets/images/slider/4.jpg"
import slide5 from "../../assets/images/slider/5.jpg"



const CarouselSlider = () => {

    let styleImg = {
        backgroundSize: "cover",
        backgroundPosition: "50% 40%",
        height: "100%",
        width: "100%"
    }

    const img = [
        <div className="image-view " style={ { ...styleImg, backgroundImage: `url(${slide1})` } }></div>,
        <div className="image-view " style={ { ...styleImg, backgroundImage: `url(${slide2})` } }></div>,
        <div className="image-view " style={ { ...styleImg, backgroundImage: `url(${slide3})` } }></div>,
        <div className="image-view " style={ { ...styleImg, backgroundImage: `url(${slide4})` } }></div>,
        <div className="image-view " style={ { ...styleImg, backgroundImage: `url(${slide5})` } }></div>,
    ];
    const desc = [
        <div className="text-description slide-right-inner">- инструменты количественной прокачки целевой аудитории</div>,
        <div className="text-description slide-right-inner">- мощные и надежные помощники в продвижении бренда</div>,
        <div className="text-description slide-right-inner">- каналы вкусной и оперативной подачи информации</div>,
        <div className="text-description slide-right-inner">- пространства для реализации самых амбициозных целей</div>,
        <div className="text-description slide-right-inner">- качественный способ позиционирования бренда</div>,
    ];

    const [activeIndex, setActiveIndex] = useState(Math.floor(Math.random(0, 5) * 5));

    useEffect(() => {
        // Запускаем интервал

        const interval = setInterval(() => {
            // Меняем состояние
            setActiveIndex((current) => {
                // Вычисляем индекс следующего слайда, который должен вывестись
                const res = current === img.length - 1 ? 0 : current + 1
                // Возвращаем индекс
                return res
            })
        }, 60000)
        // Выключаем интервал
        return () => clearInterval()
    }, [])

    // Вычисляем индекс предыдущего слайда
    const prevImgIndex = activeIndex ? activeIndex - 1 : img.length - 1

    // Вычисляем индекс следующего слайда
    const nextImgIndex = activeIndex === img.length - 1 ? 0 : activeIndex + 1


    return (
        <div className="slider-parent" style={ { textAlign: "center", alignSelf: "center", height: "100%", width: "79%", overflow: "hidden" } }>
            <div className="slider">
                <div className="slider-img-prev"
                    key={ prevImgIndex }>
                    <div className="imaga">{ img[prevImgIndex] }</div>
                </div>
                <div className="slider-img"
                    key={ activeIndex }>
                    <div className="imaga  slide-left">{ img[activeIndex] }</div>

                    <div className="text-img ">
                        {/* <span className="text-number">01/<span>04</span></span> */ }
                        <span className="text-title ">Аудио- <br />и видеомаркетинг</span>
                        {/* <span className="text-description slide-right-inner">— делает бренд узнаваемым</span> */ }
                        { desc[activeIndex] }
                        <div className="dots">
                            { img.map((item, index) => {
                                if (activeIndex === index) {
                                    return <div style={ { background: "#E34850" } }></div>
                                } else {
                                    return <div style={ { background: "#FFFFFF" } }></div>
                                }
                            })
                            }
                        </div>
                    </div>
                </div>
                <div className="slider-img-next"
                    key={ nextImgIndex }>
                    <div className="imaga">{ img[nextImgIndex] }</div>
                </div>
            </div>
        </div >
    )
}
export default CarouselSlider;
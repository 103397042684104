export const LOADING = "Tracks/LOADING"
export const TOGGLE_SELECTED_TRACK = "Tracks/TOGGLE_SELECTED_TRACK"
export const PUT_TRACKS = "Tracks/PUT_TRACKS"
export const DELETE_TRACKS = "Tracks/DELETE_TRACKS"
export const DELETE_TRACKS_EXTRA = "Tracks/DELETE_TRACKS_EXTRA"
export const LOAD_TRACKS = "Tracks/LOAD_TRACKS"
export const DOWNLOAD_TRACKS = "Tracks/DOWNLOAD_TRACKS"
export const TOGGLE_HIDE_ENDED = "Tracks/TOGGLE_HIDE_ENDED"


export const toggleHideEnded = payload => ({
    type: TOGGLE_HIDE_ENDED,
    payload
})


export const toggleSelectedTrack = ({ id, selected, onlyOne, ids }) => ({
    type: TOGGLE_SELECTED_TRACK,
    payload: {
        id,
        ids,
        selected,
        onlyOne
    }
})

export const loadTracks = (params) => ({
    type: LOAD_TRACKS,
    payload: params
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const putTracks = ({ tracks, tracksAssoc, theme }) => ({
    type: PUT_TRACKS,
    payload: {
        tracks,
        tracksAssoc,
        theme
    }
})

export const deleteTracks = selectedTracks => ({
    type: DELETE_TRACKS,
    payload: selectedTracks
})

export const deleteTracksExtra = selectedTracks => ({
    type: DELETE_TRACKS_EXTRA,
    payload: selectedTracks
})

export const downloadTracks = ({ tracksIds, type }) => ({
    type: DOWNLOAD_TRACKS,
    payload: {
        tracksIds,
        type
    }
})
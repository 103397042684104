import { takeEvery, call, put, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
//import { download } from '../../../utilities/download'
import { DOWNLOAD, downloading } from '../../../store/Modal/types/Castinfo/actions'
import { closeModal } from '../../../store/Modal/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'
import {CREATE_ADVERT} from "../../../store/Modal/types/AdvertCreate/actions";

export default function* () {
    yield takeEvery(DOWNLOAD, worker)
}
function log(message) {
    return function () {
        alert(message);
    };
}
function download(file, callback) {
    var request = new XMLHttpRequest();
    request.responseType = 'blob';
    request.open('GET', file);
    request.addEventListener('load', log('load ' + file));
    request.addEventListener('error', log('error ' + file));
    request.addEventListener('progress', log('progress ' + file));
    request.addEventListener('load', function () {
        callback(request.response);
    });
    request.send();
}

function save(object, name) {
    var a = document.createElement('a');
    var url = URL.createObjectURL(object);
    a.href = url;
    a.download = name;
    a.click();
}

function* worker({ payload }) {
    yield put(downloading(true))
    try {
        const selectedTracks = yield select(state => state.tracks.selected)
        const trackId = Number(selectedTracks[0])

        if (!isNaN(trackId)) {
            const url = buildUrl(selectedTracks, payload)
            const response = yield call(fetchDownload, url)
            
            let data = {}
            try{
                data = yield call(blobToJson, response.data)
            }
            catch(e){

            }
                                  
            
            if (response.status === 200 && !!!data.error) {
                const contentDisposition = response.headers['content-disposition'];
                const fileName = getFileName(contentDisposition);
                save(response.data, fileName)
            } else {
                yield put(showAlertError({
                    //errors: [`.`],
                    text: "Ошибка: " + data.error
                }))
            }

            // yield download(buildUrl(selectedTracks, payload))
            yield put(downloading(false))
            yield put(closeModal("castinfo"))
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DOWNLOAD}.`, e],
            text: "При скачивании произошла ошибка. Попробуйте повторить."
        }))
        yield put(downloading(false))
    }
}

//attachment; filename*=UTF-8''432657-Ð´Ð°ÑÑ_ÑÑÐ¿ÐµÑ-ether.rtf было так - не понимает iso-8859
//432657-%D0%B4%D0%B0%D1%80%D1%81_%D1%81%D1%83%D0%BF%D0%B5%D1%80-ether.rtf -сделали так - понимает

function getFileName(disposition) {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName = null;
    if (utf8FilenameRegex.test(disposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    } else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and
        //  slicing off everything before 'filename='
        const filenameStart = disposition.toLowerCase().indexOf('filename=');
        if (filenameStart >= 0) {
            const partialDisposition = disposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition );
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
    }
    return decodeURI(fileName);
}

function buildUrl(trackId, data) {
    let get = {
        id: trackId,
        t: "ether"
    }

    if (!data.allPeriod) {
        get.fromdate = data.dates.from
        get.todate = data.dates.to || data.dates.from
    }

    return buildGETUrl("/docs/select", get)
}

function fetchDownload(url) {
    return axios.get(url, {responseType: "blob"})
        .then(response => {
            //хотелось бы иметь заголовок Content-Disposition (нужно это добавить в CORS-заголовок Expose)
            console.log(response);            
            return response;
        }).catch(error => error.response)
}

function blobToJson(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            try {
                const json = JSON.parse(reader.result);
                resolve(json);
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsText(blob);
    });
}

import React from 'react'

import Place from '../../../../components/SpaceContent/Place'
import Menu from './Menu'


const What = ({ page }) => (
    <Place number={ 1 }>
        <Menu page={ page } />
    </Place>
)

export default What
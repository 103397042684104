import React from 'react'
import {connect} from 'react-redux'

import {openModal} from '../../../../../store/Modal/actions'

import {generateStateKey} from '../../../../../utilities/generateStateKey'
import {cl} from '../../../../../utilities/cl'

import {ADVERT_TRACKS} from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'
import Presets from '../../../../../components/Settings/components/Presets'
import ScrollContainer from '../../../../../components/ScrollContainer'


class SettingsPlace extends React.PureComponent { 
    constructor(props) {
        super(props)
        this.state = {
            type: "empty",
            objectsQuantity: 0,
            activeSlide: 0,
            toggleLeftDisabled: true,
            toggleRightDisabled: false,
        }

        this.typesSettings = {
            "track": {
                title: "Настройка ролика",
                presetsOn: true,
                setName: ADVERT_TRACKS,
            },
            "tracks": {
                title: "Настройка группы роликов",
                presetsOn: true,
                setName: ADVERT_TRACKS,
            },
            "line": {
                title: "Настройка бегущей строки",
                presetsOn: false,
                setName: "",
            },
            "empty": {
                presetsOn: false,
                setName: "",
            }
        }

        this.toggleActiveSlide = this.toggleActiveSlide.bind(this)
        this.updateType = this.updateType.bind(this)
    }

    componentDidMount() {
        this.updateType()
    }

    componentDidUpdate() {
        this.updateType()
    }

    render() {
        let {
            className = "",
            selectedTracks,
            selectedObjects,
            selectedGroups,
            placeNumber,
            active
        } = this.props
        const {
            type
        } = this.state

        const stateKey = generateStateKey([ selectedTracks, selectedObjects ])

        className = cl(
            className,
            "AdvertWhenSettingsCollapsible"
        )

        return(
            <Collapsible
                active={active}
                id={331}
                className={className}
                types={["simple"]}
                title={this.typesSettings[type].title}
                placeNumber={placeNumber}
                elements={
                    <>
                        {/* Пресеты */}
                        {this.typesSettings[type].presetsOn &&
                            <Presets
                                stateKey={stateKey}
                                type={this.typesSettings[type].setName}
                            />
                        }
                    </>
                }
            >
                {/* <ScrollContainer
                    className="SettingsPlace__MainScrollContainer"
                    size="mg"
                    collapsed={false}
                > */}
                    <Settings
                        key={'s'+stateKey}
                        set={this.typesSettings[type].setName}
                        data={{
                            indentIds: 
                                (type === "track" || type === "tracks")
                                    ? selectedTracks
                                    : [],
                            objectIds: selectedObjects,
                            stateKey: stateKey,
                            hideEnded: this.props.hideEnded
                        }}
                    />
                {/* </ScrollContainer> */}
            </Collapsible>
        )
    }

    toggleActiveSlide(direction) {
        let newActive, newState = {}
        
        this.setState(state => {
            if(direction === "left") newActive = state.activeSlide - 1
            if(direction === "right") newActive = state.activeSlide + 1

            if(newActive < 0) newActive = 0
            if(newActive > state.objectsQuantity) newActive = state.objectsQuantity

            newState.activeSlide = newActive
            newState.toggleLeftDisabled = (newActive === 0) ? true : false
            newState.toggleRightDisabled = (newActive === state.objectsQuantity) ? true : false

            return newState
        })
    }

    updateType() {
        const {selectedTracks, selectedObjects} = this.props
        let type = "empty",
            quantity = 0

        if(selectedTracks.length === 1 && selectedObjects.length){
            type = "track"
        }
        if(selectedTracks.length > 1 && selectedObjects.length){
            type = "tracks"
        }

        quantity = selectedObjects.length - 1

        this.setState(state => {
            if(type !== state.type || quantity !== state.objectsQuantity) {
                return {
                    type: type,
                    objectsQuantity: quantity,
                }
            } 
        })
    }
}

const stateToProps = state => ({
    selectedTracks: [...state.tracks.selected],
    selectedObjects: [...state.objects.selected],
    selectedGroups: [...state.objectsGroup.selected],
    hideEnded: state.tracks.hideEnded

})

const actionsToProps = {
    openModal
}

export default connect(stateToProps, actionsToProps)(SettingsPlace)
import React, {memo, useMemo} from 'react'
import memoize from 'memoize-one';

import {cl} from '../../utilities/cl'
import {isFunc} from '../../utilities/isFunc'
import {carryMemoize} from '../../utilities/carryMemoize'

import ScrollContainer from '../ScrollContainer'
import ElementsGroup from '../ElementsGroup'
import Spinner from '../Spinner'
import Button from '../Button'
import SvgEdit from '../SVG/Edit'
import SvgTrash from '../SVG/Trash'
import {getSortedObj} from '../../utilities/getSorted'


// const sampleData = {
//     className: "",
//     type, // simple || destructed
//     scroll: {
//         on: true,
//         data: {}
//     },
//     label: "Название",
//     header: {
//         size, // "md" || "sm"
//         left: <></>,
//         right: <></>,
//         element: <></>,
//     },
//     content: {
//         className: "",
//         vatiant: "none",
//         items: [
//             {id: 1, className: "", title: ""},
//             {id: 2, className: "", element: <></>},
//         ]
//     },
//     selected: [],
//     loading: false,
//     placeholder: "Элементы пока что отсутствуют.",
//     onClick,
//     buttons: {
//         edit: {
//             on: true,
//             mobile: true,
//             onClick: ({ id }, event) => console.log()
//         },
//         remove: {}
//     }
// }

// const ListItem = ({
const ListItem = memo(({
    className = "",
    variant = "",
    id,
    title,
    isSelected,
    buttons,
    children
}) => {

    let isElement = React.isValidElement(children)

    className = cl(
        className,
        {"List__item": [
            {"text": !isElement}
        ]},
        {"selected": isSelected},
        {"selectable-item": variant !== "none"},
        {"not-decorate": variant === "none"},
    )

    return(
        <div className={className} key={id} data-list-item-id={id}>
            {isElement
                ? children
                : <span className="selectable-item__color">
                    {title}
                </span>
            }
            {buttons
                && getButtons()
            }
        </div>
    )

    function getButtons() {
        const {
            edit,
            remove,
        } = buttons

        if(edit || remove) {
            return(
                <ElementsGroup className="List__item-buttons" position="right" offset="mr">
                    {(edit.on && edit.mobile)
                        && <Button className="button-edit" type="string">
                            <SvgEdit size="sm1" variant="dark"/>
                        </Button>
                    }
                    {(remove.on && remove.mobile)
                        && <Button className="button-remove" type="string">
                            <SvgTrash size="sm1" variant="dark"/>
                        </Button>
                    }
                </ElementsGroup>
            )
        } else {
            return null
        }
    }
// }
})

const memoGetSortedObj = carryMemoize(getSortedObj, 10)

const List = ({
    className = "",
    name,
    type,
    scroll = {
        on: true,
    },
    label,
    header,
    content,
    selected = [],
    loading = false,
    placeholder = "Элементы пока что отсутствуют.",
    onClick,
    buttons = {},
    sort = {},
    empty
}) => {
    let items = content?.items || []
    
    items = getSortedObj(items, sort.by, sort.type, sort.order)

    // const memoItems = memoGetSortedObj(
    //     [items, sort.by, sort.type, sort.order],
    //     [sort.by, sort.type, sort.order, items.length, ...(sort.deps || [])],
    //     sort.required
    // )

    const headerSizes = {
        pb: header?.size || "md",
        elementsOffset: (header?.size && header?.size === "sm") ? "mr" : "sm1"
    }

    className = cl(
        className,
        {"List": [
            type,
            {"cursor-pointer": typeof onClick === "function"}
        ]}
    )

    const headerClassName = cl(
        {"List__header": [
            {"elements": () =>
                (header && !React.isValidElement(header.element) && (header.left || header.right))
                    ? true
                    : false
            },
            `pb-${headerSizes.pb}`
        ]}
    )

    const contentClassName = cl(
        {"List__content": [
            {"white": () => (type === "simple" && scroll.on !== true) ? true : false}
        ]}
    )

    const scrollVariant = cl(
        {"white": () => (type === "simple" && scroll.on === true) ? true : false},
        {"none": () => (type === "destructed" && scroll.on === true) ? true : false},
    )

    return(
        <section className={className}>
            {(header || label)
                && <div className={headerClassName}>
                    {React.isValidElement(header?.element)
                        ? header?.element
                        : <>
                            <ElementsGroup position="left" offset={headerSizes.elementsOffset}>
                                {label
                                    && <div className="List__label">
                                        {label}
                                    </div>
                                }
                                {header?.left}
                            </ElementsGroup>
                            <ElementsGroup position="right" offset={headerSizes.elementsOffset}>
                                {header?.right}
                            </ElementsGroup>
                        </>
                    }
                </div>
            }

            <div className={contentClassName} onClick={hClick}>
                {scroll.on
                    ? <ScrollContainer
                        className={scroll.data?.className}
                        size={scroll.data?.size || "lg"}
                        collapsed={scroll.data?.collapsed !== empty ? scroll.data?.collapsed : true}
                        maxHeight={scroll.data?.maxHeight || empty}
                        variant={scrollVariant}
                    >
                        {getContent()}
                    </ScrollContainer>
                    : getContent()
                }
            </div>
        </section>
    )

    function getContent() {
        if(!content) return null

        // Загрузка
        if(loading === true) {
            return(
                <Spinner className="pt-xs pb-xs"/>
            )
        }

        // Контент
        if(items.length) {
            return(
                items.map(item =>
                    <ListItem
                        className={`${content.className || ""} ${item.className || ""}`}
                        variant={content.variant}
                        id={item.id}
                        key={item.id}
                        title={item.title}
                        isSelected={item.selected !== empty ? item.selected : (selected.indexOf(item.id) !== -1)}
                        buttons={buttons}
                    >
                        {item.element}
                    </ListItem>
                )
            )

        // Пусто
        } else {
            return(
                <div className="List__empty">
                    {placeholder}
                </div>
            )
        }
    }

    function hClick(event) {
        const target = event.target
        const item   = target.closest(".List__item")
        const edit   = target.closest(".button-edit")
        const remove = target.closest(".button-remove")

        if(item) {
            const id = Number(item.dataset.listItemId)
            if(edit) {
                if(isFunc(buttons?.edit?.onClick)) {
                    buttons.edit.onClick({ name, id }, event)
                }
            }
            else if(remove) {
                if(isFunc(buttons?.remove?.onClick)) {
                    buttons.remove.onClick({ name, id }, event)
                }
            }
            else {
                if(isFunc(onClick)) {
                    onClick({ name, id, items }, event)
                }
            }
        }
    }

    function hClick2(id)
    {
        console.log(id);
    }
}

export default List
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_SILENT } from '../../../../store/Settings/sets'
import { toggleSelectedItems, deleting } from '../../../../store/Settings/actions'
import { DELETE_ITEMS, loadItems } from '../../../../store/Settings/actions/sets/Object/Silent'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_ITEMS, worker)
}

function* worker({ payload }) {
    let { stateKey, itemIds } = payload

    try {
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedItems = yield select(state => state.settings.settings[OBJECT_SILENT][stateKey].selected || [])

        itemIds = itemIds || selectedItems

        if (itemIds.length) {
            yield put(deleting({
                type: OBJECT_SILENT,
                stateKey,
                deleting: true,
            }))

            const fetchedDelete = yield call(fetchDelete, itemIds, selectedObjects)

            // Ошибка
            if (fetchedDelete.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${DELETE_ITEMS}.`, fetchedDelete],
                    text: "Не удалось выполнить удаление. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(showAlertSuccess({
                    responses: [`Успешное выполнение ${DELETE_ITEMS}.`, fetchedDelete],
                    text: "Элементы успешно удалены."
                }))
                yield put(loadItems({
                    stateKey,
                    required: true,
                }))
                yield put(toggleSelectedItems({
                    type: OBJECT_SILENT,
                    stateKey,
                    itemIds: [0],
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_ITEMS}.`, e],
            text: "Не удалось выполнить удаление. Попробуйте повторить."
        }))
    }

    finally {
        yield put(deleting({
            type: OBJECT_SILENT,
            stateKey,
            deleting: false,
        }))
    }
}

function fetchDelete(id, ms_id) {
    id = id.join(",")
    ms_id = ms_id.join(",")
    return axios.get("/msbox/silent/delete", { params: { id, ms_id } })
        .then(response => response.data)
}
import React from 'react'

import {
    ADVERT_TRACKS,
    OBJECT_OBJECT,
    OBJECT_MODEM,
    OBJECT_NET,
    OBJECT_BACKGROUND,
    OBJECT_SCHEDULE,
    OBJECT_SILENT,
    OBJECT_VOLUME,
} from '../../store/Settings/sets'

import AdvertTracks from './sets/AdvertTracks'
import ObjectObject from './sets/ObjectObject'
import ObjectModem from './sets/ObjectModem'
import ObjectNet from './sets/ObjectNet'
import ObjectBackground from './sets/ObjectBackground'
import ObjectSchedule from './sets/ObjectSchedule'
import ObjectSilent from './sets/ObjectSilent'
import ObjectVolume from './sets/ObjectVolume'


const Settings = ({ set, data}) => {
    return(
        <>
            {(set === ADVERT_TRACKS)
                && <AdvertTracks {...data}/>
            }
            {(set === OBJECT_OBJECT)
                && <ObjectObject {...data}/>
            }
            {(set === OBJECT_MODEM)
                && <ObjectModem {...data}/>
            }
            {(set === OBJECT_NET)
                && <ObjectNet {...data}/>
            }
            {(set === OBJECT_BACKGROUND)
                && <ObjectBackground {...data}/>
            }
            {(set === OBJECT_SCHEDULE)
                && <ObjectSchedule {...data}/>
            }
            {(set === OBJECT_SILENT)
                && <ObjectSilent {...data}/>
            }
            {(set === OBJECT_VOLUME)
                && <ObjectVolume {...data}/>
            }
        </>
    )
}

export default Settings
export const UPDATE = "Modal/AdsCreate/UPDATE"
export const LOADING = "Modal/AdsCreate/LOADING"
export const CREATE_ADS = "Modal/AdsCreate/CREATE_ADS"
export const CREATE_ADS_EXTRA = "Modal/AdsCreate/CREATE_ADS_EXTRA"

export const update = () => ({
    type: UPDATE
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const createAds = adsData => ({
    type: CREATE_ADS,
    payload: adsData
})

export const createAdsExtra = adsData => ({
    type: CREATE_ADS_EXTRA,
    payload: adsData
})
import React from 'react'
import { connect } from 'react-redux'

import { openModal } from '../../../../../store/Modal/actions'

import Collapsible from '../../../../../components/Space/Collapsible'
import Lines from '../../../../../components/Lines'


const Line = ({ className = "", active, placeNumber }) => {
    return (
        <>
            <Collapsible
                active={ active }
                id={ 133 }
                className={ `CollapsibleLine ${className}` }
                title='Бегущая строка'
                placeNumber={ placeNumber }
                mobileControl={ false }
                buttons={ {
                    add: {
                        on: true,
                        title: "Добавить бегущую строку",
                        onClick: () => openModal({
                            type: "createLine",
                        })
                    },
                    edit: {
                        on: true,
                        mobile: false
                    },
                    remove: {
                        on: true,
                        mobile: false
                    }
                } }>

                <Lines />

            </Collapsible>
        </>
    )
}

const actionsToProps = {
    openModal
}

export default connect(null, actionsToProps)(Line)
import React from 'react'

import { cl } from '../../utilities/cl'

import { MAIN_OBJECT, MAIN_ADVERT, MAIN_CONTENT } from '../../routes/routes'


const ObjectsMenu = ({ className }) => (
    <nav className={ cl("ObjectsMenu", className) }>
        <div className="nav">
            <a
                href={ MAIN_OBJECT }
                className={ cl(
                    "nav-link",
                    { "active": window.location.pathname.startsWith(MAIN_OBJECT) }
                ) }
            >
                Объект
            </a>
            <a
                href={ MAIN_ADVERT }
                className={ cl(
                    "nav-link",
                    { "active": window.location.pathname.startsWith(MAIN_ADVERT) }
                ) }
            >
                Реклама
            </a>
            <a
                href={ MAIN_CONTENT }
                className={ cl(
                    "nav-link",
                    { "active": window.location.pathname.startsWith(MAIN_CONTENT) }
                ) }
            >
                Фоновый контент
            </a>
        </div>
    </nav>
)

export default ObjectsMenu
import React from 'react'
import { connect } from 'react-redux'

import ObjectItem from './ObjectItem'


const ObjectItemContainer = props => (
    <ObjectItem
        id={ props.id }
        obj={props.obj}
        statusObject={props.statusObject}
        name={ props.name }
        selected={ props.selected }
        markItems={ props.markItems }
        filteredTagId={ props.filteredTagId }
        tags={ props.tags }
        address={ props.address }
        fullName={ props.fullName }
        mute={ props.mute }
        statusText={ props.statusText }
    />
)

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.objects.selected],
    filteredTagId: state.objectsByTags.filteredTagId,
    tags: state.objectsByTags.tagsAssoc,
    statusObject: state.objects.statusObject,
})

export default connect(stateToProps, null)(ObjectItemContainer)
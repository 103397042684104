import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Button as ButtonBS} from 'react-bootstrap'
import Header from '../components/header'
import {mapValidObj} from "../../../utilities/valid";
import validateMap from "../../Settings/sets/AdvertTracks/validateMap";
import {carryUpdate} from "../../Settings/utilities/carryUpdate";
import {ADVERT_TRACKS} from "../../../store/Settings/sets";
import {updateHelper} from "../../Settings/utilities/updateHelper";
import {formatToMinutes} from "../../Settings/utilities/formatTime";
import GroupClips from "../../Settings/sets/AdvertTracks/groups/Clips";
import fieldDates from "../../Settings/sets/AdvertTracks/items/dates";
import Channel from "../../Settings/sets/AdvertTracks/items/Channel";
import fieldFromtime from "../../Settings/sets/AdvertTracks/items/fromtime";
import fieldTotime from "../../Settings/sets/AdvertTracks/items/totime";
import fieldBlock from "../../Settings/sets/AdvertTracks/items/block";
import fieldInhour from "../../Settings/sets/AdvertTracks/items/__inhour";
import fieldSteptime from "../../Settings/sets/AdvertTracks/items/steptime";
import SettingsDays from "../../Settings/components/Days";
import {updateOptions} from '../../../store/Settings/actions';
import fieldInday from "../../Settings/sets/AdvertTracks/items/__inday";
import GroupSave from "../../Settings/sets/AdvertTracks/groups/Save";
import Builder from "../../Settings/Builder";
import {cl} from "../../../utilities/cl";
import {updateClip} from "../../../store/Settings/actions/components/Clips";


const SettingsClipEdit = ({
                              className = "",
                              allObjectsAssociated,
                              selectedDatas,
                              // indentIds,
                              // objectIds,
                              // selectedIds,
                              //clipData,
                              //settings,
                              //stateKey,
                              updateOptions,
                              toggleOpened,
                              loading,
                              updateClip,
    onSuccess
                          }) => {
    className = cl(className, "SettingsAdvertTracks")
    const selectedScheduleArray = Object.values(selectedDatas)
    let action = 'replace';
    let clipData = selectedScheduleArray[0];
    const objectIds = [...new Set(selectedScheduleArray.map(i => i.ms_id))];
    let selectedTracks = [];
    selectedScheduleArray.forEach(i => selectedTracks.push(...i.indent_ids));
    selectedTracks = [...new Set(selectedTracks)];
    // console.log(selectedScheduleArray, objectIds, selectedTracks)
    const [settings, setSettings] = useState(clipData);
    //const validMap = mapValidObj(validateMap, settings)

    updateOptions = (data) => {
        setSettings({...settings, ...data})
    }

    const checkChanged = () => {
        if (clipData.fromdate === settings.fromdate || clipData.todate === settings.todate || selectedScheduleArray.length > 1) {
            action = ('update')
        } else {
            action = ('replace')
        }
        if (clipData.fromtime !== settings.fromtime || clipData.totime !== settings.totime || clipData.block !== settings.block || clipData.daymask !== settings.daymask || clipData.channel !== settings.channel || clipData.steptime != settings.steptime) {
            action = ('replace')
        }
    }
    checkChanged();
    const hChange = (name, handler) => function (params) {
        let value = params.value;
        if (handler) {
            value = handler(value);
        }
        if (name === '__inday' && value === false) {
            settings.daymask = 127;
        }
        if (name === '__inday' && value === true) {
            settings.daymask = 0;
        }
        if (name === 'daymask' && value > 0) {
            settings['__inday'] = false;
        }
        settings[name] = value;
        setSettings({...settings});
        //console.log(settings, params, name, handler)
    }

    const blockHandler = (val) => val.join(",")
    const steptimeHandler = (val) => formatToMinutes(val)

    const hSubmit = () => {
        updateClip({oldClipData: clipData, clipData: settings, action, selectedScheduleArray, onSuccess});
    }

    let title = 'Редактирование расписания'

    return (
        <div className="ModalAdsEdit">
            <Header
                // title={`Редактирование расписания ${indentIds.length > 1 ? ('роликов (' + indentIds.length + ')')  : 'ролика'}`}
                title={title}
                toggleOpened={toggleOpened}
            />
            <Builder
                className={className}
                header={"Объект"}
                groups={{
                    left: [
                        // Group
                        {
                            data: {},
                            items: [
                                {
                                    title: "Период трансляции",
                                    components: fieldDates({
                                        settings,
                                        updateOptions
                                    })
                                },
                                selectedScheduleArray.length === 1 ? <Channel
                                    //stateKey={stateKey}
                                    settings={settings}
                                    objectIds={objectIds}
                                    objectsAssociated={allObjectsAssociated}
                                    name="channel"
                                    onChange={hChange("channel")}
                                /> : ''
                            ]
                        },

                        // Group
                        selectedScheduleArray.length === 1 ? {
                            data: {
                                comment: "* Если не указывать время, то ролик будет запрограммирован на целый день"
                            },
                            items: [
                                {
                                    title: "Время первой трансляции",
                                    components: fieldFromtime({
                                        settings,
                                        name: "fromtime",
                                        onChange: hChange("fromtime"),
                                    })
                                },
                                {
                                    title: "Время последней трансляции",
                                    components: fieldTotime({
                                        settings,
                                        name: "totime",
                                        onChange: hChange("totime"),
                                    })
                                },
                            ]
                        } : '',

                        // Group
                        selectedScheduleArray.length === 1 ? {
                            data: {
                                // comment: "* Выберите конкретные минуты выхода ролика, либо укажите, через какой период он должен чередоваться в эфире"
                            },
                            items: [
                                {
                                    title: "Минуты часа",
                                    //disabled: (validMap.__inhour || validMap.steptime),
                                    components: fieldBlock({
                                        settings,
                                        name: "block",
                                        onChange: hChange("block", blockHandler),
                                    })
                                },
                                // {
                                //     title: "Трансляций в час",
                                //     components: fieldInhour({
                                //         settings,
                                //         name: "__inhour",
                                //         onChange: hChange("__inhour"),
                                //         disabled: (validMap.block || validMap.steptime),
                                //     })
                                // }
                            ]
                        } : '',
                    ],

                    right: [
                        // Group
                        selectedScheduleArray.length === 1 ? {
                            data: {
                                wrap: true
                            },
                            items: [
                                {
                                    title: "Чередовать ролик через",
                                    components: fieldSteptime({
                                        settings,
                                        name: "steptime",
                                        onChange: hChange("steptime", steptimeHandler),
                                        //disabled: (validMap.block || validMap.__inhour),
                                    })
                                },
                                <SettingsDays
                                    value={settings.daymask}
                                    onChange={hChange("daymask")}
                                    //disabled={validMap.__inday}
                                />
                            ]
                        } : '',

                    ],
                }}
            />
            <div className="Modal__footer">
                <ButtonBS variant="primary" onClick={hSubmit}
                          disabled={loading}>
                    {loading ? "Сохранение..." : action === 'replace' ? 'Заменить все расписание' : 'Продлить'}
                </ButtonBS>
            </div>
        </div>
    )
}

const stateToProps = (state) => {
    return {
        allObjectsAssociated: state.objects.allObjectsAssociated
    }
}

const actionsToProps = {
    updateOptions,
    updateClip
}

export default connect(stateToProps, actionsToProps)(SettingsClipEdit)

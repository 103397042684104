import React, { useEffect, useState, useRef, useCallback } from 'react'

import { connect } from 'react-redux'
import ScrollContainer from '../../../../../components/ScrollContainer'
import Spinner from '../../../../../components/Spinner'
import PlaylistItem from '../../../../../components/ContentMusic/PlaylistItem'
import Empty from '../PlaylistSongs/Empty'
import List from '../../../../../components/DraggableList'
import TrackList from '../PlaylistSongs'
import SongItem from './Songs'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '../../../../../components/Button'
import Trash from '../../../../../components/SVG/Trash'
import Ok from '../../../../../components/SVG/Ok'
import Plus from '../../../../../components/SVG/PlusCircle'
import Minus from '../../../../../components/SVG/MinusCircle'
import ElementsGroup from '../../../../../components/ElementsGroup'
import { sortAllVideos } from '../../../../../store/ContentAllVideolists/actions'


const AllVideos = ({
    searchActive,
    searchFounded = [],
    songs = [],
    toggleSelected,
    selected,
    videolist_items_all,
    videolist_content_all,
    selected_video_all,
    addVideos,
    selectAll,
    allVideolists,
    sortAllVideos
}) => {
    // if (searchActive) allSongs = searchFounded
    songs = [1, 2]
    const [sort_direction, setSortDirection] = useState("")


    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortAllVideos({ direction: sort_direction, sort_type: type, id: selected[0] })
    }



    return (
        <section className="" style={ { width: "61%", marginLeft: "2%" } }>
            <h2>ВСЕ ВИДЕО</h2>
            <div style={ { marginTop: -36, marginBottom: 36 } }>
                <ElementsGroup position="right">
                    <Button
                        type="string"
                        disabled={ (selected_video_all.length > 0) ? false : true }
                        onClick={ () => addVideos() }
                    >
                        <Plus size={ `sm` } /> Добавить
                    </Button>

                    <Button
                        type="string"
                        onClick={ () => selectAll() }
                    >
                        <Ok size={ `xs` } /> Выделить все строки
                    </Button>
                </ElementsGroup>
            </div>
            { selected.length > 0
                ? <div>
                    <sections className="Songs">
                        <header className="Songs__control">
                            <div className="Songs__grid">
                                <div className="id">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("id") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ID
                                    </button>
                                </div>
                                <div className="composition_video">
                                    <button className="spp-btn spp-btn_string">
                                        <SvgArrowUpDown
                                            className="spp-svg"
                                            onClick={ () => sort("name") }
                                        />
                                        ПРОИЗВЕДЕНИЕ
                                    </button>
                                </div>
                                <div className="duration_video">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("duration") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ДЛИТЕЛЬНОСТЬ
                                    </button>
                                </div>
                                <div className="type_video">
                                    <button
                                        className="spp-btn spp-btn_string"

                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ТИП
                                    </button>
                                </div>
                                <div className="added_video">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("added") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ДОБАВЛЕНО
                                    </button>
                                </div>
                            </div>
                        </header>
                    </sections>
                    { videolist_content_all }
                </div>
                : <Empty title="Выберите видео список." />
            }
        </section>
    )
}

const stateToProps = state => ({
    allVideolists: state.videolists_all.allVideolists,
    selected: state.videolists_all.selected,
    selected_video_all: state.videolists_all.selected_video
})
const actionsToProps = {
    sortAllVideos
}

export default connect(stateToProps, actionsToProps)(AllVideos)
import React from 'react'
import { connect } from 'react-redux'

import { loadVideolists, toggleSelectedVideolist } from '../../store/ContentVideolists/actions'



import Videolists from './Videolists'


const VideolistsContainer = props => (

    <Videolists
        className={ props.className }
        types={ props.types }

        allVideolists={ props.allVideolists }

        trackObjects={ props.trackObjects }
        searchFounded={ props.searchFounded }

        selectedVideolists={ props.selectedVideolists }
        selectedTracks={ props.selectedTracks }

        loading={ props.loading }
        searchActive={ props.searchActive }

        loadVideolists={ props.loadVideolists }
        toggleSelectedVideolist={ props.toggleSelectedVideolist }
    />
)
/*
TODO
- фильтр плейлистов по аккаунту юзера
*/

const stateToProps = state => ({
    allVideolists: state.videolists.allVideolists,
    searchFounded: [...state.search["objects"].founded],
    selectedVideolists: [...state.videolists.selected],
    loading: state.videolists.loading,
    searchActive: state.search["objects"].modeActive,
})

const actionsToProps = {
    loadVideolists,
    toggleSelectedVideolist,
}

export default connect(stateToProps, actionsToProps)(VideolistsContainer)
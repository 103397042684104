import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS } from 'react-bootstrap'

import { cl } from '../../../utilities/cl'
import { normalizeNumberSpaces } from '../../../utilities/normalize'

import { updateTemplates } from '../../../store/Templates/actions'

import Header from '../components/header'

import Uploader from '../../Uploader'
import Select from '../../Form/Select'
import InputText from '../../Form/InputText'
import { dateFormat } from '../../../utilities/formatDate'

class TemplateCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filename: false,
            name: false,
            description: false,
            sumbitDisabled: true,
            track: {
                name: "—",
                size: "—",
                duration: "-",
                lastmod: "-",
                template_body: "",
                template: ""
            }
        }

        this.hSubmit = this.hSubmit.bind(this)
        this.hLoadfile = this.hLoadfile.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.validateForm = this.validateFrom.bind(this)
    }

    // For convenience...

    hLoadfile(file) {
        const reader = new FileReader();
        reader.readAsText(file)
        reader.onload = (event) => {
            if (file.type == "application/msword") {
                // let text = reader.readAsText(file)
                // console.log(event)
                console.log(file)
                this.setState(() => ({
                    filename: file,
                    name: file.name,
                    track: {
                        name: file.name,
                        size: file.size,
                        lastmod: file.lastModifiedDate,
                        template: this.props.template_type,
                        template_body: reader.result
                    }
                }), this.validateFrom)
                console.log(reader.result)
            }
            if (file.type !== "image/jpeg") {
                // media.onloadedmetadata = () => {

                //     this.setState(() => ({
                //         filename: file,
                //         track: {
                //             name: file.name,
                //             size: file.size,
                //             duration: media.duration
                //         }
                //     }), this.validateFrom)
                // };
            } else {
                this.setState(() => ({
                    filename: file,
                    name: file.name,
                    track: {
                        name: file.name,
                        size: file.size,
                        duration: "--:--"
                    }
                }), this.validateFrom)
            }

        };
        //reader.readAsDataURL(file);



    }

    hInputText({ name, value }, event) {
        if (name && value) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
    }

    hSubmit() {
        this.props.updateTemplates({
            name: this.state.track.template,
            body: this.state.track.template_body
        })
    }

    validateFrom() {
        if (this.state.filename
            && (typeof this.state.track.name === "string" && this.state.track.name.length > 1)
            // && (typeof this.state.description === "string" && this.state.description.length > 3)
        ) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
    }

    componentDidMount() {
        //this.props.update()
    }

    render() {
        Date.prototype.format = function (mask, utc) {
            return dateFormat(this, mask, utc);
        };
        let mod = new Date(this.state.track.lastmod)
        return (
            <div className="ModalAdsCreate">
                <Header
                    title="Добавление шаблона"
                    toggleOpened={ this.props.toggleOpened }
                />

                <div className="ModalDoubleGrid">
                    <div className="ModalDoubleGrid__left">
                        {/* <div className="ModalDoubleGrid__title">
                            Настройка
                        </div> */}

                        <div className="ModalDoubleGrid__title Uploader-title">
                            Файл
                        </div>

                        <Uploader
                            attrs={ {
                                name: "filename",
                                accept: ".rtf"
                            } }
                            trackName={this.state.track.name}
                            fileTypes={ [".rtf"] }
                            onChange={ this.hLoadfile }
                        />

                        {/* <div className="ModalAdsCreate__grid">
                            <InputText
                                label="Название"
                                comment="* Внимание! Если имя файла не задано, звук будет импортирован из выбранного по-умолчанию"
                                attrs={ {
                                    name: "name",
                                    placeholder: "Название ролика"
                                } }
                                onChange={ this.hInputText }
                            />

                        </div> */}
                    </div>

                    <div className="ModalDoubleGrid__right">
                        <div className="ModalDoubleGrid__title">
                            Информация о файле
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Оригинальное имя файла</div>
                            <div className={ cl("info", { "c-prim": this.state.track.name !== "—" }) }>
                                { this.state.track.name }
                            </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Последняя модификация</div>
                            <div className="info"> { this.state.track.lastmod === "-" ? "-" : mod.format("dd.mm.yyyy HH:MM:ss") } </div>
                        </div>

                        <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Размермер в килобайтах</div>
                            <div className="info"> { normalizeSize(this.state.track.size) } </div>
                        </div>

                        {/* <div className="ModalDoubleGrid__info">
                            <div className="label lables-titles">Время создания</div>
                            <div className="info">

                            </div>
                        </div> */}
                    </div>

                </div>

                <div className="Modal__footer">
                    <ButtonBS onClick={ this.hSubmit } variant="primary" disabled={ this.state.sumbitDisabled || this.props.loading }>
                        { this.props.loading ? "Сохранение..." : "Сохранить" }
                    </ButtonBS>
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
    loading: state.settings_templates.loading,
})

const actionsToProps = {
    updateTemplates
}

function normalizeSize(size) {
    if (typeof size === "number") {
        size = Math.ceil(size / 1000)
        size = `${size.toLocaleString()} КБ`
    }
    return size
}



export default connect(stateToProps, actionsToProps)(TemplateCreate)
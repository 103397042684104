import React from 'react'

import Line from './Line'
import ScrollContainer from '../ScrollContainer'
import Button from '../Button'

import SvgArrowUpDown from '../SVG/ArrowUpDown'
import SvgEdit from '../SVG/Edit'
import SvgTrash from '../SVG/Trash'


const Lines = ({ className = "" }) => {
    return(
        <section className={`Lines ${className}`}>
            <div className="Lines__control">
                <div className="Lines__grid">
                    <div className="number">
                        <Button type="string">
                            <SvgArrowUpDown title='Сортировка по номеру'/>
                            №
                        </Button>
                    </div>
                    <div className="title">
                        <Button type="string">
                            <SvgArrowUpDown title='Сортировка по названию'/>
                            Название
                        </Button>
                    </div>
                    <div className="message spp-font-text-sm">* Функционал работает только в режиме трансляции Фонового видео</div>
                    <div className="buttons">
                        <div>
                            <Button type="string">
                                <SvgEdit className='m-0'/>
                            </Button>
                        </div>
                        <div>
                            <Button type="string">
                                <SvgTrash className='m-0'/>
                            </Button>
                        </div>
                    </div>
                </div>  
            </div>

            <div className="Lines__list">
                <ScrollContainer variant="white" size="lg">
                    <Line/>
                    <Line/>
                    <Line/>
                    <Line/>
                </ScrollContainer>
            </div>
        </section>
    )
}

export default Lines
import React from 'react'


const ServerSearch = ({ title = 'Поиск', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-'+variant : ''} ${size ? 'spp-svg-size-'+size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg fill="#000000"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.667 341.667" >
<g>
	<path d="M38.5,117.545h216c21.229,0,38.5-17.271,38.5-38.5c0-21.229-17.271-38.5-38.5-38.5h-216c-21.228,0-38.5,17.271-38.5,38.5
		C0,100.274,17.272,117.545,38.5,117.545z M237.5,66.295c7.03,0,12.75,5.72,12.75,12.75c0,7.03-5.72,12.75-12.75,12.75
		c-7.03,0-12.75-5.72-12.75-12.75C224.75,72.015,230.47,66.295,237.5,66.295z M46.5,69.045h35c5.514,0,10,4.485,10,10
		c0,5.514-4.486,10-10,10h-35c-5.514,0-10-4.486-10-10C36.5,73.531,40.986,69.045,46.5,69.045z"/>
    <path d="M38.5,207.545h143.578c0.702-36.744,30.79-66.424,67.699-66.424c15.111,0,29.08,4.977,40.359,13.375
		c-5.751-14.036-19.555-23.951-35.637-23.951h-216c-21.228,0-38.5,17.27-38.5,38.5C0,190.274,17.272,207.545,38.5,207.545z
		 M46.5,159.045h35c5.514,0,10,4.485,10,10c0,5.514-4.486,10-10,10h-35c-5.514,0-10-4.486-10-10
		C36.5,163.531,40.986,159.045,46.5,159.045z"/>
    <path d="M278.267,270.279c-8.982,4.185-18.567,6.307-28.489,6.307c-33.359,0-61.144-24.248-66.702-56.04H38.5
		c-21.228,0-38.5,17.27-38.5,38.5c0,21.229,17.272,38.5,38.5,38.5h216c15.037,0,28.08-8.671,34.414-21.27l-7.474-7.474
		L278.267,270.279z M81.5,269.045h-35c-5.514,0-10-4.486-10-10c0-5.515,4.486-10,10-10h35c5.514,0,10,4.485,10,10
		C91.5,264.559,87.014,269.045,81.5,269.045z"/>
    <path d="M338.694,284.007l-45.862-45.299c5.857-8.495,9.297-18.779,9.297-29.855c0-29.076-23.655-52.731-52.73-52.731
		c-29.077,0-52.732,23.655-52.732,52.731c0,29.077,23.656,52.732,52.732,52.732c10.785,0,20.821-3.261,29.184-8.84l46.057,45.491
		c1.949,1.925,4.488,2.885,7.027,2.885c2.58,0,5.159-0.992,7.115-2.973C342.663,294.22,342.624,287.888,338.694,284.007z
		 M213.398,208.853c0-19.851,16.15-36,36.001-36c19.85,0,35.999,16.149,35.999,36c0,19.852-16.149,36.001-35.999,36.001
		C229.548,244.854,213.398,228.705,213.398,208.853z"/>
</g>
</svg>

        </i>
    )
}

export default ServerSearch
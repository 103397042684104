import { takeEvery, call, put, select, takeLeading } from 'redux-saga/effects'
import { LOAD_SUBUSERS, loading, putSubusers } from '../../store/Roles/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeLeading(LOAD_SUBUSERS, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        const subusers = yield call(fetchSubusers)
        

        let currentUser = yield select(state => state.login.user);
        let note = yield select(state => state.login.note);

        if (subusers.error && subusers.error.length) {
            localStorage.removeItem('master');
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_SUBUSERS}.`, subusers],
                text: "Пользователи не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putSubusers([]))
        } else {
            const subUsers = Object.values(subusers.success);
            if (subUsers.length) {
                const master = {
                    token: localStorage.sessionToken,
                    id: currentUser.id,
                    name: currentUser.name,
                    subUsers: Object.values(subusers.success),
                    note: note && note.length ? note : null
                }
                localStorage.setItem('master', JSON.stringify(master));
                yield put(putSubusers({ subusers: Object.values(subusers.success), master }))
            } else {
                const master = JSON.parse(localStorage.getItem('master'))
                if (master && master.id && master.subUsers) {
                    yield put(putSubusers({ subusers: master.subUsers, master }))
                }
            }


        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_SUBUSERS}.`, e],
            text: "Пользователи не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putSubusers([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchSubusers() {
    return axios.get("/account/subowner/select")
        .then(response => response.data)
}


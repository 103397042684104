import React from 'react'
import PropTypes from 'prop-types'

import {cl} from '../../utilities/cl'


const ElementsGroup = ({
    className = "",
    position = "left",
    offset = "sm1",
    children
}) => {

    className = cl(
        className,
        {"ElementsGroup": [
            position,
            offset
        ]}
    )

    return (
        <div className={className}>
            {children}
        </div>
    )
}

ElementsGroup.propTypes = {
    position: PropTypes.oneOf(["center", "left", "right"]),
    offset: PropTypes.string,
    className: PropTypes.string,
}

export default ElementsGroup
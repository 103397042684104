export default function({
    settings = {},
    name,
    onChange,
}) {

    const hChange = (checked) => {
        onChange({ value: checked })
    }

    const checked = settings[name] ? settings[name].split(",") : []

    return [
        {
            type: "Select",
            data: {
                type: "minutesOfHour",
                container: false,
                placeholder: "ММ",
                checked,
                onChange: hChange,
            }
        }
    ]
}
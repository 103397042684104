export const LOAD_CLIPS   = "Settings/Clips/LOAD_CLIPS"
export const PUT_CLIPS    = "Settings/Clips/PUT_CLIPS"
export const PUT_CLIPS_ALL    = "Settings/Clips/PUT_CLIPS_ALL"
export const DELETE_CLIPS = "Settings/Clips/DELETE_CLIPS"
export const UPDATE_CLIP = "Settings/Clips/UPDATE_CLIP"

export const loadClips = ({ indentId, objectIds, required }) => ({
    type: LOAD_CLIPS,
    payload: {
        indentId,
        objectIds,
        required
    }
})

export const putClips = ({ stateKey, clips }) => ({
    type: PUT_CLIPS,
    payload: {
        stateKey,
        clips
    }
})

export const putClipsAll = ({ clips }) => ({
    type: PUT_CLIPS_ALL,
    payload: {
        clips
    }
})

export const deleteClips = ({ clipsDatas }) => ({
    type: DELETE_CLIPS,
    payload: {
        clipsDatas
    }
})

export const updateClip = ({ selectedScheduleArray, oldClipData, clipData, selectedIds, action, onSuccess }) => ({
    type: UPDATE_CLIP,
    payload: {
        selectedScheduleArray,
        oldClipData,
        clipData,
        action,
        selectedIds,
        onSuccess
    }
})
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { LOAD_USED_LIST, putUsedList, mode } from '../../../store/Settings/actions/components/BackgroundLists'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_USED_LIST, worker)
}

function* worker({ payload }) {
    const {
        stateKey,
        timelineId,
        required,
    } = payload

    try {

        if (typeof timelineId === "number") {

            const __listLoaded = yield select(state => state.settings.backgroundLists[stateKey]?.__listLoaded)

            if (__listLoaded !== true || required) {
                yield put(mode({
                    stateKey,
                    modeType: "__loading",
                    mode: true,
                }))

                const fetchedList = yield call(fetchList, timelineId)

                // Ошибка
                if (fetchedList.error) {
                    yield put(showAlertError({
                        errors: [`Ошибка сервера при ${LOAD_USED_LIST}.`, fetchedList],
                        text: "Расписание не было загружено. Попробуйте повторить."
                    }))

                    // Успех
                } else {
                    let usedOrder = {}

                    fetchedList.success.map((item, index) => {
                        usedOrder[item.id] = ++index
                    })

                    yield put(putUsedList({
                        stateKey,
                        list: fetchedList.success,
                        usedOrder,
                    }))
                }
            }
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_USED_LIST}.`, e],
            text: "Расписание не было загружено. Попробуйте повторить."
        }))
    }

    finally {
        yield put(mode({
            stateKey,
            modeType: "__loading",
            mode: false,
        }))
    }
}

function fetchList(playtimeline_id) {
    return axios.get("/msbox/play/list/select", { params: { playtimeline_id } })
        .then(response => response.data)
}
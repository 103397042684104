export const UPDATE        = "Modal/AdvertCreate/UPDATE"
export const LOADING       = "Modal/AdvertCreate/LOADING"
export const DELETING      = "Modal/AdvertCreate/DELETING"
export const CREATE_ADVERT = "Modal/AdvertCreate/CREATE_ADVERT"
export const DELETE_ADVERT = "Modal/AdvertCreate/DELETE_ADVERT"

export const update = () => ({
    type: UPDATE
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const deleting = mode => ({
    type: DELETING,
    payload: mode
})

export const createAdvert = advertData => ({
    type: CREATE_ADVERT,
    payload: advertData
})

export const deleteAdvert = ({ advertId }) => ({
    type: DELETE_ADVERT,
    payload: {
        advertId
    }
})
import React from 'react'
import {connect} from 'react-redux'

import {updateOptions, toggleSelectedItems} from '../../../../store/Settings/actions'
import {openModal, closeModal} from '../../../../store/Modal/actions'
import {loadItems, addItem, deleteItems} from '../../../../store/Settings/actions/sets/Object/Silent'
import {OBJECT_SILENT} from '../../../../store/Settings/sets'

import ObjectSilent from './ObjectSilent'


const ObjectSilentContainer = props => (
    <ObjectSilent
        className={props.className}
        stateKey={props.stateKey}
        store={props.store}
        selected={props.selected}

        loadItems={props.loadItems}
        addItem={props.addItem}
        deleteItems={props.deleteItems}
        updateOptions={props.updateOptions}
        toggleSelectedItems={props.toggleSelectedItems}
        openModal={props.openModal}
        closeModal={props.closeModal}
    />
)

const stateToProps = (state, props) => {
    const store    = state.settings.settings[OBJECT_SILENT]
    const data     = store[props.stateKey] || {}
    const selected = data.selected || []
    return {
        store,
        selected: [...selected]
    }
}

const actionsToProps = {
    loadItems,
    addItem,
    deleteItems,
    updateOptions,
    toggleSelectedItems,
    openModal,
    closeModal,
}

export default connect(stateToProps, actionsToProps)(ObjectSilentContainer)
import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap";

import PlaylistMusicMobile from '../../ContentMusicMobile'
import Footer from "../../ContentMusicMobile/Footer";
import PublicPlaylistsMobile from '../../ContentMusicMobile/PublicPlaylists'
import MoveUp from "../../SVG/MoveUp";
// import Button from '../ButtonWhite'

const ContentMobile = ({ }) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset + window.innerHeight >= document.body.clientHeight - 70 && window.pageYOffset > 700) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div>
            <PlaylistMusicMobile />
            <PublicPlaylistsMobile />
            { isVisible && (
                <div style={ { position: "fixed", bottom: 0, margin: "auto", height: "95px", display: "flex", width: "95%", flexDirection: "column", justifyContent: "center" } }>
                    <Button onClick={ scrollToTop } variant="light">
                        <h2><MoveUp></MoveUp>В начало</h2>

                    </Button>
                </div>
            ) }
            <Footer />
        </div>
    )
}


// const stateToProps = state => ({

// })


export default ContentMobile
import {
    LOAD_SYSTEM_REPORT, LOADING,
    PUT_SYSTEM_REPORT,
} from './actions'

const inititalState = {
    loading: false,
    system_report: [],
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {

        case LOAD_SYSTEM_REPORT:
            return {
                ...state,
            }
        case PUT_SYSTEM_REPORT:
            return {
                ...state,
                system_report: payload.system_report,
            }

        case LOADING:
            return {
                ...state,
                loading: payload
            }


    }
    return state
}

export default reducer
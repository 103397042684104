import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import Header from '../../components/header'
import Object from './Object'

import ScrollContainer from '../../../ScrollContainer'
import Spinner from '../../../Spinner'
import InputText from "../../../Form/InputText";
import ButtonCircle from "../../../ButtonCircle";
import SVGDownload from "../../../SVG/Download";
import {requestMediaplan} from "../../../../store/Modal/types/Mediaplan/actions";

const Mediaplan = ({
    loading,
    indentName,
    mediaplan,
    requestMediaplan,
    toggleOpened
}) => {

    const [trackPlaying, setTrackPlaying] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isWatching, setIsWatching] = useState(false)
    const [audio, setAudio] = useState(new Audio())
    const [lastClickedPlayId, setLastClickedPlayId] = useState(-1)
    const [email, setEmail] = useState('')

    useEffect(() => {
        if (lastClickedPlayId === trackPlaying) {
            audio.addEventListener("canplaythrough", audio.play)
        }
    }, [audio])

    const hPlay = ({ trackId, filename }) => {
        if (trackId === trackPlaying) {
            setLastClickedPlayId(trackId)
            audio.play()
            setIsPlaying(true)
            setIsWatching(true)
        } else {
            setLastClickedPlayId(trackId)
            audio.pause()
            setTrackPlaying(trackId)
            setIsPlaying(true)
            setIsWatching(true)
            setAudio(new Audio(filename))
        }
    }

    const hPause = () => {
        setIsPlaying(false)
        setIsWatching(false)
        audio.pause()
    }

    const hStop = () => {
        setIsPlaying(false)
        setIsWatching(false)
    }

    const hRewind = (totime) => {
        if (audio.currentTime > 0) {
            audio.currentTime = totime
        }
    }

    const hSend = () => {
        requestMediaplan({email})
    }

    return(
        <div className="ModalMediaplan">
            <Header
                style={{'marginBottom': 20}}
                title="Медиаплан по ролику:"
                toggleOpened={toggleOpened}
                addedTitleElement={(
                    <div className="ModalMediaplan__track-name">
                        {indentName}
                    </div>
                )}
            />
            <div className="mb-2">
                В случае длительной загрузки укажите электронный адрес e-mail, на который необходимо прислать Медиаплан.
            </div>
                <div className='ModalMediaplan__form'>
                    <InputText
                        //label="E-MAIL"
                        attrs={ {
                            type: 'email',
                            placeholder: 'email@email.ru',
                            name: "email",
                            value: email,
                        } }
                        onChange={ (obj) => setEmail(obj.value) }
                    />
                    <ButtonCircle
                        disabled={ email === "" }
                        onClick={ hSend }
                    >
                        <SVGDownload />
                    </ButtonCircle>
                </div>
            <ScrollContainer
                collapsed={false}
                scrollVariant="gray"
            >
                {loading
                    ? <Spinner className="pt-xs pb-xs"/>

                    : (mediaplan && mediaplan[0])
                        ? mediaplan.map(object => {
                            return(
                                <Object
                                    key={object.id}
                                    type="Объект"
                                    title={object.name}
                                    rows={object.rows}
                                    audio={ audio }
                                    trackPlaying={ trackPlaying }
                                    isPlaying={ isPlaying }
                                    isWatching={ isWatching }
                                    play={ hPlay }
                                    pause={ hPause }
                                    stop={ hStop }
                                    rewind={ hRewind }
                                />
                            )
                        })

                        : <div className="ModalMediaplan__empty">
                            Записей пока нет.
                        </div>
                }
            </ScrollContainer>
        </div>
    )
}

const stateToProps = state => ({
    loading: state.modalMediaplan.loading,
    indentName: state.modalMediaplan.indentName,
    mediaplan: state.modalMediaplan.mediaplan,
})

const actionsToProps = {
    requestMediaplan
}

export default connect(stateToProps, actionsToProps)(Mediaplan)
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../utilities/cl'
import { isMobile } from '../../utilities/media'
import { getNextSelected } from '../../utilities/getNextSelected'

import ScrollContainer from '../ScrollContainer'
import Left from './Left/Left'
import Right from './Right/Right'
import { toggleSelectedObjectState } from '../../store/Reports/actions'


const Objects = ({
    className = "",
    types = [],

    allObjects,
    trackObjects: trackObjectsInner,
    trackObjects,

    selectedObjects,
    selectedTracks,

    loading,
    searchActive,
    searchFounded,

    loadObjects,
    toggleSelectedObject,
    toggleSelectedObjectState
}) => {

    const [doubleView, setDoubleView] = useState(false)

    // const trackObjects = [...trackObjectsInner].sort((a, b) => {
    //     const nameA = a.fullName.toUpperCase().replace(a.id, '');
    //     const nameB = b.fullName.toUpperCase().replace(b.id, '');

    //     if (nameA < nameB) {
    //         return -1;
    //     }
    //     if (nameA > nameB) {
    //         return 1;
    //     }
    //     return 0;
    // })

    useEffect(() => {
        loadObjects()
    }, [])

    className = cl(
        className,
        "Objects",
        { "double": doubleView }
    )

    const hToggleSelected = (e, isRight) => {
        const object = e.target.closest(".Objects__item")

        if (object) {
            const objectId = Number(object.dataset.objectId)

            if (!isNaN(objectId)) {
                const selected = getNextSelected({
                    multiple: true,
                    items: isRight ? trackObjects : allObjects,
                    itemId: objectId,
                    selected: selectedObjects,
                    ctrlKey: isMobile() || e.ctrlKey || e.metaKey,
                    shiftKey: e.shiftKey,
                })

                toggleSelectedObject({
                    selected,
                    simple: true,
                })
                toggleSelectedObjectState({
                    selected,
                    simple: true
                })
            }
        }
    }

    const isSimple = (Array.isArray(types) && types?.indexOf("simple") !== -1)



    return (
        <section className={className} onClick={() => setDoubleView(!doubleView)}>

            {!isSimple
                && <div className="Objects__header">
                    <div className="Objects__title">Объекты {selectedObjects.length > 1 ? <span>{selectedObjects.length}/{allObjects.length}</span> : ''}</div>
                    <div className="Objects__title">Ролик транслируется на {trackObjects.length} объектах</div>
                </div>
            }

            <ScrollContainer
                className="Objects__container-main"
                size="lg"
                collapsed={false}
            >
                <div className="Objects__places">
                    <Left
                        objects={allObjects}
                        loading={loading}
                        searchActive={searchActive}
                        searchFounded={searchFounded}
                        toggleSelected={hToggleSelected}
                    />
                    {!isSimple && (
                        <Right
                            objects={trackObjects}
                            selectedTracks={selectedTracks}
                            toggleSelected={(e) => hToggleSelected(e, true)}
                        />
                    )}
                </div>
            </ScrollContainer>

        </section>
    )
}

Objects.propTypes = {
    types: PropTypes.arrayOf((value, key, componentName, propName) => {
        const values = ["simple"]
        if (values.indexOf(value[key]) === -1)
            return new Error(`Проп ${propName} компонента ${componentName} имеет неправильное значение`)
    })
}

export default Objects
import React from 'react'


const FilterCircle = ({ title = 'Фильтр', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>{ title }</title>
                <g strokeWidth="1.188"><path d="M1 0l9 15.094v5.906l4 3v-8.906l9-15.094h-22zm18.479 2l-2.981 5h-8.996l-2.981-5h14.958z" /><circle cx="9.5" cy="9.5" r="9.5" stroke="none" transform="scale(.2228)" data-name="Ellipse 719" fill="none" /></g></svg>
        </i>
    )
}

export default FilterCircle
import moment from 'moment'
import {getNumbersBetween} from './getNumbersBetween'

export function createYearsList({ plus, minus, year, selected, disabledType }) {
    let currentYear = Number(moment().format('YYYY'))
    year = Number(year)
    selected = selected || year

    let years = [],
        yearsMinus = [],
        yearsPlus = [],
        yearsList = [],
        i, current

    years = getNumbersBetween((year-1), (currentYear+1))
    
    if(!years.length) years = [currentYear]

    if(years.indexOf(currentYear) === -1) years.push(currentYear)
    years.sort()

    if(disabledType !== "past") {
        i = minus
        while(i > 0){
            current = years[0] - (i--)
            yearsMinus.push(current)
        }
    }

    if(disabledType !== "future") {
        i = 1
        while(i <= plus){
            current = years[years.length-1] + (i++)
            yearsPlus.push(current)
        }
    }

    years = [].concat(yearsMinus, years, yearsPlus)

    years.map(year => {
        yearsList.push({
            title: year,
            value: year,
            selected: selected === year
        })    
    })
    
    return yearsList
}
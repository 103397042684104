import React from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../../utilities/cl'

import Place from '../../../../components/SpaceContent/Place'
import Empty from '../../../../components/Space/Empty'

import SlideMenu from '../../../../components/SlideMenu'
import ObjectsMenu from '../../../../components/ObjectsMenu'
import { MEDIA_BREAKPOINT_LG } from '../../../../utilities/breakpoints'
import ObjectsState from '../Where/Mobile/ObjectsState'
import ObjectReport from '../Where/Mobile/ObjectReport'

import Objects from './Objects'


class What extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            empty: false
        }

        this.setEmptyMode = this.setEmptyMode.bind(this)
    }

    componentDidUpdate() {
        const {
            searchActive,
            searchFounded,
            searchValue
        } = this.props

        if (searchActive) {
            (searchValue === "")
                ? this.setEmptyMode(false)
                : this.setEmptyMode(searchFounded.length === 0)
        } else {
            this.setEmptyMode(false)
        }
    }

    setEmptyMode(mode) {
        if (!mode) {
            this.setState(state => {
                if (state.empty) {
                    return { empty: false }
                }
            })
        } else {
            this.setState(state => {
                if (!state.empty) {
                    return { empty: true }
                }
            })
        }
    }

    render() {
        const {
            searchActive
        } = this.props
        const {
            empty
        } = this.state

        const hideContent = cl({ "d-none": empty })
        const placeHolder = cl({ "d-none": !empty })

        return (
            <>
                {/* 1 */ }

                <Place
                    number={ 1 }
                    className={ cl(
                        "ObjectWhat",
                        "sp-top",
                        { "search-active": searchActive }
                    ) }
                >

                    { window.outerWidth < MEDIA_BREAKPOINT_LG ?
                        this.props.selected.length === 0
                            ?
                            <ObjectsState className={ hideContent } placeNumber={ 1 } active />
                            :
                            <ObjectReport className={ hideContent } placeNumber={ 1 } active />

                        :
                        <SlideMenu
                            activeItem={ 1 }
                            className={ hideContent }
                            items={ [
                                {
                                    title: "Объекты",
                                    content: <Objects className={ hideContent } placeNumber={ 1 } active />
                                },
                            ] }
                        />


                    }

                    <Empty className={ placeHolder }>
                        Объектов с таким названием не найдено
                    </Empty>
                </Place>
            </>
        )
    }
}

const stateToProps = state => ({
    searchActive: state.search["objects"].modeActive,
    searchValue: state.search["objects"].value,
    searchFounded: [...state.search["objects"].founded],
    selected: state.objects.selected
})

export default connect(stateToProps, null)(What)
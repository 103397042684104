export const UPDATE        = "Modal/Mediaplan/UPDATE"
export const LOADING       = "Modal/Mediaplan/LOADING"
export const PUT_MEDIAPLAN = "Modal/Mediaplan/PUT_MEDIAPLAN"
export const REQUEST_MEDIAPLAN = "Modal/Mediaplan/REQUEST_MEDIAPLAN"
export const PUT_META      = "Modal/Mediaplan/PUT_META"

export const update = () => ({
    type: UPDATE
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const putMeta = ({ indentName }) => ({
    type: PUT_META,
    payload: {
        indentName,
    }
})

export const putMediaplan = mediaplan => ({
    type: PUT_MEDIAPLAN,
    payload: mediaplan
})

export const requestMediaplan = (payload) => ({
    type: REQUEST_MEDIAPLAN,
    payload
})

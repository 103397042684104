import {takeEvery, put} from 'redux-saga/effects'
import {UPDATE_OPTIONS} from '../../../store/Settings/actions'
import {putChanged} from '../../../store/Settings/actions/Changed'


export default function* () {
    yield takeEvery(UPDATE_OPTIONS, worker)
}

function* worker({ payload }) {
    const {
        type,
        stateKey,
        noChanged = false,
        settings = {}
    } = payload
    let fields = []
    
    if(noChanged === false) {
        for(let name in settings) {
            fields.push(name)
        }
    
        yield put(putChanged({
            type,
            stateKey,
            fields
        }))
    }
}
export function russifyDate(date, declinable = false) {
    try {
        if(typeof date !== "string") {
            return new Error("russifyDate(): дата не является строкой.")
        }
    
        const ru = {
            "January": "Январь",
            "February": "Февраль",
            "March": "Март",
            "April": "Апрель",
            "May": "Май",
            "June": "Июнь",
            "July": "Июль",
            "August": "Август",
            "September": "Сентябрь",
            "October": "Октябрь",
            "November": "Ноябрь",
            "December": "Декабрь",
        }
    
        const ruDecline = {
            "January": "Января",
            "February": "Февраля",
            "March": "Марта",
            "April": "Апреля",
            "May": "Мая",
            "June": "Июня",
            "July": "Июля",
            "August": "Августа",
            "September": "Сентября",
            "October": "Октября",
            "November": "Ноября",
            "December": "Декабря",
        }
    
        const dictionary = declinable ? ruDecline : ru
    
        Object.keys(dictionary).map(key =>
            date = date.replace(key, dictionary[key])
        )
    }
    catch(e) {
        console.log(e)
        console.log("russifyDate(date)", date)
    }
    finally {
        return date
    }
}
import React from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../utilities/cl'


const ButtonCircle = ({
    className = "",
    variant = "",
    size = "md",
    disabled,
    onClick,
    value,
    children
}) => {

    function hClick(value) {
        if (!disabled) {
            if (typeof onClick === "function") {
                onClick(value)
            }
        }
    }

    className = cl(
        className,
        {
            "ButtonCircle": [
                { [variant]: variant ? true : false },
                { [size]: size ? true : false },
            ]
        }
    )

    return (
        <button
            className={ className }
            disabled={ disabled }
            onClick={ () => hClick(value) }
        >
            { children }
        </button>
    )
}

ButtonCircle.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(["light", "none"]),
    size: PropTypes.oneOf(["md", "sm"]),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.any
}

export default ButtonCircle
import { takeEvery, select, put, call } from 'redux-saga/effects'
import { LOAD_AVAIL_LIST, putAvailList, mode } from '../../../store/Settings/actions/components/BackgroundLists'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_AVAIL_LIST, worker)
}

function* worker({ payload }) {
    const { required } = payload

    try {
        const __listLoaded = yield select(state => state.settings.backgroundLists?.avail?.__listLoaded)

        if (__listLoaded !== true || required) {
            yield put(mode({
                stateKey: "avail",
                modeType: "__loading",
                mode: true,
            }))

            const fetchedPlay = yield call(fetchPlay)
            const fetchedVideo = yield call(fetchVideo)

            // Ошибка
            if (fetchedPlay.error || fetchedVideo.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${LOAD_AVAIL_LIST}.`, fetchedPlay, fetchedVideo],
                    text: "Доступные списки не были загружены. Попробуйте повторить."
                }))

                // Успех
            } else {
                let playList = [],
                    videoList = [],
                    assocPlayList = {}

                videoList.push({ title: "Не выбрано", value: -1, id: -1 })

                fetchedPlay.success.map(item => {
                    playList.push(item)
                    assocPlayList[item.id] = item
                })
                fetchedVideo.success.map(item => {
                    videoList.push({
                        id: item.id,
                        title: item.name
                    })
                })

                yield put(putAvailList({
                    playList,
                    videoList,
                    assocPlayList,
                }))
            }
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_AVAIL_LIST}.`, e],
            text: "Доступные списки не были загружены. Попробуйте повторить."
        }))
    }

    finally {
        yield put(mode({
            stateKey: "avail",
            modeType: "__loading",
            mode: false,
        }))
    }
}

function fetchPlay() {
    return axios.get("/playlist/select?all=true")
        .then(response => response.data)
}

function fetchVideo() {
    return axios.get("/videolist/select")
        .then(response => response.data)
}
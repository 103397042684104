import {LOADING, PUT_REPORT} from './actions'

const initialState = {
    loading: false,
    objects: [],
    from: "",
    to: "",
    selectedObject: '',
    report: {
        inside: [],
        outside: [],
    }
}

const reducer = (state = initialState, { type, payload }) => {
    let nextState = {...state}
    switch(type) {

        case LOADING:
            return {
                ...state,
                loading: payload
            }

        case PUT_REPORT:
            const {objects, from, to, objectId, report} = payload
            nextState.to = to
            nextState.from = from
            nextState.selectedObject = objectId
            //if(objects && objects.length)
              //  nextState.objects = objects
            //else nextState.objects = []
            //if(!nextState.reports[`${objectId}/${from}-${to}`])
            //     nextState.reports[`${objectId}/${from}-${to}`] = report
                nextState.report = report
            return {
                ...nextState
            }
    }
    return state
}

export default reducer
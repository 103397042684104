import React, { useState, useEffect } from 'react'

import { MEDIA_BREAKPOINT_LG } from '../../utilities/breakpoints'
import { cl } from '../../utilities/cl'
import { getSortedObj } from '../../utilities/getSorted'

import List from '../List'
import MoreList from '../DraggableList/LoadMoreIndexNotDND'

import Button from '../Button'
import Track from './Track'

import SvgArrowUpDown from '../SVG/ArrowUpDown'
import SvgWarning from '../SVG/Warning'
import SvgArrowDownLight from "../SVG/ArrowDownLight";
import { getNextSelected } from "../../utilities/getNextSelected";
import { isMobile } from "../../utilities/media";


const Tracks = ({
    className = "",
    theme = false,
    tracks = [],
    searchFounded = [],
    showHideEndedButton = true,

    loading,
    searchActive,

    selected = [],
    toggleSelectedTrack,
    updateCurrentMark,
    loadTracks,
    toggleHideEnded: toggleHideEndedInner
}) => {

    const [hideEnded, toggleHideEnded] = useState(true)

    const [sortBy, setSortBy] = useState(null)
    const [sortOrder, setSortOrder] = useState(null)
    const sortTypes = {
        "id": "number",
        "name": "string",
    }

    const [trackPlaying, setTrackPlaying] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isWatching, setIsWatching] = useState(false)
    const [audio, setAudio] = useState(new Audio())
    const [lastClickedPlayId, setLastClickedPlayId] = useState(-1)

    useEffect(() => {
        if (typeof toggleHideEndedInner === 'function')
            toggleHideEndedInner(hideEnded)        
    }, [hideEnded])

    useEffect(() => {
        loadTracks({ theme })
    }, [])

    useEffect(() => {
        if (lastClickedPlayId === trackPlaying) {
            audio.addEventListener("canplaythrough", audio.play)
        }
    }, [audio])

    const hPlay = ({ trackId, filename }) => {
        if (trackId === trackPlaying) {
            setLastClickedPlayId(trackId)
            audio.play()
            setIsPlaying(true)
            setIsWatching(true)
        } else {
            setLastClickedPlayId(trackId)
            audio.pause()
            setTrackPlaying(trackId)
            setIsPlaying(true)
            setIsWatching(true)
            setAudio(new Audio(filename))
        }
    }

    const hPause = () => {
        setIsPlaying(false)
        setIsWatching(false)
        audio.pause()
    }

    const hStop = () => {
        setIsPlaying(false)
        setIsWatching(false)
    }

    const hRewind = (totime) => {
        if (audio.currentTime > 0) {
            audio.currentTime = totime
        }
    }

    const toggleSelected = ({ }, e) => {
        const trackTitle = e.target
        if (trackTitle) {
            const trackId = Number(trackTitle.dataset.trackId || trackTitle.parentNode.dataset.trackId)
            if (!isNaN(trackId)) {
                const newSelected = getNextSelected({
                    multiple: true,
                    items: tracks,
                    itemId: trackId,
                    selected: selected,
                    ctrlKey: isMobile() || e.ctrlKey || e.metaKey,
                    shiftKey: e.shiftKey,
                })
                //console.log(newSelected)
                toggleSelectedTrack({ ids: newSelected })
                // toggleSelectedTrack({
                //     id: trackId,
                //     onlyOne: !((e.ctrlKey || e.metaKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                // })
                //updateCurrentMark()
            }
        }
    }

    const loadMorePublicTraks = () => {

    }

    className = cl(
        className,
        "Tracks",
        { "hide-ended": hideEnded }
    )

    return (
        <section className={className}>

            <header className="Tracks__control">
                <div className="Tracks__grid">
                    <div className="id">
                        <Button type="string" onClick={() => toggleSort("id")} disabled={!(tracks[0])}>
                            <SvgArrowUpDown title="Сортировка по ID" />
                            ID
                        </Button>
                    </div>
                    <div className="play"></div>
                    <div className="name">
                        <Button type="string" onClick={() => toggleSort("name")} disabled={!(tracks[0])}>
                            <SvgArrowUpDown title="Сортировка по названию" />
                            Название
                        </Button>
                    </div>
                    {showHideEndedButton && <div className="last ml-auto">
                        <Button type="string" onClick={() => toggleHideEnded(!hideEnded)} disabled={!(tracks[0])}>
                            <SvgWarning
                                title={hideEnded ? "Показать завершённые" : "Скрыть завершённые"}
                            />
                            {hideEnded ? "Показать завершённые" : "Скрыть завершённые"}
                        </Button>
                    </div>}
                </div>
            </header>
            <MoreList
                type="simple"
                placeholder="Список роликов пуст."
                loading={loading}
                scroll={{
                    on: true,
                    data: {
                        variant: "white",
                        size: "lg",
                    }
                }}
                onClick={toggleSelected}
                content={{ items: getItems(), variant: "none" }}
                droppableId={`1`}
            />
        </section>
    )

    function getItems() {
        if (searchActive) tracks = searchFounded
        if (hideEnded) {
            tracks = tracks.filter(track => !track.clipEnded)
        }
        const sorted = getSortedObj(tracks, sortBy, sortTypes[sortBy], sortOrder)

        return sorted.map((track, index) => ({
            id: track.id,
            name: track.name,
            element: <Track
                key={track.id}
                variant={(index % 2 == 0 && !hideEnded) ? "gray" : ""}
                audio={audio}
                trackPlaying={trackPlaying}
                isPlaying={isPlaying}
                isWatching={isWatching}
                play={hPlay}
                pause={hPause}
                stop={hStop}
                rewind={hRewind}
                {...track}
            />
        }))
    }

    function toggleSort(by) {
        let order

        if (sortBy === by) {
            order = sortOrder === "ASC" ? "DESC" : "ASC"
        } else {
            order = "ASC"
        }

        setSortOrder(order)
        setSortBy(by)
    }
}

export default Tracks
export function compareAny({ prev, next }) {
    let similar = true

    if(typeof next === typeof prev) {

        // string || number || boolean || undefined
        if(typeof next === "string" || typeof next === "number" || typeof next === "boolean" || typeof next === "undefined") {
            if(next !== prev) {
                if(typeof next === "string") {
                    similar = false
                } else {
                    if(!isNaN(next) || !isNaN(prev)) {
                        similar = false
                    }
                }
            }

        } else if(typeof next === "object"){

            // null
            if(next === null) {
                if(next !== prev) {
                    similar = false
                }
            }

            // array
            else if(Array.isArray(next)) {
                if(!compareArrays({
                    prev,
                    next
                })) {
                    similar = false
                }
            }

            // object
            else {
                if(!compareObjects({
                    prev,
                    next,
                    strict: true,
                })) {
                    similar = false
                }
            }
        }

    } else {
        similar = false
    }

    return similar
}

export function compareObjects({ prev, next, strict=false, uses=[] }) {
    if(typeof prev === "object" && !Array.isArray(prev) && typeof next === "object" && !Array.isArray(next)) {
        let similar = true

        if(strict) {
            if(objectLength(next) !== objectLength(prev)) {
                similar = false
            }
        }

        if(!similar) return false

        // Сравнение только по зависимостям
        if(uses.length) {
            uses.map(key => {
                if(similar) {
                    if(!compareAny({
                        prev: prev[key],
                        next: next[key],
                    })) {
                        similar = false
                    }
                } else {
                    similar = false
                }
            })
        }

        // Сравнение по значениям объекта next
        else {
            for(let item in next) {
                if(similar) {
                    if(!compareAny({
                        prev: prev[item],
                        next: next[item],
                    })) {
                        similar = false
                    }
                } else {
                    similar = false
                }
            }
        }

        return similar

    } else {
        return false
    }
}

export function compareArrays({ prev, next }) {
    if(!Array.isArray(next) || !Array.isArray(prev)) {
        return false
    }

    if(next.length !== prev.length) {
        return false
    }

    const hasObjects = next.filter(item => (typeof item === "object" && item !== null))

    // Сравнение по индексам
    if(hasObjects.length) {
        let similar = true

        next.map((itemNext, index) => {
            if(similar) {
                if(!compareAny({
                    prev: prev[index],
                    next: itemNext,
                })) {
                    similar = false
                }
            }
        })

        return similar
    }

    // Сравнение по значениям
    else {
        let hashNext = {},
            hashPrev = {}

        next.map(item => {
            if(!hashNext[item]) hashNext[item] = 0
            hashNext[item]++
        })
        prev.map(item => {
            if(!hashPrev[item]) hashPrev[item] = 0
            hashPrev[item]++
        })

        let unique = 0

        for(let key in hashNext) {
            if(!hashPrev[key] || hashNext[key] !== hashPrev[key]) {
                unique++
            }
        }

        return (unique === 0)
    }
}

function objectLength(obj) {
    let length = 0

    if(typeof obj === "object" && !Array.isArray(obj)) {
        for(let i in obj) {
            length++
        }
    }
    return length
}
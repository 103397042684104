export function generateStateKey(args = []) {
    let keys = []
    args.map(arg => {
        if(Array.isArray(arg)) {
            arg.sort()
            keys.push(arg.join(","))
        } else if(typeof arg === "string") {
            keys.push(arg)
        } else if(typeof arg === "number") {
            keys.push(arg)
        }
    })
    return keys.join("-")
}
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { changeExpandedPlace, changeActivePlace } from '../../../store/Panel/actions'

import Place from './Place'


const PlaceContainer = props => (
    <Place
        className={ props.className }
        title={ props.title }
        number={ props.number }
        elements={ props.elements }
        disabled={ props.disabled }

        expandedPlace={ props.expandedPlace }
        activePlace={ props.activePlace }
        changeExpandedPlace={ props.changeExpandedPlace }
        changeActivePlace={ props.changeActivePlace }
        style={ props.style }
    />
)

const stateToProps = state => ({
    expandedPlace: state.panel.expandedPlace,
    activePlace: state.panel.activePlace,
})

const actionsToProps = {
    changeExpandedPlace, changeActivePlace
}

export default connect(stateToProps, actionsToProps)(PlaceContainer)
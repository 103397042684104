import React from 'react'


const File3 = ({ title = '', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.05 4.234">
                <title>{ title }</title>
                <g data-name="Group 2655" strokeWidth="1.097"><path d="M.424 0A.43.43 0 000 .426v3.382a.43.43 0 00.424.426h2.201a.43.43 0 00.425-.426V1.165l-.097-.097L2.76.874 1.986.098A.132.132 0 001.98.092a.132.132 0 00-.004-.003L1.888 0H.425zm.001.265H1.76v.602c0 .234.192.426.425.427h.6v2.513a.16.16 0 01-.16.162h-2.2a.16.16 0 01-.161-.162V.427c0-.091.07-.161.161-.162zm1.599.247l.517.517h-.355a.16.16 0 01-.162-.162zm-.557 1.146c-.165 0-.292.035-.38.103a.35.35 0 00-.144.257.034.034 0 00.01.029c.009.007.02.011.03.011h.182a.058.058 0 00.032-.007.074.074 0 00.02-.03.15.15 0 01.079-.102.34.34 0 01.167-.037c.067 0 .122.017.164.049.042.03.064.072.064.123 0 .06-.02.104-.06.134a.276.276 0 01-.162.042h-.135a.042.042 0 00-.033.016.047.047 0 00-.013.034v.114c0 .013.004.025.013.035.01.009.02.013.033.013h.141a.31.31 0 01.189.051c.045.032.067.08.067.141 0 .06-.026.106-.077.14a.343.343 0 01-.196.053.345.345 0 01-.17-.037.188.188 0 01-.087-.1.05.05 0 00-.023-.029.058.058 0 00-.035-.01H.968a.044.044 0 00-.029.01.03.03 0 00-.012.025v.004c.007.064.03.124.07.178a.43.43 0 00.179.131c.078.034.174.05.287.05.11 0 .207-.016.29-.05a.448.448 0 00.19-.143.351.351 0 00.068-.212.372.372 0 00-.049-.188.336.336 0 00-.148-.137.267.267 0 00.117-.122.38.38 0 00.037-.164.316.316 0 00-.06-.185.4.4 0 00-.173-.137.637.637 0 00-.268-.053z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#fff" stroke="none" data-name="Group 2008" strokeMiterlimit="10" /></g></svg>
        </i>
    )
}

export default File3
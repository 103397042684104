import {isObj} from './isObj'
import {isArr} from './isArr'


export function isArrEmpty(arr) {
    if(isObj(arr)) {
        return Object.keys(arr).length === 0
    } else if(isArr(arr)) {
        return arr.length === 0
    }

    return true
}
const deleteItem = ({
    stateKey,
    itemId,
    deleteItems,
    openModal,
    closeModal,
}) => {

    openModal({
        type: "confirm",
        size: "sm",
        data: {
            title: `Вы точно хотите удалить данное расписание?`,
            buttons: {
                cancel: {
                    title: "Вернуться",
                    onClick: () => {
                        closeModal("confirm")
                    }
                },
                confirm: {
                    title: "Удалить",
                    onClick: () => {
                        deleteItems({
                            stateKey,
                            itemIds: [itemId]
                        })
                        closeModal("confirm")
                    }
                }
            }
        }
    })
}

export default deleteItem
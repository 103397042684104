import React from 'react'
import { Link } from 'react-router-dom'

import { SETTINGS_TEMPLATES, SETTINGS_NOTIFICATIONS, SETTINGS_ROLES, SETTINGS_FEATURES_OF_THE_ETHER } from '../../routes'


const Menu = () => (
    <section className="Settings__Menu">
        <p className='title spp-font-text-lg spp-c-dark spp-o-xl'>Выберите один из разделов:</p>
        <Link to={ SETTINGS_TEMPLATES } className='link'>Шаблоны</Link>
        <Link to={ SETTINGS_NOTIFICATIONS } className='link'>Уведомления</Link>
        <Link to={ SETTINGS_ROLES } className='link'>Интерфейс</Link>
        <Link to={ SETTINGS_FEATURES_OF_THE_ETHER } className='link'>Особенности эфира</Link>
        

    </section>
)

export default Menu
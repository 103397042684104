import {formatMinutesToTime} from '../../../utilities/formatTime'


export default function({
    settings = {},
    name,
    onChange,
    disabled,
}) {

    let steptime = formatMinutesToTime(settings[name]) || ""

    return [
        {
            type: "CycleTime",
            data: {
                value: steptime,
                onChange,
                disabled,
            }
        }
    ]
}
import {
    LOAD_MUSIC_REPORT, LOADING,
    PUT_MUSIC_REPORT,
    SORT_MUSIC_REPORT
} from './actions'
import {durationFromCliptime} from "../../../utilities/duration";

const inititalState = {
    loading: false,
    music_report: [],
    music_duration: ''
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {

        case SORT_MUSIC_REPORT:
            if (payload.sort_type === "timestamp") {
                return {
                    ...state,
                    music_report:
                        Array.from(state.music_report).sort((a, b) => {
                            if (a.timestamp < b.timestamp) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.timestamp > b.timestamp) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }

            } else if (payload.sort_type === "name") {
                return {
                    ...state,
                    music_report:
                        Array.from(state.music_report).sort((a, b) => {
                            if (a.name < b.name) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.name > b.name) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "list") {
                return {
                    ...state,
                    music_report:
                        Array.from(state.music_report).sort((a, b) => {
                            if (a.playlistname < b.playlistname) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.playlistname > b.playlistname) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "sync_start_time") {
                return {
                    ...state,
                    music_report:
                        Array.from(state.music_report).sort((a, b) => {
                            if (a.startsync < b.startsync) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.startsync > b.startsync) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "ip") {
                return {
                    ...state,
                    music_report:
                        Array.from(state.music_report).sort((a, b) => {
                            if (a.lip < b.lip) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.lip > b.lip) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "error") {
                return {
                    ...state,
                    music_report:
                        Array.from(state.music_report).sort((a, b) => {
                            if (a.lasterror < b.lasterror) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.lasterror > b.lasterror) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            }
        case LOAD_MUSIC_REPORT:
            return {
                ...state,
            }
        case PUT_MUSIC_REPORT:
            return {
                ...state,
                music_report: payload.music_report,
                music_duration: durationFromCliptime(payload.music_report)
            }

        case LOADING:
            return {
                ...state,
                loading: payload
            }


    }
    return state
}

export default reducer
export default function calcNewElementPosition(
    relativeElem,
    elem,
    position,
    offsets = {
        top: 0,
        left: 0,
        right: 0
    }
) {
    if(position !== "center" || !relativeElem || !elem){
        console.log("setElementPosition(): Не верный элемент или позиция НЕ `center`")
        console.log(relativeElem, elem, position)
        return false
    }

    let elems = {
        relative: {
            top: relativeElem.getBoundingClientRect().top + window.pageYOffset,
            left: relativeElem.getBoundingClientRect().left + window.pageXOffset,
            width: relativeElem.offsetWidth,
        },
        elem: {
            width: elem.offsetWidth || 0,
            height: elem.offsetHeight || 0,
        }
    }

    let answer = {
        top: 0,
        left: 0
    }

    if(position === "center"){
        answer.top = elems.relative.top
        answer.left = elems.relative.left - (elems.elem.width/2) + (elems.relative.width/2)

        if(answer.left + elems.elem.width > window.innerWidth)
            answer.left = window.innerWidth - 20 - elems.elem.width

        // Корректируем высоту
        let finnalyTopPoint = answer.top - window.pageYOffset + offsets.top
        if(finnalyTopPoint + elems.elem.height > window.innerHeight)
            answer.top = answer.top - ((finnalyTopPoint + elems.elem.height) - window.innerHeight + 20)

        if(answer.left < 0)
            answer.left = 20
    }

    // Добавляем отступы

    if(typeof offsets === "object"){
        answer = {
            top: answer.top + (offsets.top || 0),
            left: answer.left + (offsets.left || 0) - (offsets.right || 0)
        }
    }

    return answer
}
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Button as ButtonBS} from 'react-bootstrap'

import {saveTrackForItem} from '../../../../../../../store/Settings/actions/sets/Object/Schedule'

import {OBJECT_SCHEDULE} from '../../../../../../../store/Settings/sets'

import ChangeTrack from '../../../components/ChangeTrack'


const ModalContent = ({
    stateKey,
    side,
    position,

    savingTrackForItem,

    saveTrackForItem,
}) => {

    const [selected, setSelected] = useState()

    const hChange = ({ value }) => setSelected(value)
 
    const hSaveTrackForItem = () => saveTrackForItem({
        stateKey,
        trackId: selected,
        side,
        position,
    })

    return(
        <>
            <ChangeTrack
                label="Выбрать из списка"
                stateKey={stateKey}
                side={side}
                position={position}

                onChange={hChange}
            />
            
            <div className="Modal__footer">
                <ButtonBS variant="prim" onClick={hSaveTrackForItem} disabled={savingTrackForItem}>
                    {savingTrackForItem ? "Сохранение..." : "Сохранить"}
                </ButtonBS>
            </div>
        </>
    )
}

const stateToProps = (state, props) => ({
    savingTrackForItem: state.settings.settings[OBJECT_SCHEDULE][props.stateKey]?.__savingTrackForItem
})

const actionsToProps = {
    saveTrackForItem
}

const ModalContentContainer = connect(stateToProps, actionsToProps)(ModalContent)


const changeTrack = ({
    stateKey,
    side,
    position,
    
    openModal,
}) => {

    openModal({
        type: "element",
        size: "sm",
        data: {
            title: "Выбор объявления",
            element:
                <ModalContentContainer
                    stateKey={stateKey}
                    side={side}
                    position={position}
                />
        }
    })
}

export default changeTrack
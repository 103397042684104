import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Place from '../../../../components/SpaceContent/Place'
import Empty from '../../../../components/SpaceContent/Empty'
import ObjectsState from './ObjectsState'
import ObjectReport from './ObjectReport'
import SlideMenu from '../../../../components/SlideMenu'
import List from '../../../../components/List'
import { unstable_renderSubtreeIntoContainer } from 'react-dom'


const Where = ({
    loading,
    selected,
    selected_state
}) => {

    return (
        <>
            <Place
                number={ 2 }
                className="ObjectsWhere"
            // style={ { width: "67%" } }
            >

                { selected.length === 0 && selected_state.length === 0 ?
                    <><div style={ { display: "flex", flexDirection: "row", flexWrap: "nowrap" } }>
                        <div>
                            <h2>СОСТОЯНИЕ ОБЪЕКТОВ</h2>
                        </div>
                    </div>
                        <SlideMenu
                            activeItem={ 1 }
                            items={ [
                                {
                                    title: "Настройки Плейлиста",
                                    content: <ObjectsState key={ 6 } placeNumber={ 1 } />
                                }
                            ] }
                        />
                    </>
                    :
                    <SlideMenu
                        activeItem={ 1 }
                        items={ [
                            {
                                title: "Настройки Плейлиста",
                                content: <ObjectReport key={ 6 } placeNumber={ 1 } />
                            }
                        ] }
                    />
                }
            </Place>

        </>)
}

const stateToProps = state => ({
    loading: state.report.loading,
    selected: state.objects.selected,
    selected_state: state.report.selected
})
const actionsToProps = {

}
export default connect(stateToProps, actionsToProps)(Where)
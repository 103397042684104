export const OPEN_MODAL = "Modal/OPEN_MODAL"
export const CLOSE_MODAL = "Modal/CLOSE_MODAL"

export const openModal = ({ type, size, zIndex, data }) => ({
    type: OPEN_MODAL,
    payload: {
        type,
        size,
        zIndex,
        data,
    }
})

export const closeModal = type => ({
    type: CLOSE_MODAL,
    payload: {
        type
    }
})
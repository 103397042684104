import React, { useState, useEffect } from 'react'
import Spinner from '../../../../../../../components/Spinner'
import { connect } from 'react-redux'
import { loadMusicReport, sortMusicReport } from '../../../../../../../store/Reports/Music/actions'
import MusicReportItem from '../Music/item'
import List from '../../../../../../../components/List'
import SvgArrowUpDown from '../../../../../../../components/SVG/ArrowUpDown'

const ReportMusic = ({
    loadMusicReport,
    objects,
    selecteo,
    music_report,
    loading,
    sortMusicReport,
    date,
    selected_state
}) => {
    let selected
    if (selecteo.length > 0) {
        selected = selecteo
    } else {
        selected = selected_state
    }
    useEffect(() => {
        loadMusicReport({ id: selected[0], date: date })
    }, [])
    const [sort_direction, setSortDirection] = useState("")
    const [trackNotId, setTrackNotId] = useState('')
    const [trackPlaying, setTrackPlaying] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isWatching, setIsWatching] = useState(false)
    const [audio, setAudio] = useState(new Audio())
    const [lastClickedPlayId, setLastClickedPlayId] = useState(-1)
    const [lastClickedPlayNotId, setLastClickedPlayNotId] = useState(-1)


    useEffect(() => {
        if (lastClickedPlayId === trackPlaying && lastClickedPlayNotId === trackNotId) {
            audio.addEventListener("canplaythrough", audio.play)
        }
    }, [audio])

    const hPlay = ({ trackId, filename, trackNot }) => {
        if (trackId === trackPlaying && trackNotId === trackNot) {
            setLastClickedPlayId(trackId)
            setLastClickedPlayNotId(trackNot)
            audio.play()
            setIsPlaying(true)
            setIsWatching(true)
            setTrackNotId(trackNot)
        } else {
            setLastClickedPlayId(trackId)
            setLastClickedPlayNotId(trackNot)
            audio.pause()
            setTrackPlaying(trackId)
            setTrackNotId(trackNot)
            setIsPlaying(true)
            setIsWatching(true)
            setAudio(new Audio(filename))
        }
    }

    const hPause = () => {
        setIsPlaying(false)
        setIsWatching(false)
        audio.pause()
    }
    const listener = function (e) {
        if (e.target.classList.contains('Object-report_menu-element') || e.target.classList.contains('only_trigger')) {
            audio.pause()
        }
    }

    document.addEventListener('click', listener, false)
    const hStop = () => {
        setIsPlaying(false)
        setIsWatching(false)
    }
    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortMusicReport({ direction: sort_direction, sort_type: type })
        hPause()
        setLastClickedPlayId(-1)
        setLastClickedPlayNotId(-1)
        setTrackNotId('')
        setTrackPlaying(0)
    }
    const hRewind = (totime) => {
        if (audio.currentTime > 0) {
            audio.currentTime = totime
        }
    }
    const getItems = () => {
        return music_report.map((music_report, index) => ({
            id: index,
            name: music_report.name,
            element: <MusicReportItem
                key={ index }
                id={ index }
                name={ music_report.name }
                timestamp={ music_report.timestamp }
                playlistname={ music_report.playlistname }
                song_author={ music_report.song_author }
                song_id={ music_report.song_id }
                variant={ (index % 2 == 0) ? "gray" : "" }

                audio={ audio }
                trackPlaying={ trackPlaying }
                trackNotId={ trackNotId }
                isPlaying={ isPlaying }
                isWatching={ isWatching }
                play={ hPlay }
                pause={ hPause }
                stop={ hStop }
                rewind={ hRewind }
            />
        }))
    }
    return (
        <div>
            { !loading ?
                <div style={ { marginTop: "40px" } }>
                    <div className="MusicsReport">
                        <header className="MusicsReport__control">
                            <div className="MusicsReport__grid">
                                <div className="timestamp" >
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("timestamp") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ВРЕМЯ НАЧАЛА
                                    </button>
                                </div>
                                <div className="list">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("list") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        СПИСОК
                                    </button>
                                </div>
                                <div className="song">
                                    <button
                                        className="spp-btn spp-btn_string"
                                        onClick={ () => sort("name") }
                                    >
                                        <SvgArrowUpDown className="spp-svg" />
                                        ПРОИЗВЕДЕНИЕ
                                    </button>
                                </div>
                            </div>
                        </header>
                    </div>
                    <List
                        type="simple"
                        scroll={ {
                            on: true,
                            data: {
                                variant: "white",
                                size: "sm",
                            }
                        } }
                        // variant={ "none" }
                        content={ { items: getItems(), variant: "none" } }
                    />
                </div>
                : <Spinner />
            }

        </div>
    )
}

const stateToProps = state => ({
    objects: state.objects.allObjects,
    selecteo: state.objects.selected,
    music_report: state.music_report.music_report,
    loading: state.music_report.loading,
    selected_state: state.report.selected
})

const actionsToProps = {
    loadMusicReport,
    sortMusicReport
}

export default connect(stateToProps, actionsToProps)(ReportMusic)
export default function({
    settings = {},
    name,
    onChange,
}) {

    return [
        {
            type: "CycleTime",
            data: {
                value: settings[name],
                onChange,
            }
        }
    ]
}
import { takeEvery, put, call } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { CREATE_PRESET, loadPresets } from '../../../store/Settings/actions/components/Presets'
import { showAlertSuccess, showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(CREATE_PRESET, worker)
}

function* worker({ payload }) {
    try {
        const { type, name, description, data } = payload
        const created = yield call(fetchCreate, type, name, description, data)

        if (created.error && created.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CREATE_PRESET}.`, created],
                text: "Пресет не был добавлен. Попробуйте повторить."
            }))
        } else {
            yield put(showAlertSuccess({
                responses: [`Успешное выполнение ${CREATE_PRESET}.`, created],
                text: "Пресет успешно создан."
            }))
            yield put(loadPresets())
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CREATE_PRESET}.`, e],
            text: "Пресет не был добавлен. Попробуйте повторить."
        }))
    }
}

function fetchCreate(type, name, description, data) {
    delete data.id
    delete data.fromdate
    delete data.todate
    delete data.ms_id
    delete data.indent_id
    delete data.__loaded
    delete data.__saved

    data.__settingsType = type

    let formData = new FormData()
    formData.set("name", name)
    formData.set("description", description)
    formData.set("data", JSON.stringify(data))

    return axios(
        {
            url: "/preset/insert",
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { cl } from '../../../../../../../utilities/cl'
import SvgPlay from '../../../../../../../components/SVG/Play'
import SvgPause from '../../../../../../../components/SVG/Pause'
import { formatSecondsToTime } from '../../../../../../../utilities/format'
import {buildGETUrl} from "../../../../../../../utilities/buildGETUrl";


const MusicReportItem = ({
    id,
    timestamp,
    playlistname,
    name,
    channel,
    song_id,
    song_author,
    actrao_string,
    markItems,
    variant,
    audio,
    trackPlaying,
    isPlaying,
    isWatching,
    play,
    pause,
    stop,
    rewind,
    menu,
    trackNotId
}) => {
    let [watcherInterval, setWatcherInterval] = useState(null)
    const [currentTime, setCurrentTime] = useState("00:00")
    const [slider, setSlider] = useState("100%")
    const prevPlaying = usePrevious(trackPlaying)
    const prevPlayingNot = usePrevious(trackNotId)
    const prevWatching = usePrevious(isWatching)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {
        if (trackPlaying !== prevPlaying || isWatching !== prevWatching || trackNotId !== prevPlayingNot) {
            if (isWatching) {
                setWatcherInterval(clearInterval(watcherInterval))
                startWatch()
            } else {
                killWatcher()
            }

        }
    }, [trackPlaying, trackNotId])


    function startWatch() {

        if (trackPlaying === song_id && trackNotId === id) {
            setWatcherInterval(setInterval(() => {
                if (audio.currentTime === audio.duration) {
                    stop()
                } else {
                    updateTime()
                }
            }, 50))
        } else {
            killWatcher(true)
        }
    }

    function killWatcher(resetTime) {
        clearInterval(watcherInterval)
        updateTime(resetTime)
    }


    const updateTime = (resetTime) => {
        let currentTime = "00:00"
        let slider = "100%"

        if (resetTime !== true && (audio.currentTime !== audio.duration && trackPlaying === song_id && trackNotId === id)) {
            // Время
            currentTime = formatSecondsToTime(audio.currentTime)

            // Слайдер
            const sliderPercent = (audio.currentTime / (audio.duration / 100))
            slider = `${100 - sliderPercent}%`
        }
        setSlider(slider)
        setCurrentTime(currentTime)

    }
    function plays() {
        play({
            trackId: song_id,
            filename: buildGETUrl("clip/play", { id:song_id }), //`/clip/play?id=${song_id}`,
            trackNot: id
        })
    }
    function pauses() {
        pause()
    }

    function rewinds(e) {
        const rect = e.target.getBoundingClientRect(),
            clickPercent = 100 * (e.nativeEvent.offsetX / rect.width),
            totime = audio.duration * (clickPercent * 0.01)
        rewind(totime)
    }

    let className = cl(
        "selectable-item",
        { "MusicReport": [variant] },
        // { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
        { "playing": (isPlaying && trackPlaying === song_id && trackNotId === id) },
        { "name-fade": slider === "100%" },
    )
    return (
        <div className={ className } data-object-state-id={ id } >
            <header className="MusicReport__header">
                <span className="rewind" onClick={ rewinds }></span>
                <span className={ cl("slider-1", { "d-none": slider === 0 }) } style={ { right: slider } }></span>
                <span className={ cl("slider-2", { "d-none": slider === 0 }) } style={ { right: slider } }></span>
                <div className="MusicsReport__grid">
                    <div className="timestamp">
                        { timestamp }
                    </div>
                    <div className="list" data-object-state-id={ id }>
                        <span title={ playlistname }>{ playlistname }</span>
                    </div>
                    <div className="song" data-object-state-id={ id }>
                        <span onClick={ plays }>
                            <SvgPlay className="icon-play" variant="prim" size="xs1" title="Воспроизвести" />
                        </span>
                        <span onClick={ pauses }>
                            <SvgPause className="icon-pause" variant="prim" size="xs1" title="Приостановить" />
                        </span>
                        <span style={ { marginLeft: "10px" } }>{ name }</span>
                    </div>
                </div>
            </header>
        </div >
    )
}

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    menu: state.report.menu
    // selected: [...state.public_playlists.selected_song],
})

export default connect(stateToProps, null)(MusicReportItem)
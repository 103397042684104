import React from 'react'
import Navbar from '../../components/Navbar'
import Panel from './Panel'
import Workspace from './Workspace'


const Notifications = props => (
    <section>
        <Navbar page={ props.match.path } />
        <Panel page={ props.match.path } />
        <Workspace page={ props.match.path } />
    </section>
)

export default Notifications
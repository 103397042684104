import React from 'react'
import moment from 'moment'

import Button from '../../../Button'

import SvgArrowUpDown from '../../../SVG/ArrowUpDown'
import SvgPlay from "../../../SVG/Play";
import SvgPlayVideo from "../../../SVG/PlayVideo";
import SvgPause from "../../../SVG/Pause";
import {cl} from "../../../../utilities/cl";


class MediaplanObject extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            sortBy: false,
            order: "ASC", // DESC
        }

        this.sort = this.sort.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.rewind = this.rewind.bind(this)
    }

    play(id, filename, mimetype) {
        const {
            name,
            play,
            pause,
            openModal,
        } = this.props

        const isAudio = (mimetype.indexOf("mp3") !== -1 || mimetype.indexOf("audio") !== -1)
        const isVideo = (mimetype.indexOf("mp4") !== -1 || mimetype.indexOf("video") !== -1)

        // Аудио
        if (isAudio) {
            play({
                trackId: id,
                filename: filename
            })

            // Видео
        } else if (isVideo) {
            pause()
            openModal({
                type: "video",
                data: {
                    video: {
                        title: name,
                        mp4: filename,
                    }
                }
            })
        }

    }

    pause() {
        this.props.pause()
    }

    rewind(e) {
        const rect = e.target.getBoundingClientRect(),
            clickPercent = 100 * (e.nativeEvent.offsetX / rect.width),
            totime = this.props.audio.duration * (clickPercent * 0.01)

        this.props.rewind(totime)
    }

    sort(by) {
        let rows = this.state.rows
        let order =
            this.state.sortBy === by
                ? (this.state.order === "ASC") ? "DESC" : "ASC"
                : "ASC"

        if (by === "start") {
            order === "ASC"
                ? rows.sort((a, b) => a.fromdateMilliseconds - b.fromdateMilliseconds)
                : rows.sort((a, b) => b.fromdateMilliseconds - a.fromdateMilliseconds)

        } else if (by === "end") {
            order === "ASC"
                ? rows.sort((a, b) => a.todateMilliseconds - b.todateMilliseconds)
                : rows.sort((a, b) => b.todateMilliseconds - a.todateMilliseconds)
        }

        this.setState({
            rows: rows,
            sortBy: by,
            order: order
        })
    }

    componentDidMount() {
        this.setState({rows: this.props.rows})
    }

    render() {
        const {type, title, isPlaying, trackPlaying} = this.props
        const {rows} = this.state

        return (
            <div className="ModalMediaplanObject">
                <div className="ModalMediaplanObject__type grids-titles">{type}</div>
                <div className="ModalMediaplanObject__title">{title}</div>

                <div className="ModalMediaplanObject__header">
                    <div className="ModalMediaplanObject__grid grids-titles">
                        <div className="start">
                            <Button type="string" onClick={() => this.sort("start")}>
                                <SvgArrowUpDown title='Сортировка по времени начала'/>
                                <span className="des">Время начала</span>
                                <span className="mob">Нач.</span>
                            </Button>
                        </div>
                        <div className="end">
                            <Button type="string" onClick={() => this.sort("end")}>
                                <SvgArrowUpDown title='Сортировка по времени окончания'/>
                                <span className="des">Время окончания</span>
                                <span className="mob">Оконч.</span>
                            </Button>
                        </div>
                        <div className="days">
                            <span className="des">Кол-во дней</span>
                            <span className="mob">Дней</span>
                        </div>
                        <div className="schedule">
                            Расписание
                        </div>
                        <div className="track">
                            Ролик
                        </div>
                    </div>
                </div>

                <div className="ModalMediaplanObject__rows">
                    <div className="rows">
                        {rows.map(row => {
                            return (
                                <div key={row.keyId} className="ModalMediaplanObject__item">
                                    <div className={cl('ModalMediaplanObject__grid', 'PlayMedia', { "playing": (isPlaying && trackPlaying === row.keyId) })}>
                                        <div className="start">{moment(row.fromdate).format("DD[.]MM[.]YYYY")}</div>
                                        <div className="end">{moment(row.todate).format("DD[.]MM[.]YYYY")}</div>
                                        <div className="days" title={row.count}>{row.days}</div>
                                        <div className="schedule">{row.times}</div>
                                        <div className="track">
                                            <div className="play">
                                                <span onClick={() => this.play(row.keyId, row.clip.url, row.clip.mimetype)}>
                                                    <SvgPlay className="icon-play" variant="prim" size="xs1" title="Воспроизвести"/>
                                                </span>
                                                <span onClick={this.pause}>
                                                    <SvgPause className="icon-pause" variant="prim" size="xs1" title="Приостановить"/>
                                                </span>
                                            </div>
                                            <span title={row.clip.id}>{row.clip.origname}</span>
                                            <br/>
                                            <small>{row.clip.mtime2}</small>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="finnaly">
                        Итого трансляций на объекте: <span>{getCountPlays(rows)}</span>
                    </div>
                </div>

            </div>
        )
    }
}

export default MediaplanObject


function getCountPlays(rows) {
    let plays = 0,
        key

    if (!window.countPlaysCache) window.countPlaysCache = {}

    rows.map(row => {
        key = `${row.days}::${row.times}`
        if (!window.countPlaysCache[key]) window.countPlaysCache[key] = row.times.split(", ").length * row.days
        plays += window.countPlaysCache[key]
    })

    return plays
}
export const validateMap = {
    "starttime": {
        required: true,
        type: "string",
        min: 4,
    },
    "stop": {
        required: true,
        type: "string",
        min: 4,
    },
    "daymask": {
        required: true,
        type: "number",
        min: 1,
    },
}
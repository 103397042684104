export const ADVERT_TRACKS     = "ADVERT_TRACKS"

export const OBJECT            = "OBJECT"
export const OBJECT_OBJECT     = "OBJECT_OBJECT"
export const OBJECT_MODEM      = "OBJECT_MODEM"
export const OBJECT_NET        = "OBJECT_NET"

export const OBJECT_BACKGROUND = "OBJECT_BACKGROUND"
export const OBJECT_SCHEDULE   = "OBJECT_SCHEDULE"
export const OBJECT_SILENT     = "OBJECT_SILENT"
export const OBJECT_VOLUME     = "OBJECT_VOLUME"
import {
    MEDIA_BREAKPOINT_MG,
    MEDIA_BREAKPOINT_MD,
} from './breakpoints'

//
// is
//

export function isMobile() {
    const ww = window.outerWidth
    return (ww <= MEDIA_BREAKPOINT_MD)
}

export function isTablet() {
    const ww = window.outerWidth
    return (ww > MEDIA_BREAKPOINT_MD && ww <= MEDIA_BREAKPOINT_MG)
}

export function isDesktop() {
    const ww = window.outerWidth
    return (ww > MEDIA_BREAKPOINT_MG)
}

//
// render
//

export function mediaMobile(content, placeholder) {
    return isMobile() ? content : placeholder
}

export function mediaTablet(content, placeholder) {
    return isTablet() ? content : placeholder
}

export function mediaDesktop(content, placeholder) {
    return isDesktop() ? content : placeholder
}

export function mediaTabletAndDown(content, placeholder) {
    const ww = window.outerWidth
    return (ww <= MEDIA_BREAKPOINT_MG) ? content : placeholder
}

export function mediaTabletAndUp(content, placeholder) {
    const ww = window.outerWidth
    return (ww > MEDIA_BREAKPOINT_MD) ? content : placeholder
}
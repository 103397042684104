import { takeEvery, select, all, put, call } from 'redux-saga/effects'
import { OBJECT_BACKGROUND } from '../../../../store/Settings/sets'
import { ADD_ITEM, cleanSettings, loadItems } from '../../../../store/Settings/actions/sets/Object/Background'
import { adding } from '../../../../store/Settings/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { cleanInsideParams } from '../../../../utilities/cleanInsideParams'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(ADD_ITEM, worker)
}

function* worker({ payload }) {
    let { stateKey } = payload

    try {
        yield put(adding({
            type: OBJECT_BACKGROUND,
            stateKey,
            adding: true,
        }))

        const settings = yield select(state => state.settings.settings[OBJECT_BACKGROUND][stateKey] || {})
        const selectedPeriod = settings.selectedPeriod || -1
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedGroups = yield select(state => state.objectsGroup.selected || [])

        if (selectedObjects.length || selectedGroups.length) {
            const msIds = selectedGroups.length ? selectedGroups : selectedObjects
            const added = yield call(fetchAdd, settings, selectedPeriod, msIds)

            // Ошибка
            if (added.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${ADD_ITEM}.`, added],
                    text: "Ошибка при добавлении. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(cleanSettings({ stateKey }))

                let keysToReload = []
                selectedObjects.map(objectId => {
                    const stateKey = generateStateKey([objectId])
                    keysToReload.push(stateKey)
                })
                keysToReload.push(stateKey)

                yield all(
                    keysToReload.map(stateKey =>
                        put(loadItems({
                            stateKey,
                            required: true,
                            afterAdded: true
                        }))
                    )
                )
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${ADD_ITEM}.`, e],
            text: "Ошибка при добавлении. Попробуйте повторить."
        }))
    }

    finally {
        yield put(adding({
            type: OBJECT_BACKGROUND,
            stateKey,
            adding: false,
        }))
    }
}

function fetchAdd(settings, period, ms_id) {
    settings = { ...settings }

    settings.daymask = settings.daymask || 0

    settings = cleanInsideParams(settings)

    if (period !== -1) {
        settings.dates_id = period
    }

    settings.ms_id = ms_id.join(",")

    delete settings.__itemsLoaded
    delete settings.__loading
    delete settings.__adding
    delete settings.selectedPeriod
    delete settings.selected

    return axios.get("/msbox/play/insert", { params: { ...settings } })
        .then(response => response.data)
}
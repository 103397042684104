import React from 'react'


const Trash = ({ title = 'Удалить', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.23 3.791">
                <title>{ title }</title>
                <g data-name="Group 2737"><path d="M1.26 0a.18.18 0 00-.14.076.205.205 0 00-.035.118v.513H.133a.133.133 0 000 .265h.07A.132.132 0 00.207.99l.815 2.704a.133.132 0 00.13.094.133.132 0 00.005 0h1.9a.132.132 0 00.157-.084.133.132 0 00.003-.01l.82-2.704A.132.132 0 004.04.972h.057a.133.133 0 100-.265h-.944V.194a.206.206 0 00-.034-.118A.18.18 0 002.98 0zm.09.265h1.538v.434H1.35zM.479.972h3.287l-.772 2.55H1.247zm.94.312a.132.132 0 00-.133.156l.234 1.398a.133.132 0 10.261-.044l-.234-1.398a.132.132 0 00-.128-.112zm1.39 0a.132.132 0 00-.124.112l-.233 1.398a.133.132 0 10.262.044l.233-1.398a.132.132 0 00-.137-.156z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#fff" stroke="none" data-name="Group 295" strokeMiterlimit="10" strokeWidth="1.135" strokeLinecap="round" /></g></svg>
        </i>
    )
}

export default Trash
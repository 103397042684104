import React, { useState, useEffect } from "react";
import { Badge, Dropdown, FormControl } from "react-bootstrap";
import SvgArrowDown from '../SVG/ArrowDownLight'
import Tags from '../Tags'
import SvgClose from '../SVG/Close'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div style={ { display: "flex", flexDirection: "row", margin: "0 -5px" } }>
        <div
            style={ { display: "flex", cursor: "pointer", margin: "4px 4px" } }
            href=""
            ref={ ref }
            onClick={ (e) => {
                e.preventDefault();
                onClick(e);

            } }
        >

            { children }
            <div style={ { marginTop: "2px", marginLeft: "4px" } }>
                <SvgArrowDown size="mr" />
            </div>
        </div>

    </div >
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy, placeholder }, ref) => {
        const [value, setValue] = useState('');

        const hChange = (e) => {
            // console.log(e)
            setValue(e.target.value)
            console.log("HCHANGE", children)
        }
        // const hSelect = (val) => {
        //     console.log(val)
        // }

        return (
            <div
                ref={ ref }
                style={ style }
                className={ className }
                aria-labelledby={ labeledBy }
            >
                <FormControl
                    autoFocus
                    style={ {
                        minWidth: "220px",
                        padding: "0 8px"
                    } }
                    className="mx-3 my-2 w-auto"
                    placeholder={ placeholder }
                    onChange={ (e) => hChange(e) }
                    value={ value }
                />
                <ul className="list-unstyled" style={ {
                    height: "180px",
                    overflowY: "scroll"
                } }>
                    { React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                    ) }
                </ul>
            </div>
        );
    },
);
const SearchSelect = (
    // {
    // items,
    // title,
    // type,
    // select,
    // hdelete,
    // params,
    // placeholder
    // },
    props) => {
    const hSelect = (e) => {
        props.select({ type: props.type, value: e })
    }
    const onDelete = (e) => {
        props.hdelete({ type: props.type, value: e })
    }

    return (
        <div style={ { display: "flex", flexDirection: "row" } }>
            <div>
                <Dropdown onSelect={ (e) => hSelect(e) }>
                    <Dropdown.Toggle as={ CustomToggle } id="dropdown-custom-components">
                        <h4>{ props.title }</h4>
                    </Dropdown.Toggle>

                    <Dropdown.Menu renderOnMount={ true } as={ CustomMenu } placeholder={ props.placeholder }>
                        {
                            Object.values(props.items).map(item => {
                                return <Dropdown.Item eventKey={ `${item}` }>{ `${item.length > 25 ? `${item.substr(0, 25)}...` : item}` }</Dropdown.Item>
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div style={ { flex: "1 1 auto", margin: "0 10px" } }>
                { props.params[props.type]?.length > 0 &&
                    <Tags
                        onDelete={ (e) => onDelete(e) }
                        className="setsex-col-1"
                        tags={ [...props.params[props.type].map((el, index) => { return { name: el, id: index } })] }
                    // onChange={ hChange("tags") }
                    />
                }
            </div>
        </div>
    )
}
export default SearchSelect
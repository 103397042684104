import {
    LOAD_SUBUSERS, LOADING,
    PUT_SUBUSERS,
    UPDATE_SUBUSER,
    CREATE_SUBUSER,
    LOAD_ACCOUNT_INFO,
    PUT_ACCOUNT_INFO, PUT_ROLES, CREATE_ROLE
} from './actions'

const inititalState = {
    loading: false,
    subusers: [],
    roles: [],
    account: {},
    master: null
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case LOAD_SUBUSERS:
            return {
                ...state,
            }
        case LOAD_ACCOUNT_INFO:
            return {
                ...state,
            }
        case CREATE_SUBUSER:
            return {
                ...state,
            }
        case CREATE_ROLE:
            return {
                ...state,
            }

        case PUT_SUBUSERS:
            return {
                ...state,
                subusers: payload.subusers,
                master: payload.master,
            }
        case PUT_ROLES:
            return {
                ...state,
                roles: payload.roles,
            }
        case PUT_ACCOUNT_INFO:
            return {
                ...state,
                account: payload.account,
            }
        case UPDATE_SUBUSER:
            return {
                ...state,
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }
    }
    return state
}

export default reducer
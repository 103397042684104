import React from 'react'


const Edit = ({ title = 'Редактировать', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.945 18">
                <title>{ title }</title>
                <path d="M8.53 0a.5.5 0 00-.339.147l-4.71 4.71H.5a.5.5 0 00-.5.5v7.286a.5.5 0 00.5.5h2.98l4.711 4.71a.5.5 0 00.854-.353V.5a.5.5 0 00-.516-.5zm-.485 1.707v14.586L4.04 12.29a.5.5 0 00-.354-.146H1V5.858h2.688a.5.5 0 00.353-.147zm4.096 2.414a.5.5 0 10-.016 1c1.313.02 2.237.507 2.865 1.223.629.716.955 1.681.955 2.654 0 .973-.326 1.94-.955 2.657-.628.715-1.552 1.202-2.865 1.222a.5.5 0 10.016 1c1.561-.023 2.793-.642 3.601-1.562.809-.92 1.203-2.123 1.203-3.317s-.394-2.394-1.203-3.314c-.808-.92-2.04-1.54-3.601-1.563z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" /></svg>
        </i>
    )
}

export default Edit
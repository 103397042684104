import { takeEvery, call, put } from 'redux-saga/effects'
import { CREATE_SUBUSER, loading, loadSubusers } from '../../store/Roles/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(CREATE_SUBUSER, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        const subuser = yield call(createSubuser, { ...payload })
        if (subuser.error && subuser.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CREATE_SUBUSER}.`, subuser],
                text: "При создании пользователя произошла ошибка. Попробуйте перезагрузить страницу."
            }))
            // yield put(putSubusers([]))
        } else {
            yield put(loadSubusers())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CREATE_SUBUSER}.`, e],
            text: "При создании пользователя произошла ошибка. Попробуйте перезагрузить страницу."
        }))
        // yield put(putSubusers([]))
    }
    finally {
        yield put(loading(false))
    }
}

function createSubuser(userdata) {
    let formData = new FormData()
    Object.keys(userdata).forEach(name => {
        formData.set(name, userdata[name])
    })
    return axios({
        url: "/account/subowner/insert",
        method: "POST",
        data: formData
    })
        .then(response => response.data)
}

import moment from 'moment'
import { takeEvery, call, put, all, select } from 'redux-saga/effects'

import {
    loading,
    loading_assoc,
    putPublicPlaylists,
    putAssocMobilePublicTracks,
    putAssocPublicTracks,
    LOAD_ASSOC_PUBLIC_TRACKS,
    setSelectSongsAll,
    loadAssocPublicTracks
} from '../../store/PublicPlaylists/actions'
import { changeValue } from '../../store/Search/actions'
import { LOAD_PLAYLIST } from '../../store/ContentPlaylists/actions'




export default function* () {
    yield takeEvery(LOAD_ASSOC_PUBLIC_TRACKS, worker)
    yield takeEvery(LOAD_PLAYLIST, worker)

}

function* worker({ payload }) {
    
    const songsSearchState = yield select(state => state.search.songs)
    if (!payload.name && songsSearchState.value !== '' && payload.offset === 0)
        yield put(changeValue({ type: 'songs', value: '' }))
    if (typeof payload === 'object') {
        let playlistIds = yield select(state => state.public_playlists.selected)
        playlistIds = playlistIds[0];
        if (playlistIds && !('offset' in payload))
            yield put(loadAssocPublicTracks({ offset: 0 }))
    }


}



import React, {useEffect} from 'react'

import {OBJECT, OBJECT_BACKGROUND} from '../../../../store/Settings/sets'

import {cl} from '../../../../utilities/cl'
import {generateStateKey} from '../../../../utilities/generateStateKey'
import {carryUpdate} from '../../utilities/carryUpdate'
import {updateHelper} from '../../utilities/updateHelper'
import {setsex, setsmob} from '../../utilities/setsclasses'
import {prepareSettings} from '../../utilities/prepareSettings'
import {validateMap} from './validateMap'

import Builder from '../../Builder'

import SettingsPeriods from '../../components/Periods'
import SettingsDays from '../../components/Days'
import fieldStarttime from './items/starttime'
import SettingsAddButton from '../../components/AddButton'
import GroupDays from './groups/Days'
import GroupBlocksList from './groups/BlocksList'
import SettingsBackgroundLists from '../../components/BackgroundLists'


const SettingsObjectBackground = ({
    className = "",
    stateKey,

    store = {},
    settings = {},
    items = [],
    selected = [],
    selectedPeriod,

    selectedObjects = [],
    selectedGroups = [],
    objectsAssoc = {},
    groupsAssoc = {},
    usesOwn = [],

    loading,
    adding,
    saving,
    deleting,

    loadItems,
    addItem,
    saveItems,
    deleteItems,
    selectUsedVideo,
    selectPeriod,
    updateOptions,
    toggleSelectedItems,
    openModal,
    closeModal,
}) => {

    className = cl(
        className,
        "SettingsObjectBackground"
    )

    useEffect(() => {
        loadItems({ stateKey })
    }, [stateKey])

    let validMap
    [settings, validMap] = prepareSettings({
        type: OBJECT_BACKGROUND,
        settings,
        validateMap,
    })

    const updateOptionsCreate = carryUpdate({
        type: OBJECT_BACKGROUND,
        stateKey,
        updateOptions
    })

    const hChangeCreate = (name) => updateHelper({ 
        name,
        updateOptions: updateOptionsCreate,
        handlerType: OBJECT_BACKGROUND
    })

    const hSelectPeriod = ({ value }) => selectPeriod({ periodId: value, stateKey })
    const hAddItem = () => addItem({ stateKey })

    // onChange и готовые настройки для item (элементов списка)

    const itemStateKey = generateStateKey([ stateKey, selected[0] ])
    const itemSettings = store[itemStateKey] || {}

    const updateOptionsItem = carryUpdate({
        type: OBJECT_BACKGROUND,
        stateKey: itemStateKey,
        updateOptions
    })

    const hChangeItem = (name) => updateHelper({ 
        name,
        updateOptions: updateOptionsItem
    })

    const hSelectUsedVideo = ({ name, value }) => selectUsedVideo({
        itemStateKey,
        id: name,
        videolistId: value,
    })

    return(
        <Builder
            className={className}
            warning={getWarning()}
            groups={{
                all: [
                    // Group
                    {
                        data: {
                            columns: 1,
                            className: setsex(["col-1"])+setsmob(["pb-lg1"])
                        },
                        items: [
                            <SettingsPeriods
                                type="play"
                                selected={selectedPeriod}
                                onChange={hSelectPeriod}
                                showCopied
                            />
                        ]
                    },

                    // Group
                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                            title: "Добавление",
                            className: setsex(["col-2", "mr"])
                        },
                        items: [
                            {
                                title: "Время начала",
                                components: fieldStarttime({
                                    settings,
                                    name: "starttime",
                                    onChange: hChangeCreate("starttime")
                                })
                            },
                        ]
                    },

                    // Group
                    {
                        data: {
                            noDivider: true,
                            className: setsex(["col-2"])
                        },
                        items: [
                            <SettingsDays
                                value={settings["daymask"] || 127}
                                onChange={hChangeCreate("daymask")}
                            />
                        ]
                    },

                    // Group
                    {
                        data: {
                            noDivider: true,
                            className: setsex(["col-2"])
                        },
                        items: [
                            <SettingsAddButton
                                onClick={hAddItem}
                                title={adding ? "Добавление..." : "Добавить"}
                                disabled={adding || !validMap.validResult}   
                            />
                        ]
                    },

                    {
                        data: {
                            columns: 2,
                            noDivider: true,
                            title: "Редактирование",
                            className: setsex(["col-2", "mr"])
                        },
                        items: [
                            {
                                title: "Время начала",
                                components: fieldStarttime({
                                    settings: itemSettings,
                                    name: "starttime",
                                    onChange: hChangeItem("starttime")
                                })
                            },
                        ]
                    },

                    <GroupDays
                        className={setsex(["col-1"])}
                        value={itemSettings["daymask"]}
                        onChange={hChangeItem("daymask")}
                        disabled={!(selected.length)}
                    />,

                    // BlocksList
                    <GroupBlocksList
                        className={setsex(["col-1"])}
                        title="Редактирование расписания"
                        type={OBJECT_BACKGROUND}
                        stateKey={stateKey}
                        items={items}
                        selected={selected}
                        loading={loading}
                        saving={saving}
                        deleting={deleting}
                        saveItems={saveItems}
                        deleteItems={deleteItems}
                        toggleSelectedItems={toggleSelectedItems}
                        openModal={openModal}
                        closeModal={closeModal}
                    />,

                    // Lists
                    <SettingsBackgroundLists
                        className={setsex(["col-1"])}
                        timelineId={selected.length === 1 ? selected[0] : undefined}
                        usedVideo={itemSettings["videolist_id"]}
                        selectUsedVideo={hSelectUsedVideo}
                    />
                ],
            }}
        />
    )

    function getWarning(empty) {
        if(selectedObjects.length === 1 && selectedGroups.length === 0) {
            const objectId = selectedObjects[0]
            const objectGroupId = objectsAssoc[objectId].msgroup_id || 0

            if(objectGroupId) {
                let baseWarning = `Внимание! Объект «${objectsAssoc[objectId].name}» принадлежит группе объектов «${groupsAssoc[objectGroupId].name}»`
                if(usesOwn.indexOf(objectId) !== -1) {
                    baseWarning += `, но использует собственный контент`
                }
                return baseWarning+"."
            }
        }
        return empty
    }
}

export default SettingsObjectBackground
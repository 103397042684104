import React from 'react'
import PropTypes from 'prop-types'


const Confirm = ({ title, buttons = {}, toggleOpened }) => {
    return (
        <div className="ModalConfirm">
            <div className="title">{ title }</div>
            <div className="Modal__buttons">
                <div className="cancel" onClick={ buttons.cancel.onClick }>
                    { buttons.cancel.title }
                </div>
                <div className="confirm" onClick={ buttons.confirm.onClick }>
                    { buttons.confirm.title }
                </div>
            </div>
        </div>
    )
}

Confirm.propTypes = {
    title: PropTypes.string,
    buttons: PropTypes.exact({
        cancel: PropTypes.exact({
            title: PropTypes.string,
            onClick: PropTypes.func
        }),
        confirm: PropTypes.exact({
            title: PropTypes.string,
            onClick: PropTypes.func
        }),
    }),
    toggleOpened: PropTypes.func
}

export default Confirm
import React from 'react'
import PropTypes from 'prop-types'
import {Button as ButtonBS} from 'react-bootstrap'

import Header from '../components/header'
import {isFunc} from '../../../utilities/isFunc'


const Element = ({ className = "", title, element, buttons, toggleOpened }) => {
    return(
        <div
            className={`ModalElement ${className}`}
            onKeyDown={keyClose}
        >
            <Header
                title={title}
                toggleOpened={toggleOpened}
            />

            {element && element}

            {buttons
                && <div className="Modal__footer">

                    {(buttons.prim && buttons.forth)
                        ? <div className="spp-btns">
                            <ButtonBS variant="prim" onClick={hClickButtonPrim} {...buttons.prim.attrs}>
                                {buttons.prim.title}
                            </ButtonBS>
                            <ButtonBS variant="forth" onClick={hClickButtonForth} {...buttons.forth.attrs}>
                                {buttons.forth.title}
                            </ButtonBS>
                        </div>

                        : (buttons.prim)
                            ? <ButtonBS variant="prim" onClick={hClickButtonPrim} {...buttons.prim.attrs}>
                                {buttons.prim.title}
                            </ButtonBS>

                            : (buttons.forth)
                                ? <ButtonBS variant="forth" onClick={hClickButtonForth} {...buttons.forth.attrs}>
                                    {buttons.forth.title}
                                </ButtonBS>

                                : null
                    }
                </div>
            }
        </div>
    )

    function hClickButtonPrim() {
        toggleOpened()
        if(isFunc(buttons?.prim?.onClick)) {
            buttons.prim.onClick()
        }
    }

    function hClickButtonForth() {
        toggleOpened()
        if(isFunc(buttons?.forth?.onClick)) {
            buttons.forth.onClick()
        }
    }

    function keyClose(e) {
        if(e.key === "Enter") hClickButtonPrim()
    }
}

Element.propTypes = {
    title: PropTypes.string,
    element: PropTypes.element,
    buttons: PropTypes.exact({
        prim: PropTypes.exact({
            title: PropTypes.string,
            onClick: PropTypes.func,
            attrs: PropTypes.object,
        }),
        forth: PropTypes.exact({
            title: PropTypes.string,
            onClick: PropTypes.func,
            attrs: PropTypes.object,
        }),
    }),
}

export default Element
import {OBJECT} from '../../../sets'

export const LOAD_SETTINGS = `Settings/${OBJECT}/LOAD_SETTINGS`
export const SAVE_SETTINGS = `Settings/${OBJECT}/SAVE_SETTINGS`

export const loadSettings = ({ type, fields, handler, stateKey }) => ({
    type: LOAD_SETTINGS,
    payload: {
        type,
        fields,
        handler,
        stateKey,
    }
})

export const saveSettings = ({ type, fields, stateKey }) => ({
    type: SAVE_SETTINGS,
    payload: {
        type,
        fields,
        stateKey
    }
})
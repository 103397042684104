import moment from 'moment'
import { takeEvery, call, put, all } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOADING_AVMAP_BINDINGS, loading_avmap, putAvmapBindings } from '../../store/ContentPlaylists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios';

export default function* () {
    yield takeEvery(LOADING_AVMAP_BINDINGS, worker)
}

function* worker() {
    yield put(loading_avmap(true))
    try {
        const fetchedAvmap = yield call(fetchAvmap)

        if (fetchedAvmap.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOADING_AVMAP_BINDINGS}.`, LOADING_AVMAP_BINDINGS],
                text: "Не загружена связь плейлистов и видеосписков. Попробуйте перезагрузить страницу."
            }))
            yield put(putAvmapBindings([]))

        } else {
            const fpl = fetchedAvmap.success
            console.log(fpl)
            yield put(putAvmapBindings([...fpl]))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOADING_AVMAP_BINDINGS}.`, e],
            text: "Не загружена связь плейлистов и видеосписков. Попробуйте перезагрузить страницу."
        }))
        yield put(putAvmapBindings([]))
    }

    finally {
        yield put(loading_avmap(false))
    }
}

function fetchAvmap() {
    return axios.get("/videolist/avmap/select")
        .then(response => response.data)
}
import React from 'react'

import ScrollContainer from '../../ScrollContainer'
import Spinner from '../../Spinner'
import PlaylistItem from '../PlaylistItem'
import Empty from '../Empty'
import List from '../../List'



const Left = ({
    searchActive,
    searchFounded = [],
    playlists = [],
    loading,
    toggleSelected,
    searchVal
}) => {

    if (searchVal.length > 0) playlists = searchFounded
    const getItems = () => {
        return playlists.map((playlist, index) => ({
            //return (
            id: playlist.id,
            name: playlist.name,
            element: <PlaylistItem
                key={ playlist.id }
                id={ playlist.id }
                name={ playlist.name }
                totaltime={ playlist.totaltime }
                totalsize={ playlist.totalsize2 }
                totalcount={ playlist.totalcount }
                variant={ (index % 2 == 0) ? "gray" : "" }
            />
        }))
    }
    return (
        <div onClick={ toggleSelected }>
            { loading
                ? <Spinner className="pt-xs pb-xs" />

                : (playlists.length || searchActive)
                    ? <List
                        type="simple"
                        scroll={ {
                            on: true,
                            data: {
                                variant: "white",
                                size: "sm",
                            }
                        } }
                        // variant={ "none" }
                        content={ { items: getItems(), variant: "none" } }
                    />

                    : <Empty title="Плейлистов пока нет." />
            }

        </div>
    )
}



export default Left
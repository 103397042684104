export const LOAD_MUSIC_REPORT = "Reports/Music/LOAD_MUSIC_REPORT"
export const LOADING = "Reports/Music/LOADING"
export const PUT_MUSIC_REPORT = "Reports/Music/PUT_MUSIC_REPORT"
export const SORT_MUSIC_REPORT = "Reports/Music/SORT_MUSIC_REPORT"

export const loadMusicReport = date => ({
    type: LOAD_MUSIC_REPORT,
    payload: date
})
export const loading = mode => ({
    type: LOADING,
    payload: mode
})
export const putMusicReport = music_report => ({
    type: PUT_MUSIC_REPORT,
    payload: music_report
})
export const sortMusicReport = sortSource => ({
    type: SORT_MUSIC_REPORT,
    payload: sortSource
})
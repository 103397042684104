import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_TEMPLATES, loading, putTemplates, loadTemplates, UPDATE_TEMPLATES } from '../../store/Templates/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { closeModal } from '../../store/Modal/actions'

export default function* () {
    yield takeEvery(UPDATE_TEMPLATES, worker)
}

function* worker({ payload }) {
    //yield put(loading(true))
    try {
        const { name, body } = payload
        const templates = yield call(fetchTemplates, { name, body })
        if (templates.error && templates.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${UPDATE_TEMPLATES}.`, templates],
                text: "Шаблоны не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(loadTemplates())
        } else {
            // yield put(loadTemplates())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${UPDATE_TEMPLATES}.`, e],
            text: "Шаблоны не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(loadTemplates())
    }
    finally {
        yield put(loadTemplates())
        yield put(closeModal("templateCreate"))
    }
}

function fetchTemplates(data) {
    let formData = new FormData()
    formData.set(data.name, data.body)
    return axios({
        url: "/ether/template/update",
        method: "POST",
        data: formData
    })
        .then(response => response.data)
}

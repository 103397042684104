import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {Button as ButtonBS} from 'react-bootstrap'

import Header from '../components/header'

import InputText from '../../Form/InputText'


let inputValue = ""


const OneInput = ({ className = "", title, input, buttons, toggleOpened, required = false }) => {
    const formRef = useRef(null);
    return(
        <form ref={formRef}
            className={`ModalOneInput ${className}`}
            onKeyDown={keyClose}
        >
            <Header
                title={title}
                toggleOpened={toggleOpened}
            />

            <InputText
                label={input?.label}
                required={required}
                onChange={hChangeSaveValue}
                attrs={{
                    ...input?.attrs
                }}
            />

            <div className="Modal__footer">

                {(buttons && buttons.prim && buttons.forth)
                    ? <div className="spp-btns">
                        <ButtonBS variant="prim" onClick={hClickButtonPrim} {...buttons.prim.attrs}>
                            {buttons.prim.title}
                        </ButtonBS>
                        <ButtonBS variant="forth" onClick={hClickButtonForth} {...buttons.forth.attrs}>
                            {buttons.forth.title}
                        </ButtonBS>
                    </div>

                    : (buttons && buttons.prim)
                        ? <ButtonBS variant="prim" onClick={hClickButtonPrim} {...buttons.prim.attrs}>
                            {buttons.prim.title}
                        </ButtonBS>

                        : (buttons && buttons.forth)
                            ? <ButtonBS variant="forth" onClick={hClickButtonForth} {...buttons.forth.attrs}>
                                {buttons.forth.title}
                            </ButtonBS>

                            : ""
                }
            </div>
        </form>
    )

    function hChangeSaveValue({ value }) {
        inputValue = value
    }

    function hClickButtonPrim() {
        if (required) {
            formRef.current.reportValidity()
            if (!inputValue) {
                return
            }
        }
        toggleOpened()
        if(buttons && buttons.prim && typeof buttons.prim.onClick === "function") {
            buttons.prim.onClick(inputValue)
        }
    }

    function hClickButtonForth() {
        toggleOpened()
        if(buttons && buttons.forth && typeof buttons.forth.onClick === "function") {
            buttons.forth.onClick(inputValue)
        }
    }

    function keyClose(e) {
        if(e.key === "Enter") hClickButtonPrim()
    }
}

OneInput.propTypes = {
    title: PropTypes.string,
    input: PropTypes.exact({
        label: PropTypes.string,
        attrs: PropTypes.object
    }),
    buttons: PropTypes.exact({
        prim: PropTypes.exact({
            title: PropTypes.string,
            onClick: PropTypes.func,
            attrs: PropTypes.object,
        }),
        forth: PropTypes.exact({
            title: PropTypes.string,
            onClick: PropTypes.func,
            attrs: PropTypes.object,
        }),
    }),
}

export default OneInput
export const DISABLE = "AdvertPage/DISABLE"
export const ENABLE = "AdvertPage/Enable"


export const disableBlocks = () => ({
    type: DISABLE
})


export const enableBlocks = () => ({
    type: ENABLE
})

import { CHANGE_COLLAPSIBLE_VaLUE } from './actions'

const initialState = {
    'objectsGroup': false    
}

const reducer = (state = initialState, action) => {    
    switch (action.type) {
        case CHANGE_COLLAPSIBLE_VaLUE:
            
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }}
    
    return state
}

export default reducer
import {isObj} from './isObj'


const sortMap = {
    "number": {
        "ASC": (a, b) => a - b,
        "DESC": (a, b) => b - a,
    },

    "string": {
        "ASC": (a, b) => {
            if(a.toLowerCase() < b.toLowerCase()) return -1
            if(a.toLowerCase() > b.toLowerCase()) return 1
            return 0
        },
        "DESC": (a, b) => {
            if(a.toLowerCase() > b.toLowerCase()) return -1
            if(a.toLowerCase() < b.toLowerCase()) return 1
            return 0
        }
    },

    "time": {
        "ASC": (a, b) => Number(a.replace(/:/g, "")) - Number(b.replace(/:/g, "")),
        "DESC": (a, b) => Number(b.replace(/:/g, "")) - Number(a.replace(/:/g, "")),
    },
}

export function getSortedObj(list = [], by, type, order, empty) {
    if(Array.isArray(list)) {
        if(list.length) {

            if(typeof by === "string" && typeof type === "string" && typeof order === "string") {
                list.sort((a, b) => {
                    if(isObj(a) && isObj(b)) {
                        if(a[by] !== empty && b[by] !== empty) {
                            return sortMap[type][order](a[by], b[by])
                        }
                    }
                })
            }
        }
    }
    return list
}

export function getSortedArr(list = [], by, type, order, empty) {
    if(Array.isArray(list)) {
        if(list.length) {

            if(typeof by === "string" && typeof type === "string" && typeof order === "string") {
                list.sort((a, b) => {
                    if(a !== empty && b !== empty) {
                        return sortMap[type][order](a, b)
                    }
                })
            }
        }
    }
    return list
}
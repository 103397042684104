import React from 'react'

import {getNextSelected} from '../../utilities/getNextSelected'
import {isMobile} from '../../utilities/media'

import Empty from '../Space/Collapsible/Empty'
import ScrollContainer from '../ScrollContainer'
import CollapsibleCard from '../CollapsibleCard'
import Spinner from '../Spinner'


const ObjectsGroup = ({
                          className = "",
                          groups = [],
                          loading,
                          toggleSelectedObject,
                          toggleSelectedGroup,
                          selectedObjects,
                          selectedGroups,
                          statusObject ={}
                      }) => {

    const hClick = (obj, event) => {
        const objectItem = event.target.closest(".ObjectsGroup__item")

        //console.log(obj, event, event.target, objectItem)
        // Один объект
        if (objectItem) {
            const objectId = Number(objectItem.dataset.objectId)
            if (!isNaN(objectId)) {
                toggleSelectedObject({id: objectId})
            }
        }

        // Группу
        else {
            const selected = getNextSelected({
                multiple: true,
                itemId: obj.id,
                items: groups,
                selected: selectedGroups,
                shiftKey: event.shiftKey,
                ctrlKey: isMobile() || event.ctrlKey || event.metaKey,
            })
            toggleSelectedGroup({
                id: obj.id,
                selected,
            })
        }
    }

    const hClick2 = (event) => {
        //console.log(event, event.target, event.target.closest(".ObjectsGroup__item"))
        const objectItem = event.target.closest(".ObjectsGroup__item")
        if (objectItem) {
            const objectId = Number(objectItem.dataset.objectId)
            if (!isNaN(objectId)) {
                toggleSelectedObject({id: objectId})
            }
        }
    }

    const getStatus = (object) => {
        if (object.msstatus_id && typeof statusObject[object.msstatus_id] !== 'undefined') {
            return (<span className="badge badge-info">{statusObject[object.msstatus_id]}</span>)
        }
        return null;
    }

    return (
        <section className={`ObjectsGroup ${className}`}>

            <div className="ObjectsGroup__list">
                <ScrollContainer size="lg">

                    {loading

                        ? <Spinner className="pt-xs pb-xs"/>

                        : (groups.length)

                            ? groups.map(group =>
                                <CollapsibleCard
                                    key={group.id}
                                    id={group.id}
                                    selected={selectedGroups.indexOf(group.id) !== -1}
                                    title={<>{group.name} <span>({group.objects.length})</span></>}
                                    onClick={hClick}
                                >
                                    {group.objects.map(object => (

                                        <div
                                            key={object.id}
                                            data-object-id={object.id}
                                            className={`ObjectsGroup__item selectable-sub-item ${selectedObjects.indexOf(object.id) !== -1 ? "selected" : ""}`}
                                        >
                                            <span className="selectable-sub-item__color" onClick={hClick2}>
                                                {getStatus(object)} {object.fullName}
                                            </span>
                                        </div>
                                    ))}
                                </CollapsibleCard>
                            )
                            : <Empty title="Групп объектов пока нет."/>
                    }

                </ScrollContainer>
            </div>
        </section>
    )
}

export default ObjectsGroup
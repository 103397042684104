import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../utilities/cl'

import SvgArrowDownLight from '../SVG/ArrowDownLight'


const ScrollContainer = ({
    className = "",
    variant = "none",
    scrollVariant = "white",
    size = "md",
    maxHeight = false,
    collapsed = true,
    children,
    droppableId
}) => {
    const [opened, useOpened] = useState(false)
    const count = childrenCount(children)

    className = cl(
        className,
        {
            "ScrollContainer": [
                variant,
                size,
                `scroll-${scrollVariant}`,
                { "collapsed": collapsed }
            ]
        },
        { "opened": (count <= 0 || opened) }
    )

    return (
        <section className={ className }>
            <div className="ScrollContainer__background" >
                <div
                    className="ScrollContainer__wrap"
                    style={ maxHeight ? { maxHeight: maxHeight } : {} }
                    id={ `scrollableDiv${droppableId}` }
                >
                    <div className="ScrollContainer__content">
                        { children }
                    </div>
                </div>
            </div>

            { collapsed &&
                <div className="ScrollContainer__more">
                    <div onClick={ useOpened.bind(null, !opened) }>
                        <SvgArrowDownLight title="Показать ещё" variant="dark" size="xs1" />
                        {/* Показать ещё <span>({ count })</span> */ }
                        Показать ещё ({ count })
                    </div>
                </div>
            }
        </section>
    )
}

ScrollContainer.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(["white", "none"]),
    scrollVariant: PropTypes.oneOf(["white", "gray"]),
    size: PropTypes.oneOf(["md", "lg", "mg", "auto", "max"]),
    maxHeight: PropTypes.string,
    collapsed: PropTypes.bool,
}

function childrenCount(children) {
    let count = -8
    React.Children.map(children, child => count++)
    return count
}

export default ScrollContainer
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { generateStateKey } from '../../../../../../utilities/generateStateKey'
import { cl } from '../../../../../../utilities/cl'

import Collapsible from '../../../../../../components/Space/Collapsible'
import ScrollContainer from '../../../../../../components/ScrollContainer'
import List from '../../../../../../components/List'
import SvgArrowUpDown from '../../../../../../components/SVG/ArrowUpDown'
import SvgPlay from '../../../../../../components/SVG/Play'
import SvgPause from '../../../../../../components/SVG/Pause'
import { formatSecondsToTime } from '../../../../../../utilities/format'

const Song = ({
    className = "",
    selectedPlaylists,
    currentPlaylist,
    placeNumber,
    id,
    active,
    name,
    turn,
    cliptime,
    tempo,
    songauthor_name,
    player_name,
    album_name,
    genre_name,
    year,
    variant,
    selected,
    markItems,
    audio,
    trackPlaying,
    isPlaying,
    play,
    pause,
    stop,
    rewind,
    filename,
    watcherInterval = null
}) => {
    const [played, setPlayed] = useState(false)
    const [currentTime, setCurrentTime] = useState("00:00")
    const [slider, setSlider] = useState("100%")
    const [listLoaded, setListLoaded] = useState(false)
    const [opened, setOpened] = useState(false)

    const prevPlaying = usePrevious(trackPlaying)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {
        if (trackPlaying !== prevPlaying) {
            // startWatch()
            //killWatcher()
            if (isPlaying) {
                startWatch()
            } else {
                killWatcher()
            }
        }
    }, [trackPlaying])

    function startWatch() {
        if (trackPlaying === id) {
            watcherInterval = setInterval(() => {
                if (audio.currentTime === audio.duration) {
                    stop()
                } else {
                    updateTime()
                }
            }, 50)
        } else {
            killWatcher(true)
        }
    }

    function killWatcher(resetTime) {
        clearInterval(watcherInterval)
        updateTime(resetTime)
    }


    const updateTime = (resetTime) => {
        let currentTime = "00:00"
        let slider = "100%"

        if (resetTime !== true && (audio.currentTime !== audio.duration && trackPlaying === id)) {
            // Время
            currentTime = formatSecondsToTime(audio.currentTime)

            // Слайдер
            const sliderPercent = (audio.currentTime / (audio.duration / 100))
            slider = `${100 - sliderPercent}%`
        }
        setSlider(slider)
        setCurrentTime(currentTime)

    }


    function plays() {
        pause()
        play({
            trackId: id,
            filename: filename
        })
    }
    function pauses() {
        pause()
    }
    function rewinds(e) {
        const rect = e.target.getBoundingClientRect(),
            clickPercent = 100 * (e.nativeEvent.offsetX / rect.width),
            totime = audio.duration * (clickPercent * 0.01)
        rewind(totime)
    }

    className = cl(
        className,
        // { "Playlist": [variant] },
        "selectable-item",
        { "Song": [variant] },
        { "selected": selected.indexOf(id) !== -1 },
        { "highlighted": markItems.indexOf(id) !== -1 },
        { "playing": (isPlaying && trackPlaying === id) },
        { "name-fade": slider === "100%" },
    )

    return (


        <div className={ className } data-song-id={ id }>
            <header className="Song__header">
                <span className="rewind" onClick={ rewinds }></span>
                <span className={ cl("slider-1", { "d-none": slider === 0 }) } style={ { right: slider } }></span>
                <span className={ cl("slider-2", { "d-none": slider === 0 }) } style={ { right: slider } }></span>

                <div className="Songs__grid">
                    {/* <div className="number" data-song-id={ id }>
                        { turn }
                    </div> */}
                    <div className="play">
                        <span onClick={ plays }>
                            <SvgPlay className="icon-play" variant="prim" size="xs1" title="Воспроизвести" />
                        </span>
                        <span onClick={ pauses }>
                            <SvgPause className="icon-pause" variant="prim" size="xs1" title="Приостановить" />
                        </span>
                    </div>
                    <div className="composition" data-song-id={ id }>
                        {/* <span style={ { marginRight: "10px" } } onClick={ plays }>
                            <SvgPlay variant="prim" size="xs1" />
                        </span> */}
                        <h4 title={ name }>{ name.length > 36 ? `${name.substr(0, 37)}...` : name }</h4>
                    </div>
                    <div className="duration" data-song-id={ id }>
                        { cliptime.split(":").splice(1).join(":") }
                    </div>
                    <div className="temp" data-song-id={ id }>
                        { tempo }
                    </div>
                    <div className="chill_out" data-song-id={ id }>
                        { genre_name }
                    </div>
                    <div className="author" data-song-id={ id }>
                        { songauthor_name }
                    </div>
                    <div className="added" data-song-id={ id }>
                        { year }
                    </div>
                </div>
            </header>
        </div>


    )
}

const stateToProps = state => ({
    selectedSongs: [...state.public_playlists.selected],
    currentPlaylist: state.public_playlists.allPublicPlaylists.filter(cur => cur.id === state.public_playlists.selected[0])[0]
})

export default connect(stateToProps, null)(Song)
import React, {useEffect} from 'react'
import { connect } from 'react-redux'

import { MAIN_OBJECT, MAIN_ADVERT, MAIN_CONTENT } from '../../routes'

import Panel from '../../../components/Panel'
import Place from '../../../components/Panel/Place'

import Search from '../../../components/Search'
import {modeActiveOn} from "../../../store/Search/actions";


const AdvertPanel = ({
    page,
    searchState,
                         modeActiveOn
}) => {
    const disabled = isDisabled(page)
    function keyboardHandler(event) {
        const key = parseInt(event.keyCode || event.which || 0, 10);
        if ((event.ctrlKey || event.metaKey) && key === 70) {
            console.log('CTRL+F Detected!');
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
            modeActiveOn('objects');
            document.getElementById('search-input').focus();
            return false;
        }
    }
    useEffect(() => {
        window.document.addEventListener("keydown", keyboardHandler);
        //console.log('keyboardHandler started')
        return () => {
            window.document.removeEventListener("keydown", keyboardHandler);
            //console.log('keyboardHandler stopped')
        };
    }, []);
    return (
        <Panel page={ page } disabled={ disabled }>

            {/* 1 */ }

            <Place
                title="Что"
                number={ 1 }
                className={ `${(searchState.modeActive) ? "right-extend" : ""}` }
                disabled={ disabled }
                elements={ {
                    right: <Search placeholder="Поиск по объектам" searchType="objects" disabled={ disabled } />
                } }
            />

            {/* 2 */ }

            <Place
                title="Где"
                number={ 2 }
                disabled={ disabled }
            />

            {/* 3 */ }

            <Place
                title="Когда"
                number={ 3 }
                disabled={ disabled }
            />

        </Panel>
    )
}
const actionsToProps = {
    modeActiveOn
}

const stateToProps = state => ({
    searchState: state.search["objects"],
})

export default connect(stateToProps, actionsToProps)(AdvertPanel)

//
// Функции
//

function isDisabled(page) {
    return (page !== MAIN_OBJECT && page !== MAIN_ADVERT && page !== MAIN_CONTENT) ? true : false
}
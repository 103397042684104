import moment from 'moment'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { ADD_PERIOD, loadPeriods, adding } from '../../../store/Settings/actions/components/Periods'
import { closeModal } from '../../../store/Modal/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'
import { loadItems, cleanSettings  } from "../../../store/Settings/actions/sets/Object/Schedule";
import { loadItems as loadItemsBackground, cleanSettings as cleanSettingsBackground, selectPeriod } from "../../../store/Settings/actions/sets/Object/Background";

export default function* () {
    yield takeEvery(ADD_PERIOD, worker)
}

function* worker({ payload }) {
    console.log({ payload });
    const { type, period, copydate, selected } = payload

    yield put(adding({ type, adding: true }))
    const selectedObjects = yield select(state => state.objects.selected || [])
    const selectedGroups = yield select(state => state.objectsGroup.selected || [])

    try {
        period.todate = (period.todate && period.todate.length > 2) ? period.todate : period.fromdate

        period.fromdate = moment(period.fromdate).format("YYYY[-]MM[-]DD")
        period.todate = moment(period.todate).format("YYYY[-]MM[-]DD")
        if (copydate === 1) {
            period.copydate = copydate
        }
        period.ms_id = selectedGroups.length > 0 ? selectedGroups[0] : selected[0]

        if (payload.copy_ms_id) {
            period.copy_ms_id = payload.copy_ms_id;
        }

        const added = yield call(fetchAdd, type, period)

        console.log({ added });

        // Ошибка
        if (added.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${ADD_PERIOD}.`, added],
                text: "Период не был добавлен. Попробуйте заново."
            }))

            // Успех
        } else {
            yield put(loadPeriods({ type, required: true }))
            yield put(cleanSettings({ stateKey: period.ms_id }))
            yield put(cleanSettingsBackground({ stateKey: period.ms_id }))
            yield put(loadItems({ stateKey: period.ms_id, required: true }))
            yield put(loadItemsBackground({ stateKey: period.ms_id, required: true }))
            yield put(selectPeriod({ stateKey: period.ms_id, periodId: added.success.id }))
            yield put(closeModal("element"))
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${ADD_PERIOD}.`, e],
            text: "Период не был добавлен. Попробуйте заново."
        }))
    }

    finally {
        yield put(adding({ type, adding: false }))
    }
}

function fetchAdd(type, period) {
    return axios.get(`/msbox/${type}/dates/insert`, { params: { ...period } })
        .then(response => response.data)
}
import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import {ADD_VIDEO_TO_LIST, loading, loadVideolist, loadVideolists} from '../../store/ContentVideolists/actions'
import { showAlertError, showAlertSuccess } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(ADD_VIDEO_TO_LIST, worker)
}

function* worker({ payload }) {
    //yield put(loading(true))
    try {
        let videolist = payload.id_videolist
        let selected_songs = payload.selected_song
        let add_data = {
            id_videolist: videolist,
            songs: selected_songs.join(",")
        }
        const added = yield call(fetchAdd, add_data)
        if (added.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${ADD_VIDEO_TO_LIST}.`, added],
                text: "Видео не были добавлены. Попробуйте повторить."
            }))
        } else {
            yield put(showAlertSuccess({
                text: "Список успешно изменен."
            }))
            yield put(loadVideolists())
            yield put(loadVideolist(videolist))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${ADD_VIDEO_TO_LIST}.`, e],
            text: "Видео не были добавлены. Попробуйте повторить."
        }))
    }
    finally {

        //yield put(loading(false))
    }
}

function fetchAdd(addData) {
    let formData = new FormData()
    if (addData && addData.songs) {
        Object.keys(addData).forEach(name => {
            formData.set(name, addData[name])
        })
    }
    return axios(
        {
            url: `/videolist/set?id=${addData.id_videolist}`,
            method: "POST",
            data: formData,

        }
    )
        .then(response => response.data)
}
import React from 'react'

import Place from '../../../components/Space/Place'
import Menu from '../Menu'


const What = () => (
    <Place number={ 1 }>
        <Menu />
    </Place>
)

export default What
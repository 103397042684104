import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { DID_MOUNT } from '../../store/App/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import {Login} from "../../store/Login/actions";
import {loadSubusers} from "../../store/Roles/actions";
import { loadPvData } from '../../store/Features/actions'

export default function* () {
    yield takeEvery(DID_MOUNT, worker)
}

function* worker() {
    try {
        const auth = yield call(fetchAuth)
        if (auth.error) {
            localStorage.sessionToken = ""
            localStorage.removeItem('master')

            // yield put(showAlertError({
            //     errors: [`Ошибка сервера при ${DID_MOUNT}.`, auth],
            //     text: "Авторизация не пройдена. Попробуйте заново."
            // }))
        } else {
            // yield window.localStorage.setItem("sessionToken", auth.success)
            yield put(Login({ isAuthorized: true, error: '', user: auth.success }))            
            yield put(loadPvData())
            yield put(loadSubusers())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DID_MOUNT}.`, e],
            text: "Авторизация не пройдена. Попробуйте заново."
        }))
    }
}

function fetchAuth() {
    // return fetch( buildGETUrl("account/login", {name: "test11", password: "test011"}) )
    return axios.get("/account/logged")
        .then(response => response.data)
}


function fetchSubAccounts() {
    return axios.get("/account/subowner/select")
        .then(response => response.data)
}
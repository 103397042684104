import React from 'react'


const Save = ({ title = 'Сохранить', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-'+variant : ''} ${size ? 'spp-svg-size-'+size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg viewBox="0 0 512.007 512.007" xmlns="http://www.w3.org/2000/svg">
                <title>{title}</title>
                <path d="M511.927 126.537a15.028 15.028 0 00-3.315-8.027c-.747-.913 6.893 6.786-114.006-114.113A15.116 15.116 0 00383.994.003H44.999c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zM345 30.003v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113H142c-8.271 0-15-6.729-15-15v-98zm64 291H127v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92v-180c0-24.813-20.187-45-45-45H142c-24.813 0-45 20.187-45 45v180H45c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787L482 134.217z"/></svg>
        </i>
    )
}

export default Save 
import React from 'react'

import {cl} from '../../../../utilities/cl'
import { componentDisabled } from '../../../../utilities/componentDisabled'


class Tabs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: this.props.selected
        }

        this.hClick = this.hClick.bind(this)
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selected !== this.props.selected) {
            this.setState({
                selected: this.props.selected
            })
        }
    }

    hClick(event) {
        const target = event.target
        const tab = target.closest(".SettingsTabs__tab")
        const id = Number(tab.dataset.tabId)
        const onChange = this.props.onChange
        const name = this.props.name

        if(tab && !isNaN(id)) {
            if(id !== this.props.disabled) {
                this.setState({
                    selected: id
                }, () => {
                    if(typeof onChange === "function") {
                        onChange({
                            name,
                            id
                        }, event)
                    }
                })
            }
        }
    }

    render() {
        let {
            className,
            tabs = [
                {id: 2, title: "Помещение"},
                {id: 1, title: "Улица"},
            ],
            disabled,
        } = this.props
        const {
            selected
        } = this.state

        className = cl(
            className,
            "SettingsTabs"
        )

        return(
            <div className={className} onClick={this.hClick}>
                <div className="SettingsTabs__wrap">
                    {tabs.map(tab => (
                        <div key={tab.id}
                            className={`SettingsTabs__tab ${tab.id === selected ? "active" : ""} ${tab.id === disabled ? "disabled" : ""}`}
                            data-tab-id={tab.id}
                        >
                            {tab.title}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default Tabs
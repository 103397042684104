import React from 'react'

import ObjectsGroupComponent from '../../../../../components/ObjectsGroup'
import Collapsible from '../../../../../components/Space/Collapsible'


const ObjectsGroup = ({ className = "", active, placeNumber }) => {
    return(
        <>
            <Collapsible
                active={active}
                id={222}
                className={className}
                title="Группы объектов"
                placeNumber={placeNumber}
            >
                <ObjectsGroupComponent/>
            </Collapsible>
        </>
    )
}

export default ObjectsGroup
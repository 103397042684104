import {takeEvery, call, put, takeLeading} from 'redux-saga/effects'
import { LOAD_CLIP_REPORT, loading, putClipReport } from '../../../store/Reports/Advert/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeLeading(LOAD_CLIP_REPORT, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        const advert_report = yield call(fetchClipReport, payload)
        if (advert_report.error && advert_report.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_CLIP_REPORT}.`, advert_report],
                text: "Отчет не был загружен. Попробуйте перезагрузить страницу."
            }))
            yield put(putClipReport([]))
        } else {
            yield put(putClipReport({ advert_report: advert_report.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_CLIP_REPORT}.`, e],
            text: "Отчет не был загружен. Попробуйте перезагрузить страницу."
        }))
        yield put(putClipReport([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchClipReport(search) {
    //console.log(search)
    return axios.get("/msbox/report/clip", { params: { id: search.id, date: search.date } })
        .then(response => response.data)
}

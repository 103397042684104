import React from 'react'
import PropTypes from 'prop-types'
import { cl } from '../../utilities/cl'
import outside from '../../utilities/outside'
import toggleBodyScrollBar from '../../utilities/toggleBodyScrollBar'

import AdvertCreate from './types/AdvertCreate'
import Confirm from './types/Confirm'
import CreateLine from './types/CreateLine'
import Mediaplan from './types/Mediaplan'
import VideoHistory from './types/VideoHistory'
import Castinfo from './types/Castinfo'
import AdsReport from './types/Ads/Report'
import AdsCreate from './types/Ads/Create'
import AdsCreateExtra from './types/Ads/CreateExtra'

import PlaylistCreate from './types/PlaylistCreate'
import VideolistCreate from './types/VideolistCreate'

import AdsEdit from './types/Ads/Edit'
import AdsEditExtra from './types/Ads/EditExtra'
import Element from './types/Element'
import OneInput from './types/OneInput'
import ManyInputs from './types/ManyInputs'
import Video from './types/Video'
import VideoCreate from './types/VideoCreate'
import PlaylistEdit from './types/PlaylistEdit'
import VideolistEdit from './types/VideolistEdit'
import PublicSongsFilter from "./types/PublicSongsFilter"
import MobileInfoObject from './types/MobileInfoObject'
import TemplateCreate from './types/TemplateCreate'
import SettingsRole from './types/SettingsRole'
import SettingsRoleEdit from './types/SettingsRoleEdit'
import SettingsClipEdit from "./types/SettingsClipEdit";
import ObjectGroupCreate from "./types/ObjectGroupCreate";

const Modal = ({
    className = "",
    type,
    data,
    size = "md",
    opened,
    zIndex = 0,
    closeModal
}) => {

    zIndex += 10

    const hCloseModal = () => closeModal(type)

    className = cl(
        className,
        {
            "Modal": [
                size,
            ]
        },
        `Modal_type_${type}`,
        "opened"
    )
    return (
        <>
            {opened &&
                <section className={className} style={{ zIndex }}>
                    <div className="Modal__content">
                        {getComponentByType()}
                    </div>
                </section>
            }
        </>
    )

    function getComponentByType() {
        if (type === "advertCreate")
            return <AdvertCreate
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "confirm")
            return <Confirm
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "mediaplan")
            return <Mediaplan
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "videoHistory")
            return <VideoHistory
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "createLine")
            return <CreateLine
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "castinfo")
            return <Castinfo
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "adsReport")
            return <AdsReport
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "adsCopy")
            return <AdsReport
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "adsCreate")
            return <AdsCreate
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "adsCreateExtra")
            return <AdsCreateExtra
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "playlistCreate")
            return <PlaylistCreate
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "objectGroupCreate")
            return <ObjectGroupCreate
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "playlistEdit")
            return <PlaylistEdit
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "videolistCreate")
            return <VideolistCreate
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "videolistEdit")
            return <VideolistEdit
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "videoCreate")
            return <VideoCreate
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "templateCreate")
            return <TemplateCreate
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "adsEdit")
            return <AdsEdit
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "adsEditExtra")
            return <AdsEditExtra
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "element")
            return <Element
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "oneInput")
            return <OneInput
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "mobileInfoObject")
            return <MobileInfoObject
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "manyInputs")
            return <ManyInputs
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "video")
            return <Video
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "publicsongsFilter")
            return <PublicSongsFilter
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "settingsRole")
            return <SettingsRole
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "settingsRoleEdit")
            return <SettingsRoleEdit
                {...data}
                toggleOpened={hCloseModal}
            />
        if (type === "settingsClipEdit")
            return <SettingsClipEdit
                {...data}
                toggleOpened={hCloseModal}
            />
    }
}

Modal.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(["sm", "md", "lg"]),
    data: PropTypes.object,
    type: PropTypes.string,
    opened: PropTypes.bool,
    hCloseModal: PropTypes.func
}

export default Modal
import {takeEvery, call, put, select} from 'redux-saga/effects'
import { Login, DO_SUB_LOGIN, loading } from '../../store/Login/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DO_SUB_LOGIN, worker)
}

function* worker({ payload }) {
    try {
        yield put(loading(true))
        let master = yield select(state => state.settings_roles.master);
        const auth = yield call(fetchAuth, {id: payload.id, token: master.token})
        if (auth.error) {
            yield put(Login({ isAuthorized: false, error: auth.error }))
            yield put(loading(false))
        } else {
            window.localStorage.setItem("sessionToken", auth.success.token);
            if (payload.redirect) {
                window.location.href = payload.redirect; //'/main/object';
            } else {
                window.location.reload();
            }

        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DO_SUB_LOGIN}.`, e],
            text: "Авторизация не пройдена. Попробуйте заново."
        }))
    }
}

function fetchAuth(data) {
    return axios.post(`/account/subowner/login?id=${data.id}&token=${data.token}`,
        {

        })
        .then(response => response.data)
}
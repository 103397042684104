import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { CREATE_ADVERT, loading } from '../../../store/Modal/types/AdvertCreate/actions'
import { loadTracks } from '../../../store/Settings/actions/sets/Object/Schedule'
import { closeModal } from '../../../store/Modal/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(CREATE_ADVERT, worker)
}

function* worker({ payload }) {
    yield put(loading(true))

    try {
        const created = yield call(fetchCreate, payload)

        if (created.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CREATE_ADVERT}.`, created],
                text: "Объявление не было создано. Попробуйте повторить."
            }))
            yield put(closeModal("advertCreate"))

        } else {
            yield put(loadTracks({ required: true }))
            yield put(closeModal("advertCreate"))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CREATE_ADVERT}.`, e],
            text: "Объявление не было создано. Попробуйте повторить."
        }))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchCreate(advertData) {
    let formData = new FormData()
    for (let name in advertData) {
        formData.set(name, advertData[name])
    }

    return axios(
        {
            url: "/msbox/adt/insert",
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}
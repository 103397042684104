import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Spinner from '../../../../../components/Spinner'
import { loadHelpContent } from '../../../../../store/Help/actions'
import { openModal } from '../../../../../store/Modal/actions'

const HelpContent = ({
    menu,
    loadHelpContent,
    content,
    selected,
    loading_content,
    openModal
}) => {
    useEffect(() => {
        menu.map((i, index) => {
            if (index === selected) {
                loadHelpContent({ page: i.url })
            }
        })
    }, [selected])
    useEffect(() => {
        if (content.content && !loading_content) {
            let container = document.getElementById("HelpContent-parent")
            let imgs = container.getElementsByTagName("img")

            let imgs_arr = Array.from(imgs)
            console.log(imgs_arr)
            for (let i = 0; i < imgs.length; i++) {
                imgs[i].addEventListener('click', el => {
                    openModal({
                        type: "video",
                        data: {
                            video: {
                                title: "Инструкция",
                                image: imgs[i].src,
                            }
                        }
                    })
                })
            };
        }
    }, [content, loading_content])
    let body
    if (content.content) {
        body = content.content.body.data.replace('\t', '')
    }

    return (
        <div className='HelpContent-parent' id="HelpContent-parent">
            { content.content && !loading_content && <h2>{ content.content.title.data }</h2> }
            {

                content.content && !loading_content ?

                    <div dangerouslySetInnerHTML={ { __html: body } } className='HelpContent'>
                    </div>
                    :
                    <div style={ { marginTop: "20px" } }><Spinner /></div>
            }
        </div>
    )
}

const stateToProps = state => ({
    menu: state.help.help,
    content: state.help.help_content,
    selected: state.help.selected[0],
    loading_content: state.help.loading_content
})
const actionsToProps = {
    loadHelpContent,
    openModal
}
export default connect(stateToProps, actionsToProps)(HelpContent)
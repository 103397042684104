import React from 'react'


const Computer = ({ title = 'Выбрать на компьютере', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 18">
                <title>{ title }</title>
                <path d="M.646 0A.624.624 0 000 .646v13.13a.62.62 0 00.645.622l-.028.002h19.77c.325-.002.6-.269.613-.593V.647A.624.624 0 0020.354 0H.646zM1 1h19v12.4H1V1zm9.486 1.977a.5.5 0 00-.492.507v3.217H6.775a.5.5 0 100 1h3.22v3.219a.5.5 0 101 0V7.7h3.228a.5.5 0 100-1h-3.229V3.484a.5.5 0 00-.508-.507zM5.936 15.24a.5.5 0 100 1h9.117a.5.5 0 100-1H5.936z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" /></svg>
        </i>
    )
}

export default Computer
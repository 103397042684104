export const CHANGE_EXPANDED_PLACE = 'Panel/CHANGE_EXPANDED_PLACE'
export const CHANGE_ACTIVE_PLACE = 'Panel/CHANGE_ACTIVE_PLACE'

export const changeExpandedPlace = expandedNumber => ({
    type: CHANGE_EXPANDED_PLACE,
    payload: expandedNumber
})

export const changeActivePlace = activeNumber => ({
    type: CHANGE_ACTIVE_PLACE,
    payload: activeNumber
})
import React from 'react'
import PropTypes from 'prop-types'

import outside from '../../../utilities/outside'
import calcNewElementPosition from '../../../utilities/calcNewElementPosition'
import toggleBodyScrollBar from '../../../utilities/toggleBodyScrollBar'
import {MEDIA_BREAKPOINT_LG} from '../../../utilities/breakpoints'

import ScrollContainer from '../../ScrollContainer'


class Content extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            contentOpened: false,
            keyEsc: false,
            keyEnter: false,
            selected: {
                value: "",
            },
            contentPosition: {
                top: 0,
                left: 0,
            }
        }

        this.inputRef = React.createRef()
        this.contentRef = React.createRef()

        this.hClick = this.hClick.bind(this)
        this.toggleContentOpened = this.toggleContentOpened.bind(this)
        this.setValue = this.setValue.bind(this)
        this.keyReset = this.keyReset.bind(this)
        this.keyClose = this.keyClose.bind(this)
    }

    componentDidMount() {
        const position = calcNewElementPosition(this.inputRef.current, this.contentRef.current, "center")
        this.setState(state => ({
            contentPosition: {
                top: `${position.top}px`,
                left: `${position.left}px`,
                width: `${this.inputRef.current.offsetWidth}px`
            }
        }))
    }

    setValue(value) {
        this.setState(() => ({
            selected: {
                value
            }
        }), () => {
            if(typeof this.props.onSelect === "function") this.props.onSelect(this.state.selected.value)
        })
    }

    hClick() {
        this.toggleContentOpened()    
    }

    keyReset() {
        this.setState({
            keyEsc: false,
            keyEnter: false,
        })
    }

    render() {
        const {placeholder, id = "", name = "", container = true, children, disabled} = this.props
        return(
            <>
                <input
                    ref={this.inputRef}
                    className={`form-control ${this.state.selected.value === "" || this.state.selected.value === undefined ? "" : "active"}`}
                    id={id}
                    name={name}
                    onClick={this.hClick}
                    value={this.state.selected.value}
                    placeholder={placeholder}
                    type="text"
                    readonly="true"
                    disabled={disabled}
                />

                {outside(
                    <div
                        ref={this.contentRef}
                        className={`FormSelect__content mobile-modal ${!this.props.mobileModal ? "mobile-modal_disabled" : ""} ${this.state.contentOpened ? "opened" : ""}`}
                        style={{...this.state.contentPosition}}
                        onKeyDown={this.keyClose}
                    >
                        <div className="mobile-modal__content">
                            {container === true
                                ? <ScrollContainer size="md" collapsed={false}>
                                    {React.cloneElement(children, {
                                        setValue: this.setValue,
                                        toggleContentOpened: this.toggleContentOpened,
                                        opened: this.state.contentOpened,
                                        keyEsc: this.state.keyEsc,
                                        keyEnter: this.state.keyEnter,
                                        keyReset: this.keyReset,
                                    })}
                                </ScrollContainer>
                                : React.cloneElement(children, {
                                    setValue: this.setValue,
                                    toggleContentOpened: this.toggleContentOpened,
                                    opened: this.state.contentOpened,
                                    keyEsc: this.state.keyEsc,
                                    keyEnter: this.state.keyEnter,
                                    keyReset: this.keyReset,
                                })
                            }
                        </div>
                    </div>
                )}
            </>
        )
    }

    toggleContentOpened() {
        const position = calcNewElementPosition(this.inputRef.current, this.contentRef.current, "center")

        this.setState(state => {
            if(this.props.mobileModal && window.outerWidth <= MEDIA_BREAKPOINT_LG){
                toggleBodyScrollBar(!state.contentOpened)
            }
            return {
                contentOpened: !state.contentOpened,
                contentPosition: {
                    top: `${position.top}px`,
                    left: `${position.left}px`,
                    width: `${this.inputRef.current.offsetWidth}px`
                }
            }
        })

        if(!this.state.contentOpened){

            // Обработка закрытия по внешнему клику

            function closeElement(e) {
                if(e.target === this.inputRef.current){
                    return true
                }
                if(this.contentRef.current && !this.contentRef.current.contains(e.target)) {
                    this.setState({ contentOpened: false })
                    toggleBodyScrollBar(false)
                    document.removeEventListener("click", closeElement)
                }
                if(this.contentRef.current && this.contentRef.current === e.target) {
                    this.setState({ contentOpened: false })
                    toggleBodyScrollBar(false)
                    document.removeEventListener("click", closeElement)
                }
            }
            document.addEventListener("click", closeElement.bind(this))
        }
    }

    keyClose(e) {
        if(e.key === "Escape") this.setState({ keyEsc: true })
        if(e.key === "Enter") this.setState({ keyEnter: true })
    }
}

Content.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    container: PropTypes.bool,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func
}

export default Content
import {OBJECT_SCHEDULE} from '../../../sets'

export const LOAD_ITEMS                   = `Settings/${OBJECT_SCHEDULE}/LOAD_ITEMS`
export const PUT_ITEMS                    = `Settings/${OBJECT_SCHEDULE}/PUT_ITEMS`
export const LOAD_TRACKS                  = `Settings/${OBJECT_SCHEDULE}/LOAD_TRACKS`
export const PUT_TRACKS                   = `Settings/${OBJECT_SCHEDULE}/PUT_TRACKS`
export const PUT_OBJECT_TO_USES_OWN       = `Settings/${OBJECT_SCHEDULE}/PUT_OBJECT_TO_USES_OWN`
export const REMOVE_OBJECT_FROM_USES_OWN       = `Settings/${OBJECT_SCHEDULE}/REMOVE_OBJECT_FROM_USES_OWN`
export const ADD_ITEM                     = `Settings/${OBJECT_SCHEDULE}/ADD_ITEM`
export const DELETE_TRACK_FROM_POSITION   = `Settings/${OBJECT_SCHEDULE}/DELETE_TRACK_FROM_POSITION`
export const SAVE_ITEM_TRACKS_AND_DAYMASK = `Settings/${OBJECT_SCHEDULE}/SAVE_ITEM_TRACKS_AND_DAYMASK`
export const SAVE_ITEM_DAYMASK            = `Settings/${OBJECT_SCHEDULE}/SAVE_ITEM_DAYMASK`
export const DELETE_ITEMS                 = `Settings/${OBJECT_SCHEDULE}/DELETE_ITEMS`
export const SELECT_PERIOD                = `Settings/${OBJECT_SCHEDULE}/SELECT_PERIOD`
export const CLEAN_SETTINGS               = `Settings/${OBJECT_SCHEDULE}/CLEAN_SETTINGS`
export const SELECT_BLOCK                 = `Settings/${OBJECT_SCHEDULE}/SELECT_BLOCK`

export const SAVE_TRACK_FOR_ITEM          = `Settings/${OBJECT_SCHEDULE}/SAVE_TRACK_FOR_ITEM`

export const loadItems = ({ stateKey, itemIds, required }) => ({
    type: LOAD_ITEMS,
    payload: {
        stateKey,
        required,
        itemIds,
    }
})

export const putItems = ({ stateKey, periods, assocItems, tracks, blocks }) => ({
    type: PUT_ITEMS,
    payload: {
        stateKey,
        periods,
        assocItems,
        tracks,
        blocks,
    }
})

export const loadTracks = (obj) => ({
    type: LOAD_TRACKS,
    payload: {
        required: obj?.required,
    }
})

export const putTracks = ({ tracks }) => ({
    type: PUT_TRACKS,
    payload: {
        tracks,
    }
})

export const putObjectToUsesOwn = ({ objectId }) => ({
    type: PUT_OBJECT_TO_USES_OWN,
    payload: {
        objectId
    }
})

export const removeObjectFromUsesOwn = ({ objectId }) => ({
    type: REMOVE_OBJECT_FROM_USES_OWN,
    payload: {
        objectId
    }
})

export const addItem = ({ stateKey }) => ({
    type: ADD_ITEM,
    payload: {
        stateKey,
    }
})

export const deleteTrackFromPosition = ({ stateKey, side, position }) => ({
    type: DELETE_TRACK_FROM_POSITION,
    payload: {
        stateKey,
        side,
        position,
    }
})

export const saveItemTracksAndDaymask = ({ stateKey, itemId, side, trackStartId, trackStopId, daymask, __withoutAds }) => ({
    type: SAVE_ITEM_TRACKS_AND_DAYMASK,
    payload: {
        stateKey,
        itemId,
        side,
        trackStartId,
        trackStopId,
        daymask,
        __withoutAds,
    }
})

export const saveItemDaymask = ({ stateKey, itemIds }) => ({
    type: SAVE_ITEM_DAYMASK,
    payload: {
        stateKey,
        itemIds,
    }
})

export const deleteItems = ({ stateKey, itemIds = [] }) => ({
    type: DELETE_ITEMS,
    payload: {
        stateKey,
        itemIds,
    }
})

export const selectPeriod = ({ stateKey, periodId }) => ({
    type: SELECT_PERIOD,
    payload: {
        stateKey,
        periodId,
    }
})

export const cleanSettings = ({ stateKey }) => ({
    type: CLEAN_SETTINGS,
    payload: {
        stateKey,
    }
})

export const saveTrackForItem = ({ stateKey, trackId, side, position }) => ({
    type: SAVE_TRACK_FOR_ITEM,
    payload: {
        stateKey,
        trackId,
        side,
        position,
    }
})

export const selectBlock = ({ stateKey, blockNumber }) => ({
    type: SELECT_BLOCK,
    payload: {
        stateKey,
        blockNumber
    }
})
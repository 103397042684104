import React from 'react'
import { connect } from 'react-redux'

import PlaylistItem from './PlaylistItem'


const PlaylistItemContainer = props => (
    <PlaylistItem
        id={ props.id }
        name={ props.name }
        songs={ props.songs }
        selected={ props.selected }
        selected_public={ props.selected_public }
        markItems={ props.markItems }
        tags={ props.tags }
        variant={ props.variant }
    />
)

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.playlists.selected],
    selected_public: [...state.public_playlists.selected]
})

export default connect(stateToProps, null)(PlaylistItemContainer)
import React from 'react'
import moment from 'moment'

import { normalizeDate } from '../../../utilities/normalize'
import { arraysHasDifferences } from '../../../utilities/arraysHasDifferences'
import { highlightCalendar } from '../utilities/highlightCalendar'
import { compareDate } from '../utilities/compareDate'

import Single from './Single'
import DurationInput from './DurationInput'


class Between extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeCalendar: 1,
            diffDuration: "",
            disabled: [],
            left: {},
            right: {},
            noEnd: false
        }
        this.disabledDates = {
            "left": [],
            "right": []
        }

        this.picker = React.createRef()

        this.toggleActive = this.toggleActive.bind(this)
        this.changeDisplayedDates = this.changeDisplayedDates.bind(this)
        this.updateState = this.updateState.bind(this)
        this.displayDates = this.displayDates.bind(this)
        this.displayDiffDuration = this.displayDiffDuration.bind(this)
        this.selectDates = this.selectDates.bind(this)
        this.getDefaultSelected = this.getDefaultSelected.bind(this)
        this.changeSelectedDates = this.changeSelectedDates.bind(this)
        this.changeDisabledDates = this.changeDisabledDates.bind(this)
        this.onChangeDuration = this.onChangeDuration.bind(this)
    }


    componentDidMount() {

        const selected = this.getDefaultSelected()
        this.displayDates(selected)
        this.selectDates(selected)
    }

    componentDidUpdate(prevProps) {
        if (arraysHasDifferences(prevProps, this.props)) {
            const selected = this.getDefaultSelected()
            this.displayDates(selected)
            this.selectDates(selected)
        }
    }

    selectDates(selected) {
        this.updateState({
            left: { default: selected.from },
            right: { default: selected.to }
        })
    }

    changeSelectedDates(selectedDate, calendar) {
        this.updateState({
            default: selectedDate
        }, calendar)
    }

    changeDisabledDates(disabledDates, calendar) {
        this.disabledDates[calendar] = disabledDates
        this.updateState({
            disabled: [...new Set([...this.disabledDates["left"], ...this.disabledDates["right"]])]
        })
    }

    onChangeDuration(value) {
        const fromdate = this.state.left?.default
        if (typeof fromdate === "string" && fromdate.length && value > 0) {
            const todate = normalizeDate(moment(fromdate, 'YYYY-MM-DD').add((value - 1), "day").format("YYYY[-]M[-]D"))
            let continueToSave = true

            if (Array.isArray(this.state.disabled)) {
                if (this.state.disabled.indexOf(todate) !== -1) {
                    continueToSave = false
                }
            }

            if (continueToSave) {
                this.updateState({ default: todate }, "right")
                this.displayDates({
                    from: fromdate,
                    to: todate,
                    ...(this.noEnd ? { daymask: 999 } : {})

                })
            }
        }
    }

    changeDisplayedDates({ year, month, day }, calendar) {
        let nextDisplayedDates = {
            from: this.state.left.displayedDate,
            to: this.state.right.displayedDate,
        }
        if (calendar === "left") nextDisplayedDates.from = normalizeDate(moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format("YYYY[-]M[-]D"))
        if (calendar === "right") nextDisplayedDates.to = normalizeDate(moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format("YYYY[-]M[-]D"))

        if (compareDate({
            a: nextDisplayedDates.from,
            b: nextDisplayedDates.to,
        }) === "more") {
            if (calendar === "left") {
                nextDisplayedDates.to = nextDisplayedDates.from
                // nextDisplayedDates.to = normalizeDate(moment(nextDisplayedDates.from).add(1, "month").format("YYYY[-]M[-]D"))
            } else {
                nextDisplayedDates.from = nextDisplayedDates.to
                // nextDisplayedDates.from = normalizeDate(moment(nextDisplayedDates.to).subtract(1, "month").format("YYYY[-]M[-]D"))
            }
        }

        this.displayDates(nextDisplayedDates)
    }

    displayDates(selected = { from: null, to: null }) {
        let displayDates = {}

        displayDates.left =
            (selected.from && selected.from !== null)
                ? selected.from
                : normalizeDate(moment().format("YYYY[-]M[-]D"))

        displayDates.right =
            (selected.to && selected.to !== null)
                ? selected.to
                : normalizeDate(moment(displayDates.left, 'YYYY-MM-DD').add(1, "month").format("YYYY[-]M[-]D"))

        const nextState = {
            left: {
                displayedDate: displayDates.left,
                day: Number(moment(displayDates.left, 'YYYY-MM-DD').format("D")),
                month: Number(moment(displayDates.left, 'YYYY-MM-DD').format("M")),
                year: Number(moment(displayDates.left, 'YYYY-MM-DD').format("YYYY")),
            },
            right: {
                displayedDate: displayDates.right,
                day: Number(moment(displayDates.right, 'YYYY-MM-DD').format("D")),
                month: Number(moment(displayDates.right, 'YYYY-MM-DD').format("M")),
                year: Number(moment(displayDates.right, 'YYYY-MM-DD').format("YYYY")),
            }
        }

        this.updateState(nextState)
    }

    displayDiffDuration(selected) {
        if (this.state.noEnd) {
            this.setState({
                diffDuration: 999
            })
            return
        }
        if (typeof selected.from === "string" && selected.from.length && typeof selected.to === "string" && selected.to.length) {
            const a = moment(selected.from, 'YYYY-MM-DD'),
                b = moment(selected.to, 'YYYY-MM-DD')

            let different = Number(b.diff(a, 'days'))

            if (!isNaN(different)) {
                this.setState({
                    diffDuration: (Math.abs(different) + 1)
                })
            }
        } else {
            this.setState({
                diffDuration: ''
            })
        }
    }

    toggleActive(event) {
        const toggler = event.target
        if (toggler.tagName === "DIV") {
            const number = Number(toggler.dataset.calendar)
            if (number && !isNaN(number)) {
                this.setState({ activeCalendar: number })
            }
        }
    }

    toggleNoEnd = () => {
        this.onChangeDuration(this.state.diffDuration === 999 ? 28 : 999)
    };

    render() {
        const { activeCalendar, diffDuration } = this.state
        const { titles } = this.props

        return (
            <div className="FormDatepickerBetween" ref={this.picker}>

                <div className="FormDatepickerBetween__titles" onClick={this.toggleActive}>
                    <div className={activeCalendar === 1 ? "active" : ""} data-calendar="1">{titles?.from}</div>
                    <div className={activeCalendar === 2 ? "active" : ""} data-calendar="2">{titles?.to}</div>
                </div>

                <div className="FormDatepickerBetween__content">
                    <Single
                        className={activeCalendar === 1 ? "active" : ""}
                        title={titles?.from}
                        noHighlight
                        oneDate={true}
                        disabledType={this.props.disabledType}
                        disabledDates={this.props.disabledDates}
                        disabledPeriod={this.props.disabledPeriod}
                        insideHandleChangeSelected={(selectedDate) => this.changeSelectedDates(selectedDate, "left")}
                        insideHandleChangeDates={(dateObj) => this.changeDisplayedDates(dateObj, "left")}
                        insideHandleСhangeDisabledDates={(disabledDates) => this.changeDisabledDates(disabledDates, "left")}
                        {...this.state.left}
                    />
                    {this.props.durationInput === true &&
                        <DurationInput                            
                            value={diffDuration}
                            onChange={this.onChangeDuration}
                            toggleNoEnd={this.toggleNoEnd}
                        />
                    }
                    <Single
                        className={activeCalendar === 2 ? "active" : ""}
                        title={titles?.to}
                        noHighlight
                        oneDate={true}
                        disabledType={this.props.disabledType}
                        disabledDates={this.props.disabledDates}
                        disabledPeriod={this.props.disabledPeriod}
                        insideHandleChangeSelected={(selectedDate) => this.changeSelectedDates(selectedDate, "right")}
                        insideHandleChangeDates={(dateObj) => this.changeDisplayedDates(dateObj, "right")}
                        insideHandleСhangeDisabledDates={(disabledDates) => this.changeDisabledDates(disabledDates, "right")}
                        {...this.state.right}
                    />
                </div>
            </div>
        )
    }

    updateState(newParams, calendar, empty) {
        let nextState = {}

        this.setState(state => {
            if (calendar !== empty) {
                if (calendar === "left") {
                    nextState.left = {
                        ...state.left,
                        ...newParams
                    }
                }
                if (calendar === "right") {
                    nextState.right = {
                        ...state.right,
                        ...newParams
                    }
                }
            } else {
                nextState = {
                    ...state,
                    ...newParams
                }
                nextState.left = {
                    ...state.left,
                    ...newParams.left
                }
                nextState.right = {
                    ...state.right,
                    ...newParams.right
                }
            }

            return { ...nextState }
        }, () => {
            let selected = {
                from: this.state.left?.default,
                to: this.state.right?.default
            }

            if (!this.state.left?.default || this.state.left?.default === null) {
                selected.from = this.state.right?.default || null
                selected.to = null
            }
            if (!this.state.right?.default || this.state.right?.default === null) {
                selected.from = this.state.left?.default || null
                selected.to = null
            }

            highlightCalendar({
                picker: this.picker.current,
                selected: selected,
                disabled: this.state.disabled
            })

            this.displayDiffDuration(selected)

            if (typeof this.props.onChange === "function") {
                let answerDates = {}

                if (selected.from && selected.from !== null && selected.to && selected.to !== null) {
                    if (compareDate({
                        a: selected.from,
                        b: selected.to,
                    }) === "more") {
                        answerDates = {
                            from: selected.to,
                            to: selected.from,
                        }
                    } else {
                        answerDates = {
                            from: selected.from,
                            to: selected.to,
                        }
                    }
                } else {
                    if (selected.from === null) {
                        answerDates = {
                            from: "",
                            to: "",
                        }
                    } else {
                        answerDates = {
                            from: selected.from,
                            to: "",
                        }
                    }
                }

                if (typeof this.props.format === "string") {
                    if (answerDates.from.length) answerDates.from = moment(answerDates.from, 'YYYY-MM-DD').format(this.props.format)
                    if (answerDates.to.length) answerDates.to = moment(answerDates.to, 'YYYY-MM-DD').format(this.props.format)
                } else {
                    if (answerDates.from.length) answerDates.from = moment(answerDates.from, 'YYYY-MM-DD').format("DD[.]MM[.]YYYY")
                    if (answerDates.to.length) answerDates.to = moment(answerDates.to, 'YYYY-MM-DD').format("DD[.]MM[.]YYYY")
                }
                if (this.state.noEnd) {
                    answerDates.daymask = 999
                }
                this.props.onChange({ ...answerDates })
            }
        })
    }

    getDefaultSelected() {
        let defaultDates = this.props.default,
            getDefaultSelected = {
                from: null,
                to: null
            }

        if (typeof defaultDates === "string") {
            getDefaultSelected.from = normalizeDate(defaultDates)

        } else if (typeof defaultDates === "object" && !Array.isArray(defaultDates) && defaultDates !== null) {
            getDefaultSelected.from = normalizeDate(defaultDates.from)
            getDefaultSelected.to =
                defaultDates.to && defaultDates.to !== null
                    ? normalizeDate(defaultDates.to)
                    : null
        }

        return getDefaultSelected
    }
}

export default Between
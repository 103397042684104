import React from 'react'
import {connect} from 'react-redux'

import {applyPreset, resetPreset, loadPresets, updatePresetName, deletePreset} from '../../../../store/Settings/actions/components/Presets'
import {openModal} from '../../../../store/Modal/actions'

import SettingsPresets from './Presets'


const SettingsPresetsContainer = props => (
    <SettingsPresets
        className={props.className}
        type={props.type}
        stateKey={props.stateKey}
        presets={props.presets}
        openModal={props.openModal}

        applyPreset={props.applyPreset}
        resetPreset={props.resetPreset}
        loadPresets={props.loadPresets}
        updatePresetName={props.updatePresetName}
        deletePreset={props.deletePreset}
    />
)

const stateToProps = (state, props) => ({
    presets: state.settings.presets !== null  ? (state.settings.presets[props.type] || []) : null
})

const actionsToProps = {
    openModal,
    applyPreset, resetPreset, loadPresets,
    updatePresetName, deletePreset,
}

export default connect(stateToProps, actionsToProps)(SettingsPresetsContainer)
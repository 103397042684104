import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { cl } from '../../utilities/cl'
import { isMobile } from '../../utilities/media'
import { getNextSelected } from '../../utilities/getNextSelected'

import ScrollContainer from '../ScrollContainer'
import Left from './Left/Left'
import Right from './Right/Right'
import numbers from "../Settings/sets/ObjectVolume/numbers";
import { loadPlaylist } from "../../store/ContentPlaylists/actions";


const Playlists = ({
    className = "",
    types = [],

    allPlaylists,
    trackObjects,

    selectedPlaylists,
    selectedPublicPlaylists,
    //selectedTracks,

    loading,
    loading_public,
    searchActive,
    searchFounded,
    searchVal,

    loadPlaylists,
    loadPlaylist,
    loadPublicPlaylists,
    toggleSelectedPlaylist,
    toggleSelectedPublicPlaylist,
}) => {

    const [doubleView, setDoubleView] = useState(false)

    useEffect(() => {
        loadPlaylists()
        // loadPublicPlaylists()
    }, [])
    //
    // useEffect(() => {
    //     if (selectedPlaylists.length) {
    //         loadPlaylist(selectedPlaylists)
    //     }
    // }, [selectedPlaylists])

    className = cl(
        className,
        "Playlists",
        { "double": doubleView }
    )

    const hToggleSelected = (e) => {
        const playlist = e.target.closest(".Playlist")
        //return;
        if (playlist) {
            const playlistId = Number(playlist.dataset.playlistId)

            if (!isNaN(playlistId)) {
                const selected = getNextSelected({
                    multiple: true,
                    itemId: playlistId,
                    items: allPlaylists,
                    selected: selectedPlaylists,
                    ctrlKey: isMobile() || e.ctrlKey || e.metaKey,
                    shiftKey: e.shiftKey,
                })

                // console.log(selected)
                toggleSelectedPlaylist({
                    selected,
                    simple: true,
                })
                loadPlaylist(selected)
            }
        }
    }
    const isSimple = (Array.isArray(types) && types?.indexOf("simple") !== -1)

    return (
        <section className={className} onClick={() => setDoubleView(!doubleView)}>

            <Left
                playlists={allPlaylists}
                loading={loading}
                searchActive={searchActive}
                searchVal={searchVal}
                searchFounded={searchFounded}
                toggleSelected={hToggleSelected}
            />
            {!isSimple && (
                <Right
                    objects={trackObjects}
                    //selectedTracks={ selectedTracks }
                    toggleSelected={hToggleSelected}
                />
            )}

        </section>
    )
}

Playlists.propTypes = {
    types: PropTypes.arrayOf((value, key, componentName, propName) => {
        const values = ["simple"]
        if (values.indexOf(value[key]) === -1)
            return new Error(`Проп ${propName} компонента ${componentName} имеет неправильное значение`)
    })
}

export default Playlists
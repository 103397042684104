import React, {useState} from 'react'
import {connect} from 'react-redux'

import {filterByTagId} from '../../../../../store/ObjectsByTags/actions'

import ObjectsComponent from '../../../../../components/Objects'
import Collapsible from '../../../../../components/Space/Collapsible'
import Select from '../../../../../components/Form/Select'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'

import {sortObjects} from '../../../../../store/Objects/actions'

const Objects = ({
                     className = "",
                     active,
                     placeNumber,
                     tags = [],
                     filterByTagId,
                     selectedTagId,
                     sortObjects,
                 }) => {
    let sorted_tags = Array.from(tags).sort(function (a, b) {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        // a должно быть равным b
        return 0;
    })
    const hSelectTag = ({value}) => filterByTagId({tagId: value})
    const [sort_direction, setSortDirection] = useState("")
    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortObjects({direction: sort_direction, sort_type: type})
    }
    let link = window.localStorage.getItem("sessionLink")
    return (
        <>
            <Collapsible
                className={`PartObjects ${className}`}
                active={active}
                id={111}
                placeNumber={placeNumber}
                title="Объекты"
                collapsible={true}
                elements={
                    <>
                        <Select
                            type="options"
                            selected={selectedTagId}
                            placeholder="Фильтрация по меткам"
                            options={[
                                {title: "Не фильтровать", value: -1},
                                ...sorted_tags
                            ]}
                            onChange={hSelectTag}
                        />
                        {link !== null && link !== "" ? <button className="btn Object-report_menu-control-btn"
                                                                onClick={() => window.open(link, "Контроль")}>Контроль</button> : ""}
                    </>
                }
            >
                <div className="ObjectsState">
                    <header className="ObjectsState__control">
                        <div className="ObjectsState__grid">
                            <div className="id">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("name")}
                                >
                                    <SvgArrowUpDown className="spp-svg" onClick={() => sort("name")}/>
                                    ОБЪЕКТЫ
                                </button>
                            </div>
                        </div>
                    </header>
                </div>
                <ObjectsComponent types={["simple"]}/>
            </Collapsible>
        </>
    )
}

const stateToProps = state => ({
    tags: [...state.objectsByTags.tags],
    selectedTagId: state.objectsByTags.filteredTagId,
})

const actionsToProps = {
    filterByTagId,
    sortObjects
}

export default connect(stateToProps, actionsToProps)(Objects)
import { takeEvery, call, put, select } from 'redux-saga/effects'
import { TOGGLE_HIDE_ENDED, TOGGLE_SELECTED_TRACK, toggleSelectedTrack } from '../../store/Tracks/actions'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { showAlertError } from '../../store/Alert/actions'
import { LOAD_INDENT_OBJECTS, putIndentObjects } from '../../store/Objects/actions'
import axios from 'axios'
import { generateStateKey } from "../../utilities/generateStateKey";
import { putClips, putClipsAll } from "../../store/Settings/actions/components/Clips";
import moment from "moment";
import { formatMinutesToTime } from "../../components/Settings/utilities/formatTime";
import { objectSort } from './loadObjects'

export default function* () {
    yield takeEvery(LOAD_INDENT_OBJECTS, worker)
    yield takeEvery(TOGGLE_SELECTED_TRACK, worker)
    yield takeEvery(TOGGLE_HIDE_ENDED, worker)
}

function* worker({ type, payload }) {


    //Отловим нажатие показать/скрыть завершённые
    if (type === TOGGLE_HIDE_ENDED) {

        //Если показать, то нам не интересно
        if (!payload)
            return

        //Если скрыть, то убирам из выбранных завершённые
        try {
            const selectedIndents = yield select(state => state.tracks.selected)

            if (!!!selectedIndents.length)
                return;

            const selectedIndentsCopy = [...selectedIndents];
            const allTracks = yield select(state => state.tracks.tracks)


            allTracks.forEach(track => {
                const selectedTrackIndex = selectedIndentsCopy.indexOf(track.id);
                if (track.clipEnded && selectedTrackIndex !== -1) {
                    delete selectedIndentsCopy[selectedTrackIndex];
                }
            })

            //Если если таковые оказались, уберём их из выбранных
            if (selectedIndentsCopy.length !== selectedIndents.length)
                yield put(toggleSelectedTrack({ ids: selectedIndentsCopy }))

        } catch (e) {

        }


        return;
    }

    try {
        const allObjects = yield select(state => state.objects.allObjects)
        const selectedIndents = yield select(state => state.tracks.selected)



        const clips =
            (Array.isArray(selectedIndents) && selectedIndents[0])
                ? yield call(fetchClips, selectedIndents)
                : {}

        if (clips.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_INDENT_OBJECTS}.`, clips],
                text: "Объекты не были загружены. Попробуйте повторить."
            }))
            yield put(putIndentObjects([]))

        } else {
            if (clips.success) {
                let indentObjectsIds = []
                let indexedByIndentMSID = {}
                clips.success.map(clip => {
                    if (!(clip.deleted && clip.deleted.length) && indentObjectsIds.indexOf(clip.ms_id) === -1)
                        indentObjectsIds.push(clip.ms_id)

                    if (!(clip.deleted && clip.deleted.length)) {
                        const stateKey = generateStateKey([clip.indent_id, clip.ms_id])
                        if (!indexedByIndentMSID[stateKey]) {
                            indexedByIndentMSID[stateKey] = {}
                        }
                        let id = `${clip.fromdate}-${clip.todate}-${clip.channel}`;
                        if (clip.mark) {
                            id = clip.mark;
                        }
                        if (!indexedByIndentMSID[stateKey][id]) {
                            indexedByIndentMSID[stateKey][id] = []
                        }
                        indexedByIndentMSID[stateKey][id].push(clip)
                    }
                })
                let schedulesAll = {}
                for (let stateKey in indexedByIndentMSID) {
                    const sorted = indexedByIndentMSID[stateKey]
                    let schedule = []
                    for (let key in sorted) {
                        const block = String(sorted[key].map(clip => clip.block).filter((v, i, a) => a.indexOf(v) === i).sort().join(','));
                        const block2 = sorted[key].map(clip => clip.block).filter((v, i, a) => a.indexOf(v) === i).sort();
                        const fromtimes = sorted[key].map(clip => clip.fromtime).filter((v, i, a) => a.indexOf(v) === i).sort();
                        const totimes = sorted[key].map(clip => clip.totime).filter((v, i, a) => a.indexOf(v) === i).sort();
                        const fromdates = sorted[key].map(clip => clip.fromdate).filter((v, i, a) => a.indexOf(v) === i).sort();
                        const todates = sorted[key].map(clip => clip.todate).filter((v, i, a) => a.indexOf(v) === i).sort();
                        const blockText = sorted[key].map(clip => clip.block).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => Number(b) > Number(a) ? -1 : 1).map(d => (d - 1) * 5);
                        const calcTimes = getTimes(sorted[key], fromtimes, totimes);
                        const channel = sorted[key][0].channel;
                        schedule.push({
                            id: key,
                            id2: `${fromdates[0]}-${todates[todates.length - 1]}-${channel}-${calcTimes.fromtime}-${calcTimes.totime}-${block}`,
                            indent_ids: [],
                            fromdate: fromdates[0],
                            fromdates,
                            daymask: sorted[key][0].daymask,
                            block,
                            block2,
                            blockText,
                            fromtimes,
                            totimes,
                            __inday: sorted[key][0].daymask === 85 && !!sorted[key][0].mark,
                            ms_id: sorted[key][0].ms_id,
                            indent_id: sorted[key][0].indent_id,
                            todate: todates[todates.length - 1],
                            todates,
                            channel,
                            mark: sorted[key][0].mark,
                            ...calcTimes,
                        })
                    }
                    // console.log(stateKey, schedule)
                    schedulesAll[stateKey] = schedule
                }

                let indentObjects = []
                allObjects.map(object => {
                    if (indentObjectsIds.indexOf(object.id) !== -1)
                        indentObjects.push(object)
                })
                let statusList = yield select(state => state.objects.statusList)
                const statusObject = statusList.reduce((acc, current) => {
                    acc[current.id] = current.name;
                    return acc;
                }, {})

                let objectsSorted = objectSort(indentObjects, statusObject)


                yield put(putIndentObjects(objectsSorted))
                yield put(putClipsAll({
                    clips: schedulesAll
                }))
            } else {
                yield put(putIndentObjects([]))
            }
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_INDENT_OBJECTS}.`, e],
            text: "Объекты не были загружены. Попробуйте повторить."
        }))
        yield put(putIndentObjects([]))
    }
}

function fetchClips(indentId) {
    return axios.get("/timeline/clip/select", { params: { indent_id: indentId } })
        .then(response => response.data)
}

function getTimes(items, fromtimes, totimes) {
    let from = fromtimes[0],
        to = totimes[totimes.length - 1],
        steptime = '', stepTimeText = '';
    if (typeof fromtimes[1] !== 'undefined' && fromtimes[0] === totimes[0]) {
        let fr1 = moment.duration(fromtimes[1]).asMinutes()
        let fr0 = moment.duration(fromtimes[0]).asMinutes()
        let diff = fr1 - fr0;
        if (diff > 60) {
            steptime = diff;
            stepTimeText = formatMinutesToTime(steptime);
        }
    }
    return {
        fromtime: from,
        totime: to,
        steptime,
        stepTimeText
    }
}
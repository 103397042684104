import {takeEvery, select, put} from 'redux-saga/effects'
import {MOVE_USED_ITEM_TO, putMoveUsedItemTo, saveUsedItemMoveChanging} from '../../../store/Settings/actions/components/BackgroundLists'
import {showAlertError} from '../../../store/Alert/actions'


export default function* () {
    yield takeEvery(MOVE_USED_ITEM_TO, worker)
}

function* worker({ payload }) {
    const { stateKey, usedId, to } = payload

    // Успех
    try {
        if(stateKey && typeof usedId === "number") {
            let usedList = yield select(state => state.settings.backgroundLists[stateKey]?.list || []),
                order    = yield select(state => state.settings.backgroundLists[stateKey]?.usedOrder[usedId])

            let movefrom, moveto

            order--

            // Точная позиция
            if(typeof to === "number") {
                movefrom = order
                moveto = to

            // Направление
            } else if(to === "up" || to === "down") {
                movefrom = order
                moveto = (to === "up") ? order-1 : order+1
            }

            if(moveto >= 0 && moveto < usedList.length) {
                arrayMoveTo({
                    usedList,
                    movefrom,
                    moveto,
                })

                let usedOrder = {}

                usedList.map((item, index) => {
                    usedOrder[item.id] = ++index
                })
    
                yield put(putMoveUsedItemTo({
                    stateKey,
                    list: usedList,
                    usedOrder,
                }))
                yield put(saveUsedItemMoveChanging({
                    stateKey,
                    usedId,
                    order: moveto+1
                }))
            }
        }
    }

    // Ошибка
    catch(e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${MOVE_USED_ITEM_TO}.`, e],
            text: "При перемещении возникла ошибка. Попробуйте повторить."
        }))
    }
}

function arrayMoveTo({ usedList, movefrom, moveto }) {
    if (moveto >= usedList.length) {
        let k = moveto - usedList.length + 1
        while (k--) {
            usedList.push(undefined)
        }
    }
    usedList.splice(moveto, 0, usedList.splice(movefrom, 1)[0])
}
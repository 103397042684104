import {
    LOAD_OBJECTS,
    SORT_OBJECTS,
    LOADING,
    PUT_OBJECTS,
    PUT_OBJECTS_ASSOCIATED,
    PUT_INDENT_OBJECTS,
    TOGGLE_SELECTED_OBJECT,
    PUT_OBJECT_STATUS_LIST
} from './actions'

const inititalState = {
    loading: false,
    allObjects: [],
    statusList: [],
    statusObject: [],
    allObjectsAssociated: [],
    trackObjects: [],
    selected: []
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {

        case LOAD_OBJECTS:
            return {
                ...state,
                allObjects: [],
                selected: [...state.selected]
            }

        case LOADING:
            return {
                ...state,
                loading: payload
            }

        case PUT_OBJECTS:
            return {
                ...state,
                allObjects: payload
            }

        case PUT_OBJECTS_ASSOCIATED:
            return {
                ...state,
                allObjectsAssociated: payload
            }
        case SORT_OBJECTS:
            if (payload.sort_type === "name") {
                return {
                    ...state,
                    allObjects:
                        Array.from(state.allObjects).sort((a, b) => {
                            if (a.name < b.name) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.name > b.name) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }

            }

        case PUT_INDENT_OBJECTS:
            return {
                ...state,
                trackObjects: payload 
                // TASK_20
                // trackObjects: payload.sort((a, b) => {
                //     const nameA = a.name.toUpperCase();
                //     const nameB = b.name.toUpperCase();
                //     if (nameA < nameB) {
                //         return -1;
                //     }
                //     if (nameA > nameB) {
                //         return 1;
                //     }
                //     return 0;
                // })
            }

        case PUT_OBJECT_STATUS_LIST:
            return {
                ...state,
                statusList: payload,
                statusObject: payload.reduce((acc, current) => {
                    acc[current.id] = current.name;
                    return acc;
                }, {}),
            }

        // case TOGGLE_SELECTED_OBJECTS:
        //     return {
        //         ...state,
        //         selected: payload.selected
        //     }

        case TOGGLE_SELECTED_OBJECT:
            const { id, selected, simple, required } = payload
            console.log({payload});
            if (simple === true) {
                return {
                    ...state,
                    selected: Array.isArray(selected)
                    ? selected
                    : [],
                }
            } else if (required === true) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected
                }
            } else if (required === false) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) !== -1)
                            ? state.selected.filter(objectId => objectId !== id)
                            : state.selected
                }
            } else {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected.filter(objectId => objectId !== id)
                }
            }
    }
    return state
}

export default reducer
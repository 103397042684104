import { takeEvery, select, put, call, all } from 'redux-saga/effects';
import { ADD_TO_USED, loadUsedList, mode } from '../../../store/Settings/actions/components/BackgroundLists';
import { showAlertError } from '../../../store/Alert/actions';
import axios from 'axios';
import { loadObjects } from "../../../store/Objects/actions";

export default function* () {
    yield takeEvery(ADD_TO_USED, worker);
}

function* worker({ payload }) {
    const { stateKey, timelineId } = payload;

    try {
        const availSelected = yield select(state => state.settings.backgroundLists?.avail?.selected || []);

        if (typeof timelineId === "number" && availSelected.length) {
            yield put(mode({
                stateKey: "avail",
                modeType: "__addingToUsed",
                mode: true,
            }));


            const addedToUsed = [];
            
            for (const listId of availSelected) {
                const res = yield call(fetchAddToUsed, timelineId, listId);
                addedToUsed.push(res);
            }

            
            let error = false;
            addedToUsed.forEach(req => {
                if (req.error) error = req.error;
            });

            // Ошибка
            if (error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${ADD_TO_USED}.`, addedToUsed],
                    text: "Не все списки были добавлены в использованные." + error
                }));
            } else {
                // Успех
                yield put(loadUsedList({
                    timelineId,
                    required: true,
                }));
                yield put(loadObjects());
            }
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${ADD_TO_USED}.`, e],
            text: "Не все списки были добавлены в используемые. Попробуйте повторить."
        }));
    } finally {
        yield put(mode({
            stateKey: "avail",
            modeType: "__addingToUsed",
            mode: false,
        }));
    }
}

async function fetchAddToUsed(playtimeline_id, playlist_id) {
    try {
        const response = await axios.get("/msbox/play/list/insert", { params: { playtimeline_id, playlist_id } });
        return response.data;
    } catch (error) {
        return { error };
    }
}

import React from 'react'

import {cl} from '../../../../../../utilities/cl'
import {isFunc} from '../../../../../../utilities/isFunc'
import {mediaTabletAndUp} from '../../../../../../utilities/media'
import {getNextSelected} from '../../../../../../utilities/getNextSelected'
// import {handObj, actions} from '../../../../../../utilities/handlers'

import modalEditItem from './modals/editItem'
import modalDeleteItem from './modals/deleteItem'

import StringDays from '../../../../../StringDays'
import Group from '../../../../components/Group/Group'

import List from '../../../../../List'
import Button from '../../../../../Button'
import SvgSave from '../../../../../SVG/Save'
import SvgTrash from '../../../../../SVG/Trash'


const GroupBlocksList = ({
    className = "",
    title,
    stateKey,
    type,

    items = [],
    selected = [],

    loading,
    saving,
    deleting,
    
    saveItems,
    deleteItems,
    toggleSelectedItems,
    openModal,
    closeModal,
}) => {

    className = cl(
        className,
        "ObjectBackgroundGroupBlocksList"
    )

    const hToggleSelectedItems = ({ id, items }, event) => {
        if(isFunc(toggleSelectedItems)) {
            selected = getNextSelected({
                multiple: true,
                itemId: id,
                selected,
                ctrlKey: event.ctrlKey || event.metaKey,
                shiftKey: event.shiftKey,
                items,
            })
        
            toggleSelectedItems({
                type,
                stateKey,
                itemIds: selected,
            })
        }
    }

    const hSaveItems = () => saveItems({ stateKey })
    const hDeleteItems = () => deleteItems({ stateKey })

    const hModalEditItem = ({ id }) => modalEditItem({
        stateKey,
        itemId: id,
        saveItems,
        openModal,
        closeModal,
    })
    const hModalDeleteItem = ({ id }) => modalDeleteItem({
        stateKey,
        itemId: id,
        deleteItems,
        openModal,
        closeModal,
    })
    
    return(
        <Group className={className} title={title} noDivider>
            <div>
                <List
                    type="simple"
                    header={mediaTabletAndUp({
                        size: "sm",
                        left: <span>Выберите временной диапазон проигрывания</span>,
                        right: <>
                            <Button
                                type="string"
                                disabled={saving || !(selected.length)}
                                onClick={hSaveItems}
                            >
                                <SvgSave size="sm1" variant="dark"/>
                            </Button>
                            <Button
                                type="string"
                                disabled={deleting || !(selected.length)}
                                onClick={hDeleteItems}
                            >
                                <SvgTrash size="sm1" variant="dark"/>
                            </Button>
                        </>,
                    })}
                    content={{
                        items: getList()
                    }}
                    selected={selected}
                    onClick={hToggleSelectedItems}
                    loading={loading}
                    buttons={{
                        edit: {
                            on: true,
                            mobile: true,
                            onClick: hModalEditItem,
                        },
                        remove: {
                            on: true,
                            mobile: true,
                            onClick: hModalDeleteItem,
                        },
                    }}
                />
            </div>
        </Group>
    )

    function getList() {
        let list = []
        items.map(item => {
            // item = handObj({
            //     obj: item,
            //     type: OBJECT_BACKGROUND,
            //     action: actions.away,
            // })

            list.push({
                id: item.id,
                element:
                    <div className="ObjectBackgroundGroupBlocksList__item">
                        <div className="time">
                            {formatTimeToRender(item.starttime)}
                        </div>
                        <StringDays
                            daymask={item.daymask}
                        />
                    </div>
            })
        })
        return list
    }
}

export default GroupBlocksList

//
// funcs
//

function formatTimeToRender(time = "") {
    return time.slice(0, 5)
}
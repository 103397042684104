import React from 'react'
import PropTypes from 'prop-types'

import InputText from '../InputText'


const InputNumber = props => {

    const numberValidate = (value) => {
        let test = value

        if(Array.isArray(props.masks)) {
            props.masks.map(mask => 
                test = test.replace(mask, "")
            )
        }
        
        test = Number(test)

        if(isNaN(test)) return false

        const valueNumber = Number(value)
        value = isNaN(valueNumber) ? value : valueNumber

        if(typeof props.validate === "function") {
            return props.validate(value)
        }
        return true
    }

    return(
        <InputText
            className={`FormInputNumber ${props.className || ""}`}
            label={props.label}
            comment={props.comment}
            attrs={props.attrs}
            onChange={props.onChange}
            createRef={props.createRef}
            validate={numberValidate}
        />
    )
}

InputNumber.propTypes = {
    masks: PropTypes.array
}

export default InputNumber
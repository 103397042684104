import React from 'react'
import Navbar from '../../components/Navbar'
import Workspace from './Workspace'
import Panel from './Panel'


const Advert = props => {
    return (
        <section className="Advert">
            <Navbar page={ props.match.path } />
            <Panel page={ props.match.path } />
            <Workspace page={ props.match.path } />
        </section>
    )
}

export default Advert
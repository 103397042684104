export const DELETE_VIDEOLISTS_ALL = "VideolistsAll/DELETE_VIDEOLISTS_ALL"
export const LOAD_VIDEOLISTS_ALL = "VideolistsAll/LOAD_VIDEOLISTS_ALL"
export const LOAD_VIDEOLISTS_ALL_BY_ID = "VideolistsAll/LOAD_VIDEOLISTS_ALL_BY_ID"
export const LOADING = "VideolistsAll/LOADING"
export const PUT_VIDEOLISTS_ALL = "VideolistsAll/PUT_VIDEOLISTS_ALL"
export const PUT_VIDEOLISTS_ALL_SONGS = "VideolistsAll/PUT_VIDEOLISTS_ALL_SONGS"
export const TOGGLE_SELECTED_VIDEOLIST_ALL = "VideolistsAll/TOGGLE_SELECTED_VIDEOLIST_ALL"
export const TOGGLE_SELECTED_VIDEO_ALL = "VideolistsAll/TOGGLE_SELECTED_VIDEO_ALL"
export const SORT_ALL_VIDEOLISTS = "VideolistsAll/SORT_ALL_VIDEOLISTS"
export const SORT_ALL_VIDEOS = "VideolistsAll/SORT_ALL_VIDEOS"
export const COPY_VIDEO = "VideolistsAll/COPY_VIDEO"
export const COPY_VIDEO_TO_EXTRA = "VideolistsAll/COPY_VIDEO_TO_EXTRA"

export const addVideoToAdvert = payload => ({
    type: COPY_VIDEO,
    payload
})

export const addVideoToExtra = payload => ({
    type: COPY_VIDEO_TO_EXTRA,
    payload
})


export const loadVideolistsAll = () => ({
    type: LOAD_VIDEOLISTS_ALL,
})

export const loadVideolistsAllById = (id) => ({
    type: LOAD_VIDEOLISTS_ALL_BY_ID,
    payload: id
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const deleteVideolistsAll = selectedVideolists => ({
    type: DELETE_VIDEOLISTS_ALL,
    payload: selectedVideolists
})
export const putVideolistsAll = videolists => ({
    type: PUT_VIDEOLISTS_ALL,
    payload: videolists
})

export const putVideolistsAllSongs = songs => ({
    type: PUT_VIDEOLISTS_ALL_SONGS,
    payload: songs
})

export const toggleSelectedVideolistAll = ({ id, selected, simple, required }) => ({
    type: TOGGLE_SELECTED_VIDEOLIST_ALL,
    payload: {
        id,
        selected,
        simple,
        required,
    }
})

export const toggleSelectedVideoAll = ({ id_video, selected_video, onlyOne, setAll }) => ({
    type: TOGGLE_SELECTED_VIDEO_ALL,
    payload: {
        id_video,
        setAll,
        selected_video,
        onlyOne
    }
})
export const sortAllVideolists = sortSource => ({
    type: SORT_ALL_VIDEOLISTS,
    payload: sortSource
})
export const sortAllVideos = sortSource => ({
    type: SORT_ALL_VIDEOS,
    payload: sortSource
})
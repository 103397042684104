import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Button as ButtonBS} from 'react-bootstrap'

import {saveItemTracksAndDaymask} from '../../../../../../../store/Settings/actions/sets/Object/Schedule'

import {OBJECT_SCHEDULE} from '../../../../../../../store/Settings/sets'

import ChangeTrack from '../../../components/ChangeTrack'
import SettingsDays from '../../../../../components/Days'
import Select from '../../../../../../Form/Select'
import SettingsWithoutAds from '../../../../../components/WithoutAds'


const ModalContent = ({
    stateKey,
    itemId,
    side,
    daymask,
    withoutAds,

    saving,

    saveItemTracksAndDaymask,
}) => {

    const [trackStartId, setTrackStartId] = useState()
    const [trackStopId, setTrackStopId] = useState()
    const [nextDaymask, setDaymask] = useState(daymask)
    const [nextWithoutAds, setWithoutAds] = useState(withoutAds)

    const hChangeTrackStartId = ({ value }) => setTrackStartId(value)
    const hChangeTrackStopId  = ({ value }) => setTrackStopId(value)
    const hChangeDaymask      = ({ value }) => setDaymask(value)
    const hChangeWithoutAds   = ({ value }) => setWithoutAds(value)

    const hSave = () => saveItemTracksAndDaymask({
        stateKey,
        itemId,
        side,
        trackStartId,
        trackStopId,
        daymask: nextDaymask,
        __withoutAds: nextWithoutAds,
    })

    return(
        <>
            <ChangeTrack
                label="Объявление: начало"
                stateKey={stateKey}
                itemId={itemId}
                side={side}
                position="start"
                onChange={hChangeTrackStartId}
            />

            <div className="pt-md"></div>

            <ChangeTrack
                label="Объявление: завершение"
                stateKey={stateKey}
                itemId={itemId}
                side={side}
                position="stop"
                onChange={hChangeTrackStopId}
            />

            <div className="pt-md"></div>

            <SettingsDays
                value={nextDaymask}
                onChange={hChangeDaymask}
            />

            <div className="pt-md"></div>

            <SettingsWithoutAds
                value={nextWithoutAds}
                onChange={hChangeWithoutAds}
            />
            
            <div className="Modal__footer">
                <ButtonBS variant="prim" onClick={hSave} disabled={saving}>
                    {saving ? "Сохранение..." : "Сохранить"}
                </ButtonBS>
            </div>
        </>
    )
}

const stateToProps = (state, props) => ({
    saving: state.settings.settings[OBJECT_SCHEDULE][props.stateKey]?.__saving
})

const actionsToProps = {
    saveItemTracksAndDaymask
}

const ModalContentContainer = connect(stateToProps, actionsToProps)(ModalContent)


const editItem = ({
    stateKey,
    itemId,
    side,
    daymask,
    withoutAds,
    
    openModal,
}) => {

    openModal({
        type: "element",
        size: "sm",
        data: {
            title: "Редактирование расписания",
            element:
                <ModalContentContainer
                    stateKey={stateKey}
                    itemId={itemId}
                    side={side}
                    daymask={daymask}
                    withoutAds={withoutAds}
                />
        }
    })
}

export default editItem
import { takeEvery, put, call } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { DELETE_PRESET, loadPresets } from '../../../store/Settings/actions/components/Presets'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_PRESET, worker)
}

function* worker({ payload }) {
    try {
        const deleted = yield call(fetchDelete, payload)

        if (deleted.error && deleted.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${DELETE_PRESET}.`, deleted],
                text: "Пресет не был удалён. Попробуйте повторить."
            }))
        } else {
            yield put(loadPresets())
        }
    }

    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_PRESET}.`, e],
            text: "Пресет не был удалён. Попробуйте повторить."
        }))
    }
}

function fetchDelete(id) {
    return axios.get("/preset/delete", { params: { id } })
        .then(response => response.data)
}
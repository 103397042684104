export function formatSecondsToTime(seconds, empty) {
    let time = "00:00"

    if (seconds === empty) return time

    seconds = Number(seconds)

    if (!isNaN(seconds)) {
        let t = Math.floor(seconds),
            min = Math.floor(t / 60),
            sec = Math.floor(t - min * 60),
            formatMin = (min < 10) ? `0${min}` : min,
            formatSec = (sec < 10) ? `0${sec}` : sec

        time = `${formatMin}:${formatSec}`
    }

    return time
}

export function formatTimeToSeconds(time, empty) {
    let seconds = 0

    if (time !== "" && time !== empty) {
        let arrTimes = time.split(":")
        let hours = 0
        let min = Number(arrTimes[0])
        let sec = Number(arrTimes[1])
        if (arrTimes.length >= 3) {
            hours = Number(arrTimes[0])
            min = Number(arrTimes[1])
            sec = Number(arrTimes[2])
        }
        if (!isNaN(min) && !isNaN(sec)) {
            seconds = min * 60
            seconds += sec
        }
    }
    return seconds
}

export function formatTimeToRender(time = "") {
    return time.slice(0, 5)
}
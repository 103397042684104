export const REPORT = "/report"
export const SETTINGS = "/settings"
export const HELP = "/help"
export const LOGIN = "/login"
export const MAIN = "/main"

export const MAIN_OBJECT = "/main/object"
export const MAIN_ADVERT = "/main/advert"
export const MAIN_CONTENT = "/main/content"

export const SETTINGS_TEMPLATES = "/settings/templates"
export const SETTINGS_NOTIFICATIONS = "/settings/notifications"
export const SETTINGS_ROLES = "/settings/roles"
export const SETTINGS_INTERFACE = "/settings/interface"
export const SETTINGS_FEATURES_OF_THE_ETHER = "/settings/features"


export const OLD_LK = "https://t.ms-box.ru/"


export default function({
    settings = {},
    name,
    onChange
}) {

    return [
        {
            type: "Switch",
            data: {
                value: settings[name] || false,
                onChange,
            }
        }
    ]
}
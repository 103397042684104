import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { generateStateKey } from '../../../../../utilities/generateStateKey'
import { cl } from '../../../../../utilities/cl'

import { OBJECT_OBJECT } from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'
import ButtonCircle from '../../../../../components/ButtonCircle'
import SVGPlus from '../../../../../components/SVG/Plus'
import InputText from '../../../../../components/Form/InputText'
import { Button as ButtonBS } from 'react-bootstrap'
import Button from "../../../../../components/Button"
import SVGTrash from '../../../../../components/SVG/Trash'
import Tags from '../../../../../components/Tags'
import { loadNotifications, updateNotifications } from '../../../../../store/Notifications/actions'
import Spinner from '../../../../../components/Spinner'

const SettingsNotifications = ({
    className = "",
    loadNotifications,
    notifyto1 = "",
    notifyto2 = "",
    loading,
    notifications = {},
    updateNotifications
}) => {
    useEffect(() => {
        loadNotifications()
    }, [])
    const [email_warn, setEmailWarn] = useState("")
    const [email_notify, setEmailNotify] = useState("")


    className = cl(
        className,
        "SettingsObjectObjectCollapsible"
    )
    const hChangeEmail = (e) => {
        if (e.name === "email_warn") {
            setEmailWarn(e.value)
        }
        if (e.name === "email_notify") {
            setEmailNotify(e.value)
        }
    }

    const hDeleteNotify = (e, type) => {
        if (type === "warn") {
            let email_string
            let email_arr = notifyto1.split(',')
            email_arr.splice(e.id, 1)
            email_string = email_arr.join(',')
            updateNotifications({ notifyto1: email_string })
            setEmailWarn('')
        } else if (type === "notify") {
            let email_string
            let email_arr = notifyto2.split(',')
            email_arr.splice(e.id, 1)
            email_string = email_arr.join(',')
            updateNotifications({ notifyto2: email_string })
            setEmailNotify('')
        }
    }

    const hUpdateNotify = (type) => {
        if (type === "warn") {
            let email_string
            let email_arr = []
            if (notifyto1 !== "" && notifyto1 !== null) {
                email_arr = notifyto1.split(',')
                email_arr.push(email_warn)
                email_string = email_arr.join(',')
            } else {
                email_string = email_warn
            }
            updateNotifications({ notifyto1: email_string })
            setEmailWarn('')
        } else if (type === "notify") {
            let email_string
            let email_arr = []
            if (notifyto2 !== "" && notifyto2 !== null) {
                email_arr = notifyto2.split(',')
                email_arr.push(email_notify)
                email_string = email_arr.join(',')
            } else {
                email_string = email_notify
            }
            updateNotifications({ notifyto2: email_string })
            setEmailNotify('')
        }

    }
    return (
        <div>
            <h2>НАСТРОЙКА АДРЕСОВ И УВЕДОМЛЕНИЙ</h2>

            { !loading && (Object.values(notifications).length > 0 || Object.values(notifications).length === 0) ?
                <div className="Notifications">
                    <div className="Notifications_settings_mail">
                        <div className="Notifications_settings_mail_element">
                            <div className='Notifications_settings_mail_element_tags'>
                                <h4>Предупреждение о сбое синхронизации плеера:</h4>
                                <div className='Notifications_settings_mail_element_tags_items'>
                                    <Tags
                                        tags={ notifyto1 !== "" && notifyto1 !== null && [...notifyto1.split(',').map((email, index) => ({ name: email, id: index }))] }
                                        onDelete={ (e) => hDeleteNotify(e, "warn") }
                                    />
                                </div>
                            </div>
                            <div className='Notifications_settings_mail_element_add'>
                                <InputText
                                    label="E-MAIL"
                                    attrs={ {
                                        name: "email_warn",
                                        value: email_warn,
                                    } }
                                    onChange={ hChangeEmail }
                                />
                                <ButtonCircle
                                    disabled={ email_warn !== "" ? false : true }
                                    onClick={ () => hUpdateNotify("warn") }
                                >
                                    <SVGPlus />
                                </ButtonCircle>
                            </div>
                        </div>
                        <div className="Notifications_settings_mail_element">
                            <div className='Notifications_settings_mail_element_tags'>
                                <h4>Уведомление о завершении трансляции рекламного материала:</h4>
                                <div className='Notifications_settings_mail_element_tags_items'>
                                    <Tags
                                        tags={ notifyto2 !== "" && notifyto2 !== null && [...notifyto2.split(',').map((email, index) => ({ name: email, id: index }))] }
                                        onDelete={ (e) => hDeleteNotify(e, "notify") }
                                    />
                                </div>
                            </div>
                            <div className='Notifications_settings_mail_element_add'>
                                <InputText
                                    label="E-MAIL"
                                    attrs={ {
                                        name: "email_notify",
                                        value: email_notify,
                                    } }
                                    onChange={ hChangeEmail }
                                ></InputText>
                                <ButtonCircle
                                    disabled={ email_notify !== "" ? false : true }
                                    onClick={ () => hUpdateNotify("notify") }
                                >
                                    <SVGPlus />
                                </ButtonCircle>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Spinner />
            }
        </div>
    )
}

const stateToProps = state => ({
    notifyto1: state.settings_notifications.notifications?.notifyto1,
    notifyto2: state.settings_notifications.notifications?.notifyto2,
    loading: state.settings_notifications.loading,
    notifications: state.settings_notifications.notifications
})

const actionsToProps = {
    loadNotifications,
    updateNotifications
}

export default connect(stateToProps, actionsToProps)(SettingsNotifications)
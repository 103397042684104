import React from 'react'
import {connect} from 'react-redux'

import {saveObjectsGroup, createObjectsGroup, deleteObjectsGroup} from '../../../../../store/ObjectsGroup/actions'
import {openModal, closeModal} from '../../../../../store/Modal/actions'

import ObjectsGroupComponent from '../../../../../components/ObjectsGroup'
import Collapsible from '../../../../../components/Space/Collapsible'


const ObjectsGroup = ({
    className = "",
    active,
    placeNumber,

    selectedGroups = [],
    groups = {},

    createObjectsGroup,
    deleteObjectsGroup,
    saveObjectsGroup,
    openModal,
    onChangeCollapsible,
    closeModal,
}) => {

    const selectedGroup = selectedGroups[0]
    const selectedTitle = groups[selectedGroup]?.name || ""

    return(
        <>
            <Collapsible
                active={active}
                id={112}
                className={className}
                onChangeCollapsible={onChangeCollapsible}                
                title="Группы объектов"
                placeNumber={placeNumber}
                buttons={{
                    add: {
                        on: true,
                        title: "Создать группу",
                        onClick: () => openModal({
                            type: "objectGroupCreate",
                            size: "sm",
                        })
                    },
                    remove: {
                        on: true,
                        disabled: !(selectedGroups.length >= 1),
                        onClick: () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: `Группа содержит ${groups[selectedGroups[0]].objects.length} объектов. Вы уверены, что хотите произвести удаление Группы?`,
                                buttons: {
                                    cancel: {
                                        title: "Отменить",
                                        onClick: () => closeModal("confirm")
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deleteObjectsGroup({ id: selectedGroups })
                                            closeModal("confirm")
                                        }
                                    },
                                }
                            }
                        })
                    },
                    edit: {
                        on: true,
                        disabled: !(selectedGroups.length === 1),
                        onClick: () => openModal({
                            type: "oneInput",
                            size: "sm",
                            data: {
                                title: "Редактирование группы объектов",
                                input: {
                                    label: "Название группы объектов",
                                    attrs: {
                                        placeholder: "Введите название",
                                        value: selectedTitle,
                                    }
                                },
                                buttons: {
                                    prim: {
                                        title: "Сохранить",
                                        onClick: (value) => {
                                            saveObjectsGroup({
                                                title: value,
                                                id: selectedGroup,
                                            })
                                            closeModal("oneInput")
                                        }
                                    }
                                }
                            }
                        })
                    },
                }}
            >
                <ObjectsGroupComponent/>
            </Collapsible>
        </>
    )
}

const stateToProps = state => ({
    selectedGroups: state.objectsGroup.selected,
    groups: state.objectsGroup.groupsAssoc,
})

const actionsToProps = {
    createObjectsGroup,
    deleteObjectsGroup,
    saveObjectsGroup,
    openModal,
    closeModal,
}

export default connect(stateToProps, actionsToProps)(ObjectsGroup)
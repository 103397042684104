import React from 'react'


const Warning = ({ title = 'Предупреждение', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.233 3.709">
                <title>{ title }</title>
                <path d="M2.117 0a.172.172 0 00-.156.09l.003-.006L.022 3.442c-.067.115.027.272.16.267H4.05c.133.005.227-.152.16-.267L2.273.09A.172.172 0 002.117 0zm0 .348l1.79 3.097H.326zm0 .753a.132.132 0 00-.13.135v1.17a.132.132 0 10.264 0v-1.17a.132.132 0 00-.134-.135zm.002 1.542a.265.265 0 00-.263.263c0 .144.119.263.263.263a.264.264 0 00.262-.263.265.265 0 00-.262-.263zm0 .261l.001.002-.001.002-.002-.002.002-.002z" color="#000" fontWeight="400" fontFamily="sans-serif" overflow="visible" fill="#0a0a0a" stroke="none" data-name="Group 3325" strokeMiterlimit="10" strokeWidth="1.073" /></svg>
        </i>
    )
}

export default Warning
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { cl } from '../../../../../../utilities/cl'
import SvgArrowDownLight from '../../../../../../components/SVG/ArrowDownLight'
import ScrollContainer from '../../../../../../components/ScrollContainer'
import HelpContent from '../../HelpContent'

const HelpMenuItem = ({
    title,
    index,
    markItems,
    selected,
    //index
}) => {
    const [opened, setOpened] = useState(false)
    const className = cl(
        { "opened": opened },
        { "highlighted": markItems.indexOf(index) !== -1 },
    )

    return (
        <div>
            {/* <div className={ `${className} CollapsibleCardPlaylist` } data-videolist-id={ id } onClick={ hClick }> */ }
            <div className={ `${className} CollapsibleCardHelp` } data-help-id={ index } onClick={ () => setOpened(!opened) }>
                <div className="Help__header CollapsibleCardHelp__header">
                    <div className="Help__grid CollapsibleCardHelp__title">
                        <span>{ title }</span>
                    </div>
                    <div className="__CollapsibleCardHelp__buttons" >
                        <div className="CollapsibleCardHelp__open" >
                            <SvgArrowDownLight
                                variant="dark"
                                size="mr"
                                title={ opened ? "Свернуть" : "Развернуть" }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="CollapsibleCardHelp__content">
                { (opened)
                    && <ScrollContainer size="md">
                        <HelpContent />
                    </ScrollContainer>
                }
            </div>
        </div>





    )
}
const stateToProps = state => ({
    menu: state.help.help,
    loading: state.help.loading,
    markItems: [...state.marks[state.marks.current].items],
    selected: state.help.selected,
})


export default connect(stateToProps, null)(HelpMenuItem)
import React from 'react'
import {connect} from 'react-redux'

import Place from '../../../../components/Space/Place'
import Empty from '../../../../components/Space/Empty'

import SlideMenu from '../../../../components/SlideMenu'

import SettingsObject from './SettingsObject'
import SettingsModem from './SettingsModem'
import SettingsNet from './SettingsNet'


const Where = ({ selectedObjects }) => (
    <>
        {/* 2 */}

        <Place 
            number={2}
            className="ObjectsWhere"
        >
            {(selectedObjects?.length)
                ? <SlideMenu
                    activeItem={1}
                    items={[
                        { 
                            title: "Настройки объекта",
                            content: <SettingsObject placeNumber={2} active/>
                        },
                        { 
                            title: "Проводная сеть",
                            content: <SettingsNet placeNumber={2}/>
                        },
                        { 
                            title: "МОДЕМ",
                            content: <SettingsModem placeNumber={2}/>
                        },
                    ]}
                />
                : <Empty>
                    Выберите объект или группу объектов
                </Empty>
            }
        </Place>
    </>
)

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
})

export default connect(stateToProps, null)(Where)
import {takeEvery, call, put, select} from 'redux-saga/effects'
import {
    loading,
    putObjects,
    SEARCH_OBJECTS
} from '../../store/Objects/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import React from "react";
import {doSubLogin} from "../../store/Login/actions";

export default function* () {
    yield takeEvery(SEARCH_OBJECTS, worker)
}

function* worker({ payload }) {
    const userId = yield select(state => state.login.user.id)
    const master = yield select(state => state.settings_roles.master)
    const subUsers = yield select(state => state.settings_roles.subusers)
    try {
        const objects = yield call(fetchObjects, payload, master.token)
        if (objects.error && objects.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${SEARCH_OBJECTS}.`, objects],
                text: "Объекты не были найдены. " + objects.error
            }))
            yield put(putObjects([]))
        } else {
            let objectsFound = objects.success
            if(!objectsFound.length) {
                yield put(showAlertError({
                    errors: [],
                    text: "Объект не был найден."
                }))
                return;
            }
            const object = objectsFound[0]
            if (object.owner_id === userId) {
                yield put(showAlertError({
                    errors: [],
                    text: `Объект ${payload} находится в текущем аккаунте, переключение не требуется.`
                }))
                return;
            }
            window.localStorage.setItem('search_ms_id', Number(payload));
            yield put(doSubLogin({id: object.owner_id, redirect: `/main/object`}));
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SEARCH_OBJECTS}.`, e],
            text: "Объекты не были найдены. Попробуйте перезагрузить страницу."
        }))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchObjects(id, token) {
    return axios.get("/msbox/search", {params: {id, token}})
        .then(response => response.data)
}

import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../utilities/generateStateKey'

import {loadItems, loadTracks, addItem, deleteTrackFromPosition, saveItemDaymask, deleteItems, selectPeriod, selectBlock} from '../../../../store/Settings/actions/sets/Object/Schedule'
import {openModal, closeModal} from '../../../../store/Modal/actions'
import {updateOptions, toggleSelectedItems} from '../../../../store/Settings/actions'
import {OBJECT_SCHEDULE} from '../../../../store/Settings/sets'

import ObjectSchedule from './ObjectSchedule'
import { downloadTracks } from '../../../../store/Tracks/actions'


const ObjectScheduleContainer = props => (
    <ObjectSchedule
        className={props.className}
        stateKey={props.stateKey}

        store={props.store}
        data={props.data}
        settings={props.settings}
        selectedPeriod={props.selectedPeriod}
        selected={props.selected}
        periodStateKey={props.periodStateKey}
        items={props.items}
        tracks={props.tracks}

        selectedObjects={props.selectedObjects}
        selectedGroups={props.selectedGroups}
        objectsAssoc={props.objectsAssoc}
        groupsAssoc={props.groupsAssoc}
        usesOwn={props.usesOwn}

        loading={props.data.__loading}
        saving={props.data.__saving}
        deleting={props.data.__deleting}
        adding={props.data.__adding}

        loadItems={props.loadItems}
        loadTracks={props.loadTracks}
        addItem={props.addItem}
        deleteTrackFromPosition={props.deleteTrackFromPosition}
        downloadTracks={props.downloadTracks}
        saveItemDaymask={props.saveItemDaymask}
        deleteItems={props.deleteItems}
        selectPeriod={props.selectPeriod}
        toggleSelectedItems={props.toggleSelectedItems}
        selectBlock={props.selectBlock}
        updateOptions={props.updateOptions}
        openModal={props.openModal}
        closeModal={props.closeModal}
    />
)

const stateToProps = (state, { stateKey }) => {
    const store    = state.settings.settings[OBJECT_SCHEDULE]
    const data     = store[stateKey] || {}
    const settings = data
    if (typeof settings['daymask'] === 'undefined') {
        settings['daymask'] = 127;
    }
    const selectedPeriod = data.selectedPeriod || -1
    const selected       = data.selected
    const periodStateKey = generateStateKey([ stateKey, selectedPeriod ])
    const items          = store[periodStateKey]?.list || []
    const tracks         = store.tracks || {}

    return {
        store,
        data,
        settings,
        selectedPeriod,
        selected,
        periodStateKey,
        items,
        tracks,
        selectedObjects: state.objects.selected,
        selectedGroups: state.objectsGroup.selected,
        objectsAssoc: state.objects.allObjectsAssociated,
        groupsAssoc: state.objectsGroup.groupsAssoc,
        usesOwn: store.usesOwn,
    }
}

const actionsToProps = {
    loadItems,
    loadTracks,
    addItem,
    deleteTrackFromPosition,
    downloadTracks,
    saveItemDaymask,
    deleteItems,
    selectPeriod,
    toggleSelectedItems,
    selectBlock,
    updateOptions,

    openModal,
    closeModal,
}

export default connect(stateToProps, actionsToProps)(ObjectScheduleContainer)
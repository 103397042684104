import React from 'react'


const ArrowDownLight = ({ title = 'Вниз', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg className={ className } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.175 1.66">
                <title>{ title }</title>
                <path className={ className } d="M.095 0a.095.095 0 00-.067.162L1.5 1.634a.094.094 0 00.088.024c.03.006.064 0 .088-.024L3.148.162a.094.094 0 10-.134-.134L1.587 1.454.162.028A.094.094 0 00.095 0z" data-name="Group 1178" fill="#0a0a0a" strokeWidth="1.398" /></svg>
        </i>
    )
}

export default ArrowDownLight
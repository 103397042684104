import { LOADING } from './actions'

const initialState = {
    loading: false,
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case LOADING:
            return {
                ...state,
                loading: payload
            }
    }
    return state
}

export default reducer
import React, {useEffect, useState, useRef} from 'react'

import {connect} from 'react-redux'
import ScrollContainer from '../../../../../components/ScrollContainer'
import Spinner from '../../../../../components/Spinner'
import PlaylistItem from '../../../../../components/ContentMusic/PlaylistItem'
import Empty from '../PlaylistSongs/Empty'
import List from '../../../../../components/DraggableList'
import {
    loadAssocPublicTracks,
    putAssocPublicTracksTable,
    sortPublicSongs
} from '../../../../../store/PublicPlaylists/actions'
import {selectSongsAll} from "../../../../../store/PublicPlaylists/actions";
import TrackList from '../PlaylistSongs'
import SongItem from './Songs'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Button from '../../../../../components/Button'
import Trash from '../../../../../components/SVG/Trash'
import Ok from '../../../../../components/SVG/Ok'
import Plus from '../../../../../components/SVG/PlusCircle'
import Minus from '../../../../../components/SVG/MinusCircle'
import Filter from '../../../../../components/SVG/FilterCircle'
import ElementsGroup from '../../../../../components/ElementsGroup'
import {openModal} from '../../../../../store/Modal/actions'
import {cl} from "../../../../../utilities/cl";
import {updateFilterParams} from "../../../../../store/Modal/types/PublicSongsFilter/actions";
import Close from "../../../../../components/SVG/Close";


const AllSongs = ({
                      searchActive,
                      searchFounded = [],
                      songs = [],
                      loading_assoc,
                      toggleSelected,
                      selected,
                      selected_song,
                      playlistSelected,
                      allSongs,
                      loadAssocPublicTracks,
                      putAssocPublicTracksTable,
                      updateFilterParams,
                      assocPublicTracksTable,
                      assoc_public_tracks,
                      parentCallback,
                      public_playlist_items,
                      public_playlist_content,
                      selected_public_song,
                      addSongs,
                      selectAll,
                      selectSongsAll,
                      sortPublicSongs,
                      openModal,
                      rubrics_playlist,
                      filter_params
                  }) => {
    songs = Object.values(assoc_public_tracks)
    const prevPublicPlaylist = usePrevious(selected[0])

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {
        if (selected.length > 0) {
            if (Object.values(assoc_public_tracks).length === 0 || prevPublicPlaylist !== selected[0]) {
                putAssocPublicTracksTable({sort_field: '', filter: {}})
                loadAssocPublicTracks({offset: 0})
            }
        }
    }, [selected[0]])
    const sort = (field) => {
        putAssocPublicTracksTable({sort_field: field})
        if (filter_params.genre_name?.length > 0 || filter_params.songauthor_name?.length > 0 || filter_params.tempo?.length > 0) {
            updateFilterParams(filter_params)
        } else {
            loadAssocPublicTracks({offset: 0})
        }
    }

    const blacklist = () => {
        putAssocPublicTracksTable({filter: {blacklist: !assocPublicTracksTable.filter.blacklist}})
        loadAssocPublicTracks({offset: 0})
    }

    return (
        <section className="" style={{width: "61%", marginLeft: "2%"}}>
            <h2 style={{maxWidth: "30%"}}>{"ВСЯ МУЗЫКА"}</h2>
            <div style={{marginTop: -36, marginBottom: 36}}>
                <ElementsGroup position="right">
                    <Button
                        type="button"
                        disabled={selected.length > 0 && playlistSelected.length > 0 ? false : true}
                        onClick={() => blacklist()}
                    >
                        {assocPublicTracksTable.filter.blacklist ?
                            <Close size={`sm`}
                                   variant="primary"/> : ''
                        }
                        Отбор

                    </Button>
                    <Button
                        type="string"
                        disabled={selected.length > 0 ? false : true}
                        onClick={() => openModal({
                            type: "publicsongsFilter",
                            size: "sm",
                        })}
                    >
                        <Filter size={`sm`}
                                variant={selected[0] === rubrics_playlist && Object.values(filter_params).length > 0 ? "primary" : "dark"}/> Фильтр
                    </Button>
                    <Button
                        type="string"
                        disabled={selected_public_song.length > 0 ? false : true}
                        onClick={() => addSongs()}
                    >
                        <Plus size={`sm`}/> Добавить
                    </Button>
                    <Button
                        type="string"
                        disabled={Object.keys(assoc_public_tracks).length > 0 ? false : true}
                        onClick={selectSongsAll}
                    >
                        <Ok size={`xs`}/> Выделить все строки {selected_song.length > 0 ? `(${selected_song.length})` : ''}
                    </Button>
                </ElementsGroup>
            </div>
            {
                loading_assoc && selected.length > 0
                    ? <Spinner className="pt-xs pb-xs"/>
                    : songs.length > 0 && selected.length > 0

                        ? <div>
                            <sections className="Songs">
                                <header className="Songs__control">
                                    <div className="Songs__grid">
                                        <div
                                            className={cl("composition", {"Songs__grid--active": assocPublicTracksTable.sort_field === 'name'})}
                                            style={{marginLeft: 20}}>
                                            <button
                                                className="spp-btn spp-btn_string"
                                                onClick={() => sort("name")}
                                            >
                                                <SvgArrowUpDown className="spp-svg"/>
                                                ПРОИЗВЕДЕНИЕ
                                            </button>
                                        </div>
                                        <div
                                            className={cl("duration", {"Songs__grid--active": assocPublicTracksTable.sort_field === 'cliptime'})}>
                                            <button
                                                className="spp-btn spp-btn_string"
                                                onClick={() => sort("cliptime")}
                                            >
                                                <SvgArrowUpDown className="spp-svg"/>
                                                ДЛИТ.
                                            </button>
                                        </div>
                                        <div
                                            className={cl("temp", {"Songs__grid--active": assocPublicTracksTable.sort_field === 'tempo'})}>
                                            <button
                                                className="spp-btn spp-btn_string"
                                                onClick={() => sort("tempo")}
                                            >
                                                <SvgArrowUpDown className="spp-svg"/>
                                                ТЕМП
                                            </button>
                                        </div>
                                        <div
                                            className={cl("chill_out", {"Songs__grid--active": assocPublicTracksTable.sort_field === 'genre_name'})}>
                                            <button
                                                className="spp-btn spp-btn_string"
                                                onClick={() => sort("genre_name")}
                                            >
                                                <SvgArrowUpDown className="spp-svg"/>
                                                ЖАНР
                                            </button>
                                        </div>
                                        <div
                                            className={cl("author", {"Songs__grid--active": assocPublicTracksTable.sort_field === 'songauthor_name'})}>
                                            <button
                                                className="spp-btn spp-btn_string"
                                                onClick={() => sort("songauthor_name")}
                                            >
                                                <SvgArrowUpDown className="spp-svg"/>
                                                АВТОР
                                            </button>
                                        </div>
                                        <div
                                            className={cl("added", {"Songs__grid--active": assocPublicTracksTable.sort_field === 'year'})}>
                                            <button
                                                className="spp-btn spp-btn_string"
                                                onClick={() => sort("year")}
                                            >
                                                <SvgArrowUpDown className="spp-svg"/>
                                                ДОБАВЛЕНО
                                            </button>
                                        </div>
                                    </div>
                                </header>
                            </sections>
                            {public_playlist_content}
                        </div>
                        : <Empty title="Выберите плейлист."/>
            }
        </section>
    )
}

const stateToProps = state => ({
    allPlaylists: state.public_playlists.allPlaylists,
    selected: state.public_playlists.selected,
    selected_song: state.public_playlists.selected_song,
    playlistSelected: state.playlists.selected,
    public_playlists: state.public_playlists,
    loading_assoc: state.public_playlists.loading_assoc,
    assoc_public_tracks: state.public_playlists.assocPublicTracks,
    assocPublicTracksTable: state.public_playlists.assocPublicTracksTable,
    selected_public_song: state.public_playlists.selected_song,
    rubrics_playlist: state.modalPublicSongsFilter.playlist,
    filter_params: state.modalPublicSongsFilter.filter_params,
})
const actionsToProps = {
    loadAssocPublicTracks,
    putAssocPublicTracksTable,
    updateFilterParams,
    sortPublicSongs,
    selectSongsAll,
    openModal
}

export default connect(stateToProps, actionsToProps)(AllSongs)
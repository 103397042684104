import React from 'react'
import ReactDOM from 'react-dom'
import {NavLink} from 'react-router-dom'
import outside from '../../utilities/outside'

import SvgMenu from '../SVG/Menu'
import iconMenu from '../../assets/icons/menu.svg'
import SvgClose from '../SVG/Close'


class NavbarCollapse extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            active: false
        }
        this.toggleActive = this.toggleActive.bind(this)
    }

    toggleActive(){
        this.setState(state => ({
            active: !state.active
        }))
    }

    render() {
        const { className = '', linksToTop, linksToBottom } = this.props

        return(
            <div className={`NavbarCollapse ${className}`}>
                <div className="open" onClick={this.toggleActive}>
                    <img src={iconMenu} alt="Открыть меню"/>
                    {/* <SvgMenu variant="dark" size="sm" title="Открыть меню"/> */}
                </div>        

                {outside(
                    <div className={`NavbarCollapse__menu ${this.state.active ? 'active' : ''}`}>
                        <div className="close" onClick={this.toggleActive}>
                            <SvgClose variant="dark" size="sm" title="Закрыть меню"/>
                        </div>
                        <div className="wrap">
                            <div className="top" onClick={this.toggleActive}>
                                <ul>
                                    {linksToTop.map(link => {
                                        return (
                                            <li key={link.id}>
                                                <NavLink to={link.href}>{link.title}</NavLink>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="bottom" onClick={this.toggleActive}>
                                <ul>
                                    {linksToBottom.map(link => {
                                        return (
                                            <li key={link.id}>
                                                <NavLink to={link.href}>{link.title}</NavLink>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}


export default NavbarCollapse
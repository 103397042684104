import React from 'react'


const LogOut = ({ title = 'Выйти', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.977 4.233">
                <title>{ title }</title>
                <path d="M.125 0A.124.124 0 000 .125v3.983c0 .07.056.125.125.125h.747a.124.124 0 100-.249H.249V.25h.623a.124.124 0 100-.249zM2.62.708a.125.125 0 00-.088.212l1.072 1.072H.872a.124.124 0 100 .249h2.642l-.98.98a.124.124 0 10.175.177l1.233-1.232a.123.123 0 00.035-.096.123.123 0 00-.035-.093L2.709.745a.124.124 0 00-.088-.037z" data-name="Group 960" fill="#0a0a0a" strokeWidth="1.063" /></svg>
        </i>
    )
}

export default LogOut
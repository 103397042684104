import React from 'react'
import {connect} from 'react-redux'

import {generateStateKey} from '../../../../../utilities/generateStateKey'

import {OBJECT_SILENT} from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'

import Collapsible from '../../../../../components/Space/Collapsible'


const SettingsScheduleCollapsible = ({
    className = "",
    selectedObjects,
    placeNumber,
    active
}) => {

    const stateKey = generateStateKey([ selectedObjects ])

    return(
        <Collapsible
            active={active}
            id={3551}
            className={className}
            title="Молчание по датам"
            placeNumber={placeNumber}
        >
            <Settings
                set={OBJECT_SILENT}
                data={{
                    stateKey,
                }}
            />
        </Collapsible>
    )
}

const stateToProps = state => ({
    selectedObjects: [...state.objects.selected],
})

export default connect(stateToProps, null)(SettingsScheduleCollapsible)
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Button as ButtonBS} from 'react-bootstrap'

import {generateStateKey} from '../../../../../../../utilities/generateStateKey'
import {saveItemTracksAndDaymask} from '../../../../../../../store/Settings/actions/sets/Object/Schedule'

import {OBJECT_BACKGROUND} from '../../../../../../../store/Settings/sets'

import CycleTime from '../../../../../../Form/CycleTime'
import SettingsDays from '../../../../../components/Days'


const ModalContent = ({
    stateKey,
    itemId,
    starttime,
    daymask,

    saving,

    saveItem,
    closeModal,
}) => {

    const [nextStarttime, setStarttime] = useState(starttime)
    const [nextDaymask, setDaymask] = useState(daymask)

    const hChangeStarttime = ({ value }) => setStarttime(value)
    const hChangeDaymask   = ({ value }) => setDaymask(value)

    const hSave = () => {
        closeModal("element")
        saveItem({
            stateKey,
            itemId,
            settings: {
                starttime: nextStarttime,
                daymask: nextDaymask,
            }
        })
    }

    return(
        <>
            <CycleTime
                onChange={hChangeStarttime}
            />
            
            <div className="pt-md1"></div>

            <SettingsDays
                value={nextDaymask}
                onChange={hChangeDaymask}
            />
            
            <div className="Modal__footer">
                <div className="spp-btns">
                    <ButtonBS variant="prim" onClick={hSave} disabled={saving}>
                        {saving ? "Сохранение..." : "Сохранить"}
                    </ButtonBS>
                </div>
            </div>
        </>
    )
}

const stateToProps = (state, { stateKey, itemId }) => {
    const itemStateKey = generateStateKey([ stateKey, itemId ])
    return {
        starttime: state.settings.settings[OBJECT_BACKGROUND][itemStateKey]?.starttime,
        daymask: state.settings.settings[OBJECT_BACKGROUND][itemStateKey]?.daymask,
        saving: state.settings.settings[OBJECT_BACKGROUND][stateKey]?.__saving
    }
}

const ModalContentContainer = connect(stateToProps, null)(ModalContent)


const editItem = ({
    stateKey,
    itemId,
    
    saveItem,
    openModal,
    closeModal,
}) => {

    openModal({
        type: "element",
        size: "sm",
        data: {
            title: "Редактирование расписания",
            element:
                <ModalContentContainer
                    stateKey={stateKey}
                    itemId={itemId}
                    saveItem={saveItem}
                    closeModal={closeModal}
                />
        }
    })
}

export default editItem
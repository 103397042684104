import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_SCHEDULE } from '../../../../store/Settings/sets'
import { SAVE_ITEM_TRACKS_AND_DAYMASK, loadItems } from '../../../../store/Settings/actions/sets/Object/Schedule'
import { closeModal } from '../../../../store/Modal/actions'
import { saving } from '../../../../store/Settings/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError, showAlertSuccess } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(SAVE_ITEM_TRACKS_AND_DAYMASK, worker)
}

function* worker({ payload }) {
    let { stateKey, itemId } = payload
    let { side, trackStartId, trackStopId } = payload
    let { daymask, __withoutAds } = payload

    try {
        if (!isNaN(itemId)) {
            yield put(saving({
                type: OBJECT_SCHEDULE,
                stateKey,
                saving: true,
            }))

            const itemStateKey = generateStateKey([stateKey, itemId])
            const itemSettings = yield select(state => state.settings.settings[OBJECT_SCHEDULE][itemStateKey])
            const selectedObjects = yield select(state => state.objects.selected || [])
            const selectedGroups = yield select(state => state.objectsGroup.selected || [])

            let start_id = itemSettings["start_id"]
            let stop_id = itemSettings["stop_id"]
            start_id[side] = trackStartId
            stop_id[side] = trackStopId

            daymask = (__withoutAds === true) ? daymask + 128 : daymask

            const settings = {
                daymask,
                start_id,
                stop_id,
            }

            const msIds = selectedGroups.length ? selectedGroups : selectedObjects
            const saved = yield call(fetchSave, itemId, msIds, settings)

            // Ошибка
            if (saved.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${SAVE_ITEM_TRACKS_AND_DAYMASK}.`, saved],
                    text: "Ошибка при сохранении. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(showAlertSuccess({
                    responses: [`Успешное выполнение ${SAVE_ITEM_TRACKS_AND_DAYMASK}.`, saved],
                    text: "Настройки успешно сохранены."
                }))
                yield put(loadItems({
                    stateKey,
                    required: true,
                }))
                yield put(closeModal("element"))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_ITEM_TRACKS_AND_DAYMASK}.`, e],
            text: "Ошибка при сохранении. Попробуйте повторить."
        }))
    }

    finally {
        yield put(saving({
            type: OBJECT_SCHEDULE,
            stateKey,
            saving: false,
        }))
    }
}

function fetchSave(id, ms_id, settings) {
    ms_id = ms_id.join(",")

    let formData = new FormData()

    for (let name in settings) {
        formData.set(name, settings[name])
    }

    return axios(
        {
            url: "/msbox/clip/update",
            method: "POST",
            data: formData,
            params: {
                id, ms_id
            }
        }
    )
        .then(response => response.data)
}
import {ADVERT_TRACKS} from '../../../sets'

export const SAVE_SETTINGS = `Settings/${ADVERT_TRACKS}/SAVE_SETTINGS`

export const saveSettings = ({ type, indentIds, objectIds, stateKey, settings }) => ({
    type: SAVE_SETTINGS,
    payload: {
        type,
        indentIds,
        objectIds,
        stateKey,
        settings
    }
})
import React, {useRef} from 'react'

import {cl} from '../../utilities/cl'
import outside from '../../utilities/outside'
import {mediaMobile, mediaTabletAndUp} from '../../utilities/media'
import {position} from '../../utilities/position'


const MobileModal = ({
    className = "",
    opened,
    children,
}) => {

    const refModalTablet = useRef(null)
    const refForParent = useRef(null)

    className = cl(
        "MobileModal",
        {"opened": opened},
        className,
    )
 
    return(
        <>
            <div ref={refForParent}></div>
            
            {mediaTabletAndUp(
                outside(
                    <div
                        ref={refModalTablet}
                        className={className}
                        style={{
                            position: "absolute",
                            ...position({
                                parent: refForParent?.current?.parentElement,
                                child: refModalTablet?.current,
                                isWidthAuto: false,
                                isSqueeze: true,
                                isOverlay: true,
                            })
                        }}
                    >
                        <div className="MobileModal__content">
                            {opened && children}
                        </div>
                    </div>
                ),
                null
            )}
            {mediaMobile(
                outside(
                    <div className={className}>
                        <div className="MobileModal__content">
                            {opened && children}
                        </div>
                    </div>
                ),
                null
            )}
        </>
    )
}

export default MobileModal
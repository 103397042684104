import React from 'react'

import ObjectsByTags from '../../../../../components/ObjectsByTags'
import Collapsible from '../../../../../components/Space/Collapsible'


const Tags = ({ className = "", active, placeNumber }) => {
    return(
        <>
            <Collapsible
                active={active}
                id={233}
                className={className}
                title='Объекты по меткам'
                placeNumber={placeNumber}
            >
                <ObjectsByTags/>

            </Collapsible>
        </>
    )
}

export default Tags
import {takeEvery, call, put, all} from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { DELETE_OBJECTS_GROUP, loadObjectsGroup } from '../../store/ObjectsGroup/actions'
import { showAlertError, showAlertSuccess } from '../../store/Alert/actions'
import axios from 'axios'
import {DELETE_PLAYLISTS, loadPlaylists} from "../../store/ContentPlaylists/actions";

export default function* () {
    yield takeEvery(DELETE_OBJECTS_GROUP, worker)
}

function* worker({ payload }) {
    const { id } = payload
    try {
        let deletedSuccess = true
        const deleted = yield all(
            id.map(groupId => call(fetchDelete, groupId))
        )

        deleted.map(d => { if (d.error) deletedSuccess = false })

        if (deletedSuccess) {
            yield put(showAlertSuccess({
                //response: [`Ошибка сервера при ${DELETE_OBJECTS_GROUP}.`, deleted],
                text: "Группа объектов успешно удалена."
            }))
            yield put(loadObjectsGroup())
        } else {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${DELETE_OBJECTS_GROUP}.`, deleted],
                text: "Группа объектов не была удалена. Попробуйте повторить."
            }))
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_OBJECTS_GROUP}.`, e],
            text: "Группа объектов не была удалена. Попробуйте повторить."
        }))
        console.error(e)
    }
}

function fetchDelete(id) {
    return axios.get(`/msgroup/delete?id=${id}`)
        .then(response => response.data)
}
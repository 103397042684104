import { takeEvery, select, all, put, call } from 'redux-saga/effects'
import { OBJECT_BACKGROUND } from '../../../../store/Settings/sets'
import { toggleSelectedItems } from '../../../../store/Settings/actions'
import { DELETE_ITEMS, loadItems } from '../../../../store/Settings/actions/sets/Object/Background'
import { deleting } from '../../../../store/Settings/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_ITEMS, worker)
}

function* worker({ payload }) {
    let { stateKey, itemIds } = payload

    try {
        yield put(deleting({
            type: OBJECT_BACKGROUND,
            stateKey,
            deleting: true,
        }))

        const selectedItems = yield select(state => state.settings.settings[OBJECT_BACKGROUND][stateKey].selected || [])
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedGroups = yield select(state => state.objectsGroup.selected || [])

        itemIds = itemIds || selectedItems

        if (itemIds.length && (selectedObjects.length || selectedGroups.length)) {
            const msIds = selectedGroups.length ? selectedGroups : selectedObjects
            const deleted = yield call(fetchDelete, itemIds, msIds)

            // Ошибка
            if (deleted.error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${DELETE_ITEMS}.`, deleted],
                    text: "Ошибка при удалении. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(toggleSelectedItems({
                    type: OBJECT_BACKGROUND,
                    stateKey,
                    itemIds: [],
                }))

                let keysToReload = []
                selectedObjects.map(objectId => {
                    const stateKey = generateStateKey([objectId])
                    keysToReload.push(stateKey)
                })
                keysToReload.push(stateKey)

                yield all(
                    keysToReload.map(stateKey =>
                        put(loadItems({
                            stateKey,
                            required: true,
                        }))
                    )
                )
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_ITEMS}.`, e],
            text: "Ошибка при удалении. Попробуйте повторить."
        }))
    }

    finally {
        yield put(deleting({
            type: OBJECT_BACKGROUND,
            stateKey,
            deleting: false,
        }))
    }
}

function fetchDelete(items, ms_id) {
    let params = { ms_id }
    params.id = items.join(",")
    //params.ms_id = items.join(",")

    return axios.get("/msbox/play/delete", { params: { ...params } })
        .then(response => response.data)
}
import React from 'react'
import {connect} from 'react-redux'

import Tags from './Tags'


const TagsContainer = props => (
    <Tags
        className={props.className}
        name={props.name}
        value={props.value}
        allTags={props.allTags}
        onChange={props.onChange}
    />
)

const stateToProps = (state) => ({
    allTags: [...state.objectsByTags.tags].sort((a, b) => a.name < b.name ? -1 : 1),
})

export default connect(stateToProps, null)(TagsContainer)
import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_HELP_CONTENT, loadingContent, putHelpContent } from '../../store/Help/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_HELP_CONTENT, worker)
}

function* worker({ payload }) {
    yield put(loadingContent(true))
    try {
        const help = yield call(fetchHelpContent, payload.page)
        if (help.error && help.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_HELP_CONTENT}.`, help],
                text: "Страница не была загружена. Попробуйте перезагрузить страницу."
            }))
            yield put(putHelpContent([]))
        } else {
            yield put(putHelpContent({ help_content: help.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_HELP_CONTENT}.`, e],
            text: "Страница не была загружена. Попробуйте перезагрузить страницу."
        }))
        yield put(putHelpContent([]))
    }
    finally {
        yield put(loadingContent(false))
    }
}

function fetchHelpContent(page) {
    return axios.get(`/page/select?url=${page}`)
        .then(response => response.data)
}

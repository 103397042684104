import React from 'react'
import { connect } from 'react-redux'

import PublicPlaylistItem from './PublicPlaylistItem'


const PublicPlaylistItemContainer = props => (
    <PublicPlaylistItem
        id={ props.id }
        name={ props.name }
        songs={ props.songs }
        selected={ props.selected }
        markItems={ props.markItems }
        tags={ props.tags }
        variant={ props.variant }
        totaltime={ props.totaltime }
        totalsize={ props.totalsize }
        totalcount={ props.totalcount }
    />
)

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.public_playlists.selected]
})

export default connect(stateToProps, null)(PublicPlaylistItemContainer)
import { takeEvery, select, put, all, call } from 'redux-saga/effects'
import { OBJECT_SCHEDULE } from '../../../../store/Settings/sets'
import { DELETE_ITEMS, loadItems } from '../../../../store/Settings/actions/sets/Object/Schedule'
import { toggleSelectedItems } from '../../../../store/Settings/actions'
import { deleting } from '../../../../store/Settings/actions'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_ITEMS, worker)
}

function* worker({ payload }) {
    let { stateKey, itemIds } = payload

    try {
        if (itemIds.length === 0) itemIds = yield select(state => state.settings.settings[OBJECT_SCHEDULE][stateKey].selected || [])

        if (itemIds.length) {
            yield put(deleting({
                type: OBJECT_SCHEDULE,
                stateKey,
                deleting: true,
            }))

            const selectedObjects = yield select(state => state.objects.selected || [])
            const selectedGroups = yield select(state => state.objectsGroup.selected || [])

            const msIds = selectedGroups.length ? selectedGroups : selectedObjects
            const deleted = yield all(
                itemIds.map(itemId => call(fetchDelete, itemId, msIds))
            )

            let error = false
            deleted.map(req => {
                if (req.error) error = true
            })

            // Ошибка
            if (error) {
                yield put(showAlertError({
                    errors: [`Ошибка сервера при ${DELETE_ITEMS}.`, deleted],
                    text: "Расписания не были удалены. Попробуйте повторить."
                }))

                // Успех
            } else {
                yield put(toggleSelectedItems({
                    type: OBJECT_SCHEDULE,
                    stateKey,
                    itemIds: []
                }))
                yield put(loadItems({
                    stateKey,
                    required: true,
                }))
            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_ITEMS}.`, e],
            text: "Расписания не были удалены. Попробуйте повторить."
        }))
    }

    finally {
        yield put(deleting({
            type: OBJECT_SCHEDULE,
            stateKey,
            deleting: false,
        }))
    }
}

function fetchDelete(id, ms_id) {
    ms_id = ms_id.join(",")

    return axios.get("/msbox/clip/delete", { params: { id, ms_id } })
        .then(response => response.data)
}
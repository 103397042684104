import React from 'react'

import { cl } from '../../utilities/cl'

import { SETTINGS, SETTINGS_TEMPLATES, REPORT, HELP, LOGIN, MAIN } from '../../routes/routes'

import Navbar from './Navbar'


const NavbarContainer = props => {

    const className = cl(
        props.className,
        { "without-menu": isMainSettingsPage(props.page) }
    )

    return (
        <Navbar
            { ...props }
            className={ `${className} only_trigger` }
            links={ [
                {
                    id: 11,
                    title: 'Главная',
                    href: MAIN,
                },
                {
                    id: 12,
                    title: 'Отчёт',
                    href: REPORT,
                },
                {
                    id: 13,
                    title: 'Настройка',
                    href: SETTINGS_TEMPLATES,
                },
                {
                    id: 14,
                    title: 'Помощь',
                    href: HELP,
                }
            ] }
        />
    )
}

function isMainSettingsPage(page) {
    return page === SETTINGS
}

export default NavbarContainer
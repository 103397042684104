export function getChangedSettings(map = [], settings = {}) {
    let changed = {}

    for(let name in settings) {
        if(map.indexOf(name) !== -1) {
            changed[name] = settings[name]
        }
    }

    return changed
}
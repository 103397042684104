import React from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../../utilities/cl'

import Place from '../../../../components/SpaceContent/Place'
import Empty from '../../../../components/SpaceContent/Empty'

import SlideMenu from '../../../../components/SlideMenu'
import ObjectsMenu from '../../../../components/ObjectsMenu'

import Music from './Music'
import Video from './Video'


class What extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            empty: false
        }

        this.setEmptyMode = this.setEmptyMode.bind(this)
    }

    componentDidUpdate() {
        const {
            searchActive,
            searchFounded,
            searchValue
        } = this.props

        if (searchActive) {
            (searchValue === "")
                ? this.setEmptyMode(false)
                : this.setEmptyMode(searchFounded.length === 0)
        } else {
            this.setEmptyMode(false)
        }
    }

    setEmptyMode(mode) {
        if (!mode) {
            this.setState(state => {
                if (state.empty) {
                    return { empty: false }
                }
            })
        } else {
            this.setState(state => {
                if (!state.empty) {
                    return { empty: true }
                }
            })
        }
    }

    render() {
        const {
            searchActive
        } = this.props
        const {
            empty
        } = this.state

        const hideContent = cl({ "d-none": empty })
        const placeHolder = cl({ "d-none": !empty })

        return (
            <>
                {/* 1 */ }

                <Place
                    number={ 1 }
                    className={ cl(
                        "ContentWhat",
                        { "search-active": searchActive }
                    ) }
                >
                    <ObjectsMenu />

                    <SlideMenu
                        activeItem={ 1 }
                        className={ hideContent }
                        items={ [
                            {

                                title: "Музыка",
                                content: <Music className={ hideContent } key={ 1 } placeNumber={ 1 } active />
                            },
                            {

                                title: "Видео",
                                content: <Video className={ hideContent } key={ 2 } placeNumber={ 1 } />
                            }
                        ] }
                    />

                    <Empty className={ placeHolder }>
                        Плейлистов с таким названием не найдено
                    </Empty>
                </Place>
            </>
        )
    }
}

const stateToProps = state => ({
    searchActive: state.search["objects"].modeActive,
    searchValue: state.search["objects"].value,
    searchFounded: [...state.search["objects"].founded],
})

export default connect(stateToProps, null)(What)
import React from 'react'
import { connect } from 'react-redux'

import PlaylistItem from './PlaylistItem'
import {loadPlaylist, toggleSelectedPlaylist} from "../../../store/ContentPlaylists/actions";

const PlaylistItemContainer = props => (
    <PlaylistItem
        loadPlaylist={props.loadPlaylist}
        id={ props.id }
        name={ props.name }
        selected={ props.selected }
        selected_public={ props.selected_public }
        markItems={ props.markItems }
        tags={ props.tags }
        variant={ props.variant }
        totaltime={ props.totaltime }
        totalsize={ props.totalsize }
        totalcount={ props.totalcount }
    />
)

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.playlists.selected],
    selected_public: [...state.public_playlists.selected]
})

const actionsToProps = {
    loadPlaylist
}

export default connect(stateToProps, actionsToProps)(PlaylistItemContainer)
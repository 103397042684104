import React from 'react'
import {Spinner as SpinnerBS} from 'react-bootstrap'


const Spinner = ({ className = "", text = '' }) => {
    return(
        <div className={`Spinner text-center ${className}`}>
            <div className="scale">
                <SpinnerBS
                    animation="border"
                    role="status"
                    variant="dark-md"
                    size="md"
                >
                    <span className="sr-only">Загрузка...</span>
                </SpinnerBS>
            </div>
            { text && <span className="Spinner__text">{ text }</span>}
        </div>
    )
}

export default Spinner
import {LOADING, DELETING} from './actions'

const initialState = {
    loading: false,
    deleting: false,
}

const reducer = (state = initialState, { type, payload }) => {
    switch(type) {

        case LOADING:
            return {
                ...state,
                loading: payload
            }

        case DELETING:
            return {
                ...state,
                deleting: payload
            }
    }
    return state
}

export default reducer
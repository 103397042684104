export function normalizeTime(m, s, divider) {
    m = m.toString()
    s = s.toString()
    if(m.length < 2) m = `0${m}`
    if(s.length < 2) s = `0${s}`
    return `${m}${divider}${s}`
}

export function normalizeNumber(number) {
    number = number.toString()
    if(number.length < 2) number = `0${number}`
    return number
}

export function normalizeNumberSpaces(x) {
    if(typeof x === "number" || typeof x === "string") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    } else {
        return ""
    }
}

export function normalizeDate(date) {
    if(typeof date === "string") {
        return date.replace(/-0/g, "-")

    } else if(Array.isArray(date)) {
        return date.map(d => {
            if(typeof d === "string")
                return d.replace(/-0/g, "-")
        })
        
    } else {
        return date
    }
}
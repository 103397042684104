import {
    LOAD_HELP, LOADING,
    PUT_HELP,
    NEED_HELP,
    PUT_SENDED,
    SENDING_CALL,
    SENDING_QUESTION,
    PUT_HELP_CONTENT,
    LOAD_HELP_CONTENT,
    TOGGLE_SELECTED_HELP_MENU,
    LOADING_CONTENT
} from './actions'

const inititalState = {
    loading: false,
    loading_content: false,
    sending_call: false,
    sending_question: false,
    help: [],
    help_content: [],
    selected: [],
    sended: ""
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case LOAD_HELP:
            return {
                ...state,
            }
        case NEED_HELP:
            return {
                ...state,
            }
        case PUT_HELP:
            return {
                ...state,
                help: payload.help,
            }
        case PUT_SENDED:
            return {
                ...state,
                sended: payload.type,
            }
        case LOAD_HELP_CONTENT:
            return {
                ...state,
            }
        case PUT_HELP_CONTENT:
            return {
                ...state,
                help_content: payload.help_content
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case SENDING_CALL:
            return {
                ...state,
                sending_call: payload
            }
        case SENDING_QUESTION:
            return {
                ...state,
                sending_question: payload
            }
        case LOADING_CONTENT:
            return {
                ...state,
                loading_content: payload
            }
        case TOGGLE_SELECTED_HELP_MENU:
            const { id } = payload
            return {
                ...state,
                selected:
                    (state.selected.indexOf(id) === -1)
                        ? [id]
                        : state.selected.filter(help_menu => help_menu !== id)
            }
    }
    return state
}

export default reducer